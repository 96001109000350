import escapeRegExp from 'lodash/escapeRegExp';
import { SearchProfileColor } from 'src/helpers/constants';
import { notNull } from 'src/helpers/typescript';
import { IProcurementFilesCategory, IProcurementFile } from 'src/models/procurements/Tender/types';
import {
  IDocument,
  IDocumentSearchResult,
  IHandledDocument,
  IHandledDocumentPart,
  IHighlightChunk,
  IOccurrence,
  ISearchProfile,
  ISearchProfileDocuments,
  ISearchSettings,
  WORD_ENDING,
  WORD_BEGINNING
} from './types';

export function toSafeGroupId(groupId: string): string {
  return groupId.replace(/-/g, '');
}

function checkOccurrences(occurrences: IOccurrence[], searchProfile: ISearchProfile): boolean {
  return occurrences.some(oc => oc.searchProfile.id === searchProfile.id && !oc.isHidden);
}

/**
 * if part does not contain results from a search profile there this document is hosted then this part is excluded.
 * if a document has no parts remaining then the document is excluded too.
 *
 * but the search profile itself will be there (just with empty array of documents).
 * I guess we should show <Empty /> in this case
 */
export function filterProfiledDocuments<T extends IHandledDocument>(
  profiledDocuments: ISearchProfileDocuments<T>[]
): ISearchProfileDocuments<T>[] {
  return profiledDocuments.map(pDoc => {
    const { searchProfile, documents } = pDoc;
    const filteredDocuments = documents
      .map(document => {
        return {
          ...document,
          parts: document.parts.filter(
            part =>
              checkOccurrences(part.occurrences, searchProfile) ||
              checkOccurrences(part.headlineOccurrences, searchProfile)
          )
        };
      })
      .filter(doc => doc.parts.length > 0);
    return {
      ...pDoc,
      documents: filteredDocuments
    };
  });
}

export function findOccurrences(content: string, searchProfiles: readonly ISearchProfile[]): IOccurrence[] {
  const occurrences: IOccurrence[] = [];

  for (const searchProfile of searchProfiles) {
    for (const term of searchProfile.terms) {
      const regexp = RegExp(`${WORD_BEGINNING}(?<term>${escapeRegExp(term)})${WORD_ENDING}`, 'ig');
      const matches = Array.from(content.matchAll(regexp));
      for (const match of matches) {
        const { index } = match;
        if (index !== undefined && match.groups) {
          const i = Math.max(match.groups.term.toLowerCase().indexOf(term.toLowerCase()), 0);
          occurrences.push({
            from: index + i,
            to: index + i + term.length,
            searchProfile,
            term,
            match
          });
        }
      }
    }
  }

  return occurrences;
}

export function handleDocuments(documents: IDocument[], searchProfiles: readonly ISearchProfile[]): IHandledDocument[] {
  const handledDocuments: IHandledDocument[] = [];
  for (const document of documents) {
    const handledParts: IHandledDocumentPart[] = [];
    for (const part of document.parts) {
      const occurrences = findOccurrences(part.content, searchProfiles);
      const headlineOccurrences = findOccurrences(part.headline, searchProfiles);

      handledParts.push({
        ...part,
        occurrences,
        headlineOccurrences
      });
    }
    handledDocuments.push({
      ...document,
      parts: handledParts
    });
  }
  return handledDocuments;
}

export function searchResultsToDocuments(
  searchResultsRaw: Readonly<Record<string, readonly IDocumentSearchResult[]>>,
  searchProfiles: readonly ISearchProfile[]
): IDocument[] {
  const documentsMap = new Map<string, IDocument>();
  for (const [searchProfileDirtyKey, resultDocuments] of Object.entries(searchResultsRaw)) {
    const searchProfileDirtyKey2 = searchProfileDirtyKey.replace(/group_/g, '');
    const searchProfile = searchProfiles.find(sp => sp.id.replace(/-/g, '') === searchProfileDirtyKey2);
    if (!searchProfile) {
      console.warn('Search profile not found');
      continue;
    }
    for (const resultDocument of resultDocuments) {
      const { filePath, content: documentContent, order, headline, contentPosition, headlinePosition } = resultDocument;
      const content = ` ${documentContent}`;

      const pathChunks = filePath.split('/');
      const fileName = pathChunks[pathChunks.length - 1] || '-';
      const document = documentsMap.get(filePath) || {
        filePath,
        fileName,
        parts: []
      };
      const existingPart = document.parts.find(part => part.content === content);
      const partToHandle = existingPart || {
        content,
        order,
        headline,
        searchProfiles: new Set<ISearchProfile>(),
        contentPosition,
        headlinePosition
      };

      partToHandle.searchProfiles.add(searchProfile);

      if (!existingPart) {
        document.parts.push(partToHandle);
      }

      documentsMap.set(filePath, document);
    }
  }
  const documentsArray = Array.from(documentsMap.values());
  for (const document of documentsArray) {
    document.parts.sort((a, b) => a.order - b.order);
  }

  return documentsArray;
}

export function getDocumentsByProfiles<T extends IDocument>(
  documents: T[],
  searchProfiles: ISearchProfile[]
): ISearchProfileDocuments<T>[] {
  const map = new Map<ISearchProfile, Set<T>>();
  for (const document of documents) {
    for (const part of document.parts) {
      for (const searchProfile of part.searchProfiles.values()) {
        const set = map.get(searchProfile) || new Set<T>();
        set.add(document);
        map.set(searchProfile, set);
      }
    }
  }

  return searchProfiles
    .filter(searchProfile => {
      return !!map.get(searchProfile);
    })
    .map(searchProfile => {
      const document = map.get(searchProfile) || new Set<T>();
      return {
        searchProfile,
        documents: Array.from(document)
      };
    });
}

export function combineChunks(chunks: IHighlightChunk[], mainSearchProfile: ISearchProfile | null): IHighlightChunk[] {
  const sortedChunks = chunks.slice().sort((a, b) => a.start - b.start);
  const processedChunks: IHighlightChunk[] = [];
  for (const chunk of sortedChunks) {
    // TODO: maybe we should take into account the main color to better handle overlapping
    if (processedChunks.length === 0) {
      // First chunk just goes straight in the array...
      processedChunks.push(chunk);
    }
    // ... subsequent chunks get checked to see if they overlap...
    // FIXME: @discuss: we probably need to refactor this part coz each time we mutate processedChunks array
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const prevChunk = processedChunks.pop()!;
    const isOverlap = chunk.start <= prevChunk.end;
    if (isOverlap) {
      if (prevChunk.highlight === chunk.highlight) {
        // the same group, just combine together
        const endIndex = Math.max(prevChunk.end, chunk.end);
        processedChunks.push({ highlight: prevChunk.highlight, start: prevChunk.start, end: endIndex });
      } else {
        // different groups, can not merge. Let's choose more important
        const prevChunkInMainGroup: boolean = !!mainSearchProfile && prevChunk.highlight === mainSearchProfile.color;
        const prevChunkLength: number = prevChunk.end - prevChunk.start;
        const chunkInMainGroup: boolean = !!mainSearchProfile && chunk.highlight === mainSearchProfile.color;
        const chunkLength: number = chunk.end - chunk.start;
        const prevChunkWeight = 10 * (prevChunkInMainGroup ? 1 : 0) + (prevChunkLength > chunkLength ? 1 : 0);
        const chunkWeight = 10 * (chunkInMainGroup ? 1 : 0) + (chunkLength > prevChunkLength ? 1 : 0);
        if (chunkWeight > prevChunkWeight) {
          // current chunk is more important then previous. Save the current one and abandon previous.
          processedChunks.push(chunk);
        } else {
          processedChunks.push(prevChunk);
        }
      }
    } else {
      processedChunks.push(prevChunk, chunk);
    }
  }

  return processedChunks;
}

export function applySearchSettings(documents: IHandledDocument[], settings: ISearchSettings): IHandledDocument[] {
  const termsForSearchMap = new Map(settings.groups.map(sp => [sp.searchProfile, sp.terms]));
  const filterIsTouched = settings.groups.some(group => !!group.terms.length);

  const includedDocumentNames = settings.documents.length > 0 ? settings.documents : documents.map(doc => doc.fileName);
  return documents
    .filter(doc => includedDocumentNames.includes(doc.fileName))
    .map(document => {
      const filteredParts = document.parts
        .map(part => {
          const filteredOccurrences: IOccurrence[] = filterOccurrences(
            part.occurrences,
            termsForSearchMap,
            filterIsTouched
          );
          const filteredHeadlineOccurrences: IOccurrence[] = filterOccurrences(
            part.headlineOccurrences,
            termsForSearchMap,
            filterIsTouched
          );

          if (filteredOccurrences.length > 0 || filteredHeadlineOccurrences.length > 0) {
            return {
              ...part,
              occurrences: filteredOccurrences,
              headlineOccurrences: filteredHeadlineOccurrences
            };
          } else {
            return null;
          }
        })
        .filter(notNull);

      if (filteredParts.length > 0) {
        return {
          ...document,
          parts: filteredParts
        };
      } else {
        return null;
      }
    })
    .filter(notNull);
}

function filterOccurrences(
  occurrences: IOccurrence[],
  termsForSearchMap: Map<ISearchProfile, string[]>,
  filterIsTouched: boolean
): IOccurrence[] {
  return occurrences
    .map(occurrence => {
      const termsForSearch = termsForSearchMap.get(occurrence.searchProfile);
      if (termsForSearch && termsForSearch.length > 0 && termsForSearch.includes(occurrence.term)) {
        return occurrence;
      } else {
        return { ...occurrence, isHidden: filterIsTouched };
      }
    })
    .filter(notNull);
}

/**
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength number
 * @return {start:number, end:number, highlight:boolean}[]
 */
export const fillInChunks = (chunksToHighlight: IHighlightChunk[], totalLength: number): IHighlightChunk[] => {
  const allChunks: IHighlightChunk[] = [];
  const append = (start: number, end: number, highlight: SearchProfileColor | null): void => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight
      });
    }
  };

  if (chunksToHighlight.length === 0) {
    append(0, totalLength, null);
  } else {
    let lastIndex = 0;
    chunksToHighlight.forEach(chunk => {
      append(lastIndex, chunk.start, null);
      append(chunk.start, chunk.end, chunk.highlight);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, null);
  }
  return allChunks;
};

export function getHighlights(
  part: IHandledDocumentPart,
  mainSearchProfile: ISearchProfile | null
): { content: IHighlightChunk[]; headline: IHighlightChunk[] } {
  const chunks = part.occurrences.map(
    (occ): IHighlightChunk => ({
      start: occ.from,
      end: occ.to,
      highlight: occ.searchProfile.color
    })
  );
  const headLineChunks = part.headlineOccurrences.map(
    (occ): IHighlightChunk => ({
      start: occ.from,
      end: occ.to,
      highlight: occ.searchProfile.color
    })
  );

  return {
    content: fillInChunks(combineChunks(chunks, mainSearchProfile), part.content.length),
    headline: fillInChunks(combineChunks(headLineChunks, mainSearchProfile), part.headline.length)
  };
}

export function extractFiles(procurementFiles: IProcurementFilesCategory): IProcurementFile[] {
  const handleFileNode = (accumulator: IProcurementFile[], node: IProcurementFile): void => {
    const { children } = node;
    if (children && children.length > 0) {
      for (const child of children) {
        handleFileNode(accumulator, child);
      }
    } else {
      // not directory
      accumulator.push(node);
    }
  };
  const files = Object.entries(procurementFiles.fileCategories)
    .map(([, fileCategory]) => fileCategory.children)
    .flat()
    .reduce((accumulator, current) => {
      handleFileNode(accumulator, current);
      return accumulator;
    }, [] as IProcurementFile[]);

  const uniqueFiles: IProcurementFile[] = [];
  files.forEach(file => {
    const isNotPresentedYet = !uniqueFiles.some(f => file.fileName === f.fileName);
    if (isNotPresentedYet) {
      uniqueFiles.push(file);
    }
  });
  return uniqueFiles;
}

export function calTermOccurrence(filteredDocuments: IHandledDocument[]): { map: Map<string, number>; sum: number } {
  const map = new Map<string, number>();
  let sum = 0;
  for (const document of filteredDocuments) {
    for (const part of document.parts) {
      for (const occurrence of part.occurrences) {
        const term = occurrence.term;
        const termNumber = (map.get(term) || 0) + 1;
        map.set(term, termNumber);
      }
      for (const occurrence of part.headlineOccurrences) {
        const term = occurrence.term;
        const termNumber = (map.get(term) || 0) + 1;
        map.set(term, termNumber);
      }
    }
  }
  for (const [, value] of map) {
    sum += value;
  }
  return { map, sum };
}
