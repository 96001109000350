import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

interface IProps {
  children: ReactNode;
  visibility: boolean | number;
  visibleHeight?: number;
  onChange?: () => void;
  showMoreTitle?: string;
  isListExpandable?: boolean;
  isDescription?: boolean;
  buttonClassName?: string;
}

export const Expand: FC<IProps> = props => {
  const { children, onChange, visibility, showMoreTitle, isListExpandable, isDescription, buttonClassName } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [btnVisible, setBtnVisible] = useState(false);
  const [textHeight, setTextHeight] = useState(0);

  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const showMoreText = !!showMoreTitle ? showMoreTitle : t('Common.showMore');

  useEffect(() => {
    setIsVisible(false);
    const textBlockHeight = ref.current && ref.current.offsetHeight;
    if (typeof visibility === 'number') {
      if (textBlockHeight) {
        setTextHeight(textBlockHeight);
        setBtnVisible(textBlockHeight > visibility);
      }
    } else {
      setBtnVisible(visibility);
    }
  }, [visibility, children]);

  const toggleVisible = useCallback(() => {
    !!onChange && onChange();
    setIsVisible(!isVisible);
  }, [isVisible, onChange]);

  return (
    <div className={styles.expand}>
      <div
        className={classNames(styles.expandContent, isDescription && btnVisible && !isVisible ? styles.isBlur : '')}
        style={{
          maxHeight: isVisible && typeof visibility === 'number' ? `unset` : `${visibility}px`
        }}
      >
        <div ref={ref} className={classNames(styles.content, { [styles.isListExpandable]: isListExpandable })}>
          {children}
        </div>
        {isListExpandable && (
          <div className={classNames(styles.container, { [styles.isLimited]: !isVisible && textHeight > visibility })}>
            {children}
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        {btnVisible && (
          <Button
            className={classNames(buttonClassName, styles.button)}
            onClick={toggleVisible}
            type={'link'}
            icon={
              <FontAwesomeIcon
                icon={faAngleDown}
                className={classNames(styles.expandActionIcon, { [styles.isExpanded]: isVisible })}
              />
            }
          >
            {isVisible ? t('Common.showLess') : showMoreText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Expand;
