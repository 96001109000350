import React, { FC, useCallback, useMemo } from 'react';
import { Menu } from 'src/common';
import { useTranslation } from 'react-i18next';
import { useBidReportUrl, useOverviewBackLink, usePreviewTabState, useTenderUrl } from 'src/models/procurements/hooks';
import { ActivePreviewTab } from 'src/models/procurements/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faPaperclip, faEye, faFileSearch, faAward } from '@fortawesome/pro-light-svg-icons';
import previewStyles from 'src/shared/InfoSidebar/index.module.scss';
import classNames from 'classnames';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { Link } from 'react-router-dom';
import styles from '../Info/index.module.scss';
import { BidItemType } from '@tendium/prom-types/tender';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';

export const BidPreviewNav: FC = () => {
  const { t } = useTranslation();
  const { data } = useBidPreview();
  const bidId = data?.id;
  const mailsQty = useMemo(() => (data ? data.mails.length : 0), [data]);
  const [activeTab, setActiveTab] = usePreviewTabState();

  const origin = data?.origin;
  const tenderId = origin?.originType === BidItemType.Procurement ? origin.id : undefined;
  const tenderUrl = useTenderUrl(tenderId || '');
  const bidReportUrl = useBidReportUrl(bidId || '');

  const isAwardDataFeatureAvailable = useFeatureFlag(FeatureFlag.AwardData);

  const onChangeTab = useCallback(
    ({ key }: { key: string }) => {
      setActiveTab(key as ActivePreviewTab);
    },
    [setActiveTab]
  );
  const { set: setBackLink } = useOverviewBackLink();

  return (
    <div className={previewStyles.container}>
      <Menu mode={'horizontal'} onClick={onChangeTab} selectedKeys={[activeTab]} className={previewStyles.navTabs}>
        <Menu.Item key={ActivePreviewTab.Info} className={previewStyles.navTab}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faEye} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Tenders.overview')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key={ActivePreviewTab.Comments} className={previewStyles.navTab} disabled={!bidId}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faCommentDots} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Comments.comments')}</span>
          </span>
        </Menu.Item>
        {isAwardDataFeatureAvailable && tenderId && (
          <Menu.Item key={ActivePreviewTab.Outcome} className={previewStyles.navTab}>
            <span className={previewStyles.navTab}>
              <FontAwesomeIcon icon={faAward} className={previewStyles.navTabIcon} />
              <span className={previewStyles.navTabLabel}>{t('Tenders.outcomeTab')}</span>
            </span>
          </Menu.Item>
        )}
        <Menu.Item
          key={ActivePreviewTab.Email}
          className={classNames(previewStyles.navTab, { [previewStyles.isDisabled]: mailsQty === 0 })}
          disabled={mailsQty === 0}
        >
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faPaperclip} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>
              {t('Common.attachments')} {mailsQty > 0 && `(${mailsQty})`}
            </span>
          </span>
        </Menu.Item>
      </Menu>

      <Link
        className={styles.reportButton}
        to={{ pathname: tenderId ? tenderUrl : bidReportUrl }}
        onClick={setBackLink}
      >
        <FontAwesomeIcon icon={faFileSearch} className={classNames(styles.reportIcon)} />
        <span className={styles.reportLabel}>{t('Tenders.openReport')}</span>
      </Link>
    </div>
  );
};

export default BidPreviewNav;
