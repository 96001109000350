import { FieldPolicy } from '@apollo/client';
import { isNotUndefined } from 'src/helpers';
import { AdminCompaniesCached, AdminCompaniesDataCached } from './types';

export function getAdminCompaniesPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['searchOptions', ['id', 'name'], 'sortBy']],
    read(existing: AdminCompaniesDataCached) {
      if (!existing) {
        return;
      }
      if (existing) {
        return existing.value;
      }
    },
    merge(
      existing: AdminCompaniesDataCached,
      incoming: AdminCompaniesCached,
      { variables, readField, canRead, isReference }
    ) {
      if (!existing) {
        return { value: incoming, variables };
      }
      if (incoming?.companies.length === 0) {
        return { value: { ...existing.value, count: incoming.count }, variables };
      }
      if (!!incoming?.companies.length) {
        const merged =
          existing && !!existing.value.companies.length
            ? [
                ...existing.value.companies,
                ...incoming.companies.filter(company => canRead(company) && isReference(company))
              ].filter(isNotUndefined)
            : [];
        return {
          value: {
            ...incoming,
            companies: [
              ...new Map(merged.map(company => [readField({ fieldName: 'id', from: company }), company])).values()
            ]
          },
          variables
        };
      }
    }
  };
}
