import React, { FC, useMemo, useCallback, useState } from 'react';
import styles from './index.module.scss';
import { getIconByFileType } from 'src/helpers/fileTypeIcon';
import { faCloudDownload, faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { IInboxEmailAttachment } from 'src/models/callOffs/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentViewer } from 'src/shared';
import { downloadFile, splitFileExt } from 'src/helpers/files';
import { trackViewDocumentPreview } from 'src/segment/events';

interface IProps {
  attachment: IInboxEmailAttachment;
  eventSource: string;
  procurementId?: string;
}

export const AttachmentItem: FC<IProps> = props => {
  const { attachment, eventSource, procurementId } = props;
  const { fileName, getUrl: url } = attachment;
  const [, fileExt] = splitFileExt(fileName);

  const extIcon = useMemo(() => {
    return getIconByFileType(fileExt, faPaperclip);
  }, [fileExt]);

  const onDownload = useCallback(() => {
    downloadFile(url, fileName);
  }, [url, fileName]);

  const [modalVisible, setModalVisible] = useState(false);

  const onShowModal = useCallback(() => {
    setModalVisible(true);
    trackViewDocumentPreview(eventSource, procurementId ? [procurementId] : undefined);
  }, [eventSource, procurementId]);

  const onHideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <>
      <li className={styles.attachment}>
        <button className={styles.downloadButton} onClick={onDownload}>
          <FontAwesomeIcon icon={faCloudDownload} />
        </button>

        <button className={styles.button} onClick={fileExt === 'pdf' ? onShowModal : onDownload}>
          <span className={styles.docsIcon}>{extIcon}</span>
          <span className={styles.docsName}>{fileName}</span>
        </button>
      </li>
      {modalVisible && <DocumentViewer onClose={onHideModal} url={url} title={fileName} />}
    </>
  );
};

export default AttachmentItem;
