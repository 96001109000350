import { gql } from '@apollo/client';

export const NOTIFICATION_SETTINGS_GROUP = gql`
  fragment notificationSettingsGroupField on NotificationSettingGroup {
    name
    isActive
    notificationSettings {
      name
      itemId
      isActive
      notificationSettingValues {
        mode
        value
        schedule
      }
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query getNotificationSettings {
    getMe {
      account {
        email
        notificationSettingGroups {
          ...notificationSettingsGroupField
        }
      }
    }
  }
  ${NOTIFICATION_SETTINGS_GROUP}
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateNotificationSettings(
    $name: NotificationType!
    $mode: NotificationForm!
    $value: Boolean!
    $itemId: String
    $schedule: ExpiringContractSendoutsSchedule
  ) {
    updateNotificationSettings(
      input: { name: $name, mode: $mode, value: $value, itemId: $itemId, schedule: $schedule }
    ) {
      email
      notificationSettingGroups {
        ...notificationSettingsGroupField
      }
    }
  }
  ${NOTIFICATION_SETTINGS_GROUP}
`;
