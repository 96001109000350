import { XYCoord, useDragLayer } from 'react-dnd';
import { CSSProperties } from 'react';

export const getStyle = (clientOffset: XYCoord): CSSProperties => {
  const transform = `translate(${clientOffset.x}px, ${clientOffset.y}px)`;
  return { pointerEvents: 'none', position: 'fixed', top: 0, left: 0, transform, WebkitTransform: transform };
};

export const useDragPreview = (): {
  display: boolean;
  item: unknown;
  style: CSSProperties | undefined;
} => {
  const { isDragging, item, clientOffset } = useDragLayer(monitor => {
    return {
      clientOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
      item: monitor.getItem()
    };
  });

  if (!isDragging || clientOffset === null) {
    return { display: false, item: undefined, style: undefined };
  }

  return {
    display: true,
    item,
    style: getStyle(clientOffset)
  };
};
