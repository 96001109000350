import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { isUser, AssignedTo } from 'src/models/users/types';

export class MentionsMenuOption extends MenuOption {
  user: AssignedTo;

  constructor(user: AssignedTo) {
    const name = isUser(user) && user.userName ? user.userName : user.name ?? '';
    super(name);
    this.user = user;
  }
}
