import React, { FC, useCallback, useEffect, useRef } from 'react';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  BLUR_COMMAND,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND
} from 'lexical';
import classNames from 'classnames';
import { MENTION_CLASSNAME, MENTION_SELECTED_CLASSNAME, MentionData } from './types';
import { $isMentionNode } from './MentionNode';

interface Props {
  nodeKey: string;
  data: MentionData;
}

const MentionComponent: FC<Props> = ({ nodeKey, data }) => {
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  const ref = useRef<HTMLElement>(null);

  const onClick = useCallback(
    (event: MouseEvent) => {
      if (event.target === ref.current) {
        clearSelection();
        setSelected(true);
        return true;
      }
      return false;
    },
    [clearSelection, setSelected]
  );

  const onBlur = useCallback(() => {
    if (isSelected && $isNodeSelection($getSelection())) {
      clearSelection();
    }
    return false;
  }, [clearSelection, isSelected]);

  const onDelete = useCallback(
    (event: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if ($isMentionNode(node)) {
          node.remove();
        }
      }
      return false;
    },
    [isSelected, nodeKey]
  );

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
      editor.registerCommand(BLUR_COMMAND, onBlur, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW)
    );
    return () => {
      unregister();
    };
  }, [editor, onBlur, onClick, onDelete]);

  return (
    <span ref={ref} className={classNames(MENTION_CLASSNAME, { [MENTION_SELECTED_CLASSNAME]: isSelected })}>
      {data.text}
    </span>
  );
};

export default MentionComponent;
