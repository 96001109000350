import { gql } from '@apollo/client';

export const SSO_CONFIG_FIELDS = gql`
  fragment ssoConfigFields on SSOConfiguration {
    emailDomain
    company {
      id
    }
    enabled
    enforceSSO
    allowNewUserCreationFromSSO
    provider
  }
`;

export const UPDATE_MY_MFA_MODE = gql`
  mutation updateMFAMode($email: String!, $mfaMode: MFAMode) {
    updateMFAMode(input: { id: $email, mfaMode: $mfaMode }) {
      email
      mfaMode
    }
  }
`;

export const GET_GROUP_ID = gql`
  query ($email: String!) {
    getSSOGroup(email: $email)
  }
`;

export const GET_COMPANY_SSO_CONFIG = gql`
  query getCompanySSOConfiguration {
    getCompanySSOConfiguration {
      ...ssoConfigFields
    }
  }
  ${SSO_CONFIG_FIELDS}
`;

export const CREATE_SSO_CONFIG = gql`
  mutation createSSOConfiguration(
    $enabled: Boolean
    $enforceSSO: Boolean
    $allowNewUserCreationFromSSO: Boolean
    $provider: String
  ) {
    createSSOConfiguration(
      updateParams: {
        enabled: $enabled
        enforceSSO: $enforceSSO
        allowNewUserCreationFromSSO: $allowNewUserCreationFromSSO
        provider: $provider
      }
    ) {
      ...ssoConfigFields
    }
  }
  ${SSO_CONFIG_FIELDS}
`;

export const UPDATE_SSO_CONFIG = gql`
  mutation updateSSOConfiguration(
    $enabled: Boolean
    $enforceSSO: Boolean
    $allowNewUserCreationFromSSO: Boolean
    $provider: String
  ) {
    updateSSOConfiguration(
      updateParams: {
        enabled: $enabled
        enforceSSO: $enforceSSO
        allowNewUserCreationFromSSO: $allowNewUserCreationFromSSO
        provider: $provider
      }
    ) {
      ...ssoConfigFields
    }
  }
  ${SSO_CONFIG_FIELDS}
`;

export const DELETE_SSO_CONFIG = gql`
  mutation deleteSSOConfiguration($emailDomain: String!) {
    deleteSSOConfiguration(emailDomain: $emailDomain) {
      ...ssoConfigFields
    }
  }
  ${SSO_CONFIG_FIELDS}
`;

export const CHECK_USER_LOGGED_TROUGH_SSO = gql`
  query checkSsoLoginUser {
    checkIfUserLoggedInThroughSSO
  }
`;

export const GET_SSO_ENTITY_ID_AND_REPLY_URL = gql`
  query getSsoEntityIdAndReplyUrl {
    getSsoEntityIdAndReplyUrl {
      entityId
      replyUrl
    }
  }
`;

export const SETUP_AZURE_SSO = gql`
  mutation setupAzureSso($samlMetaDataUrl: String!) {
    setupAzureSamlSso(samlMetaDataUrl: $samlMetaDataUrl) {
      ...ssoConfigFields
    }
  }
  ${SSO_CONFIG_FIELDS}
`;

export const FETCH_COMPANY_ADMIN = gql`
  query getEmployees($companyId: String) {
    getCompany(input: $companyId) {
      createdBy {
        email
        isAdmin
      }
    }
  }
`;
