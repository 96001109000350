import React, { FC } from 'react';
import { Expand } from 'src/common';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Translations } from 'src/lib/I18nService/types';
import { BlockKeyValue } from 'src/shared/Blocks/KeyValue';

interface IProps {
  nutsCodes: string[];
}
export const NutsCodes: FC<IProps> = ({ nutsCodes }) => {
  const { t: nuts_t } = useTranslation([Translations.nutsCodes, Translations.country]);
  const { t } = useTranslation();

  return (
    <Expand visibility={91} isListExpandable showMoreTitle={t('Common.showAll')}>
      <div className={styles.list}>
        {nutsCodes.map(value => (
          <BlockKeyValue key={value} name={nuts_t(value)} value={value} />
        ))}
      </div>
    </Expand>
  );
};

export default NutsCodes;
