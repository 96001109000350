import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const EMAIL_TRANSITION = gql`
  mutation emailTransition($url: String!) {
    emailTransition(url: $url)
  }
`;

export function useTrackEntryWithUTMSource(): [(url: string) => void, { loading: boolean; error: Error | undefined }] {
  const [trackEntry, { loading, error }] = useMutation<{ emailTransition: boolean }, { url: string }>(EMAIL_TRANSITION);
  const trackEntryFn = useCallback(
    (url: string) => {
      trackEntry({
        variables: { url }
      }).catch(err => {
        console.error(err);
      });
    },
    [trackEntry]
  );
  return [trackEntryFn, { loading, error }];
}
