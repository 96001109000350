import { gql } from '@apollo/client';
import { USER_NAME_FIELDS } from '../users/queries';

export const COMMENT_FIELDS = gql`
  fragment commentFields on ProcurementComment {
    id
    parentId
    parentType
    content
    createdAt
    isRead
    user {
      ... on User {
        ...userNameFields
      }
      ... on GuestUser {
        email
      }
    }
    sharedWith {
      email
    }
  }
  ${USER_NAME_FIELDS}
`;

export const CREATE_COMMENT = gql`
  mutation createComment(
    $id: String!
    $content: String!
    $parentId: String!
    $mentions: [String!]!
    $previousMentions: [String!]!
    $shareWith: [String!]!
    $parentType: ProcurementCommentParentType!
  ) {
    createProcurementComment(
      input: {
        id: $id
        content: $content
        parentId: $parentId
        mentions: $mentions
        previousMentions: $previousMentions
        shareWith: $shareWith
        parentType: $parentType
      }
    ) {
      ...commentFields
    }
  }
  ${COMMENT_FIELDS}
`;

export const MARK_COMMENT_AS_READ = gql`
  mutation markProcurementCommentAsRead($commentId: String!) {
    markProcurementCommentAsRead(input: { procurementCommentId: $commentId }) {
      ...commentFields
    }
  }
  ${COMMENT_FIELDS}
`;

export const DELETE_COMMENT = gql`
  mutation deleteProcurementComment($id: String!) {
    deleteProcurementComment(input: { id: $id })
  }
`;

export const ON_NEW_COMMENT = gql`
  subscription commentAdded {
    commentAdded
  }
`;
export const GET_COMMENT = gql`
  query ($commentId: String!) {
    getComment(commentId: $commentId) {
      ...commentFields
    }
  }
  ${COMMENT_FIELDS}
`;
export const COMMENT_COUNT_FIELDS = gql`
  fragment commentCountFields on GetCommentsCountResult {
    parentId
    count
  }
`;
export const GET_COMMENTS_COUNT = gql`
  query getCommentsCount($ids: [String!]!, $parentType: ProcurementCommentParentType!) {
    getCommentsCount(input: { parentIds: $ids, parentType: $parentType }) {
      ...commentCountFields
    }
  }
  ${COMMENT_COUNT_FIELDS}
`;
export const GET_PARENT_COMMENTS = gql`
  query getComments($parentId: String!, $parentType: ProcurementCommentParentType!) {
    getComments(input: { parentId: $parentId, parentType: $parentType }) {
      ...commentFields
    }
  }
  ${COMMENT_FIELDS}
`;
