import React, { FC, useMemo, useCallback } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import removeMarkdown from 'remove-markdown';
import ReactMarkdown from 'react-markdown';
import dayjs from 'src/helpers/dayjs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { INotification } from 'src/models/notifications/types';
import { useMarkNotificationAsRead, getUpdateCacheOnMarkNotificationAsRead } from 'src/models/notifications/hooks';
import { useMe, UserName, notification as notificationAlert, Tooltip } from 'src/common';
import { NOTIFICATION_ICON_MAPPER } from 'src/models/notifications/mappers';

interface IProps {
  showMarkDown?: boolean;
  notification: INotification;
  onClose?: () => void;
}

export const NotificationsItem: FC<IProps> = props => {
  const { notification, showMarkDown, onClose } = props;
  const { createdAt, type, title, body, url, createdBy, isRead } = notification;
  const { data: meData } = useMe();
  const email = meData?.email;
  const { t } = useTranslation();
  const [markNotificationAsRead] = useMarkNotificationAsRead();

  const onNotificationClick = useCallback(() => {
    email &&
      markNotificationAsRead({
        variables: { email, createdAt },
        update: getUpdateCacheOnMarkNotificationAsRead(createdAt)
      }).catch(() => {
        notificationAlert.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
  }, [createdAt, email, markNotificationAsRead, t]);

  const translateTitle = (title: string): string => {
    const splitTitleIdentifier = [
      'New procurements',
      'Tagged in a comment',
      'You were tagged in a comment',
      'You are tagged in a comment',
      'You have been assigned to a procurement',
      'New tender opportunities'
    ];
    let translatedTitle = title;
    splitTitleIdentifier.forEach(element => {
      if (title === element) {
        translatedTitle = t(`Notifications.${element}`);
      }
      if (translatedTitle.includes(element)) {
        translatedTitle = translatedTitle.startsWith(element)
          ? `${t(`Notifications.${element}`)} ` + translatedTitle.split(element)[1]
          : `${translatedTitle.split(element)[0]} ${t(`Notifications.${element}`)} ` +
            translatedTitle.split(element)[1];
      }
    });
    return translatedTitle;
  };

  const date = useMemo(() => dayjs(createdAt).utc().toISOString(), [createdAt]);

  const relativeDate = useMemo((): string => {
    const currentDate = dayjs();
    const createdDate = dayjs(createdAt);
    const days = Math.abs(currentDate.diff(createdDate, 'day'));

    if (days < 7) {
      const hours = currentDate.diff(createdDate, 'hour');
      const minutes = currentDate.diff(createdDate, 'minute');
      if (minutes < 60) {
        return `${minutes}min`;
      }
      if (hours < 24) {
        return `${hours}h`;
      }
      return `${days}d`;
    } else if (days < 30) {
      return `${currentDate.diff(createdDate, 'week')}w`;
    } else if (days < 365) {
      return `${currentDate.diff(createdDate, 'month')}m`;
    }
    return `${currentDate.diff(createdDate, 'year')}y`;
  }, [createdAt]);

  // FIXME: should be removed when we switch to v2 notifications data format :/
  const tooltipBody = useMemo(() => body.split('>')[0], [body]);

  const notificationBody = (
    <div className={styles.itemInner}>
      <div className={styles.pic}>
        {createdBy ? (
          <UserName mode={'onlyAvatar'} user={createdBy} size={'l'} />
        ) : (
          <span className={styles.avatar}></span>
        )}
        <span className={styles.type}>
          <FontAwesomeIcon icon={NOTIFICATION_ICON_MAPPER[type].defaultIcon} className={styles.typeIcon} />
        </span>
      </div>
      <div className={styles.content}>
        <span className={styles.title}>{translateTitle(title)}</span>
        <Tooltip title={<ReactMarkdown>{tooltipBody}</ReactMarkdown>} mouseEnterDelay={0.6}>
          <span className={styles.desc}>
            {!!showMarkDown ? <ReactMarkdown>{body}</ReactMarkdown> : removeMarkdown(body)}
          </span>
        </Tooltip>
      </div>
      <div className={styles.meta}>
        <span className={styles.date}>
          <time dateTime={date}>{relativeDate}</time>
        </span>
        {!isRead && <span className={styles.statusIcon}></span>}
      </div>
    </div>
  );

  return (
    <li onClick={onNotificationClick} className={styles.item}>
      {url ? (
        <Link to={url} onClick={onClose}>
          {notificationBody}
        </Link>
      ) : (
        notificationBody
      )}
    </li>
  );
};

export default NotificationsItem;
