import { SupplierSearchTerm } from 'src/models/matchingProfiles/types';
import {
  ApiTendersItem,
  IdentifiableTenderObject,
  SupplierContractObject,
  MappedTendersTypes,
  SupplierOrg,
  SupplierString,
  TendersFilterStatus,
  TendersItem,
  TendersVars
} from './types';
import {
  ContractEndRangeMode,
  ContractEndSearchType,
  ExpiringContractsTier,
  SearchOnlyStatus,
  SupplierSearchType
} from '@tendium/prom-types';
import { MatchingProfile } from 'src/models/matchingProfiles';
import { AnalyticsTenderStatus } from '@tendium/prom-types/schema';
import dayjs from 'src/helpers/dayjs';
import { IApiOrganization } from '../../organisations';

export function isSearchApplied<T extends TendersVars>(filter: T, initFilter: T): boolean {
  const cpvCodesApplied = !!filter.cpvCodes && !!filter.cpvCodes.length;
  const nutCodesApplied = !!filter.nutsCodes && !!filter.nutsCodes.length;
  const deadlineApplied = !!filter.deadline;
  const publishedApplied = !!filter.published;
  const assignedToApplied = !!filter.assignedTo && !!filter.assignedTo.length;
  const statusApplied = filter.onlyStatus !== initFilter.onlyStatus;
  return (
    !!filter.search ||
    cpvCodesApplied ||
    nutCodesApplied ||
    deadlineApplied ||
    publishedApplied ||
    !!filter.name ||
    assignedToApplied ||
    statusApplied ||
    !!filter.awarded
  );
}

export function toSupplierStrings(terms?: SupplierSearchTerm[]): SupplierString[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.SearchStrings)
    .map(({ value, status }) => ({ orgName: value, status }));
}
export function toSupplierIds(terms?: SupplierSearchTerm[]): SupplierOrg[] | undefined {
  return terms
    ?.filter(term => term.searchType === SupplierSearchType.OrgIds)
    .map(({ value, status }) => ({ orgId: value, status }));
}

export function getNextOffset(length: number, total: number, pageSize: number): { offset: number } {
  return {
    offset: Math.min(Math.floor(length / pageSize) * pageSize, total)
  };
}

export function getQueryParamsFromTenderStatus(status?: TendersFilterStatus): {
  onlyStatus?: SearchOnlyStatus;
  awarded?: boolean;
} {
  const defaultParams = {
    onlyStatus: undefined,
    awarded: undefined
  };
  if (!status) return defaultParams;
  switch (status) {
    case TendersFilterStatus.all:
      return {
        onlyStatus: undefined,
        awarded: undefined
      };
    case TendersFilterStatus.awarded:
      return {
        onlyStatus: undefined,
        awarded: true
      };
    case TendersFilterStatus.expired:
      return {
        onlyStatus: SearchOnlyStatus.Expired,
        awarded: undefined
      };
    case TendersFilterStatus.recent:
      return {
        onlyStatus: SearchOnlyStatus.Recent,
        awarded: undefined
      };
    default:
      return defaultParams;
  }
}

export function checkExpiringContracts<T extends keyof MappedTendersTypes>(type: T): boolean {
  return type === 'ExpContract' || type === 'SupplierProfile' || type === 'BuyerProfile';
}

export function toEcParams(
  type: keyof MappedTendersTypes,
  initVars: TendersVars,
  mp?: MatchingProfile,
  expiringContractsTier?: ExpiringContractsTier
): Partial<TendersVars> {
  const supplierOrgIds = toSupplierIds(mp?.supplierSearchTerms);
  const supplierStrings = toSupplierStrings(mp?.supplierSearchTerms);
  const supplierRelatedParams = {
    supplierOrgIds: !!supplierOrgIds?.length ? supplierOrgIds : undefined,
    supplierSearchStrings: !!supplierStrings?.length ? supplierStrings : undefined,
    supplierSearchLogic: mp?.supplierSearchLogic
  };
  const buyerRelatedParams = {
    buyerOrgIds: !!mp?.buyerOrgIds ? mp?.buyerOrgIds : undefined,
    buyerSearchStrings: !!mp?.buyerSearchStrings?.length ? mp.buyerSearchStrings : undefined
  };

  const commonParams = mp
    ? {
        profileId: mp.id,
        ...initVars,
        cpvCodes: !!mp?.cpvCodes?.length ? mp.cpvCodes : undefined,
        cpvCodesExact: !!mp?.cpvCodesExact?.length ? mp.cpvCodesExact : undefined,
        nutsCodes: !!mp?.nutsCodes?.length ? mp.nutsCodes : undefined,
        nutsCodesExact: !!mp?.nutsCodesExact?.length ? mp.nutsCodesExact : undefined,
        filterKeywordLanguages: mp?.filterKeywordLanguages,
        highlightKeywordLanguages: mp?.highlightKeywordLanguages,
        keywordWidth: mp?.keywordWidth,
        keywordGroups: mp?.apiKeywordGroups
          ? mp?.apiKeywordGroups?.map(group => ({
              values: group.values.map(keyword => keyword.value),
              searchType: group.searchType,
              searchLogic: group.searchLogic,
              filterLogic: group.filterLogic,
              highlightColor: group.highlightColor,
              groupId: group.groupId
            }))
          : undefined,
        keywordArgs:
          mp?.apiKeywords?.map(keyword => ({
            value: keyword.value,
            searchType: keyword.searchType,
            searchLogic: keyword.searchLogic,
            filterLogic: keyword.filterLogic,
            highlightColor: keyword.highlightColor
          })) ?? undefined,
        procuringAgencyTypes: !!mp?.buyerTypes?.length ? mp.buyerTypes : undefined,
        contractValueRange:
          mp?.contractValueCurrency || mp?.contractValueMax || mp?.contractValueMin
            ? {
                currency: mp?.contractValueCurrency,
                max: mp?.contractValueMax,
                min: mp?.contractValueMin
              }
            : undefined,
        contractValueExcludeMissingValue: mp?.contractValueExcludeMissingValue
      }
    : {};

  switch (type) {
    case 'ExpContract':
      return mp
        ? {
            ...commonParams,
            ...buyerRelatedParams,
            ...supplierRelatedParams,
            contractEnd: expiringContractsTier === ExpiringContractsTier.Light ? initVars?.contractEnd : mp?.contractEnd
          }
        : initVars;

    // Prioritize filters on the page
    case 'SupplierProfile':
      // - Should use contractEnd, partyFilter from initVars
      // - Should not include supplier related params
      return mp
        ? {
            ...commonParams,
            ...buyerRelatedParams
          }
        : initVars;
    case 'BuyerProfile':
      // - Should use contractEnd, partyFilter from initVars
      // - Should not include buyer related params
      return mp
        ? {
            ...commonParams,
            ...supplierRelatedParams
          }
        : initVars;
    default:
      return initVars;
  }
}

export function fromTenderStatusToGetTenderVars(status?: AnalyticsTenderStatus): Partial<TendersVars> {
  switch (status) {
    case 'ACTIVE':
      const start = dayjs().utc().startOf('day');

      // the end date is converted to contract start date strictly less than input.end (need to cover whole day)
      const end = start.add(1, 'day');

      return {
        contractEnd: {
          searchMode: ContractEndSearchType.PeriodOverlap,
          rangeMode: ContractEndRangeMode.ABSOLUTE,
          start: start.toDate(),
          end: end.toDate(),
          strictPeriodOverlap: true
        }
      };
    case 'ALL':
    default:
      return {
        contractEnd: undefined
      };
  }
}

export function toSupplierContractObjects(
  tenders: TendersItem<ApiTendersItem>[],
  suppliers: IApiOrganization[],
  excludeTenderIds?: string[]
): SupplierContractObject[] {
  const supplierMap: { [key: string]: IdentifiableTenderObject[] } = {};
  tenders.forEach(({ id, tender, parties }) => {
    parties.forEach(party => {
      if (party.roles?.find(role => role === 'Supplier') && party.identifier?.id) {
        if (!supplierMap[party.identifier?.id]) {
          supplierMap[party.identifier?.id] = [];
        }
        if (!supplierMap[party.identifier?.id].some(d => id === d.id) && !excludeTenderIds?.includes(id)) {
          supplierMap[party.identifier?.id].push({ id, tender });
        }
      }
    });
  });

  return Object.entries(supplierMap).reduce<SupplierContractObject[]>((acc, [supplierId, tenders]) => {
    const supplier = suppliers.find(s => s.organisationNumber === supplierId);
    if (supplier && tenders.length > 0) {
      acc.push({ supplier, tenders, contractCount: tenders.length });
    }
    return acc;
  }, []);
}
