import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { Skeleton } from 'antd';
import { AssignedTo } from 'src/models/users/types';
import Assignee from 'src/shared/Assignee';

export interface MemberListProps {
  owners: AssignedTo[] | undefined;
  members: AssignedTo[] | undefined;
  onClick?: () => void;
  loading?: boolean;
  size?: 's' | 'xs' | 'm' | 'l' | 'xl' | undefined;
  availableCount?: number;
}
export const MemberList: FC<MemberListProps> = ({ availableCount = 12, ...props }) => {
  const { owners, members, onClick, loading, size } = props;

  const memberList = useMemo(() => {
    const ownersLen = owners?.length || 0;
    return members && members.slice(0, availableCount - ownersLen);
  }, [availableCount, owners?.length, members]);

  const label = useMemo(() => {
    const ownersLen = owners?.length || 0;
    const membersLen = members?.length || 0;
    const len = ownersLen + membersLen;
    return len > availableCount ? len - availableCount : null;
  }, [availableCount, owners?.length, members?.length]);

  return (
    <div className={styles.container}>
      {loading && <Skeleton.Button active className={styles.skeleton} />}
      {!loading && (
        <>
          <div className={classNames(styles.unit, styles.ownersUnit)}>
            {owners &&
              owners.map(user => (
                <button onClick={onClick} className={styles.avatarButton} key={`owners_${user.id}`}>
                  <Assignee
                    mode={'onlyAvatar'}
                    className={classNames(styles.avatar, styles.borderedAvatar)}
                    showTooltip
                    assignedToData={user}
                    size={size}
                  />
                </button>
              ))}
          </div>
          <div className={classNames(styles.unit, styles.memberListUnit)}>
            {memberList &&
              memberList.map(user => {
                return (
                  <button onClick={onClick} className={styles.avatarButton} key={`members_${user.id}`}>
                    <Assignee
                      mode={'onlyAvatar'}
                      className={classNames(styles.avatar, styles.borderedAvatar)}
                      showTooltip
                      assignedToData={user}
                      size={size}
                    />
                  </button>
                );
              })}

            {!!label && <div className={styles.count}>{`+${label}`}</div>}
          </div>
        </>
      )}
    </div>
  );
};

export default MemberList;
