export type StackSeries<T extends StackData> = {
  stacks: StackSerie<T>[];
  axises: StackAxises;
  width: number;
};

export type StackAccessor<T extends StackData, Output> = (d: StackSerie<T>) => Output;
export type BarAccessor<T extends StackData, Output> = (r: StackSerieRange, index: number, d: StackSerie<T>) => Output;

export type StackSerie<T extends StackData> = {
  id: string;
  label: string;
  ranges: StackSerieRange[];
  data: T;
  subLabel?: string;
};
export type StackSerieRange = { label: Range<Date>; value: Range<number> };
export type StackData = { id: string; dates: number[]; title: string };
export type StackConfig<T extends StackData> = {
  data: T[];
  parentWidth: number;
  startDate?: number;
  endDate?: number;
  focusDate?: number;
  isResponsive?: boolean;
  scale?: 1 | 2 | 3 | 4;
};
export type AxisUnit = /*'week' |*/ 'month' | 'quarter' | 'year';
export type AxisTick = { label: Date; range: Range<number> };
export type StackAxises = Record<Partial<AxisUnit>, AxisTick[]>;

export type Range<T> = { from: T; to: T };

export const MIN_M_WIDTH = 25;
export const MIN_Q_WIDTH = 60;

export const LEFT_SIDEBAR = 340;
export const MONTH_WIDTH = 30;
