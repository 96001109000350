import { AssignedTo } from '../users/types';

export interface IAccessControl {
  editPolicy: AccessControlPolicy;
  subscribePolicy: AccessControlPolicy;
}

export interface IMember {
  user: AssignedTo;
  isOwner: boolean;
}

export enum AccessControlPolicy {
  ALL = 'ALL',
  ONLY_ADDED = 'ONLY_ADDED'
}
