import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlainDropDown, WorkspaceBadge } from 'src/common';
import styles from './index.module.scss';
import { useBidspacesQpVars, useBidsFilterVars, useResetBidsPage } from 'src/models/bids/Bids/hooks';
import { useActiveWorkspaces } from 'src/models/workspace/hooks';
import { trackABFilter } from 'src/segment/events/allBidspaces';

const BidspacesFilterBidspace: FC = () => {
  const { t } = useTranslation();
  const currentVars = useBidspacesQpVars();
  const { updateVars } = useBidsFilterVars();
  const resetPage = useResetBidsPage();
  const { data } = useActiveWorkspaces();

  const bidspaces = useMemo(() => {
    return data
      ? data?.map(workspace => {
          return {
            label: (
              <span className={styles.optionLabel}>
                <WorkspaceBadge title={workspace.name} color={workspace.color} mode={'short'} size={'s'} />
                {` ${workspace.name}`}
              </span>
            ),
            value: workspace.id
          };
        })
      : [];
  }, [data]);

  const selectedBidspaces = useMemo(() => (currentVars.bidspaceId ? currentVars.bidspaceId : undefined), [currentVars]);

  const onChange = useCallback(
    (bidspaceId: string, checked?: boolean) => {
      const previousValue = selectedBidspaces || [];
      const currentValue = checked ? [...previousValue, bidspaceId] : previousValue.filter(item => item !== bidspaceId);
      updateVars?.({ bidspaceId: currentValue });
      resetPage();

      if (checked) trackABFilter('Bidspace');
    },
    [selectedBidspaces, updateVars, resetPage]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedBidspaces?.length })}>
      <PlainDropDown
        values={bidspaces}
        selectedValue={selectedBidspaces}
        label={t('BidSpaces.Filters.bidspace')}
        onSelect={onChange}
        buttonClassName={styles.label}
        placement="bottomLeft"
        type="multiple"
        button
      />
    </div>
  );
};

export default BidspacesFilterBidspace;
