import React, { FC } from 'react';
import { Skeleton } from 'src/common';
import WorkspacesItem from './WorkspacesItem';
import { useWorkspaces } from 'src/models/workspace/hooks';

interface IProps {
  sidebarIsOpen?: boolean;
}

export const WorkspacesList: FC<IProps> = props => {
  const { sidebarIsOpen } = props;

  const { data, loading } = useWorkspaces();

  const workspaces = data && data.filter(ws => ws.isActive).sort((a, b) => a.name.localeCompare(b.name, 'sv'));

  return (
    <>
      {(!workspaces || loading) && <Skeleton active />}
      {workspaces &&
        workspaces
          .filter(ws => ws.isActive)
          .map(ws => <WorkspacesItem sidebarIsOpen={sidebarIsOpen} workspace={ws} key={ws.id} />)}
    </>
  );
};

export default WorkspacesList;
