import { gql } from '@apollo/client';
import { USER_NAME_FIELDS } from 'src/models/users/queries';

const FILE_CHECKLIST_ITEM_FIELDS = gql`
  fragment fileChecklistItemFields on ChecklistItem {
    id
    name
  }
`;

export const FILE_NODE_FIELDS = gql`
  fragment fileNodeFields on FileNode {
    id
    name
    nodeType
    createDateTime
    createdBy {
      ...userNameFields
    }
    getUrl
    attachedTo {
      ...fileChecklistItemFields
    }
    status
  }
  ${USER_NAME_FIELDS}
  ${FILE_CHECKLIST_ITEM_FIELDS}
`;

export const DIRECTORY_NODE_FIELDS = gql`
  fragment directoryNodeFields on DirectoryNode {
    id
    name
    nodeType
    createDateTime
    createdBy {
      ...userNameFields
    }
    children {
      ... on FileNode {
        id
      }
      ... on DirectoryNode {
        id
      }
    }
  }
  ${USER_NAME_FIELDS}
`;
