import React, { FC } from 'react';
import { LogoProps, ThemeMode } from 'src/models/ownership/types';

export const CliraLogoIcon: FC<LogoProps> = ({ mode = ThemeMode.light, isShort, className }) => {
  return isShort ? (
    <svg className={className} x="0px" y="0px" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M508.234 1024C380.019 1024.11 264.189 980.074 163.91 893.075C178.209 843.546 193.263 790.176 209.786 730.434C356.99 805.162 499.015 862.53 643.435 905.607C699.975 922.473 747.247 933.032 792.2 938.831C792.29 938.843 792.376 938.856 792.466 938.868C784.263 944.315 775.605 949.66 766.447 954.939C696.502 995.257 621.63 1018.12 543.899 1022.87C531.944 1023.61 519.944 1023.99 508.234 1024Z" />
        <path d="M831.354 877.253C787.547 876.131 745.317 866.612 706.195 856.484C610.329 831.656 508.86 795.664 386.86 743.203C334.386 720.636 280.697 695.301 227.156 667.839C259.754 553.391 292.236 441.483 329.892 330.247C444.157 292.117 558.335 259.356 665.124 229.621C741.946 382.849 799.784 529.727 841.855 678.345C857.524 733.677 866.154 776.566 869.836 817.408C871.421 834.929 871.163 848.993 869.025 861.681C867.186 872.612 866.146 873.661 855.112 875.701C847.707 877.073 839.762 877.171 831.354 877.253Z" />
        <path d="M113.394 840.705C55.1698 771.387 17.2492 691.346 0.537489 602.36C0.354091 601.382 0.174797 600.403 0 599.424C34.1082 629.613 72.3947 652.814 109.812 675.492L120.137 681.754C130.859 688.274 141.644 694.262 152.22 700.012C139.544 745.449 126.527 792.618 113.394 840.705Z" />
        <path d="M932.638 801.095C932.413 799.035 932.167 796.987 931.905 794.947C925.815 747.057 914.294 695.776 895.659 633.568C853.158 491.72 796.696 350.099 727.742 212.362C780.989 197.793 835.916 182.554 894.983 165.965C961.815 242.889 1002.97 330.558 1017.48 426.743C1037.92 562.292 1009.38 688.154 932.638 801.095Z" />
        <path d="M169.763 636.563C128.813 613.349 83.6129 586.068 43.613 552.685C30.4658 541.717 20.8787 531.957 13.4431 521.98C3.90832 509.181 3.76629 504.557 12.503 491.079C19.7018 479.971 30.0125 469.781 45.9332 458.039C86.3476 428.231 133.055 406.898 173.662 389.244C198.098 378.62 224.038 368.262 253.621 357.345C253.424 357.962 253.229 358.579 253.033 359.196C243.601 388.85 233.849 419.516 224.497 449.733C204.732 513.596 186.556 576.952 169.763 636.563Z" />
        <path d="M0.638672 412.555C35.4923 218.975 185.633 57.1951 377.023 8.24882C389.342 5.1031 401.672 2.40796 413.955 0.182617C407.241 8.49092 401.278 16.9328 395.834 25.0578C360.172 78.2667 335.11 137.057 314.42 187.983C300.785 221.541 289.613 251.426 280.263 279.347C280.145 279.701 280.041 279.977 279.955 280.189C279.752 280.274 279.491 280.374 279.162 280.488C221.225 300.506 152.99 325.548 86.195 359.524C57.5973 374.068 27.8728 390.513 0.638672 412.555Z" />
        <path d="M357.299 254.027C358.804 250.015 360.308 246.044 361.834 242.093C385.537 180.692 407.107 129.961 435.332 81.943C450.908 55.4447 465.333 34.5034 485.098 18.4661C487.719 16.3355 490.816 14.2237 494.305 12.169C498.872 9.48059 503.012 8.12523 506.613 8.12208C510.618 8.11859 514.796 9.82143 519.387 13.3455C533.39 24.1092 546.385 37.702 561.518 57.4384C589.705 94.1872 613.75 134.82 634.49 171.9C546.209 196.604 452.894 223.208 360.092 253.124C359.161 253.424 358.23 253.724 357.299 254.027Z" />
        <path d="M697.729 155.019C679.303 121.011 654.676 77.5702 624.835 36.0713C615.899 23.6542 606.88 11.4672 597.051 0C688.678 15.5087 769.726 53.6427 842.743 115.502C832.647 118.217 822.769 120.881 812.898 123.569C789.377 129.971 765.864 136.398 742.347 142.828L697.729 155.019Z" />
      </g>
    </svg>
  ) : (
    <svg width="896" height="301" viewBox="0 0 896 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M811.152 256C797.152 256 785.652 252.667 776.652 246C767.652 239.167 760.986 229.833 756.652 218C752.319 206.167 750.152 192.75 750.152 177.75C750.152 162.25 752.319 148.667 756.652 137C761.152 125.333 767.902 116.25 776.902 109.75C785.902 103.25 797.236 100 810.902 100C821.402 100 829.986 102.083 836.652 106.25C843.486 110.25 848.652 115.667 852.152 122.5H858.152V103H895.652V253H858.152V233.5H852.152C848.652 239.833 843.569 245.167 836.902 249.5C830.236 253.833 821.652 256 811.152 256ZM821.652 227.5C831.819 227.5 839.902 223.083 845.902 214.25C852.069 205.25 855.152 193.167 855.152 178C855.152 162.667 852.069 150.583 845.902 141.75C839.902 132.917 831.819 128.5 821.652 128.5C812.486 128.5 805.236 132.667 799.902 141C794.736 149.167 792.152 161.5 792.152 178C792.152 194.5 794.736 206.917 799.902 215.25C805.236 223.417 812.486 227.5 821.652 227.5Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#110D40' }}
      />
      <path
        d="M662.152 253V103H699.152V130.5H705.152C706.152 125.5 707.986 120.833 710.652 116.5C713.486 112 717.736 108.417 723.402 105.75C729.069 102.917 736.652 101.5 746.152 101.5H754.152V138H743.152C728.986 138 718.652 141.583 712.152 148.75C705.819 155.917 702.652 166.667 702.652 181V253H662.152Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#110D40' }}
      />
      <path
        d="M600.016 253V103H640.516V253H600.016ZM620.266 85.75C613.099 85.75 607.682 83.9167 604.016 80.25C600.349 76.4167 598.516 71.5 598.516 65.5C598.516 59.5 600.349 54.6667 604.016 51C607.682 47.1667 613.099 45.25 620.266 45.25C627.432 45.25 632.849 47.1667 636.516 51C640.182 54.6667 642.016 59.5 642.016 65.5C642.016 71.5 640.182 76.4167 636.516 80.25C632.849 83.9167 627.432 85.75 620.266 85.75Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#110D40' }}
      />
      <path
        d="M548.75 253C544.083 247.667 540.75 241.667 538.75 235C536.917 228.167 536 219.25 536 208.25V53H576.5V204C576.5 216.667 577.917 226.583 580.75 233.75C583.583 240.75 587.333 246.667 592 251.5V253H548.75Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#110D40' }}
      />
      <path
        d="M442.25 256.75C413.917 256.75 391.917 247.833 376.25 230C360.75 212.167 353 187.333 353 155.5C353 124 360.667 99.25 376 81.25C391.333 63.25 413.333 54.25 442 54.25C459.167 54.25 473.833 57.5 486 64C498.167 70.3333 507.667 79.1667 514.5 90.5C521.333 101.833 525.333 114.833 526.5 129.5H484.75C483.417 115.833 479 105.167 471.5 97.5C464 89.6667 454.25 85.75 442.25 85.75C427.083 85.75 415.667 91.8333 408 104C400.333 116.167 396.5 133.417 396.5 155.75C396.5 178.917 400.417 196.333 408.25 208C416.25 219.5 427.5 225.25 442 225.25C454.5 225.25 464.417 221.25 471.75 213.25C479.25 205.25 483.583 194.667 484.75 181.5H526.5C525.333 196.167 521.333 209.167 514.5 220.5C507.667 231.833 498.167 240.75 486 247.25C474 253.583 459.417 256.75 442.25 256.75Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#110D40' }}
      />
      <rect
        x="597.5"
        y="43"
        width="46"
        height="45"
        rx="22.5"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint0_linear_1_35)' }}
      />
      <path
        d="M149.06 300.132C111.494 300.165 77.5562 287.262 48.1748 261.771C52.3645 247.26 56.775 231.623 61.6164 214.118C104.747 236.014 146.359 252.821 188.674 265.444C205.24 270.385 219.09 273.479 232.261 275.178C232.287 275.181 232.313 275.186 232.339 275.189C229.935 276.785 227.399 278.351 224.716 279.897C204.222 291.71 182.284 298.409 159.51 299.802C156.007 300.019 152.491 300.129 149.06 300.132Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint1_linear_1_35)' }}
      />
      <path
        d="M243.746 257.14C230.91 256.811 218.536 254.022 207.075 251.054C178.986 243.78 149.256 233.234 113.51 217.864C98.1357 211.252 82.4051 203.828 66.718 195.782C76.2689 162.25 85.7861 129.461 96.8191 96.8692C130.298 85.6973 163.752 76.0984 195.041 67.3863C217.549 112.281 234.496 155.316 246.822 198.86C251.413 215.072 253.942 227.639 255.021 239.605C255.485 244.739 255.409 248.859 254.783 252.577C254.244 255.78 253.939 256.088 250.706 256.685C248.537 257.086 246.209 257.116 243.746 257.14Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint2_linear_1_35)' }}
      />
      <path
        d="M33.3769 246.425C16.3175 226.115 5.20703 202.663 0.310571 176.591C0.256836 176.305 0.204304 176.017 0.153089 175.73C10.1466 184.576 21.3645 191.374 32.3277 198.018L35.3526 199.853C38.4942 201.763 41.6543 203.517 44.7528 205.203C41.0389 218.515 37.2249 232.335 33.3769 246.425Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint3_linear_1_35)' }}
      />
      <path
        d="M273.396 234.831C273.33 234.227 273.258 233.627 273.182 233.03C271.396 218.998 268.022 203.973 262.561 185.746C250.109 144.185 233.566 102.69 213.363 62.3341C228.963 58.0654 245.057 53.6005 262.363 48.7399C281.944 71.2784 294.004 96.9651 298.254 125.147C304.243 164.862 295.88 201.74 273.396 234.831Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint4_linear_1_35)' }}
      />
      <path
        d="M49.901 186.621C37.9027 179.819 24.6594 171.825 12.9396 162.045C9.08752 158.83 6.27855 155.971 4.09993 153.048C1.30629 149.297 1.26466 147.944 3.8245 143.994C5.9337 140.74 8.95471 137.755 13.6194 134.314C25.4607 125.58 39.1457 119.33 51.0435 114.157C58.203 111.044 65.8033 108.009 74.4711 104.811C74.4134 104.992 74.3561 105.172 74.2987 105.353C71.5352 114.041 68.678 123.026 65.938 131.88C60.1468 150.592 54.8213 169.155 49.901 186.621Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint5_linear_1_35)' }}
      />
      <path
        d="M0.335158 120.979C10.5471 64.261 54.5377 16.8602 110.614 2.51918C114.224 1.59749 117.836 0.807827 121.435 0.15581C119.468 2.59011 117.721 5.06354 116.126 7.44415C105.677 23.0342 98.3339 40.2594 92.2718 55.1807C88.277 65.0129 85.0035 73.7691 82.264 81.9498C82.2293 82.0535 82.1988 82.1344 82.1736 82.1965C82.1144 82.2213 82.0378 82.2506 81.9413 82.2841C64.9661 88.1492 44.9736 95.4866 25.4028 105.441C17.0238 109.703 8.31464 114.521 0.335158 120.979Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint6_linear_1_35)' }}
      />
      <path
        d="M104.832 74.538C105.273 73.3624 105.714 72.199 106.161 71.0413C113.106 53.0513 119.426 38.1871 127.695 24.1181C132.259 16.3542 136.486 10.2185 142.277 5.51964C143.044 4.89537 143.952 4.27661 144.974 3.6746C146.312 2.88691 147.525 2.4898 148.581 2.48888C149.754 2.48786 150.978 2.98678 152.323 4.01931C156.426 7.17304 160.234 11.1557 164.668 16.9383C172.926 27.7056 179.971 39.6109 186.048 50.4753C160.182 57.7133 132.841 65.5082 105.651 74.2733C105.378 74.3614 105.105 74.4493 104.832 74.538Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint7_linear_1_35)' }}
      />
      <path
        d="M204.573 45.5288C199.175 35.5647 191.96 22.8367 183.215 10.6777C180.598 7.03957 177.955 3.46884 175.075 0.108989C201.922 4.65298 225.668 15.8261 247.062 33.9507C244.104 34.746 241.21 35.5266 238.318 36.3142C231.426 38.1899 224.537 40.0729 217.647 41.957L204.573 45.5288Z"
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#paint8_linear_1_35)' }}
      />
      {mode !== ThemeMode.dark && (
        <defs>
          <linearGradient id="paint0_linear_1_35" x1="620.5" y1="43" x2="620.5" y2="88" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1_35"
            x1="150"
            y1="0.130866"
            x2="150.262"
            y2="300.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA00FF" />
            <stop offset="1" stopColor="#8262FF" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};

export default CliraLogoIcon;
