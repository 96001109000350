import { createContext } from 'react';
import { AssignedTo } from 'src/models/users/types';

export interface UserSearch {
  searchString: string;
  users: AssignedTo[];
}

export const UpdateUserSearchContext = createContext<((obj: UserSearch) => void) | null>(null);
UpdateUserSearchContext.displayName = 'UpdateUserSearchContext';

export const UserSearchContext = createContext<UserSearch>({ searchString: '', users: [] });
UserSearchContext.displayName = 'UserSearchContext';
