import { createContext } from 'react';
import { BidPreviewData } from '.';

export const BidPreviewContext = createContext<BidPreviewContextValue>({
  loading: false,
  data: null
});
export type BidPreviewContextValue = {
  loading: boolean;
  data: BidPreviewData | null;
};
