import React, { FC } from 'react';
import { Tooltip as BaseTooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import classNames from 'classnames';
import styles from './index.module.scss';

const Tooltip: FC<TooltipProps> = ({ overlayClassName, ...restProps }) => {
  return (
    <BaseTooltip
      trigger={'hover'}
      mouseLeaveDelay={0}
      mouseEnterDelay={0.4}
      color={'white'}
      {...restProps}
      overlayClassName={classNames(styles.tooltip, !!overlayClassName && overlayClassName)}
    />
  );
};
export default Tooltip;
