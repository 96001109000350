import { BidItemType } from '@tendium/prom-types/tender';
import { IBaseWorkspace } from 'src/models/workspace/types';
import { IApiTender, ITenderGeneral, IApiTenderBox } from 'src/models/procurements/Tender/types';
import { IInboxEmail } from 'src/models/callOffs/types';

export interface IApiLinkedBidOriginGeneric {
  general: Pick<ITenderGeneral<IApiTenderBox>, 'name'>;
}
export interface IApiLinkedBidTenderOrigin extends Pick<IApiTender, 'id'>, IApiLinkedBidOriginGeneric {}
export interface IApiLinkedBidCallOffOrigin extends Pick<IInboxEmail, 'mailId'>, IApiLinkedBidOriginGeneric {}
export type IApiLinkedBidOrigin = IApiLinkedBidTenderOrigin | IApiLinkedBidCallOffOrigin;

export interface IApiLinkedBid {
  id: string;
  itemType: BidItemType;
  item: IApiLinkedBidOrigin;
  workspace: IBaseWorkspace;
}
export interface ILinkedBid {
  id: string;
  name: string;
  workspace: IBaseWorkspace;
}

export interface ILinkedBidsData {
  readonly bids: ILinkedBid[];
  readonly total: number;
}
export interface IGetAllBidsResponse {
  getBids: IApiLinkedBidData;
}
export interface IApiLinkedBidData {
  total: number;
  bids: IApiLinkedBid[];
}
export interface IGetAllBidsRequest {
  search?: string;
}
export interface ILinkedBidsSearchArgs {
  search?: string;
  offset?: number;
  amount?: number;
}

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 8;
