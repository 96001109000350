import React, { FC, useMemo } from 'react';
import { Skeleton } from 'src/common';
import SavedSearchItem from './SavedSearchItem';
import { useLoadAllMpsIds } from 'src/models/matchingProfiles/hooks';
import { ProfileType } from '@tendium/prom-types';

interface Props {
  sidebarIsOpen?: boolean;
}

export const SavedSearchList: FC<Props> = props => {
  const { sidebarIsOpen } = props;
  const { data, loading } = useLoadAllMpsIds(false, ProfileType.ExpiringContract);

  const savedSearches = useMemo(() => data?.getAllMyMatchingProfiles, [data]);

  if (!savedSearches || loading) return <Skeleton active />;

  return (
    <>
      {savedSearches.map(mp => (
        <SavedSearchItem sidebarIsOpen={sidebarIsOpen} mp={mp} key={mp.id} />
      ))}
    </>
  );
};

export default SavedSearchList;
