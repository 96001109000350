import React, { FC } from 'react';
import { List } from 'src/common';
import { IdentifiableTenderObject } from 'src/models/procurements/Tenders/types';
import { TenderListItem } from './TenderListItem';
import styles from '../index.module.scss';

interface Props {
  tenders: IdentifiableTenderObject[];
  loading?: boolean;
}

export const TenderList: FC<Props> = ({ tenders, loading = false }) => {
  return (
    <List items={tenders} loading={loading} className={styles.list}>
      {tenderObject => tenderObject && <TenderListItem tenderObject={tenderObject} />}
    </List>
  );
};
