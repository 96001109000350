import { useContext } from 'react';
import { UpdateQuickActionsCloseContext } from './context';

export function useUpdateQuickActionsCloseContext(): () => void {
  const ctx = useContext(UpdateQuickActionsCloseContext);
  if (!ctx) {
    throw new Error('not in UpdateQuickActionsCloseContext provider');
  }

  return ctx;
}
