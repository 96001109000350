import { gql } from '@apollo/client';

export const WEBHOOKS_FIELDS = gql`
  fragment webhooksFields on Webhook {
    id
    name
    url
    eventType
    secret
  }
`;

export const WEBHOOKS_BIDSPACE_SETTING_FIELDS = gql`
  fragment webhooksBidspaceSettingFields on WebhookBidspaceSettings {
    companyId
    mode
    workspaceId
    webhookId
  }
`;

export const WEBHOOKS_CREATE_STATUS_FIELDS = gql`
  fragment webhooksCreateStatusFields on WebhookStatusEntity {
    changedAt
    eventType
    message
    status
  }
`;

export const GET_WEBHOOKS_WITH_SETTINGS = gql`
  query getWebhooks($workspaceId: String!) {
    getWebhooks {
      bidspaceSetting(workspaceId: $workspaceId) {
        ...webhooksBidspaceSettingFields
      }
      ...webhooksFields
    }
  }
  ${WEBHOOKS_FIELDS}
  ${WEBHOOKS_BIDSPACE_SETTING_FIELDS}
`;

export const GET_WEBHOOKS = gql`
  query getWebhooks {
    getWebhooks {
      ...webhooksFields
    }
  }
  ${WEBHOOKS_FIELDS}
`;

export const CREATE_WEBHOOK = gql`
  mutation createWebhook($name: String!, $url: String!, $eventType: WebhookEventType!, $secret: String!) {
    createWebhook(input: { name: $name, url: $url, eventType: $eventType, secret: $secret }) {
      ...webhooksFields
    }
  }
  ${WEBHOOKS_FIELDS}
`;

export const UPDATE_WEBHOOK = gql`
  mutation updateWebhook($id: String!, $name: String, $url: String, $eventType: WebhookEventType, $secret: String) {
    updateWebHook(input: { id: $id, name: $name, url: $url, eventType: $eventType, secret: $secret }) {
      ...webhooksFields
    }
  }
  ${WEBHOOKS_FIELDS}
`;

export const REMOVE_WEBHOOK = gql`
  mutation removeWebhook($id: String!) {
    removeWebhook(input: { id: $id }) {
      ...webhooksFields
    }
  }
  ${WEBHOOKS_FIELDS}
`;

export const SEND_CREATE_BID_WEBHOOK = gql`
  mutation sendCreateBidWebhook($bidId: String!) {
    sendCreateBidWebhook(bidId: $bidId) {
      id
      webhookStatus {
        ...webhooksCreateStatusFields
      }
    }
  }
  ${WEBHOOKS_CREATE_STATUS_FIELDS}
`;

export const UPDATE_WORKSPACE_WEBHOOKS_SETTINGS = gql`
  mutation updateWebhooksettings($webhookSettings: UpdateWebhookBidspaceSettings!) {
    updateWebhookBidspaceSettings(input: $webhookSettings) {
      ...webhooksBidspaceSettingFields
    }
  }
  ${WEBHOOKS_BIDSPACE_SETTING_FIELDS}
`;

export const CREATE_WEBHOOK_STATUS = gql`
  mutation createWebhookStatus($webhookStatus: CreateWebhookStatus!) {
    createWebhookStatus(input: $webhookStatus) {
      ...webhooksCreateStatusFields
    }
  }
  ${WEBHOOKS_CREATE_STATUS_FIELDS}
`;
