import React, { FC, useMemo } from 'react';
import ReactMarkdown from 'src/common/Markdown';
import styles from './index.module.scss';
import { useApp } from 'src/models/auth';
import { Language } from '@tendium/prom-types';
import { Summaries } from 'src/models/procurements/Preview';

interface Props {
  summaries?: Summaries;
  showEnglish?: boolean;
}

export const TenderSummary: FC<Props> = ({ summaries, showEnglish }) => {
  const { user } = useApp();

  const showOriginal = useMemo(() => user?.language === Language.sv || user?.language === Language.fi, [user]);

  if (showEnglish && summaries?.eng)
    return (
      <div className={styles.summary}>
        <ReactMarkdown>{summaries.eng}</ReactMarkdown>
      </div>
    );

  return (
    <div className={styles.summary}>
      {summaries?.eng && !showOriginal && <ReactMarkdown>{summaries.eng}</ReactMarkdown>}
      {showOriginal && summaries?.original && <ReactMarkdown>{summaries.original}</ReactMarkdown>}
      {showOriginal && !summaries?.original && summaries?.eng && <ReactMarkdown>{summaries.eng}</ReactMarkdown>}
    </div>
  );
};

export default TenderSummary;
