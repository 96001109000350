import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { BlockEmpty, TenderBox, InfoBox, PreviousProcurements, BoxDataSource } from 'src/shared';
import { Button, Expand, UserName } from 'src/common';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { isFieldString, TenderBlockSize, TenderBoxMode } from 'src/models/procurements/Tender/types';
import { Translations } from 'src/lib/I18nService/types';
import { isAddition } from 'src/models/procurements/Tender/helpers';
import { BidItemType } from '@tendium/prom-types/tender';
import TenderSummary from 'src/pages/TendersPage/Sidebars/TenderPreview/Summary';
import TenderDescriptionInfo from 'src/shared/TenderDescriptionInfo';
import { PreviewDescriptionSetting } from 'src/models/users/Preferences/types';
import { usePreviewDescriptionState } from 'src/models/procurements/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-solid-svg-icons';
import SourceDescription from 'src/shared/Tender/SourceDescription';
import classNames from 'classnames';

export const BidPreviewInfo: FC = () => {
  const { t } = useTranslation();
  const { t: tenderT } = useTranslation(Translations.procurements);

  const { data } = useBidPreview();
  const origin = data?.origin;
  const bidId = data?.id;
  const isProcurementItemType = data?.origin.originType === BidItemType.Procurement;
  const [fallBackSelected, setFallBackSelected] = useState<PreviewDescriptionSetting | undefined>();
  const isGPTSummaryFeature = useFeatureFlag(FeatureFlag.GPT_Summary);
  const [activeDescSetting, setActivePreviewDescription] = usePreviewDescriptionState();
  const aiSummaries = data?.origin.summaries;

  const isAvailableSummaries = useMemo(() => {
    if (!isGPTSummaryFeature) return false;

    return aiSummaries?.eng || aiSummaries?.original;
  }, [aiSummaries?.eng, aiSummaries?.original, isGPTSummaryFeature]);

  const descBoxes = useMemo(() => origin?.description, [origin]);
  const locationBoxes = useMemo(
    () =>
      origin?.locationBoxes
        ? origin?.placeOfPerformanceNutsCode
          ? [...origin.locationBoxes, origin.placeOfPerformanceNutsCode]
          : origin.locationBoxes
        : origin?.placeOfPerformanceNutsCode
        ? [origin.placeOfPerformanceNutsCode]
        : [],
    [origin]
  );
  const estimationValueBox = useMemo(() => origin?.contractValueBoxes[0], [origin]);
  const deadlineBox = useMemo(() => origin?.deadline[0], [origin]);

  const startBox = useMemo(() => origin?.contractStartAndEndStart || undefined, [origin]);

  const durationBoxes = origin?.contractDurationBoxes;
  const renewalBoxes = origin?.contractRenewalBoxes;
  const procedureBoxes = origin?.typeOfProcedureBoxes;
  const publishedBox = origin?.availableDate;
  const questionBoxes = origin?.deadlineOfClarificationQuestions;
  const customBoxes = origin?.customBoxes;
  const scopeBoxes = origin?.scopeOfContract;

  const isCustomBidFieldsFeature = useFeatureFlag(FeatureFlag.CustomBidFields);
  const isPreviousProcurementsFeature = useFeatureFlag(FeatureFlag.Analytics_Previous_Procurements);
  const isEditableBidFeature = !!useFeatureFlag(FeatureFlag.BiddingWorkflow_Preview);

  const returnDescAndSetFallback = useCallback(
    (setting: PreviewDescriptionSetting) => {
      setFallBackSelected(setting);

      if (setting === PreviewDescriptionSetting.ShortDescription)
        return (
          <>
            <TenderBox
              boxes={descBoxes}
              title={''}
              as={TenderBoxMode.Collection}
              actions={false}
              isEditable={isEditableBidFeature}
            />
            <SourceDescription
              desc={tenderT('SHORT_DESCRIPTION')}
              className={styles.sourceDescription}
              descClassName={styles.sourceName}
            />
          </>
        );

      if (setting === PreviewDescriptionSetting.ScopeOfContract)
        return (
          <>
            <TenderBox boxes={scopeBoxes} title="" as={TenderBoxMode.Collection} actions={false} />
            <div className={classNames(styles.sourceDescription, styles.scopeSources)}>
              {scopeBoxes?.map(
                box =>
                  box.dataSource && (
                    <BoxDataSource
                      key={`source_${box.dataSource.filePath}_${box.id}`}
                      dataSource={box.dataSource}
                      boxId={box.id}
                      procurementId={data?.origin.id}
                    />
                  )
              )}
            </div>
          </>
        );

      if (setting === PreviewDescriptionSetting.Summary)
        return (
          <Expand
            visibility={300}
            isListExpandable
            showMoreTitle={t('Common.showAll')}
            isDescription
            buttonClassName={styles.expandButton}
          >
            <TenderSummary summaries={aiSummaries} />
            <TenderDescriptionInfo desc={t(`Tenders.quickSummaryInfo`)} />
          </Expand>
        );
    },
    [descBoxes, isEditableBidFeature, tenderT, scopeBoxes, t, aiSummaries, data?.origin.id]
  );
  const previewDescritionEventSource = useMemo(() => (origin?.id ? 'Bid preview' : 'Tender preview'), [origin?.id]);

  const scopeMissingAndSelected = useMemo(
    () => !scopeBoxes && activeDescSetting === PreviewDescriptionSetting.ScopeOfContract,
    [activeDescSetting, scopeBoxes]
  );

  const shortDescMissingAndSelected = useMemo(
    () => !descBoxes && activeDescSetting === PreviewDescriptionSetting.ShortDescription,
    [activeDescSetting, descBoxes]
  );

  const summaryMissingAndSelected = useMemo(
    () => !isAvailableSummaries && activeDescSetting === PreviewDescriptionSetting.Summary,
    [activeDescSetting, isAvailableSummaries]
  );

  const currentDescription = useMemo(() => {
    if (summaryMissingAndSelected) {
      return descBoxes
        ? returnDescAndSetFallback(PreviewDescriptionSetting.ShortDescription)
        : returnDescAndSetFallback(PreviewDescriptionSetting.ScopeOfContract);
    }

    if (shortDescMissingAndSelected) {
      return isAvailableSummaries
        ? returnDescAndSetFallback(PreviewDescriptionSetting.Summary)
        : returnDescAndSetFallback(PreviewDescriptionSetting.ScopeOfContract);
    }

    if (scopeMissingAndSelected) {
      return isAvailableSummaries
        ? returnDescAndSetFallback(PreviewDescriptionSetting.Summary)
        : returnDescAndSetFallback(PreviewDescriptionSetting.ShortDescription);
    }

    return returnDescAndSetFallback(activeDescSetting);
  }, [
    activeDescSetting,
    descBoxes,
    isAvailableSummaries,
    returnDescAndSetFallback,
    scopeMissingAndSelected,
    shortDescMissingAndSelected,
    summaryMissingAndSelected
  ]);

  if (!data) return null;

  return bidId ? (
    <>
      <div className={styles.buttonSelectionContainer}>
        {isGPTSummaryFeature && isAvailableSummaries && (
          <Button
            isSelected={
              activeDescSetting === PreviewDescriptionSetting.Summary ||
              fallBackSelected === PreviewDescriptionSetting.Summary
            }
            icon={<FontAwesomeIcon icon={faStars} className={styles.summaryIcon} />}
            onClick={() => setActivePreviewDescription(PreviewDescriptionSetting.Summary, previewDescritionEventSource)}
          >
            {t('Tenders.quickSummary')}
          </Button>
        )}
        {descBoxes && (
          <Button
            isSelected={
              activeDescSetting === PreviewDescriptionSetting.ShortDescription ||
              fallBackSelected === PreviewDescriptionSetting.ShortDescription
            }
            onClick={() =>
              setActivePreviewDescription(PreviewDescriptionSetting.ShortDescription, previewDescritionEventSource)
            }
          >
            {tenderT('Short description')}
          </Button>
        )}
        {scopeBoxes && (
          <Button
            isSelected={
              activeDescSetting === PreviewDescriptionSetting.ScopeOfContract ||
              fallBackSelected === PreviewDescriptionSetting.ScopeOfContract
            }
            onClick={() =>
              setActivePreviewDescription(PreviewDescriptionSetting.ScopeOfContract, previewDescritionEventSource)
            }
          >
            {tenderT('Scope of contract')}
          </Button>
        )}
      </div>
      {(descBoxes || scopeBoxes || isAvailableSummaries) && <div className={styles.boxes}>{currentDescription}</div>}
      <div className={styles.block}>
        <InfoBox
          title={t('BidSpaces.creationDateOfBid')}
          className={styles.info}
          value={
            data.createdAt ? (
              <>
                <div className={styles.date}>
                  {t('Common.Format.tableDateFormatForShortMonths', { date: new Date(data.createdAt) })}
                </div>
                {data.createdBy?.email && <UserName user={data.createdBy} />}
              </>
            ) : (
              <BlockEmpty />
            )
          }
        />
      </div>

      <div className={styles.boxes}>
        <TenderBox
          boxes={locationBoxes}
          title={tenderT('Place of performance')}
          as={TenderBoxMode.Collection}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
        />
        <TenderBox
          box={publishedBox}
          title={tenderT('Publication date')}
          as={TenderBoxMode.Custom}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
          config={{ date: { isCountdown: true } }}
        />
        <TenderBox
          box={deadlineBox}
          title={tenderT('Submission deadline', { context: 'short' })}
          as={TenderBoxMode.Custom}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
          config={{ date: { isCountdown: true } }}
          isFirstField
          isInfo={isAddition(origin?.deadline)}
        />
        <TenderBox
          box={estimationValueBox}
          title={tenderT('Estimated contract value')}
          as={TenderBoxMode.Custom}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
          field={estimationValueBox?.fields.find(field => field.name === 'Estimated contract value')}
          isInfo={isAddition(origin?.contractValueBoxes)}
        />
        <TenderBox
          boxes={questionBoxes}
          title={tenderT('Deadline of clarification questions')}
          as={TenderBoxMode.Collection}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
          config={{ date: { isCountdown: true } }}
        />

        <TenderBox
          boxes={durationBoxes}
          title={tenderT('Contract duration')}
          as={TenderBoxMode.Collection}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
          isFirstField
          isInfo={isAddition(origin?.contractDurationBoxes)}
        />
        <TenderBox
          box={startBox}
          title={tenderT('Contract start date')}
          as={TenderBoxMode.Custom}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
          isFirstField
          isInfo={startBox && isAddition([startBox])}
        />
        <TenderBox
          boxes={renewalBoxes}
          title={tenderT('Contract renewal')}
          as={TenderBoxMode.Collection}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
          isFirstField
          isInfo={isAddition(origin?.contractRenewalBoxes)}
        />

        <TenderBox
          boxes={procedureBoxes}
          title={tenderT('Type of procedure')}
          as={TenderBoxMode.Collection}
          size={TenderBlockSize.Half}
          actions={false}
          isEditable={isEditableBidFeature}
        />
        {isCustomBidFieldsFeature &&
          customBoxes &&
          customBoxes.map(box => (
            <TenderBox
              title={box.title}
              isEditable={isEditableBidFeature}
              actions={false}
              boxes={[box]}
              as={TenderBoxMode.Collection}
              key={box.id}
              size={isFieldString(box.firstField) ? TenderBlockSize.Full : TenderBlockSize.Half}
            />
          ))}
        {!!isProcurementItemType && !!isPreviousProcurementsFeature && (
          <PreviousProcurements procurementId={data.origin.id} />
        )}
      </div>
    </>
  ) : (
    <p className={styles.block}>{t('BidSpaces.noBidNote')}</p>
  );
};

export default BidPreviewInfo;
