import React, { FC, useMemo, useState } from 'react';
import { TooltipWithBounds } from '@visx/tooltip';
import { formatCurrencyValue } from 'src/models/procurements/Tender/helpers';
import { BarStackGroup, ChartContainer, XYChart } from 'src/common/Charts';
import { useChartTooltip } from 'src/common/Charts/hooks';
import HoverTooltip from './Tooltip';
import { useTranslation } from 'react-i18next';
import { useGetBidsCategoryStatistics } from 'src/models/bids/Bids/hooks';
import styles from './index.module.scss';
import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { WORKSPACE_STATUS_COLOR_MAPPER } from 'src/models/workspace/WorkspaceStatus/mappers';
import { scaleOrdinal } from '@visx/scale';
import ForecastBidsCard from './ForecastBidsCard';
import { EmptyFilter, InfoIcon, useLanguage } from 'src/common';
import { formatDate, getMonthRange } from 'src/helpers/dates';
import { faSparkles } from '@fortawesome/pro-light-svg-icons';
import { BIDSPACES_STATISTIC_ORDER, ForecastBidChartStackState, StackData } from 'src/models/bids/Bids/types';
import { trackGraphInteraction } from 'src/segment/events';
import { scrollToItem } from 'src/helpers/scrollToElement';

const ForecastBarStackChart: FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { data, loading } = useGetBidsCategoryStatistics(true); // Will always return amount in SEK

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useChartTooltip<StackData>();

  const stackedData: StackData[] = useMemo(() => {
    if (!data) return [];
    return data.flatMap(statistics =>
      BIDSPACES_STATISTIC_ORDER.map(category => ({
        date: statistics.date,
        statusCategory: category,
        amount: statistics.categoryStatistics[category]?.amount ?? undefined,
        count: statistics.categoryStatistics[category]?.count ?? undefined
      })).filter(entry => entry.amount > 0)
    );
  }, [data]);

  const yDomain: [number, number] = useMemo(() => [0, Math.max(...(data?.map(d => d.monthlyTotal) ?? []))], [data]);
  const xDomain: string[] = useMemo(
    () => data?.map(statistics => formatDate(statistics.date, 'month', { showOnlyMonthName: true })) ?? [],
    [data]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal<WorkspaceStatusCategory, string>()
        .domain(BIDSPACES_STATISTIC_ORDER)
        .range(BIDSPACES_STATISTIC_ORDER.map(category => WORKSPACE_STATUS_COLOR_MAPPER[category].color)) as never,
    []
  );

  const [chartState, setChartState] = useState<ForecastBidChartStackState | null>(null);

  let timer: NodeJS.Timeout;
  const onClick = ({ date, statusCategory, amount, count }: StackData, isSelect?: boolean): void => {
    timer && clearTimeout(timer);
    if (!isSelect || !date) {
      setChartState(null);
      return;
    }
    const range = getMonthRange(date, 1);
    setChartState({
      contractStartRange: range,
      statusCategory,
      amount,
      count,
      date
    });
    trackGraphInteraction(statusCategory);
    timer = setTimeout(() => scrollToItem('ForecastBidsCardAnchor'), 300);
  };

  return (
    <ChartContainer
      title={
        <div className={styles.forecastTitle}>
          <span>{t('BidSpaces.forecastChartTitle')}</span>
          <InfoIcon desc={t('BidSpaces.forecastChartInfoDesc')} />
        </div>
      }
      loading={loading}
    >
      {({ width }) => (
        <>
          {stackedData.length === 0 ? (
            <EmptyFilter icon={faSparkles} desc={t('BidSpaces.Dashboard.EmptyFilter.desc')} />
          ) : (
            <>
              <XYChart
                width={width}
                xDomain={xDomain}
                yDomain={yDomain}
                colorScale={colorScale}
                formatY={value => formatCurrencyValue(value as number, language)}
                formatLegend={key => t(`BidSpaces.WorkspaceStatusCategory.${key}`)}
              >
                <BarStackGroup<StackData>
                  data={stackedData}
                  getLegendKey={d => d.statusCategory}
                  getYValue={d => d.amount ?? 0}
                  getXValue={d => formatDate(d.date, 'month', { showOnlyMonthName: true })}
                  getSelectedColor={key => WORKSPACE_STATUS_COLOR_MAPPER[key]?.selectedColor}
                  onClick={onClick}
                  onMouseLeave={hideTooltip}
                  onMouseEnter={({ tooltipData, tooltipTop, tooltipLeft }) => {
                    showTooltip({ tooltipData, tooltipTop, tooltipLeft });
                  }}
                />
              </XYChart>
              {tooltipOpen && tooltipData && (
                <TooltipWithBounds top={tooltipTop} left={tooltipLeft} className={styles.tooltip}>
                  <HoverTooltip data={tooltipData} />
                </TooltipWithBounds>
              )}
              <div id={'ForecastBidsCardAnchor'}>{chartState && <ForecastBidsCard chartState={chartState} />}</div>
            </>
          )}
        </>
      )}
    </ChartContainer>
  );
};

export default ForecastBarStackChart;
