import { createContext } from 'react';
import { Reminder } from '.';
import { QueryResult } from '@apollo/client';
import { GetRemindersInput } from '@tendium/prom-types/schema';
import { ApiRemindersResponse } from './types';

export const RemindersContext = createContext<RemindersContextValue>({
  loading: false,
  remindersData: undefined,
  remindersCount: undefined,
  id: undefined,
  wsId: undefined,
  sourceTitle: undefined,
  fetchMore: undefined
});
export type RemindersContextValue = {
  loading: boolean;
  remindersData?: Reminder[];
  remindersCount?: number;
  id?: string;
  wsId?: string;
  sourceTitle?: string;
  fetchMore?: QueryResult<ApiRemindersResponse, GetRemindersInput>['fetchMore'];
};
