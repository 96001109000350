import React, { FC } from 'react';
import { Button } from 'src/common/Button';
import { Input } from 'src/common/Input';
import { InputProps } from 'antd/lib/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

interface IClearableInputProps extends Omit<InputProps, 'suffix'> {
  onClear: () => void;
}

/**
 * ClearableInput uses value property passed by antd, so it does not require additional state on the parent component
 * and does not trigger unnecessary re-render
 */
export const ClearableInput: FC<IClearableInputProps> = props => {
  const { onClear, value, disabled, readOnly, ...restProps } = props;
  const needClear = !disabled && !readOnly && value;
  return (
    <Input
      {...restProps}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      suffix={
        needClear ? (
          <Button type={'text'} onClick={onClear} className={styles.button} icon={<FontAwesomeIcon icon={faTimes} />} />
        ) : (
          <FontAwesomeIcon icon={faSearch} />
        )
      }
    />
  );
};
export default ClearableInput;
