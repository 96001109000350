import { useContext, Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { ISidebar, ISidebarsContextValue, SidebarsContext, UpdateSidebarsContext } from './context';
import { updateQueryParams, useQueryParams } from 'src/helpers/history';
import { isString } from 'lodash';
import { isSidebarMode } from './helpers';
import { OverviewQp } from 'src/models/procurements/types';
import { useLocation } from 'react-router-dom';
import { SidebarMode } from './types';
import { isTenderPage } from 'src/models/procurements/helpers';
import { IMpSidebarData } from 'src/models/matchingProfiles/types';

export function useSidebarsContext(): ISidebarsContextValue | null {
  const contextValue = useContext(SidebarsContext);

  if (contextValue === null) {
    console.warn('SidebarsContext is required');
  }

  return contextValue;
}

export function useUpdateSidebarsContext(): Dispatch<SetStateAction<ISidebar[]>> | null {
  const ctx = useContext(UpdateSidebarsContext);
  if (!ctx) {
    console.warn('not in UpdateSidebarsContext provider');
  }

  return ctx;
}

export function useCloseSidebar(onClose?: () => void): () => void {
  const updateNavSidebars = useUpdateSidebarsContext();
  return useCallback(() => {
    updateNavSidebars?.(prevState => prevState.slice(0, -1));
    updateQueryParams({
      [OverviewQp.PreviewId]: undefined,
      [OverviewQp.PreviewMode]: undefined,
      [OverviewQp.CommentId]: undefined
    });
    onClose?.();
  }, [onClose, updateNavSidebars]);
}

export function useCurrentSidebar(): (ISidebar & { mp?: IMpSidebarData }) | null {
  const sidebarCtx = useSidebarsContext();
  const qp = useQueryParams();
  const sidebarsQty = sidebarCtx?.sidebars.length ?? 0;
  const qpPreviewId = qp[OverviewQp.PreviewId];
  const qpPreviewMode = qp[OverviewQp.PreviewMode];
  const qpCommentId = qp[OverviewQp.CommentId];

  const location = useLocation();

  const inProcPage = isTenderPage();
  const procId = location.pathname.split('/')[2];
  return useMemo(() => {
    if ((!qpPreviewId && !qpPreviewMode && !qpCommentId) || !sidebarCtx) {
      return null;
    } else if (isString(qpPreviewId) && isSidebarMode(qpPreviewMode)) {
      return {
        mode: qpPreviewMode,
        id: qpPreviewId,
        mp: sidebarCtx.mp
      };
    } else if (isString(qpCommentId) && inProcPage && !!procId) {
      return {
        mode: SidebarMode.COMMENTS,
        id: procId
      };
    } else if (sidebarsQty > 0) {
      return sidebarCtx.sidebars[sidebarsQty - 1];
    } else {
      return null;
    }
  }, [qpPreviewId, qpPreviewMode, qpCommentId, sidebarCtx, inProcPage, procId, sidebarsQty]);
}

export function useOpenSidebar(): (sidebarState: ISidebar) => void {
  const updateNavSidebars = useUpdateSidebarsContext();
  const location = useLocation();

  const { pathname, hash } = location;

  return useCallback(
    (sidebarState: ISidebar) => {
      updateNavSidebars?.(prevState => {
        const alreadyOpen = prevState.length > 0;
        const newState = alreadyOpen
          ? prevState.map(sidebar =>
              sidebar.mode === sidebarState.mode ? { ...sidebar, id: sidebarState.id } : /*sidebar*/ sidebarState
            )
          : [/*...prevState,*/ sidebarState];
        return newState;
      });
      updateNavSidebars &&
        updateQueryParams(
          {
            [OverviewQp.PreviewId]: sidebarState.id,
            [OverviewQp.PreviewMode]: sidebarState.mode
          },
          false,
          pathname,
          hash
        );
    },
    [hash, pathname, updateNavSidebars]
  );
}
