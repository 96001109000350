import {
  ADMIN_AND_ABOVE,
  BYGGFAKTA_ROLES,
  FeatureRole,
  FEATURE_ROLES,
  PAYMENT_ROLES,
  Role,
  TENDIUM_ROLES,
  CLIRA_ROLES
} from '@tendium/prom-types';

export const BF_PARTNER_PAYMENT_ROLES = [...BYGGFAKTA_ROLES] as string[];
export const CLR_PAYMENT_ROLES = [...CLIRA_ROLES] as string[];
export const TENDIUM_PAYMENT_ROLES = [...TENDIUM_ROLES, Role.BF_ADMIN, Role.CLR_ADMIN] as string[];

export const COMMON_PAYMENT_ROLES = [Role.NOT_ACTIVATED] as string[];

export type AdminRoles = (typeof ADMIN_AND_ABOVE)[number];
export type PaymentRoles = (typeof PAYMENT_ROLES)[number];
export type FeaturesRoles = (typeof FEATURE_ROLES)[number];

export function isAdminRole(role: unknown): role is AdminRoles {
  return isRole(role) && ADMIN_AND_ABOVE.some(r => r === role);
}
export function isFeaturesRole(role: unknown): role is FeaturesRoles {
  return typeof role === 'string' && Object.values(FeatureRole).some(x => x === role);
}

export function isRole(role: unknown): role is Role {
  return typeof role === 'string' && Object.values(Role).some(x => x === role);
}
