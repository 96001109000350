import React, { FC, useCallback, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import { Form, Input } from 'src/common';
import { IBoxFieldEmail } from 'src/models/procurements/Tender/types';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import ValueActions from '../../ValueActions';
import classNames from 'classnames';
import { useClickAway } from 'react-use';

interface IFormData {
  email: string;
}

export interface IProps extends IBlockEditProps<IBoxFieldEmail> {}

export const EditableBlockEmail: FC<IProps> = ({ onChange, onFinish, field, className }) => {
  const { id: fieldId, email } = field;
  const { t } = useTranslation();
  const [form] = useForm();
  const ref = useRef<HTMLDivElement>(null);

  const onSubmit = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);

    value.email !== field.email ? onChange && onChange(field.update(value.email)) : onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        email
      }
    };
  }, [fieldId, email]);

  const onCancel = useCallback(() => {
    form.resetFields();
    onFinish && onFinish();
  }, [form, onFinish]);

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    onSubmit();
  });

  return (
    <div ref={ref} className={className}>
      <Form
        form={form}
        initialValues={initialValues}
        layout={'vertical'}
        className={classNames(styles.container, styles.fields)}
      >
        <div className={styles.field}>
          <Form.Item
            name={[fieldId, 'email']}
            className={styles.withErrorMessage}
            rules={[{ type: 'email', message: t('Common.incorrectEmailError') }]}
          >
            <Input type={'email'} onPressEnter={onSubmit} placeholder={t('Common.email')} className={styles.control} />
          </Form.Item>
          <ValueActions onCancel={onCancel} />
        </div>
      </Form>
    </div>
  );
};

export default EditableBlockEmail;
