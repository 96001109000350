import { IWorkspace, IApiWorkspace, IBaseWorkspace } from './types';
import { toHSLColor } from 'src/helpers/styles';
import { IAccessControl } from '../acl/types';
import { AssignedTo, IUserName } from '../users/types';
import { User } from '../users';
import { IWorkspaceStatus } from './WorkspaceStatus/types';
import { Stage } from './WorkspaceStatus';
import { toOwnersAndSubscribers } from '../acl/helpers';

export class BaseWorkspace implements IBaseWorkspace {
  public readonly id: string;
  public readonly name: string;
  public readonly color: string;

  constructor(private readonly apiWs: IBaseWorkspace) {
    this.id = apiWs.id;
    this.name = apiWs.name;
    this.color = this.toWsColor();
  }

  private toWsColor(): string {
    const { id, color } = this.apiWs;
    return color ?? toHSLColor(id);
  }
}

export class Workspace extends BaseWorkspace implements IWorkspace {
  public readonly isActive: boolean;
  public readonly statuses: IWorkspaceStatus[];
  public readonly archivedDate?: number;
  public readonly archivedBy?: IUserName;
  public readonly accessControl: IAccessControl;
  public readonly accessToEdit?: boolean;
  public readonly owners: AssignedTo[];
  public readonly subscribers: AssignedTo[];

  constructor(apiWs: IApiWorkspace, allUsersAndTeams: AssignedTo[]) {
    super(apiWs);
    this.isActive = apiWs.isActive;
    this.statuses = apiWs.statuses.map(status => new Stage(status));
    this.archivedDate = apiWs.archivedDate;
    this.archivedBy = apiWs.archivedBy ? new User(apiWs.archivedBy) : undefined;
    this.accessControl = apiWs.accessControl;
    this.accessToEdit = apiWs.accessToEdit;
    this.owners = toOwnersAndSubscribers(apiWs.owners, allUsersAndTeams);
    this.subscribers = toOwnersAndSubscribers(apiWs.subscribers, allUsersAndTeams);
  }
}
