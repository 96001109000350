import { Tree as BaseTree } from 'antd';
import { DirectoryTreeProps } from 'antd/lib/tree/DirectoryTree';
import React, { FC } from 'react';

export interface IDirectoryTreeProps extends DirectoryTreeProps {}

const DirectoryTree: FC<IDirectoryTreeProps> = props => {
  return <BaseTree.DirectoryTree {...props} />;
};

export default DirectoryTree;
