import { createContext, Dispatch, SetStateAction } from 'react';
import { SidebarMode } from './types';
import { IMpSidebarData } from 'src/models/matchingProfiles/types';

export interface ISidebar {
  id: string | null;
  mode: SidebarMode;
}

export interface ISidebarsContextValue {
  sidebars: ISidebar[];
  mp: IMpSidebarData;
}

export const SidebarsContext = createContext<ISidebarsContextValue | null>(null);
SidebarsContext.displayName = 'SidebarsContext';

export const UpdateSidebarsContext = createContext<Dispatch<SetStateAction<ISidebar[]>> | null>(null);
UpdateSidebarsContext.displayName = 'UpdateSidebarsContext';
