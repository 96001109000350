import { TendersSortName } from 'src/models/procurements/Tenders/types';
import { BidsColumn } from 'src/models/bids/Bids/types';
import { ActivePreviewTab } from 'src/models/procurements/types';
import { ActiveNotificationsTab } from 'src/models/notifications/types';
import { AnalyticsFilters, BuyerProfileSettings } from '@tendium/prom-types/schema';
import { SupplierProfileSettings } from 'src/models/supplierProfiles/types';

export enum PageName {
  'bids' = 'bids',
  'bidSpaces' = 'bidSpaces',
  'allTenders' = 'allTenders',
  'mpTenders' = 'mpTenders',
  'starredTenders' = 'starredTenders',
  'callOffs' = 'callOffs',
  'tasks' = 'tasks',
  'users' = 'users',
  'expiringContracts' = 'expiringContracts',
  'tenderNavMonitoring' = 'tenderNavMonitoring',
  'tenderNavProjectBid' = 'tenderNavProjectBid',
  'tenderNavAnalytics' = 'tenderNavAnalytics',
  'notificationsMenu' = 'notificationsMenu',
  'marketDashboard' = 'marketDashboard',
  'supplierProfile' = 'supplierProfile',
  'buyerProfile' = 'buyerProfile',
  'biddingTool' = 'biddingTool'
}

export enum PageView {
  table = 'table',
  board = 'board',
  timeline = 'timeline',
  collapsed = 'collapsed',
  expanded = 'expanded',
  dashboard = 'dashboard'
}

export enum PreviewDescriptionSetting {
  Summary = 'summary',
  ShortDescription = 'shortDescription',
  ScopeOfContract = 'scopeOfContract'
}

export interface PagePreferences {
  id: PageName;
  view?: PageView;
  columns?: TendersSortName | BidsColumn;
  tab?: ActivePreviewTab | ActiveNotificationsTab;
  marketDashboardFilters?: AnalyticsFilters;
  description?: PreviewDescriptionSetting;
  supplierProfile?: SupplierProfileSettings;
  buyerProfile?: BuyerProfileSettings;
  openTaskGroup?: string | null;
}

export interface UserPreferences {
  minimal: boolean;
  pages?: PagePreferences[];
}

export const DEFAULT_TENDER_NAV_VIEW = PageView.expanded;
