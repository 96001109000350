export enum RequestType {
  BidsSorting = 'BidsSorting'
}

export interface RequestSettings {
  entityId: string;
  requestType: RequestType;
  settings: string;
}

export type SetRequestSettingsVar = Omit<RequestSettings, 'requestType'> & {
  type: RequestType;
};

export type SetRequestSettingsData = {
  setRequestSettings: boolean;
  __typename: 'Mutation';
};
