import React, { FC, useCallback, useMemo } from 'react';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { useBackHref } from 'src/helpers/history';
import { Navigate, useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Button } from 'src/common/Button';
import { Paths } from 'src/pages/paths';
import { ConditionalWrapper, PageHeading, Tooltip } from 'src/common';
import { useWorkspaceViewState } from 'src/models/workspace/hooks';
import BidsTable from '../BidsPage/BidsTable';
import BidspacesStatistic from './BidspacesStatistic';
import { BidspacesFilters } from './Filters';
import WinHitRateLabel from '../BidsPage/WinHitRateLabel';
import { QuickActions, QuickActionsItem, ViewOptionsDropDown } from 'src/shared';
import BidsFilter from '../BidsPage/BidsFilter';
import { PageView } from 'src/models/users/Preferences/types';
import BidsSidebars from '../BidsPage/BidsSidebars';
import { useBidspaceBANSStatistics } from 'src/models/bids/Bids/hooks';
import { faArchive, faSquareKanban } from '@fortawesome/pro-light-svg-icons';
import BidspacesDashboard from './BidspacesDashboard';
import { useCreateWsDisabled } from 'src/models/admin/CompanyUsers/hooks';
import { trackABSwitchMode } from 'src/segment/events/allBidspaces';
import classNames from 'classnames';
import BidsDndLayer from '../BidsPage/BidsTable/DndLayer';

const BidspacesNavPage: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const isCreateWsDisabled = useCreateWsDisabled();

  const backHref = useBackHref();
  const isBidspacesFeature = useFeatureFlag(FeatureFlag.Bidspaces);

  const onCreateWorkspace = useCallback(() => {
    navigate({ pathname: Paths.BIDSPACES_CREATE });
  }, [navigate]);

  const [currentView, setWsView] = useWorkspaceViewState();
  const toggleView = useCallback(
    (view: PageView) => {
      setWsView(view);
      trackABSwitchMode(view);
    },
    [setWsView]
  );

  const isDashboard = useMemo(() => currentView === PageView.dashboard, [currentView]);

  const { data: stats, loading: loadingStats } = useBidspaceBANSStatistics();
  const winHitRateDecimal = stats?.winHitRate;

  const onGoToArchived = useCallback(() => {
    navigate({ pathname: `${Paths.BIDSPACES_ARCHIVED}` });
  }, [navigate]);

  return isBidspacesFeature ? (
    <div
      className={classNames(styles.bidspaceContainer, {
        [styles.dashboardView]: isDashboard
      })}
    >
      <BidsDndLayer />
      <PageHeading
        title={t('Navigation.bidSpaces')}
        icon={faSquareKanban}
        desc={t('BidSpaces.desc')}
        actions={
          <div className={styles.pageHeader}>
            <ConditionalWrapper
              condition={isCreateWsDisabled}
              wrapper={children => (
                <Tooltip title={t('BidSpaces.wsRestrictionTooltip')}>
                  {children}
                  {''}
                </Tooltip>
              )}
            >
              <Button
                onClick={onCreateWorkspace}
                icon={<FontAwesomeIcon icon={faPlus} />}
                type="text"
                disabled={isCreateWsDisabled}
              >
                {t('BidSpaces.createNewBidSpace')}
              </Button>
            </ConditionalWrapper>
            <QuickActions>
              <QuickActionsItem title={t('BidSpaces.archivedBidSpaces')} icon={faArchive} onClick={onGoToArchived} />
            </QuickActions>
          </div>
        }
      />
      <div className={styles.actionBar}>
        <ul className={styles.viewActions}>
          <li className={styles.viewAction}>
            <WinHitRateLabel winHitRateDecimal={winHitRateDecimal} loading={loadingStats} />
          </li>

          <li className={styles.viewAction}>
            <ViewOptionsDropDown
              extra={<BidsFilter />}
              onToggleView={toggleView}
              currentView={currentView}
              tableViewText={t('Common.tableView')}
              cardViewText={t('Common.boardView')}
              isDashboard={true}
            />
          </li>
        </ul>
        <BidspacesFilters />
      </div>
      <BidspacesStatistic />
      {isDashboard ? <BidspacesDashboard /> : <BidsTable showBidspace />}
      <BidsSidebars />
    </div>
  ) : (
    <Navigate to={backHref} />
  );
};

export default BidspacesNavPage;
