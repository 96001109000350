import { gql } from '@apollo/client';
import { SSO_CONFIG_FIELDS } from 'src/models/company/Security/queries';

export const UPDATE_COMPANY_PRICE_PLAN = gql`
  mutation updateCompanyPricePlan($companyId: String!, $pricePlan: PaymentRole!) {
    updateCompanyPricePlan(input: { companyId: $companyId, pricePlan: $pricePlan }) {
      id
      pricePlan
    }
  }
`;

export const UPDATE_COMPANY_FEATURE_ROLE = gql`
  mutation updateCompanyPricePlan($companyId: String!, $featureRoles: [FeatureRole!]) {
    updateCompanyFeatureRole(input: { companyId: $companyId, featureRoles: $featureRoles }) {
      id
      featureRoles
    }
  }
`;

export const UPDATE_COMPANY_ENABLED = gql`
  mutation updateCompanyEnabled($companyId: String!, $enabled: Boolean!) {
    updateCompanyEnabled(input: { companyId: $companyId, enabled: $enabled }) {
      id
      enabled
    }
  }
`;

export const UPDATE_COMPANY_ADMIN = gql`
  mutation updateCompanyAdmin($email: String!, $companyId: String!) {
    updateCompanyAdmin(input: { newAdmin: $email, companyId: $companyId }) {
      id
      personel {
        id
        email
        isAdmin
      }
      createdBy {
        id
        email
        isAdmin
      }
    }
  }
`;

export const GET_COMPANY_INFO = gql`
  query getCompany($companyId: String) {
    getCompany(input: $companyId) {
      ...CompanyFields
      createdOn
      createdBy {
        ...CompanyUserFields
      }
      personel {
        ...CompanyUserFields
      }
      ssoConfiguration {
        ...ssoConfigFields
      }
      competitors {
        orgId
      }
      settings {
        filterKeywordsLimit
        matchingProfilesLimit
        workspaceLimit
      }
    }
  }
  ${SSO_CONFIG_FIELDS}
`;

export const LOAD_COMPANY_NUTS = gql`
  query getCompanyNutsFilter($companyId: String!) {
    getCompanyNutsFilter(companyId: $companyId) {
      id
      isActive
      createDateTime
      nutsCodes
      nutsCodesExact
    }
  }
`;

export const UPDATE_COMPANY_NUTS_CODES = gql`
  mutation updateCompanyNutsFilter(
    $companyId: String!
    $isActive: Boolean
    $nutsCodes: [String!]
    $nutsCodesExact: [String!]
  ) {
    updateNutsFilter(
      companyId: $companyId
      updateParams: { isActive: $isActive, nutsCodes: $nutsCodes, nutsCodesExact: $nutsCodesExact }
    ) {
      id
      isActive
      createDateTime
      nutsCodes
      nutsCodesExact
    }
  }
`;

export const SAVE_COMPANY_SETTINGS = gql`
  mutation saveCompanySettings($companyId: String!, $settings: UpdateCompanySettingsInput!) {
    saveCompanySettings(companyId: $companyId, settings: $settings) {
      id
      settings {
        filterKeywordsLimit
        matchingProfilesLimit
        workspaceLimit
      }
    }
  }
`;

export const GET_COMPANY_MP_COUNT = gql`
  query getCompanyMatchingProfilesCount($input: GetCompanyMatchingProfilesCountInput) {
    getCompanyMatchingProfilesCount(input: $input)
  }
`;

export const GET_COMPANY_WS_COUNT = gql`
  query getCompanyWorkspacesCount {
    getCompanyWorkspacesCount
  }
`;
