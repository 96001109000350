import { AggregateBy } from './Sidebar/AggregateSelect/types';
import { SearchProfileColor, SearchProfilesColorsDict } from 'src/helpers/constants';
import { DocumentPosition } from './hooks';

export type SearchProfileTermsSearch = { searchProfile: ISearchProfile; terms: string[] };
export type TermsForSearch = SearchProfileTermsSearch[];

export interface ISearchSettings {
  aggregateBy: AggregateBy;
  groups: TermsForSearch;
  documents: string[];
}

//// BRAND NEW

export interface ISearchProfile {
  id: string;
  name: string;
  terms: string[];
  color: SearchProfileColor;
  colorValue: SearchProfilesColorsDict;
  isMpBasedGroup?: boolean;
  // order: number; // TODO
}

export interface IDocumentSearchResult {
  filePath: string;
  headline: string;
  content: string;
  order: number;
  headlinePosition: DocumentPosition[];
  contentPosition: DocumentPosition[];
}

export interface IDocumentPart {
  searchProfiles: Set<ISearchProfile>;
  headline: string;
  content: string;
  order: number;
  headlinePosition: DocumentPosition[];
  contentPosition: DocumentPosition[];
}

export interface IOccurrence {
  searchProfile: ISearchProfile;
  from: number;
  to: number;
  term: string;
  match: RegExpMatchArray;
  isHidden?: boolean;
}
export interface IDocument {
  filePath: string;
  fileName: string;
  parts: IDocumentPart[];
}

export interface ISearchProfileDocuments<T extends IDocument> {
  searchProfile: ISearchProfile;
  documents: T[];
}

export interface IHandledDocumentPart extends IDocumentPart {
  occurrences: IOccurrence[];
  headlineOccurrences: IOccurrence[];
}
export interface IHandledDocument extends IDocument {
  parts: IHandledDocumentPart[];
}

export interface IHighlightChunk {
  start: number;
  end: number;
  highlight: SearchProfileColor | null;
}

const NON_WORD_CHARACTERS = '[^a-zA-Z0-9åäöÅÄÖÆØæøšŠžŽ]';
export const WORD_BEGINNING = `(?<=^|${NON_WORD_CHARACTERS})`;
export const WORD_ENDING = `(?=$|${NON_WORD_CHARACTERS})`;
