import { makeVar } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useReactiveVariable } from 'src/helpers/reactiveVariables';
import { EXPORT_STATUS_TYPE } from 'src/models/workspace/types';

interface IExportBidspaceStatus {
  [key: string]: { status: EXPORT_STATUS_TYPE | null; operationId: string | null };
}

export const exportBidspaceStatusVar = makeVar<IExportBidspaceStatus>({});
export function useExportBidspaceStatus(): [
  IExportBidspaceStatus,
  (operationId: string, status: EXPORT_STATUS_TYPE, bidspaceId?: string) => void
] {
  const [exportStatus, setExportStatus] = useReactiveVariable<IExportBidspaceStatus>(exportBidspaceStatusVar);

  const updateExportStatus = useCallback(
    (operationId: string, status: EXPORT_STATUS_TYPE, bidspaceId?: string) => {
      if (operationId === 'all' && status === 'finished') {
        Object.entries(exportStatus).forEach(([key, value]) => {
          // set all pending operations' status to finished
          if (value.status === 'pending') {
            setExportStatus({
              ...exportStatus,
              [key]: {
                operationId: value.operationId,
                status
              }
            });
          }
        });
        return;
      }
      const currentItem = Object.entries(exportStatus).find(([, value]) => value.operationId === operationId);

      if (currentItem) {
        const [currentExportKey, currentExportValue] = currentItem;

        if (currentExportValue.status === 'finished') {
          return;
        }

        setExportStatus({
          ...exportStatus,
          [currentExportKey]: {
            operationId,
            status
          }
        });
      } else if (bidspaceId) {
        setExportStatus({
          ...exportStatus,
          [bidspaceId]: {
            operationId,
            status
          }
        });
      }
    },
    [exportStatus, setExportStatus]
  );

  return useMemo(() => [exportStatus, updateExportStatus], [exportStatus, updateExportStatus]);
}

export default useExportBidspaceStatus;
