import { Dispatch, SetStateAction, createContext } from 'react';
import { BidChecklist } from '.';

export type BidChecklistContextValue = {
  data: BidChecklist | null;
  loading: boolean;
};

export const BidChecklistContext = createContext<BidChecklistContextValue>({
  data: null,
  loading: false
});

export const ChecklistExpandedContext = createContext<boolean>(false);
ChecklistExpandedContext.displayName = 'ChecklistExpandedContext';

export const ToggleChecklistContext = createContext<Dispatch<SetStateAction<boolean>> | null>(null);
ToggleChecklistContext.displayName = 'ToggleChecklistContext';
