import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../index.module.scss';
import boxStyles from 'src/shared/Tender/Boxes/EditableBox/index.module.scss';
import { BlockTitle } from 'src/shared/Blocks';
import { billing_title_icon, SupplierTransactions } from '../../types';
import {
  ApiTenderTransactionData,
  ApiTenderTransactionsInput,
  PeriodTransaction
} from 'src/models/procurements/Billing/types';
import BillingCumulativeLineChart from '../../CumulativeLineChart';
import BillingBarStackChart from '../../BarStackChart';
import MonthlyPaymentTable from '../../MonthlyPaymentTable';
import { IApiOrganization } from 'src/models/organisations/types';
import { formatDate, getDatesFromDuration, getDurationFromPeriod, PeriodType } from 'src/helpers/dates';
import SupplierListDemo from '../../SupplierList/SupplierListDemo';
import { AnalyticsDemoBanner } from 'src/shared';

interface Props {
  className?: string;
  boxStyle?: boolean;
}

const BillingChartsDemo: FC<Props> = ({ className, boxStyle = true }) => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState<SupplierTransactions | undefined>(undefined);

  const suppliers: IApiOrganization[] = useMemo(
    () => [
      { organisationName: `${t('Company.company')} 1`, organisationNumber: 'DEMO1' },
      { organisationName: `${t('Company.company')} 2`, organisationNumber: 'DEMO2' },
      { organisationName: `${t('Company.company')} 3`, organisationNumber: 'DEMO3' },
      { organisationName: `${t('Company.company')} 4`, organisationNumber: 'DEMO4' },
      { organisationName: `${t('Company.company')} 5`, organisationNumber: 'DEMO5' }
    ],
    [t]
  );

  const demoVars: ApiTenderTransactionsInput = {
    contractStart: '2021-09-17',
    contractEnd: '2022-07-15',
    periodType: 'month',
    extensionEndDates: [],
    dateFrom: '2021-06-17',
    dateTo: '2022-10-15',
    buyerOrgIds: [],
    supplierOrgIds: []
  };

  const demoData: ApiTenderTransactionData = {
    currency: 'SEK',
    organisations: suppliers,
    transactions: [
      // Company 1 (upward trend)
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 1_300_000,
        cumulativeValue: 1_300_000,
        currency: 'SEK',
        period: '2021-06'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 2_800_000,
        cumulativeValue: 4_100_000,
        currency: 'SEK',
        period: '2021-07'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 4_700_000,
        cumulativeValue: 8_800_000,
        currency: 'SEK',
        period: '2021-08'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 1_100_000,
        cumulativeValue: 9_900_000,
        currency: 'SEK',
        period: '2021-09'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 100_000,
        cumulativeValue: 10_000_000,
        currency: 'SEK',
        period: '2021-10'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 2_000_000,
        cumulativeValue: 12_000_000,
        currency: 'SEK',
        period: '2021-11'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 1_000_000,
        cumulativeValue: 13_000_000,
        currency: 'SEK',
        period: '2021-12'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 100_000,
        cumulativeValue: 13_100_000,
        currency: 'SEK',
        period: '2022-01'
      },
      {
        buyerOrgId: 'DEMO1',
        supplierOrgId: 'DEMO1',
        amount: 900_000,
        cumulativeValue: 14_000_000,
        currency: 'SEK',
        period: '2022-02'
      },
      // Company 2 (upward trend)
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 55_000,
        cumulativeValue: 55_000,
        currency: 'SEK',
        period: '2021-06'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 347_000,
        cumulativeValue: 402_000,
        currency: 'SEK',
        period: '2021-07'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 4_698_000,
        cumulativeValue: 5_100_000,
        currency: 'SEK',
        period: '2021-08'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 4_000_000,
        cumulativeValue: 9_100_000,
        currency: 'SEK',
        period: '2021-09'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 1_900_000,
        cumulativeValue: 11_000_000,
        currency: 'SEK',
        period: '2021-10'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 1_000_000,
        cumulativeValue: 12_000_000,
        currency: 'SEK',
        period: '2021-11'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 2_000_000,
        cumulativeValue: 14_000_000,
        currency: 'SEK',
        period: '2021-12'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 2_000_000,
        cumulativeValue: 16_000_000,
        currency: 'SEK',
        period: '2022-01'
      },
      {
        buyerOrgId: 'DEMO2',
        supplierOrgId: 'DEMO2',
        amount: 300_000,
        cumulativeValue: 16_300_000,
        currency: 'SEK',
        period: '2022-02'
      },
      // Company 3
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 463_000,
        cumulativeValue: 463_000,
        currency: 'SEK',
        period: '2021-06'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 737_000,
        cumulativeValue: 1_200_000,
        currency: 'SEK',
        period: '2021-07'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 2_500_000,
        cumulativeValue: 3_700_000,
        currency: 'SEK',
        period: '2021-08'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 2_000_000,
        cumulativeValue: 5_700_000,
        currency: 'SEK',
        period: '2021-09'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 900_000,
        cumulativeValue: 6_600_000,
        currency: 'SEK',
        period: '2021-10'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 800_000,
        cumulativeValue: 7_400_000,
        currency: 'SEK',
        period: '2021-11'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 600_000,
        cumulativeValue: 8_000_000,
        currency: 'SEK',
        period: '2021-12'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 600_000,
        cumulativeValue: 8_600_000,
        currency: 'SEK',
        period: '2022-01'
      },
      {
        buyerOrgId: 'DEMO3',
        supplierOrgId: 'DEMO3',
        amount: 1_000_000,
        cumulativeValue: 9_600_000,
        currency: 'SEK',
        period: '2022-02'
      },
      // Company 4
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 88_000,
        cumulativeValue: 88_000,
        currency: 'SEK',
        period: '2021-06'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 167_000,
        cumulativeValue: 255_000,
        currency: 'SEK',
        period: '2021-07'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 515_000,
        cumulativeValue: 770_00,
        currency: 'SEK',
        period: '2021-08'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 530_000,
        cumulativeValue: 1_300_000,
        currency: 'SEK',
        period: '2021-09'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 300_000,
        cumulativeValue: 1_600_000,
        currency: 'SEK',
        period: '2021-10'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 400_000,
        cumulativeValue: 2_000_000,
        currency: 'SEK',
        period: '2021-11'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 400_000,
        cumulativeValue: 2_400_000,
        currency: 'SEK',
        period: '2021-12'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 700_000,
        cumulativeValue: 3_100_000,
        currency: 'SEK',
        period: '2022-01'
      },
      {
        buyerOrgId: 'DEMO4',
        supplierOrgId: 'DEMO4',
        amount: 1_900_000,
        cumulativeValue: 5_000_000,
        currency: 'SEK',
        period: '2022-02'
      },
      // Company 5
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 0,
        cumulativeValue: 0,
        currency: 'SEK',
        period: '2021-06'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 160_000,
        cumulativeValue: 160_000,
        currency: 'SEK',
        period: '2021-07'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 220_000,
        cumulativeValue: 380_000,
        currency: 'SEK',
        period: '2021-08'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 120_000,
        cumulativeValue: 500_000,
        currency: 'SEK',
        period: '2021-09'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 190_000,
        cumulativeValue: 690_000,
        currency: 'SEK',
        period: '2021-10'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 10_000,
        cumulativeValue: 700_000,
        currency: 'SEK',
        period: '2021-11'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 100_000,
        cumulativeValue: 800_000,
        currency: 'SEK',
        period: '2021-12'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 200_000,
        cumulativeValue: 1_000_000,
        currency: 'SEK',
        period: '2022-01'
      },
      {
        buyerOrgId: 'DEMO5',
        supplierOrgId: 'DEMO5',
        amount: 100_000,
        cumulativeValue: 1_100_000,
        currency: 'SEK',
        period: '2022-02'
      }
    ],
    cumulativeTransactions: [
      { from: '2021-06', to: '2021-09', value: 9_000_000, currency: 'SEK' },
      { from: '2021-06', to: '2021-12', value: 15_000_000, currency: 'SEK' },
      { from: '2021-06', to: '2022-02', value: 21_000_000, currency: 'SEK' }
    ],
    buyerCoverageGaps: []
  };

  const currency = 'SEK';
  const theClassName = classNames({
    [styles.container]: !boxStyle,
    [boxStyles.editableBox]: boxStyle,
    [className ?? '']: className
  });

  const onClick = useCallback(
    async (d: PeriodTransaction) => {
      const duration = getDurationFromPeriod(d.period, demoVars?.periodType as PeriodType);
      const dateFrom = formatDate(duration[0]);
      const dateTo = formatDate(duration[1]);
      const periodType = 'month';
      setCurrent(undefined);
      const transactions = demoData.transactions;
      const supplier = suppliers.find(supplier => supplier.organisationNumber === d.supplierOrgId);
      if (!!transactions.length && supplier) {
        setCurrent({
          period: d.period,
          dates: getDatesFromDuration([dateFrom, dateTo], periodType),
          transactions,
          supplier
        });
      }
    },
    [demoData.transactions, demoVars?.periodType, suppliers]
  );

  return (
    <>
      <BlockTitle className={styles.header} title={t('Tenders.Billing.title')} icon={billing_title_icon} />
      <AnalyticsDemoBanner />
      <BillingCumulativeLineChart
        className={theClassName}
        loading={false}
        error={false}
        data={demoData}
        vars={demoVars}
        displaySupplierLimits={0}
      />
      <BillingBarStackChart
        className={theClassName}
        loading={false}
        error={false}
        data={demoData}
        vars={demoVars}
        displaySupplierLimits={0}
        onClick={onClick}
      >
        <MonthlyPaymentTable currency={currency} data={current} vars={demoVars} loading={false} />
      </BillingBarStackChart>
      <SupplierListDemo className={theClassName} />
    </>
  );
};

export default BillingChartsDemo;
