import { gql } from '@apollo/client';
import { USER_NAME_FIELDS } from '../queries';
export const TEAM_FIELDS = gql`
  fragment teamFields on Team {
    id
    name
    color
    members {
      ...userNameFields
    }
    createdBy {
      ...userNameFields
    }
  }
  ${USER_NAME_FIELDS}
`;

export const CREATE_TEAM = gql`
  mutation createTeam($name: String!, $color: String, $members: [String!]!) {
    createTeam(input: { name: $name, color: $color, members: $members }) {
      ...teamFields
    }
  }
  ${TEAM_FIELDS}
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($teamId: String!, $name: String!, $color: String, $members: [String!]) {
    updateTeam(input: { teamId: $teamId, name: $name, color: $color, members: $members }) {
      ...teamFields
    }
  }
  ${TEAM_FIELDS}
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($teamId: String!) {
    deleteTeam(input: { teamId: $teamId })
  }
`;

export const LEAVE_TEAM = gql`
  mutation leaveTeam($teamId: String!) {
    leaveTeam(input: { teamId: $teamId }) {
      ...teamFields
    }
  }
  ${TEAM_FIELDS}
`;
