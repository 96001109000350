import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toStatusName } from 'src/models/workspace/WorkspaceStatus/helpers';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import styles from './index.module.scss';
import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import Tooltip from 'src/common/Tooltip';

interface Props {
  status: IWorkspaceStatus | WorkspaceStatusCategory;
}

export const BidCategoryBadge: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  const name = typeof status === 'object' && 'name' in status ? toStatusName(status.name, t) : toStatusName(status, t);

  return (
    <span className={classNames(styles.badge, styles[`theme${typeof status === 'object' ? status.category : status}`])}>
      <Tooltip title={name}>
        <span className={styles.statusName}>{name}</span>
      </Tooltip>
    </span>
  );
};
