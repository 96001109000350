import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { WorkspacePicker, RejectTendersButton } from 'src/shared';
import { BidItemType } from '@tendium/prom-types/tender';
import { ConditionalWrapper, StarToggler, Tooltip } from 'src/common';
import { useOpenTenderPreview } from 'src/models/procurements/hooks';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { useUpdateIsStarredTender } from 'src/models/procurements/hooks';
import { useApiTenders } from 'src/models/procurements/Tenders/hooks';
import { ApiTendersItem, TendersItem } from 'src/models/procurements/Tenders/types';
import { useTenderActionEventSource } from 'src/segment/hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  tender: TendersItem<ApiTendersItem>;
  showStarred?: boolean;
  disableActions?: boolean;
}

export const OverviewBoardActionsSingle: FC<Props> = ({ tender, showStarred = true, disableActions }) => {
  const { id, rejected: isRejected, score, isStarred, nextId } = tender;
  const name = tender.tender?.title;
  const bid =
    !!tender.bids && !!tender.bids.length ? { id: tender.bids[0].id, wsId: tender.bids[0].workspace.id } : undefined;
  const [{ vars }] = useApiTenders();
  const [updateTenderIsStarred] = useUpdateIsStarredTender();
  const openNextTenderPreview = useOpenTenderPreview();
  const currentSidebar = useCurrentSidebar();
  const eventSource = useTenderActionEventSource();
  const { t } = useTranslation();

  const isMatchedTab = useMemo(() => vars.matchingProfileId, [vars.matchingProfileId]);

  const onSetStarred = useCallback(
    (id: string, name: string, isStarred: boolean) => {
      updateTenderIsStarred(id, name, isStarred, 'OverviewBoardActionsSingle');
    },
    [updateTenderIsStarred]
  );

  const onActions = useCallback(() => {
    if (currentSidebar?.mode === SidebarMode.PROCUREMENT_INFO) {
      openNextTenderPreview(nextId);
    }
  }, [currentSidebar?.mode, openNextTenderPreview, nextId]);

  return (
    <ul className={styles.actionsSingleTender}>
      {isRejected && isMatchedTab && !bid?.wsId && (
        <ConditionalWrapper
          condition={!!disableActions}
          wrapper={children => (
            <Tooltip title={t('Tenders.rejectDisabled')} overlayClassName={styles.tooltip}>
              {children}
            </Tooltip>
          )}
        >
          <li className={classNames(styles.actionSingleTender, styles.rejectAction)} onClick={e => e.stopPropagation()}>
            <RejectTendersButton
              mode={'medium'}
              procurements={[{ id, isRejected, relevanceScore: score ?? undefined }]}
              eventSource={eventSource}
              eventType={'OverviewBoardActionsSingle'}
              onFinish={onActions}
              disabled={disableActions}
            />
          </li>
        </ConditionalWrapper>
      )}
      {!bid?.wsId && (
        <ConditionalWrapper
          condition={!!disableActions}
          wrapper={children => (
            <Tooltip title={t('Tenders.addToBidspaceDisabled')} overlayClassName={styles.tooltip}>
              {children}
            </Tooltip>
          )}
        >
          <li className={styles.actionSingleTender} onClick={e => e.stopPropagation()}>
            <WorkspacePicker
              originIds={bid ? undefined : [id]}
              bidIds={bid ? [bid] : undefined}
              originType={bid ? undefined : BidItemType.Procurement}
              eventSource={eventSource}
              eventType={'OverviewBoardActionsSingle'}
              mode={'medium'}
              size={'xs'}
              onChange={onActions}
              disabled={disableActions}
              relevanceScores={[tender.score]}
            />
          </li>
        </ConditionalWrapper>
      )}
      {!isRejected && isMatchedTab && bid?.wsId === undefined && (
        <ConditionalWrapper
          condition={!!disableActions}
          wrapper={children => (
            <Tooltip title={t('Tenders.rejectDisabled')} overlayClassName={styles.tooltip}>
              {children}
            </Tooltip>
          )}
        >
          <li className={classNames(styles.actionSingleTender, styles.rejectAction)} onClick={e => e.stopPropagation()}>
            <RejectTendersButton
              mode={'medium'}
              procurements={[{ id, isRejected, relevanceScore: score ?? undefined }]}
              eventSource={eventSource}
              eventType={'OverviewBoardActionsSingle'}
              onFinish={onActions}
              disabled={disableActions}
            />
          </li>
        </ConditionalWrapper>
      )}
      {isRejected && bid?.wsId && (
        <>
          <ConditionalWrapper
            condition={!!disableActions}
            wrapper={children => (
              <Tooltip title={t('Tenders.addToBidspaceDisabled')} overlayClassName={styles.tooltip}>
                {children}
              </Tooltip>
            )}
          >
            <li className={styles.actionSingleTender} onClick={e => e.stopPropagation()}>
              <WorkspacePicker
                originIds={bid ? undefined : [id]}
                bidIds={bid ? [bid] : undefined}
                originType={bid ? undefined : BidItemType.Procurement}
                eventSource={eventSource}
                eventType={'OverviewBoardActionsSingle'}
                mode={'medium'}
                size={'xs'}
                onChange={onActions}
                disabled={disableActions}
                relevanceScores={[tender.score]}
              />
            </li>
          </ConditionalWrapper>
          <ConditionalWrapper
            condition={!!disableActions}
            wrapper={children => (
              <Tooltip title={t('Tenders.rejectDisabled')} overlayClassName={styles.tooltip}>
                {children}
              </Tooltip>
            )}
          >
            <li
              className={classNames(styles.actionSingleTender, styles.rejectAction)}
              onClick={e => e.stopPropagation()}
            >
              <RejectTendersButton
                mode={'medium'}
                procurements={[{ id, isRejected, relevanceScore: score ?? undefined }]}
                eventSource={eventSource}
                eventType={'OverviewBoardActionsSingle'}
                onFinish={onActions}
                disabled={disableActions}
              />
            </li>
          </ConditionalWrapper>
        </>
      )}
      {showStarred && (
        <li className={styles.actionSingleTender} onClick={e => e.stopPropagation()}>
          <StarToggler
            isStarred={isStarred}
            onSetStarred={onSetStarred}
            id={id}
            mode={'full'}
            className={styles.starButton}
            name={name}
          />
        </li>
      )}
    </ul>
  );
};

export default OverviewBoardActionsSingle;
