import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { DatePicker, Form, Button } from 'src/common';
import { IBoxFieldDateRange } from 'src/models/procurements/Tender/types';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';

const { RangePicker } = DatePicker;

interface IFormData {
  range: [number | undefined, number | undefined];
}

interface IProps extends IBlockEditProps<IBoxFieldDateRange> {}

export const EditableBlockDateRange: FC<IProps> = ({ field, onChange, onFinish, className, containerRef }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { id: fieldId, from, to } = field;

  const onChangeValues = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);
    onChange &&
      onChange(
        field.update(
          value.range[0] ? new Date(value.range[0]).setUTCHours(0, 0, 0, 0) : null,
          value.range[1] ? new Date(value.range[1]).setUTCHours(23, 59, 59, 0) : null
        )
      );
    onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        range: [!!from ? dayjs(from) : null, !!to ? dayjs(to) : null]
      }
    };
  }, [fieldId, from, to]);

  const onOpenChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (vis: any) => {
      !vis && onFinish && onFinish();
    },
    [onFinish]
  );

  const onDeleteField = useCallback(() => {
    onChange && onChange(field.update(null, null));
    onFinish && onFinish();
  }, [field, onChange, onFinish]);

  return (
    <div className={className} ref={containerRef}>
      <Form form={form} initialValues={initialValues} className={styles.container}>
        <Form.Item name={[fieldId, 'range']} className={styles.field}>
          <RangePicker
            className={styles.range}
            bordered={false}
            separator={'-'}
            allowClear={false}
            onChange={onChangeValues}
            dropdownClassName={styles.rangePicker}
            defaultOpen
            onOpenChange={onOpenChange}
            getPopupContainer={node => node.parentNode as HTMLElement}
            renderExtraFooter={() => (
              <Button
                type={'text'}
                icon={<FontAwesomeIcon icon={faTimes} />}
                onClick={onDeleteField}
                className={styles.deleteButton}
              >
                {t('Common.Blocks.deleteDate')}
              </Button>
            )}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditableBlockDateRange;
