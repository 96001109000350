import React, { FC } from 'react';
import { Skeleton } from 'src/common';
import MpItem from './MpItem';
import { useLoadAllMps } from 'src/models/matchingProfiles/hooks';

interface Props {
  sidebarIsOpen?: boolean;
}

export const MpsList: FC<Props> = props => {
  const { sidebarIsOpen } = props;

  const { data: mps, loading } = useLoadAllMps();

  return (
    <>
      {(!mps || loading) && <Skeleton active />}
      {mps && mps.map(mp => <MpItem sidebarIsOpen={sidebarIsOpen} mp={mp} key={mp.id} />)}
    </>
  );
};

export default MpsList;
