import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faCoin, faTimer } from '@fortawesome/pro-light-svg-icons';
import styles from './index.module.scss';
import { Paths } from 'src/pages/paths';
import { toDateRangeStr } from 'src/helpers/dates';
import { toCurrencyValue } from 'src/models/procurements/Billing/helpers';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'src/common';
import { IdentifiableTenderObject } from 'src/models/procurements/Tenders/types';

interface Props {
  tenderObject: IdentifiableTenderObject;
}

export const TenderListItem: FC<Props> = ({ tenderObject }) => {
  const { id, tender } = tenderObject;
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <a href={`${Paths.TENDER_ROUTE}${id}`} rel="noopener noreferrer" target="_blank" className={styles.item}>
      <span className={styles.title}>{tender.title}</span>
      <span className={styles.info}>
        <span>
          <FontAwesomeIcon icon={faTimer} className={styles.icon} />
          {toDateRangeStr([tender.contractPeriod?.startDate, tender.contractPeriod?.maxExtentDate], t)}
        </span>
        <span>
          <FontAwesomeIcon icon={faCoin} className={styles.icon} />
          {toCurrencyValue(language, tender.value?.amount, tender.value?.currency, true)}
        </span>
      </span>
    </a>
  );
};
