import { BidItemType } from '@tendium/prom-types/tender';
import { AccessControlPolicy } from 'src/models/acl/types';
import { PageView } from 'src/models/users/Preferences/types';
import { safeCallSegment } from '../helpers';

export function trackMoveItemsToWorkspace(
  itemsIds: readonly string[],
  itemType: BidItemType,
  ws: string,
  eventSource: string,
  type: string | undefined,
  id?: string,
  relevanceScores?: (number | null | undefined)[]
): void {
  safeCallSegment(() => {
    analytics.track(`Move ${itemType === BidItemType.Procurement ? `procurements` : `call-offs`} to WS`, {
      itemsIds,
      ws,
      eventSource,
      type,
      id,
      relevanceScores
    });
  });
}

export function trackAddWorkspace(ws: { id: string; name: string }, subscribersPolicy: AccessControlPolicy): void {
  safeCallSegment(() => {
    analytics.track('Add Workspace', {
      ws,
      subscribersPolicy
    });
  });
}

export function trackUpdateWorkspacePolicy(
  ws: { id: string; name?: string },
  subscribersPolicy: AccessControlPolicy
): void {
  safeCallSegment(() => {
    analytics.track('Update Workspace subscribers policy', {
      ws,
      subscribersPolicy
    });
  });
}

export function trackUpdateWorkspaceOwners(ws: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Update Workspace owners', {
      ws
    });
  });
}

export function trackUpdateWorkspaceSubscribers(ws: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Update Workspace subscribers', {
      ws
    });
  });
}

export function trackUpdateWorkspaceSubscribersAndOwners(ws: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Update Workspace subscribers and owners', {
      ws
    });
  });
}

export function trackSwitchWorkspace(ws: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Switch Workspace', {
      ws
    });
  });
}

export function trackSwitchWorkspaceViewMode(ws: { id: string; name?: string }, mode: PageView): void {
  safeCallSegment(() => {
    analytics.track('Switch Workspace mode', {
      ws,
      mode
    });
  });
}

export function trackArchiveWorkspace(workspace: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Workspace:Archive', {
      workspace
    });
  });
}

export function trackDeleteWorkspace(workspace: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Workspace:Delete', {
      workspace
    });
  });
}

export function trackRestoreWorkspace(workspace: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Workspace:Restore', {
      workspace
    });
  });
}

export function trackAddStageToWorkspace(workspace: { id: string; name?: string }, categoryName: string): void {
  safeCallSegment(() => {
    analytics.track('WS add stage', {
      workspace,
      categoryName
    });
  });
}

export function trackDeleteStageFromWorkspace(workspace: { id: string; name?: string }, categoryName: string): void {
  safeCallSegment(() => {
    analytics.track('WS delete stage', {
      workspace,
      categoryName
    });
  });
}

export function trackOpenBidPreviewInWorkspace(
  workspace: { id: string; name?: string },
  eventSource: string,
  categoryName?: string
): void {
  safeCallSegment(() => {
    analytics.track('WS open bid preview', {
      workspace,
      categoryName,
      eventSource
    });
  });
}

export function trackWorkspaceTableSorting(workspace: { id: string; name?: string }, columnName?: string): void {
  safeCallSegment(() => {
    analytics.track('WS table sorting', {
      workspace,
      columnName
    });
  });
}

export function trackWorkspaceKanbanSorting(workspace: { id: string }, columnName?: string): void {
  safeCallSegment(() => {
    analytics.track('WS Kanban sorting', {
      workspace,
      columnName
    });
  });
}
