import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { Translations } from 'src/lib/I18nService/types';
import { useExistingTranslation } from 'src/common/hooks';
import { useTranslation } from 'react-i18next';
import NutsCodes from 'src/shared/Tender/NutsCodes';
import classNames from 'classnames';
import { IBoxFieldArray } from 'src/models/procurements/Tender/types';
import { IBlockProps } from 'src/shared/Tender/Boxes/types';
import BlockEmpty from '../Empty';

interface IProps extends IBlockProps<IBoxFieldArray> {}
export const BlockList: FC<IProps> = ({ field, translation, className }) => {
  const { array: list, unit } = field;
  const { i18n } = useTranslation(Translations.nutsCodes);
  const { existingT } = useExistingTranslation(translation ?? Translations.procurements);
  const { t } = useTranslation();
  const isNutsCodes = useMemo(
    () => list && list.some(item => i18n.exists(item, { ns: Translations.nutsCodes })),
    [i18n, list]
  );

  return list ? (
    isNutsCodes ? (
      <NutsCodes nutsCodes={list} />
    ) : (
      <div className={styles.container}>
        <ul className={classNames(styles.block, !!className && className, { [styles.withUnit]: !!unit })}>
          {list.map((item, index) => (
            <li key={`${item}_${index}`} className={styles.item}>
              {existingT(item)}
            </li>
          ))}
        </ul>
        {unit && <span className={styles.unit}>{t(unit, { count: list.length })}</span>}
      </div>
    )
  ) : (
    <BlockEmpty />
  );
};

export default BlockList;
