import { NavSidebarType } from './index';
import { NavSidebarContext, UseNavSidebar } from './context';
import { useMe } from 'src/common';
import { useCallback, useContext, useMemo, useState } from 'react';
import { isBoolean } from 'src/helpers';
import { useMyPreferences, useUpdateSidebarPrefs } from 'src/models/users/Preferences/hooks';

export function useNavSidebarCtx(type?: NavSidebarType): UseNavSidebar {
  const { data: paidUser } = useMe();
  const { data: prefsData } = useMyPreferences();
  const isWideScreen = useMemo(() => window.outerWidth >= 1440, []);
  const updateSidebarPrefs = useUpdateSidebarPrefs();
  const paidPrefs = useMemo(
    () =>
      paidUser
        ? isBoolean(prefsData?.getMe.account?.settings?.minimal)
          ? !prefsData?.getMe.account?.settings?.minimal
          : undefined
        : undefined,
    [paidUser, prefsData?.getMe.account?.settings?.minimal]
  );
  const [isOpen, toggleOpen] = useState<boolean>(() =>
    type ? type === 'max' : paidUser ? paidPrefs ?? isWideScreen : isWideScreen
  );
  const toggle = useCallback(() => {
    if (type) return;
    if (paidUser) {
      updateSidebarPrefs(isOpen);
    }
    toggleOpen(() => !isOpen);
  }, [isOpen, paidUser, toggleOpen, type, updateSidebarPrefs]);

  return useMemo(() => [isOpen, toggle], [isOpen, toggle]);
}
export function useNavSidebar(): UseNavSidebar {
  return useContext(NavSidebarContext);
}
