import { WebhookEventType } from '@tendium/prom-types';

export enum WebhookMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export interface UpdateWebhookBidspaceSettings {
  workspaceId: string;
  webhookId: string;
  mode: WebhookMode;
}

export interface WebhookBidspaceSettings {
  companyId: string;
  mode: WebhookMode;
  webhookId: string;
  workspaceId: string;
}

export interface IApiWebhook {
  id: string;
  name: string;
  url: string;
  eventType: WebhookEventType;
  secret: string;
  bidspaceSetting?: WebhookBidspaceSettings;
}

export enum CreateBidWebhookStatus {
  Unknown = 'Unknown',
  Success = 'Success',
  Failed = 'Failed'
}

export interface WebhookStatus {
  changedAt: Date;
  eventType: WebhookEventType;
  message?: string;
  status: CreateBidWebhookStatus;
}

export interface CreateWebhookStatus extends Omit<WebhookStatus, 'changedAt'> {
  relatedEntityId: string;
}

export interface ApiWebhookResponse {
  getWebhooks: IApiWebhook[];
}

export interface ApiWebhookVars {
  workspaceId: string;
}
