/**
 * Analytics can be blocked by add-block or antivirus.
 * We can not be even sure that `analytics` variable is exist.
 * @param cb
 */
export function safeCallSegment(cb: () => void): void {
  if (typeof analytics === 'undefined') {
    return;
  }
  try {
    cb();
  } catch (e) {
    console.error(e);
  }
}
