import React, { FC, useMemo } from 'react';
import styles from '../Box/index.module.scss';
import { BlockLabel, BlockEmpty } from 'src/shared/Blocks';
import LotsSups from 'src/shared/LotsSups';
import BoxActions from '../BoxActions';
import { IBoxCollectionProps } from '../types';
import { InfoIcon, useExistingTranslation } from 'src/common';
import { useTranslation } from 'react-i18next';
import BoxField from '../BoxField';
import { isString } from 'src/helpers';
import { useBoxProps } from '../hooks';
import { Translations } from 'src/lib/I18nService/types';

export const BoxCollection: FC = () => {
  const {
    boxes,
    title,
    isFirstField,
    isInfo,
    isEditable,
    config,
    actions = true
  } = useBoxProps() as IBoxCollectionProps;
  const { t } = useTranslation();
  const { existingT: tenderT } = useExistingTranslation(Translations.procurements);
  const firstBox = useMemo(() => (!!boxes.length ? boxes[0] : undefined), [boxes]);
  const notEmptyBoxes = useMemo(() => boxes.filter(box => !box.isEmpty), [boxes]);

  return (
    <>
      {title && (
        <div className={styles.header}>
          <div className={styles.title}>
            <BlockLabel label={isString(title) ? tenderT(title) : title} />
            {!!firstBox && !!firstBox.lots.length && <LotsSups lots={firstBox.lots} />}
            {isInfo && <InfoIcon desc={t('DetailsPage.additionAvailable')} />}
            {!!actions && (
              <>
                {actions}
                {firstBox && <BoxActions box={firstBox} />}
              </>
            )}
          </div>
        </div>
      )}
      <div className={styles.content}>
        {!!notEmptyBoxes.length ? (
          notEmptyBoxes.map(box =>
            isFirstField && box.firstField ? (
              <BoxField
                box={box}
                field={box.firstField}
                key={box.firstField.id}
                isEditable={!!isEditable}
                config={config}
              />
            ) : (
              !!box.fields.length &&
              box.fields.map(field => (
                <BoxField
                  box={box}
                  field={field}
                  config={{ ...config, isTitle: !!config?.isTitle }}
                  key={field.id}
                  isEditable={!!isEditable}
                />
              ))
            )
          )
        ) : firstBox && firstBox.firstField ? (
          <BoxField box={firstBox} field={firstBox?.firstField} config={config} isEditable={!!isEditable} />
        ) : (
          <BlockEmpty />
        )}
      </div>
    </>
  );
};

export default BoxCollection;
