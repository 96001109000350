import { Switch as BaseSwitch } from 'antd';
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { SwitchChangeEventHandler } from 'antd/lib/switch';

interface ISwitchProps {
  size?: 'small' | 'default';
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: SwitchChangeEventHandler;
  onClick?: SwitchChangeEventHandler;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  title?: string;
  custom?: boolean;
}
export const Switch: FC<ISwitchProps> = props => {
  const { custom, className, size, ...restProps } = props;
  return (
    <BaseSwitch
      {...restProps}
      className={classNames(className, { [styles.switch]: custom === undefined || custom })}
      size={custom === undefined || custom ? 'default' : size}
    />
  );
};

export default Switch;
