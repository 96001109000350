import React, { useCallback, FC, useMemo } from 'react';
import { Form, Input, Button, Modal } from 'src/common';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { useGenerateInbox, getUpdateCacheOnGenerateInbox } from 'src/models/callOffs/hooks';
import classNames from 'classnames';
import { trackAddCallOffInbox } from 'src/segment/events';
import { useNavigate } from 'react-router';
import { Paths } from 'src/pages/paths';
import { toInboxId } from 'src/models/callOffs/helpers';

interface IFormState {
  inboxName: string;
}

interface IProps {
  onClose: () => void;
}

export const GenerateInbox: FC<IProps> = props => {
  const { onClose: onCloseModal } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [createInbox, { loading: inboxCreating }] = useGenerateInbox();

  const initialValues = useMemo((): IFormState => {
    return {
      inboxName: ''
    };
  }, []);

  const onFinish = useCallback(
    (values: IFormState) => {
      const { inboxName } = values;
      createInbox({ variables: { inboxName: inboxName }, update: getUpdateCacheOnGenerateInbox() })
        .then(data => {
          const generatedInbox = data.data?.generateInbox.emailAddress;
          form.resetFields();
          if (!!generatedInbox) {
            trackAddCallOffInbox({ id: generatedInbox });
            form.resetFields();
            onCloseModal();
            navigate({ pathname: `${Paths.CALL_OFFS}/${toInboxId(generatedInbox)}` });
          }
        })
        .catch(() => {
          form.setFields([
            {
              name: 'inboxName',
              errors: [t('Common.unknownErrorDesc')]
            }
          ]);
        });
    },
    [createInbox, form, navigate, onCloseModal, t]
  );

  return (
    <Modal
      title={<span>{t('CallOffs.addNewInboxTitle')}</span>}
      visible
      onCancel={onCloseModal}
      maskClosable
      width={560}
      footer={null}
      keyboard={false}
    >
      <div className={styles.modalContent}>
        <p className={styles.desc}>{t('CallOffs.generateInboxDesc')}</p>
        <Form
          form={form}
          onFinish={onFinish}
          className={styles.content}
          layout={'vertical'}
          initialValues={initialValues}
        >
          <Form.Item
            label={t('CallOffs.inboxName')}
            name={'inboxName'}
            rules={[
              { required: true, message: t('Common.inputRequired', { inputName: t('CallOffs.inboxName') }) },
              { max: 30, message: t('Common.inputTooLong', { inputName: t('CallOffs.inboxName') }) }
            ]}
          >
            <Input type={'text'} data-name={'inboxName'} placeholder={t('CallOffs.inboxNamePlaceholder')} />
          </Form.Item>
          <div className={styles.formSubmit}>
            <Button className={classNames(styles.ghostButton, styles.button)} onClick={onCloseModal} type={'text'}>
              {t('Common.cancel')}
            </Button>
            <Button
              htmlType={'submit'}
              loading={inboxCreating}
              type={'primary'}
              className={classNames(styles.defaultButton, styles.button)}
            >
              {t('Common.add')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default GenerateInbox;
