import styles from './index.module.scss';
import React, { FC, useCallback, useState } from 'react';
import HeaderAction from '../HeaderAction';
import { Dropdown } from 'src/common';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faQuestionCircle as faQuestionCircleSolid } from '@fortawesome/pro-solid-svg-icons';

export const FaqMenu: FC = () => {
  const { t, i18n } = useTranslation();

  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuVisible(!menuVisible);
  }, [menuVisible]);

  return (
    <Dropdown
      trigger={['click']}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      visible={menuVisible}
      onVisibleChange={toggleMenu}
      overlayClassName={styles.faqMenu}
      overlay={
        <div className={styles.menu}>
          <div className={styles.actions}>
            <a
              href={`${import.meta.env.VITE_HELPDOCS_DOMAIN}${i18n.language}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.action}
            >
              <FontAwesomeIcon icon={faQuestionCircle} className={styles.actionIcon} />
              <span className={styles.actionTitle}>{t('Header.knowledgebankLink')}</span>
            </a>
          </div>
        </div>
      }
    >
      <HeaderAction>
        <FontAwesomeIcon icon={faQuestionCircleSolid} className={styles.icon} />
      </HeaderAction>
    </Dropdown>
  );
};
export default FaqMenu;
