import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Button, UserName, useMe, Form, Input, Editor } from 'src/common';
import { notUndefined } from 'src/helpers/typescript';
import { IApiComment, CommentParentType } from 'src/models/comments/types';
import { TrackEventSource } from 'src/segment/events';
import { AssignedTo, DELETED_USER } from 'src/models/users/types';
import { useCreateComment } from 'src/models/comments/hooks';
import classNames from 'classnames';
import { CommentsMode } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { useFeatureFlag, FeatureFlag } from 'src/helpers/featureFlag';
import { EditorStateContent, clearEditor, useEditorRef } from 'src/common/Editor';

function getMentionedNames(contents: readonly string[]): Set<string> {
  return contents.reduce((previousValue, currentValue) => {
    const el = document.createElement('html');
    el.innerHTML = currentValue;
    const records = el.getElementsByTagName('span');
    for (const record of records) {
      const res = record.getAttribute('data-id');
      res && previousValue.add(res);
    }
    return previousValue;
  }, new Set<string>());
}

interface IProps {
  onCancel?: () => void;
  parentId: string;
  existingComments?: readonly IApiComment[];
  parentType: CommentParentType;
  mode: CommentsMode;
  users: AssignedTo[];
  eventSource: TrackEventSource;
}
interface IFormState {
  shareWith: string;
}

export const CommentCreate: FC<IProps> = (props: IProps) => {
  const { onCancel, existingComments, parentId, parentType, mode, users, eventSource } = props;
  const [isTouched, setIsTouched] = useState(false);
  const { t } = useTranslation();

  const [isSharing, setIsSharing] = useState(false);
  const onToggleShare = useCallback(() => {
    setIsSharing(!isSharing);
  }, [isSharing]);
  const onHideShare = useCallback(() => {
    setIsSharing(false);
  }, []);

  const { data: me } = useMe();

  const isGuestFeature = useFeatureFlag(FeatureFlag.Interactions_ShareWithGuestUsers);

  const [editorContent, setEditorContent] = useState<EditorStateContent | undefined>();

  const ref = useRef<HTMLDivElement>(null);
  const sendButtonRef = React.createRef<HTMLButtonElement>();

  const [form] = Form.useForm();
  const initialValues = useMemo((): IFormState => {
    return {
      shareWith: ''
    };
  }, []);

  const [createComment, { loading: isLoading }] = useCreateComment();
  const editor = useEditorRef();

  const onClose = useCallback(() => {
    editor.current && clearEditor(editor.current);
    setEditorContent(undefined);
    onHideShare();
    form.setFieldsValue({ shareWith: '' });
    setIsTouched(false);
  }, [editor, form, onHideShare]);

  const onCreate = useCallback(() => {
    onClose();
  }, [onClose]);

  const onFinish = useCallback(
    (values: IFormState) => {
      if (!!editorContent?.content) {
        const { htmlContent, mentions } = editorContent;
        const { shareWith } = values;
        const previousNames = getMentionedNames(existingComments?.map(c => c.content) || []);
        const previousMentions = Array.from(previousNames).filter(notUndefined);
        createComment({
          content: htmlContent,
          parentId,
          mentions: mentions.map(mention => mention.mention),
          previousMentions,
          parentType,
          onCreate,
          shareWith: isGuestFeature ? shareWith : undefined,
          eventSource
        });
      }
    },
    [createComment, editorContent, eventSource, existingComments, isGuestFeature, onCreate, parentId, parentType]
  );

  const onClickCancel = useCallback(() => {
    onCancel && onCancel();
    onClose();
  }, [onCancel, onClose]);

  const onCommentFieldClick = useCallback(() => {
    setIsTouched(true);
  }, []);

  const onChange = useCallback((detail: EditorStateContent) => {
    setEditorContent(detail);
  }, []);

  return (
    <div className={classNames(styles.commentForm, { [styles[mode]]: mode })} ref={ref}>
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={initialValues}
        className={styles.commentFormInner}
      >
        <UserName mode={'onlyAvatar'} user={me || DELETED_USER} className={styles.commentPic} />
        <div className={styles.commentFieldset}>
          {isGuestFeature && (
            <Form.Item
              className={classNames(styles.sharingField, { [styles.isSharing]: isSharing })}
              name={'shareWith'}
              rules={[
                {
                  type: 'email',
                  message: t('Common.incorrectEmailError'),
                  validateTrigger: 'onBlur'
                }
              ]}
            >
              <Input placeholder={t('Comments.shareToEmail')} autoComplete={'email'} type={'email'} />
            </Form.Item>
          )}
          <div className={styles.commentField} onClick={onCommentFieldClick}>
            <Editor
              editorRef={editor}
              mentions={users}
              className={classNames(styles.commentInput, { [styles.isFocused]: isTouched })}
              onChange={onChange}
              placeholder={t('Comments.addNewComment')}
            />
          </div>
          {isTouched && (
            <div className={styles.commentSubmit}>
              <Button
                className={styles.submitButton}
                htmlType={'submit'}
                data-action={'addComment'}
                loading={isLoading}
                type={'primary'}
                ref={sendButtonRef}
                tabIndex={0}
                disabled={!editorContent?.content}
              >
                {t(isSharing ? 'Comments.shareComment' : 'Comments.addComment')}
              </Button>
              <Button onClick={onClickCancel} type={'text'}>
                {t('Common.cancel')}
              </Button>
              {isGuestFeature && (
                <Button
                  className={styles.shareButton}
                  onClick={onToggleShare}
                  data-action={'share'}
                  tabIndex={0}
                  icon={<FontAwesomeIcon icon={faShareNodes} />}
                >
                  {t('Comments.share')}
                </Button>
              )}
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CommentCreate;
