import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { EmptyFilter, Heading, List, Paging, PagingProps } from 'src/common';
import { SupplierListItem } from './SupplierListItem';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  IdentifiableTenderObject,
  SupplierContractObject,
  SUPPLIERS_PAGE_SIZE
} from 'src/models/procurements/Tenders/types';

export interface SupplierContractsProps {
  supplierContractObjects: SupplierContractObject[];
  dynamicLoadedTenders?: IdentifiableTenderObject[]; // if specified, always display this value when expending
  className?: string;
  loading?: boolean;
  itemLoading?: boolean;
  pageSize?: number;
  title?: ReactNode;
  onExpend?: (data?: SupplierContractObject) => void;
  hideEmptyState?: boolean;
  emptyStateText?: string;
}

export const SupplierContracts: FC<SupplierContractsProps> = ({
  supplierContractObjects,
  dynamicLoadedTenders,
  pageSize = SUPPLIERS_PAGE_SIZE,
  title,
  className,
  loading,
  itemLoading = false,
  onExpend,
  hideEmptyState,
  emptyStateText
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedId, setExpandedId] = useState<string | undefined>();

  const onChange: PagingProps['onChange'] = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const currentSuppliers = useMemo(
    () => supplierContractObjects.slice(pageSize * (currentPage - 1), pageSize * currentPage),
    [currentPage, supplierContractObjects, pageSize]
  );

  const totalPages = useMemo(() => supplierContractObjects.length, [supplierContractObjects]);
  const visible = useMemo(
    () => !hideEmptyState || supplierContractObjects.length,
    [hideEmptyState, supplierContractObjects.length]
  );
  const emptyState = !hideEmptyState ? (
    <EmptyFilter className={styles.message} title={''} desc={emptyStateText ?? t('Common.noResultsFound')} />
  ) : undefined;

  return (
    <>
      {(visible || loading) && (
        <div className={classNames(styles.container, className)}>
          <div>
            <Heading title={title ?? t('Tenders.Billing.multipleContracts')} />
            <List items={currentSuppliers} loading={loading} emptyState={!loading ? emptyState : undefined}>
              {(item: SupplierContractObject) => (
                <SupplierListItem
                  dynamicLoadedTenders={dynamicLoadedTenders}
                  supplierContractObject={item}
                  expandedId={expandedId}
                  setExpandedId={setExpandedId}
                  onExpend={onExpend}
                  loading={itemLoading}
                />
              )}
            </List>
          </div>
          <Paging
            defaultCurrent={1}
            current={currentPage}
            onChange={onChange}
            pageSize={pageSize}
            total={totalPages}
            className={styles.paging}
          />
        </div>
      )}
    </>
  );
};

export default SupplierContracts;
