import React, { FC } from 'react';
import { IBoxProps, isBoxBase, isBoxCollection, isBoxCustom, isBoxReverse } from '../types';
import { TenderBlockSize } from 'src/models/procurements/Tender/types';
import BoxBase from '../BoxBase';
import BoxCustom from '../BoxCustom';
import BoxCollection from '../BoxCollection';
import BoxReverse from '../BoxReverse';
import InfoBox from '../InfoBox';
import { BlockEmpty } from 'src/shared/Blocks';
import { BoxProvider } from '../providers';

export const TenderBox: FC<IBoxProps> = props => {
  const { size = TenderBlockSize.Full } = props;

  return props.title && !props.boxes && !props.box ? (
    <InfoBox title={props.title} value={<BlockEmpty />} size={size} />
  ) : (
    <BoxProvider props={props}>
      {isBoxBase(props) && <BoxBase />}
      {isBoxCollection(props) && <BoxCollection />}
      {isBoxCustom(props) && <BoxCustom />}
      {isBoxReverse(props) && <BoxReverse />}
    </BoxProvider>
  );
};

export default TenderBox;
