/**
 * Typescript guard to check on null
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
 */
export function notNull<T>(u: T | null): u is T {
  return u !== null;
}

/**
 * Typescript guard to check on undefined
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
 */
export function notUndefined<T>(u: T | undefined): u is T {
  return u !== undefined;
}

/**
 * Make all properties in T writable
 * @see Readonly
 */
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

type UnionKeys<T> = T extends T ? keyof T : never;
type StrictUnionHelper<T, TAll> = T extends unknown
  ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>>
  : never;
export type StrictUnion<T> = StrictUnionHelper<T, T>;

export type Maybe<T> = null | undefined | T;
