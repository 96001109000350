import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
interface Props {
  wrapper: (children: ReactNode | undefined) => ReactElement;
  condition: boolean;
}
export const ConditionalWrapper: FC<PropsWithChildren<Props>> = ({ condition, wrapper, children }) => {
  if (condition) {
    return wrapper(children);
  }
  return <>{children}</>;
};
export default ConditionalWrapper;
