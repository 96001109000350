import {
  useQuery,
  useMutation,
  ApolloCache,
  useSubscription,
  OperationVariables,
  MutationUpdaterFn
} from '@apollo/client';
import { QueryResult, MutationTuple } from '@apollo/client/react/types/types';
import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import {
  GET_WORKSPACES,
  GET_WORKSPACE,
  GET_WORKSPACES_IDS,
  CREATE_WORKSPACE,
  DELETE_WORKSPACE,
  ARCHIVE_WORKSPACE,
  RESTORE_WORKSPACE,
  GET_WORKSPACE_STATISTIC,
  EXPORT_BIDSPACES,
  ON_EXPORT_BIDSPACES_FILE_READY,
  GET_ACTIVE_WORKSPACES
} from './queries';
import { useLocation, useParams } from 'react-router-dom';
import {
  IWorkspace,
  IApiWorkspace,
  IWorkspaceStatistic,
  DEFAULT_WORKSPACE_VIEW,
  IExportBidspacesInput,
  EXPORT_BIDSPACES_KEY,
  IApiExportBidspaces,
  IExportBidspacesOutput,
  ICreateWorkspaceInput,
  ICreateWorkspaceOutput,
  MIN_INDICATOR_DURATION,
  DEFAULT_WORKSPACES_VIEW
} from './types';
import { useFeatureFlag, FeatureFlag } from 'src/helpers/featureFlag';
import { useActiveUsers, useAllUsersAndTeams } from '../users/hooks';
import { AssignedTo } from 'src/models/users/types';
import { AccessControlPolicy } from '../acl/types';
import { Spinner, notification } from 'src/common';
import { PageView } from '../users/Preferences/types';
import { usePagePreferences } from '../users/Preferences/hooks';
import { trackAddWorkspace } from 'src/segment/events';
import { useTranslation } from 'react-i18next';
import { Workspace } from '.';
import useBidsSearchArgs from 'src/reactiveVars/BidsSearchArgsVar';
import { downloadFile } from 'src/helpers';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import styles from 'src/common/Spinner/index.module.scss';
import { useExportBidspaceStatus } from 'src/reactiveVars';
import { BidspacesExportedSub } from '@tendium/prom-types/subscriptions';
import { useAllTeams } from '../users/Team/hooks';
import { Paths } from 'src/pages/paths';
import { Operation } from '../bids/Bids/hooks';
import { cache } from 'src/lib/API/graphql/cache';

export function useWorkspaces(skip?: boolean): Omit<QueryResult<{ getWorkspaces: IApiWorkspace[] }>, 'data'> & {
  data?: IWorkspace[];
} {
  const allUsersAndTeams = useAllUsersAndTeams();
  const query = useQuery<{ getWorkspaces: IApiWorkspace[] }>(GET_WORKSPACES, {
    skip
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? query.data.getWorkspaces.map(ws => new Workspace(ws, allUsersAndTeams)) : undefined
    }),
    [allUsersAndTeams, query]
  );
}

export function useActiveWorkspaces(skip?: boolean): Omit<
  QueryResult<{ getActiveWorkspaces: IApiWorkspace[] }>,
  'data'
> & {
  data?: IWorkspace[];
} {
  const allUsersAndTeams = useAllUsersAndTeams();
  const query = useQuery<{ getActiveWorkspaces: IApiWorkspace[] }>(GET_ACTIVE_WORKSPACES, {
    skip
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? query.data.getActiveWorkspaces.map(ws => new Workspace(ws, allUsersAndTeams)) : undefined
    }),
    [allUsersAndTeams, query]
  );
}

export function clearWsStatsCache(cache: ApolloCache<unknown>, wsId: string): void {
  cache.modify({
    fields: {
      getWorkspaceStats(cached, { storeFieldName, DELETE }) {
        return storeFieldName.includes(wsId) ? DELETE : cached;
      }
    }
  });
  cache.gc();
}

export interface IGetWsStatsRequest {
  workspaceId: string;
  rejected?: boolean;
}
export interface IGetWsStatsResponse {
  getWorkspaceStats: IWorkspaceStatistic;
}
export function useWorkspacesStatistic(): Omit<QueryResult<IGetWsStatsResponse, IGetWsStatsRequest>, 'data'> & {
  data?: IWorkspaceStatistic;
} {
  const [searchVar] = useBidsSearchArgs();
  const { data: ws } = useWorkspace();
  const wsId = ws?.id;
  const query = useQuery<IGetWsStatsResponse, IGetWsStatsRequest>(GET_WORKSPACE_STATISTIC, {
    variables: {
      workspaceId: wsId ?? '',
      rejected: searchVar.rejected
    },
    skip: !wsId
  });

  return useMemo(() => ({ ...query, data: query.data?.getWorkspaceStats ?? undefined }), [query]);
}

export function useWorkspacesIds(skip?: boolean): QueryResult<{ getWorkspaces: { id: string }[] }> {
  return useQuery<{ getWorkspaces: { id: string }[] }>(GET_WORKSPACES_IDS, {
    skip
  });
}

export function useWorkspaceViewState(): [PageView, (view: PageView) => void] {
  const [page, updatePage] = usePagePreferences();
  const { pathname: currentUrl } = useLocation();
  const isBidspaces = useMemo(() => currentUrl === Paths.BIDSPACES, [currentUrl]);

  const wsView = useMemo(() => {
    return page?.view ? page.view : isBidspaces ? DEFAULT_WORKSPACES_VIEW : DEFAULT_WORKSPACE_VIEW;
  }, [isBidspaces, page]);

  const setWsView = useCallback(
    (view: PageView) => {
      updatePage({ view });
    },
    [updatePage]
  );

  return useMemo(() => {
    return [wsView, setWsView];
  }, [wsView, setWsView]);
}

export function useWorkspace(wsId?: string): Omit<
  QueryResult<{ getWorkspace: IApiWorkspace }, { id?: string }>,
  'data'
> & {
  data?: IWorkspace;
} {
  const { wsId: activeWsId } = useParams<{ wsId: string }>();
  const id = useMemo(() => wsId ?? activeWsId, [activeWsId, wsId]);
  const allUsersAndTeams = useAllUsersAndTeams();
  const query = useQuery<{ getWorkspace: IApiWorkspace }, { id?: string }>(GET_WORKSPACE, {
    variables: {
      id
    },
    skip: !id
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? new Workspace(query.data.getWorkspace, allUsersAndTeams) : undefined
    }),
    [allUsersAndTeams, query]
  );
}

export function useWsUsersAndTeams(wsId?: string): AssignedTo[] {
  const { data: wsData } = useWorkspace(wsId);
  const companyUsers = useActiveUsers();
  const teams = useAllTeams();
  const wsOwnerUsers = wsData?.owners;
  const wsSubscriberUsers = wsData?.subscribers;
  const wsOwnerPolicy = wsData?.accessControl.editPolicy;
  const wsSubscriberPolicy = wsData?.accessControl.subscribePolicy;

  return useMemo(() => {
    const isPolicyAll =
      (wsOwnerPolicy && wsOwnerPolicy === AccessControlPolicy.ALL) ||
      (wsSubscriberPolicy && wsSubscriberPolicy === AccessControlPolicy.ALL);

    if (!isPolicyAll) {
      const wsUsers = (wsOwnerUsers && wsSubscriberUsers && [...wsOwnerUsers, ...wsSubscriberUsers]) || [];
      // filter out teams without authorised users in this bidspace
      const teamsWithAccess = teams.filter(
        team => !!team.members.some(teamUser => wsUsers.some(user => user.id === teamUser.id))
      );
      return [...wsUsers, ...teamsWithAccess];
    }

    return [...companyUsers, ...teams];
  }, [companyUsers, teams, wsOwnerPolicy, wsOwnerUsers, wsSubscriberPolicy, wsSubscriberUsers]);
}

export function useDeleteWorkspace(): MutationTuple<unknown, { id: string | number }> {
  return useMutation<unknown, { id: string | number }>(DELETE_WORKSPACE);
}

export function useWorkspaceArchivingAvailable(): boolean {
  return useFeatureFlag(FeatureFlag.Workspace_Archive) || false;
}
export function useArchiveWorkspace(): [MutationTuple<unknown, { id: string | number }>, { enabled: boolean }] {
  const mutationPair = useMutation<unknown, { id: string | number }>(ARCHIVE_WORKSPACE);

  const enabled = useWorkspaceArchivingAvailable();
  return [mutationPair, { enabled }];
}

export function useRestoreWorkspace(): [MutationTuple<unknown, { id: string | number }>, { enabled: boolean }] {
  const enabled = useWorkspaceArchivingAvailable();
  const mutationPair = useMutation<unknown, { id: string | number }>(RESTORE_WORKSPACE);

  return [mutationPair, { enabled }];
}

export function updateCacheOnModifyWorkspaceIsActiveOrDelete(): void {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'getActiveWorkspaces' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'getBidCategoryStatistics' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'getAssignedToStatistics' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'getBids' });
  cache.gc();
}

export function useWorkspaceExportingAvailable(): boolean {
  return useFeatureFlag(FeatureFlag.Workspace_Export) || false;
}

export function useExportWorkspaces(): {
  exportWorkspaces: (bidspaceIds: string[]) => void;
  isExportInProgress: (bidspaceIds: string[]) => boolean;
  enabled: boolean;
} {
  const enabled = useWorkspaceExportingAvailable();
  const { t } = useTranslation();
  const [exportWorkspaces] = useMutation<IExportBidspacesOutput, IExportBidspacesInput>(EXPORT_BIDSPACES);
  const [exportStatus, updateExportStatus] = useExportBidspaceStatus();

  const isExporting = useMemo(
    () => Object.values(exportStatus).some(item => item.status === 'pending'),
    [exportStatus]
  );
  const exportIndicatorLastShown = useRef<null | number>(null);

  useEffect(() => {
    if (isExporting) {
      const timeout = setTimeout(() => {
        notification.info({
          description: t('BidSpaces.exportBidSpaceNotificationDescription'),
          message: t('BidSpaces.exportBidSpaceNotificationMessage'),
          duration: null,
          key: EXPORT_BIDSPACES_KEY,
          icon: React.createElement(Spinner, {
            icon: faSpinnerThird,
            className: styles.spinnerMedium
          })
        });
        exportIndicatorLastShown.current = new Date().getTime();
      }, 300);

      return () => clearTimeout(timeout);
    } else {
      const timeoutDuration = exportIndicatorLastShown.current
        ? MIN_INDICATOR_DURATION -
          Math.min(MIN_INDICATOR_DURATION, new Date().getTime() - exportIndicatorLastShown.current)
        : MIN_INDICATOR_DURATION;

      const timeout = setTimeout(() => {
        notification.close(EXPORT_BIDSPACES_KEY);
      }, timeoutDuration);

      return () => clearTimeout(timeout);
    }
  }, [t, isExporting, exportIndicatorLastShown]);

  const isExportInProgress = useCallback(
    (bidspaceIds: string[]) => {
      return exportStatus[bidspaceIds[0]]?.status === 'pending';
    },
    [exportStatus]
  );

  const exportWorkspacesFn = useCallback(
    (bidspaceIds: string[]) => {
      if (bidspaceIds.length === 0 || isExportInProgress(bidspaceIds)) {
        return;
      }

      exportWorkspaces({
        variables: { bidspaceIds }
      })
        .then(response => {
          if (!response.data) {
            return;
          }

          updateExportStatus(response.data.exportBidspaces.operationId, 'pending', bidspaceIds[0]);
        })
        .catch(() => {
          notification.error({
            description: t('BidSpaces.exportBidSpaceUnexpectedError'),
            message: t('BidSpaces.exportBidSpaceErrorMessage')
          });
        });
    },
    [exportWorkspaces, t, updateExportStatus, isExportInProgress]
  );
  return useMemo(
    () => ({
      exportWorkspaces: exportWorkspacesFn,
      isExportInProgress,
      enabled
    }),
    [enabled, exportWorkspacesFn, isExportInProgress]
  );
}

export function useExportWorkspacesSubscription(skip: boolean): void {
  const enabled = useWorkspaceExportingAvailable();
  const [exportStatus, updateExportStatus] = useExportBidspaceStatus();
  const { t } = useTranslation();
  const isExporting = useMemo(
    () => Object.values(exportStatus).some(item => item.status === 'pending'),
    [exportStatus]
  );

  useSubscription<{ bidspacesExported: IApiExportBidspaces }, OperationVariables>(ON_EXPORT_BIDSPACES_FILE_READY, {
    onData: ({ data: response }) => {
      if (!response.data) {
        return;
      }
      const bidspacesExported = response.data.bidspacesExported;
      updateExportStatus(bidspacesExported.operationId, 'finished');
      if (bidspacesExported.error) {
        notification.error({
          description: t(
            `BidSpaces.${
              bidspacesExported.error === BidspacesExportedSub.ExportingError.PayloadTooLarge
                ? 'exportBidSpacePayloadTooLargeError'
                : 'exportBidSpaceUnexpectedError'
            }`
          ),
          message: t('BidSpaces.exportBidSpaceErrorMessage')
        });
      }
      if (bidspacesExported.file) {
        const currentItem = Object.values(exportStatus).find(
          value => value.operationId === bidspacesExported.operationId
        );
        // Only download when operation was made in this session (Avoid downloading on other open tabs)
        currentItem && downloadFile(bidspacesExported.file.url, bidspacesExported.file.name);
      }
    },
    onError: () => {
      if (isExporting) {
        updateExportStatus('all', 'finished');
        notification.error({
          description: t('BidSpaces.exportBidSpaceUnexpectedError'),
          message: t('BidSpaces.exportBidSpaceErrorMessage')
        });
      }
    },
    skip: !enabled || skip
  });
}

export function useCreateWorkspace(): [
  (data: ICreateWorkspaceInput, selectedPolicy: AccessControlPolicy, onFinish?: (id: string | null) => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [createWS, { loading, error }] = useMutation<ICreateWorkspaceOutput, ICreateWorkspaceInput>(CREATE_WORKSPACE, {
    refetchQueries: [{ query: GET_WORKSPACES }, { query: GET_ACTIVE_WORKSPACES }]
  });

  const createWorkspaceFn = useCallback(
    (data: ICreateWorkspaceInput, selectedPolicy: AccessControlPolicy, onFinish?: (id: string | null) => void) => {
      const { name, color, owners, subscribers } = data;
      createWS({
        variables: {
          name,
          color,
          owners,
          subscribers
        },
        update: updateCacheOnCreateOrDeleteWorkspace('add')
      })
        .then(result => {
          if (result.data) {
            trackAddWorkspace(
              {
                id: result.data.createWorkspace.id,
                name: result.data.createWorkspace.name
              },
              selectedPolicy
            );
          }
          onFinish?.(result.data ? result.data.createWorkspace.id : null);
        })
        .catch(() => {
          onFinish?.(null);
          notification.error({
            message: t('Common.unknownError'),
            description: t('Common.unknownErrorDesc')
          });
        });
    },
    [createWS, t]
  );

  return [
    createWorkspaceFn,
    {
      loading,
      error
    }
  ];
}

export function updateCacheOnCreateOrDeleteWorkspace(
  operation: Operation
): MutationUpdaterFn<ICreateWorkspaceOutput | unknown> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const adjustment = operation === 'add' ? 1 : -1;

    cache.modify({
      fields: {
        getCompanyWorkspacesCount: (existing: number) => {
          return existing + adjustment;
        }
      }
    });
  };
}
