import React, { FC } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'src/common';

type Props = {
  winHitRateDecimal?: number;
  loading: boolean;
};
export const WinHitRateLabel: FC<Props> = ({ winHitRateDecimal, loading }) => {
  const { t } = useTranslation();
  const winHitRate = (winHitRateDecimal || 0) * 100;

  return (
    <div className={styles.container}>
      <FontAwesomeIcon icon={faAward} className={styles.icon} />
      <span className={styles.label}>{`${t('BidSpaces.winHitRate')}: `}</span>
      {loading ? <Spinner /> : <span className={styles.value}>{`${winHitRate.toFixed(0)}%`}</span>}
    </div>
  );
};

export default WinHitRateLabel;
