/**
 * ant-design validation RegEx for email used under the hood in Form.Item rules
 * @see: https://github.com/yiminghe/async-validator/blob/master/src/rule/type.ts
 */
export const pattern: Record<string, RegExp> = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
};

export function isEmail(value: unknown): boolean {
  return typeof value === 'string' && value.length <= 320 && !!value.match(pattern.email);
}
