import React, { FC, PropsWithChildren } from 'react';
import { Modal as BaseModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export const ModalComponent = BaseModal;

const BaseModalComponent: FC<PropsWithChildren & ModalProps> = ({ children, ...rest }) => {
  return <ModalComponent {...rest}>{children}</ModalComponent>;
};

interface IModalProps extends ModalProps {
  title: React.ReactNode;
  visible: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  footer?: React.ReactNode;
  closeIcon?: React.ReactNode;
  className?: string;
  closable?: boolean;
  maskClosable?: boolean;
  children: React.ReactNode;
}

export const Modal: FC<IModalProps> = props => {
  const {
    title,
    visible,
    onCancel,
    onOk,
    children,
    footer,
    className,
    closable,
    closeIcon = <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />,
    maskClosable,
    ...restProps
  } = props;

  return (
    <BaseModalComponent
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      footer={footer}
      className={classNames(styles.modal, className)}
      wrapClassName={styles.modalWrap}
      closable={closable}
      closeIcon={closeIcon}
      maskClosable={maskClosable}
      centered
      transitionName={'ant-fade'}
      {...restProps}
    >
      {children}
    </BaseModalComponent>
  );
};

export default Modal;
