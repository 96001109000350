import { QueryResult, Reference } from '@apollo/client';
import { ContentLibrary } from '.';
import { isObject, isString } from 'src/helpers';
import {
  ContentLibraryDTO,
  ContentLibraryFiltersDTO,
  ContentLibrarySortFields,
  ContentLibrarySortOnFieldsDTO,
  ContentLibrarySortOrder,
  GetContentLibraryDTO
} from '@tendium/prom-types/schema';
import { AccessControlPolicy } from 'src/models/acl/types';
import { AssignedTo } from 'src/models/users/types';
import { Room } from '../ContentLibraryRoom';
import { Order } from '@tendium/prom-types/dist/subscriptions/tenjin-answer';
import { ContentLibraryBulkImportSub } from '@tendium/prom-types/subscriptions';
export const DEFAULT_CONTENT_LIBRARY_LIMIT = 8;

export type DefaultInput<T> = {
  input: T;
};

export const NO_FILTER_STORE_FIELD_NAME = 'getContentLibraryContents:{"query":{"filters":{}}}';
export enum ContentLibraryEventSource {
  room = 'Room',
  contentLibrary = 'Content library',
  taskPage = 'Task page'
}

export enum ContentLibraryQueryParams {
  sort = 'sort',
  search = 'search',
  assignedTo = 'assignedTo',
  roomIds = 'roomIds',
  tagIds = 'tagIds',
  createdBy = 'createdBy',
  types = 'types'
}

export type ApiContentLibraryVars = {
  search?: string;
  roomIds?: string[];
  tagIds?: string[];
  createdBy?: string[];
  assignedTo?: string[];
  sort?: ContentLibrarySortOnFieldsDTO;
  offset?: number;
  limit?: number;
  types?: string[];
};

export type ContentLibraryQpVars = {
  sort?: ContentLibrarySortOnFieldsDTO;
  filters: ContentLibraryFiltersDTO;
};

export type ContentLibraryCached = {
  rows: Reference[];
  totalCount: number;
};

export type ContentLibraryCachedResult = {
  value: ContentLibraryCached;
  variables?: ApiContentLibraryVars;
};

export interface ApiContentLibraryResponseData {
  rows: ContentLibraryDTO[];
  totalCount: number;
}

export type ApiCreateRowResponse = {
  createContentLibraryContent: ContentLibraryDTO;
};

export interface ApiContentLibraryAllResponse {
  getContentLibraryContents: ApiContentLibraryResponseData;
}

export type ApiContentLibraryBulkUpdateResponse = {
  updateContentLibraryContents: ContentLibraryDTO[];
};

export type ApiContentLibraryUpdateResponse = {
  updateContentLibraryContent: ContentLibraryDTO;
};

export type ApiContentLibraryDeleteResponse = {
  deleteContentLibraryContent: boolean;
};

export type ApiDownloadTemplateUrlResponse = {
  getSignedUrlDownloadTemplateContentLibrary: string;
};

export type ApiContentLibraryBulkImportUrlResponse = {
  getSignedUrlBulkImportContentLibrary: {
    signedUrl: string;
    taskId: string;
  };
};

export type ApiContentLibraryImportStatus = {
  taskId: string;
  messageOrder: Order;
  user: {
    id: string;
  };
  error?: string;
  rowsProcessed: number;
  totalRows: number;
  failedRows: number;
  errorCode?: string;
  fileName: string;
};
export interface ContentLibraryAllResponseData
  extends Omit<QueryResult<ApiContentLibraryAllResponse, GetContentLibraryDTO>, 'data'> {
  data?: ContentLibrary;
  fetchingMore: boolean;
  updateVars: (vars: Partial<ApiContentLibraryVars>) => void;
  currentVars: GetContentLibraryDTO;
  clearVars: () => void;
  isFiltersApplied: boolean;
}

export interface ApiContentLibraryCountResponse {
  getContentLibraryContents: Pick<ApiContentLibraryResponseData, 'totalCount'>;
}

export interface ContentLibraryCountResponseData
  extends Omit<QueryResult<ApiContentLibraryCountResponse, GetContentLibraryDTO>, 'data'> {
  data?: number;
  fetchingMore: boolean;
}

export function isContentLibrarySortOrder(u: unknown): u is ContentLibrarySortOrder {
  return isString(u) && Object.values(ContentLibrarySortOrder).some(c => c === u);
}

export function isContentLibrarySortField(u: unknown): u is ContentLibrarySortFields {
  return isString(u) && Object.values(ContentLibrarySortFields).some(c => c === u);
}

export function isContentLibrarySort(u: unknown): u is ContentLibrarySortOnFieldsDTO {
  return (
    isObject(u) && 'key' in u && 'sort' in u && isContentLibrarySortField(u.key) && isContentLibrarySortOrder(u.sort)
  );
}

export function getUsersWithAccessToRooms(users: AssignedTo[], selectedRooms: Room[]): AssignedTo[] {
  if (selectedRooms.length === 0) return [];

  return (
    selectedRooms.reduce((acc: AssignedTo[] | null, room: Room): AssignedTo[] => {
      let currentUsers: AssignedTo[] = [];

      if (room.editPolicy === AccessControlPolicy.ALL || room.subscribePolicy === AccessControlPolicy.ALL) {
        currentUsers = users;
      } else {
        const roomUsers = new Set([...(room.owners ?? []), ...(room.subscribers ?? [])]);
        currentUsers = Array.from(roomUsers);
      }

      if (!acc) {
        return currentUsers;
      }

      return acc.filter(user => currentUsers.some(c => c.id === user.id));
    }, null) ?? []
  );
}

export type GetSignedUrlOutput = {
  getSignedUrlUpload: { putUrl: string; contentRowId: string };
};

export interface ApiUploadContentDocument {
  message: {
    contentLibraryRowId: string;
    documentStatus: string;
  } | null;
  error: string | null;
}

export interface ApiImportCsvContent {
  messageOrder: ContentLibraryBulkImportSub.Order;
  fileName?: string;
  error: string | null;
}

export type GetSingleContentInput = {
  id: string;
};

export type GetSingleContentOutput = {
  getContentLibraryContent: ContentLibraryDTO;
};

export type GetSignedUrlDownloadInput = {
  getSignedUrlDownloadTemplateContentLibrary: string;
};

export type GetSignedUrlDownloadOutput = {
  getSignedUrlDownloadTemplateContentLibrary: string;
};

export type GetSignedUrlBulkOutput = {
  getSignedUrlBulkImportContentLibrary: { signedUrl: string };
};

export type ApiContentLibraryUploadRequest = {
  targetPath: string;
  roomId: string;
};
