import React, { FC, useCallback, useEffect } from 'react';
import styles from './index.module.scss';
import Alert from 'src/common/Alert';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { GET_FRONTEND_VERSION } from './queries';
import { Button } from 'src/common/Button';
import { useClientVersionUpdate } from 'src/reactiveVars/clientVersionUpdateVar';
import { isPaidUser, useApp } from 'src/models/auth';

export const VersionUpdateAlert: FC = () => {
  const { t } = useTranslation();
  const { user } = useApp();

  const [clientVersionUpdate] = useClientVersionUpdate();
  const [getFrontendVersion, { loading }] = useLazyQuery<{
    getFrontendVersion: string;
  }>(GET_FRONTEND_VERSION, { fetchPolicy: 'no-cache' });

  const onFocus = useCallback(() => {
    if (!loading && isPaidUser(user)) {
      // no need to do anything. Let apollo link handle response header
      getFrontendVersion();
    }
  }, [getFrontendVersion, loading, user]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [onFocus]);

  const onReloadPage = useCallback(() => window.location.reload(), []);

  return (
    <>
      {clientVersionUpdate && (
        <Alert
          message={
            <>
              <span>{t('Maintenance.refreshNotification')}</span>
              <Button onClick={onReloadPage} type={'primary'} className={styles.button}>
                {t('Maintenance.reloadPage')}
              </Button>
            </>
          }
          type={'warning'}
          showIcon
          banner
        />
      )}
    </>
  );
};

export default VersionUpdateAlert;
