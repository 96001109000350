import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { faFaceDisappointed, faMedal, faPaperPlaneTop, faPen, faSeedling } from '@fortawesome/pro-light-svg-icons';
import { IIconMapper } from 'src/types/icon';
import { ColorMapper } from './types';

export const WORKSPACE_STATUS_CATEGORY_ICON_MAPPER: IIconMapper = {
  [WorkspaceStatusCategory.Interesting]: { defaultIcon: faSeedling, color: '#D48806' },
  [WorkspaceStatusCategory.InProgress]: { defaultIcon: faPen, color: '#3B3BC4' },
  [WorkspaceStatusCategory.Submitted]: { defaultIcon: faPaperPlaneTop, color: '#531DAB' },
  [WorkspaceStatusCategory.Won]: { defaultIcon: faMedal, color: '#389E0D' },
  [WorkspaceStatusCategory.Lost]: { defaultIcon: faFaceDisappointed, color: '#CF1322' }
};

export const WORKSPACE_STATUS_COLOR_MAPPER: ColorMapper = {
  [WorkspaceStatusCategory.Interesting]: { color: '#FFE58F', selectedColor: '#FAAD14' },
  [WorkspaceStatusCategory.InProgress]: { color: '#ADC6FF', selectedColor: '#5357EA' },
  [WorkspaceStatusCategory.Submitted]: { color: '#D3ADF7', selectedColor: '#722ED1' },
  [WorkspaceStatusCategory.Won]: { color: '#B7EB8F', selectedColor: '#52C41A' },
  [WorkspaceStatusCategory.Lost]: { color: '#FFA39E', selectedColor: '#F5222D' }
};
