import { ApolloError, MutationUpdaterFn, Reference, useMutation } from '@apollo/client';
import { CREATE_TEAM, DELETE_TEAM, LEAVE_TEAM, UPDATE_TEAM } from './queries';
import { useCallback, useMemo } from 'react';
import { notification, useMe } from 'src/common';
import { useTranslation } from 'react-i18next';
import {
  CreateTeamInput,
  CreateTeamResponse,
  DeleteTeamInput,
  DeleteTeamResponse,
  LeaveTeamInput,
  LeaveTeamResponse,
  Team,
  UpdateTeamInput,
  UpdateTeamResponse
} from './types';
import { trackCreateTeam, trackLeaveTeam, trackSaveTeam } from 'src/segment/events';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';
import { useEmployees } from '../hooks';

export function useAllTeams(): Team[] {
  const userTeamsAvailable = useFeatureFlag(FeatureFlag.UserTeams);
  const { data: employeesData } = useEmployees();
  return useMemo(
    () => (userTeamsAvailable ? employeesData?.teams ?? [] : []),
    [employeesData?.teams, userTeamsAvailable]
  );
}

export function useCreateTeam(): [
  (input: CreateTeamInput, options: { onComplete?: () => void }) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const { data: meData } = useMe();
  const companyId = meData?.company.id;
  const [createTeam, { loading, error }] = useMutation<CreateTeamResponse, CreateTeamInput>(CREATE_TEAM);

  const createTeamFn = useCallback(
    (input: CreateTeamInput, options: { onComplete?: () => void }) => {
      const { onComplete } = options;
      companyId &&
        !loading &&
        createTeam({
          variables: input,
          update: updateCacheOnCreateTeam(companyId)
        })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          })
          .then(res => {
            res?.data?.createTeam && trackCreateTeam(res.data.createTeam.id, res.data.createTeam.name);
          })
          .finally(() => {
            onComplete && onComplete();
          });
    },
    [companyId, createTeam, loading, t]
  );
  return useMemo(() => [createTeamFn, { loading, error }], [createTeamFn, error, loading]);
}

function updateCacheOnCreateTeam(companyId: string): MutationUpdaterFn<CreateTeamResponse> {
  return (cache, { data }) => {
    if (!data?.createTeam?.id) {
      return null;
    }

    const { id, name } = data.createTeam;

    trackCreateTeam(id, name);

    const companyRef = cache.identify({
      __typename: 'Company',
      id: companyId
    });
    cache.modify({
      id: companyRef,
      fields: {
        teams(existingRefs: Reference[], { toReference, readField }) {
          return existingRefs.some(ref => readField('id', ref) === id)
            ? existingRefs
            : [
                ...existingRefs,
                toReference({
                  __typename: 'Team',
                  id
                })
              ];
        }
      }
    });
  };
}

export function useUpdateTeam(): [
  (input: UpdateTeamInput, options: { onComplete?: () => void }) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [updateTeam, { loading, error }] = useMutation<UpdateTeamResponse, UpdateTeamInput>(UPDATE_TEAM);
  const updateTeamFn = useCallback(
    (input: UpdateTeamInput, options: { onComplete?: () => void }) => {
      const { onComplete } = options;
      !loading &&
        updateTeam({
          variables: input
        })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          })
          .then(res => {
            res?.data?.updateTeam && trackSaveTeam(res.data.updateTeam.id, res.data.updateTeam.name);
          })
          .finally(() => {
            onComplete && onComplete();
          });
    },
    [loading, t, updateTeam]
  );
  return useMemo(() => [updateTeamFn, { loading, error }], [updateTeamFn, error, loading]);
}

export function useDeleteTeam(): [
  (input: DeleteTeamInput, teamName: string | null) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [deleteTeam, { loading, error }] = useMutation<DeleteTeamResponse, DeleteTeamInput>(DELETE_TEAM);
  const deleteTeamFn = useCallback(
    (input: DeleteTeamInput, teamName: string | null) => {
      const { teamId } = input;
      !loading &&
        deleteTeam({
          variables: { teamId },
          update: updateCacheOnDeleteTeam(input)
        })
          .then(res => {
            res?.data?.deleteTeam && trackSaveTeam(teamId, teamName);
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [deleteTeam, loading, t]
  );
  return useMemo(() => [deleteTeamFn, { loading, error }], [deleteTeamFn, error, loading]);
}

function updateCacheOnDeleteTeam(input: DeleteTeamInput): MutationUpdaterFn<DeleteTeamResponse> {
  return (cache, { data }) => {
    if (!data?.deleteTeam) {
      return null;
    }

    const { teamId } = input;

    cache.evict({ id: cache.identify({ id: teamId, __typename: 'Team' }) });
    cache.gc();
  };
}

export function useLeaveTeam(): [(input: LeaveTeamInput) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const { data: meData } = useMe();
  const [leaveTeam, { loading, error }] = useMutation<LeaveTeamResponse, LeaveTeamInput>(LEAVE_TEAM);
  const leaveTeamFn = useCallback(
    (input: LeaveTeamInput) => {
      const { teamId } = input;
      !loading &&
        leaveTeam({
          variables: { teamId }
        })
          .then(res => {
            res?.data?.leaveTeam &&
              meData?.email &&
              trackLeaveTeam(res.data.leaveTeam.id, res.data.leaveTeam.name, meData.email);
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [leaveTeam, loading, meData?.email, t]
  );
  return useMemo(() => [leaveTeamFn, { loading, error }], [leaveTeamFn, error, loading]);
}
