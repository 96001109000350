export const Paths = {
  ROOT: '/',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PROCUREMENT_TABLE_PAGE: '/details/table',
  TENDER_PAGE: ':id',
  TENDER_KEYWORDS_SEARCH: 'keyword-search',
  PROCUREMENT_DETAILS_PAGE: '/procurement-details/',
  BIDDING_PAGE: 'bid/:id',
  BIDDING_DRAFT_PAGE: 'tender/bid/:id/create-tasks' /** /bidding-tool/id/create-tasks */,
  TASK_PAGE: ':id' /* '/bid/:bidId/task/:id' */,
  TASK_ROUTE: '/task/',
  SETTINGS_PAGE: '/settings/',
  SETTINGS_PAGE_COMPANY_INFO: 'company',
  SETTINGS_PAGE_COMPANY_WEBHOOKS: 'webhooks',
  SETTINGS_PAGE_COMPANY_TOKENS: 'tokens',
  SETTINGS_PAGE_COMPANY_SECURITY: 'security',
  SETTINGS_PAGE_PROMPT_BATTERY: 'tender-questions',
  SETTINGS_PAGE_CUSTOM_BID_FIELDS: 'custom-bid-fields',
  SETTINGS_PAGE_ACCOUNT: 'account',
  SETTINGS_PAGE_NOTIFICATIONS: 'notifications',
  SETTINGS_PAGE_EMPLOYEES: 'employees',
  SETTINGS_PAGE_TEAMS: 'teams',
  SETTINGS_PAGE_COMPETITORS: 'competitors',
  ANALYTICS_PAGE: '/analytics/',
  BUYERS_PAGE: 'buyers',
  BUYER_PAGE: 'buyer/:orgId',
  SUPPLIERS_PAGE: 'suppliers',
  SUPPLIER_PAGE: 'supplier/:orgId',
  EXPIRING_CONTRACTS_ROUTE: 'expiring-contracts',
  EXPIRING_CONTRACTS_PAGE: 'expiring-contracts/:mpId',
  MARKET_DASHBOARD: 'market-insights',
  ADMIN_PAGE: '/admin/',
  ADMIN_PAGE_USERS_LIST: 'users',
  ADMIN_PAGE_COMPANIES_LIST: 'companies',
  ADMIN_PAGE_COMPANY_INFO: 'company/:companyId',
  AUTH: '/auth/',
  SIGN_IN: '/auth/sign-in',
  SIGN_IN_SSO: '/auth/sign-in-sso',
  SIGN_UP: '/auth/sign-up',
  CONFIRM_SIGN_UP: '/auth/confirm-sign-up',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  CHANGE_PASSWORD: '/auth/change-password',
  CONFIRM_2FA: '/auth/confirm-sign-in',
  SETUP_2FA: '/auth/setup-mfa',
  START: '/start/',
  COMPANY_INFO: '/start/company',
  INVITE_TEAM: '/start/invite',
  NOTIFICATIONS_PAGE: '/notifications',
  TENDER_ROUTE: '/tender/',
  BIDDING_GPT: '/response-generator',
  CONTENT_LIBRARY: '/lib',
  CONTENT_LIBRARY_ROOM: '/room/:id',
  ALL_TENDERS: '/all-tenders',
  STARRED_TENDERS: '/starred-tenders',
  MONITORING: '/monitoring',
  MONITORING_PAGE: '/monitoring/:mpId',
  CALL_OFFS: '/call-offs',
  INBOX_PAGE: '/call-offs/:inboxId',
  BIDSPACES: '/bidspaces',
  BIDSPACES_CREATE: '/bidspaces/create',
  BIDSPACES_ARCHIVED: '/bidspaces/archived',
  BIDSPACE_PAGE: '/bidspaces/:wsId',
  DOCS_LIB: '/document-library',
  BID_REPORT_PAGE: '/bid/:id'
};
