import { gql } from '@apollo/client';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';

export const ROOM_FIELDS = gql`
  fragment roomFields on ContentLibraryRoom {
    id
    title
    owners {
      ...assignedToFields
    }
    subscribers {
      ...assignedToFields
    }
    editPolicy
    subscribePolicy
    accessToEdit
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const CREATE_CONTENT_LIBRARY_ROOM = gql`
  mutation createContentLibraryRoom($input: CreateContentLibraryRoomInput!) {
    createContentLibraryRoom(input: $input) {
      ...roomFields
    }
  }
  ${ROOM_FIELDS}
`;

export const DELETE_CONTENT_LIBRARY_ROOM = gql`
  mutation DeleteContentLibraryRoom($input: DeleteContentLibraryRoomInput!) {
    deleteContentLibraryRoom(input: $input)
  }
`;

export const GET_CONTENT_LIBRARY_ROOMS = gql`
  query getContentLibraryRooms($ids: [String!]) {
    getContentLibraryRooms(query: { ids: $ids }) {
      ...roomFields
    }
  }
  ${ROOM_FIELDS}
`;

export const UPDATE_CONTENT_LIBRARY_ROOM = gql`
  mutation UpdateContentLibraryRoom($input: UpdateContentLibraryRoomInput!) {
    updateContentLibraryRoom(input: $input) {
      ...roomFields
    }
  }
  ${ROOM_FIELDS}
`;
