import React, { FC, useMemo } from 'react';
import { useMe, UserPicker } from 'src/common';
import { useTranslation } from 'react-i18next';
import { AssignedTo } from 'src/models/users/types';
import { useEmployees, useActiveUsersAndTeams } from 'src/models/users/hooks';
import InviteUsers from './InviteUsers';
import { FeatureFlag, useFeatureFlag } from '../../helpers/featureFlag';
import { Ownerships, ownership } from 'src/models/ownership';
import { RenderMode } from 'src/common/UserPicker';

export interface IAssignToProps {
  size?: (typeof Sizes)[number];
  assignedTo?: string | null;
  mode?: 'full' | 'short';
  disabled?: boolean;
  className?: string;
  defaultPopupContainer?: boolean;
  label?: string;
}

interface IProps extends IAssignToProps {
  onAssign: (assignedTo: AssignedTo) => void;
  onUnAssign?: () => void;
  isUpdating?: boolean;
  users?: AssignedTo[];
  renderMode?: RenderMode;
}

export const AssignToComponent: FC<IProps> = ({
  assignedTo,
  onUnAssign,
  onAssign,
  users: providedUsers,
  ...restProps
}) => {
  const { t } = useTranslation();
  const inviteTeammatesFeature = useFeatureFlag(FeatureFlag.InviteTeammates);

  const { data: me } = useMe();
  const inviteTeammatesAvailable = me?.isAdmin && inviteTeammatesFeature;
  const { loading: employeesLoading } = useEmployees();

  const activeUsers = useActiveUsersAndTeams();
  const users = useMemo(() => {
    return providedUsers ?? activeUsers;
  }, [activeUsers, providedUsers]);

  return (
    <UserPicker
      users={users}
      selected={assignedTo}
      label={t('Common.unassigned')}
      tooltip={t('Common.assignTo')}
      onSelect={onAssign}
      onUnSelect={onUnAssign}
      usersLoading={employeesLoading}
      userPickerType="single"
      {...restProps}
    >
      {inviteTeammatesAvailable && <InviteUsers />}
    </UserPicker>
  );
};

export const AssignTo: FC<IProps> = props => {
  const isAssignToFeature = ownership.name !== Ownerships.Clira;

  return isAssignToFeature ? <AssignToComponent {...props} /> : null;
};

export default AssignTo;
