import React, { FC } from 'react';
import { BlockLabel, BlockEmpty } from 'src/shared/Blocks';
import LotsSups from 'src/shared/LotsSups';
import BoxActions from '../BoxActions';
import { IBoxReverseProps } from '../types';
import BoxField from '../BoxField';
import { InfoIcon } from 'src/common';
import { useTranslation } from 'react-i18next';
import styles from '../Box/index.module.scss';
import innerStyles from './index.module.scss';
import BoxDeleteAction from '../BoxDeleteAction';
import classNames from 'classnames';
import { useBoxProps } from '../hooks';
import { Translations } from 'src/lib/I18nService/types';

export const BoxReverse: FC = () => {
  const {
    box,
    isFirstField,
    isEditable,
    isInfo,
    config,
    editable,
    title = true,
    actions = true
  } = useBoxProps() as IBoxReverseProps;
  const { t } = useTranslation();
  const { t: tenderT } = useTranslation(Translations.procurements);
  const { title: defaultTitle, lots, firstField, rawFields } = box;

  return (
    <>
      <div className={styles.header}>
        <div className={classNames(styles.title, innerStyles.title)}>
          {isFirstField ? (
            <>
              {firstField ? (
                <BoxField
                  box={box}
                  field={firstField}
                  isEditable={!!isEditable}
                  config={config}
                  className={innerStyles.field}
                />
              ) : (
                <BlockEmpty />
              )}
            </>
          ) : rawFields.length ? (
            rawFields.map(field => (
              <BoxField
                box={box}
                field={field}
                isEditable={!!isEditable}
                config={config}
                key={field.id}
                className={innerStyles.field}
              />
            ))
          ) : (
            <BlockEmpty />
          )}
          {!!lots?.length && <LotsSups lots={lots} />}
          {isInfo && <InfoIcon desc={t('DetailsPage.additionAvailable')} />}
          {!!actions && (
            <>
              {actions}
              <BoxActions box={box} />
            </>
          )}
        </div>
        {editable && <BoxDeleteAction box={box} />}
      </div>
      <div className={styles.content}>
        <BlockLabel label={title === true ? tenderT(defaultTitle) : title} className={styles.label} />
      </div>
    </>
  );
};

export default BoxReverse;
