import { TenderBoxFieldType } from '@tendium/prom-types/tender';
import {
  IBoxField,
  ITenderBox,
  BoxRequirementStatus,
  ITenderBoxDataSource,
  ITenderLot,
  TenderCatName,
  TenderSubCatName,
  IBoxFieldGeneric,
  IBoxFieldString,
  IBoxFieldCurrency,
  IBoxFieldDate,
  IBoxFieldUrl,
  IBoxFieldBoolean,
  IBoxFieldArray,
  IApiTenderBox,
  IApiTenderLot,
  IApiBoxDataSource,
  IApiBoxFieldString,
  IApiBoxFieldDate,
  IApiBoxFieldArray,
  IApiBoxFieldBoolean,
  IBoxFieldDateRange,
  BoxFieldRangeUnit,
  IBoxFieldRange,
  IBoxFieldNumber,
  IApiBoxFieldCurrency,
  IApiBoxFieldRange,
  IApiBoxFieldUrl,
  IApiBoxField,
  IBoxFieldCurrencyRange,
  IApiBoxFieldCurrencyRange,
  IApiBoxFieldNumber,
  IApiBoxFieldDateRange,
  IBoxFieldEmail,
  TenderKeys
} from './Tender/types';
import { IApiComment } from '../comments/types';
import { v4 } from 'uuid';
import { TRUNC_CHARS_MAX, BOOLEAN_ANSWERS, URL_MARKERS, EMAIL_BOX_SPEC_IDS } from './Tender/mappers';
import { Maybe, Writeable } from 'src/helpers/typescript';
import { BoxSpecId, BOX_SPEC_KEY_MAPPER } from './types';
import { isNotUndefined } from 'src/helpers';
import { isEmail as isEmailCheck } from 'src/helpers/validation';
import { fieldFilter, toCorrectEmail } from './helpers';
import { splitFileExt } from 'src/helpers/files';
import { isFileExt } from '../file/types';

const disrespectfulLinkNames = ['ID/Link', 'URL'];

export class TenderBox implements ITenderBox {
  public readonly id: string;
  public readonly title: string;
  public readonly category: string;
  public readonly specificationId: string;
  public readonly requirementStatus: BoxRequirementStatus | null = null;
  public readonly lots: ITenderLot[] = [];
  public readonly comments: IApiComment[] = [];
  public readonly order?: number = 0;
  public readonly dataSource?: ITenderBoxDataSource = undefined;
  private convertedFields: IBoxField[] = [];

  constructor(private readonly box: IApiTenderBox, public readonly keyName?: TenderKeys) {
    this.id = box.id;
    this.specificationId = box.specificationId ?? this.id;
    this.category = box.category ?? `${TenderCatName.Overview}.${TenderSubCatName.Others}`;
    this.title = box.title;
    this.lots = !!box.lots?.length ? this.convertLots(box.lots) : [];
    this.comments = box.comments || [];
    this.order = box.order;
    this.dataSource = box.dataSource ? this.convertDataSource(box.dataSource) : undefined;
    this.requirementStatus = box.requirementStatus;
    this.keyName = keyName;
    this.convertedFields = !!this.box.fields.length
      ? this.box.fields.map(field => this.convertField(field))
      : this.box.specificationId === BoxSpecId.PROCURING_AGENCY
      ? [
          this.createEmptyField(this.box.specificationId, `Procuring agency`),
          this.createEmptyField(this.box.specificationId, `Procuring agency's national ID`)
        ]
      : [this.createEmptyField(this.box.specificationId)];
  }
  public get fields(): IBoxField[] {
    return !!this.convertedFields.length
      ? this.convertedFields
          .filter(field => (field.isChanged || field.name === 'Estimated contract value' ? field : fieldFilter(field)))
          .filter(isNotUndefined)
      : [];
  }

  public get rawFields(): IBoxField[] {
    switch (this.box.specificationId) {
      case BoxSpecId.EVALUATION_MODEL_3:
        return !!this.fields.length && this.fields[0].name === 'Base for award of contract'
          ? this.fields
          : [this.convertedFields[0], ...this.fields];
      case BoxSpecId.NUMBER_OF_SUPPLIERS_ASSIGNED_CONTRACT:
        return !!this.fields.length && this.fields[0].name === 'Number of suppliers'
          ? this.fields
          : [
              this.convertedFields.find(field => field.name === 'Number of suppliers') ?? this.convertedFields[0],
              ...this.fields
            ];
      case BoxSpecId.ESTIMATED_CONTRACT_VALUE:
      case BoxSpecId.ESTIMATED_CONTRACT_VALUE_2:
      case BoxSpecId.TYPE_OF_PROCEDURE_2:
        return !!this.fields.length && this.fields[0].name === this.box.title
          ? this.fields
          : [this.convertedFields[0], ...this.fields];
      case BoxSpecId.PROCURING_AGENCY:
        return this.convertedFields;
      default:
        return !!this.fields.length ? this.fields : !!this.convertedFields.length ? [this.convertedFields[0]] : [];
    }
  }

  public get firstField(): IBoxField | undefined {
    return !!this.rawFields.length ? this.rawFields[0] : undefined;
  }

  public get originId(): string {
    return this.id.substring(this.id.lastIndexOf('#') + 1, this.id.length);
  }

  public get heliumId(): string {
    return this.originId.split('_')[0];
  }

  public get isEmpty(): boolean {
    return !this.fields.length || false;
  }

  public get isCustom(): boolean {
    return this.box.specificationId === 'PROM_CUSTOM_BID_FIELD';
  }

  private toCorrectName(name: string): string {
    if (!name) return '';
    return name.endsWith(':') ? name.slice(0, -1) : disrespectfulLinkNames.includes(name) ? '' : name;
  }

  private toCorrectLink(link?: Maybe<string>): string | undefined {
    if (!link) return;
    return link.startsWith('http') || link.startsWith('//')
      ? link
      : link.startsWith('www')
      ? `https://${link}`
      : `//${link}`;
  }

  private createEmptyField(specId: BoxSpecId | string, title?: string): IBoxField {
    const name = title ?? this.box.title;
    switch (specId) {
      case BoxSpecId.MAIN_CPV_CODE:
      case BoxSpecId.ADDITIONAL_CPV_CODE:
      case BoxSpecId.PLACE_OF_PERFORMANCE_NUTS_CODE:
        return new BoxFieldArray(name, undefined, undefined);
      case BoxSpecId.FRAMEWORK_AGREEMENT_2:
        return new BoxFieldBoolean(name, undefined);
      case BoxSpecId.CONTRACT_DURATION:
      case BoxSpecId.NUMBER_OF_SUPPLIERS_ASSIGNED_CONTRACT:
        return new BoxFieldNumber(name, undefined, undefined);
      case BoxSpecId.ESTIMATED_CONTRACT_VALUE_2:
      case BoxSpecId.ESTIMATED_CONTRACT_VALUE:
        return new BoxFieldCurrency(name, undefined, undefined);
      case BoxSpecId.DEADLINE_OF_CLARIFICATION_QUESTIONS:
      case BoxSpecId.EXPECTED_PUBLICATION_DATE:
      case BoxSpecId.AVAILABLE_DATE_BOX:
      case BoxSpecId.DEADLINE:
      case BoxSpecId.DATE_OF_DISPATCH:
      case BoxSpecId.CONTRACT_PERIOD_START:
      case BoxSpecId.CONTRACT_PERIOD_END:
      case BoxSpecId.DEMONSTRATION_2_START:
      case BoxSpecId.DEMONSTRATION_2_END:
      case BoxSpecId.PRESENTATION_2_START:
      case BoxSpecId.PRESENTATION_2_END:
      case BoxSpecId['SHOWING_OF_OBJECT(S)_2_START']:
      case BoxSpecId['SHOWING_OF_OBJECT(S)_2_END']:
      case BoxSpecId.CONTRACT_PERIOD:
      case BoxSpecId.DEMONSTRATION_2:
      case BoxSpecId.PRESENTATION_2:
      case BoxSpecId.SHOWING_OF_OBJECTS_2:
        return new BoxFieldDate(name, undefined, undefined);
      case BoxSpecId.LINK_TO_TED_2:
      case BoxSpecId.LINK_TO_TED:
      case BoxSpecId.LINK_FOR_SUBMITTING_TENDER_2:
      case BoxSpecId.LINK_FOR_SUBMITTING_TENDER:
      case BoxSpecId.LINK_TO_PROCURER_2:
      case BoxSpecId.LINK_TO_PROCURER:
      case BoxSpecId.LINK_TO_TENDER_DOCUMENT_2:
      case BoxSpecId.LINK_TO_TENDER_DOCUMENT:
      case BoxSpecId.LINK_TO_ETENDERING_2:
      case BoxSpecId.LINK_TO_ETENDERING:
        return new BoxFieldUrl(name, undefined, undefined);
      case BoxSpecId.EMAIL:
      case BoxSpecId['E-MAIL']:
        return new BoxFieldEmail(name, undefined);
      default:
        return new BoxFieldString(name, undefined);
    }
  }

  private convertField(field: IApiBoxField): IBoxField {
    const name = this.toCorrectName(field.name);
    const isBoxEmail = EMAIL_BOX_SPEC_IDS.includes(this.box.specificationId as BoxSpecId);
    switch (field.type) {
      case TenderBoxFieldType.Array:
        return new BoxFieldArray(name, (field as IApiBoxFieldArray).array, (field as IApiBoxFieldArray).unit);
      case TenderBoxFieldType.Boolean:
        return new BoxFieldBoolean(name, (field as IApiBoxFieldBoolean).boolean);
      case TenderBoxFieldType.Number:
        return new BoxFieldNumber(name, (field as IApiBoxFieldNumber).number, (field as IApiBoxFieldNumber).unit);
      case TenderBoxFieldType.Money:
        return new BoxFieldCurrency(
          name,
          (field as IApiBoxFieldCurrency).amount,
          (field as IApiBoxFieldCurrency).currency
        );
      case TenderBoxFieldType.Date:
        return new BoxFieldDate(name, (field as IApiBoxFieldDate).date, (field as IApiBoxFieldDate).time);
      case TenderBoxFieldType.DateRange:
        return new BoxFieldDateRange(name, (field as IApiBoxFieldDateRange).from, (field as IApiBoxFieldDateRange).to);
      case TenderBoxFieldType.MoneyRange:
        return new BoxFieldCurrencyRange(
          name,
          (field as IApiBoxFieldCurrencyRange).from,
          (field as IApiBoxFieldCurrencyRange).to,
          (field as IApiBoxFieldCurrencyRange).currency
        );
      case TenderBoxFieldType.Range:
        return new BoxFieldRange(
          name,
          (field as IApiBoxFieldRange).from,
          (field as IApiBoxFieldRange).to,
          (field as IApiBoxFieldRange).unit
        );
      case TenderBoxFieldType.String:
        // FIXME: PROM-2729
        const string = (field as IApiBoxFieldString).string;
        const isBoolean =
          !!string && [...BOOLEAN_ANSWERS.positive, ...BOOLEAN_ANSWERS.negative].includes(string.toLowerCase());
        const isNutsCodes = field.name === 'Nuts code(s)';
        const isUrl = !!string && URL_MARKERS.some(marker => string.startsWith(marker));

        if (!string) {
          return new BoxFieldString(name, (field as IApiBoxFieldString).string);
        } else if (isBoolean) {
          return new BoxFieldBoolean(name, [...BOOLEAN_ANSWERS.positive].includes(string.toLowerCase()));
        } else if (isNutsCodes) {
          return new BoxFieldArray(name, [string]);
        } else if (isUrl && this.box.specificationId !== BoxSpecId.TITLE_OF_THE_TENDER) {
          return new BoxFieldUrl(name, this.toCorrectLink(string));
        } else if (isBoxEmail && isEmailCheck((field as IApiBoxFieldString).string)) {
          return new BoxFieldEmail(name, string);
        } else {
          return new BoxFieldString(name, (field as IApiBoxFieldString).string);
        }
      case TenderBoxFieldType.URL:
        if (isBoxEmail && isEmailCheck((field as IApiBoxFieldUrl).title)) {
          return new BoxFieldEmail(name, (field as IApiBoxFieldUrl).title);
        }
        return new BoxFieldUrl(
          name,
          this.toCorrectLink((field as IApiBoxFieldUrl).url),
          (field as IApiBoxFieldUrl).title
        );
      default:
        return new BoxFieldString(name, undefined);
    }
  }

  private convertLots(apiLots?: IApiTenderLot[]): ITenderLot[] {
    return apiLots
      ? apiLots
          .map(apiLot => ({ ...apiLot, lotItem: Number(apiLot.lotItem) }))
          .slice()
          .sort((a, b) => a.lotItem - b.lotItem)
      : [];
  }

  private convertDataSource(apiSource: IApiBoxDataSource): ITenderBoxDataSource | undefined {
    const splittedPath = (!!apiSource && apiSource.filePath && apiSource.filePath.split('/')) || null;
    if (!splittedPath || splittedPath.length < 2) return;
    const [fileName, fileExt] = splitFileExt(splittedPath[splittedPath.length - 1]);
    return {
      filePath: apiSource.filePath ?? '',
      fileName: this.truncateString(fileName, TRUNC_CHARS_MAX),
      fileExt: isFileExt(fileExt) ? fileExt : undefined
    };
  }

  private truncateString(string: string, max: number): string {
    return string.length <= max ? string : `${string.slice(0, max - 3)}...`;
  }
}

export class TenderBoxFactory {
  public create(keyName: TenderKeys, fields?: IBoxField[]): ITenderBox {
    if (keyName in BOX_SPEC_KEY_MAPPER) {
      return new TenderBox(
        {
          id: v4(),
          title: BOX_SPEC_KEY_MAPPER[keyName].title,
          category: BOX_SPEC_KEY_MAPPER[keyName].category,
          specificationId: BOX_SPEC_KEY_MAPPER[keyName].specificationId,
          fields: fields ? fields : [],
          comments: [],
          requirementStatus: null
        },
        keyName
      );
    } else {
      return new TenderBox({
        id: v4(),
        title: keyName,
        category: `OTHER.OTHER`,
        specificationId: `CUSTOM`,
        fields: [],
        comments: [],
        requirementStatus: null
      });
    }
  }
}

export abstract class BoxField implements IBoxFieldGeneric {
  public readonly name: string;
  public readonly type: TenderBoxFieldType;
  public readonly id: string;
  private _isChanged = false;

  protected constructor(name: string, type: TenderBoxFieldType) {
    this.name = name;
    this.type = type;
    this.id = v4();
  }
  get isChanged(): boolean {
    return this._isChanged;
  }
  update(): void {
    this._isChanged = true;
  }
}

export class BoxFieldString extends BoxField implements IBoxFieldString {
  public string: Writeable<Maybe<string>>;

  constructor(name: string, string?: Maybe<string>) {
    super(name, TenderBoxFieldType.String);
    this.string = string || undefined;
  }
  public get apiField(): IApiBoxFieldString {
    const { name, type, string } = this;
    return {
      name,
      type,
      string: string ?? null
    };
  }
  public update(string?: Maybe<string>): IBoxFieldString {
    super.update();
    this.string = string;
    return this;
  }
}

export class BoxFieldBoolean extends BoxField implements IBoxFieldBoolean {
  public boolean: Writeable<Maybe<boolean>>;

  constructor(name: string, boolean?: Maybe<boolean>) {
    super(name, TenderBoxFieldType.Boolean);
    this.boolean = boolean;
  }
  public get apiField(): IApiBoxFieldBoolean {
    const { name, type, boolean } = this;
    return {
      name,
      type,
      boolean: boolean === undefined ? null : boolean
    };
  }
  public update(boolean?: Maybe<boolean>): IBoxFieldBoolean {
    super.update();
    this.boolean = boolean;
    return this;
  }
}

export class BoxFieldNumber extends BoxField implements IBoxFieldNumber {
  public number: Writeable<Maybe<number>>;
  public unit: Writeable<Maybe<BoxFieldRangeUnit>>;

  constructor(name: string, number?: Maybe<number>, unit?: Maybe<BoxFieldRangeUnit>) {
    super(name, TenderBoxFieldType.Number);
    this.number = number;
    this.unit = unit;
  }
  public get apiField(): IApiBoxFieldNumber {
    const { name, type, number, unit } = this;
    return {
      name,
      type,
      number: number ?? null,
      unit: unit ?? null
    };
  }
  public update(number?: Maybe<number>, unit?: Maybe<BoxFieldRangeUnit>): IBoxFieldNumber {
    super.update();
    this.number = number;
    this.unit = unit;
    return this;
  }
}

export class BoxFieldArray extends BoxField implements IBoxFieldArray {
  public array: Writeable<Maybe<string[]>>;
  public unit: Writeable<Maybe<BoxFieldRangeUnit>>;

  constructor(name: string, array?: Maybe<string[]>, unit?: Maybe<BoxFieldRangeUnit>) {
    super(name, TenderBoxFieldType.Array);
    this.array = array;
    this.unit = unit;
  }
  public get apiField(): IApiBoxFieldArray {
    const { name, type, array } = this;
    return {
      name,
      type,
      array: array ?? []
    };
  }
  public update(array?: Maybe<string[]>): IBoxFieldArray {
    super.update();
    this.array = array;
    return this;
  }
}

export class BoxFieldCurrency extends BoxField implements IBoxFieldCurrency {
  public amount: Writeable<Maybe<number>>;
  public currency: Writeable<Maybe<string>>;

  constructor(name: string, amount?: Maybe<number>, currency?: Maybe<string>) {
    super(name, TenderBoxFieldType.Money);
    this.amount = amount;
    this.currency = currency;
  }
  public get apiField(): IApiBoxFieldCurrency {
    const { name, type, amount, currency } = this;
    return {
      name,
      type,
      amount: amount ?? null,
      currency: currency ?? null
    };
  }
  public update(amount?: Maybe<number>, currency?: Maybe<string>): IBoxFieldCurrency {
    super.update();
    this.amount = amount;
    this.currency = currency;
    return this;
  }
}

export class BoxFieldDate extends BoxField implements IBoxFieldDate {
  public date: Writeable<Maybe<number>>;
  public time: Writeable<Maybe<string>>;

  constructor(name: string, date?: Maybe<number>, time?: Maybe<string>) {
    super(name, TenderBoxFieldType.Date);
    this.date = date;
    this.time = time;
  }
  public get apiField(): IApiBoxFieldDate {
    const { name, type, date, time } = this;
    return {
      name,
      type,
      date: date ?? null,
      time: time ?? null
    };
  }
  public update(date?: Maybe<number>, time?: Maybe<string>): IBoxFieldDate {
    super.update();
    this.date = date;
    this.time = time;
    return this;
  }
}

export class BoxFieldCurrencyRange extends BoxField implements IBoxFieldCurrencyRange {
  public from: Writeable<Maybe<number>>;
  public to: Writeable<Maybe<number>>;
  public currency: Writeable<Maybe<string>>;

  constructor(name: string, from?: Maybe<string>, to?: Maybe<string>, currency?: Maybe<string>) {
    super(name, TenderBoxFieldType.MoneyRange);
    this.from = Number(from) ?? undefined;
    this.to = Number(to) ?? undefined;
    this.currency = currency;
  }
  public get apiField(): IApiBoxFieldCurrencyRange {
    const { name, type, from, to, currency } = this;
    return {
      name,
      type,
      from: from ? `${from}` : null,
      to: to ? `${to}` : null,
      currency: currency ?? null
    };
  }
  public update(from?: Maybe<number>, to?: Maybe<number>, currency?: Maybe<string>): IBoxFieldCurrencyRange {
    super.update();
    this.from = from;
    this.to = to;
    this.currency = currency;
    return this;
  }
}

export class BoxFieldUrl extends BoxField implements IBoxFieldUrl {
  public url: Writeable<Maybe<string>>;
  public title: Writeable<Maybe<string>>;
  constructor(name: string, url?: Maybe<string>, title?: Maybe<string>) {
    super(name, TenderBoxFieldType.URL);
    this.url = url;
    this.title = title;
  }
  public get apiField(): IApiBoxFieldUrl {
    const { name, type, url, title } = this;
    return {
      name,
      type,
      url: url ?? null,
      title: title ?? null
    };
  }
  public update(url?: Maybe<string>, title?: Maybe<string>): IBoxFieldUrl {
    super.update();
    this.url = title && isEmailCheck(title) ? toCorrectEmail(title) : url;
    this.title = title;
    return this;
  }
}

export class BoxFieldEmail extends BoxField implements IBoxFieldEmail {
  public email: Writeable<Maybe<string>>;
  constructor(name: string, email?: Maybe<string>) {
    super(name, TenderBoxFieldType.String);
    this.email = email;
  }
  public get apiField(): IApiBoxFieldString {
    const { name, type, email } = this;
    return {
      name,
      type,
      string: email ?? null
    };
  }
  public update(email?: Maybe<string>): IBoxFieldEmail {
    super.update();
    this.email = email;
    return this;
  }
}
export class BoxFieldDateRange extends BoxField implements IBoxFieldDateRange {
  public from: Writeable<Maybe<number>>;
  public to: Writeable<Maybe<number>>;

  constructor(name: string, from?: Maybe<string>, to?: Maybe<string>) {
    super(name, TenderBoxFieldType.DateRange);
    this.from = Number(from) ?? undefined;
    this.to = Number(to) ?? undefined;
  }

  public get apiField(): IApiBoxFieldDateRange {
    const { name, type, from, to } = this;
    return {
      name,
      type,
      from: from ? `${from}` : null,
      to: to ? `${to}` : null
    };
  }
  public update(from?: Maybe<number>, to?: Maybe<number>): IBoxFieldDateRange {
    super.update();
    this.from = from;
    this.to = to;
    return this;
  }
}

export class BoxFieldRange extends BoxField implements IBoxFieldRange {
  public from: Writeable<Maybe<string>>;
  public to: Writeable<Maybe<string>>;
  public unit: Writeable<Maybe<BoxFieldRangeUnit>>;

  constructor(name: string, from?: Maybe<string>, to?: Maybe<string>, unit?: Maybe<BoxFieldRangeUnit>) {
    super(name, TenderBoxFieldType.Range);
    this.from = from;
    this.to = to;
    this.unit = unit;
  }
  public get apiField(): IApiBoxFieldRange {
    const { name, type, from, to, unit } = this;
    return {
      name,
      type,
      from: from ?? null,
      to: to ?? null,
      unit: unit ?? null
    };
  }
  public update(from?: Maybe<string>, to?: Maybe<string>, unit?: Maybe<BoxFieldRangeUnit>): IBoxFieldRange {
    super.update();
    this.from = from;
    this.to = to;
    this.unit = unit;
    return this;
  }
}
