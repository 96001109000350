import React, { FC } from 'react';
import styles from './index.module.scss';
import { Header } from 'src/shared';
import { Skeleton } from 'src/common';
import classNames from 'classnames';
import { useNavSidebarCtx } from 'src/shared/NavSidebar/hooks';

const MainSkeleton: FC = () => {
  const [sidebarIsOpen] = useNavSidebarCtx();

  return (
    <div className={styles.container}>
      <Header />
      <aside className={classNames(styles.nav, { [styles.isOpen]: sidebarIsOpen })}>
        <Skeleton active loading />
      </aside>
      <main className={styles.main}>
        <Skeleton active loading />
      </main>
    </div>
  );
};

export default MainSkeleton;
