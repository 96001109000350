import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Input, notification, Skeleton, Button, Alert } from 'src/common';
import { useMutation } from '@apollo/client';
import { UPDATE_WORKSPACE } from 'src/models/workspace/queries';
import AccessControlList from './AccessControlList';
import classNames from 'classnames';
import { useFeatureFlag, FeatureFlag } from 'src/helpers/featureFlag';
import WorkspaceBadge from 'src/common/WorkspaceBadge';
import { ColorPicker } from 'src/shared';
import { DEFAULT_WORKSPACE_COLORS } from 'src/models/workspace/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { useWorkspace } from 'src/models/workspace/hooks';
import { useParams } from 'react-router-dom';

interface IFormState {
  title: string;
}

export const WorkspaceInfoDrawer: FC = () => {
  const { wsId: workspaceId } = useParams<{ wsId: string }>();

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const isWorkspaceAclFeature = useFeatureFlag(FeatureFlag.Workspace_ACL);

  const [updateWS] = useMutation<unknown, { workspaceId: string; name?: string; color?: string }>(UPDATE_WORKSPACE);
  const { data, loading } = useWorkspace();
  const workspaceName = data?.name || '';
  const workspaceColor = data?.color;
  const [color, setColor] = useState(workspaceColor);
  const onChangeColor = useCallback(
    (color: string) => {
      workspaceId &&
        updateWS({
          variables: {
            workspaceId,
            color
          }
        }).catch(() => {
          notification.error({
            message: t('Common.unknownError'),
            description: t('Common.unknownErrorDesc')
          });
        });
    },
    [t, updateWS, workspaceId]
  );

  const accessToEdit = data?.accessToEdit;

  const onSaveWorkspace = useCallback(() => {
    const title = form.getFieldValue('title');
    if (title.length < 1) {
      form.setFields([
        {
          name: 'title',
          errors: [t('BidSpaces.enterBidSpaceName')]
        }
      ]);
    } else {
      workspaceId &&
        updateWS({
          variables: {
            workspaceId,
            name: title
          }
        }).catch(() => {
          notification.error({
            message: t('Common.unknownError'),
            description: t('Common.unknownErrorDesc')
          });
        });
    }
  }, [form, t, updateWS, workspaceId]);

  const onValuesChange = useCallback(() => {
    form.setFields([
      {
        name: 'title',
        errors: []
      }
    ]);
  }, [form]);

  const initialValue = useMemo((): IFormState => {
    return { title: workspaceName };
  }, [workspaceName]);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const isLoading = loading;

  return isLoading ? (
    <Skeleton active loading />
  ) : (
    <>
      <h2 className={classNames(styles.title, { [styles.disabled]: !accessToEdit })}>
        {accessToEdit ? `${t('Common.edit')} ${workspaceName}` : workspaceName}
      </h2>
      {!accessToEdit && <Alert message={t('BidSpaces.infoAboutBidSpaceSubscribers')} type={'info'} />}
      {accessToEdit && (
        <Form initialValues={initialValue} form={form} onFinish={onSaveWorkspace} onValuesChange={onValuesChange}>
          <div className={styles.unit}>
            <Form.Item label={t('Common.title')} name="title">
              <Input name="title" type="text" maxLength={30} placeholder={t('Common.name')} />
            </Form.Item>
            <Button type={'default'} htmlType={'submit'}>
              {t('Common.save')}
            </Button>
          </div>
          <div className={styles.unit}>
            <div className={styles.unitContainer}>
              <span className={styles.unitTitle}>{t('Common.color')}</span>
              {workspaceColor && (
                <ColorPicker
                  currentColor={color || workspaceColor}
                  onSubmit={onChangeColor}
                  onChange={setColor}
                  paletteColors={DEFAULT_WORKSPACE_COLORS}
                  placement={'bottomRight'}
                >
                  <div className={styles.colorPicker}>
                    <WorkspaceBadge
                      title={workspaceName || 'W'}
                      color={color || workspaceColor}
                      className={styles.colorPickerBadge}
                    />
                    <span className={styles.colorPickerIconContainer}>
                      <FontAwesomeIcon icon={faEdit} className={styles.colorPickerIcon} />
                    </span>
                  </div>
                </ColorPicker>
              )}
            </div>
          </div>
        </Form>
      )}
      {isWorkspaceAclFeature && <AccessControlList readOnly={!accessToEdit} />}
    </>
  );
};

export default WorkspaceInfoDrawer;
