import React, { FC } from 'react';
import styles from './index.module.scss';
import { IBaseWorkspace } from 'src/models/workspace/types';
import { Skeleton, WorkspaceBadge } from 'src/common';
import classNames from 'classnames';

interface IProps {
  ws: IBaseWorkspace;
  className?: string;
  loading?: boolean;
  onMoveToWorkspace: (id: string) => Promise<void>;
}

export const WorkspacePickerItem: FC<IProps> = props => {
  const { ws, className, onMoveToWorkspace, loading } = props;

  return (
    <li className={classNames(styles.wsItem, { [styles.isLoading]: loading })} onClick={() => onMoveToWorkspace(ws.id)}>
      <WorkspaceBadge
        title={ws.name}
        color={ws.color}
        mode={'full'}
        className={classNames(className, styles.wsItemBadge)}
        size={'xs'}
        tooltip={true}
      />
      <Skeleton className={styles.wsItemLoading} loading={loading} paragraph={false} active={loading} />
    </li>
  );
};
export default WorkspacePickerItem;
