import React, { FC } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface Props {
  desc: React.ReactNode;
  className?: string;
  descClassName?: string;
}

const SourceDescription: FC<Props> = ({ desc, className, descClassName }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.source, className)}>
      <span className={styles.sourceLabel}>{t('Tenders.source')}</span>
      <span className={classNames(styles.sourceName, descClassName)}>{desc}</span>
    </div>
  );
};

export default SourceDescription;
