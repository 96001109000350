import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from 'src/common';
import { IMatchingProfile } from 'src/models/matchingProfiles/types';
import { toHSLColor } from 'src/helpers/styles';
import { trackSwitchMatchingProfile } from 'src/segment/events';
import { Paths } from 'src/pages/paths';

interface Props {
  mp: IMatchingProfile;
  sidebarIsOpen?: boolean;
}

export const MpItem: FC<Props> = ({ mp, sidebarIsOpen }) => {
  const { id, name } = mp;
  const { mpId } = useParams<{ mpId: string }>();

  const onMpClick = useCallback(() => {
    trackSwitchMatchingProfile({ id, name });
  }, [id, name]);

  return (
    <Link
      className={classNames(
        styles.menuItem,
        {
          [styles.isActive]: id === mpId
        },
        { [styles.isClosed]: !sidebarIsOpen }
      )}
      tabIndex={0}
      to={`${Paths.MONITORING}/${id}`}
      onClick={onMpClick}
    >
      <Tooltip
        title={mp.name}
        placement={'right'}
        className={classNames(styles.tooltip, { [styles.isIconOnly]: !sidebarIsOpen })}
      >
        <span className={styles.itemBadge} style={{ color: toHSLColor(id) }}></span>
        {sidebarIsOpen && <span className={styles.itemLabel}>{name}</span>}
      </Tooltip>
    </Link>
  );
};

export default MpItem;
