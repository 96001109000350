import { ReactNode } from 'react';
import { faChartMixed } from '@fortawesome/pro-light-svg-icons';
import {
  ApiTenderTransactionsInput,
  ApiTenderTransactionData,
  PeriodTransaction
} from 'src/models/procurements/Billing/types';
import { IApiOrganization } from 'src/models/organisations';

export interface BillingChartProps {
  loading?: boolean;
  error?: boolean;
  data: ApiTenderTransactionData | null;
  vars?: ApiTenderTransactionsInput;
  className?: string;
  children?: ReactNode;
  onClick?: (d: PeriodTransaction) => void;
  displaySupplierLimits: number;
}

export interface SupplierTransactions {
  period: string;
  dates: string[];
  supplier: IApiOrganization;
  transactions: PeriodTransaction[];
}

export interface SupplierCumulativeValue {
  supplierOrgId: string;
  cumulativeValue: number;
  lastPeriodWithValue: string | null;
}

export const billing_title_icon = { defaultIcon: faChartMixed, color: '#BCBCC2' };
