import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faPlus, faSpinner, faTrash, faCheck } from '@fortawesome/pro-light-svg-icons';

export interface IEditableBoxActionProps {
  type?: 'add' | 'remove';
  isLoading?: boolean;
  isDone?: boolean;
  onAction?: () => void;
}

export interface IEditableBoxActionIcon {
  icon: IconDefinition;
  title: string;
}

export interface IEditableBoxActionStatusIcon {
  isDefault: IEditableBoxActionIcon;
  isDone: IEditableBoxActionIcon;
}

export const ICON_STATUS_LOADING = {
  icon: faSpinner,
  title: 'Common.spinnerText'
};

export const ICON_TYPE_REMOVE = {
  isDefault: {
    icon: faTrash,
    title: 'DetailsPage.RemoveFromSummary'
  },
  isDone: {
    icon: faCheck,
    title: 'DetailsPage.RemovedFromSummary'
  }
};

export const ICON_TYPE_ADD = {
  isDefault: {
    icon: faPlus,
    title: 'DetailsPage.AddToSummary'
  },
  isDone: {
    icon: faCheck,
    title: 'DetailsPage.AddedToSummary'
  }
};
