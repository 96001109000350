import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';

type Props = {
  name: string;
  icon: ReactNode;
  count: number;
  additional?: string;
};

export const KPI: FC<Props> = ({ name, icon, count, additional }) => {
  return (
    <div className={styles.block} key={name}>
      <span className={styles.name}>{name}</span>
      <div className={styles.value}>
        {icon}
        <span className={styles.statCount}>{count}</span>
        {additional && <span className={styles.statPercent}>{`(${additional})`}</span>}
      </div>
    </div>
  );
};
