import React, { FC, KeyboardEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInviteUsers, IInvitedUser } from 'src/models/company/Users/hooks';
import { Form, Input, Select, Alert, UserName, Button } from 'src/common';
import { IUserName } from 'src/models/users/types';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/pro-light-svg-icons';
import InfoLabel from 'src/shared/InfoLabel';
import { toHSLColor } from 'src/helpers/styles';
import { toUserName } from 'src/models/users/types';

interface IProps {
  onSuccess: () => void;
  onClose: () => void;
}

interface IFormState {
  usersEmail: string[];
  users: Pick<IUserName, 'email' | 'name' | 'lastName'>[];
}

export const InviteUsersForm: FC<IProps> = props => {
  const { onSuccess, onClose: onCloseModal } = props;
  const { t } = useTranslation();

  const [addCompanyEmployees, { loading: isLoading }] = useInviteUsers();

  const [form] = Form.useForm();
  const initialValues = useMemo((): IFormState => {
    return {
      usersEmail: [],
      users: []
    };
  }, []);

  const onInvite = useCallback(() => {
    onSuccess();
    form.resetFields();
  }, [form, onSuccess]);

  const onError = useCallback(
    (users: IInvitedUser[]) => {
      form.resetFields();
      form.setFields([
        { name: 'usersEmail', value: users.map(user => user.email), errors: [t('Auth.unInvitedUsersError')] },
        {
          name: 'users',
          value: users.map(user => ({ email: user.email, name: user.name, lastName: user.lastName }))
        }
      ]);
    },
    [form, t]
  );

  const onFinish = useCallback(
    (values: IFormState) => {
      const { users } = values;
      const invitedUsers = users
        .filter(user => !!user.email)
        .map(user => ({
          email: user.email,
          name: user.name,
          lastName: user.lastName,
          inviteSubUser: true
        }));
      !!invitedUsers.length && addCompanyEmployees({ users: invitedUsers, onInvite, onError });
    },
    [addCompanyEmployees, onInvite, onError]
  );

  const onValuesChange = useCallback(
    (changedValues: Partial<IFormState>) => {
      form.setFields([
        {
          name: 'usersEmail',
          errors: []
        }
      ]);
      if (changedValues.usersEmail) {
        const emails = changedValues.usersEmail.map(email => email.trim());
        const processedUsers = emails
          .map(email => {
            if (email.includes('@')) {
              const [userName] = email.trim().split('@');
              if (userName.includes('.')) {
                const [name, lastName] = userName.split('.');
                return {
                  email,
                  name,
                  lastName
                };
              } else {
                return {
                  email,
                  name: userName,
                  lastName: null
                };
              }
            } else {
              form.setFields([
                {
                  name: 'usersEmail',
                  errors: [t('Common.incorrectEmailError')]
                }
              ]);
              return null;
            }
          })
          .filter(email => email !== null);
        form.setFieldsValue({ users: processedUsers });
      } else if (changedValues.users) {
        const usersFromForm: IUserName[] = form.getFieldValue('users');
        const processedUsersEmail = usersFromForm
          .map(user => {
            return user && user.email ? user.email : null;
          })
          .filter(user => user !== null);
        form.setFieldsValue({ usersEmail: processedUsersEmail });
      } else {
        return;
      }
    },
    [form, t]
  );

  const onKeyPress = useCallback((e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const onClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
  }, [form, onCloseModal]);

  return (
    <>
      <Alert message={t('Users.costChange')} type="info" />

      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        className={styles.form}
        layout={'vertical'}
        initialValues={initialValues}
        onKeyPress={onKeyPress}
      >
        <Form.Item
          name={'usersEmail'}
          label={<InfoLabel title={t('Users.emailAddresses')} desc={t('Users.emailAddressesDesc')} />}
          rules={[{ required: true, message: t('Users.emailAddressesRequiredError') }]}
        >
          <Select mode={'tags'} style={{ width: '100%' }} tokenSeparators={[',', ' ']} open={false} />
        </Form.Item>
        <Form.List name={'users'}>
          {(fields, { add, remove }) => {
            const isUsers = fields.length > 0;
            return (
              <div className={styles.formContainer}>
                {isUsers && <h3 className={classNames(styles.title)}>{t('Users.invitedUsers')}</h3>}
                <div className={styles.fieldsContainer}>
                  {fields.map((field, index) => {
                    const userField: IUserName = form.getFieldValue('users')[index];
                    const withEmail = userField && userField.email;
                    return (
                      <div key={field.key} className={styles.fields}>
                        <div className={styles.fieldsInner}>
                          <Form.Item
                            {...field}
                            fieldKey={[field.fieldKey, 'users']}
                            key={`${field.key}_email`}
                            className={classNames(styles.field, styles.emailField)}
                            name={[field.name, 'email']}
                            rules={[
                              {
                                required: true,
                                message: t('Common.inputRequired', { inputName: t('Auth.emailAddress') })
                              },
                              { type: 'email', message: t('Common.incorrectEmailError'), validateTrigger: 'onBlur' }
                            ]}
                          >
                            <Input
                              data-type={'email'}
                              type={'email'}
                              placeholder={t('Auth.emailAddress')}
                              prefix={
                                withEmail ? (
                                  <UserName
                                    mode={'onlyAvatar'}
                                    user={{
                                      ...userField,
                                      userName: toUserName(userField),
                                      color: toHSLColor(userField.email)
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon icon={faUser} />
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            className={styles.field}
                            key={`${field.key}_name`}
                            fieldKey={[field.fieldKey, 'users']}
                            name={[field.name, 'name']}
                          >
                            <Input data-type="name" type="text" placeholder={t('Account.firstName')} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            className={styles.field}
                            key={`${field.key}_lastName`}
                            name={[field.name, 'lastName']}
                          >
                            <Input data-type="lastName" type="text" placeholder={t('Account.lastName')} />
                          </Form.Item>
                        </div>
                        <button onClick={() => remove(field.name)} className={styles.deleteButton}>
                          <FontAwesomeIcon icon={faTimes} className={styles.deleteIcon} />
                        </button>
                      </div>
                    );
                  })}
                </div>
                {isUsers && (
                  <Form.Item>
                    <Button type={'primary'} onClick={add} className={styles.ctaButton}>
                      {t('Users.addPerson')}
                    </Button>
                  </Form.Item>
                )}
              </div>
            );
          }}
        </Form.List>
        <div className={styles.formFooter}>
          <Button type={'text'} onClick={onClose} key={'Close'}>
            {t('Common.close')}
          </Button>
          <Button type={'primary'} htmlType={'submit'} disabled={isLoading} loading={isLoading} key={'invite'}>
            {t('Users.invite')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default InviteUsersForm;
