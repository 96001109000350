import { useCallback, useMemo } from 'react';
import { useMutation, MutationTuple, MutationUpdaterFn, Reference, ApolloError } from '@apollo/client';
import { REMOVE_BID, ASSIGN_TO_BID, CREATE_BF_PROJECT, BID_STAGE_ID_FIELDS } from './queries';
import { useCloseSidebar } from 'src/shared/InfoSidebar/hooks';
import { IApiBids, IBidsCached } from './Bids/types';
import { BidItemType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { EmailStatus } from '../callOffs/types';
import { clearWsStatsCache } from '../workspace/hooks';
import { trackDeleteBid } from 'src/segment/events';
import { ByggfaktaProject } from './types';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { getCurrentBidFromCache } from './Bids/hooks';
import { evictBidStatisticsCache } from './Bids/helpers';

export function getUpdateCacheOnRemoveBids(
  bidId: string,
  originId: string,
  originType: BidItemType,
  wsId: string
): MutationUpdaterFn<{ deleteBid: boolean }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    const { deleteBid } = data;
    if (!deleteBid) return;

    if (originType === BidItemType.Procurement) {
      const modifyTenderCache = (originId: string, typename: string): void => {
        cache.modify({
          id: cache.identify({
            __typename: typename,
            id: originId
          }),
          fields: {
            bids(existingRefs: Reference[], { readField }) {
              return existingRefs.filter(ref => readField('id', ref) !== bidId);
            }
          }
        });
      };
      modifyTenderCache(originId, 'Tender');
      modifyTenderCache(originId, 'OCDSTender');
    }

    if (originType === BidItemType.CallOff) {
      const callOffRef = cache.identify({
        __typename: 'UserMail',
        mailId: originId
      });
      cache.modify({
        id: callOffRef,
        fields: {
          mailStatus(previousStatus, { readField }) {
            const existingBidsRefs: readonly Reference[] | undefined = readField('bids');
            return !!existingBidsRefs && !!existingBidsRefs.filter(ref => readField('id', ref) !== bidId).length
              ? previousStatus
              : EmailStatus.UNHANDLED;
          },
          bidsV2(existingRefs: Reference[], { readField }) {
            return existingRefs.filter(ref => readField('id', ref) !== bidId);
          }
        }
      });
    }

    cache.modify({
      fields: {
        getBids(cached: IBidsCached, { readField, storeFieldName }) {
          const bidStageIdFragment = cache.readFragment<{ bidStageId: string | null } | null>({
            id: cache.identify({
              __typename: 'BidV2',
              id: bidId
            }),
            fragment: BID_STAGE_ID_FIELDS,
            fragmentName: 'bidStageIdFields'
          });

          if (!bidStageIdFragment) return cached;

          if (!bidStageIdFragment.bidStageId) return cached;

          if (storeFieldName.includes(bidStageIdFragment.bidStageId) || !storeFieldName.includes('stageIds')) {
            return {
              ...cached,
              total: cached.total - 1,
              bids: [...cached.bids].filter(bid => readField('id', bid) !== bidId)
            };
          } else {
            return cached;
          }
        }
      }
    });

    clearWsStatsCache(cache, wsId);
  };
}

export function useRemoveBid(): [
  (
    bidId: string,
    wsId: string,
    originId: string,
    originType: BidItemType,
    categoryName?: WorkspaceStatusCategory
  ) => void,
  { loading: boolean; error: Error | undefined; data: { deleteBid: boolean } | null | undefined }
] {
  const [removeBid, { loading: bidLoading, error: bidError, data: bidData }] = useMutation<
    { deleteBid: boolean },
    { bidId: string }
  >(REMOVE_BID);
  const closeSidebar = useCloseSidebar();
  const removeCb = useCallback(
    (
      bidId: string,
      wsId: string,
      originId: string,
      originType: BidItemType,
      categoryName?: WorkspaceStatusCategory
    ) => {
      removeBid({
        variables: {
          bidId
        },
        update: getUpdateCacheOnRemoveBids(bidId, originId, originType, wsId)
      }).then(() => {
        closeSidebar();
        trackDeleteBid(
          {
            id: wsId
          },
          categoryName
        );
      });
    },
    [closeSidebar, removeBid]
  );

  return [removeCb, { loading: bidLoading, error: bidError, data: bidData }];
}

interface IAssignToBidResponse {
  __typename: 'Mutation';
  assignUserToBid: IApiBids & {
    __typename: 'BidV2';
  };
}
interface IAssignToBidRequest {
  bidId: string;
  userId: string | null;
}
export function useAssignToBid(): MutationTuple<IAssignToBidResponse, IAssignToBidRequest> {
  return useMutation(ASSIGN_TO_BID);
}

export function getUpdateCacheOnAssignBid(
  bidId: string,
  assignedId: string | null
): MutationUpdaterFn<IAssignToBidResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    const currentBid = getCurrentBidFromCache(bidId, cache);

    if (assignedId) {
      const userRef = cache.identify({
        __typename: 'AssignedTo',
        id: assignedId
      });
      cache.modify({
        id: bidRef,
        fields: {
          assignedTo() {
            return { __ref: userRef };
          }
        },
        optimistic: true
      });
    } else {
      cache.modify({
        id: bidRef,
        fields: {
          assignedTo() {
            return null;
          }
        },
        optimistic: true
      });
    }

    cache.modify({
      fields: {
        getBids(cached: IBidsCached, { readField, storeFieldName, toReference }) {
          if (!currentBid) return cached;
          const isAssignedToFilterApplied = storeFieldName.includes('assignedToIds');
          if (!isAssignedToFilterApplied) return cached;

          // Filter include both new and prevoius value so no need to update cache
          if (
            assignedId &&
            storeFieldName.includes(assignedId) &&
            currentBid.assignedTo &&
            storeFieldName.includes(currentBid.assignedTo.id)
          )
            return cached;

          // Assigned to id is in cache, so adding the newly assigned bid
          if (assignedId && storeFieldName.includes(assignedId)) {
            const bid = cache.identify({
              __typename: 'BidV2',
              id: bidId
            });
            if (!bid) return cached;

            return {
              ...cached,
              total: cached.total + 1,
              bids: [...cached.bids, toReference(bid)]
            };
          }

          // Prevoius had an assignee, so need to remove it
          if (currentBid.assignedTo && storeFieldName.includes(currentBid.assignedTo.id)) {
            const newBids = [...cached.bids].filter(bid => bid && bidId !== readField({ fieldName: 'id', from: bid }));
            return {
              ...cached,
              total: cached.total - 1,
              bids: newBids
            };
          }
          return cached;
        }
      }
    });

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'getAssignedToStatistics' });
    cache.gc();
  };
}

interface CreateByggfaktaProjectVars {
  bidId: string;
}
interface CreateByggfaktaProjectData {
  exportBidToByggfaktaPrivate: ByggfaktaProject;
}
export function useCreateByggfaktaProject(): [
  (data: CreateByggfaktaProjectVars) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [createProject, { loading, error }] = useMutation<CreateByggfaktaProjectData, CreateByggfaktaProjectVars>(
    CREATE_BF_PROJECT
  );

  const createProjectFn = useCallback(
    async ({ bidId }: CreateByggfaktaProjectVars) => {
      return createProject({
        variables: { bidId },
        update: updateCacheOnCreateBfProject(bidId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },

    [createProject, t]
  );

  return useMemo(() => [createProjectFn, { loading, error }], [createProjectFn, error, loading]);
}
function updateCacheOnCreateBfProject(bidId: string): MutationUpdaterFn<CreateByggfaktaProjectData> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    cache.modify({
      id: bidRef,
      fields: {
        byggfaktaProject(cached: Reference, { toReference }) {
          return toReference({
            __typename: 'ByggfaktaPrivateCreatedProject',
            projectId: data.exportBidToByggfaktaPrivate.projectId
          });
        }
      }
    });
    cache.gc();
  };
}
