import React, { FC, useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'src/common';
import { useBidWorkspace } from 'src/models/bids/Bids/hooks';
import styles from './index.module.scss';
import classNames from 'classnames';
import { WorkspaceStatusBadge } from '../StatusBadge';
import { toSortedStatuses } from 'src/models/workspace/WorkspaceStatus/helpers';
import { useKey } from 'react-use';
import WorkspaceStatusItem from '../StatusItem';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';

interface IProps {
  bidId: string;
  eventSource: string;
  loading?: boolean;
  colorful?: boolean;
  className?: string;
}

export const WorkspaceStatusPickerComponent: FC<IProps> = props => {
  const { bidId, loading, colorful, className, eventSource } = props;
  const { workspace, status } = useBidWorkspace(bidId);
  const sortedStatuses = useMemo(() => {
    return toSortedStatuses(workspace?.statuses || []).filter(wsStatus => wsStatus.id !== status?.id);
  }, [status, workspace]);

  const [isSelecting, setSelecting] = useState(false);

  const closeSelecting = useCallback(() => {
    setSelecting(() => false);
  }, []);
  const toggleSelecting = useCallback(() => {
    setSelecting(isOpen => !isOpen);
  }, []);

  useKey('Escape', () => closeSelecting());

  return (
    <Dropdown
      trigger={['click']}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      visible={isSelecting}
      onVisibleChange={toggleSelecting}
      className={classNames(styles.statusPicker, !!className && className)}
      overlayClassName={styles.statusPickerMenu}
      overlay={
        <div className={styles.menu}>
          <ul className={styles.statuses}>
            {sortedStatuses.map(wsStatus => (
              <WorkspaceStatusItem
                status={wsStatus}
                colorful={!!colorful}
                key={wsStatus.id}
                bidId={bidId}
                loading={loading}
                eventSource={eventSource}
              />
            ))}
          </ul>
        </div>
      }
    >
      {status ? (
        <button className={styles.statusButton}>
          <WorkspaceStatusBadge status={status} colorful={!!colorful} />
        </button>
      ) : (
        <></>
      )}
    </Dropdown>
  );
};

export const WorkspaceStatusPicker: FC<IProps> = props => {
  const isBidspacesFeature = useFeatureFlag(FeatureFlag.Bidspaces);

  return isBidspacesFeature ? <WorkspaceStatusPickerComponent {...props} /> : null;
};
export default WorkspaceStatusPicker;
