import { gql } from '@apollo/client';

export const LINKED_BIDS_FIELDS = gql`
  fragment linkedBidsFields on BidV2 {
    id
    workspace {
      id
      color
      name
    }
    itemType
    item {
      ... on Tender {
        id
        general {
          name {
            id
            fields {
              ... on ProcurementBoxStringField {
                string
              }
            }
          }
        }
      }
      ... on UserMail {
        mailId
        general {
          name {
            id
            fields {
              ... on ProcurementBoxStringField {
                string
              }
            }
          }
        }
      }
      ... on ManualBidItem {
        id
        general {
          name {
            id
            fields {
              ... on ProcurementBoxStringField {
                string
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_ALL_BIDS = gql`
  query getAllBids($search: String, $amount: Float!, $offset: Float!) {
    getBids(input: { search: $search, pagination: { amount: $amount, offset: $offset } }) {
      total
      bids {
        ...linkedBidsFields
      }
    }
  }
  ${LINKED_BIDS_FIELDS}
`;
export const ASSIGN_MAILS_TO_BID = gql`
  mutation ($bidId: String!, $mailIds: [String!]!) {
    assignMultipleMailsToBid(input: { bidId: $bidId, mailIds: $mailIds }) {
      ...linkedBidsFields
    }
  }
  ${LINKED_BIDS_FIELDS}
`;
export const UNASSIGN_MAILS_TO_BID = gql`
  mutation ($bidId: String!, $mailIds: [String!]!) {
    unassignMultipleMailsFromBid(input: { bidId: $bidId, mailIds: $mailIds }) {
      ...linkedBidsFields
    }
  }
  ${LINKED_BIDS_FIELDS}
`;
