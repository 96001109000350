import { gql } from '@apollo/client';
import { TAG_FIELDS } from '../ContentLibraryTag/queries';
import { ROOM_FIELDS } from '../ContentLibraryRoom/queries';
import { USER_NAME_FIELDS } from 'src/models/users/queries';

export const CONTENT_CARD_FIELDS = gql`
  fragment contentCardFields on ContentLibraryContent {
    id
    question
    answer
    createdAt
    createdBy {
      ...userNameFields
    }
    modifiedAt
    modifiedBy {
      ...userNameFields
    }
    assignedTo {
      ...userNameFields
    }
    room {
      ...roomFields
    }
    tags {
      ...tagFields
    }
    type
    document {
      id
      fileName
      status
      getUrl
    }
  }
  ${ROOM_FIELDS}
  ${TAG_FIELDS}
  ${USER_NAME_FIELDS}
`;

export const GET_CONTENT_LIBRARY = gql`
  query getContentLibraryContents(
    $filters: ContentLibraryFilters
    $question: String
    $offset: Float!
    $limit: Float!
    $sort: SortOnField
  ) {
    getContentLibraryContents(
      query: { filters: $filters, question: $question, offset: $offset, limit: $limit, sort: $sort }
    ) {
      rows {
        ...contentCardFields
      }
      totalCount
    }
  }
  ${CONTENT_CARD_FIELDS}
`;

export const DELETE_CONTENT_LIBRARY_CONTENTS = gql`
  mutation deleteContentLibraryContents($input: DeleteContentLibraryContentsInput!) {
    deleteContentLibraryContents(input: $input)
  }
`;

export const CREATE_CONTENT_LIBRARY_CONTENT = gql`
  mutation CreateContentLibraryContent($input: CreateContentLibraryContentInput!) {
    createContentLibraryContent(input: $input) {
      ...contentCardFields
    }
  }
  ${CONTENT_CARD_FIELDS}
`;

export const BULK_UPDATE_CONTENT_LIBRARY_CONTENTS = gql`
  mutation updateContentLibraryContents($input: UpdateContentLibraryContentsInput!) {
    updateContentLibraryContents(input: $input) {
      ...contentCardFields
    }
  }
  ${CONTENT_CARD_FIELDS}
`;

export const UPDATE_CONTENT_LIBRARY_CONTENT = gql`
  mutation updateContentLibraryContent($input: UpdateContentLibraryContentInput!) {
    updateContentLibraryContent(input: $input) {
      ...contentCardFields
    }
  }
  ${CONTENT_CARD_FIELDS}
`;

export const GET_URL_FILE_UPLOAD = gql`
  query getSignedUrlUpload($targetPath: String!, $roomId: String!) {
    getSignedUrlUpload(query: { targetPath: $targetPath, roomId: $roomId }) {
      putUrl
      contentRowId
    }
  }
`;

export const ON_DOCUMENT_UPLOAD = gql`
  subscription onContentLibraryDocumentUpload {
    contentLibraryDocumentUpload {
      message {
        contentLibraryRowId
        documentStatus
      }
      error
    }
  }
`;

export const GET_SINGLE_CONTENT_LIBRARY_CONTENT = gql`
  query getContentLibraryContent($id: String!) {
    getContentLibraryContent(query: { id: $id }) {
      ...contentCardFields
    }
  }
  ${CONTENT_CARD_FIELDS}
`;

export const GET_SIGNED_URL_DOWNLOAD_TEMPLATE_CONTENT_LIBRARY = gql`
  query getSignedUrlDownloadTemplateContentLibrary {
    getSignedUrlDownloadTemplateContentLibrary
  }
`;

export const GET_SIGNED_URL_BULK_IMPORT = gql`
  query getSignedUrlBulkImportContentLibrary($targetPath: String!, $roomId: String!) {
    getSignedUrlBulkImportContentLibrary(query: { targetPath: $targetPath, roomId: $roomId }) {
      signedUrl
    }
  }
`;

export const CONTENT_LIBRARY_IMPORT = gql`
  subscription contentLibraryImport {
    contentLibraryImport {
      messageOrder
      error
    }
  }
`;
