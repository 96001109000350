import { MentionData } from 'src/common/Editor/plugins/Mentions/types';
import { safeCallSegment } from '../helpers';
import { TrackEventSource } from './types';

export interface TrackAddCommentData {
  eventSource: TrackEventSource;
  boxId: string;
  mentions: MentionData[];
}

const MIXED_USER_TYPE = 'Mixed'; // mentions include more than one type of users
export function trackAddComment(data: TrackAddCommentData): void {
  safeCallSegment(() => {
    const { mentions: mentionData } = data;
    let type: string = mentionData[0]?.type ?? MIXED_USER_TYPE;
    const mentions = mentionData.map(mention => {
      if (type !== MIXED_USER_TYPE && type !== mention.type) {
        type = MIXED_USER_TYPE;
      }
      return mention.value;
    });
    analytics.track('Add Comment', {
      ...data,
      mentions,
      type: !!mentions.length ? type : ''
    });
  });
}
