import { AlertProps, Alert as BaseAlert } from 'antd';
import classNames from 'classnames';
import * as React from 'react';
import { FC } from 'react';
import styles from './index.module.scss';

type AlertType = AlertProps['type'] | 'update';
function isAntdAlertType(t: AlertType): t is AlertProps['type'] {
  return t === 'error' || t === 'info' || t === 'success' || t === 'warning';
}
export interface IAlertProps {
  type?: AlertType;
  /** Content of Alert */
  message: React.ReactNode;
  /** Additional content of Alert */
  description?: React.ReactNode;
  className?: string;
  showIcon?: boolean;
  closable?: boolean;
  banner?: boolean;
  afterClose?: () => void;
}
export const Alert: FC<IAlertProps> = ({ className, type, banner, ...restProps }) => {
  return (
    <BaseAlert
      className={classNames(
        styles.alert,
        {
          [styles.update]: type === 'update',
          [styles.banner]: !!banner
        },
        className
      )}
      type={isAntdAlertType(type) ? type : undefined}
      {...restProps}
    />
  );
};

export default Alert;
