import { gql } from '@apollo/client';
import { BIDS_FIELDS, BIDS_GENERAL_ITEM_BASIC_FIELDS } from '../queries';
import { WEBHOOKS_CREATE_STATUS_FIELDS } from 'src/models/company/Webhooks/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';

export const GET_BIDS = gql`
  query getBids(
    $offset: Float!
    $amount: Float!
    $sortOnField: GetBidsSortInput
    $rejected: Boolean
    $stageIds: [String!]
    $assignedToIds: [String!]
    $bidItemTypes: [BidItemType!]
    $statusCategories: [WorkspaceStatusCategory!]
    $workspaceIds: [String!]
    $textSearch: BidTextSearch
    $isWebHooksFeature: Boolean!
  ) {
    getBids(
      input: {
        pagination: { amount: $amount, offset: $offset }
        rejected: $rejected
        sort: $sortOnField
        stageIds: $stageIds
        assignedToIds: $assignedToIds
        bidItemTypes: $bidItemTypes
        statusCategories: $statusCategories
        workspaceIds: $workspaceIds
        textSearch: $textSearch
      }
    ) @connection(key: "infinity") {
      total
      bids {
        ...bidsFields
      }
    }
  }
  ${BIDS_FIELDS}
`;

export const GET_BIDS_PAGES = gql`
  query getBidsPages(
    $offset: Float!
    $amount: Float!
    $sortOnField: GetBidsSortInput
    $rejected: Boolean
    $stageIds: [String!]
    $assignedToIds: [String!]
    $bidItemTypes: [BidItemType!]
    $statusCategories: [WorkspaceStatusCategory!]
    $workspaceIds: [String!]
    $textSearch: BidTextSearch
    $isWebHooksFeature: Boolean!
    $contractStart: ContractStartDateFilter
  ) {
    getBids(
      input: {
        pagination: { amount: $amount, offset: $offset }
        rejected: $rejected
        sort: $sortOnField
        stageIds: $stageIds
        assignedToIds: $assignedToIds
        bidItemTypes: $bidItemTypes
        statusCategories: $statusCategories
        workspaceIds: $workspaceIds
        textSearch: $textSearch
        contractStart: $contractStart
      }
    ) @connection(key: "pages") {
      total
      bids {
        ...bidsFields
      }
    }
  }
  ${BIDS_FIELDS}
`;

export const GET_MONTHLY_BIDS_PAGES = gql`
  query getBidsPages(
    $offset: Float!
    $amount: Float!
    $sortOnField: GetBidsSortInput
    $rejected: Boolean
    $stageIds: [String!]
    $assignedToIds: [String!]
    $bidItemTypes: [BidItemType!]
    $statusCategories: [WorkspaceStatusCategory!]
    $workspaceIds: [String!]
    $textSearch: BidTextSearch
    $isWebHooksFeature: Boolean!
    $contractStart: ContractStartDateFilter
    $currency: String
  ) {
    getBids(
      input: {
        pagination: { amount: $amount, offset: $offset }
        rejected: $rejected
        sort: $sortOnField
        stageIds: $stageIds
        assignedToIds: $assignedToIds
        bidItemTypes: $bidItemTypes
        statusCategories: $statusCategories
        workspaceIds: $workspaceIds
        textSearch: $textSearch
        contractStart: $contractStart
        currency: $currency
      }
    ) @connection(key: "pages") {
      total
      bids {
        ...bidsFields
      }
    }
  }
  ${BIDS_FIELDS}
`;

export const GET_TOTAL_BIDS = gql`
  query getTotalBids($offset: Float!, $amount: Float!, $stageIds: [String!]) {
    getBids(input: { pagination: { amount: $amount, offset: $offset }, stageIds: $stageIds }) {
      totalBids
    }
  }
`;

export const BID_CATEGORY_STATISTICS_FIELDS = gql`
  fragment bidCategoryStatisticsFields on BidCategoryStatistics {
    amount
    count
  }
`;

export const ASSIGNED_TO_STATISTICS_FIELDS = gql`
  fragment assignedToStatisticsFields on AssignedToCountResponse {
    id
    bidCount
    assignedTo {
      ...assignedToFields
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const GET_ASSIGNED_TO_STATISTICS = gql`
  query getAssignedToStatistics(
    $rejected: Boolean
    $stageIds: [String!]
    $assignedToIds: [String!]
    $bidItemTypes: [BidItemType!]
    $statusCategories: [WorkspaceStatusCategory!]
    $workspaceIds: [String!]
  ) {
    getAssignedToStatistics(
      input: {
        rejected: $rejected
        stageIds: $stageIds
        assignedToIds: $assignedToIds
        bidItemTypes: $bidItemTypes
        statusCategories: $statusCategories
        workspaceIds: $workspaceIds
      }
    ) {
      ...assignedToStatisticsFields
    }
  }
  ${ASSIGNED_TO_STATISTICS_FIELDS}
`;

export const GET_BIDS_CATEGORY_STATISTICS = gql`
  query getBidCategoryStatistics(
    $rejected: Boolean
    $stageIds: [String!]
    $assignedToIds: [String!]
    $bidItemTypes: [BidItemType!]
    $contractStartRanges: [ContractStartDateFilter!]
    $statusCategories: [WorkspaceStatusCategory!]
    $workspaceIds: [String!]
    $textSearch: BidTextSearch
  ) {
    getBidCategoryStatistics(
      input: {
        rejected: $rejected
        stageIds: $stageIds
        assignedToIds: $assignedToIds
        bidItemTypes: $bidItemTypes
        contractStartRanges: $contractStartRanges
        statusCategories: $statusCategories
        workspaceIds: $workspaceIds
        textSearch: $textSearch
      }
    ) {
      statistics {
        categories {
          InProgress {
            ...bidCategoryStatisticsFields
          }
          Interesting {
            ...bidCategoryStatisticsFields
          }
          Lost {
            ...bidCategoryStatisticsFields
          }
          Submitted {
            ...bidCategoryStatisticsFields
          }
          Won {
            ...bidCategoryStatisticsFields
          }
        }
        startDate
      }
    }
  }
  ${BID_CATEGORY_STATISTICS_FIELDS}
`;

export const GET_BID = gql`
  query getBid($bidId: String!) {
    getBid(input: { bidId: $bidId }) {
      id
      workspace {
        id
      }
      status {
        id
      }
      itemId
      bidStageId
      webhookStatus {
        ...webhooksCreateStatusFields
      }
    }
  }
  ${WEBHOOKS_CREATE_STATUS_FIELDS}
`;

export const CREATE_BIDS = gql`
  mutation createBids(
    $workspaceId: String!
    $itemType: BidItemType!
    $items: [CreateBidItemInput!]!
    $meta: BidCreationContext
  ) {
    createBids(input: { workspaceId: $workspaceId, itemType: $itemType, items: $items, meta: $meta }) {
      id
      itemId
      itemType
      assignedTo {
        ...assignedToFields
      }
      workspace {
        id
      }
      bidStageId
      status {
        id
      }
      webhookStatus {
        ...webhooksCreateStatusFields
      }
      item {
        ... on Tender {
          id
          general {
            ...bidsGeneralItemBasicFields
          }
        }
        ... on UserMail {
          mailId
          general {
            ...bidsGeneralItemBasicFields
          }
        }
        ... on ManualBidItem {
          id
          general {
            ...bidsGeneralItemBasicFields
          }
        }
      }
    }
  }
  ${BIDS_GENERAL_ITEM_BASIC_FIELDS}
  ${WEBHOOKS_CREATE_STATUS_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;

export const UPDATE_BIDS_WS = gql`
  mutation updateBidsWorkspace($workspaceId: String!, $bidsIds: [String!]!) {
    updateBidsWorkspace(input: { workspaceId: $workspaceId, bidsIds: $bidsIds }) {
      id
      itemId
      workspace {
        id
      }
      bidStageId
      status {
        id
      }
    }
  }
`;

export const UPDATE_BID_STATUS = gql`
  mutation updateBidStatus($bidId: String!, $statusId: String!) {
    updateBidStatusV2(input: { bidId: $bidId, statusId: $statusId }) {
      id
      itemId
      workspace {
        id
      }
      bidStageId
      status {
        id
      }
    }
  }
`;
