import { gql } from '@apollo/client';

export const CUSTOM_BID_FIELD = gql`
  fragment customBidFields on CustomBidField {
    id
    companyId
    name
    type
  }
`;

export const GET_ALL_CUSTOM_BID_FIELDS = gql`
  query getAllCustomBidFields {
    getAllCustomBidFields {
      ...customBidFields
    }
  }
  ${CUSTOM_BID_FIELD}
`;

export const CREATE_CUSTOM_BID_FIELD = gql`
  mutation createCustomBidField($name: String, $type: ProcurementBoxFieldType!) {
    createCustomBidField(input: { name: $name, type: $type }) {
      ...customBidFields
    }
  }
  ${CUSTOM_BID_FIELD}
`;

export const DELETE_CUSTOM_BID_FIELD = gql`
  mutation deleteCustomBidField($id: String!) {
    deleteCustomBidField(input: { id: $id })
  }
`;

export const UPDATE_CUSTOM_BID_FIELD = gql`
  mutation updateCustomBidField($id: String!, $name: String) {
    updateCustomBidField(input: { id: $id, name: $name }) {
      ...customBidFields
    }
  }
  ${CUSTOM_BID_FIELD}
`;
