import { PeriodType } from 'src/helpers/dates';
import { IApiOrganization } from '../../organisations';
import { IApiBoxFieldDate } from '../Tender/types';

export type PeriodTransaction = {
  buyerOrgId: string;
  supplierOrgId: string;
  amount: number;
  cumulativeValue: number;
  currency: string;
  period: string;
};

export type CumulativeTransaction = {
  from: string;
  to: string;
  value: number;
  currency: string;
};

export type BuyerCoverageGap = {
  period: string;
};

export type ApiTenderTransactionData = {
  currency: string;
  organisations: IApiOrganization[];
  transactions: PeriodTransaction[];
  cumulativeTransactions: CumulativeTransaction[];
  buyerCoverageGaps: BuyerCoverageGap[];
};

export interface ApiTenderTransactionsOutput<T> {
  getTransactionsPeriod: T;
}

export type ApiTenderTransactionsInput = {
  buyerOrgIds: string[];
  supplierOrgIds: string[];
  dateFrom: string;
  dateTo: string;
  contractStart?: string;
  contractEnd?: string;
  extensionEndDates?: string[];
  periodType?: PeriodType;
  displaySupplierCount?: number;
};

export type ApiTenderPeriodTransactionsInput = Pick<
  ApiTenderTransactionsInput,
  'periodType' | 'buyerOrgIds' | 'supplierOrgIds' | 'dateFrom' | 'dateTo'
>;

export type TenderContractInfo = {
  contractEnd?: number;
  contractStart?: number;
  extensionEndDates?: number[];
  availableDate?: number;
  deadline?: number;
  buyerOrgIds: string[];
};

export interface ApiContractPeriodDatesOutput {
  getTender: {
    expiringContract: {
      contractEnd?: number;
      contractStart?: number;
      extensionEndDates?: number[];
    };
    timeline: {
      availableDate?: {
        fields: IApiBoxFieldDate[];
      };
      deadline?: {
        fields: IApiBoxFieldDate[];
      }[];
    };
    general: {
      buyerBoxes: {
        fields: IApiBoxFieldDate[];
      }[];
    };
  };
}

export const DISPLAY_SUPPLIER_COUNT = 12;
export const CONTRACT_DURATION_YEARS_FALLBACK = 4;
