import React, { FC, ReactNode } from 'react';
import styles from '../index.module.scss';

interface IProps {
  children: ReactNode;
}
const QuickActionsMenu: FC<IProps> = props => {
  const { children } = props;

  return (
    <ul className={styles.actionsMenu} onClick={e => e.stopPropagation()}>
      {children}
    </ul>
  );
};
export default QuickActionsMenu;
