import { gql } from '@apollo/client';
import { USER_PREFERENCES_FIELDS } from '../users/Preferences/queries';
import { USER_REQUEST_SETTINGS_FIELDS } from '../users/RequestSettings/queries';

export const GET_ME_FIELDS = gql`
  fragment meFields on User {
    ...userNameFields
    ...userPreferencesFields
    ...userRequestSettingsFields
    isAdmin
    language
    featureFlags
    mfaMode
    company {
      ...CompanyFields
    }
  }
  ${USER_PREFERENCES_FIELDS}
  ${USER_REQUEST_SETTINGS_FIELDS}
`;

export const GET_ME_INFO = gql`
  query meInfo {
    getMe {
      account {
        ...meFields
      }
    }
  }
  ${GET_ME_FIELDS}
`;
export const GET_FREE_INFO = gql`
  query freeInfo {
    freeUser @client
  }
`;
export const GET_GROUP_ID = gql`
  query getSSOGroup($email: String!) {
    getSSOGroup(email: $email)
  }
`;

export const GET_MS_TEAMS_URL = gql`
  query getMsTeamsUrl($email: String!, $password: String!, $state: String!) {
    getMicrosoftRedirectUrlForMe(email: $email, password: $password, state: $state)
  }
`;
