import dayjs from 'dayjs';
import { Tag } from '../ContentLibraryTag';
import { ApiContentLibraryResponseData } from './types';
import { Room } from '../ContentLibraryRoom';
import { User } from 'src/models/users';
import { ContentLibraryDTO, ContentLibraryDocumentDTO, ContentLibraryType } from '@tendium/prom-types/schema';
import { AssignedTo } from 'src/models/users/types';

export class ContentLibrary {
  readonly content: Content[];
  readonly totalCount: number;
  constructor(private readonly raw: ApiContentLibraryResponseData, allUsersAndTeams: AssignedTo[]) {
    this.totalCount = raw.totalCount;
    this.content = this.toTaskContentData(allUsersAndTeams);
  }

  private toTaskContentData(allUsersAndTeams: AssignedTo[]): Content[] {
    const content = this.raw.rows;

    if (!content) {
      return [];
    }
    return content.map(c => new Content(c, allUsersAndTeams));
  }
}

export class Content {
  public readonly id: string;
  public readonly question: string;
  public readonly answer: string;

  public readonly assignedTo?: User;
  public readonly createdAt: string;
  public readonly createdBy: User;
  public readonly modifiedAt: string;
  public readonly modifiedBy: User;
  public readonly room: Room;
  public readonly tags: Tag[];
  public readonly type: ContentLibraryType;
  public readonly document: ContentLibraryDocumentDTO;

  constructor(private readonly apiContentLibrary: ContentLibraryDTO, allUsersAndTeams: AssignedTo[]) {
    this.id = apiContentLibrary.id;
    this.answer = apiContentLibrary.answer;
    this.question = apiContentLibrary.question;
    this.assignedTo = apiContentLibrary.assignedTo ? new User(apiContentLibrary.assignedTo) : undefined;
    this.createdAt = dayjs(apiContentLibrary.createdAt).format('YYYY-MM-DD');
    this.createdBy = new User(apiContentLibrary.createdBy);
    this.modifiedAt = dayjs(apiContentLibrary.modifiedAt).format('YYYY-MM-DD');
    this.modifiedBy = new User(apiContentLibrary.modifiedBy);
    this.room = new Room(apiContentLibrary.room, allUsersAndTeams);
    this.tags = this.toTags();
    this.type = apiContentLibrary.type;
    this.document = apiContentLibrary.document;
  }

  private toTags(): Tag[] {
    const tags = this.apiContentLibrary.tags;
    if (!tags) {
      return [];
    }
    return tags.map(tag => new Tag(tag));
  }
}
