import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus } from '@fortawesome/pro-solid-svg-icons';
import styles from './index.module.scss';

const TenderRejectedBadge: FC = () => {
  return <FontAwesomeIcon icon={faSquareMinus} className={styles.rejectedIcon} />;
};

export default TenderRejectedBadge;
