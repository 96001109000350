import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ISpinnerIconProps {
  className?: string;
  icon?: IconProp;
}

const SpinnerIcon: FC<ISpinnerIconProps> = props => {
  const { className, icon } = props;
  return <FontAwesomeIcon icon={icon ?? faSpinner} className={classNames(styles.spinnerIcon, className)} />;
};

interface ISpinnerProps {
  className?: string;
  text?: string;
  isLarge?: boolean;
  icon?: IconProp;
}

const Spinner: FC<ISpinnerProps> = props => {
  const { className, text, isLarge, icon } = props;
  if (text) {
    return (
      <div className={classNames(styles.spinner, { [styles.large]: !!isLarge }, className)}>
        <SpinnerIcon icon={icon} />
        <div className={styles.spinnerText}>{text}</div>
      </div>
    );
  }
  return <SpinnerIcon className={className} icon={icon} />;
};

export default Spinner;
