import { gql } from '@apollo/client';
import { ASSIGNED_TO_FIELDS } from '../users/AssignedTo/queries';

export const COMMON_INBOX_FIELDS = gql`
  fragment commonInboxFields on CompanyInbox {
    emailAddress
    inboxName
    isArchived
  }
`;

export const INBOX_FIELDS = gql`
  fragment inboxFields on CompanyInbox {
    ...commonInboxFields
    owners {
      ...assignedToFields
    }
    subscribers {
      ...assignedToFields
    }
    accessControl {
      editPolicy
      subscribePolicy
    }
    accessToEdit
  }
  ${COMMON_INBOX_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;

export const EMAIL_ATTACHMENT_FIELDS = gql`
  fragment emailAttachmentFields on Attachment {
    filePath
    fileName
    getUrl
  }
`;

export const INBOX_EMAIL_BASIC_FIELDS = gql`
  fragment inboxEmailBasicFields on UserMail {
    mailId
    date
    subject
    content
    htmlContent
    attachments {
      ...emailAttachmentFields
    }
    zip {
      ...emailAttachmentFields
    }
  }
  ${EMAIL_ATTACHMENT_FIELDS}
`;

export const INBOX_EMAIL_FIELDS = gql`
  fragment inboxEmailFields on UserMail {
    ...inboxEmailBasicFields
    email
    originalSender
    mailStatus
    isRead
    linkedBidsV2 {
      id
      item {
        ... on Tender {
          id
        }
        ... on UserMail {
          mailId
        }
        ... on ManualBidItem {
          id
        }
      }
      workspace {
        id
      }
    }
    bidsV2 {
      id
      item {
        ... on Tender {
          id
        }
        ... on UserMail {
          mailId
        }
        ... on ManualBidItem {
          id
        }
      }
      workspace {
        id
      }
      status {
        id
      }
    }
  }
  ${INBOX_EMAIL_BASIC_FIELDS}
  ${EMAIL_ATTACHMENT_FIELDS}
`;

export const GET_INBOXES = gql`
  query getInboxes {
    getInboxes {
      ...inboxFields
    }
  }
  ${INBOX_FIELDS}
`;

export const GET_BASE_INFO_OF_INBOXES = gql`
  query getInboxes {
    getInboxes {
      ...commonInboxFields
    }
  }
  ${COMMON_INBOX_FIELDS}
`;

export const GENERATE_INBOX = gql`
  mutation generateInbox($inboxName: String!) {
    generateInbox(data: { inboxName: $inboxName }) {
      ...inboxFields
    }
  }
  ${INBOX_FIELDS}
`;

export const GET_INBOX_EMAILS = gql`
  query getInboxEmails($from: Float, $size: Float, $keywords: [String!], $statuses: [Status!], $emails: [String!]) {
    getInboxEmails(from: $from, size: $size, statuses: $statuses, keywords: $keywords, emails: $emails) {
      total
      userMails {
        ...inboxEmailFields
      }
    }
  }
  ${INBOX_EMAIL_FIELDS}
`;

export const GET_INBOX_TOTAL = gql`
  query getInboxTotals($from: Float, $size: Float, $keywords: [String!], $statuses: [Status!], $emails: [String!]) {
    getInboxEmails(from: $from, size: $size, statuses: $statuses, keywords: $keywords, emails: $emails) {
      total
    }
  }
`;

export const GET_INBOX_EMAIL = gql`
  query getInboxEmail($ids: [String!]!) {
    getInboxEmail(ids: $ids) {
      userMails {
        ...inboxEmailFields
      }
    }
  }
  ${INBOX_EMAIL_FIELDS}
`;

export const UPDATE_INBOX_NAME = gql`
  mutation updateInbox($inboxName: String, $inboxId: String!) {
    updateInbox(data: { inboxName: $inboxName, inboxId: $inboxId }) {
      ...inboxFields
    }
  }
  ${INBOX_FIELDS}
`;

export const UPDATE_INBOX = gql`
  mutation updateInbox(
    $owners: [String!]
    $subscribers: [String!]
    $inboxId: String!
    $editPolicy: AccessControlPolicy
    $subscribePolicy: AccessControlPolicy
  ) {
    updateInbox(
      data: {
        owners: $owners
        subscribers: $subscribers
        inboxId: $inboxId
        editPolicy: $editPolicy
        subscribePolicy: $subscribePolicy
      }
    ) {
      ...inboxFields
    }
  }
  ${INBOX_FIELDS}
`;

export const UPDATE_INBOX_EMAIL_STATUS = gql`
  mutation updateInboxEmailStatus($mailIds: [String!]!, $status: Status!) {
    setInboxMailsStatus(data: { mailIds: $mailIds, status: $status }) {
      ...inboxEmailFields
    }
  }
  ${INBOX_EMAIL_FIELDS}
`;

export const MARK_INBOX_EMAIL_AS_READ = gql`
  mutation setInboxMailsRead($mailIds: [String!]!) {
    setInboxMailsRead(data: { mailIds: $mailIds, readStatus: true }) {
      mailId
      isRead
    }
  }
`;

export const MARK_INBOX_EMAIL_AS_UNREAD = gql`
  mutation setInboxMailsRead($mailIds: [String!]!) {
    setInboxMailsRead(data: { mailIds: $mailIds, readStatus: false }) {
      mailId
      isRead
    }
  }
`;

export const ARCHIVE_INBOX = gql`
  mutation updateArchived($id: String!) {
    updateArchived(data: { emailAddress: $id, archive: true }) {
      emailAddress
      isArchived
    }
  }
`;
