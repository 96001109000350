import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { usePublishedDate } from 'src/helpers';

interface IProps {
  published: number | null;
}

export const Published: FC<IProps> = props => {
  const { published } = props;
  const { t } = useTranslation();
  const publishedDate = usePublishedDate(published);

  return (
    <dl className={styles.date}>
      <dt className={styles.name}>{t('Tenders.published')} :</dt>
      <dd className={styles.value}>{publishedDate}</dd>
    </dl>
  );
};

export default Published;
