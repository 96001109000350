function hashCode(str: string): number {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number): string {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

/**
 * https://stackoverflow.com/a/3426956
 */
export function toColor(str: string): string {
  return `#${intToRGB(hashCode(str))}`;
}

export function toHSLColor(str: string): string {
  const hue = hashCode(str) % 360;
  return `hsl(${Math.abs(hue)}, 100%, 40%)`;
}

// https://github.com/ilariaventurini/colors-convert too heavy to install
export function orRegex(regexes: RegExp[], flag = 'i'): RegExp {
  return new RegExp(regexes.map(r => r.source).join('|'), flag);
}
/*
 * ✓ 'rgb(0, 0, 0)', 'rgb(0,0, 0)', 'rgb(0,    0, 0)', 'rgb(255, 0, 4)', 'rgb(244, 0, 300)'
 * ✗ '(-1, 0, 0)'
 */
const RGB_REGEX = /^((rgb(\s)*\()(\s)*([0-9]+)(\s)*,(\s)*([0-9]+)(\s)*,(\s)*([0-9]+)(\s)*(\)))$/i;
/*
 * ✓ 'hsl(322, 79%, 52%)', 'hsl(322 , 79%, 52%)', 'hsl(322, 79%,52 %)', 'hsl(1000, 79%, 52%)'
 * ✗ '-1, 0, 0', 'hsl(322, 79, 52%)'
 */
const HSL_REGEX = /^((hsl(\s)*\()(\s)*([0-9]+)(\s)*,(\s)*([0-9]+%)(\s)*,(\s)*([0-9]+%)(\s)*(\)))$/i;
/*
 * ✓ #fff, #FFF
 * ✗ #ffffff, #FFFFFF, #FFFFFF00, FF, KKKKKK
 */
const HEX_SHORT = /^#(?:([0-9a-f]{3}))$/i;
/* ✓ #ffffff, #FFFFFF
 * ✗ #FFFFFF00, FF, KKKKKK
 */
const HEX_LONG = /^#(?:([0-9a-f]{6}))$/i;
/*
 * ✓ #fff, #FFF, #fffa, #FFFf
 * ✗ #FFFFFF, #FFFFFF00, FF, KKKKKK
 */
const HEX_REGEX = orRegex([HEX_SHORT, HEX_LONG]);

export function isHex(color: unknown): boolean {
  return typeof color === 'string' && HEX_REGEX.test(color);
}

export function isRgb(color: unknown): boolean {
  return typeof color === 'string' && RGB_REGEX.test(color);
}

export function isHsl(color: unknown): boolean {
  return typeof color === 'string' && HSL_REGEX.test(color);
}

// const DEFAULT_HEX = '#bababa';
const DEFAULT_RGB = 'rgb(185, 185, 185)';
const DEFAULT_HSL = 'hsl(0, 0%, 73%)';

export function hexToRgb(hex: string, alpha?: number): string {
  if (!isHex(hex)) return DEFAULT_RGB;

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export function rgbToHsl(rgb: string): string {
  if (!isRgb(rgb)) return DEFAULT_HSL;

  const [r, g, b] = rgb
    .replace('rgb', '')
    .replace('(', '')
    .replace(')', '')
    .split(',')
    .map(n => Number(n.trim()) / 255);

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  switch (max) {
    case r:
      h = Math.round((((g - b) / delta) % 6) * 60);
      break;
    case g:
      h = Math.round(((b - r) / delta + 2) * 60);
      break;
    case b:
      h = Math.round(((r - g) / delta + 4) * 60);
      break;
  }
  if (h < 0) h += 360;

  l = (max + min) / 2;

  s = delta === 0 ? 0 : Math.abs(delta / (1 - Math.abs(2 * l - 1)));
  l = +(l * 100).toFixed(1);
  s = +(s * 100).toFixed(1);

  const hsl = `hsl(${h}, ${s}%, ${l}%)`;
  return hsl;
}

export function hexToHsl(hex: string): string {
  if (!isHex(hex)) return DEFAULT_HSL;

  const rgb = hexToRgb(hex);

  return rgbToHsl(rgb);
}
