import { gql } from '@apollo/client';

export const WORKSPACE_STATUS_FIELDS = gql`
  fragment WorkspaceStatusFields on WorkspaceStatus {
    id
    name
    description
    order
    category
    rank
  }
`;

export const FETCH_WORKSPACES_STATUSES = gql`
  query ($workspaceId: String!) {
    getWorkspace(id: $workspaceId) {
      id
      statuses {
        ...WorkspaceStatusFields
      }
    }
  }

  ${WORKSPACE_STATUS_FIELDS}
`;

export const DELETE_WORKSPACE_STATUS = gql`
  mutation ($id: String!) {
    deleteWorkspaceStatus(id: $id)
  }
`;

export const CREATE_WORKSPACE_STATUS = gql`
  mutation ($workspaceId: String!, $name: String!, $rank: String!, $category: WorkspaceStatusCategory!) {
    createWorkspaceStatus(workspaceId: $workspaceId, status: { name: $name, rank: $rank, category: $category }) {
      ...WorkspaceStatusFields
    }
  }
  ${WORKSPACE_STATUS_FIELDS}
`;

export const UPDATE_WORKSPACE_STATUS = gql`
  mutation ($id: String!, $name: String, $rank: String, $category: WorkspaceStatusCategory) {
    updateWorkspaceStatus(status: { id: $id, name: $name, rank: $rank, category: $category }) {
      ...WorkspaceStatusFields
    }
  }
  ${WORKSPACE_STATUS_FIELDS}
`;
