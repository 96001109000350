import React, { FC, useMemo } from 'react';
import { MentionsMenuOption } from './MentionMenuOption';
import MentionsMenuItem from './MentionsMenuItem';
import styles from '../index.module.scss';
import { AssignmentType } from 'src/models/users/types';
import { useTranslation } from 'react-i18next';

interface Props {
  options: MentionsMenuOption[];
  selectedIndex: number | null;
  onClick: (i: number, option: MentionsMenuOption) => void;
  onMouseEnter: (i: number) => void;
}

const MentionsTypeaheadMenu: FC<Props> = ({ options, onClick, onMouseEnter, selectedIndex }) => {
  const { t } = useTranslation();
  const grouppedOptions = useMemo(() => {
    const optionsMap = new Map<string, MentionsMenuOption[]>();
    Object.keys(AssignmentType).reduce((map, key) => {
      const filteredOptions = options.filter(option => option.user.type === key);
      filteredOptions.length && map.set(key, filteredOptions);
      return map;
    }, optionsMap);
    return Array.from(optionsMap.entries());
  }, [options]);

  return (
    <>
      {grouppedOptions.map(([key, options], index) => {
        const currIndex = index > 0 ? grouppedOptions[index - 1][1].length : 0;
        return (
          <React.Fragment key={`group_${key}`}>
            {grouppedOptions.length > 1 && (
              <li key={`group_title_${key}`} className={styles.mentionDropdownGroupTitle}>
                {t(`Common.AssignmentType.${key}`, { count: 2 })}
              </li>
            )}

            {!!options.length &&
              options.map((option, i) => {
                const index = currIndex + i;
                return (
                  <MentionsMenuItem
                    index={index}
                    isSelected={selectedIndex === index}
                    onClick={() => onClick(index, option)}
                    onMouseEnter={() => onMouseEnter(index)}
                    key={option.user.id}
                    option={option}
                  />
                );
              })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default MentionsTypeaheadMenu;
