import { makeVar } from '@apollo/client';
import { ReactiveVariable, useReactiveVariable } from 'src/helpers/reactiveVariables';
import { IBids } from 'src/models/bids/Bids/types';

const SelectedBidsVar = makeVar<IBids[]>([]);
export function useSelectedBids(): ReactiveVariable<IBids[]> {
  return useReactiveVariable<IBids[]>(SelectedBidsVar);
}

export default useSelectedBids;
