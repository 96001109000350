import { FilterLogic } from '@tendium/prom-types';
import { MpKeyword, MpKeywordAndGroup, MpKeywordGroup } from '../matchingProfiles/types';

export function getGroupedKeywords(
  keywords: MpKeyword[],
  keywordGroups: MpKeywordGroup[],
  filterLogic: FilterLogic
): MpKeywordAndGroup[] {
  const singleKeywords = keywords.map(keyword => ({
    values: [{ value: keyword.value, id: keyword.id }],
    filterLogic: keyword.filterLogic,
    searchLogic: keyword.searchLogic,
    searchType: keyword.searchType,
    highlightColor: keyword.highlightColor
  }));
  const filteredKeywords = [...singleKeywords, ...keywordGroups].filter(keyword => keyword.filterLogic === filterLogic);

  return filteredKeywords.sort((a, b) => {
    for (let i = 0; i < Math.min(a.values.length, b.values.length); ++i) {
      const aKeyword = a.values[i].value.toLowerCase();
      const bKeyword = b.values[i].value.toLowerCase();

      if (aKeyword !== bKeyword) {
        return aKeyword.localeCompare(bKeyword);
      }
    }

    return a.values.length - b.values.length;
  });
}
