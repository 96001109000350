import React, { CSSProperties, FC } from 'react';
import { Skeleton as BaseSkeleton } from 'antd';

interface ISkeletonTitleProps {
  className?: string;
  style?: CSSProperties;
  width?: number | string;
}
interface ISkeletonParagraphProps {
  className?: string;
  style?: CSSProperties;
  rows?: number;
  width?: number | string;
}

interface ISkeletonProps {
  active?: boolean;
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
  title?: ISkeletonTitleProps | boolean;
  paragraph?: ISkeletonParagraphProps | boolean;
}

export const Skeleton: FC<ISkeletonProps> = props => {
  return <BaseSkeleton {...props} />;
};

export default Skeleton;
