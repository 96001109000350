import { NotificationGroupName } from './types';

const ASSIGNED_TEMPLATE = 'assigned';
const CHANGES_TEMPLATE = 'changed';
const COMMENT_TEMPLATE = 'comment';
const DEADLINE_TEMPLATE = 'submission deadline';

// TODO: switch to real grouping types
export function toNotificationType(title: string): NotificationGroupName {
  if (title.includes(ASSIGNED_TEMPLATE)) {
    return NotificationGroupName.ASSIGNED;
  } else if (title.includes(COMMENT_TEMPLATE)) {
    return NotificationGroupName.COMMENTS;
  } else if (title.includes(CHANGES_TEMPLATE)) {
    return NotificationGroupName.CHANGES_IN_STATUS;
  } else if (title.includes(DEADLINE_TEMPLATE)) {
    return NotificationGroupName.DEADLINE;
  } else {
    return NotificationGroupName.MP_DAILY_SENDOUT;
  }
}
