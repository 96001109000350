import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { IBaseInbox } from 'src/models/callOffs/types';
import { Tooltip } from 'src/common';
import { Paths } from 'src/pages/paths';
import { toInboxId } from 'src/models/callOffs/helpers';

interface Props {
  inbox: IBaseInbox;
  sidebarIsOpen?: boolean;
}

export const InboxesItem: FC<Props> = ({ inbox, sidebarIsOpen }) => {
  const { emailAddress: id, inboxName, color } = inbox;
  const { inboxId } = useParams<{ inboxId: string }>();

  return (
    <Link
      className={classNames(
        styles.menuItem,
        {
          [styles.isActive]: inboxId && id.startsWith(inboxId)
        },
        { [styles.isClosed]: !sidebarIsOpen }
      )}
      tabIndex={0}
      to={`${Paths.CALL_OFFS}/${toInboxId(id)}`}
    >
      <Tooltip
        title={inboxName ?? id}
        placement={'right'}
        className={classNames(styles.tooltip, { [styles.isIconOnly]: !sidebarIsOpen })}
      >
        <span className={styles.itemBadge} style={{ color: color }}></span>
        {sidebarIsOpen && <span className={styles.itemLabel}>{inboxName ?? id}</span>}
      </Tooltip>
    </Link>
  );
};

export default InboxesItem;
