import { safeCallSegment } from '../helpers';
export function trackMissingTranslation(lang: string[], namespace: string, key: string): void {
  safeCallSegment(() => {
    analytics.track('Missing Translation', {
      lang,
      namespace,
      key
    });
  });
}
