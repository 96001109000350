import React, { FC, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useApp, UserType } from 'src/models/auth';
import { Paths } from 'src/pages/paths';
import { useNavigationState } from 'src/helpers/history';
import { StorageKey } from 'src/types/enums';

type Props = {
  permissions?: UserType[];
};
/** responsible only for checking permissions and setting redirect url if not authorized */
const PrivateRoutes: FC<Props> = ({ permissions: routePermissions }) => {
  const { permission: mePermission, user, loading } = useApp();
  const fromUrl = useNavigationState();
  const permissions = [
    ...(routePermissions ? new Set([...routePermissions, UserType.Paid]) : [UserType.Paid]).values()
  ];
  useEffect(() => {
    if (!user && fromUrl) {
      sessionStorage.setItem(StorageKey.FromLogin, fromUrl);
    }
  }, [fromUrl, loading, user]);

  if (user) {
    if (mePermission && permissions.includes(mePermission)) {
      return <Outlet />;
    } else {
      return <Navigate to={Paths.ROOT} replace />;
    }
  } else {
    return <Navigate to={{ pathname: Paths.SIGN_IN }} replace />;
  }
};

export default PrivateRoutes;
