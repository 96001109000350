import React from 'react';
import { createBrowserRouter, RouterProvider as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Root from './Root';
import './inits';

export const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<Router router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
