import React, { ChangeEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import Input from 'src/common/Input';
import dropDownOverlayStyles from 'src/shared/DropDownWithSearch/index.module.scss';
import classNames from 'classnames';
import { useUserSearch } from '../hooks';
import { AssignedTo } from 'src/models/users/types';

interface Props {
  users: AssignedTo[];
  icon?: IconDefinition;
  placeholder?: string;
  className?: string;
  bordered?: boolean;
  onFocus?: () => void;
  disabled?: boolean;
}

export const SearchInput: FC<Props> = ({ users, icon, placeholder, className, bordered, onFocus, disabled }) => {
  const { t } = useTranslation();
  const { data, clear, search } = useUserSearch(users);
  const { searchString } = data;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => search((e.target.value ?? '').toLowerCase()),
    [search]
  );

  return (
    <Input
      bordered={!!bordered}
      placeholder={placeholder ?? t('Common.search')}
      className={classNames({ [dropDownOverlayStyles.searchField]: !bordered }, className)}
      name={'searchPhrase'}
      onChange={onChange}
      value={searchString}
      prefix={<FontAwesomeIcon icon={icon ?? faSearch} />}
      suffix={
        !!searchString && (
          <button onClick={clear} className={dropDownOverlayStyles.buttonClearSearch}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )
      }
      onFocus={onFocus}
      disabled={disabled}
    />
  );
};

export default SearchInput;
