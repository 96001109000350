import React, { FC } from 'react';
import { Skeleton } from 'src/common';
import { RoomListItem } from './RoomListItem';
import { useLoadContentLibraryRooms } from 'src/models/contentLibrary/ContentLibraryRoom/hooks';

interface Props {
  sidebarIsOpen?: boolean;
}

export const ContentLibraryRoomList: FC<Props> = props => {
  const { sidebarIsOpen } = props;

  const { data: rooms, loading } = useLoadContentLibraryRooms();

  return (
    <>
      {(!rooms || loading) && <Skeleton active />}
      {rooms && rooms.map(room => <RoomListItem sidebarIsOpen={sidebarIsOpen} room={room} key={room.id} />)}
    </>
  );
};

export default ContentLibraryRoomList;
