import React, { FC, useCallback, useMemo } from 'react';
import { Translations } from 'src/lib/I18nService/types';
import {
  IBoxField,
  isFieldArray,
  isFieldBoolean,
  isFieldCurrency,
  isFieldCurrencyRange,
  isFieldDate,
  isFieldDateRange,
  isFieldNumber,
  isFieldRange,
  isFieldString,
  isFieldUrl,
  isFieldEmail,
  showFieldCurrency,
  showField,
  showFieldArray,
  showFieldBoolean,
  showFieldRange,
  showFieldCurrencyRange,
  showFieldUrl,
  showFieldEmail,
  showFieldDateRange,
  showFieldDate,
  showFieldNumber,
  showFieldString
} from 'src/models/procurements/Tender/types';
import { BlockBoolean, BlockLink, BlockList, BlockRange, BlockEmail, BlockText } from 'src/shared/Blocks';
import { useExistingTranslation } from 'src/common';
import styles from './index.module.scss';
import BlockCurrency from 'src/shared/Blocks/Currency';
import BlockNumber from 'src/shared/Blocks/Number';
import BlockDate from 'src/shared/Blocks/Date';
import BlockDateRange from 'src/shared/Blocks/DateRange';
import { useUpdateBox, useUpdateCustomBox } from 'src/models/bids/BidPreview/hooks';
import useCurrentTenderId from 'src/reactiveVars/CurrentTenderIdVar';
import useCurrentBidId from 'src/reactiveVars/CurrentBidIdVar';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import classNames from 'classnames';
import BlockCurrencyRange from 'src/shared/Blocks/CurrencyRange';
import { useAddTenderBox, updateCacheAddTenderBox } from 'src/models/procurements/Tender/hooks';
import { IFieldProps } from '../types';
import { BoxSpecId } from 'src/models/procurements/types';
import { BIG_STRING_LIMIT, NOT_EDITABLE_FIELD_NAMES, STRING_FIELD_SIMPLE_NAMES } from './types';
import { toSupplierOrgId, toSupplierOrgName } from 'src/models/procurements/helpers';
import { SupplierLink } from 'src/shared';
import { BidderBoxFieldName } from 'src/models/procurements/PreviewOutcome/types';

interface IProps extends IFieldProps {}
export const BoxField: FC<IProps> = ({
  field,
  value_t = Translations.procurements,
  name_t = Translations.procurements,
  box,
  isEditable,
  className,
  hideEmpty,
  config
}) => {
  const { existingT: t } = useExistingTranslation(name_t);
  const { id: boxId, title, category, specificationId, rawFields, keyName, isCustom } = box;
  const isLongString = useMemo(
    () => isFieldString(field) && !!field.string && field.string.length > BIG_STRING_LIMIT,
    [field]
  );
  const isEditableBidFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Preview);
  const _isEditable = useMemo(
    () => !!isEditableBidFeature && isEditable && !NOT_EDITABLE_FIELD_NAMES.includes(field.name),
    [field.name, isEditable, isEditableBidFeature]
  );

  const [updateTenderBox] = useUpdateBox();
  const [addTenderBox] = useAddTenderBox();
  const [updateCustomBox] = useUpdateCustomBox();
  const [tenderId] = useCurrentTenderId();
  const [bidId] = useCurrentBidId();
  const prevField = useMemo(() => {
    return { ...field };
  }, [field]);

  const onChange = useCallback(
    (currentField: IBoxField) => {
      if (!tenderId || !bidId) return;

      const isEstValueChanged =
        isFieldCurrency(currentField) &&
        isFieldCurrency(prevField) &&
        (currentField.currency === 'SEK' || prevField.currency === 'SEK');

      const isWsStatsAffected =
        (keyName === 'contractValueBoxes' ||
          box.specificationId === BoxSpecId.ESTIMATED_CONTRACT_VALUE ||
          box.specificationId === BoxSpecId.ESTIMATED_CONTRACT_VALUE_2) &&
        isEstValueChanged;

      if (isCustom) {
        updateCustomBox(tenderId, bidId, {
          ...box,
          fields: [currentField.apiField]
        });
      } else if (keyName) {
        addTenderBox({
          variables: {
            id: tenderId,
            bidId,
            box: {
              title,
              category,
              specificationId,
              fields: [currentField.apiField]
            }
          },
          update: updateCacheAddTenderBox(tenderId, keyName, isWsStatsAffected ? bidId : undefined)
        });
      } else {
        const fields =
          rawFields.length === 1
            ? [currentField.apiField]
            : rawFields.map(rawField => (currentField.id === rawField.id ? currentField : rawField).apiField);

        updateTenderBox(
          tenderId,
          bidId,
          {
            specificationId,
            title,
            id: boxId,
            category,
            fields
          },
          isWsStatsAffected
        );
      }
    },
    [
      tenderId,
      bidId,
      prevField,
      keyName,
      box,
      isCustom,
      updateCustomBox,
      addTenderBox,
      title,
      category,
      specificationId,
      rawFields,
      updateTenderBox,
      boxId
    ]
  );

  return (
    <div
      className={classNames(
        styles.field,
        {
          [styles.isTitle]: isLongString && config?.isTitle
        },
        !!className && className
      )}
    >
      {showField(field) && config?.isTitle && <span className={styles.name}>{t(field.name)}</span>}
      <div className={styles.content}>
        {isFieldArray(field) && (hideEmpty ? showFieldArray(field) : true) && (
          <BlockList field={field} translation={value_t} />
        )}
        {isFieldBoolean(field) && (hideEmpty ? showFieldBoolean(field) : true) && (
          <BlockBoolean field={field} onChange={onChange} isEditable={_isEditable} />
        )}
        {isFieldRange(field) && (hideEmpty ? showFieldRange(field) : true) && (
          <BlockRange field={field} translation={value_t} />
        )}
        {isFieldCurrencyRange(field) && (hideEmpty ? showFieldCurrencyRange(field) : true) && (
          <BlockCurrencyRange field={field} translation={value_t} />
        )}
        {isFieldUrl(field) && (hideEmpty ? showFieldUrl(field) : true) && (
          <BlockLink
            field={field}
            onChange={onChange}
            translation={value_t}
            isEditable={_isEditable}
            config={{ ...config, isTooltip: true }}
          />
        )}
        {isFieldEmail(field) && (hideEmpty ? showFieldEmail(field) : true) && (
          <BlockEmail
            field={field}
            onChange={onChange}
            translation={value_t}
            isEditable={_isEditable}
            config={{ ...config, isTooltip: true }}
          />
        )}
        {isFieldDateRange(field) && (hideEmpty ? showFieldDateRange(field) : true) && (
          <BlockDateRange field={field} onChange={onChange} isEditable={_isEditable} config={config} />
        )}
        {isFieldDate(field) && (hideEmpty ? showFieldDate(field) : true) && (
          <BlockDate field={field} onChange={onChange} isEditable={_isEditable} config={config} />
        )}
        {isFieldNumber(field) && (hideEmpty ? showFieldNumber(field) : true) && (
          <BlockNumber field={field} onChange={onChange} isEditable={_isEditable} />
        )}
        {isFieldCurrency(field) && (hideEmpty ? showFieldCurrency(field) : true) && (
          <BlockCurrency field={field} onChange={onChange} isEditable={_isEditable} config={config} />
        )}
        {isFieldString(field) && (hideEmpty ? showFieldString(field) : true) && (
          <BlockText
            field={field}
            onChange={onChange}
            translation={value_t}
            isEditable={_isEditable}
            isSimple={STRING_FIELD_SIMPLE_NAMES.includes(field.name)}
            config={config}
            renderReadOnly={
              specificationId === BoxSpecId.CONTRACT_AWARD_INFORMATION_2 && field.name === BidderBoxFieldName.BidderName
                ? children => (
                    <SupplierLink orgId={toSupplierOrgId(box)} orgName={toSupplierOrgName(box)}>
                      {children}
                    </SupplierLink>
                  )
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

export default BoxField;
