import { makeVar } from '@apollo/client';
import { ReactiveVariable, useReactiveVariable } from 'src/helpers/reactiveVariables';
import { SimpleTendersBaseItem } from 'src/models/procurements/TendersViews';

const SelectedTendersVar = makeVar<SimpleTendersBaseItem[]>([]);
export function useSelectedTenders(): ReactiveVariable<SimpleTendersBaseItem[]> {
  return useReactiveVariable<SimpleTendersBaseItem[]>(SelectedTendersVar);
}

export default useSelectedTenders;
