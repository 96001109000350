import {
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
  faFileZipper
} from '@fortawesome/pro-light-svg-icons';
import { IIcon } from 'src/types/icon';

export interface File {
  filePath: string;
  fileName: string;
  fileExt?: FileExt;
  icon?: IIcon;
}
export enum FileExt {
  doc = 'doc',
  docx = 'docx',
  xls = 'xls',
  xlsx = 'xlsx',
  pdf = 'pdf',
  zip = 'zip',
  rar = 'rar',
  md = 'md',
  jpeg = 'jpeg',
  jpg = 'jpg',
  png = 'png',
  gif = 'gif',
  tiff = 'tiff',
  eps = 'eps',
  svg = 'svg',
  csv = 'csv'
}
export function isFileExt(u: unknown): u is FileExt {
  return typeof u === 'string' && Object.values(FileExt).some(v => v === u);
}
export interface FileExtIcon {
  fileExt: FileExt[];
  icon: IIcon;
}
export const FILE_EXT_MAPPER: FileExtIcon[] = [
  { fileExt: [FileExt.doc, FileExt.docx], icon: { defaultIcon: faFileWord } },
  { fileExt: [FileExt.xls, FileExt.xlsx], icon: { defaultIcon: faFileExcel } },
  { fileExt: [FileExt.pdf], icon: { defaultIcon: faFilePdf } },
  { fileExt: [FileExt.zip, FileExt.rar], icon: { defaultIcon: faFileZipper } },
  { fileExt: [FileExt.md], icon: { defaultIcon: faFileCode } },
  {
    fileExt: [FileExt.jpeg, FileExt.jpg, FileExt.png, FileExt.gif, FileExt.tiff, FileExt.eps, FileExt.svg],
    icon: { defaultIcon: faFileImage }
  },
  { fileExt: [FileExt.csv], icon: { defaultIcon: faFileCsv } }
];
export const PREVIEW_FILE_EXT = [FileExt.pdf, FileExt.docx];
export const CONVERT_FILE_EXT = [FileExt.docx];
