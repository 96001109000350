import { Paths } from 'src/pages/paths';
import { ALL_DASHES_REGEX, isString } from 'src/helpers';
import { IApiOrganization, ORG_ID_BULK_MODE_REGEX, OrgType } from './types';
import { TFunction } from 'i18next';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';

export function getBuyerLink(orgId: string, orgName?: string): string {
  const buyerUrl = `${Paths.ANALYTICS_PAGE}${Paths.BUYER_PAGE.replace(':orgId', orgId.replace(ALL_DASHES_REGEX, ''))}`;
  return !!orgName ? `${buyerUrl}?orgName=${orgName}` : buyerUrl;
}

export function getSupplierLink(orgId: string, orgName?: string): string {
  const supplierUrl = `${Paths.ANALYTICS_PAGE}${Paths.SUPPLIER_PAGE.replace(
    ':orgId',
    orgId.replace(ALL_DASHES_REGEX, '')
  )}`;
  return !!orgName ? `${supplierUrl}?orgName=${orgName}` : supplierUrl;
}

export function isContainOrgId(orgId: string): boolean {
  const value = orgId.trimStart().replace(ALL_DASHES_REGEX, '');

  return ORG_ID_BULK_MODE_REGEX.test(value);
}

export function getSupplierNameByOrgId(supplierData: IApiOrganization[], supplierOrgId: string): string {
  const supplier = supplierData.find(d => d.organisationNumber === supplierOrgId);
  return getFallbackOrgName(supplier?.organisationNumber ?? supplierOrgId, supplier?.organisationName);
}

export function getFallbackOrgName(orgId?: string | null, orgName?: string | null): string {
  return orgName ?? orgId ?? '';
}

export function getFallbackOrgNameFromQP(
  qp: Partial<Record<string, unknown>>,
  orgId?: string | null,
  orgName?: string | null
): string {
  return (isString(qp['orgName']) ? qp['orgName'] : undefined) ?? getFallbackOrgName(orgId, orgName);
}

export function getTranslatedOrgLabel(
  t: TFunction,
  orgType: OrgType,
  options?: { capitalizing?: boolean; plural?: boolean }
): string {
  const label = t(`Common.OrgType.${orgType}`, { count: options?.plural ? 2 : 1 });
  if (options?.capitalizing) {
    return capitalizeFirstLetter(label);
  }
  return label;
}
