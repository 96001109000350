import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PeriodTransaction } from 'src/models/procurements/Billing/types';
import { toCurrencyValue } from 'src/models/procurements/Billing/helpers';
import { IApiOrganization } from 'src/models/organisations';
import styles from '../index.module.scss';
import { SupplierCumulativeValue } from '../types';
import { useLanguage } from 'src/common';

interface Props {
  transactions: PeriodTransaction[];
  period: string;
  suppliers: IApiOrganization[];
  supplierCumulativeValues: SupplierCumulativeValue[];
  currency?: string;
  colorScale: (d: string) => string;
}

const HoverTooltip: FC<Props> = ({
  period,
  currency,
  transactions,
  suppliers,
  supplierCumulativeValues,
  colorScale
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const sortedSupplierData = useMemo(() => {
    const values = suppliers.map(s => {
      const transaction = transactions.find(tr => tr.supplierOrgId === s.organisationNumber);
      return {
        ...s,
        // if no payment for this period, use last period having payment
        cumulativeValue:
          transaction?.cumulativeValue ??
          supplierCumulativeValues.find(val => val.supplierOrgId === s.organisationNumber)?.cumulativeValue ??
          0
      };
    });
    return [...values].sort((a, b) => {
      return b.cumulativeValue - a.cumulativeValue;
    });
  }, [supplierCumulativeValues, suppliers, transactions]);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('Tenders.Billing.totalPayments')}
          <span>({period})</span>
        </div>
      </div>
      <ul>
        {sortedSupplierData.map(d => {
          const formatedValue = toCurrencyValue(language, d.cumulativeValue, currency);
          return (
            <li key={d.organisationNumber}>
              <div className={styles.square} style={{ backgroundColor: colorScale(d.organisationNumber) }}></div>
              <div className={styles.name}>{d.organisationName}</div>
              <div className={styles.value}>{formatedValue}</div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default HoverTooltip;
