import React, { FC } from 'react';
import { PdfLoader, PdfHighlighter, Highlight, IHighlight } from 'react-pdf-highlighter';
import { Modal } from 'src/common';
import LoadingState from './LoadingState';
import styles from './index.module.scss';

export type TextSelectOptions = {
  hideTipAndSelection: () => void;
  selectedText: string;
};

interface IProps {
  title: string;
  onClose: () => void;
  url?: string;
  highlights?: IHighlight[];
  loading?: boolean;
  onSelect?: (data: TextSelectOptions) => JSX.Element | null;
}
const DocumentViewer: FC<IProps> = ({ title, onClose, url, highlights, loading, onSelect }) => {
  return (
    <Modal
      width={1300}
      onCancel={onClose}
      className={styles.modal}
      footer={null}
      title={<span className={styles.title}>{title}</span>}
      visible
    >
      <div className={styles.content}>
        {!url || !!loading ? (
          <LoadingState />
        ) : (
          <PdfLoader url={url} beforeLoad={<LoadingState />}>
            {pdfDocument => (
              <PdfHighlighter
                highlights={highlights || []}
                pdfDocument={pdfDocument}
                highlightTransform={(highlight, index, setTip, hideTip, viewportToScaled, screenshot, isScrolledTo) => {
                  return (
                    <Highlight
                      key={highlight.id}
                      position={highlight.position}
                      comment={highlight.comment}
                      isScrolledTo={isScrolledTo}
                    />
                  );
                }}
                onSelectionFinished={(position, content, hideTipAndSelection) =>
                  onSelect && content.text ? onSelect({ selectedText: content.text, hideTipAndSelection }) : null
                }
                // FIXME: it is required, issue for that: https://github.com/agentcooper/react-pdf-highlighter/issues/159
                onScrollChange={() => {
                  /*empty fn*/
                }}
                scrollRef={scrollTo => {
                  if (highlights && highlights[0]) scrollTo(highlights[0]);
                }}
                enableAreaSelection={() => false}
              />
            )}
          </PdfLoader>
        )}
      </div>
    </Modal>
  );
};

export default DocumentViewer;
