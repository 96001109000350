import {
  $applyNodeReplacement,
  $createTextNode,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type LexicalNode,
  type NodeKey,
  RangeSelection,
  DecoratorNode
} from 'lexical';
import React from 'react';
import { AssignmentType } from 'src/models/users/types';
import {
  MENTION_CLASSNAME,
  MENTION_TRIGGER,
  MENTION_TRIGGER_CLASSNAME,
  MENTION_NODE_TYPE_NAME,
  MENTION_NODE_VERSION,
  MentionData,
  SerializedMentionNode
} from './types';
import MentionComponent from './MentionComponent';

function $convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const text = domNode.textContent;
  const id = domNode.getAttribute('data-id');
  const value = domNode.getAttribute('data-value') ?? id;
  const type = domNode.getAttribute('data-type') ?? AssignmentType.User;

  if (id !== null) {
    return {
      node: $createMentionNode({ id, value, text, type })
    };
  }

  return null;
}

export class MentionNode extends DecoratorNode<React.JSX.Element> {
  /*** Mention data */
  __mention: MentionData;

  static getType(): string {
    return MENTION_NODE_TYPE_NAME;
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mention, node.__key);
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return $createMentionNode(serializedNode.mention);
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }
        return {
          conversion: $convertMentionElement,
          priority: 1
        };
      }
    };
  }

  constructor(mention: MentionData, key?: NodeKey) {
    super(key);
    this.__mention = mention;
  }

  exportJSON(): SerializedMentionNode {
    return {
      mention: this.getMention(),
      type: MENTION_NODE_TYPE_NAME,
      version: MENTION_NODE_VERSION
    };
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span');
    dom.setAttribute('data-lexical-mention', 'true');
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const char = document.createElement('span');
    char.className = MENTION_TRIGGER_CLASSNAME;
    char.textContent = MENTION_TRIGGER;

    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.setAttribute('data-id', this.__mention.id);
    element.setAttribute('data-value', this.__mention.value ?? this.__mention.id);
    element.setAttribute('data-type', this.__mention.type ?? AssignmentType.User);
    element.className = MENTION_CLASSNAME;
    element.textContent = this.__mention.text;
    element.prepend(char);

    return { element };
  }

  updateDOM(): false {
    return false;
  }

  getTextContent(): string {
    return MENTION_TRIGGER + this.__mention.text;
  }

  decorate(): React.JSX.Element {
    return <MentionComponent nodeKey={this.getKey()} data={this.getMention()} />;
  }

  /**
   * Return MentionData attached to a MentionNode
   */
  getMention(): MentionData {
    return this.__mention;
  }

  /**
   * Insert a MentionNode and append a TextNode with whitespace
   */
  insertMention(): RangeSelection {
    const textNode = $createTextNode(' ');
    this.insertAfter(textNode);
    return textNode.select();
  }
}
/**
 * Create a MentionNode
 */
export function $createMentionNode(mention: MentionData): MentionNode {
  const mentionNode = new MentionNode(mention);
  return $applyNodeReplacement(mentionNode);
}

/**
 * Check if a node is a MentionNode instance
 */
export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}
