import React, { FC } from 'react';
import { RemindersList } from 'src/shared/Reminders';
import styles from './index.module.scss';
import { Form, Switch } from 'src/common';
import { useTranslation } from 'react-i18next';
import RemindersCount from './RemindersCount';
import { DebouncedFunc } from 'lodash';
import { useReminders } from 'src/models/reminders/hooks';
import { Skeleton } from 'antd';
interface Props {
  onValuesChange: DebouncedFunc<() => void>;
}

interface FormState {
  createdByMe: boolean;
}

export const RemindersTab: FC<Props> = props => {
  const { onValuesChange } = props;
  const { t } = useTranslation();
  const { remindersCount, loading } = useReminders();

  const [form] = Form.useForm<FormState>();

  const initialValue = {
    createdByMe: false
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <Skeleton active={loading} />
      ) : (
        <>
          {remindersCount && remindersCount > 0 ? (
            <>
              <div className={styles.toggleContainer}>
                <RemindersCount />
                <Form form={form} initialValues={initialValue} onValuesChange={onValuesChange}>
                  <div className={styles.toggle}>
                    <Form.Item
                      name="createdByMe"
                      label={t('Reminders.createdByMe')}
                      valuePropName={'checked'}
                      className={styles.switchField}
                    >
                      <Switch />
                    </Form.Item>
                  </div>
                </Form>
              </div>

              <RemindersList fromNotificationList />
            </>
          ) : (
            <div className={styles.noReminders}>
              <p>{t('Notifications.noReminders')}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RemindersTab;
