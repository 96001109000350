import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import Tooltip from 'src/common/Tooltip';

interface IProps {
  children: ReactNode;
  isOpen?: boolean;
  className?: string;
  actions?: ReactNode;
  tooltip?: ReactNode | boolean;
}
const NavMenuTitle: FC<IProps> = ({ children, isOpen, className, actions, tooltip }) => {
  return isOpen !== false ? (
    <div className={classNames(styles.title, className)}>
      {tooltip ? (
        <Tooltip title={children}>
          <span className={styles.label}>{children}</span>
        </Tooltip>
      ) : (
        <span className={styles.label}>{children}</span>
      )}

      {!!actions && actions}
    </div>
  ) : null;
};

export default NavMenuTitle;
