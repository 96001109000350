import { Button, Skeleton } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useLoadNotifications, useMarkNotificationsAsRead } from 'src/models/notifications/hooks';
import { Paths } from 'src/pages/paths';
import { trackClickOnShowAllNotifications } from 'src/segment/events';
import { NotificationsList } from 'src/shared';
import styles from './index.module.scss';

const NotificationsTab: FC = () => {
  const { t } = useTranslation();

  const { data, loading } = useLoadNotifications();

  const [markAllNotificationsAsRead] = useMarkNotificationsAsRead();

  const isUnreadNotifications = useMemo(
    () => !!data && !!data.notifications.filter(notification => !notification.isRead).length,
    [data]
  );
  const markAllAsRead = useCallback(() => {
    markAllNotificationsAsRead();
  }, [markAllNotificationsAsRead]);

  const location = useLocation();
  const { pathname, search } = location;

  const backHref = useMemo(() => {
    return `${pathname}${search}`;
  }, [pathname, search]);

  return (
    <div className={styles.container}>
      {isUnreadNotifications && (
        <div className={styles.markAllAsRead}>
          <Button type={'link'} onClick={markAllAsRead} className={styles.button}>
            {t('Header.markAllAsRead')}
          </Button>
        </div>
      )}
      {data && data.notifications.length ? (
        <>
          <div className={styles.notificationsList}>
            {loading ? (
              <Skeleton loading active className={styles.loader} />
            ) : (
              <NotificationsList showMarkDown={false} limit={10} />
            )}
          </div>

          <div className={styles.footer}>
            {/* FIXME: should we remove this link and corresponding page? */}
            <Link
              onClick={() => {
                trackClickOnShowAllNotifications();
              }}
              to={{ pathname: Paths.NOTIFICATIONS_PAGE }}
              state={{ from: backHref }}
              className={styles.link}
            >
              {t('Common.showAll')}
            </Link>
          </div>
        </>
      ) : (
        <div className={styles.noNotifications}>
          <p>{t('Notifications.noNotifications')}</p>
        </div>
      )}
    </div>
  );
};

export default NotificationsTab;
