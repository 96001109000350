import { ApiAdminCompany } from './types';
import { Company } from 'src/models/company';
import { ICompanyUser } from 'src/models/company/Users/types';
import { IApiCompetitor } from 'src/models/company/Competitors/types';
import { ISsoConfiguration } from 'src/models/company/Security/types';
import { CompanyUser } from 'src/models/company/Users';
import { CompanySettings } from '@tendium/prom-types/schema';

export class AdminCompany extends Company {
  readonly users: ICompanyUser[];
  readonly competitors: IApiCompetitor[];
  readonly ssoConfig?: ISsoConfiguration;
  readonly settings?: CompanySettings;

  constructor(private readonly apiCompany: ApiAdminCompany) {
    super(apiCompany);
    this.users = this.toCompanyUsers();
    this.competitors = apiCompany.competitors;
    this.ssoConfig = apiCompany.ssoConfiguration ?? undefined;
    this.settings = apiCompany.settings;
  }

  private toCompanyUsers(): ICompanyUser[] {
    const users =
      this.apiCompany.personel.map(user => new CompanyUser(user)).sort((a, b) => a.email.localeCompare(b.email)) || [];
    const owner = this.apiCompany.createdBy ? new CompanyUser(this.apiCompany.createdBy) : undefined;
    return owner ? [owner, ...users] : users;
  }
}
