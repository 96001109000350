import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from 'src/common';
import { Room } from 'src/models/contentLibrary/ContentLibraryRoom';
import { Paths } from 'src/pages/paths';
import { toHSLColor } from 'src/helpers/styles';
import { trackSwitchRoomCL } from 'src/segment/events';

interface Props {
  room: Room;
  sidebarIsOpen?: boolean;
}

export const RoomListItem: FC<Props> = props => {
  const { room, sidebarIsOpen } = props;
  const { id: roomId } = useParams<{ id: string }>();

  const onTrackAnalytics = useCallback(() => {
    trackSwitchRoomCL({ id: room.id, name: room.title });
  }, [room.id, room.title]);

  return (
    <Link
      className={classNames(
        styles.menuItem,
        {
          [styles.isActive]: roomId && roomId === room.id
        },
        { [styles.isClosed]: !sidebarIsOpen }
      )}
      tabIndex={0}
      to={`${Paths.CONTENT_LIBRARY_ROOM.replace(':id', room.id)}`}
      onClick={onTrackAnalytics}
    >
      <Tooltip
        title={room.title}
        placement={'right'}
        className={classNames(styles.tooltip, { [styles.isIconOnly]: !sidebarIsOpen })}
      >
        <span className={styles.itemBadge} style={{ color: toHSLColor(room.id) }} />
        {sidebarIsOpen && <span className={styles.itemLabel}>{room.title}</span>}
      </Tooltip>
    </Link>
  );
};

export default RoomListItem;
