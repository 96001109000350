import { FreeUser, PaidUser } from './index';
import { Role, Language } from '@tendium/prom-types';
import { ICompany } from '../company/types';
import { UserPreferences } from '../users/Preferences/types';
import { IApiUser, MfaMode } from '../users/types';
import { COUNTRIES } from 'src/helpers/constants';
import { isObject } from 'src/helpers';
import { RequestSettings } from '../users/RequestSettings/types';

export type CountryCode = (typeof COUNTRIES)[number]['key'];
export const DEFAULT_COUNTRY: CountryCode = 'SE';
export type AuthAttributes = {
  email: string;
  sub: string;
  email_verified: boolean;
  address?: { formatted?: string };
  phone_number?: string;
  given_name?: string;
  family_name?: string;
  locale?: Language;
  'custom:userType'?: UserType;
  'custom:country'?: CountryCode;
  'custom:orgId'?: string;
  'custom:orgName'?: string;
};
export type FreeUserAttributes = {
  email: string;
  address?: string;
  phone?: string;
  name?: string;
  lastName?: string;
  language?: Language;
  country?: CountryCode;
  orgNumber?: string;
  orgName?: string;
};
export type ApiMeUserFields = {
  paymentPlan: Role;
  company: ICompany;
  isAdmin: boolean;
  language: Language | null;
  featureFlags: string[];
  requestSettings: RequestSettings[];
  settings?: UserPreferences;
  mfaMode?: MfaMode;
};
export type ApiMeUser = IApiUser & ApiMeUserFields;

export type AppData = UseAppCtx & {
  user?: PaidUser | FreeUser;
};
export type UseAppCtx = AppCtxData & {
  setAuth: ({ email }: { email: string }) => void;
};
export type AppCtxData = {
  email?: string;
  loading: boolean;
  error?: Error;
  permission?: UserType;
};
export enum UserType {
  Paid = 'PAID',
  Free = 'FREE'
}
export enum AmplifyError {
  IllegalOrigin = 'IllegalOriginError',
  DisabledUser = 'disabledUserError'
}
export interface IErrorMessage {
  errorMessage: string;
  option: string | null;
}
export enum AuthQueryParams {
  MsRedirectUri = 'redirect_uri',
  MsState = 'state',
  AswError = 'error_description'
}
export interface IAwsError {
  [AuthQueryParams.AswError]: string;
}
export function isAwsError(u: unknown): u is IAwsError {
  return isObject(u) && AuthQueryParams.AswError in u && typeof u[AuthQueryParams.AswError] === 'string';
}
export function isPaidUser(u: unknown): u is PaidUser {
  return isObject(u) && 'permission' in u && u.permission === UserType.Paid;
}
export function isFreeUser(u: unknown): u is FreeUser {
  return isObject(u) && 'permission' in u && u.permission === UserType.Free;
}

export type UseSignUpCtx = [SignUpFlow, (step: SignUpFlow) => void];
export enum SignUpFlow {
  CompanyInfo = 'CompanyInfo',
  UserInfo = 'UserInfo'
}
