import { createContext } from 'react';
import { BidTask } from '.';
import { TaskNavData } from './types';
import { AnswerFormState } from './providers';
import { FormInstance } from 'antd';

export type TasksNavCtx = {
  data: TaskNavData | null;
  loading: boolean;
};
export const TaskNavContext = createContext<TasksNavCtx>({
  data: null,
  loading: false
});
TaskNavContext.displayName = 'TaskNavContext';

export type TaskContextValue = {
  data: BidTask | null;
  loading: boolean;
};

export const TaskContext = createContext<TaskContextValue>({
  data: null,
  loading: false
});
TaskContext.displayName = 'TaskContext';

export type TaskFormContexValue = {
  form: FormInstance<AnswerFormState> | null;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>> | null;
};

export const TaskFormContext = createContext<TaskFormContexValue>({
  form: null,
  isFocused: false,
  setIsFocused: null
});
TaskFormContext.displayName = 'TaskFormContext';
