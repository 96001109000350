import React, { FC } from 'react';
import { Table as BaseTable } from 'antd';
import { TableProps } from 'antd/es/table';
import classNames from 'classnames';
import styles from './index.module.scss';

export type OnRow<DataType> = (record: DataType, index?: number) => React.HTMLAttributes<HTMLElement>;

// typing from antd-lib :/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table: FC<TableProps<any>> = ({ className, size = 'small', pagination = false, ...restProps }) => {
  return (
    <BaseTable
      bordered={false}
      size={size}
      pagination={pagination}
      {...restProps}
      className={classNames(styles.table, !!className && className)}
    />
  );
};
export default Table;
