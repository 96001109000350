import React, { FC, useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'src/common';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as faBellLight } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';
import RemindersInput from './RemindersInput';
import RemindersList from '../RemindersList';
import { useReminders } from 'src/models/reminders/hooks';

interface Props {
  bottomRightPlacement?: boolean;
}

export const RemindersDropdown: FC<Props> = props => {
  const { bottomRightPlacement } = props;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { remindersCount: remindersCountData } = useReminders();

  const remindersCount = useMemo(() => {
    return remindersCountData || 0;
  }, [remindersCountData]);

  const toggleVisible = useCallback(() => {
    setDropdownVisible(prevVisible => !prevVisible);
  }, []);

  return (
    <div className={styles.dropdownWrapper}>
      <Dropdown
        trigger={['click']}
        placement={bottomRightPlacement ? 'bottomRight' : undefined}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        overlay={
          <div className={styles.overlay}>
            {remindersCount > 0 && (
              <div className={styles.remindersList}>
                <RemindersList />
              </div>
            )}
            <RemindersInput />
          </div>
        }
        visible={dropdownVisible}
        onVisibleChange={toggleVisible}
      >
        <div className={styles.remindersContainer}>
          <FontAwesomeIcon
            icon={remindersCount > 0 ? faBellSolid : faBellLight}
            className={classNames(styles.icon, { [styles.hasNotifications]: remindersCount > 0 })}
          />
          {!!remindersCount && <span className={styles.tabCount}>{remindersCount}</span>}
        </div>
      </Dropdown>
    </div>
  );
};

export default RemindersDropdown;
