import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import InviteUsersModal from '../../InviteUsersModal';

export const InviteUsers: FC = () => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const onShowModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onHideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <div className={styles.menuFooter}>
      <button onClick={onShowModal} className={styles.menuActionButton}>
        <span className={styles.menuAction}>
          <FontAwesomeIcon icon={faPlus} className={styles.menuActionIcon} />
          <span className={styles.menuActionTitle}>{t('Users.inviteUsersByEmail')}</span>
        </span>
      </button>
      {modalVisible && <InviteUsersModal onHide={onHideModal} />}
    </div>
  );
};

export default InviteUsers;
