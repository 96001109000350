import React, { FC } from 'react';
import { Footer, FooterView } from 'src/common';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ownership } from 'src/models/ownership';

interface IProps {}

export const MaintenancePage: FC<IProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <ownership.logo />
        </div>
        <div className={styles.heading}>{t('Maintenance.maintenanceUpdateHeading')}</div>
        <div className={styles.subHeading}>
          <span>{t('Maintenance.maintenanceUpdateDesc1')}</span>
          <span>{t('Maintenance.maintenanceUpdateDesc2')}</span>
        </div>
      </div>
      <Footer view={FooterView.info} />
    </div>
  );
};

export default MaintenancePage;
