import React, { FC, useCallback, useMemo } from 'react';
import { trackRejectProcurements, trackUnrejectProcurements } from 'src/segment/events';
import { useMarkAsRejected, getUpdateCacheOnRejectTenders } from 'src/models/procurements/hooks';
import { Tooltip } from 'src/common';
import { useTranslation } from 'react-i18next';
import RejectButton from '../../RejectButton';
import { useApiTenders } from 'src/models/procurements/Tenders/hooks';

export interface IProcToRejecting {
  id: string;
  isRejected: boolean;
  relevanceScore?: number;
}

interface Props {
  procurements: IProcToRejecting[];
  onFinish?: () => void;
  eventSource: string;
  eventType: string;
  mode?: 'full' | 'medium' | 'short';
  disabled?: boolean;
}

export const RejectTendersButton: FC<Props> = ({ mode = 'short', ...restProps }) => {
  const { procurements, eventSource, eventType, onFinish, disabled } = restProps;
  const { t } = useTranslation();
  const [{ vars }] = useApiTenders();

  const procIds = useMemo(() => procurements.map(proc => proc.id), [procurements]);
  const procToUnreject = useMemo(() => {
    return procurements.filter(proc => proc.isRejected);
  }, [procurements]);
  const procToReject: IProcToRejecting[] = useMemo(() => {
    return procurements.filter(proc => !procToUnreject.map(proc => proc.id)?.includes(proc.id));
  }, [procToUnreject, procurements]);

  const [markAsRejected] = useMarkAsRejected();

  const isRejectedButton = useMemo(() => {
    return !procToReject.length && !!procToUnreject.map(proc => proc.id).length;
  }, [procToReject, procToUnreject]);

  const rejectProcurements = useCallback(
    async (procToReject: IProcToRejecting[], isRejected: boolean) => {
      const procurementIds = procToReject.map(proc => proc.id);
      if (!!vars.matchingProfileId) {
        await markAsRejected({
          variables: { matchingProfileId: vars.matchingProfileId, rejected: isRejected, procurementIds },
          update: getUpdateCacheOnRejectTenders(procurementIds, vars.matchingProfileId, isRejected)
        }).then(() => {
          onFinish && onFinish();
          if (isRejected) {
            trackRejectProcurements(
              procurementIds,
              eventSource,
              eventType,
              procToReject.map(proc => {
                return proc.relevanceScore ?? undefined;
              })
            );
          } else {
            trackUnrejectProcurements(procurementIds, eventSource, eventType);
          }
        });
      }
    },
    [eventSource, eventType, markAsRejected, onFinish, vars.matchingProfileId]
  );

  const handleReject = useCallback(async () => {
    if (!!procToReject.length) {
      await rejectProcurements(procToReject, true).then(() => {
        if (!!procToUnreject.length) {
          rejectProcurements(procToUnreject, false);
        }
      });
    } else if (!!procToUnreject.length) {
      rejectProcurements(procToUnreject, false);
    }
  }, [procToReject, procToUnreject, rejectProcurements]);

  return (
    <Tooltip title={mode === 'full' ? undefined : t('Tenders.rejectFromMonitoringProfile')}>
      <RejectButton
        filteredIds={procIds}
        onReject={handleReject}
        mode={mode}
        isRejected={isRejectedButton}
        disabled={disabled}
      />
    </Tooltip>
  );
};
export default RejectTendersButton;
