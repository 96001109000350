import React, { FC, useMemo, useState } from 'react';
import { NotificationsNav } from './NotificationsNav';
import { ActiveNotificationsTab } from 'src/models/notifications/types';
import { useNotificationsTabState } from 'src/models/notifications/hooks';
import NotificationsTab from './NotificationsTab';
import RemindersTab from './RemindersTab';
import styles from './index.module.scss';
import { debounce } from 'lodash';
import { useApp } from 'src/models/auth';
import { RemindersProvider } from 'src/models/reminders/providers';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';
import { trackNotificationReminderToggle } from 'src/segment/events/reminders';
import { FormState } from 'src/shared/Notifications/types';

export const NotificationsMenuContent: FC = () => {
  const [activeNotificationsTab] = useNotificationsTabState();
  const { user } = useApp();
  const [createdByMe, setCreatedByMe] = useState(false);
  const isRemindersFeature = useFeatureFlag(FeatureFlag.Reminders);

  const onValuesChange = useMemo(
    () =>
      debounce((changedValues: Partial<FormState>) => {
        if (changedValues.hasOwnProperty('createdByMe')) {
          setCreatedByMe(prevCreatedByMe => {
            const newCreatedByMe = !prevCreatedByMe;
            trackNotificationReminderToggle(newCreatedByMe);
            return newCreatedByMe;
          });
        }
      }, 300),
    []
  );

  return (
    <>
      {!!isRemindersFeature && (
        <div className={styles.header}>
          <NotificationsNav />
        </div>
      )}
      <div className={styles.content}>
        {activeNotificationsTab === ActiveNotificationsTab.Notifications && <NotificationsTab />}
        {!!isRemindersFeature && (
          <RemindersProvider
            createdBy={!!createdByMe ? user?.id : undefined}
            assignedTo={!createdByMe ? user?.id : undefined}
          >
            {activeNotificationsTab === ActiveNotificationsTab.Reminders && (
              <RemindersTab onValuesChange={onValuesChange} />
            )}
          </RemindersProvider>
        )}
      </div>
    </>
  );
};

export default NotificationsMenuContent;
