import React, { FC } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { WORKSPACE_STATUS_CATEGORY_ICON_MAPPER } from 'src/models/workspace/WorkspaceStatus/mappers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Spinner, Tooltip } from 'src/common';
import { toStatusName } from 'src/models/workspace/WorkspaceStatus/helpers';

interface IProps {
  status: IWorkspaceStatus;
  colorful?: boolean;
  loading?: boolean;
}

export const WorkspaceStatusBadge: FC<IProps> = ({ status, colorful, loading }) => {
  const { t } = useTranslation();
  const icon = WORKSPACE_STATUS_CATEGORY_ICON_MAPPER[status.category];
  const name = toStatusName(status.name, t);

  return (
    <Tooltip title={name}>
      <span
        className={classNames(styles.statusBadge, {
          [styles[`theme${status.category}`]]: !!colorful
        })}
      >
        {icon && icon.defaultIcon && loading !== true && (
          <FontAwesomeIcon icon={icon.defaultIcon} className={styles.statusIcon} />
        )}
        {loading && <Spinner className={styles.statusSpinner} />}
        <span className={styles.statusName}>{name}</span>
      </span>
    </Tooltip>
  );
};

export default WorkspaceStatusBadge;
