import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlainDropDown } from 'src/common';
import styles from './index.module.scss';
import { BidItemType } from '@tendium/prom-types/tender';
import { useBidspacesQpVars, useBidsFilterVars, useResetBidsPage } from 'src/models/bids/Bids/hooks';
import { trackABFilter } from 'src/segment/events/allBidspaces';

const BidspacesFilterType: FC = () => {
  const { t } = useTranslation();

  const { updateVars } = useBidsFilterVars();
  const vars = useBidspacesQpVars();
  const resetPage = useResetBidsPage();

  const currentFilters = useMemo(() => vars ?? {}, [vars]);
  const selectedBidTypes = useMemo(
    () => (currentFilters.bidType ? currentFilters.bidType : undefined),
    [currentFilters]
  );
  const bidTypes = Object.keys(BidItemType).map(key => {
    return {
      label: t(`BidSpaces.BidItemType.${key}`),
      value: key
    };
  });

  const onChange = useCallback(
    (bidType: string, checked?: boolean) => {
      const previousValue = selectedBidTypes || [];
      const currentValue = checked ? [...previousValue, bidType] : previousValue.filter(item => item !== bidType);
      updateVars?.({ bidType: currentValue });
      resetPage();

      if (checked) trackABFilter('Bid type');
    },
    [resetPage, selectedBidTypes, updateVars]
  );

  return (
    <div className={classNames(styles.filter, { [styles.isActive]: selectedBidTypes?.length })}>
      <PlainDropDown
        values={bidTypes}
        selectedValue={selectedBidTypes}
        label={t('BidSpaces.Filters.type')}
        onSelect={onChange}
        buttonClassName={styles.label}
        placement="bottomLeft"
        type="multiple"
        button
      />
    </div>
  );
};

export default BidspacesFilterType;
