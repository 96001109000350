import React from 'react';

export const GoogleIconSVG: () => React.JSX.Element = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 32 32">
    <path
      fill={'#4285f4'}
      d="M31.36 16.364c0-1.135-0.102-2.225-0.291-3.273h-15.069v6.189h8.611c-0.371 2-1.498 3.695-3.193 4.829v4.015h5.171c3.025-2.785 4.771-6.887 4.771-11.76v0z"
    ></path>
    <path
      fill={'#34a853'}
      d="M16 32c4.32 0 7.942-1.433 10.589-3.876l-5.171-4.015c-1.433 0.96-3.265 1.527-5.418 1.527-4.167 0-7.695-2.815-8.953-6.596h-5.345v4.145c2.633 5.229 8.044 8.815 14.298 8.815v0z"
    ></path>
    <path
      fill={'#fbbc05'}
      d="M7.047 19.040c-0.32-0.96-0.502-1.985-0.502-3.040s0.182-2.080 0.502-3.040v-4.145h-5.345c-1.084 2.16-1.702 4.604-1.702 7.185s0.618 5.025 1.702 7.185l5.345-4.145z"
    ></path>
    <path
      fill={'#ea4335'}
      d="M16 6.364c2.349 0 4.458 0.807 6.116 2.393l4.589-4.589c-2.771-2.582-6.393-4.167-10.705-4.167-6.255 0-11.665 3.585-14.298 8.815l5.345 4.145c1.258-3.782 4.785-6.596 8.953-6.596v0z"
    ></path>
  </svg>
);

export default GoogleIconSVG;
