import { ocdsFilterOnlyBuyerBoxes, ocdsFilterOnlySupplierBoxes, ocdsPartyToBuyerBox } from '../helpers';
import { ITenderBox } from '../Tender/types';
import { ApiPreviousTendersItem } from './types';
import dayjs from 'src/helpers/dayjs';

export class PreviousTenderItem {
  readonly id: string;
  readonly title: string;
  readonly buyers: ITenderBox[];
  readonly suppliers: ITenderBox[];
  readonly contractStart?: Date;
  readonly contractEnd?: Date;
  readonly score?: number | null;

  constructor(readonly apiPreviousTender: ApiPreviousTendersItem & { score?: number | null }) {
    const { id, tender, parties } = apiPreviousTender;

    this.id = id;
    this.title = tender?.title ?? '';
    this.buyers = ocdsFilterOnlyBuyerBoxes(parties).map(ocdsPartyToBuyerBox);
    this.suppliers = ocdsFilterOnlySupplierBoxes(parties).map(ocdsPartyToBuyerBox);
    this.contractStart = tender?.contractPeriod?.startDate
      ? dayjs(tender.contractPeriod.startDate).toDate()
      : undefined;
    this.contractEnd = tender?.contractPeriod?.endDate ? dayjs(tender.contractPeriod.endDate).toDate() : undefined;
    this.score = apiPreviousTender.score ?? null;
  }
}
