import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'src/helpers/dayjs';
import { isMonthWithShortName } from 'src/helpers/dates';

interface IDayCount {
  label: string | null;
  value: string;
}
export function useDayCount(deadline: number | null): IDayCount {
  const { t } = useTranslation();

  return useMemo(() => {
    if (deadline === null) {
      return { label: null, value: '—' };
    }
    const now = dayjs().utc().startOf('day');

    const deadlineDate = new Date(deadline);

    const startOfDeadlineDate = dayjs(deadline).utc().startOf('day');

    const diffBetweenDates = startOfDeadlineDate.diff(now, 'day');

    let label: string | null = null;
    const keyForTranslator = isMonthWithShortName(deadlineDate)
      ? 'Common.Format.tableDateFormatForShortMonths'
      : 'Common.Format.tableDateFormat';

    if (diffBetweenDates < 0) {
      label = null;
    } else if (diffBetweenDates === 0) {
      label = t('Common.today');
    } else if (diffBetweenDates === 1) {
      label = t('Common.tomorrow');
    } else if (diffBetweenDates > 1) {
      label = diffBetweenDates <= 365 ? `${t('Common.In')} ${diffBetweenDates} ${t('Common.days')}` : null;
    }

    return { value: `${t(keyForTranslator, { date: deadlineDate })}`, label };
  }, [deadline, t]);
}

export default useDayCount;
