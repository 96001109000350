export enum DndType {
  DocumentsItem = 'DocumentsItem',
  TendersItem = 'TendersItem',
  CallOffsItem = 'CallOffsItem',
  BidsBoardItem = 'BidsBoardItem',
  BidsTableItem = 'BidsTableItem',
  TodoItem = 'TodoItem'
}

export interface IDndItem<T> {
  type: DndType;
  dndItem: T;
  index?: number;
  itemIds?: string[];
}
