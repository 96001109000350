import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/common';
import styles from './index.module.scss';
import BidspacesFilterAssignedTo from './BidspacesFilterAssignedTo';
import { useBidsFilterVars, useResetBidsPage } from 'src/models/bids/Bids/hooks';
import BidspacesFilterBidspace from './BidspacesFilterBidspace';
import BidspacesFilterCategory from './BidspacesFilterCategory';
import BidspacesFilterType from './BidspacesFilterType';
import BidspacesFilterSearch from './BidspacesFilterSearch';
import { useWorkspaceViewState } from 'src/models/workspace/hooks';
import { PageView } from 'src/models/users/Preferences/types';

export const BidspacesFilters: FC = () => {
  const { t } = useTranslation();
  const { clearVars } = useBidsFilterVars();
  const resetBidsPage = useResetBidsPage();
  const [currentView] = useWorkspaceViewState();
  const isDashboard = useMemo(() => currentView === PageView.dashboard, [currentView]);

  const onClear = useCallback(() => {
    clearVars();
    resetBidsPage();
  }, [clearVars, resetBidsPage]);

  return (
    <div className={styles.bar}>
      <div className={styles.filters}>
        {!isDashboard && <BidspacesFilterSearch />}
        <BidspacesFilterBidspace />
        <BidspacesFilterCategory />
        <BidspacesFilterAssignedTo />
        <BidspacesFilterType />
        <Button className={styles.clearButton} type={'link'} onClick={onClear}>
          {t('Common.clear')}
        </Button>
      </div>
    </div>
  );
};
