import { useQuery, QueryResult } from '@apollo/client';
import { GET_MY_COMPANY } from '../company/queries';
import { IUserName, IApiUser, AssignedTo } from 'src/models/users/types';
import { useMemo } from 'react';
import { User } from '.';
import { ApiTeam } from './Team/types';
import { Team } from './Team';
import { useAllTeams } from './Team/hooks';

interface IEmployeesResult
  extends Omit<
    QueryResult<
      { getCompany: { personel: IApiUser[]; teams: ApiTeam[]; createdBy: IApiUser | null } },
      { companyId?: string }
    >,
    'data'
  > {
  data?: {
    personel: IUserName[];
    teams: Team[];
    createdBy: IUserName | null;
  };
}
export function useEmployees(): IEmployeesResult {
  const query = useQuery<
    { getCompany: { personel: IApiUser[]; teams: ApiTeam[]; createdBy: IApiUser | null } },
    { companyId?: string }
  >(GET_MY_COMPANY);

  return useMemo(
    () => ({
      ...query,
      data: query.data
        ? {
            createdBy: query.data.getCompany.createdBy ? new User(query.data.getCompany.createdBy) : null,
            personel: query.data.getCompany.personel.map(user => new User(user)),
            teams: query.data.getCompany.teams.map(team => new Team(team))
          }
        : undefined
    }),
    [query]
  );
}

export function useActiveUsers(): IUserName[] {
  const users = useAllUsers();

  return useMemo(() => users.filter(user => user.enabled), [users]);
}

export function useAllUsers(): IUserName[] {
  const { data: employeesData } = useEmployees();
  const employees = employeesData?.personel;
  const admin = employeesData?.createdBy;

  return useMemo(() => (admin && employees && [admin, ...employees]) || [], [admin, employees]);
}

export function useActiveUsersAndTeams(): AssignedTo[] {
  const teams = useAllTeams();
  const users = useActiveUsers();

  return useMemo(() => [...users, ...teams] || [], [users, teams]);
}

export function useAllUsersAndTeams(): AssignedTo[] {
  const teams = useAllTeams();
  const users = useAllUsers();

  return useMemo(() => [...users, ...teams] || [], [users, teams]);
}
