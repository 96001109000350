import React, { FC } from 'react';
import styles from './index.module.scss';
import CommentItem from '../../../Comments/CommentItem';
import { CommentParentType } from 'src/models/comments/types';
import { ITenderBox } from 'src/models/procurements/Tender/types';

interface IProps {
  box: ITenderBox;
}

export const BoxComments: FC<IProps> = ({ box }) => {
  const { comments } = box;

  return (
    <div className={styles.container}>
      {!!comments && !!comments.length && (
        <>
          <div className={styles.boxHeader} />
          <div className={styles.boxContent}>
            <ul className={styles.commentList}>
              {comments.map(comment => (
                <CommentItem comment={comment} key={comment.id} parentType={CommentParentType.ProcurementBox} />
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default BoxComments;
