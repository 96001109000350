import { Role, Language } from '@tendium/prom-types';
import { ICompany } from '../company/types';
import { UserPreferences } from '../users/Preferences/types';
import { MfaMode } from '../users/types';
import { UserType, ApiMeUser, AuthAttributes, CountryCode } from './types';
import { User } from '../users';
import { RequestSettings } from '../users/RequestSettings/types';

export class PaidUser extends User {
  public readonly paymentPlan: Role;
  public readonly company: ICompany;
  public readonly isAdmin: boolean;
  public readonly language: Language | null;
  public readonly featureFlags: string[];
  public readonly settings?: UserPreferences;
  public readonly requestSettings: RequestSettings[];
  public readonly mfaMode?: MfaMode;
  public readonly permission: UserType;
  public readonly isDisabled: boolean;

  constructor(apiUser: ApiMeUser) {
    super(apiUser);
    this.paymentPlan = apiUser.paymentPlan;
    this.company = apiUser.company;
    this.isAdmin = apiUser.isAdmin;
    this.language = apiUser.language;
    this.featureFlags = apiUser.featureFlags;
    this.settings = apiUser.settings;
    this.requestSettings = apiUser.requestSettings;
    this.mfaMode = apiUser.mfaMode;
    this.permission = UserType.Paid;
    this.isDisabled = !apiUser.company.enabled || !apiUser.enabled;
  }
}

export class FreeUser extends User {
  public readonly company: Pick<ICompany, 'orgNumber' | 'companyName'>;
  public readonly language: Language | null;
  public readonly permission: UserType;
  public readonly address: string | null;
  public readonly phone: string | null;
  public readonly country: CountryCode | null;

  constructor(apiUser: AuthAttributes) {
    super({
      email: apiUser?.email,
      name: apiUser?.given_name ?? null,
      lastName: apiUser?.family_name ?? null,
      enabled: true,
      color: null
    });
    this.company = {
      orgNumber: apiUser['custom:orgId'] ?? '',
      companyName: apiUser['custom:orgName'] ?? ''
    };
    this.language = apiUser.locale ?? null;
    this.address = apiUser.address?.formatted ?? null;
    this.phone = apiUser.phone_number ?? null;
    this.country = apiUser['custom:country'] ?? null;
    this.permission = UserType.Free;
  }
}

export * from './hooks';
export * from './types';
export * from './helpers';
export * from './providers';
