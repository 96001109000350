import { IApiUser, IUserName, AssignmentType, toUserName } from './types';
export class User implements IUserName {
  public readonly id: string;
  public readonly email: string;
  public readonly name: string | null;
  public readonly lastName: string | null;
  public readonly color: string | null;
  public readonly enabled: boolean;
  public readonly userName: string;
  public readonly type: AssignmentType;

  constructor(apiUser: IApiUser) {
    this.id = apiUser.email;
    this.email = apiUser.email;
    this.name = apiUser.name;
    this.lastName = apiUser.lastName;
    this.color = apiUser.color;
    this.enabled = !!apiUser.enabled;
    this.userName = this.toUserName(apiUser);
    this.type = AssignmentType.User;
  }

  private toUserName(user: IApiUser): string {
    const { email, name, lastName } = user;
    return toUserName({ email, name, lastName });
  }
}
