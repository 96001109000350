import { User } from 'src/models/users';
import { PaymentRole, FeatureRole, Country } from '@tendium/prom-types';
import { ICompany, IApiCompany } from './types';
import dayjs from 'src/helpers/dayjs';
import { IUserName } from 'src/models/users/types';

export class Company implements ICompany {
  readonly id: string;
  readonly orgNumber: string;
  readonly companyName: string;
  readonly pricePlan: PaymentRole;
  readonly featureRoles: FeatureRole[];
  readonly enabled: boolean;
  readonly createdOn: string;
  readonly createdBy?: IUserName;
  readonly country: Country;

  constructor(apiCompany: IApiCompany) {
    this.id = apiCompany.id;
    this.orgNumber = apiCompany.orgNumber;
    this.companyName = apiCompany.companyName;
    this.pricePlan = apiCompany.pricePlan;
    this.featureRoles = apiCompany.featureRoles;
    this.enabled = apiCompany.enabled;
    this.createdOn = dayjs(apiCompany.createdOn).format('YYYY-MM-DD');
    this.createdBy = apiCompany.createdBy ? new User(apiCompany.createdBy) : undefined;
    this.country = apiCompany.country;
  }
}
