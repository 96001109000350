import React, { FC, useState, ReactNode, useCallback } from 'react';
import styles from './index.module.scss';
import { Dropdown } from 'src/common';
import ColorPickerOverlay from './ColorPickerOverlay';

export type ColorPickerMode = 'picker' | 'palette' | 'all';

interface IProps {
  onChange?: (color: string) => void;
  onSubmit: (color: string) => void;
  currentColor: string | null;
  children: ReactNode;
  paletteColors?: string[];
  pickerMode?: ColorPickerMode;
  placement?: (typeof Placements)[number] | 'float';
}

export const ColorPicker: FC<IProps> = ({ pickerMode = 'all', ...restProps }) => {
  const { currentColor, placement, onChange, onSubmit, children, paletteColors } = restProps;
  const defaultPlacement = placement !== 'float' ? placement : 'bottomRight';
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const onClose = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  return (
    <Dropdown
      arrow
      trigger={['click']}
      placement={defaultPlacement}
      getPopupContainer={placement === 'float' ? undefined : triggerNode => triggerNode.parentNode as HTMLElement}
      overlay={
        <ColorPickerOverlay
          currentColor={currentColor}
          onChange={onChange}
          onSubmit={onSubmit}
          paletteColors={paletteColors}
          pickerMode={pickerMode}
          onClose={onClose}
        />
      }
      visible={dropdownVisible}
      onVisibleChange={visible => setDropdownVisible(visible)}
      className={styles.dropdownButton}
    >
      {children}
    </Dropdown>
  );
};

export default ColorPicker;
