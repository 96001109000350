import { gql } from '@apollo/client';
import { USER_NAME_FIELDS } from '../queries';
import { GET_ME_FIELDS } from '../../auth/queries';

export const CHANGE_LANGUAGE = gql`
  mutation updateLanguage($id: String!, $language: NotificationLanguage) {
    updateLanguage(input: { id: $id, language: $language }) {
      ...meFields
    }
  }
  ${GET_ME_FIELDS}
`;

export const UPDATE_USER = gql`
  mutation updateUser($email: String!, $name: String, $lastName: String, $color: String) {
    updateUser(input: { email: $email, name: $name, lastName: $lastName, color: $color }) {
      ...userNameFields
    }
  }
  ${USER_NAME_FIELDS}
`;
