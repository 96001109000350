import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import Assignee from 'src/shared/Assignee';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/pro-light-svg-icons';
import Published from 'src/pages/TendersPage/TendersList/Published';
import { IBids } from 'src/models/bids/Bids/types';
import { WorkspaceStatusBadge } from 'src/shared';

interface Props {
  bid: IBids;
  bidIdsCount: number;
}

export const BidsDndPreview: FC<Props> = ({ bid, bidIdsCount }) => {
  const { origin, assignedTo, status, isCommented } = bid;
  const { name, published } = origin;

  return (
    <div
      className={classNames(styles.dragPreview, {
        [styles.isCouple]: bidIdsCount === 2,
        [styles.isMultiple]: bidIdsCount > 2
      })}
    >
      <div className={styles.inner}>
        <span className={styles.colCheck}>
          <span className={styles.check}></span>
        </span>
        {status && (
          <span className={styles.colWs}>
            <WorkspaceStatusBadge status={status} colorful />
          </span>
        )}
        <span className={styles.colPublished}>
          <Published published={published} />
        </span>
        <span className={styles.colTitle}>{name}</span>
        {!!assignedTo && (
          <span className={classNames(styles.colAction, styles.colAssignTo)}>
            <Assignee assignedToId={assignedTo} mode={'onlyAvatar'} />
          </span>
        )}
        {isCommented && (
          <span className={classNames(styles.colAction, styles.colMessage)}>
            <FontAwesomeIcon icon={faCommentDots} />
          </span>
        )}
      </div>
    </div>
  );
};

export default BidsDndPreview;
