import { AwsRum, AwsRumConfig, PageAttributes } from 'aws-rum-web';
import { Config } from 'src/config/config';

let awsRum: AwsRum | null = null;
try {
  if (!awsRum) {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: Config.AwsRUM.GuestRoleArn,
      identityPoolId: Config.AwsRUM.IdentityPoolId,
      endpoint: `https://dataplane.rum.${Config.AwsRegion}.amazonaws.com`,
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
      enableRumClient: !!Config.AwsRUM.ApplicationId && import.meta.env.MODE !== 'development',
      disableAutoPageView: true
    };

    const APPLICATION_ID: string = Config.AwsRUM.ApplicationId;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION: string = Config.AwsRegion;

    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
  }
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

export function recordPageView(params: PageAttributes): void {
  if (!awsRum) return;

  try {
    awsRum.recordPageView(params);
  } catch (e) {
    console.error(e);
  }
}
