import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useNavSidebarCtx } from './hooks';
import { NavSidebarContext } from './context';

export type NavSidebarType = 'min' | 'mid' | 'max';
interface Props {
  children: ReactNode;
  type?: NavSidebarType;
}

export const NavSidebar: FC<Props> = ({ children, type }) => {
  const value = useNavSidebarCtx(type);
  const [sidebarIsOpen, updateSidebar] = value;
  return (
    <NavSidebarContext.Provider value={value}>
      <aside
        className={classNames(styles.aside, {
          [styles.isOpen]: sidebarIsOpen,
          [styles.isClosed]: !sidebarIsOpen,
          [styles.isNarrow]: type === 'min'
        })}
      >
        <div className={styles.asideInner}>
          {!type && (
            <button className={styles.closeButton} onClick={updateSidebar}>
              <FontAwesomeIcon icon={faAngleRight} className={styles.closeButtonIcon} />
            </button>
          )}
          <div className={styles.container}>{children}</div>
        </div>
      </aside>
    </NavSidebarContext.Provider>
  );
};

export default NavSidebar;
export { useNavSidebar } from './hooks';
