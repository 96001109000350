import { ApolloError, MutationUpdaterFn, QueryResult, Reference, useMutation, useQuery } from '@apollo/client';
import { GET_ME_REQUEST_SETTINGS, REQUEST_SETTINGS_FIELDS, SET_REQUEST_SETTINGS } from './queries';
import { ApiMeResponse, notification, useMe } from 'src/common';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { SetRequestSettingsData, SetRequestSettingsVar } from './types';
import { isPaidUser, useApp } from 'src/models/auth';

export function useMyRequestSettings(skip?: boolean): QueryResult<ApiMeResponse> {
  const { user } = useApp();
  return useQuery(GET_ME_REQUEST_SETTINGS, { skip: !isPaidUser(user) || skip });
}

export function useSetRequestSettings(): [
  (variables: SetRequestSettingsVar) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const { data: meData } = useMe();
  const email = meData?.email;
  const [setRequestSettings, { loading, error }] = useMutation<SetRequestSettingsData, SetRequestSettingsVar>(
    SET_REQUEST_SETTINGS
  );
  const setRequestSettingsFn = useCallback(
    (variables: SetRequestSettingsVar) => {
      email &&
        variables.entityId &&
        setRequestSettings({
          variables,
          update: updateCacheOnSetRequestSettings(email, variables)
        }).catch(err => {
          notification.error({
            description: t(err.message),
            message: t('Common.unknownError')
          });
        });
    },
    [email, setRequestSettings, t]
  );
  return useMemo(() => [setRequestSettingsFn, { loading, error }], [setRequestSettingsFn, loading, error]);
}

function updateCacheOnSetRequestSettings(
  email: string,
  vars: SetRequestSettingsVar
): MutationUpdaterFn<SetRequestSettingsData> {
  return (cache, { data }) => {
    if (!data?.setRequestSettings) {
      return;
    }
    const { entityId, type: requestType, settings } = vars;
    const userRef = cache.identify({ __typename: 'User', email });
    cache.modify({
      id: userRef,
      fields: {
        requestSettings(cached: Reference[] = [], { readField }) {
          const alreadyExists = cached.some(
            setting =>
              setting &&
              readField({ fieldName: 'entityId', from: setting }) === entityId &&
              readField({ fieldName: 'requestType', from: setting }) === requestType
          );

          if (alreadyExists) {
            const settingRef = cache.identify({ __typename: 'RequestSettings', entityId, requestType });
            cache.modify({
              id: settingRef,
              fields: {
                settings: () => {
                  return settings;
                }
              }
            });
            return cached;
          }

          const newSettingRef = cache.writeFragment({
            data: { entityId, requestType, settings, __typename: 'RequestSettings' },
            fragment: REQUEST_SETTINGS_FIELDS,
            fragmentName: 'requestSettingsFields'
          });

          return [...cached, newSettingRef];
        }
      }
    });
  };
}
