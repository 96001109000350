import dayjs from 'src/helpers/dayjs';
import { AxisTick, AxisUnit, StackAxises, StackConfig, StackData, StackSerie, MONTH_WIDTH } from './types';
import { scaleUtc } from '@visx/scale';
import { notUndefined } from 'src/helpers/typescript';
import { Range } from './Range';

export class StackSeries<T extends StackData> {
  public readonly stacks: StackSerie<T>[];
  public readonly axises: StackAxises;
  private dates: number[];
  private min: Date;
  private max: Date;
  private domain: [Date, Date];
  private timeScale;
  private maxByWidth: Date;
  private responseScale;

  constructor(private config: StackConfig<T>) {
    this.dates = [...new Set(this.config.data.map(d => d.dates).flat())];
    this.min = this.config.startDate
      ? new Date(this.config.startDate)
      : dayjs(Math.min(...this.dates))
          .startOf('month')
          .toDate();
    this.max = this.config.endDate
      ? new Date(this.config.endDate)
      : dayjs(Math.max(...this.dates))
          .endOf('month')
          .toDate();
    this.domain = [this.min, this.max];
    this.timeScale = scaleUtc({
      range: [0, this.width],
      domain: this.domain
    });
    this.maxByWidth = dayjs(this.timeScale.invert(this.config.parentWidth)).endOf('month').toDate();
    this.responseScale = scaleUtc({
      range: [0, this.config.parentWidth],
      domain: [this.min, this.maxByWidth]
    });
    this.stacks = this.toStacks();
    this.axises = this.toAxises();
  }

  get width(): number {
    const w = this.config.isResponsive
      ? this.config.parentWidth
      : (dayjs(this.max).diff(this.min, 'day') + 1) * (this.config.scale ?? 1);
    return w;
  }
  public update(): StackSeries<T> {
    return this;
  }
  public toInitialScrollLeft(parentWidth: number): number {
    if (!this.config.focusDate) {
      return 0;
    }
    const quarterWidth = 3 * MONTH_WIDTH;
    const focusDate = dayjs(this.config.focusDate);
    const scrollLeft = (focusDate.diff(this.min, 'quarter') + 1) * quarterWidth - parentWidth / 2 + 18;
    return scrollLeft;
  }

  private toStacks(): StackSerie<T>[] {
    return this.config.data.map(d => ({
      id: d.id,
      label: d.title,
      data: d,
      ranges: d.dates
        .map(date => new Date(date))
        .map((date, index) => {
          return index > 0
            ? {
                value: new Range(
                  Math.floor(
                    this.timeScale(
                      this.config.startDate && d.dates[index - 1] < this.config.startDate
                        ? new Date(this.config.startDate)
                        : new Date(d.dates[index - 1])
                    )
                  ),
                  Math.floor(this.timeScale(date))
                ),
                label: new Range(new Date(d.dates[index - 1]), date)
              }
            : undefined;
        })
        .filter(notUndefined)
    }));
  }

  private toAxises(): StackAxises {
    const axisUnits: AxisUnit[] = ['year', 'quarter', 'month'];
    const scale =
      this.config.isResponsive || this.config.parentWidth > this.width ? this.responseScale : this.timeScale;

    return Object.fromEntries(
      axisUnits.reduce((axisMap, currentAxis) => {
        const total = dayjs(this.domain[1]).diff(this.domain[0], currentAxis) + 2;
        axisMap.set(
          currentAxis,
          Array.from({ length: total }, (_, index) => {
            const from =
              index === 0
                ? dayjs(this.min).add(index, currentAxis).toDate()
                : dayjs(this.min).add(index, currentAxis).startOf(currentAxis).toDate();
            const to = dayjs(this.min).add(index, currentAxis).endOf(currentAxis).toDate();

            return {
              label: from,
              range: new Range(Math.floor(scale(from) < 0 ? 0 : scale(from)), Math.floor(scale(to)))
            };
          })
        );
        return axisMap;
      }, new Map<AxisUnit, AxisTick[]>())
    ) as StackAxises;
  }
}
