import React, { FC, useCallback } from 'react';
import AssignTo, { IAssignToProps } from 'src/shared/AssignTo';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import { useAssignToBid, getUpdateCacheOnAssignBid } from 'src/models/bids/hooks';
import { trackAssignTo, TrackEventSource } from 'src/segment/events';
import { AssignedTo } from 'src/models/users/types';

interface IProps extends IAssignToProps {
  bidId: string;
  wsId?: string;
  eventSource: TrackEventSource;
}

export const AssignToBid: FC<IProps> = ({ size = 'm', mode = 'short', ...restProps }) => {
  const { bidId, wsId, eventSource } = restProps;
  const { t } = useTranslation();

  const [assignToBid, { loading }] = useAssignToBid();
  const wsUsers = useWsUsersAndTeams(wsId);

  const onAssign = useCallback(
    async (assignedTo: AssignedTo) => {
      const { id } = assignedTo;
      try {
        trackAssignTo({ eventSource, assignedTo });
        await assignToBid({
          variables: {
            bidId,
            userId: id
          },
          update: getUpdateCacheOnAssignBid(bidId, id)
        });
      } catch {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      }
    },
    [assignToBid, bidId, eventSource, t]
  );

  const onUnAssign = useCallback(async () => {
    try {
      await assignToBid({
        variables: {
          bidId,
          userId: null
        },
        update: getUpdateCacheOnAssignBid(bidId, null)
      });
    } catch {
      notification.error({
        description: t('Common.unknownErrorDesc'),
        message: t('Common.unknownError')
      });
    }
  }, [assignToBid, bidId, t]);

  return (
    <AssignTo
      onAssign={onAssign}
      onUnAssign={onUnAssign}
      isUpdating={loading}
      users={wsUsers}
      size={size}
      mode={mode}
      {...restProps}
    />
  );
};
export default AssignToBid;
