import styles from './index.module.scss';
import React, { FC, useCallback, useState, useMemo } from 'react';
import classNames from 'classnames';
import { UserName, Dropdown } from 'src/common';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paths } from 'src/pages/paths';
import HeaderAction from '../HeaderAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUserCircle, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { signOut } from 'aws-amplify/auth';
import { DELETED_USER } from 'src/models/users/types';
import { useApp } from 'src/models/auth/hooks';
import { isPaidUser } from 'src/models/auth/types';
import { StorageKey } from 'src/types/enums';

export const UserMenu: FC = () => {
  const { user } = useApp();
  const { t } = useTranslation();

  const location = useLocation();
  const { pathname, search } = location;
  const isPaid = isPaidUser(user);
  const backHref = useMemo(() => {
    return `${pathname}${search}`;
  }, [pathname, search]);

  const logout = useCallback(async () => {
    await signOut();
    sessionStorage.removeItem(StorageKey.FromLogin);
  }, []);
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuVisible(!menuVisible);
  }, [menuVisible]);

  return !!user ? (
    <Dropdown
      trigger={['click']}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      visible={menuVisible}
      onVisibleChange={toggleMenu}
      overlayClassName={styles.userMenu}
      overlay={
        <div className={styles.menu}>
          <div className={styles.menuInner}>
            <div className={classNames(styles.menuItem, styles.menuUser)}>
              <UserName mode={'onlyAvatar'} user={user} className={styles.menuAvatar} size={'l'} />
              <div className={styles.userInfo}>
                <span>{user.userName}</span>
                {isPaid && <span>{t(`FeatureRole.role_${user.company.pricePlan}`)}</span>}
              </div>
            </div>
            <div className={styles.menuItem}>
              <span className={styles.menuItemTitle}>{t('Common.account')}</span>
              <Link
                to={{ pathname: `${Paths.SETTINGS_PAGE}${Paths.SETTINGS_PAGE_ACCOUNT}` }}
                state={{ from: backHref }}
                className={styles.action}
              >
                <FontAwesomeIcon icon={faUserCircle} className={styles.actionIcon} />
                <span className={styles.actionTitle}>{t('Common.profile')}</span>
              </Link>
            </div>
            {isPaid && (
              <div className={styles.menuItem}>
                <span className={styles.menuItemTitle}>{user.company.companyName || t('Company.company')}</span>
                <Link
                  to={{ pathname: `${Paths.SETTINGS_PAGE}${Paths.SETTINGS_PAGE_COMPANY_INFO}` }}
                  state={{ from: backHref }}
                  className={styles.action}
                >
                  <FontAwesomeIcon icon={faCog} className={styles.actionIcon} />
                  <span className={styles.actionTitle}>{t('Company.company')}</span>
                </Link>
              </div>
            )}
            <div className={styles.menuItem}>
              <button onClick={logout} className={classNames(styles.action, styles.signOutButton)}>
                <FontAwesomeIcon icon={faSignOut} className={styles.actionIcon} />
                <span className={styles.actionTitle}>{t('Header.signOut')}</span>
              </button>
            </div>
          </div>
        </div>
      }
    >
      <HeaderAction>
        <UserName mode={'onlyAvatar'} user={user ?? DELETED_USER} bordered className={styles.avatar} />
      </HeaderAction>
    </Dropdown>
  ) : null;
};

export default UserMenu;
