import { createContext } from 'react';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { ChartColorScale, ChartMargin } from '../types';

export type XYChartContext = {
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
  colorScale: ChartColorScale;
  xMax: number;
  yMax: number;
  margin: ChartMargin;
};

export const XYChartContext = createContext<XYChartContext | null>(null);
XYChartContext.displayName = 'XYChartContext';
