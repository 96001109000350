import React, { FC } from 'react';
import styles from './index.module.scss';
import { Header } from 'src/shared';
import { Skeleton } from 'src/common';

const PageSkeleton: FC = () => (
  <div className={styles.container}>
    <Header />
    <aside className={styles.nav}>
      <Skeleton active loading />
    </aside>
    <aside className={styles.sidebar}>
      <Skeleton active loading />
    </aside>
    <main className={styles.main}>
      <Skeleton active loading />
    </main>
  </div>
);

export default PageSkeleton;
