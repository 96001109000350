import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from './index.module.scss';
import { FieldViewMode } from '../Tender/Boxes/types';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import AddValueButton from './AddValueButton';
import { useClickAway, useKey } from 'react-use';
import EditableBuyer from './Editable';
import ReadOnlyBuyer from './ReadOnly';
import BaseBadge from 'src/common/BaseBadge';
import { toBuyerName } from 'src/models/procurements/helpers';
import { MultiBuyerBoxesProps } from './type';

export const MultiBuyerBoxes: FC<MultiBuyerBoxesProps> = ({
  buyerBoxes,
  linkBox,
  className,
  isEditable,
  noLink,
  noTooltip,
  size
}) => {
  const [leadBuyer, ...otherBuyers] = buyerBoxes ?? [];

  const otherBuyerNames = useMemo(() => {
    return otherBuyers?.map(buyerBox => ({
      name: toBuyerName(buyerBox),
      key: buyerBox.id
    }));
  }, [otherBuyers]);

  const isEditableBidFeature = useFeatureFlag(FeatureFlag.BiddingWorkflow_Preview);
  const _isEditable = useMemo(() => !!isEditableBidFeature && isEditable, [isEditable, isEditableBidFeature]);
  const isMore = useMemo(() => !!otherBuyers?.length, [otherBuyers?.length]);

  const [currentMode, setCurrentMode] = useState(FieldViewMode.Read);
  const ref = useRef<HTMLDivElement>(null);

  const switchToReadMode = useCallback(() => {
    if (_isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [_isEditable, currentMode]);

  const onToggleView = useCallback(() => {
    _isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [_isEditable]);

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    switchToReadMode();
  });

  return (
    <div className={classNames(styles.buyer, className)}>
      {currentMode === FieldViewMode.Edit && (
        <EditableBuyer
          containerRef={ref}
          onFinish={switchToReadMode}
          className={className}
          buyerBox={leadBuyer}
          linkBox={linkBox}
        />
      )}

      {currentMode === FieldViewMode.Read && (
        <>
          {leadBuyer ? (
            <div
              className={classNames(styles.toggler, styles.content, {
                [styles.isEditable]: _isEditable,
                [styles.isMore]: isMore
              })}
            >
              <ReadOnlyBuyer buyerBox={leadBuyer} linkBox={linkBox} size={size} noLink={noLink} noTooltip={noTooltip} />
              {isMore && (
                <BaseBadge
                  theme={'info'}
                  title={`+${otherBuyers?.length}`}
                  className={styles.badge}
                  tooltip={
                    !noTooltip ? (
                      <>
                        {otherBuyerNames.map(buyer => (
                          <div key={buyer.key}>{buyer.name}</div>
                        ))}
                      </>
                    ) : undefined
                  }
                />
              )}
              {_isEditable && (
                <button className={styles.togglerIcon} onClick={onToggleView}>
                  <FontAwesomeIcon icon={faPen} />
                </button>
              )}
            </div>
          ) : (
            <AddValueButton onToggleView={onToggleView} isEditable={!!_isEditable} />
          )}
        </>
      )}
    </div>
  );
};

export default MultiBuyerBoxes;
