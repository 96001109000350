import { useLocation } from 'react-router-dom';
import {
  AmplifyError,
  AppCtxData,
  AppData,
  AuthAttributes,
  AuthQueryParams,
  IErrorMessage,
  isAwsError,
  SignUpFlow,
  UseAppCtx,
  UserType,
  UseSignUpCtx
} from './types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { isString } from 'src/helpers';
import { GET_ME_INFO, GET_MS_TEAMS_URL } from './queries';
import { LazyQueryResultTuple, makeVar, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { AppContext, SignUpFlowContext } from './context';
import { FreeUser, PaidUser } from './index';
import { ApiMeResponse } from 'src/common';
import { StorageKey } from 'src/types/enums';
import { cache } from 'src/lib/API/graphql/cache';
import { Config } from 'src/config/config';
import { ownership } from '../ownership';
import { useTranslation } from 'react-i18next';

Amplify.configure({
  Auth: {
    Cognito: {
      /** not presented in aws-amplify@v6 config:
       * aws_project_region: Config.AwsRegion,
       * aws_cognito_region: Config.AwsRegion,
       * federationTarget: 'COGNITO_USER_POOLS
       */
      userPoolId: Config.CognitoPoolId,
      userPoolClientId: Config.CognitoClientId,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          responseType: 'code',
          domain: Config.CognitoDomain,
          ...ownership.oAuth
        }
      }
    }
  }
});

const freeUserVar = makeVar<AuthAttributes | undefined>(undefined);
type FreeUserUpdateVars = Partial<Pick<FreeUser, 'address' | 'country' | 'language' | 'lastName' | 'name' | 'phone'>> &
  Partial<FreeUser['company']>;
export function useUpdateFreeUser(): (input: FreeUserUpdateVars) => void {
  const current = freeUserVar();
  return useCallback(
    ({ address, country, companyName, language, lastName, name, orgNumber, phone }: FreeUserUpdateVars) => {
      current &&
        freeUserVar({
          ...current,
          address: address ? { formatted: address } : current?.address,
          'custom:country': country ?? current['custom:country'],
          'custom:orgName': companyName ?? current['custom:orgName'],
          locale: language ?? current?.locale,
          family_name: lastName ?? current.family_name,
          given_name: name ?? current.given_name,
          'custom:orgId': orgNumber ?? current['custom:orgName'],
          phone_number: phone ?? current.phone_number
        });
    },
    [current]
  );
}

export const useAppCtx = (): UseAppCtx => {
  const { i18n } = useTranslation();
  const [result, setResult] = useState<AppCtxData>(() => ({ loading: true }));
  const [loadPaidUser] = useLazyQuery<ApiMeResponse>(GET_ME_INFO);

  const handlePaidUser = useCallback(async () => {
    try {
      const { data: meData, error } = await loadPaidUser();

      if (!meData || !meData?.getMe || error) {
        throw new Error("Couldn't fetch user data");
      }

      if (!meData.getMe.account?.company.enabled || !meData.getMe.account.enabled) {
        setResult(() => ({ loading: false, error: new Error(AmplifyError.DisabledUser) }));
      } else {
        setResult(() => ({ permission: UserType.Paid, loading: false }));
      }
    } catch (error) {
      setResult(() => ({ loading: false, error }));
      await signOut(); // Resetting the cognito session if we failed to fetch user data
    }
  }, [loadPaidUser]);

  const handleFreeUser = useCallback((data: AuthAttributes) => {
    setResult(() => ({ permission: UserType.Free, loading: false }));
    freeUserVar(data);
  }, []);

  /**
   * Hub events like `tokenRefresh` will not give back the user object.
   * This util will be used to get current user after those events.
   */
  const updateUser = useCallback(async (): Promise<void> => {
    const session = await fetchAuthSession();
    const attributes = session?.tokens?.idToken?.payload as AuthAttributes;
    setResult(currentState => ({ ...currentState, loading: true }));
    try {
      if (session.tokens?.accessToken) {
        const permission = attributes?.['custom:userType'];
        if (permission === UserType.Free) {
          handleFreeUser(attributes as AuthAttributes);
        } else {
          await handlePaidUser();
        }
      }
    } catch (error) {
      setResult(() => ({ loading: false, error: error instanceof Error ? error : undefined }));
      freeUserVar(undefined);
    } finally {
      setResult(currentState => ({ ...currentState, loading: false }));
    }
  }, [handleFreeUser, handlePaidUser]);

  const handleAuth = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ payload: { data, event, message } }: { payload: any }) => {
      switch (event) {
        // success events
        case 'signedIn':
        case 'signUp':
        case 'autoSignIn': {
          freeUserVar(undefined);
          localStorage.removeItem(StorageKey.DefaultSearchProfileId);
          await cache.reset();
          await updateUser();
          break;
        }
        case 'signedOut': {
          setResult(() => ({ loading: false }));
          freeUserVar(undefined);
          sessionStorage.clear();
          localStorage.clear();
          i18n.init();
          await cache.reset();
          break;
        }
        // failure events
        case 'tokenRefresh_failure':
        case 'signIn_failure': {
          setResult(() => ({ error: data as Error, loading: false }));
          break;
        }
        case 'autoSignIn_failure': {
          // autoSignIn just returns error message. Wrap it to an Error object
          setResult(() => ({ error: new Error(data.message), loading: false }));
          setResult(() => ({ error: new Error(message), loading: false }));
          break;
        }
        // events that need another fetch
        case 'tokenRefresh': {
          await updateUser();
          break;
        }
        default: {
          // we do not need to handle other hub events like `configured`.
          break;
        }
      }
    },
    [i18n, updateUser]
  );

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', handleAuth, 'useApp');
    updateUser();

    return unsubscribe;
  }, [handleAuth, updateUser]);

  const setAuth = useCallback(({ email }: { email: string }) => {
    setResult(currentState => ({ ...currentState, email }));
  }, []);

  return useMemo(() => ({ ...result, setAuth }), [result, setAuth]);
};

export function useApp(): AppData {
  const ctx = useContext(AppContext);
  if (!ctx) {
    throw new Error('useApp used not in AppContext provider');
  }

  const { permission } = ctx;
  const rawFreeUser = useReactiveVar(freeUserVar);
  const { data } = useQuery<ApiMeResponse>(GET_ME_INFO, {
    skip: (permission === UserType.Free && !!permission) || !permission
  });
  const paidUser = useMemo(
    () => (!!data?.getMe.account ? new PaidUser(data.getMe.account) : undefined),
    [data?.getMe.account]
  );
  const freeUser = useMemo(() => (rawFreeUser ? new FreeUser(rawFreeUser) : undefined), [rawFreeUser]);
  const user = useMemo(
    () => (permission ? (permission === UserType.Free ? freeUser : paidUser) : undefined),
    [permission, freeUser, paidUser]
  );

  return useMemo(() => ({ ...ctx, user }), [ctx, user]);
}

export function useSignUpFlow(): UseSignUpCtx {
  const ctx = useContext(SignUpFlowContext);
  if (!ctx) {
    throw new Error('not in SignUpFlowContext provider');
  }

  return ctx;
}

export function useSignUpCtx(): UseSignUpCtx {
  const [step, setStep] = useState(SignUpFlow.CompanyInfo);
  return useMemo(() => [step, setStep], [step]);
}

export function useAwsError(): [IErrorMessage | null, () => void] {
  const { search } = useLocation();

  useEffect(() => {
    if (search.includes(`?${AuthQueryParams.AswError}`)) {
      const qpError = qs.parse(search.replace('?', ''));
      if (isAwsError(qpError)) {
        const errorMessage = getErrorMessage(qpError[AuthQueryParams.AswError]);
        sessionStorage.setItem(StorageKey.AwsError, JSON.stringify(errorMessage));
      }
    }
  }, [search]);

  const clearFn = useCallback(() => {
    sessionStorage.removeItem(StorageKey.AwsError);
  }, []);
  const res = sessionStorage.getItem(StorageKey.AwsError);
  return [isString(res) ? JSON.parse(res) : res, clearFn];
}

const errorRegexp = new RegExp(
  /(for [a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)|( [a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
);
function getErrorMessage(initialErrorMessage: string): IErrorMessage {
  const matchesInInitErrorMessage = initialErrorMessage.match(errorRegexp);

  const option = matchesInInitErrorMessage && matchesInInitErrorMessage[0];
  const errorMessage = option
    ? initialErrorMessage.replace(option, '').replace(/[!.,]/g, '').trim()
    : initialErrorMessage;
  return { errorMessage, option };
}

interface IMsTeamsRequest {
  email: string;
  password: string;
  state: string;
}
interface IMsTeamsResponse {
  getMicrosoftRedirectUrlForMe: string;
}
export function useMsTeamsUrl(): LazyQueryResultTuple<IMsTeamsResponse, IMsTeamsRequest> {
  return useLazyQuery<IMsTeamsResponse, IMsTeamsRequest>(GET_MS_TEAMS_URL, {
    fetchPolicy: 'no-cache'
  });
}
