import React, { FC, ReactNode, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Button } from 'src/common';
import classNames from 'classnames';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  children: ReactNode;
  label: ReactNode;
  initialValue?: boolean;
  onToggleComplete?: () => void;
  className?: string;
  value?: boolean;
  labelVisible?: boolean;
  labelClassname?: string;
}

export const Accordion: FC<Props> = ({
  children,
  label,
  initialValue,
  onToggleComplete,
  className,
  value,
  labelVisible,
  labelClassname
}) => {
  const [isOpen, setIsOpen] = useState(initialValue ?? false);

  const toggleCollapse = (): void => {
    setIsOpen(prev => !prev);
    onToggleComplete && onToggleComplete();
  };

  const open = useMemo(() => value ?? isOpen, [value, isOpen]);
  return (
    <div className={classNames(styles.accordion, className)}>
      <Button className={styles.button} type={'text'} onClick={toggleCollapse}>
        {labelVisible !== false && <div className={classNames(styles.label, labelClassname)}>{label}</div>}
        <FontAwesomeIcon icon={faAngleDown} className={classNames(styles.icon, { [styles.isExpanded]: open })} />
      </Button>

      {open && <div>{children}</div>}
    </div>
  );
};

export default Accordion;
