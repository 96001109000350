import { gql } from '@apollo/client';

export const PAGE_PREFERENCES_FIELDS = gql`
  fragment pagesPreferencesFields on PageSettings {
    id
    view
    tab
    columns {
      name
      order
    }
    description
    supplierProfile {
      profileId
      tenderStatus
      dateFilter {
        start
        end
      }
      activeTable
    }
    buyerProfile {
      profileId
      tenderStatus
      supplierOrgIds
    }
    marketDashboardFilters {
      profileId
      tenderStatus
      dateFilter {
        start
        end
      }
    }
    openTaskGroup
  }
`;

export const USER_PREFERENCES_FIELDS = gql`
  fragment userPreferencesFields on User {
    settings {
      minimal
      pages {
        ...pagesPreferencesFields
      }
    }
  }
  ${PAGE_PREFERENCES_FIELDS}
`;

export const GET_ME_PREFERENCES = gql`
  query mePrefs {
    getMe {
      account {
        email
        ...userPreferencesFields
      }
    }
  }
  ${USER_PREFERENCES_FIELDS}
`;

export const UPDATE_ME_PREFS = gql`
  mutation updatePrefs($minimal: Boolean, $pages: [PageSettingsInput!]) {
    updateSettings(input: { minimal: $minimal, pages: $pages }) {
      email
      ...userPreferencesFields
    }
  }
  ${USER_PREFERENCES_FIELDS}
`;

export const UPDATE_PAGE_PREFS = gql`
  mutation updatePagePrefs(
    $id: String!
    $view: String
    $tab: String
    $columns: [ColumnSettingsInput!]
    $marketDashboardFilters: AnalyticsFilterSettingsInput
    $description: String
    $supplierProfile: SupplierProfileSettingsInput
    $openTaskGroup: String
    $buyerProfile: BuyerProfileSettingsInput
  ) {
    updatePageSettings(
      input: {
        id: $id
        view: $view
        tab: $tab
        columns: $columns
        marketDashboardFilters: $marketDashboardFilters
        description: $description
        supplierProfile: $supplierProfile
        openTaskGroup: $openTaskGroup
        buyerProfile: $buyerProfile
      }
    ) {
      ...pagesPreferencesFields
    }
  }
  ${PAGE_PREFERENCES_FIELDS}
`;
