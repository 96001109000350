import { gql } from '@apollo/client/core';

export const CHECKLIST_TEMPLATE_ITEM_FIELDS = gql`
  fragment checklistTemplateItemFields on ChecklistTemplateItem {
    id
    name
    description
    status
  }
`;

export const CHECKLIST_TEMPLATE_FIELDS = gql`
  fragment checklistTemplateFields on ChecklistTemplate {
    id
    name
    items {
      ...checklistTemplateItemFields
    }
    isPreMade
  }
  ${CHECKLIST_TEMPLATE_ITEM_FIELDS}
`;
