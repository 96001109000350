import { ApiMeUser, isPaidUser, PaidUser, useApp, UserType } from 'src/models/auth';
import { useMemo } from 'react';

export interface ApiMeResponse {
  getMe: {
    account: ApiMeUser | null;
  };
}
export interface MeResponse {
  data?: PaidUser | null;
}
/** @deprecated probably would be enough to use user from useApp hook */
export function useMe(): MeResponse {
  const { permission, user } = useApp();

  return useMemo(
    () => ({
      data: permission === UserType.Paid && isPaidUser(user) ? user : undefined
    }),
    [permission, user]
  );
}

export default useMe;
