import { OAuth, OWNERSHIPS, Ownerships, OwnershipLinks, Favicon, LogoProps, PaymentRoles } from './types';

export class Ownership {
  public readonly name: Ownerships;
  public readonly qrCodeTitle: string;
  public readonly favicon: Favicon;
  public readonly logo: React.FC<LogoProps>;
  public readonly links: OwnershipLinks;
  public readonly oAuth: OAuth;
  public readonly paymentRoles: PaymentRoles;
  public readonly isTendium: boolean;
  public readonly metaTitle: string;

  constructor() {
    this.name =
      Object.values(Ownerships).find(val => {
        return (OWNERSHIPS[val]?.domain ?? '').split(',').includes(window.location.hostname);
      }) ?? Ownerships.Tendium;

    this.metaTitle = this.name === Ownerships.Byggfakta ? 'Tender Public' : this.name;

    const ownership = OWNERSHIPS[this.name];

    this.qrCodeTitle = ownership.qrCodeTitle ?? this.name;
    this.logo = ownership.logo;
    this.favicon = ownership.favicon;
    this.links = ownership.links;
    this.oAuth = ownership.oAuth;
    this.paymentRoles = ownership.paymentRoles;
    this.isTendium = this.name === Ownerships.Tendium;
  }
}

export * from './types';
export const ownership = new Ownership();
