import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import dayjs from 'src/helpers/dayjs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, confirm } from 'src/common';
import { ReminderStatus } from '@tendium/prom-types/schema';
import Assignee from 'src/shared/Assignee';
import { useBidUrl } from 'src/models/procurements/hooks';
import classNames from 'classnames';
import { faCheck, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteReminder, useHandleReminder } from 'src/models/reminders/hooks';
import { Reminder } from 'src/models/reminders/types';
import modalStyles from 'src/common/Modal/index.module.scss';
import { trackNotificationListReminderClicked } from 'src/segment/events/reminders';

interface Props {
  reminder: Reminder;
  fromNotificationList?: boolean;
}

export const RemindersItem: FC<Props> = props => {
  const { reminder, fromNotificationList } = props;
  const { id, message, dueDate, relationId, assignedTo, status, title } = reminder;
  const { t } = useTranslation();

  const [deleteReminder] = useDeleteReminder();
  const [handleReminder] = useHandleReminder();

  const bidHref = useBidUrl(relationId || '');
  const date = useMemo(() => dayjs(dueDate).utc().toISOString(), [dueDate]);
  const currentDate = dayjs().startOf('day');
  const dueDateParsed = dayjs(dueDate).startOf('day');
  const daysDifference = dueDateParsed.diff(currentDate, 'day');

  const relativeDate = useMemo((): string => {
    switch (true) {
      case daysDifference < 0:
        return t('Reminders.overdue', { count: Math.abs(daysDifference) });
      case daysDifference === 0:
        return t('Reminders.dueToday');
      case daysDifference === 1:
        return t('Reminders.dueInOneDay');
      case daysDifference <= 7:
        return t('Reminders.dueInDays', { count: daysDifference });
      default:
        return t('Reminders.dueDate', { date: dueDateParsed.format('YYYY-MM-DD') });
    }
  }, [daysDifference, dueDateParsed, t]);

  const onDeleteReminder = useCallback(() => {
    confirm.confirm({
      title: t('Reminders.deleteConfirmationTitle'),
      content: t('Reminders.deleteConfirmationBody'),
      okText: t('Common.delete'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk() {
        deleteReminder(id, fromNotificationList);
      }
    });
  }, [t, deleteReminder, id, fromNotificationList]);

  const onHandle = useCallback(() => {
    handleReminder(id, fromNotificationList);
  }, [fromNotificationList, handleReminder, id]);

  const onReminderClicked = useCallback(() => {
    if (!fromNotificationList) return;
    trackNotificationListReminderClicked();
  }, [fromNotificationList]);

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.actions}>
          <Button
            type={'text'}
            className={classNames(styles.button, styles.buttonHandle)}
            icon={<FontAwesomeIcon icon={faCheck} onClick={onHandle} className={styles.icon} />}
            disabled={status === ReminderStatus.HANDLED}
          />
          <Button
            type={'text'}
            className={classNames(styles.button, styles.buttonDelete)}
            icon={<FontAwesomeIcon icon={faTrashCan} onClick={onDeleteReminder} className={styles.icon} />}
          />
          <Assignee assignedToId={assignedTo?.id} mode={'onlyAvatar'} size={'m'} />
        </div>
      </div>
      <Link to={bidHref} onClick={onReminderClicked}>
        <div className={styles.content}>
          <Tooltip title={message} mouseEnterDelay={0.6}>
            <span className={styles.desc}>{message}</span>
          </Tooltip>
          <div className={styles.meta}>
            {status === ReminderStatus.HANDLED ? (
              <span className={classNames(styles.date, styles.handled)}>{t('Reminders.handled')}</span>
            ) : (
              <span className={classNames(styles.date, { [styles.dateOverdue]: daysDifference < 0 })}>
                <time dateTime={date}>{relativeDate}</time>
              </span>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RemindersItem;
