import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuResolution,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { TextNode } from 'lexical';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFloating, FloatingPortal, offset, flip, shift } from '@floating-ui/react';
import { AssignedTo } from 'src/models/users/types';
import { $createMentionNode } from './MentionNode';
import { MentionsMenuOption } from './MentionsMenu/MentionMenuOption';
import { getPossibleQueryMatch } from './helpers';
import MentionsMenu from './MentionsMenu';
import { isTeam } from 'src/models/users/Team';
import styles from './index.module.scss';

interface Props {
  users: AssignedTo[];
}

export const MentionsPlugin: FC<Props> = ({ users }) => {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const options = useMemo(() => users.map(user => new MentionsMenuOption(user)), [users]);

  const results = useMemo(() => {
    return queryString
      ? options.filter(
          op => op.key.toLowerCase().includes(queryString) || (op.user.name ?? '').toLowerCase().includes(queryString)
        )
      : [...options];
  }, [options, queryString]);

  const onSelectOption = useCallback(
    (selectedOption: MentionsMenuOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const { id, type, name } = selectedOption.user;
        const $mentionNode = $createMentionNode({
          id,
          value: (isTeam(selectedOption.user) ? name : id) ?? id,
          text: selectedOption.key,
          type
        });
        if (nodeToReplace) {
          nodeToReplace.replace($mentionNode);
        }
        $mentionNode.insertMention();
        closeMenu();
      });
    },
    [editor]
  );

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  });
  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  const { x, y, refs, strategy, update } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(6), flip(), shift()]
  });

  const onOpen = useCallback(
    (r: MenuResolution) => {
      refs.setPositionReference({
        getBoundingClientRect: r.getRect
      });
    },
    [refs]
  );

  useEffect(() => {
    update();
  }, [results, update]);

  return (
    <LexicalTypeaheadMenuPlugin<MentionsMenuOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      onOpen={onOpen}
      triggerFn={checkForMentionMatch}
      options={results}
      anchorClassName={styles.mentionDropdownAnchor}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
        anchorElementRef.current && results.length ? (
          <FloatingPortal root={anchorElementRef}>
            <div
              className={styles.mentionDropdownContainer}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0
              }}
            >
              <ul className={styles.mentionDropdown}>
                <MentionsMenu
                  options={results}
                  selectedIndex={selectedIndex}
                  onClick={(i, option) => {
                    setHighlightedIndex(i);
                    selectOptionAndCleanUp(option);
                  }}
                  onMouseEnter={i => {
                    setHighlightedIndex(i);
                  }}
                />
              </ul>
            </div>
          </FloatingPortal>
        ) : null
      }
    />
  );
};

export default MentionsPlugin;
