import React, { FC, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import { useNavigationState } from 'src/helpers';

export const TenderRedirect: FC = () => {
  const currentUrl = useNavigationState();
  const redirect = useMemo(() => currentUrl.replace(Paths.PROCUREMENT_DETAILS_PAGE, Paths.TENDER_ROUTE), [currentUrl]);

  return <Navigate to={redirect} replace />;
};

export default TenderRedirect;
