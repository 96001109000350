import { gql } from '@apollo/client';
import { DOCUMENT_COORDINATES_FIELDS } from 'src/models/bids/BidTask/queries';

export const SEARCH_PROFILE = gql`
  fragment searchProfileFields on SearchProfile {
    id
    name
    terms
    color
    colorValue @client
  }
`;

export const GET_SEARCH_PROFILES = gql`
  query getSearchProfiles {
    getSearchProfiles {
      ...searchProfileFields
    }
  }
  ${SEARCH_PROFILE}
`;

export const GET_SEARCH_COLORS = gql`
  query getSearchProfiles {
    getSearchProfiles {
      id
      color
    }
  }
`;

export const DELETE_SEARCH_PROFILE = gql`
  mutation deleteSearchProfile($id: String!) {
    deleteSearchProfile(input: { id: $id })
  }
`;

export const RENAME_SEARCH_PROFILE = gql`
  mutation updateSearchProfile($id: String!, $name: String) {
    updateSearchProfile(input: { id: $id, name: $name }) {
      ...searchProfileFields
    }
  }
  ${SEARCH_PROFILE}
`;

export const CHANGE_PROFILE_COLOR = gql`
  mutation updateSearchProfile($id: String!, $color: SearchProfileColor) {
    updateSearchProfile(input: { id: $id, color: $color }) {
      ...searchProfileFields
    }
  }
  ${SEARCH_PROFILE}
`;

export const UPDATE_PROFILE_TERMS = gql`
  mutation updateSearchProfile($id: String!, $terms: [String!]) {
    updateSearchProfile(input: { id: $id, terms: $terms }) {
      ...searchProfileFields
    }
  }
  ${SEARCH_PROFILE}
`;

export const CREATE_SEARCH_PROFILE = gql`
  mutation createSearchProfile($name: String!, $color: SearchProfileColor!, $terms: [String!]!) {
    createSearchProfile(input: { name: $name, terms: $terms, color: $color }) {
      ...searchProfileFields
    }
  }
  ${SEARCH_PROFILE}
`;

export const BOX_DOCUMENT_COODINATES = gql`
  query boxCoordinates($boxId: String!, $procurementId: String!) {
    boxCoordinates(query: { boxId: $boxId, procurementId: $procurementId }) {
      classificationId
      contentPosition {
        ...documentCoordinatesFields
      }
      headlinePosition {
        ...documentCoordinatesFields
      }
    }
  }
  ${DOCUMENT_COORDINATES_FIELDS}
`;

export const TERMS_DOCUMENT_COODINATES = gql`
  query termsDocumentCoordinates($filePath: String!, $procurementId: String!, $terms: [String!]!) {
    termsDocumentCoordinates(query: { filePath: $filePath, procurementId: $procurementId, terms: $terms }) {
      classificationId
      contentPosition {
        ...documentCoordinatesFields
      }
      headlinePosition {
        ...documentCoordinatesFields
      }
    }
  }
  ${DOCUMENT_COORDINATES_FIELDS}
`;
