import React, { FC, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import { isString, useQueryParams } from 'src/helpers';
import { OverviewQp } from 'src/models/procurements/types';
import qs from 'qs';

export const OverviewRedirect: FC = () => {
  const qp = useQueryParams();
  const mpId = useMemo(() => (isString(qp['matchingProfileId']) ? qp['matchingProfileId'] : undefined), [qp]);
  const wsId = useMemo(() => (isString(qp['workspaceId']) ? qp['workspaceId'] : undefined), [qp]);
  const previewMode = useMemo(
    () => (isString(qp[OverviewQp.PreviewMode]) ? qp[OverviewQp.PreviewMode] : undefined),
    [qp]
  );
  const previewId = useMemo(() => (isString(qp[OverviewQp.PreviewId]) ? qp[OverviewQp.PreviewId] : undefined), [qp]);
  const pathname = useMemo(
    () => (mpId ? `${Paths.MONITORING}/${mpId}` : wsId ? `${Paths.BIDSPACES}/${wsId}` : Paths.MONITORING),
    [mpId, wsId]
  );
  const search = useMemo(
    () =>
      previewMode && previewId
        ? qs.stringify({
            [OverviewQp.PreviewId]: previewId,
            [OverviewQp.PreviewMode]: previewMode
          })
        : undefined,
    [previewId, previewMode]
  );

  return <Navigate to={{ pathname, search }} replace />;
};

export default OverviewRedirect;
