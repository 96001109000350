import { ReminderStatus, ReminderType } from '@tendium/prom-types/schema';

export class Reminder {
  public readonly id: string;
  public readonly message: string;
  public readonly createDate: Date;
  public readonly dueDate: Date;
  public readonly type: ReminderType;
  public readonly assignedTo: { id: string };
  public readonly createdBy: { email: string };
  public readonly status: ReminderStatus;
  public readonly relationId?: string;
  public readonly title?: string;

  constructor(apiReminder: Reminder) {
    this.id = apiReminder.id;
    this.message = apiReminder.message;
    this.createDate = apiReminder.createDate;
    this.dueDate = apiReminder.dueDate;
    this.type = apiReminder.type;
    this.assignedTo = apiReminder.assignedTo;
    this.createdBy = apiReminder.createdBy;
    this.status = apiReminder.status;
    this.relationId = apiReminder.relationId;
    this.title = apiReminder.title;
  }
}
