import { Dayjs } from 'dayjs';
import dayjs from './dayjs';
import i18next from 'i18next';
import { ContractStartRangesFilter } from 'src/models/bids/Bids/types';

export function isMonthWithShortName(date: Date): boolean {
  const monthsWithShortName = [4, 5, 6];
  const month = date.getUTCMonth();
  return monthsWithShortName.some(m => m === month);
}
/** PROM-830
To keep previous logic, don’t show time under the following situation:
- If time is not set for a date (so it translates to 00:00)  
- if users set 00:00 for a date
**/
export function showTimeInDeadline(date: Dayjs | Date | null): boolean {
  if (!date) {
    return false;
  }
  if (date instanceof Date) {
    date = dayjs(date);
  }
  if (!date.isUTC()) {
    date = date.utc();
  }
  return !!(date.hour() || date.minute() || date.second() || date.millisecond());
}

export type DateType = 'quarter' | 'year' | 'month' | 'date';
export type PeriodType = Exclude<DateType, 'date'>;
export type DayJsDateInput = string | number | Date | Dayjs | null | undefined;

export const FORMAT_DATE = 'YYYY-MM-DD'; // 2022-03-04
export const FORMAT_MONTH = 'YYYY-MM'; // 2022-03
export const FORMAT_MONTH_WITH_NAME = 'MMM YYYY'; // Mar 2022
export const FORMAT_MONTH_WITH_FULL_NAME = 'MMMM YYYY'; // March 2022
export const FORMAT_MONTH_FULL = 'MMMM'; // March
export const FORMAT_QUARTER = 'YYYY-Qn'; // 2022-Q1
export const FORMAT_YEAR = 'YYYY'; // 2022
export const FORMAT_RANGE_DATE = 'D MMM YYYY'; // 4 Mar 2022
export const FORMAT_RANGE_DATE_FULL = 'D MMMM YYYY'; // 4 March 2022

/**
 * Get consecutive dates from a duration based on a specified date type
 * e.g.: ['2020-Q1', '2020-Q4'], 'quarter' => ['2020-Q1', '2020-Q2', '2020-Q3', '2020-Q4']
 */
export function getDatesFromDuration(periodStrArr: [string, string], dateType: DateType): string[] {
  const startDate = toDayJs(periodStrArr[0]).startOf(dateType);
  const endDate = toDayJs(periodStrArr[1]).endOf(dateType);
  const dates = [];
  let currentDate = startDate;
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(formatDate(currentDate, dateType));
    currentDate = currentDate.add(1, dateType as dayjs.ManipulateType);
  }

  return dates;
}

/**
 * Get duration from a period
 */
export function getDurationFromPeriod(period: DayJsDateInput, periodType: PeriodType): [Dayjs, Dayjs] {
  const date = toDayJs(period);

  return [date.startOf(periodType), date.endOf(periodType)];
}

/**
 * Get DayJs object from a date
 * - Add quarter format 'YYYY-Qn' support to dayjs(...)
 */
export function toDayJs(date: DayJsDateInput): Dayjs {
  if (typeof date === 'string' && /-Q/.test(date)) {
    const [year, quarter] = date.split('-Q').map(Number);
    return dayjs.utc().year(year).quarter(quarter);
  }
  return dayjs.utc(date);
}

/**
 * Given a group of dates, get a period str with a specified format
 */
export function toDateRangeStr(dates: DayJsDateInput[], t: typeof i18next.t, dateFormat?: string): string {
  if (dates.length === 0) return '';

  const format = dateFormat ?? FORMAT_RANGE_DATE;

  const formatDate = (date: DayJsDateInput): string => {
    if (!date) return t('Common.dateMissing');
    return toDayJs(date).isValid() ? toDayJs(date).format(format) : t('Common.dateMissing');
  };

  if (dates.length === 1) return formatDate(dates[0]);

  return [formatDate(dates[0]), '-', formatDate(dates[dates.length - 1])].join(' ');
}

/**
 * Given a group of period strings and a date, find out a period string where the date falls in between
 */
export function inPeriod(date: DayJsDateInput, periods: string[]): string | undefined {
  const dateToCheck = toDayJs(date);
  return periods.find(d => {
    const dateType = getDateType(d);
    if (!dateType) return false;
    const [start, end] = getDurationFromPeriod(d, dateType as PeriodType);
    return dateToCheck.isBetween(start, end, 'date', '[]');
  });
}

/**
 * Format a date with default formats across the platform
 */
export function formatDate(
  date: DayJsDateInput,
  dateType?: DateType,
  options?: {
    showMonthName?: boolean;
    showFullName?: boolean;
    showOnlyMonthName?: boolean;
    showRangeDateFull?: boolean;
  }
): string {
  const type = dateType ?? 'date';
  const dayjsDate = toDayJs(date);
  switch (type) {
    case 'month':
      if (options?.showOnlyMonthName) return dayjsDate.format(FORMAT_MONTH_FULL);

      return dayjsDate.format(
        options?.showMonthName
          ? options?.showFullName
            ? FORMAT_MONTH_WITH_FULL_NAME
            : FORMAT_MONTH_WITH_NAME
          : FORMAT_MONTH
      );
    case 'year':
      if (options?.showRangeDateFull) return dayjsDate.format(FORMAT_RANGE_DATE_FULL);

      return dayjsDate.format(FORMAT_YEAR);
    case 'quarter':
      return `${dayjsDate.format('YYYY')}-Q${dayjsDate.quarter()}`; // FORMAT_QUARTER
    default:
      return dayjsDate.format(FORMAT_DATE);
  }
}

/**
 * Given a date string, get DateType of it
 */
export function getDateType(dateStr: string): DateType | undefined {
  // Check if the input is a year (e.g., '2022')
  if (/^\d{4}$/.test(dateStr)) {
    return 'year';
  }
  // Check if the input is a quarter (e.g., '2022-Q1')
  if (/^\d{4}-Q[1-4]$/.test(dateStr)) {
    return 'quarter';
  }
  // Check if the input is a month (e.g., '2022-03')
  if (/^\d{4}-\d{2}$/.test(dateStr)) {
    return 'month';
  }
  // Check if the input is a date (e.g., '2022-03-04')
  if (/^\d{4}-\d{2}-d{2}$/.test(dateStr)) {
    return 'date';
  }

  return;
}

/**
 * Check if two date ranges are overlapping
 */
export function checkRangesOverlapping(
  range1: [DayJsDateInput, DayJsDateInput],
  range2: [DayJsDateInput, DayJsDateInput]
): boolean {
  return toDayJs(range1[0]).isSameOrBefore(range2[1]) && toDayJs(range2[0]).isSameOrBefore(range1[1]);
}

export function formatDateWithTimezoneOffset(date: Date): string {
  const tzOffsetInMinutes = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - tzOffsetInMinutes * 60 * 1000);
  return adjustedDate.toISOString();
}

/**
 * Returns an object containing the start and end dates of a range of months.
 *
 * @param date - The starting date of the range.
 * @param length - The number of months in the range.
 * @returns An object with `start` and `end` properties, each formatted with timezone offset.
 */
export function getMonthRange(date: Date, length: number): ContractStartRangesFilter {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = new Date(date.getFullYear(), date.getMonth() + length, 1);
  return {
    start: formatDateWithTimezoneOffset(start),
    end: formatDateWithTimezoneOffset(end)
  };
}
