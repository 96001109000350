import React, { FC, useCallback } from 'react';
import { Menu } from 'src/common';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faInbox } from '@fortawesome/pro-light-svg-icons';
import previewStyles from 'src/shared/InfoSidebar/index.module.scss';
import { useNotificationsTabState } from 'src/models/notifications/hooks';
import { ActiveNotificationsTab } from 'src/models/notifications/types';
import styles from './index.module.scss';
import classNames from 'classnames';

export const NotificationsNav: FC = () => {
  const { t } = useTranslation();

  const [activeNotificationsTab, setActiveNotificationsTabState] = useNotificationsTabState();

  const onChangeTab = useCallback(
    ({ key }: { key: string }) => {
      setActiveNotificationsTabState(key as ActiveNotificationsTab);
    },
    [setActiveNotificationsTabState]
  );

  return (
    <div className={styles.container}>
      <Menu
        mode={'horizontal'}
        onClick={onChangeTab}
        selectedKeys={[activeNotificationsTab]}
        className={classNames(previewStyles.navTabs, styles.navTabs)}
      >
        <Menu.Item key={ActiveNotificationsTab.Notifications} className={previewStyles.navTab}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faInbox} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Notifications.notifications')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key={ActiveNotificationsTab.Reminders} className={previewStyles.navTab}>
          <span className={previewStyles.navTab}>
            <FontAwesomeIcon icon={faBell} className={previewStyles.navTabIcon} />
            <span className={previewStyles.navTabLabel}>{t('Notifications.reminders')}</span>
          </span>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default NotificationsNav;
