import { FieldPolicy } from '@apollo/client';
import { ApiBuyerSupplierTransactionHistoryData, BuyerSupplierTransactionHistoryCachedResult } from './types';

export function getBuyerSupplierTransactionHistoryPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['buyerId', 'sort', ['direction', 'key'], 'supplierFilter']],
    read(existing: BuyerSupplierTransactionHistoryCachedResult) {
      if (!existing) {
        return;
      }
      return existing.value;
    },
    merge(
      existing: BuyerSupplierTransactionHistoryCachedResult,
      incoming: ApiBuyerSupplierTransactionHistoryData,
      { variables }
    ) {
      let merged = existing?.value ? [...existing.value.suppliers] : [];

      if (incoming) {
        if (variables) {
          const { resultOffset } = variables;
          for (let i = 0; i < incoming.suppliers.length; ++i) {
            merged[resultOffset + i] = incoming.suppliers[i];
          }
        } else {
          merged = [...merged, ...incoming.suppliers];
        }
        // Fill in null for the rest of data that haven't been fetched yet
        for (let i = 0; i < incoming.totalCount; ++i) {
          merged[i] = merged[i] ?? null;
        }
      }

      return {
        value: {
          ...incoming,
          suppliers: merged
        },
        variables
      };
    }
  };
}
