import { ApolloCache, MutationUpdaterFn, NormalizedCacheObject, useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import {
  CREATE_CUSTOM_BID_FIELD,
  DELETE_CUSTOM_BID_FIELD,
  GET_ALL_CUSTOM_BID_FIELDS,
  UPDATE_CUSTOM_BID_FIELD
} from './queries';
import { ICustomBidField } from './types';
import { TenderBoxFieldType } from '@tendium/prom-types/tender';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { MutationTuple } from '@apollo/client/react/types/types';
import { isNotUndefined } from 'src/helpers';

interface IGetAllCustomBidFieldsResponse {
  getAllCustomBidFields: ICustomBidField[];
}

interface ICreateCustomBidFieldResponse {
  __typename: 'Mutation';
  createCustomBidField: ICustomBidField;
}

interface IUpdateCustomBidFieldResponse {
  updateCustomBidField: ICustomBidField;
  __typename: 'Mutation';
}

interface IDeleteCustomBidField {
  deleteCustomBidField: boolean;
  __typename: 'Mutation';
}

export function groupFieldsByType(fields: ICustomBidField[]): { [type: string]: ICustomBidField[] } {
  return fields.reduce((groups, item) => {
    groups[item.type] = groups[item.type] || [];
    groups[item.type].push(item);
    return groups;
  }, {} as { [type: string]: ICustomBidField[] });
}

export function useAllCustomBidFields(): { loading: boolean; data?: IGetAllCustomBidFieldsResponse } {
  const { data, loading } = useQuery<IGetAllCustomBidFieldsResponse>(GET_ALL_CUSTOM_BID_FIELDS);

  return useMemo(
    () => ({
      loading,
      data
    }),
    [data, loading]
  );
}

export function useCreateCustomBidField(): MutationTuple<
  ICreateCustomBidFieldResponse,
  { type: TenderBoxFieldType; name?: string }
> {
  return useMutation<ICreateCustomBidFieldResponse, { type: TenderBoxFieldType; name?: string }>(
    CREATE_CUSTOM_BID_FIELD
  );
}

export function useDeleteCustomBidField(): [
  (id: string) => void,
  { loading: boolean; error: Error | undefined; data: boolean }
] {
  const { t } = useTranslation();

  const [deleteCustomField, { loading, error, data }] = useMutation<IDeleteCustomBidField, { id: string }>(
    DELETE_CUSTOM_BID_FIELD
  );

  const deleteFn = useCallback(
    (id: string) => {
      deleteCustomField({
        variables: {
          id
        },
        update: getUpdateCacheOnDeleteCustomBidFields(id)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, deleteCustomField]
  );
  return [deleteFn, { loading, error, data: !!data?.deleteCustomBidField }];
}

export function useUpdateCustomBidField(): MutationTuple<IUpdateCustomBidFieldResponse, { name?: string; id: string }> {
  return useMutation<IUpdateCustomBidFieldResponse, { name?: string; id: string }>(UPDATE_CUSTOM_BID_FIELD);
}

export function getUpdateCacheOnDeleteCustomBidFields(
  id: string
): MutationUpdaterFn<{ deleteCustomBidField: boolean; __typename: 'Mutation' }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const customBidFieldsData = cache.readQuery<IGetAllCustomBidFieldsResponse>({
      query: GET_ALL_CUSTOM_BID_FIELDS
    });
    if (!customBidFieldsData) {
      return;
    }
    const { getAllCustomBidFields } = customBidFieldsData;
    const res = getAllCustomBidFields.filter(field => field.id !== id);
    cache.writeQuery({
      query: GET_ALL_CUSTOM_BID_FIELDS,
      data: { getAllCustomBidFields: res }
    });
    // FIXME: @check
    clearTendersCacheAfterUpdateCustomBidFields(cache as unknown as ApolloCache<NormalizedCacheObject>);
  };
}

export function getUpdateCacheOnCreateCustomBidFields(): MutationUpdaterFn<ICreateCustomBidFieldResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const customBidFieldsData = cache.readQuery<IGetAllCustomBidFieldsResponse>({
      query: GET_ALL_CUSTOM_BID_FIELDS
    });
    if (!customBidFieldsData) {
      return;
    }
    const { getAllCustomBidFields } = customBidFieldsData;
    cache.writeQuery({
      query: GET_ALL_CUSTOM_BID_FIELDS,
      data: { getAllCustomBidFields: [...getAllCustomBidFields, data.createCustomBidField] }
    });
    // FIXME: @check
    clearTendersCacheAfterUpdateCustomBidFields(cache as unknown as ApolloCache<NormalizedCacheObject>);
  };
}

export function clearTendersCacheAfterUpdateCustomBidFields(cache: ApolloCache<NormalizedCacheObject>): void {
  const data /*{ [key: string]: any }*/ = cache.extract();
  // TSerialized
  Object.values(data)
    .filter(isNotUndefined)
    .filter(item => item.__typename === 'Tender' || item.__typename === 'UserMail')
    .forEach(item => {
      if (item.__typename === 'Tender') {
        const ref = cache.identify({
          __typename: 'Tender',
          id: item.id
        });

        cache.evict({ id: ref });
        cache.gc();
      } else if (item.__typename === 'UserMail') {
        const ref = cache.identify({
          __typename: 'UserMail',
          mailId: item.mailId
        });

        cache.evict({ id: ref });
        cache.gc();
      }
    });
}
