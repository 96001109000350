import { gql } from '@apollo/client';

export const NOTIFICATION_FIELDS = gql`
  fragment notificationFields on NotificationEntity {
    body
    createdAt
    email
    title
    type
    isMarkDown
    isRead
    isSeen
    url
    html
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications($limit: Float!, $from: FromInputObject) {
    getNotifications(input: { limit: $limit, from: $from }) {
      newCounter
      from {
        email
        createdAt
      }
      notifications {
        ...notificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`;

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($email: String!, $createdAt: Float!) {
    markNotificationAsRead(input: { email: $email, createdAt: $createdAt }) {
      ...notificationFields
    }
  }
  ${NOTIFICATION_FIELDS}
`;

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation markAllNotificationsAsRead($email: String!) {
    markAllNotificationsAsRead(input: { email: $email })
  }
`;

export const MARK_NOTIFICATIONS_AS_SEEN = gql`
  mutation markAllNotificationsAsSeen($email: String!) {
    markAllNotificationsAsSeen(input: { email: $email })
  }
`;

export const ON_NEW_NOTIFICATIONS = gql`
  subscription onNewNotification {
    newNotification {
      ...notificationFields
    }
  }
  ${NOTIFICATION_FIELDS}
`;
