import { ILinkedBidsData, ILinkedBid, IApiLinkedBidData, IApiLinkedBid } from './types';
import { IApiBoxFieldString } from 'src/models/procurements/Tender/types';
import { BaseWorkspace } from 'src/models/workspace';

export class LinkedBidsData implements ILinkedBidsData {
  readonly bids: ILinkedBid[];
  readonly total: number;
  constructor(private readonly apiBidsResponse: IApiLinkedBidData) {
    this.bids = this.toBidsData();
    this.total = this.apiBidsResponse.total;
  }

  private toBidsData(): ILinkedBid[] {
    const apiBids = this.apiBidsResponse.bids;
    if (!apiBids) {
      return [];
    }
    return apiBids.map(apiBid => {
      return {
        id: apiBid.id,
        name: this.toBidName(apiBid),
        workspace: new BaseWorkspace(apiBid.workspace)
      };
    });
  }

  private toBidName(apiBid: IApiLinkedBid): string {
    const nameBox = apiBid.item.general.name;
    return (nameBox && !!nameBox.fields.length && (nameBox.fields[0] as IApiBoxFieldString).string) || '';
  }
}
