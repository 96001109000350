import React, { FC } from 'react';
import { Button } from 'src/common';
import { BlockEmpty } from '../../Blocks';
import { useTranslation } from 'react-i18next';
import { MultiBuyerBoxesProps } from '../type';

interface Props extends MultiBuyerBoxesProps {
  onToggleView: () => void;
}

export const AddValueButton: FC<Props> = ({ isEditable, onToggleView }) => {
  const { t } = useTranslation();

  return isEditable ? (
    <Button type={'link'} onClick={onToggleView}>
      {t('Tenders.addBuyer')}
    </Button>
  ) : (
    <BlockEmpty />
  );
};

export default AddValueButton;
