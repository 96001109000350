import { safeCallSegment } from '../helpers';

export function trackIdentify(): void {
  safeCallSegment(() => {
    analytics.track('User:identified', {});
  });
}

// RG: Press contact tendium
export function trackRGContact(): void {
  safeCallSegment(() => {
    analytics.track('RG contact');
  });
}
