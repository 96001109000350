import React, { FC, useCallback, JSX, MouseEvent } from 'react';
import styles from '../index.module.scss';
import { useUpdateQuickActionsCloseContext } from '../hooks';
import SingleLineSkeleton from 'src/common/betaComponents/SingleLineSkeleton';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface IProps {
  title: string;
  onClick?: () => void;
  loading?: boolean;
  icon?: IconDefinition;
  className?: string;
  readonly?: boolean;
  danger?: boolean;
  disabled?: boolean;
  CustomIcon?: (props: { className: string }) => JSX.Element | null;
}
const QuickActionsItem: FC<IProps> = props => {
  const { title, onClick, loading, icon: fontAwesomeIcon, className, readonly, danger, disabled, CustomIcon } = props;
  const onClose = useUpdateQuickActionsCloseContext();

  const onActionClick = useCallback(
    (e: MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();
      if (!readonly) {
        onClick?.();
        onClose();
      }
    },
    [onClick, onClose, readonly]
  );

  return (
    <li
      className={classNames(
        styles.actionsItem,
        {
          [styles.danger]: !!danger,
          [styles.readonly]: !!readonly,
          [styles.disabled]: !!disabled
        },
        !!className && className
      )}
      onClick={onActionClick}
    >
      {fontAwesomeIcon ? (
        <FontAwesomeIcon icon={fontAwesomeIcon} className={styles.actionItemIcon} />
      ) : CustomIcon ? (
        <CustomIcon className={styles.actionItemIcon} />
      ) : null}
      <span className={styles.actionsTitle}>{title}</span>
      {loading && <SingleLineSkeleton active={true} loading={true} />}
    </li>
  );
};

export default QuickActionsItem;
