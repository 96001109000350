import React, { FC } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useReminders } from 'src/models/reminders/hooks';
import { Spinner } from 'src/common';

export const RemindersCount: FC = () => {
  const { t } = useTranslation();
  const { remindersCount, loading } = useReminders();

  return !!loading ? (
    <Spinner />
  ) : (
    <div className={styles.remindersCount}>{`${remindersCount ?? 0} ${t('Reminders.reminders')}`}</div>
  );
};

export default RemindersCount;
