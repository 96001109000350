import React, { FC, Suspense } from 'react';
import styles from './index.module.scss';
import { Footer, FooterView, Skeleton } from 'src/common';
import classNames from 'classnames';
import { ownership, ThemeMode } from 'src/models/ownership';
import { Outlet } from 'react-router-dom';

export const AuthLayout: FC = () => (
  <div className={classNames(styles.layout, styles[ownership.name])}>
    <ownership.logo className={styles.logo} isShort />
    <div className={styles.content}>
      <Suspense fallback={<Skeleton active loading className={styles.skeleton} />}>
        <Outlet />
      </Suspense>
    </div>
    <Footer view={FooterView.full} themeMode={ThemeMode.dark} />
  </div>
);

export default AuthLayout;
