import { SerializedLexicalNode, Spread } from 'lexical';

/*** Mention data interface */
export interface MentionData {
  id: string;
  value: string | null;
  text: string | null;
  type: string | null;
}

export type SerializedMentionNode = Spread<
  {
    mention: MentionData;
  },
  SerializedLexicalNode
>;

/** MentionNode type name */
export const MENTION_NODE_TYPE_NAME = 'mention';
/** MentionNode version */
export const MENTION_NODE_VERSION = 1;
/** Mention trigger */
export const MENTION_TRIGGER = '@';

/** Mention styling class name  */
export const MENTION_CLASSNAME = 'mention';
/** Mention selected status styling class name  */
export const MENTION_SELECTED_CLASSNAME = 'selected';
/** Mention trigger styling class name  */
export const MENTION_TRIGGER_CLASSNAME = 'mention-trigger';

/** Minimal query string length to trigger search */
export const MENTION_MIN_MATCH_LENGTH = 0;
/** Query string length limit */
export const MENTION_LENGTH_LIMIT = 75;
/** Query string alias length limit */
export const MENTION_ALIAS_LENGTH_LIMIT = 50;
