import React, { FC, useCallback, useState } from 'react';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { useBackHref } from 'src/helpers/history';
import { Navigate, useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { GenerateInbox } from 'src/shared';
import styles from './index.module.scss';
import { Button } from 'src/common/Button';
import { Skeleton } from 'src/common/Skeleton';
import { Paths } from 'src/pages/paths';
import NavCard from 'src/common/NavCard';
import { PageHeading } from 'src/common';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useLoadInboxes } from 'src/models/callOffs/hooks';
import InboxEmail from './InboxEmail';
import { toInboxId } from 'src/models/callOffs/helpers';

const CallOffsNavPage: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const backHref = useBackHref();

  const isCallOffFeature = useFeatureFlag(FeatureFlag.Mercury);

  const { data: allInboxes, loading } = useLoadInboxes();
  const [createInboxVisible, setCreateInboxVisible] = useState(false);

  const inboxes = allInboxes?.filter(inbox => !inbox.isArchived);

  const onOpen = useCallback(() => {
    setCreateInboxVisible(true);
  }, []);
  const onClose = useCallback(() => {
    setCreateInboxVisible(false);
  }, []);

  const onGoToInbox = useCallback(
    (id: string) => {
      navigate({ pathname: `${Paths.CALL_OFFS}/${toInboxId(id)}` });
    },
    [navigate]
  );

  return isCallOffFeature ? (
    loading ? (
      <Skeleton active={loading} />
    ) : (
      <>
        <PageHeading title={t('Navigation.callOffs')} />
        <div className={styles.scrollContainer}>
          <div className={styles.container}>
            {!!inboxes?.length &&
              inboxes.map(inbox => (
                <NavCard
                  key={inbox.emailAddress}
                  title={inbox.inboxName}
                  id={inbox.emailAddress}
                  onClick={onGoToInbox}
                  cardInfo={<InboxEmail email={inbox.emailAddress} />}
                  icon={faCircle}
                  owners={inbox.owners}
                  subscribers={inbox.subscribers}
                  subscribersPolicy={inbox.accessControl.subscribePolicy}
                />
              ))}
            <Button className={styles.addNewCard} onClick={onOpen}>
              <FontAwesomeIcon icon={faPlus} />
              <span className={styles.addNew}> {t('CallOffs.addNewInboxTitle')}</span>
            </Button>
            {createInboxVisible && <GenerateInbox onClose={onClose} />}
          </div>
        </div>
      </>
    )
  ) : (
    <Navigate to={backHref} />
  );
};

export default CallOffsNavPage;
