import React, { FC, useCallback } from 'react';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { useUpdateBidStatus, useBidWorkspace, updateCacheOnUpdateBidStatus } from 'src/models/bids/Bids/hooks';
import styles from './index.module.scss';
import WorkspaceStatusBadge from '../StatusBadge';
import useBidsSearchArgs from 'src/reactiveVars/BidsSearchArgsVar';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { trackChangeBidStage } from 'src/segment/events';

interface Props {
  status: IWorkspaceStatus;
  bidId: string;
  eventSource: string;
  colorful?: boolean;
  loading?: boolean;
}

export const WorkspaceStatusItem: FC<Props> = ({ status, bidId, colorful, loading: externalLoading, eventSource }) => {
  const { t } = useTranslation();
  const [{ rejected: isRejectedTab }] = useBidsSearchArgs();

  const { workspace, status: prevStatus } = useBidWorkspace(bidId);
  const [moveToWorkspaceStatus, { loading: moveInProgress }] = useUpdateBidStatus();
  const loading = moveInProgress || externalLoading;
  const onChange = useCallback(() => {
    moveToWorkspaceStatus({
      variables: {
        bidId,
        statusId: status.id
      },
      update: updateCacheOnUpdateBidStatus({
        wsId: workspace?.id || '',
        bidId,
        rejectedView: !!isRejectedTab,
        currentStatusId: status.id,
        prevStatusId: prevStatus?.id
      })
    })
      .then(() => {
        if (workspace?.id) {
          trackChangeBidStage(
            {
              id: workspace?.id,
              name: workspace?.name
            },
            status.category,
            eventSource
          );
        }
      })
      .catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
  }, [bidId, eventSource, isRejectedTab, moveToWorkspaceStatus, prevStatus, status, t, workspace]);

  return (
    <li className={styles.status} onClick={onChange}>
      <WorkspaceStatusBadge status={status} colorful={!!colorful} loading={loading} />
    </li>
  );
};
export default WorkspaceStatusItem;
