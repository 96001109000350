import React, { FC, useMemo, useCallback } from 'react';
import { Tooltip } from 'src/common';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-light-svg-icons';
import { faComment as faCommentSolid } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateCurrentTenderBox } from 'src/models/procurements/Tender/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { ITenderBox } from 'src/models/procurements/Tender/types';
import { useBoxProps } from '../hooks';

interface IProps {
  box: ITenderBox;
  isActive?: boolean;
  className?: string;
}

export const BoxActions: FC<IProps> = ({ box, isActive: isHoveredOutside, className }) => {
  const { isActive: isHovered } = useBoxProps();
  const { id, comments } = box;
  const { t } = useTranslation();

  const updateCurrentBox = useUpdateCurrentTenderBox();
  const openSidebar = useOpenSidebar();

  const commentsCount = useMemo(() => (comments || []).length, [comments]);
  const isActive = useMemo(
    () => !!commentsCount || isHovered || isHoveredOutside,
    [commentsCount, isHovered, isHoveredOutside]
  );

  const onShowSidebar = useCallback(() => {
    openSidebar({ id, mode: SidebarMode.COMMENTS });
    updateCurrentBox?.(id);
  }, [id, openSidebar, updateCurrentBox]);

  return (
    <div className={classNames(styles.actions, className)}>
      <Tooltip title={t('Comments.addComment')}>
        <button
          className={classNames(styles.action, styles.actionComment, {
            [styles.isActive]: isActive,
            [styles.hasComments]: !!commentsCount
          })}
          onClick={onShowSidebar}
        >
          {!!commentsCount && <span className={styles.commentCount}>{commentsCount}</span>}
          <FontAwesomeIcon icon={!!commentsCount ? faCommentSolid : faComment} className={styles.icon} />
        </button>
      </Tooltip>
    </div>
  );
};

export default BoxActions;
