import { makeVar } from '@apollo/client';
import { useReactiveVariable } from 'src/helpers/reactiveVariables';
import { useCallback } from 'react';
import { StorageKey } from 'src/types/enums';

const MpSearchProfileIdVar = makeVar(localStorage.getItem(StorageKey.DefaultSearchProfileId) || undefined);

export function useMpSearchProfileId(): [string | undefined, (mpId?: string) => void] {
  const [mpSearchGroupId, setMpSearchGroupId] = useReactiveVariable<string | undefined>(MpSearchProfileIdVar);

  const setMpId = useCallback(
    (mpId?: string) => {
      setMpSearchGroupId(mpId);
      if (mpId) {
        localStorage.setItem(StorageKey.DefaultSearchProfileId, mpId);
      } else {
        localStorage.removeItem(StorageKey.DefaultSearchProfileId);
      }
    },
    [setMpSearchGroupId]
  );

  return [mpSearchGroupId, setMpId];
}

export default useMpSearchProfileId;
