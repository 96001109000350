import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import Accordion from 'src/common/Accordion';
import { useTranslation } from 'react-i18next';
import { TenderList } from './TenderList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { SupplierLink } from 'src/shared';
import { IdentifiableTenderObject, SupplierContractObject } from 'src/models/procurements/Tenders/types';

interface Props {
  supplierContractObject: SupplierContractObject;
  dynamicLoadedTenders?: IdentifiableTenderObject[];
  expandedId?: string;
  setExpandedId: React.Dispatch<React.SetStateAction<string | undefined>>;
  onExpend?: (data?: SupplierContractObject) => void;
  loading?: boolean;
}

export const SupplierListItem: FC<Props> = ({
  supplierContractObject,
  dynamicLoadedTenders,
  expandedId,
  setExpandedId,
  onExpend,
  loading
}) => {
  const { t } = useTranslation();

  const isExpended = useMemo(
    () => expandedId === supplierContractObject.supplier?.organisationNumber,
    [expandedId, supplierContractObject.supplier?.organisationNumber]
  );

  const tenders = useMemo(
    () => dynamicLoadedTenders ?? supplierContractObject.tenders,
    [supplierContractObject.tenders, dynamicLoadedTenders]
  );

  const contractCount = useMemo(
    () => supplierContractObject.contractCount ?? tenders.length,
    [tenders.length, supplierContractObject.contractCount]
  );

  const onToggleComplete = useCallback(() => {
    setExpandedId(prev => {
      const toExpend = prev !== supplierContractObject.supplier?.organisationNumber;
      if (toExpend) {
        onExpend?.({
          supplier: supplierContractObject.supplier,
          tenders,
          contractCount
        });
        return supplierContractObject.supplier?.organisationNumber;
      }
      return undefined;
    });
  }, [contractCount, supplierContractObject.supplier, onExpend, setExpandedId, tenders]);

  return (
    <div className={styles.item} key={supplierContractObject.supplier?.organisationNumber}>
      <Accordion
        label={
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <SupplierLink
                orgId={supplierContractObject.supplier?.organisationNumber}
                orgName={supplierContractObject.supplier?.organisationName ?? undefined}
              >
                {supplierContractObject.supplier?.organisationName}
              </SupplierLink>
            </div>
            <div className={styles.count}>
              <FontAwesomeIcon icon={faCircleSmall} size="xs" className={styles.icon} />
              {t('Tenders.Billing.contractCount', {
                count: contractCount
              })}
            </div>
          </div>
        }
        onToggleComplete={onToggleComplete}
        value={isExpended}
        className={styles.accordionThin}
      >
        <TenderList tenders={tenders} loading={loading} />
      </Accordion>
    </div>
  );
};

export default SupplierListItem;
