import { FieldPolicy } from '@apollo/client';
import { ContentLibraryCached, ContentLibraryCachedResult } from './types';

export function getContentLibraryPolicy(): FieldPolicy {
  return {
    keyArgs: ['query', ['filters', 'question', 'sort']],

    read(existing: ContentLibraryCachedResult) {
      if (!existing) {
        return;
      }
      if (!!existing) {
        return existing.value;
      }
    },
    merge(existing: ContentLibraryCachedResult, incoming: ContentLibraryCached, { variables }) {
      let merged = existing?.value ? [...existing.value.rows] : [];

      if (incoming) {
        if (variables) {
          const { offset } = variables;
          for (let i = 0; i < incoming.rows.length; ++i) {
            merged[offset + i] = incoming.rows[i];
          }
        } else {
          merged = [...merged, ...incoming.rows];
        }
      }

      return {
        value: {
          ...incoming,
          rows: merged
        },
        variables
      };
    }
  };
}
