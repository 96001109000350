import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { ITenderBox } from 'src/models/procurements/Tender/types';
import { Tooltip } from 'src/common';
import { BlockEmpty } from 'src/shared/Blocks';
import BuyerLink from 'src/shared/BuyerLink';
import { toBuyerName, toBuyerOrgId } from 'src/models/procurements/helpers';
import { MultiBuyerBoxesProps } from '../type';

interface Props extends MultiBuyerBoxesProps {
  buyerBox?: ITenderBox;
}
export const ReadOnlyBuyer: FC<Props> = ({ buyerBox, className, size = 'm', noTooltip, noLink }) => {
  const buyerName = useMemo(() => toBuyerName(buyerBox), [buyerBox]);
  const buyerOrgId = useMemo(() => toBuyerOrgId(buyerBox), [buyerBox]);

  return buyerName ? (
    <div className={classNames(styles.buyerInfo, { [styles.small]: size === 's' }, className)}>
      <Tooltip title={!noTooltip ? buyerName : undefined}>
        {noLink ? (
          <span className={styles.buyerName}>{buyerName}</span>
        ) : (
          <BuyerLink orgId={buyerOrgId} orgName={buyerName}>
            <span className={styles.buyerName}>{buyerName}</span>
          </BuyerLink>
        )}
      </Tooltip>
    </div>
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBuyer;
