import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { IBoxFieldCurrencyRange } from 'src/models/procurements/Tender/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatCurrencyValue } from 'src/models/procurements/Tender/helpers';
import { IBlockProps } from 'src/shared/Tender/Boxes/types';
import { useLanguage } from 'src/common';

interface IProps extends IBlockProps<IBoxFieldCurrencyRange> {}
export const BlockCurrencyRange: FC<IProps> = ({ field, className }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { from, to, currency } = field;

  const range = useMemo(() => {
    const fromFormatted = !!from ? formatCurrencyValue(from, language) : t('Common.notSet');
    const toFormatted = !!to ? formatCurrencyValue(to, language) : t('Common.notSet');

    return `${fromFormatted} - ${toFormatted} ${currency || ''}`;
  }, [from, t, to, currency, language]);

  return <span className={classNames(styles.block, !!className && className)}>{range}</span>;
};

export default BlockCurrencyRange;
