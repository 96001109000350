import { capitalizeFirstLetter } from 'src/helpers/capitalizeFirstLetter';
import { Assignment } from './AssignedTo/types';
import { Team } from './Team';
import isObject from 'src/helpers/isObject';
import isString from 'src/helpers/isString';

export interface IApiUser {
  email: string;
  name: string | null;
  lastName: string | null;
  color: string | null;
  enabled?: boolean;
}

export enum AssignmentType {
  User = 'User',
  Team = 'Team'
}

export type IUserName = IApiUser &
  Omit<Assignment, 'name'> & {
    userName: string;
    type: AssignmentType;
  };

export interface IGuestUser {
  email: string;
}

export const DELETED_USER: IUserName = {
  id: '',
  userName: '',
  name: '-',
  lastName: '',
  email: '',
  color: '#ccc',
  enabled: false,
  type: AssignmentType.User
};

export enum SsoProvider {
  google = 'Google',
  microsoft = '' // It is expected
}

export enum MfaMode {
  TOTP = 'TOTP'
}

export function toUserName(user: Pick<IUserName, 'email' | 'name' | 'lastName'>): string {
  const { email, name, lastName } = user;
  return !name && !lastName
    ? email
    : `${name ? capitalizeFirstLetter(name) : ''} ${lastName ? capitalizeFirstLetter(lastName) : ''}`;
}

export function isUser(u: unknown): u is IUserName {
  return isObject(u) && 'lastName' in u && isString(u.lastName);
}

export type AssignedTo = IUserName | Team;
