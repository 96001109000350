import { safeCallSegment } from '../helpers';
import { RemindersEventSource } from 'src/models/reminders/types';

export function trackAddReminder(eventSource: RemindersEventSource, assignedTo: string | null): void {
  safeCallSegment(() => {
    analytics.track('Reminders add reminder', {
      eventSource,
      assignedTo
    });
  });
}

export function trackHandledReminder(eventSource: RemindersEventSource): void {
  safeCallSegment(() => {
    analytics.track('Reminders done reminder', {
      eventSource
    });
  });
}

export function trackDeleteReminder(eventSource: RemindersEventSource): void {
  safeCallSegment(() => {
    analytics.track('Reminders delete reminder', {
      eventSource
    });
  });
}

export function trackNotificationListReminderClicked(): void {
  safeCallSegment(() => {
    analytics.track('Reminders notification list reminder clicked');
  });
}

export function trackNotificationReminderToggle(status: boolean): void {
  const statusText = status ? 'active' : 'inactive';
  safeCallSegment(() => {
    analytics.track('Reminders notification reminder toggle', {
      status: statusText
    });
  });
}
