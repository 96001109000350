import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import Button from '../Button';
import { MultiBuyerBoxes } from 'src/shared';
import { ITenderBox } from 'src/models/procurements/Tender/types';
import { useTranslation } from 'react-i18next';
import { useTenderUrl } from 'src/models/procurements/hooks';
import Tooltip from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-regular-svg-icons';
import { faDash } from '@fortawesome/pro-light-svg-icons';

interface Props {
  id?: string;
  title?: string;
  buyers?: ITenderBox[];
  suppliers?: ITenderBox[];
  contractStart?: Date;
  contractEnd?: Date;
}
export const ProcurementCard: FC<Props> = props => {
  const { id, title, buyers, suppliers, contractStart, contractEnd } = props;

  const { t } = useTranslation();
  const tenderUrl = useTenderUrl(id ?? '');

  const onClick = useCallback(() => {
    window.open(tenderUrl, '_blank');
  }, [tenderUrl]);

  return (
    <Button key={id} className={styles.button} onClick={onClick}>
      <div className={styles.procurementCard}>
        <Tooltip title={title}>
          <span className={styles.title}>{title}</span>
        </Tooltip>
        <div className={styles.orgInfo}>
          <MultiBuyerBoxes buyerBoxes={buyers} noLink />
        </div>
        <div className={styles.orgInfo}>
          <FontAwesomeIcon icon={faAward} className={styles.awardIcon} />
          <MultiBuyerBoxes buyerBoxes={suppliers} noLink />
        </div>
        <div className={styles.contractPeriod}>
          <div>
            {contractStart ? (
              <span>{t('Common.Format.tableDateFormatForShortMonths', { date: contractStart })}</span>
            ) : (
              <FontAwesomeIcon className={styles.dashIcon} icon={faDash} />
            )}
            <span className={styles.to}>{t('Common.to')}</span>
            {contractEnd ? (
              <span>{t('Common.Format.tableDateFormatForShortMonths', { date: contractEnd })}</span>
            ) : (
              <FontAwesomeIcon className={styles.dashIcon} icon={faDash} />
            )}
          </div>
        </div>
      </div>
    </Button>
  );
};

export default ProcurementCard;
