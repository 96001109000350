import { useCallback, useMemo } from 'react';
import {
  useQuery,
  MutationTuple,
  useMutation,
  MutationUpdaterFn,
  Reference,
  QueryResult,
  ApolloError,
  gql,
  ApolloCache,
  useLazyQuery,
  LazyQueryResultTuple
} from '@apollo/client';
import {
  IApiBidsData,
  IBidsSearchArgs,
  IBidsData,
  IBidIdentifier,
  ILinkedBidIdentifier,
  IApiShortBid,
  IBidsCached,
  IBidSorting,
  NewBidspaceRequest,
  BidspaceQueryParams,
  BidsColumn,
  SortOrder,
  ApiGetTotalBidsResponse,
  ApiGetTotalBidsRequest,
  BidspaceStatisticsData,
  AssignedToStatisticsData,
  AssignedToStatisticsResponse,
  BidsCategoryStatistics,
  BidsCategoryStatisticsResponse,
  ContractStartRangesFilter
} from './types';
import {
  CREATE_BIDS,
  UPDATE_BIDS_WS,
  UPDATE_BID_STATUS,
  GET_BIDS,
  GET_BID,
  GET_TOTAL_BIDS,
  GET_ASSIGNED_TO_STATISTICS,
  GET_BIDS_PAGES,
  GET_BIDS_CATEGORY_STATISTICS,
  GET_MONTHLY_BIDS_PAGES
} from './queries';
import { AllAssignedToBidspacesStatistics, AllBidspaceStatistics, BidsCategoryStatistic, BidsData } from '.';
import { useWorkspaces, clearWsStatsCache } from 'src/models/workspace/hooks';
import { IWorkspace } from 'src/models/workspace/types';
import { IWorkspaceStatus } from 'src/models/workspace/WorkspaceStatus/types';
import { BidItemType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { evictBidStatisticsCache, isBidItemTypes, isWorkspaceStatusCategories } from './helpers';
import useBidsSearchArgs from 'src/reactiveVars/BidsSearchArgsVar';
import { ITendersCached } from 'src/models/procurements/types';
import { useUnAssignMailsToBid } from '../BidsSelector/hooks';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import { trackMoveItemsToWorkspace } from 'src/segment/events';
import { notification } from 'src/common';
import { notUndefined } from 'src/helpers/typescript';
import { EmailStatus } from 'src/models/callOffs/types';
import { useSetRequestSettings } from 'src/models/users/RequestSettings/hooks';
import { RequestType } from 'src/models/users/RequestSettings/types';
import { WebhookStatus } from 'src/models/company/Webhooks/types';
import { useParams, useLocation } from 'react-router';
import { FeatureFlag, isString, isStrings, updateQueryParams, useFeatureFlag, useQueryParams } from 'src/helpers';
import { BidTextSearch, BidTextSearchField, GetBidsFilters, Interactions } from '@tendium/prom-types/schema';
import { Paths } from 'src/pages/paths';
import { BidCacheUpdateValues } from '../BidPreview/types';
import { BID_CACHE_UPDATE_FIELDS } from '../BidPreview/queries';
import { ApiTendersSearchInput, TendersSearchParams } from 'src/models/procurements/Tenders/types';
import { formatDateWithTimezoneOffset } from 'src/helpers/dates';
import dayjs from 'src/helpers/dayjs';

export interface IApiGetBidsResponse {
  getBids: IApiBidsData;
}

export interface ISearchBidsRequest extends IBidsSearchArgs, GetBidsFilters {
  wsId?: string;
  stageIds?: string[];
  isWebHooksFeature?: boolean;
  contractStart?: ContractStartRangesFilter;
  currency?: string;
}

export interface BidsResponseData extends Omit<QueryResult<IApiGetBidsResponse, ISearchBidsRequest>, 'data'> {
  data?: IBidsData;
  fetchingMore: boolean;
}

export interface BidsVars {
  updateVars: (vars: Partial<BidspaceQpVars>) => void;
  currentVars: BidspaceQpVars;
  clearVars: () => void;
}

function transformQpVars(vars: BidspaceQpVars): GetBidsFilters {
  return {
    statusCategories: isWorkspaceStatusCategories(vars.category) ? vars.category : undefined,
    bidItemTypes: isBidItemTypes(vars.bidType) ? vars.bidType : undefined,
    workspaceIds: isStrings(vars.bidspaceId) ? vars.bidspaceId : undefined
  };
}

function transformAssignedToQpVars(vars: BidspaceQpVars): string[] | undefined {
  return isStrings(vars.assignedTo) ? vars.assignedTo : undefined;
}

function transformSearchQpVars(search: string | undefined): BidTextSearch | undefined {
  return { fields: [BidTextSearchField.Title, BidTextSearchField.Buyer], text: search ?? '' };
}

export type BidFilter = {
  name: string | null;
  category: WorkspaceStatusCategory;
  bidspaceId: string;
  assignedTo?: string;
  type: BidItemType;
};

export function transformBidValuesToBidFilter(bidValues: BidCacheUpdateValues): BidFilter {
  return {
    name: bidValues.item.general.name.fields[0].string ?? null,
    category: bidValues.status.category,
    bidspaceId: bidValues.workspace.id,
    assignedTo: bidValues.assignedTo?.id,
    type: bidValues.itemType
  };
}

export function isBidspaceFiltersApplied(filter?: BidspaceQpVars): boolean {
  return (
    !!filter?.assignedTo?.length ||
    !!filter?.bidType?.length ||
    !!filter?.bidspaceId?.length ||
    !!filter?.category?.length ||
    !!filter?.search?.length
  );
}

const filterKeys = ['statusCategories', 'bidItemTypes', 'assignedToIds', 'workspaceIds', 'textSearch'];

export function checkCacheIsAffectedByBid(storeFieldName: string, bidFieldsInFilter: BidFilter): boolean {
  const values = Object.values(bidFieldsInFilter);

  if (!filterKeys.some(key => storeFieldName.includes(key))) return true;
  for (const value of values) {
    if (value && storeFieldName.includes(value)) return true;
  }
  return false;
}

export function getCurrentBidFromCache(bidId: string, cache: ApolloCache<unknown>): BidCacheUpdateValues | null {
  const bidRef = cache.identify({
    __typename: 'BidV2',
    id: bidId
  });

  return cache.readFragment<BidCacheUpdateValues>({
    id: bidRef,
    fragment: BID_CACHE_UPDATE_FIELDS
  });
}

export type Operation = 'add' | 'sub';

export function useBidsFilterVars(): BidsVars {
  const vars = useBidspacesQpVars();
  const updateVars = useCallback((vars: Partial<BidspaceQpVars>) => {
    updateQueryParams(vars);
  }, []);

  const clearVars = useCallback(() => {
    updateQueryParams({
      search: undefined,
      category: undefined,
      bidspaceId: undefined,
      bidType: undefined,
      assignedTo: undefined
    });
  }, []);

  return useMemo(
    () => ({
      updateVars,
      currentVars: vars,
      clearVars
    }),
    [clearVars, updateVars, vars]
  );
}

export function useStagesBids(params?: {
  amount?: number;
  stageId?: string;
  assignedToId?: string;
  sort?: IBidSorting;
}): BidsResponseData {
  const { wsId } = useParams<{ wsId: string }>();
  const stageId = params?.stageId;
  const amount = params?.amount;
  const assignedToId = useMemo(() => params?.assignedToId, [params]);
  const [{ amount: defaultAmount, ...restSearchArgs }] = useBidsSearchArgs();
  const vars = useBidspacesQpVars();
  const { pathname: currentUrl } = useLocation();
  const isBidspaces = useMemo(() => currentUrl === Paths.BIDSPACES, [currentUrl]);

  const workspaceIds = useMemo(() => {
    return !isBidspaces && wsId ? [...(vars.bidspaceId ?? []), wsId] : [...(vars.bidspaceId ?? [])];
  }, [isBidspaces, vars.bidspaceId, wsId]);

  const sortOnField = params?.sort ?? restSearchArgs.sortOnField;
  const isWebHooksFeature = !!useFeatureFlag(FeatureFlag.Users_Webhooks);

  const query = useQuery<IApiGetBidsResponse, ISearchBidsRequest>(GET_BIDS, {
    variables: {
      stageIds: stageId ? [stageId] : undefined,
      assignedToIds: assignedToId ? [assignedToId] : transformAssignedToQpVars(vars),
      amount: amount || defaultAmount,
      ...(isBidspaces ? transformQpVars(vars) : undefined),
      textSearch: transformSearchQpVars(vars.search),
      workspaceIds: workspaceIds,
      ...restSearchArgs,
      offset: 0,
      sortOnField,
      isWebHooksFeature
    }
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? new BidsData(query.data.getBids) : undefined,
      loading:
        (query.loading || query === undefined || query.networkStatus === 4 || query.networkStatus === 2) &&
        query.networkStatus !== 3,
      fetchingMore: query.networkStatus === 3
    }),
    [query]
  );
}

export function useDeadlineComingUpBids(params?: {
  amount?: number;
}): Omit<BidsResponseData, 'updateVars' | 'currentVars' | 'clearVars'> {
  const amount = params?.amount;
  const [{ amount: defaultAmount, ...restSearchArgs }] = useBidsSearchArgs();
  const vars = useBidspacesQpVars();
  const isWebHooksFeature = !!useFeatureFlag(FeatureFlag.Users_Webhooks);

  const query = useQuery<IApiGetBidsResponse, ISearchBidsRequest>(GET_BIDS_PAGES, {
    variables: {
      amount: amount ?? defaultAmount,
      ...transformQpVars(vars),
      textSearch: transformSearchQpVars(vars.search),
      assignedToIds: transformAssignedToQpVars(vars),
      ...restSearchArgs,
      sortOnField: {
        name: BidsColumn.deadline,
        order: SortOrder.ASC
      },
      isWebHooksFeature
    }
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? new BidsData(query.data.getBids) : undefined,
      loading:
        (query.loading || query === undefined || query.networkStatus === 4 || query.networkStatus === 2) &&
        query.networkStatus !== 3,
      fetchingMore: query.networkStatus === 3
    }),
    [query]
  );
}

export function useTotalStagesBids(): LazyQueryResultTuple<ApiGetTotalBidsResponse, ApiGetTotalBidsRequest> {
  return useLazyQuery<ApiGetTotalBidsResponse, ApiGetTotalBidsRequest>(GET_TOTAL_BIDS, { fetchPolicy: 'no-cache' });
}

export interface IGetBidRequest {
  bidId: string;
}
export interface IGetBidResponse {
  getBid: IApiShortBid;
}

export function useBidWorkspace(bidId?: string): {
  workspace: IWorkspace | null;
  status: IWorkspaceStatus | null;
  didFail: boolean;
  inNoAccessWorkspace: boolean;
  webhookStatus?: WebhookStatus;
} {
  const { data: wssData } = useWorkspaces();
  const { data: bidData } = useQuery<IGetBidResponse, IGetBidRequest>(GET_BID, {
    variables: { bidId: bidId || '' },
    skip: !bidId,
    fetchPolicy: 'cache-first'
  });
  const bidWsId = bidData?.getBid?.workspace.id;
  const statusWsId = bidData?.getBid?.status.id;

  const workspace = useMemo(
    () => (!!bidWsId && wssData && wssData.find(ws => ws.id === bidWsId)) || null,
    [bidWsId, wssData]
  );

  const inNoAccessWorkspace = useMemo(
    () => (!!bidWsId && wssData && !wssData.find(ws => ws.id === bidWsId)) || false,
    [bidWsId, wssData]
  );

  const status = useMemo(
    () => (workspace && workspace.statuses.find(wsStatus => wsStatus.id === statusWsId)) || null,
    [statusWsId, workspace]
  );

  const didFail = useMemo(() => bidData?.getBid === null, [bidData]);

  const webhookStatus = useMemo(() => bidData?.getBid?.webhookStatus, [bidData]);

  return useMemo(() => {
    return { workspace, status, didFail, inNoAccessWorkspace, webhookStatus };
  }, [didFail, inNoAccessWorkspace, status, webhookStatus, workspace]);
}

interface ICreateBidsResponse {
  __typename: 'Mutation';
  createBids: IApiShortBid[] & {
    __typename: 'BidV2';
  };
}

export interface Buyer {
  orgName?: string;
  orgId?: string;
}

interface CreateBidItemInput {
  id?: string;
  name?: string;
  buyer?: Buyer;
}
interface ICreateBidsRequest {
  workspaceId: string;
  itemType: BidItemType;
  items: CreateBidItemInput[];
  meta?: Interactions.BidCreationContext;
}
export function useCreateBids(): MutationTuple<ICreateBidsResponse, ICreateBidsRequest> {
  return useMutation(CREATE_BIDS);
}
export function getUpdateCacheOnCreateBids(
  originIds: string[],
  originType: BidItemType,
  wsId: string,
  mpId?: string
): MutationUpdaterFn<ICreateBidsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    let originIdsData = originIds;
    const { createBids: newBids } = data;
    if (originType === BidItemType.Manual) {
      originIdsData = newBids.map(bid => bid.itemId);
    }
    let origins: string[] = [];
    const modifyTenderCache = (originId: string, typename: string): void => {
      const newBid = newBids.find(bid => bid.itemId === originId);
      origins = newBid?.itemId ? Array.from(new Set([...origins, newBid?.itemId])) : origins;
      cache.modify({
        id: cache.identify({ __typename: typename, id: originId }),
        fields: {
          bids(existingRefs: Reference[], { toReference, readField }) {
            return !newBid || existingRefs.some(ref => readField('id', ref) === newBid.id)
              ? existingRefs
              : [...existingRefs, toReference({ __typename: 'BidV2', id: newBid.id })];
          }
        }
      });
    };
    originIdsData.forEach(originId => {
      if (originType === BidItemType.Procurement) {
        modifyTenderCache(originId, 'Tender');
        modifyTenderCache(originId, 'OCDSTender');
      }
      if (originType === BidItemType.CallOff) {
        const callOffRef = cache.identify({
          __typename: 'UserMail',
          mailId: originId
        });
        cache.modify({
          id: callOffRef,
          fields: {
            bidsV2(existingRefs: Reference[], { toReference, readField }) {
              const newBid = data.createBids.find(bid => bid.itemId === originId);
              return !newBid || existingRefs.some(ref => readField('id', ref) === newBid.id)
                ? existingRefs
                : [
                    ...existingRefs,
                    toReference({
                      __typename: 'BidV2',
                      id: newBid.id
                    })
                  ];
            },
            mailStatus() {
              return EmailStatus.INTERESTING;
            }
          }
        });
      }
    });

    // TODO: ideally we should filter bids references also by origin contained some search match
    // storeFieldName.includes(`search`)

    cache.modify({
      fields: {
        getBids(cached: IBidsCached, { storeFieldName, fieldName, readField, toReference }) {
          if (!storeFieldName.includes(wsId) && storeFieldName.includes('workspaceIds')) return cached;
          if (
            (newBids[0].status.id && storeFieldName.includes(newBids[0].status.id)) ||
            !storeFieldName.includes('stageIds')
          ) {
            const queryInput: { input?: ISearchBidsRequest } = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));

            if (!queryInput.input || !notUndefined(queryInput.input.rejected)) return cached;

            const isGetRejectedBidsQuery = queryInput.input.rejected;

            if (isGetRejectedBidsQuery) {
              return {
                ...cached,
                totalBids: sumTotalBidsValue(cached.totalBids, originIds.length)
              };
            } else {
              const newBidsRefs = newBids.map(newBid =>
                toReference({
                  __typename: 'BidV2',
                  id: newBid.id
                })
              );
              const bidsToAdd =
                cached.bids && [...cached.bids].length
                  ? newBidsRefs.filter(
                      newBidsRef =>
                        newBidsRef &&
                        ![...cached.bids].some(
                          bid =>
                            readField({ fieldName: 'id', from: bid }) ===
                            readField({ fieldName: 'id', from: newBidsRef })
                        )
                    )
                  : newBidsRefs;
              return {
                ...cached,
                total: cached.total + bidsToAdd.length,
                totalBids: sumTotalBidsValue(cached.totalBids, bidsToAdd.length),
                bids: [...cached.bids, ...bidsToAdd]
              };
            }
          } else {
            return cached;
          }
        }
      }
    });

    if (origins.length && mpId) {
      cache.modify({
        fields: {
          getSimpleTenders(cached: ITendersCached, { storeFieldName, fieldName, readField }) {
            const input: ApiTendersSearchInput = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));
            if (!input.query) return cached;

            if (input.query.matchingProfileId !== mpId || !input.query.isUnhandled) return cached;

            return {
              ...cached,
              count: cached.count - origins.length,
              procurementsWithScore: [...cached.procurementsWithScore].filter(
                proc =>
                  proc && !origins.some(originId => originId === readField({ fieldName: 'id', from: proc.procurement }))
              )
            };
          }
        }
      });

      cache.modify({
        fields: {
          getTenders(cached: ITendersCached, { storeFieldName, fieldName, readField }) {
            const input: ApiTendersSearchInput = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));
            if (!input.query) return cached;

            if (input.query.matchingProfileId !== mpId || !input.query.isUnhandled) return cached;

            return {
              ...cached,
              count: cached.count - origins.length,
              procurementsWithScore: [...cached.procurementsWithScore].filter(
                proc =>
                  proc && !origins.some(originId => originId === readField({ fieldName: 'id', from: proc.procurement }))
              )
            };
          }
        }
      });
    }

    clearWsStatsCache(cache, wsId);
  };
}
interface IUpdateBidsWsResponse {
  __typename: 'Mutation';
  updateBidsWorkspace: IApiShortBid[] & {
    __typename: 'BidV2';
  };
}
interface IUpdateBidsWsRequest {
  workspaceId: string;
  bidsIds: string[];
}
export function useUpdateBidsWs(): MutationTuple<IUpdateBidsWsResponse, IUpdateBidsWsRequest> {
  return useMutation(UPDATE_BIDS_WS);
}

export function getUpdateCacheOnUpdateBidsWs(
  wsId: string,
  bidIds: IBidIdentifier[],
  rejectedView: boolean
): MutationUpdaterFn<IUpdateBidsWsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    const { updateBidsWorkspace: updatedBids } = data;
    if (!updatedBids) {
      return;
    }

    const notInWsBidIds = bidIds.filter(bidIdObj => bidIdObj.wsId !== wsId);

    if (!notInWsBidIds.length) {
      return;
    }

    bidIds.forEach(bid => {
      const currentBid = getCurrentBidFromCache(bid.id, cache);

      cache.modify({
        fields: {
          getBids(cached: IBidsCached, { storeFieldName, toReference, fieldName, readField }) {
            const isWorkspaceFilterApplied = storeFieldName.includes('workspaceIds');
            const prevWsId = bid.wsId;
            if (!isWorkspaceFilterApplied) return cached;
            if (!currentBid) return cached;

            const queryInput: { input?: ISearchBidsRequest } = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));
            if (!queryInput.input || !notUndefined(queryInput.input.rejected)) return cached;
            if (
              prevWsId &&
              storeFieldName.includes(prevWsId) &&
              currentBid.workspace.id &&
              storeFieldName.includes(currentBid.workspace.id)
            )
              return cached;

            if (currentBid.workspace.id && storeFieldName.includes(currentBid.workspace.id)) {
              if (queryInput.input.rejected !== rejectedView) {
                return {
                  ...cached,
                  totalBids: sumTotalBidsValue(cached.totalBids, 1)
                };
              }

              if (
                (storeFieldName.includes('stageIds') && storeFieldName.includes(currentBid.bidStageId)) ||
                !storeFieldName.includes('stageIds')
              ) {
                const isNotInCache = (bidIdentifier: IBidIdentifier): boolean =>
                  !cached.bids.map(bidRef => readField('id', bidRef)).includes(bidIdentifier.id);

                return {
                  ...cached,
                  total: cached.total + 1,
                  totalBids: sumTotalBidsValue(cached.totalBids, 1),

                  bids: [
                    ...cached.bids,
                    ...notInWsBidIds.filter(isNotInCache).map(bid => {
                      return toReference({
                        __typename: 'BidV2',
                        id: bid.id
                      });
                    })
                  ]
                };
              }
            }

            if (prevWsId && storeFieldName.includes(prevWsId)) {
              if (queryInput.input.rejected !== rejectedView) {
                return {
                  ...cached,
                  totalBids: subsTotalBidsValue(cached.totalBids, 1)
                };
              }
              return {
                ...cached,
                total: cached.total - (cached.bids.length - 1),
                totalBids: subsTotalBidsValue(cached.totalBids, 1),
                bids: [...cached.bids].filter(b => readField('id', b) !== bid.id)
              };
            }
            return cached;
          }
        }
      });
    });

    clearWsStatsCache(cache, wsId);
  };
}

interface IUpdateBidStatusResponse {
  __typename: 'Mutation';
  updateBidStatusV2: IApiShortBid & {
    __typename: 'BidV2';
  };
}

interface IUpdateBidsStatusRequest {
  bidId: string;
  statusId: string;
}
export function useUpdateBidStatus(): MutationTuple<IUpdateBidStatusResponse, IUpdateBidsStatusRequest> {
  return useMutation(UPDATE_BID_STATUS);
}
export function updateCacheOnUpdateBidStatus(input: {
  wsId: string;
  bidId: string;
  rejectedView: boolean;
  currentStatusId: string;
  prevStatusId?: string;
}): MutationUpdaterFn<IUpdateBidStatusResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    const { wsId, bidId, rejectedView, prevStatusId, currentStatusId } = input;
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    const prevWorkspaceStatus = cache.identify({ id: prevStatusId, __typename: 'WorkspaceStatus' });

    const prevStatusCategory = cache.readFragment<{ category: WorkspaceStatusCategory }>({
      id: prevWorkspaceStatus,
      fragment: gql`
        fragment fields on WorkspaceStatus {
          category
        }
      `
    });

    const currentBid = getCurrentBidFromCache(bidId, cache);

    if ((currentStatusId || prevStatusCategory?.category) && bidRef) {
      cache.modify({
        fields: {
          getBids(cached: IBidsCached, { storeFieldName, toReference, readField, fieldName }) {
            const queryInput: { input?: ISearchBidsRequest } = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));

            if (!queryInput.input || !notUndefined(queryInput.input.rejected)) return cached;

            const queryOnCurrentView = queryInput.input.rejected === rejectedView;

            if (
              storeFieldName.includes(currentStatusId) ||
              (currentBid && storeFieldName.includes(currentBid.status.category))
            ) {
              if (queryOnCurrentView) {
                return {
                  ...cached,
                  total: cached.total + 1,
                  totalBids: sumTotalBidsValue(cached.totalBids, 1),
                  bids: [...cached.bids, toReference(bidRef)]
                };
              } else {
                return { ...cached, totalBids: sumTotalBidsValue(cached.totalBids, 1) };
              }
            } else if (
              (prevStatusId && storeFieldName.includes(prevStatusId)) ||
              (prevStatusCategory && storeFieldName.includes(prevStatusCategory.category))
            ) {
              const newBids = [...cached.bids].filter(
                bid => bid && bidId !== readField({ fieldName: 'id', from: bid })
              );

              if (queryOnCurrentView) {
                return {
                  ...cached,
                  total: cached.total - 1,
                  totalBids: subsTotalBidsValue(cached.totalBids, 1),
                  bids: newBids
                };
              } else {
                return {
                  ...cached,
                  totalBids: subsTotalBidsValue(cached.totalBids, 1)
                };
              }
            } else {
              return cached;
            }
          }
        }
      });
    }

    clearWsStatsCache(cache, wsId);
  };
}

interface IMoveToWorkspaceInput {
  workspaceId: string;
  eventSource: string;
  eventType: string | undefined;
  originType?: BidItemType;
  originIds?: string[];
  bids?: IBidIdentifier[];
  linkedBids?: ILinkedBidIdentifier[];
  buyer?: Buyer;
  relevanceScores?: (number | null | undefined)[];
}

export const useBidCreationContext = (): Interactions.BidCreationContext => {
  const { wsId, mpId, inboxId } = useParams<{ wsId: string; mpId: string; inboxId: string }>();
  const { pathname } = useLocation();
  const qp = useQueryParams();

  const profileId = useMemo(() => {
    return isString(qp[TendersSearchParams.profileId]) ? qp[TendersSearchParams.profileId] : undefined;
  }, [qp]);

  return useMemo(() => {
    switch (true) {
      case pathname.includes(Paths.MONITORING) && !!mpId:
        return { bidContext: Interactions.BidContext.MatchingProfile, bidContextId: mpId };

      case pathname.includes(Paths.ALL_TENDERS):
        return { bidContext: Interactions.BidContext.AllTenders, bidContextId: undefined };

      case pathname.includes(Paths.CALL_OFFS) && !!inboxId:
        return { bidContext: Interactions.BidContext.CallOff, bidContextId: inboxId };

      case pathname.includes(Paths.EXPIRING_CONTRACTS_ROUTE) && !!profileId:
        return { bidContext: Interactions.BidContext.ExpiringContracts_SavedSearch, bidContextId: profileId };

      case pathname.includes(Paths.EXPIRING_CONTRACTS_ROUTE) && !profileId:
        return { bidContext: Interactions.BidContext.ExpiringContracts, bidContextId: undefined };

      case pathname.includes(Paths.BIDSPACES) && !!wsId:
        return { bidContext: Interactions.BidContext.Manual, bidContextId: wsId };

      default:
        return { bidContext: undefined, bidContextId: undefined };
    }
  }, [inboxId, mpId, pathname, profileId, wsId]);
};

export function useMoveToWorkspace(): [(data: IMoveToWorkspaceInput) => Promise<void>, { loading: boolean }] {
  const [createBids, { loading: createBidLoading }] = useCreateBids();
  const [updateBidsWs, { loading: updateBidsWsLoading }] = useUpdateBidsWs();
  const [unassignMailsToBid, { loading: unassignLoading }] = useUnAssignMailsToBid();
  const { bidContext, bidContextId } = useBidCreationContext();
  const [{ rejected: isRejectedTab }] = useBidsSearchArgs();
  const { mpId: activeMpId } = useParams<{ mpId: string }>();
  const loading = createBidLoading || updateBidsWsLoading || unassignLoading;
  const { t } = useTranslation();

  const moveToWorkspaceFn = useCallback(
    async (data: IMoveToWorkspaceInput) => {
      const { workspaceId, eventSource, eventType, originType, originIds, bids, linkedBids, buyer, relevanceScores } =
        data;

      try {
        if (!!originType && originType === BidItemType.CallOff && linkedBids) {
          const groups = groupBy(linkedBids, 'id');
          const promises = Object.entries(groups).map(([key, val]) => {
            return unassignMailsToBid({
              variables: { bidId: key, mailIds: val.map(bid => bid.originId) }
            });
          });
          await Promise.all(promises);
        }
        if (!!originType && !!originIds && !!originIds.length) {
          await createBids({
            variables: {
              workspaceId: workspaceId,
              items: originIds.map(id => ({ id, buyer })),
              itemType: originType,
              meta: { bidContext, bidContextId }
            },
            update: getUpdateCacheOnCreateBids(originIds, originType, workspaceId, activeMpId)
          });

          trackMoveItemsToWorkspace(
            originIds,
            originType,
            workspaceId,
            eventSource,
            eventType,
            activeMpId,
            relevanceScores
          );
        }
        if (!!bids && !!bids.length) {
          const bidsIds = bids.map(bid => bid.id);
          await updateBidsWs({
            variables: {
              workspaceId,
              bidsIds
            },
            update: getUpdateCacheOnUpdateBidsWs(workspaceId, bids, !!isRejectedTab)
          });
        }
      } catch {
        notification.error({
          message: t('Common.unknownError'),
          description: t('Common.unknownErrorDesc')
        });
      }
    },
    [activeMpId, bidContext, bidContextId, createBids, isRejectedTab, t, unassignMailsToBid, updateBidsWs]
  );
  return [
    moveToWorkspaceFn,
    {
      loading
    }
  ];
}

function sumTotalBidsValue(currentVal: number | undefined, term: number): number | undefined {
  return notUndefined(currentVal) ? currentVal + term : undefined;
}

function subsTotalBidsValue(currentVal: number | undefined, term: number): number | undefined {
  return notUndefined(currentVal) ? currentVal - term : undefined;
}

export function useUpdateWsSorting(): [(sorting: IBidSorting) => void, { loading: boolean; error?: ApolloError }] {
  const [setRequestSettings, { loading, error }] = useSetRequestSettings();
  const { wsId } = useParams<{ wsId: string }>();

  const updateWsSortingFn = useCallback(
    (sorting: IBidSorting) => {
      wsId && setRequestSettings({ entityId: wsId, type: RequestType.BidsSorting, settings: JSON.stringify(sorting) });
    },
    [wsId, setRequestSettings]
  );
  return useMemo(() => [updateWsSortingFn, { loading, error }], [updateWsSortingFn, error, loading]);
}

export function useBidspaceBANSStatistics(): BidspaceStatisticsData {
  const vars = useBidspacesQpVars();
  const [{ rejected }] = useBidsSearchArgs();
  const query = useQuery<BidsCategoryStatisticsResponse, NewBidspaceRequest>(GET_BIDS_CATEGORY_STATISTICS, {
    variables: {
      ...transformQpVars(vars),
      assignedToIds: transformAssignedToQpVars(vars),
      textSearch: transformSearchQpVars(vars.search),
      rejected
    },
    nextFetchPolicy: 'cache-only'
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data?.getBidCategoryStatistics ? new AllBidspaceStatistics(query.data) : undefined,
      loading:
        (query.loading || query === undefined || query.networkStatus === 4 || query.networkStatus === 2) &&
        query.networkStatus !== 3,
      fetchingMore: query.networkStatus === 3
    }),
    [query]
  );
}

export function useAssignedToStatistics(): AssignedToStatisticsData {
  const vars = useBidspacesQpVars();
  const [{ rejected }] = useBidsSearchArgs();
  const query = useQuery<AssignedToStatisticsResponse, NewBidspaceRequest>(GET_ASSIGNED_TO_STATISTICS, {
    variables: { ...transformQpVars(vars), assignedToIds: transformAssignedToQpVars(vars), rejected },
    fetchPolicy: 'network-only'
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data?.getAssignedToStatistics ? new AllAssignedToBidspacesStatistics(query.data) : undefined,
      loading:
        (query.loading || query === undefined || query.networkStatus === 4 || query.networkStatus === 2) &&
        query.networkStatus !== 3,
      fetchingMore: query.networkStatus === 3
    }),
    [query]
  );
}

export type BidspaceQpVars = {
  search?: string;
  bidspaceId?: string[];
  category?: string[];
  assignedTo?: string[];
  bidType?: string[];
};

export function useBidspacesQpVars(): BidspaceQpVars {
  const qp = useQueryParams();
  const searchQueryParams = qp[BidspaceQueryParams.search];
  const bidspaceQueryParams = qp[BidspaceQueryParams.bidspaceId];
  const categoryQueryParams = qp[BidspaceQueryParams.category];
  const assignedToQueryParams = qp[BidspaceQueryParams.assignedTo];
  const bidTypeQueryParams = qp[BidspaceQueryParams.bidType];

  const queryParams = useMemo(
    () => ({
      search: searchQueryParams ? String(searchQueryParams) : undefined,
      bidspaceId: isStrings(bidspaceQueryParams) ? bidspaceQueryParams.sort() : undefined,
      category: isStrings(categoryQueryParams) ? categoryQueryParams.sort() : undefined,
      assignedTo: isStrings(assignedToQueryParams) ? assignedToQueryParams.sort() : undefined,
      bidType: isStrings(bidTypeQueryParams) ? bidTypeQueryParams.sort() : undefined
    }),
    [assignedToQueryParams, bidTypeQueryParams, bidspaceQueryParams, categoryQueryParams, searchQueryParams]
  );

  return useMemo(() => queryParams, [queryParams]);
}

export const useResetBidsPage = (): (() => void) => {
  const [searchArgs, updateSearchArgs] = useBidsSearchArgs();

  const resetPage = useCallback(() => {
    updateSearchArgs({ ...searchArgs, offset: 0 });
  }, [updateSearchArgs, searchArgs]);

  return resetPage;
};

export function useGetBidsCategoryStatistics(contractStartRanges?: boolean): {
  data?: BidsCategoryStatistics[];
  loading?: boolean;
} {
  const vars = useBidspacesQpVars();
  const [{ rejected }] = useBidsSearchArgs();

  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();

  // Periods is from current month to 6 months ahead
  const periods: ContractStartRangesFilter[] = Array.from({ length: 6 }, (_, i) => {
    const start = new Date(currentYear, currentMonth + i, 1);
    const offsettedStartDate = formatDateWithTimezoneOffset(start);

    const end = new Date(currentYear, currentMonth + i + 1, 1);
    const offsettedEndDate = formatDateWithTimezoneOffset(end);

    return {
      start: offsettedStartDate,
      end: offsettedEndDate
    };
  });

  const query = useQuery<BidsCategoryStatisticsResponse, NewBidspaceRequest>(GET_BIDS_CATEGORY_STATISTICS, {
    variables: {
      ...transformQpVars(vars),
      assignedToIds: transformAssignedToQpVars(vars),
      textSearch: transformSearchQpVars(vars.search),
      rejected,
      contractStartRanges: !!contractStartRanges ? periods : undefined
    }
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data?.getBidCategoryStatistics
        ? query.data.getBidCategoryStatistics.statistics
            .filter(bidCategoryStatistic => bidCategoryStatistic.startDate) // Filter out statistic without startDate
            .map(bidCategoryStatistic => new BidsCategoryStatistic(bidCategoryStatistic))
        : undefined,
      loading:
        (query.loading || query === undefined || query.networkStatus === 4 || query.networkStatus === 2) &&
        query.networkStatus !== 3,
      fetchingMore: query.networkStatus === 3
    }),
    [query]
  );
}

export function useForecastBids(
  contractStartRange: ContractStartRangesFilter,
  params: {
    amount?: number;
    offset?: number;
    statusCategories: WorkspaceStatusCategory[];
  },
  skip?: boolean
): Omit<BidsResponseData, 'updateVars' | 'currentVars' | 'clearVars'> {
  const amount = params?.amount;
  const offset = params?.offset;
  const [{ amount: defaultAmount, ...restSearchArgs }] = useBidsSearchArgs();
  const vars = useBidspacesQpVars();
  const isWebHooksFeature = !!useFeatureFlag(FeatureFlag.Users_Webhooks);

  const query = useQuery<IApiGetBidsResponse, ISearchBidsRequest>(GET_MONTHLY_BIDS_PAGES, {
    variables: {
      amount: amount ?? defaultAmount,
      ...transformQpVars(vars),
      offset,
      textSearch: transformSearchQpVars(vars.search),
      assignedToIds: transformAssignedToQpVars(vars),
      ...restSearchArgs,
      sortOnField: {
        name: BidsColumn.deadline,
        order: SortOrder.ASC
      },
      isWebHooksFeature,
      contractStart: contractStartRange,
      statusCategories: params.statusCategories,
      currency: 'SEK'
    },
    skip: !!skip
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? new BidsData(query.data.getBids) : undefined,
      loading:
        (query.loading || query === undefined || query.networkStatus === 4 || query.networkStatus === 2) &&
        query.networkStatus !== 3,
      fetchingMore: query.networkStatus === 3
    }),
    [query]
  );
}
