import React, { FC, useMemo } from 'react';
import { Skeleton } from 'src/common';
import InboxesItem from './InboxesItem';
import { useLoadInboxesNav } from 'src/models/callOffs/hooks';

interface IProps {
  sidebarIsOpen?: boolean;
}

export const InboxesList: FC<IProps> = props => {
  const { sidebarIsOpen } = props;

  const { data: inboxes, loading } = useLoadInboxesNav();

  const filteredInboxes = useMemo(() => {
    return (inboxes || []).filter(inbox => !inbox.isArchived);
  }, [inboxes]);

  return loading ? (
    <Skeleton active loading />
  ) : (
    <>
      {filteredInboxes.length
        ? filteredInboxes.map(inbox => (
            <InboxesItem inbox={inbox} key={inbox.emailAddress} sidebarIsOpen={sidebarIsOpen} />
          ))
        : null}
    </>
  );
};

export default InboxesList;
