import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { IApiWorkspaceStatus, DEFAULT_STAGE_COLORS, IWorkspaceStatus, IApiBaseStage, IBaseStage } from './types';
import { toColor } from 'src/helpers/styles';

export class BaseStage implements IBaseStage {
  public readonly id: string;
  public readonly name: string;
  public readonly color: string;

  constructor(private readonly apiStage: IApiBaseStage) {
    this.id = apiStage.id;
    this.name = apiStage.name;
    this.color = this.toStageColor();
  }

  private toStageColor(): string {
    const { name } = this.apiStage;
    return DEFAULT_STAGE_COLORS[name] || toColor(name);
  }
}

export class Stage extends BaseStage implements IWorkspaceStatus {
  public readonly description: string | null;
  public readonly order: number;
  public readonly category: WorkspaceStatusCategory;
  public readonly rank: string;

  constructor(apiStage: IApiWorkspaceStatus) {
    super(apiStage);
    this.description = apiStage.description;
    this.order = apiStage.order;
    this.category = apiStage.category;
    this.rank = apiStage.rank;
  }
}
