export const Config = {
  PrivateApi: import.meta.env.VITE_PRIVATE_API,
  PublicApi: import.meta.env.VITE_PUBLIC_API,
  SubscriptionsApi: import.meta.env.VITE_WS_API,
  CognitoClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
  CognitoPoolId: import.meta.env.VITE_COGNITO_POOL_ID,
  CognitoDomain: import.meta.env.VITE_COGNITO_DOMAIN,
  AwsRegion: import.meta.env.VITE_AWS_REGION,
  Code: import.meta.env.VITE_GA_CODE,
  I18NextURL: import.meta.env.VITE_I18NEXT_URL,
  SupportWidgetCode: import.meta.env.VITE_SUPPORT_WIDGET_CODE,
  MonitoringKey: import.meta.env.VITE_MONITORING_KEY,
  AnalyticsKey: import.meta.env.VITE_ANALYTICS_KEY,
  MsRedirectURL: import.meta.env.VITE_MICROSOFT_SIGN_IN_REDIRECT,
  ProductVoiceId: import.meta.env.VITE_PRODUCT_VOICE_ID,
  AwsRUM: {
    ApplicationId: import.meta.env.VITE_AWSRUM_APPLICATION_ID,
    GuestRoleArn: import.meta.env.VITE_AWSRUM_GUEST_ROLE_ARN,
    IdentityPoolId: import.meta.env.VITE_AWSRUM_IDENTITY_POOL_ID
  },
  RecaptchaSiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY
};

/**
 * used with https://github.com/brendanashworth/generate-password
 */
export const PASSWORD_TO_GENERATE_POLICY = {
  length: 12,
  numbers: true,
  symbols: false,
  lowercase: true,
  uppercase: true,
  strict: true
};

export interface IPasswordPolicy {
  minLength?: number;
  maxLength?: number;
  include: {
    numbers?: boolean;
    symbols?: boolean;
    lowercase?: boolean;
    uppercase?: boolean;
  };
}
/**
 * similar to PASSWORD_TO_GENERATE_POLICY but less strict because may validate user's input as well.
 */
export const PASSWORD_POLICY: IPasswordPolicy = {
  minLength: 8,
  include: {
    numbers: true,
    symbols: false,
    lowercase: true,
    uppercase: true
  }
};
