import React, { CSSProperties, FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

/**
 * factory to create onLoadMore function which does not suffer from variable changes.
 */
export function useFetchMore<T, R>(fetchMoreVariables: T, onFetchMore: (variables: T) => R): () => R {
  const variables = useRef(fetchMoreVariables);
  variables.current = fetchMoreVariables;

  return useCallback(() => {
    return onFetchMore(variables.current);
  }, [onFetchMore, variables]);
}

interface IOnVisibilityTriggerProps {
  container: Element | null;
  containerMargin?: string; // e.g., "0px 0px 200px 0px"
  isLoading: boolean;
  onVisible: () => void;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

function useObservableState<T>(value: T, initialValue: T): Observable<T> {
  const [state$] = useState(() => new BehaviorSubject<T>(initialValue));
  useEffect(() => {
    state$.next(value);
  }, [state$, value]);

  return state$;
}
export const OnVisibilityTrigger: FC<IOnVisibilityTriggerProps> = props => {
  const { children, container, containerMargin, isLoading, onVisible, className, style } = props;
  const intersectionRef = useRef(null);

  const intersection = useIntersection(intersectionRef, {
    root: container,
    rootMargin: containerMargin
  });
  const isIntersecting$ = useObservableState(!!intersection?.isIntersecting, false);
  const isLoading$ = useObservableState(isLoading, false);
  const watcher$ = useMemo(
    () =>
      combineLatest([isIntersecting$, isLoading$]).pipe(
        debounceTime(200), // isLoading can be set to false while button
        tap(([isIntersecting_, isLoading_]) => {
          if (isIntersecting_ && !isLoading_) {
            onVisible();
          }
        })
      ),
    [isIntersecting$, isLoading$, onVisible]
  );

  useEffect(() => {
    const subscription = watcher$.subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [watcher$]);

  return (
    <div ref={intersectionRef} className={className} style={style}>
      {children}
    </div>
  );
};
