import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import styles from './index.module.scss';
import HeaderActions from './HeaderActions';
import VersionUpdateAlert from '../VersionUpdateAlert';
import { ThemeMode, ownership } from 'src/models/ownership';

export const Header: FC = () => {
  return (
    <header className={styles.topbar}>
      <div className={styles.topbarContainer}>
        <div className={styles.logo}>
          <Link to={Paths.ROOT} className={styles.logoLink}>
            <ownership.logo mode={ThemeMode.dark} />
          </Link>
        </div>
        <HeaderActions />
      </div>
      <VersionUpdateAlert />
    </header>
  );
};

export default Header;
