import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'src/common';

interface IProps {
  onClose: () => void;
  description: string;
  title?: string;
}

export const AuthWarning: FC<IProps> = props => {
  const { onClose, description, title } = props;
  const { t } = useTranslation();
  return (
    <Modal
      visible
      onCancel={onClose}
      title={title ?? t('Common.unknownError')}
      footer={
        <Button type={'primary'} onClick={onClose}>
          {t('Common.ok')}
        </Button>
      }
      maskClosable={false}
      width={500}
    >
      <p>{description}</p>
    </Modal>
  );
};

export default AuthWarning;
