import { Modal as baseModal } from 'antd';
import React from 'react';
import { ButtonProps } from 'antd/lib/button/button';

export interface ArgsProps {
  title: string | React.ReactNode;
  content?: string | React.ReactNode;
  onClose?: () => void;
  onCancel?: () => void;
  icon?: React.ReactNode;
  cancelText?: string;
  okText: string;
  onOk?: () => void;
  closeIcon?: React.ReactNode;
  width?: string | number;
  cancelButtonProps?: ButtonProps;
  okButtonProps?: ButtonProps;
  className?: string;
  centered?: boolean;
  transitionName?: string;
}

export interface ConfirmInstance {
  confirm(args: ArgsProps): void;
  warning(args: ArgsProps): void;
}
export interface NotificationApi extends ConfirmInstance {}

// The API restricted
const confirm: NotificationApi = baseModal;
export default confirm;
