import { BadgeProps, Badge as BaseBadge } from 'antd';

import React, { FC, PropsWithChildren } from 'react';

export const BadgeComponent = BaseBadge;

const Badge: FC<PropsWithChildren & BadgeProps> = ({ children, ...rest }) => {
  return <BadgeComponent {...rest}>{children}</BadgeComponent>;
};

export default Badge;
