import React, { JSX, useContext, useState } from 'react';
import { Group } from '@visx/group';
import { inPeriod } from 'src/helpers/dates';
import {
  BAR_OPACITY_BLUR,
  BAR_RADIUS,
  DATE_RANGE_BAR_COLOR,
  DATE_RANGE_BAR_HEIGHT,
  DateRangeProps,
  SPACE_BETWEEN_BARS
} from '../types';
import { XYChartContext } from '../XYChart/context';
import { getX } from '../helpers';

export function DateRangeBar<T>(props: DateRangeProps<T>): JSX.Element | null {
  const { data, getDateRanges, onMouseEnter, onMouseLeave, onClick } = props;
  const [focus, setFocus] = useState<number | null>(null);
  const context = useContext(XYChartContext);
  if (!context) return null;

  const { xScale, yMax, margin } = context;
  const dates = xScale.domain();

  let cumulativeX = 0;
  let xOffset = 0;

  return (
    <>
      <Group>
        {data.map((d, i) => {
          const [start, end] = getDateRanges(d);
          const startDate = inPeriod(start, dates);
          const endDate = inPeriod(end, dates);
          const startX = startDate ? getX(startDate, xScale) : 0;
          const endX = endDate ? getX(endDate, xScale) : 0;
          const barWidth = Math.max(endX - startX, 0);
          const barX = cumulativeX ? cumulativeX + xOffset : startX;
          const barY = (yMax - DATE_RANGE_BAR_HEIGHT) / 2;

          cumulativeX = endX;
          xOffset += SPACE_BETWEEN_BARS;

          return (
            <React.Fragment key={`dr-bar-${i}`}>
              <rect
                style={onClick ? { cursor: 'pointer' } : undefined}
                y={barY}
                x={barX}
                rx={BAR_RADIUS}
                ry={BAR_RADIUS}
                height={DATE_RANGE_BAR_HEIGHT}
                width={barWidth}
                fill={i === 0 ? DATE_RANGE_BAR_COLOR[0] : DATE_RANGE_BAR_COLOR[1]}
                opacity={focus === i ? '100%' : BAR_OPACITY_BLUR}
                onMouseEnter={() => {
                  setFocus(i);
                  onMouseEnter?.(
                    {
                      tooltipData: d,
                      tooltipTop: barY - margin.top + margin.bottom + DATE_RANGE_BAR_HEIGHT + 10,
                      tooltipLeft: barX + margin.left
                    },
                    i
                  );
                }}
                onMouseLeave={() => {
                  setFocus(null);
                  onMouseLeave?.();
                }}
                onClick={() => {
                  onClick?.(d);
                }}
              />
            </React.Fragment>
          );
        })}
      </Group>
    </>
  );
}

export default DateRangeBar;
