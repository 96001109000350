import { User } from 'src/models/users';
import { PaymentRole } from '@tendium/prom-types';
import dayjs from 'src/helpers/dayjs';
import { MfaMode } from 'src/models/users/types';
import { ICompanyUser, IApiCompanyUser } from './types';

export class CompanyUser extends User implements ICompanyUser {
  readonly companyRole: string;
  readonly isAdmin: boolean;
  readonly createdOn: string;
  readonly inviteSent: boolean;
  readonly pricePlan?: PaymentRole;
  readonly mfaMode: MfaMode;

  constructor(apiUser: IApiCompanyUser) {
    super(apiUser);
    this.companyRole = apiUser.companyRole;
    this.isAdmin = apiUser.isAdmin;
    this.createdOn = dayjs(apiUser.createdOn).format('YYYY-MM-DD');
    this.inviteSent = apiUser.invitationReSent;
    this.mfaMode = apiUser.mfaMode;
  }
}
