import React, { FC } from 'react';
import App from './App';
import { ApolloProvider } from '@apollo/client/react';
import { gqlClient } from 'src/lib/API/graphql/api-client';
import { Outlet } from 'react-router';
import { AppProvider } from './models/auth';
import { ErrorBoundary } from '@sentry/react';
import i18nService from './lib/I18nService';
import { I18nextProvider } from 'react-i18next';
import ErrorPage from './pages/ErrorPage';

const Root: FC = () => {
  const logError = (error: Error, componentStack: string, eventId: string): void => {
    console.warn({ error, componentStack, eventId });
  };

  return (
    <ApolloProvider client={gqlClient.client}>
      <I18nextProvider i18n={i18nService.i18n}>
        <ErrorBoundary fallback={<ErrorPage />} onError={logError}>
          <AppProvider>
            <App />
            <Outlet />
          </AppProvider>
        </ErrorBoundary>
      </I18nextProvider>
    </ApolloProvider>
  );
};

export { Root };

export default Root;
