import React, { FC, ReactNode, useCallback, useState } from 'react';
import Dropdown from 'src/common/Dropdown';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';
import classNames from 'classnames';
import ViewOptionsDropDownOverlay from './ViewOptionsDropDownOverlay';
import { PageView } from 'src/models/users/Preferences/types';

interface IProps {
  extra?: ReactNode;
  onToggleView: (view: PageView) => void;
  currentView: PageView;
  tableViewText?: string;
  cardViewText?: string;
  isDashboard?: boolean;
}
const ViewOptionsDropDown: FC<IProps> = props => {
  const { extra, onToggleView, currentView, tableViewText, cardViewText, isDashboard } = props;
  const [menuVisible, setMenuVisible] = useState(false);

  const onVisibleChange = useCallback(() => {
    setMenuVisible(prevState => !prevState);
  }, []);

  return (
    <Dropdown
      overlay={
        <ViewOptionsDropDownOverlay
          extra={extra}
          onToggleView={onToggleView}
          currentView={currentView}
          tableViewText={tableViewText}
          cardViewText={cardViewText}
          isDashboard={isDashboard}
        />
      }
      trigger={['click']}
      visible={menuVisible}
      onVisibleChange={onVisibleChange}
    >
      <button className={classNames(styles.button, { [styles.isActive]: menuVisible })}>
        <FontAwesomeIcon icon={faEye} className={styles.icon} />
      </button>
    </Dropdown>
  );
};

export default ViewOptionsDropDown;
