import React, { ReactNode, FC, useMemo, useState } from 'react';
import { BoxPropsContext } from './context';
import {
  IBoxInnerProps,
  IBoxProps,
  isBoxBase,
  isBoxCollection,
  IFieldConfig,
  IBoxCollectionProps,
  IBoxReverseProps,
  IBoxCustomProps,
  IBoxBaseProps,
  DEFAULT_CONFIG
} from './types';
import classNames from 'classnames';
import { TenderBlockSize, TenderBoxMode } from 'src/models/procurements/Tender/types';
import styles from './Box/index.module.scss';
import boxStyles from './EditableBox/index.module.scss';

interface IProps {
  children: ReactNode;
  props: IBoxProps;
}

export const BoxProvider: FC<IProps> = ({ children, props }) => {
  const { className, size = TenderBlockSize.Full, boxMode, config, as, box, boxes } = props;
  const [isActive, setIsActive] = useState(false);

  const order = useMemo(
    () =>
      isBoxCollection(props)
        ? props.boxes && !!props.boxes.length
          ? props.boxes[0].order
          : 0
        : props.box
        ? props.box.order
        : 0,
    [props]
  );

  const fieldConfig: IFieldConfig = useMemo(
    () => ({ ...DEFAULT_CONFIG, isTitle: config?.isTitle ?? isBoxBase(props) }),
    [config, props]
  );
  const contextValue: IBoxInnerProps = useMemo(() => {
    const tempProps = {
      ...props,
      config: fieldConfig,
      isActive
    };
    switch (as) {
      case TenderBoxMode.Collection:
        return { ...tempProps, boxes } as IBoxCollectionProps;
      case TenderBoxMode.Reverse:
        return { ...tempProps, box } as IBoxReverseProps;
      case TenderBoxMode.Custom:
        return { ...tempProps, box } as IBoxCustomProps;
      case TenderBoxMode.Base:
        return { ...tempProps, box } as IBoxBaseProps;
      default:
        return { ...tempProps, box } as IBoxCustomProps;
    }
  }, [as, box, boxes, fieldConfig, isActive, props]);

  return (
    <BoxPropsContext.Provider value={contextValue}>
      <div
        className={classNames(styles.box, styles[size], !!className && className, {
          [boxStyles.editableBox]: !!boxMode,
          [styles.editable]: !!boxMode
        })}
        style={{ order }}
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        {children}
      </div>
    </BoxPropsContext.Provider>
  );
};
