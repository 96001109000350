import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { BlockLabel } from 'src/shared/Blocks';
import classNames from 'classnames';
import { TenderBlockSize } from 'src/models/procurements/Tender/types';

export interface IProps {
  value: ReactNode;
  title?: ReactNode;
  size?: TenderBlockSize;
  className?: string;
}
export const InfoBox: FC<IProps> = ({ title, value, className, size = TenderBlockSize.Full }) => {
  return (
    <div className={classNames(styles.box, styles.infoBox, !!className && className, styles[size])}>
      {title && <BlockLabel label={title} />}
      <span className={styles.content}>{value}</span>
    </div>
  );
};

export default InfoBox;
