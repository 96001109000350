import { useMutation } from '@apollo/client';
import differenceBy from 'lodash/differenceBy';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, notification, useMe } from 'src/common';
import styles from './index.module.scss';
import {
  UPDATE_WS_OWNERS,
  UPDATE_WS_SUBSCRIBERS,
  MOVE_WS_MEMBERS,
  UPDATE_WS_POLICY,
  UPDATE_WS_OWNERS_POLICY
} from 'src/models/workspace/queries';
import AccessControlItem, { Mode } from 'src/common/AccessControlItem';
import {
  trackUpdateWorkspaceOwners,
  trackUpdateWorkspacePolicy,
  trackUpdateWorkspaceSubscribers,
  trackUpdateWorkspaceSubscribersAndOwners
} from 'src/segment/events';
import { AccessControlPolicy, IMember } from 'src/models/acl/types';
import { useEmployees } from 'src/models/users/hooks';
import { useWorkspace } from 'src/models/workspace/hooks';
import { toMembersList, getPreparedMembersArray } from 'src/models/acl/helpers';
import { useParams } from 'react-router';

interface ICompanyTypeProps {
  readOnly: boolean;
}

export const AccessControlList: FC<ICompanyTypeProps> = props => {
  const { readOnly } = props;
  const { t } = useTranslation();
  const { wsId: activeWSId } = useParams<{ wsId: string }>();
  const { data: meData } = useMe();
  const myUserId = meData?.email;

  const { data: employees, loading: employeeLoading } = useEmployees();
  const { data: ws, loading: wsLoading } = useWorkspace(activeWSId);

  const [updateWSOwners] = useMutation<
    { updateMatchingProfile: { owners: string[] } },
    { id: string; owners: string[] }
  >(UPDATE_WS_OWNERS);

  const [updateWSSubscribers] = useMutation<
    { updateMatchingProfile: { subscribers: string[] } },
    { id: string; subscribers: string[] }
  >(UPDATE_WS_SUBSCRIBERS);

  const [moveWSMembers] = useMutation<
    { updateMatchingProfile: { subscribers: string[]; owners: string[] } },
    { id: string; subscribers: string[]; owners: string[] }
  >(MOVE_WS_MEMBERS);

  const [updateWSPolicy] = useMutation<
    { updateMatchingProfile: { accessControl: { subscribePolicy: AccessControlPolicy } } },
    { id: string; policy: AccessControlPolicy }
  >(UPDATE_WS_POLICY);

  const [updateWSOwnersPolicy] = useMutation<
    { updateMatchingProfile: { accessControl: { editPolicy: AccessControlPolicy } } },
    { id: string; policy: AccessControlPolicy }
  >(UPDATE_WS_OWNERS_POLICY);

  const owner = employees?.createdBy;

  const subscribers = useMemo((): IMember[] => {
    if (employees && owner) {
      return toMembersList(ws?.subscribers || [], owner);
    } else {
      return []; // we can not show subscribers while we do not know who is an owner
    }
  }, [employees, owner, ws]);
  const owners = useMemo((): IMember[] => {
    if (employees && owner) {
      return toMembersList(ws?.owners || [], owner);
    } else {
      return []; // we can not show subscribers while we do not know who is an owner
    }
  }, [employees, owner, ws]);

  const subscribePolicy = ws?.accessControl.subscribePolicy;
  const editPolicy = ws?.accessControl.editPolicy;

  const updateWSOwnersList = useCallback(
    (_owners: string[]) => {
      activeWSId &&
        updateWSOwners({ variables: { id: activeWSId, owners: _owners } })
          .then(() => {
            trackUpdateWorkspaceOwners({ id: activeWSId });
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeWSId, t, updateWSOwners]
  );

  const updateWSSubscribersList = useCallback(
    (_subscribers: string[]) => {
      activeWSId &&
        updateWSSubscribers({ variables: { id: activeWSId, subscribers: _subscribers } })
          .then(() => {
            trackUpdateWorkspaceSubscribers({ id: activeWSId });
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeWSId, t, updateWSSubscribers]
  );

  const onMoveMember = useCallback(
    (id: string, isOwner?: boolean) => {
      const ownersIds = owners.map(({ user }) => user.id);
      const subscribersIds = subscribers.map(({ user }) => user.id);

      const ownersVal = getPreparedMembersArray(ownersIds, id, isOwner);
      const subscribersVal = getPreparedMembersArray(subscribersIds, id, !isOwner);

      activeWSId &&
        moveWSMembers({ variables: { id: activeWSId, owners: ownersVal, subscribers: subscribersVal } })
          .then(() => {
            trackUpdateWorkspaceSubscribersAndOwners({ id: activeWSId });
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [owners, subscribers, activeWSId, moveWSMembers, t]
  );

  const changeWSOwnerPolicy = useCallback(
    (policy: AccessControlPolicy) => {
      activeWSId &&
        updateWSOwnersPolicy({ variables: { id: activeWSId, policy } })
          .then(() => {
            trackUpdateWorkspacePolicy(
              {
                id: activeWSId
              },
              policy
            );
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeWSId, t, updateWSOwnersPolicy]
  );

  const changeWSPolicy = useCallback(
    (policy: AccessControlPolicy) => {
      activeWSId &&
        updateWSPolicy({ variables: { id: activeWSId, policy } })
          .then(() => {
            trackUpdateWorkspacePolicy(
              {
                id: activeWSId
              },
              policy
            );
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
    },
    [activeWSId, t, updateWSPolicy]
  );

  // SHOW ONLY MEMBERS WHO ARE NOT OWNER OR SUBSCRIBER  YET
  const membersOptions = useMemo((): IMember[] => {
    const employeesList =
      employees && owner
        ? toMembersList([owner, ...employees.personel.filter(emp => emp.enabled), ...employees.teams], owner)
        : [];

    return differenceBy(employeesList, [...owners, ...subscribers], 'user.id');
  }, [employees, owner, owners, subscribers]);

  const isLoading = wsLoading || employeeLoading;

  return (
    <Card loading={isLoading} bordered={false} className={styles.card}>
      <div className={styles.container}>
        <AccessControlItem
          myUserId={myUserId}
          readOnly={readOnly}
          mode={Mode.Owners}
          membersOptions={membersOptions}
          members={owners}
          onUpdate={updateWSOwnersList}
          onMove={onMoveMember}
          description={t('BidSpaces.infoAboutBSOwners')}
          policy={editPolicy}
          onChangePolicy={changeWSOwnerPolicy}
          policyDescription={t('Common.ACL.setEverybodyAsEditor')}
        />
        <AccessControlItem
          myUserId={myUserId}
          readOnly={readOnly}
          mode={Mode.Subscribers}
          membersOptions={membersOptions}
          members={subscribers}
          onUpdate={updateWSSubscribersList}
          onMove={onMoveMember}
          policy={subscribePolicy}
          onChangePolicy={changeWSPolicy}
          policyDescription={t('BidSpaces.giveAllUsersPermissionToBidSpace')}
          description={t('BidSpaces.infoAboutBSSubscribers')}
          disabled={editPolicy === AccessControlPolicy.ALL}
        />
      </div>
    </Card>
  );
};

export default AccessControlList;
