import { ASSIGNED_TO_FIELDS } from './../users/AssignedTo/queries';
import { gql } from '@apollo/client';
import { LOT_FIELDS, BOX_FIELDS_FIELD, BASE_BOX_FIELDS } from '../procurements/queries';
import { WEBHOOKS_CREATE_STATUS_FIELDS } from '../company/Webhooks/queries';

export const BID_BOX_FIELDS = gql`
  fragment bidBoxFields on TenderBox {
    ...baseBoxFields
    ...boxFieldsField
  }
  ${BASE_BOX_FIELDS}
  ${BOX_FIELDS_FIELD}
`;

// TODO: lotsFields needed for bids list too (prioritizing boxes), until we switch to item-ish stuff
export const BID_BOX_FULL_FIELDS = gql`
  fragment bidBoxFullFields on TenderBox {
    ...bidBoxFields
    lots {
      ...lotFields
    }
  }
  ${BID_BOX_FIELDS}
  ${LOT_FIELDS}
`;

export const BIDS_TIMELINE_ITEM_BASIC_FIELDS = gql`
  fragment bidsTimelineItemBasicFields on TimelineRefactored {
    id
    availableDate {
      ...bidBoxFullFields
    }
    contractStartAndEndStart {
      ...bidBoxFullFields
    }
    contractStartAndEndEnd {
      ...bidBoxFullFields
    }
    deadline {
      ...bidBoxFullFields
    }
  }
  ${BID_BOX_FULL_FIELDS}
`;

export const BIDS_GENERAL_ITEM_BASIC_FIELDS = gql`
  fragment bidsGeneralItemBasicFields on General {
    id
    name {
      ...bidBoxFullFields
    }
    buyerBoxes {
      ...bidBoxFullFields
    }
    contractValueBoxes {
      ...bidBoxFullFields
    }
    frameworkAgreement {
      ...bidBoxFullFields
    }
  }
  ${BID_BOX_FULL_FIELDS}
`;
export const BF_PROJECT_FIELDS = gql`
  fragment ByggfaktaProjectFields on ByggfaktaPrivateCreatedProject {
    createdAt
    bidId
    projectId
  }
`;
export const BID_BF_PROJECT_FIELDS = gql`
  fragment BidByggfaktaProjectFields on BidV2 {
    byggfaktaProject {
      ...ByggfaktaProjectFields
    }
  }
  ${BF_PROJECT_FIELDS}
`;

export const BID_STAGE_ID_FIELDS = gql`
  fragment bidStageIdFields on BidV2 {
    bidStageId
  }
`;

export const BIDS_FIELDS = gql`
  fragment bidsFields on BidV2 {
    id
    ...bidStageIdFields
    comments {
      id
    }
    status {
      id
      name
      category
      rank
    }
    assignedTo {
      ...assignedToFields
    }
    workspace {
      id
      color
      name
    }
    webhookStatus @include(if: $isWebHooksFeature) {
      ...webhooksCreateStatusFields
    }
    isRejected
    itemType
    itemId
    item {
      ... on Tender {
        procurementStatus
        id
        assignedTo {
          ...assignedToFields
        }
        timeline {
          ...bidsTimelineItemBasicFields
        }
        general {
          ...bidsGeneralItemBasicFields
        }
        custom {
          ...bidBoxFields
        }
        bids {
          id
        }
        lots {
          ...lotFields
        }
      }
      ... on UserMail {
        mailId
        timeline {
          ...bidsTimelineItemBasicFields
        }
        general {
          ...bidsGeneralItemBasicFields
        }
        custom {
          ...bidBoxFields
        }
        bids {
          id
        }
      }
      ... on ManualBidItem {
        id
        timeline {
          ...bidsTimelineItemBasicFields
        }
        general {
          ...bidsGeneralItemBasicFields
        }
        custom {
          ...bidBoxFields
        }
        bids {
          id
        }
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
  ${BIDS_GENERAL_ITEM_BASIC_FIELDS}
  ${BIDS_TIMELINE_ITEM_BASIC_FIELDS}
  ${BID_BOX_FIELDS}
  ${LOT_FIELDS}
  ${WEBHOOKS_CREATE_STATUS_FIELDS}
  ${BID_STAGE_ID_FIELDS}
`;

export const REMOVE_BID = gql`
  mutation ($bidId: String!) {
    deleteBid(bidId: $bidId)
  }
`;

export const ASSIGN_TO_BID = gql`
  mutation assignToBid($bidId: String!, $userId: String) {
    assignUserToBid(input: { bidId: $bidId, userId: $userId }) {
      assignedTo {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const CREATE_BF_PROJECT = gql`
  mutation createByggfaktaProject($bidId: String!) {
    exportBidToByggfaktaPrivate(bidId: $bidId) {
      ...ByggfaktaProjectFields
    }
  }
  ${BF_PROJECT_FIELDS}
`;
