import { gql } from '@apollo/client';
import { LOT_FIELDS } from '../queries';
import { BID_BOX_FIELDS } from 'src/models/bids/queries';
import { BOX_FIELDS, CATEGORY_FILE_FIELDS } from '../Tender/queries';
import { BID_BF_PROJECT_FIELDS } from '../../bids/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';

export const GET_BID_ORIGIN_ID = gql`
  query getBidOriginId($bidId: String!) {
    getBid(input: { bidId: $bidId }) {
      id
      itemId
      itemType
    }
  }
`;

export const TENDER_NAV_FIELDS = gql`
  fragment tenderNavFields on Tender {
    id
    lots {
      ...lotFields
    }
    general {
      id
      name {
        ...boxFields
      }
      buyerBoxes {
        ...boxFields
      }
      linkToProcurerBoxes {
        ...boxFields
      }
      contractValueBoxes {
        ...boxFields
      }
    }
    timeline {
      id
      availableDate {
        ...boxFields
      }
      deadline {
        ...boxFields
      }
    }
    boxes {
      ...bidBoxFields
    }
    procurementStatus
    isStarred
    assignedTo {
      ...assignedToFields
    }
    bids {
      id
      assignedTo {
        ...assignedToFields
      }
      workspace {
        id
      }
      ...BidByggfaktaProjectFields
    }
    fileCategories {
      ...categoryFileFields
    }
  }
  ${BOX_FIELDS}
  ${LOT_FIELDS}
  ${ASSIGNED_TO_FIELDS}
  ${BID_BOX_FIELDS}
  ${CATEGORY_FILE_FIELDS}
  ${BID_BF_PROJECT_FIELDS}
`;

export const GET_TENDER_NAV = gql`
  query getTenderNav($id: String!) {
    getTender(query: { id: $id }) {
      ...tenderNavFields
    }
  }
  ${TENDER_NAV_FIELDS}
`;

export const GET_UPD_TENDER_NAV = gql`
  query getTenderNav($id: String!, $bidId: String!) {
    getUpdatedTender(query: { id: $id, bidId: $bidId }) {
      ...tenderNavFields
    }
  }
  ${TENDER_NAV_FIELDS}
`;
