import { useCallback, useContext } from 'react';
import { BidPreviewContext, BidPreviewContextValue } from './context';
import { useQuery, MutationUpdaterFn, Reference, QueryResult, useMutation } from '@apollo/client';
import { IBidPreviewResponse } from './types';
import { GET_BID_PREVIEW, REJECT_BID, UPDATE_CUSTOM_TENDER_BOX, UPDATE_TENDER_BOX } from './queries';
import { IApiBoxField, ITenderBox } from 'src/models/procurements/Tender/types';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { BID_BOX_FIELDS } from '../queries';
import { IBids, IBidsCached } from '../Bids/types';
import { clearWsStatsCache } from 'src/models/workspace/hooks';
import { isTenderPage } from 'src/models/procurements/helpers';
import { trackRejectBid } from 'src/segment/events';
import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { useLocation } from 'react-router';
import { Paths } from 'src/pages/paths';
import { evictBidStatisticsCache } from '../Bids/helpers';

export function useBidPreview(): BidPreviewContextValue {
  return useContext(BidPreviewContext);
}

export function useBidPreviewData(bidId?: string): QueryResult<IBidPreviewResponse, { bidId?: string }> {
  const isWebHooksFeature = !!useFeatureFlag(FeatureFlag.Users_Webhooks);
  return useQuery<IBidPreviewResponse, { bidId?: string; isWebHooksFeature?: boolean }>(GET_BID_PREVIEW, {
    variables: {
      bidId,
      isWebHooksFeature
    },
    skip: !bidId
  });
}

// FIXME: @ move to tender or root
interface IUpdateTenderBox {
  id: string;
  title: string;
  specificationId: string;
  fields: IApiBoxField[];
  category: string;
}

export function useUpdateBox(): [
  (id: string, bidId: string, box: IUpdateTenderBox, isWsStatsAffected?: boolean) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [updateBox, { loading, error }] = useMutation<
    { updateTenderBox: IUpdateTenderBox & { __typename: 'UpdateTenderBoxResult' }; __typename: 'Mutation' },
    { id: string; bidId: string; box: IUpdateTenderBox }
  >(UPDATE_TENDER_BOX);

  const updateFn = useCallback(
    (id: string, bidId: string, box: IUpdateTenderBox, isWsStatsAffected?: boolean) => {
      updateBox({
        variables: {
          id,
          bidId,
          box
        },
        update: getUpdateCacheOnUpdateField(box.id, isWsStatsAffected ? bidId : undefined)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, updateBox]
  );
  return [updateFn, { loading, error }];
}

export function useMarkBidAsRejected(): [
  (
    bidId: string,
    rejected: boolean,
    bidStageId: string,
    workspaceId: string,
    onComplete: () => void,
    workspaceStageCategory?: WorkspaceStatusCategory
  ) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [rejectBid, { loading, error }] = useMutation<
    { rejectBid: IBids; __typename: 'Mutation' },
    { id: string; rejected: boolean }
  >(REJECT_BID);

  const rejectFn = useCallback(
    (
      id: string,
      rejected: boolean,
      bidStageId: string,
      workspaceId: string,
      onComplete: () => void,
      workspaceStageCategory?: WorkspaceStatusCategory
    ) => {
      rejectBid({
        variables: {
          id,
          rejected
        },
        update: getUpdateCacheOnRejectBid(id, bidStageId, workspaceId, rejected)
      })
        .then(() => {
          onComplete();
          trackRejectBid(
            {
              id: workspaceId
            },
            workspaceStageCategory
          );
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [rejectBid, t]
  );
  return [rejectFn, { loading, error }];
}

interface IUpdateCustomTenderBox {
  id: string;
  fields: IApiBoxField[];
}

interface ITenderBoxTest extends Omit<ITenderBox, 'fields'> {
  fields: IApiBoxField[];
}
export function useUpdateCustomBox(): [
  (id: string, bidId: string, box: ITenderBoxTest) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [updateCustomBox, { loading, error }] = useMutation<
    { updateCustomBidFieldBox: IUpdateCustomTenderBox; __typename: 'Mutation' },
    { id: string; bidId: string; box: IUpdateCustomTenderBox }
  >(UPDATE_CUSTOM_TENDER_BOX);

  const updateFn = useCallback(
    (id: string, bidId: string, box: ITenderBoxTest) => {
      updateCustomBox({
        variables: {
          id,
          bidId,
          box: {
            id: box.id,
            fields: box.fields
          }
        },
        update: getUpdateCacheOnUpdateCustomBoxField(id, box)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, updateCustomBox]
  );
  return [updateFn, { loading, error }];
}

export function getUpdateCacheOnUpdateField(
  boxId: string,
  bidId?: string
): MutationUpdaterFn<{
  updateTenderBox: IUpdateTenderBox;
  __typename: 'Mutation';
}> {
  return (cache, { data }) => {
    if (!data?.updateTenderBox.fields) {
      return;
    }

    evictBidStatisticsCache(cache);

    const tenderBoxRef = cache.identify({
      __typename: 'TenderBox',
      id: boxId
    });

    !isTenderPage() &&
      cache.modify({
        id: tenderBoxRef,
        fields: {
          fields() {
            return [...data.updateTenderBox.fields];
          }
        }
      });
    cache.evict({
      id: cache.identify({ __typename: 'UpdateTenderBoxResult', id: boxId })
    });
    cache.gc();

    let wsId;
    bidId &&
      cache.modify({
        id: cache.identify({
          __typename: 'BidV2',
          id: bidId
        }),
        fields: {
          workspace(wsRef, { readField }) {
            wsId = readField('id', wsRef);
            return wsRef;
          }
        }
      });
    wsId && clearWsStatsCache(cache, wsId);
  };
}

export function getUpdateCacheOnUpdateCustomBoxField(
  tenderId: string,
  box: ITenderBoxTest
): MutationUpdaterFn<{
  updateCustomBidFieldBox: IUpdateCustomTenderBox;
  __typename: 'Mutation';
}> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const tenderBoxRef = cache.identify({
      __typename: 'TenderBox',
      id: data.updateCustomBidFieldBox.id
    });

    if (data.updateCustomBidFieldBox.id === box.id) {
      cache.modify({
        id: tenderBoxRef,
        fields: {
          fields() {
            return [...data.updateCustomBidFieldBox.fields];
          }
        }
      });
    } else {
      const tenderRef = cache.identify({
        __typename: 'Tender',
        id: tenderId
      });

      const newTenderBoxRef = cache.writeFragment({
        fragment: BID_BOX_FIELDS,
        fragmentName: 'bidBoxFields',
        data: {
          ...box,
          id: data.updateCustomBidFieldBox.id,
          fields: data.updateCustomBidFieldBox.fields,
          __typename: 'TenderBox'
        }
      });

      cache.modify({
        id: tenderRef,
        fields: {
          custom(existingRefs: Reference[], { readField }) {
            return existingRefs.map(ref => {
              return box.id === readField('id', ref) ? newTenderBoxRef : ref;
            });
          }
        }
      });
    }
    cache.evict({
      id: cache.identify({ __typename: 'UpdateTenderBoxResult', id: box.id })
    });
    cache.gc();
  };
}

export function getUpdateCacheOnRejectBid(
  bidId: string,
  bidStageId: string,
  workspaceId: string,
  toReject: boolean
): MutationUpdaterFn<{
  rejectBid: IBids;
  __typename: 'Mutation';
}> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    evictBidStatisticsCache(cache);

    cache.modify({
      fields: {
        getBids(cached: IBidsCached, { readField, storeFieldName, toReference }) {
          const rejected = storeFieldName.includes('"rejected":false');
          if (storeFieldName.includes(bidStageId) || !storeFieldName.includes('stageIds')) {
            if ((!rejected && toReject) || (rejected && !toReject)) {
              const bid = cache.identify({
                __typename: 'BidV2',
                id: bidId
              });
              if (!bid) return cached;

              return {
                ...cached,
                total: cached.total + 1,
                bids: [...cached.bids, toReference(bid)]
              };
            } else {
              const newBids = [...cached.bids].filter(
                bid => bid && bidId !== readField({ fieldName: 'id', from: bid })
              );
              return {
                ...cached,
                total: cached.total - 1,
                bids: newBids
              };
            }
          } else {
            return cached;
          }
        }
      }
    });

    clearWsStatsCache(cache, workspaceId);
  };
}

// NOTE: These paths might need to be updated when new paths are implemented
export function useBidPreviewNav(): 'All bidspaces' | 'Bidspace' | '' {
  const { pathname } = useLocation();

  const isAllBidspacesPage = pathname === Paths.BIDSPACES;
  const isBidspacePage = pathname.includes(Paths.BIDSPACES + '/');

  if (isAllBidspacesPage) return 'All bidspaces';

  if (isBidspacePage) return 'Bidspace';

  return '';
}
