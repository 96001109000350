import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { notification } from 'src/common';
import { useUpdateLanguage } from 'src/models/users/Account/hooks';
import { Language } from '@tendium/prom-types';
import { isPaidUser, useApp, useUpdateFreeUser } from '../models/auth';
import { updateUserAttributes } from 'aws-amplify/auth';

export function useSyncUsersLanguage(): void {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;
  const { user } = useApp();
  const [changeLanguage] = useUpdateLanguage();
  const updateFreeUser = useUpdateFreeUser();

  useEffect(() => {
    if (user && !user.language) {
      if (isPaidUser(user)) {
        changeLanguage({
          variables: {
            id: user.email,
            language
          }
        }).catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
      } else {
        updateUserAttributes({ userAttributes: { locale: language } })
          .then(() => {
            updateFreeUser({
              language
            });
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          });
      }
    } else if (user?.language && user.language !== language) {
      i18n.changeLanguage(user.language).catch(error => {
        console.warn({ error });
      });
    }
  }, [changeLanguage, i18n, language, t, updateFreeUser, user]);
}

export default useSyncUsersLanguage;
