import React, { FC } from 'react';
import previewStyles from 'src/shared/InfoSidebar/index.module.scss';
import BidPreviewHeader from '../Header';
import BidPreviewNav from '../Nav';
import { ActivePreviewTab } from 'src/models/procurements/types';
import BidPreviewInfo from '../Info';
import BidPreviewComments from '../Comments';
import BidPreviewEmail from '../Email';
import { usePreviewTabState } from 'src/models/procurements/hooks';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { Skeleton } from 'src/common';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';
import TenderPreviewOutcome from 'src/pages/TendersPage/Sidebars/TenderPreview/Outcome';
import { BidItemType } from '@tendium/prom-types/tender';

export const BidPreviewContent: FC = () => {
  const { data, loading } = useBidPreview();
  const origin = data?.origin;
  const tenderId = origin?.originType === BidItemType.Procurement ? origin.id : undefined;
  const [activeTab] = usePreviewTabState();

  const isAwardDataFeatureAvailable = useFeatureFlag(FeatureFlag.AwardData);

  return loading ? (
    <Skeleton active loading />
  ) : (
    <>
      <div className={previewStyles.header}>
        <BidPreviewHeader />
        <BidPreviewNav />
      </div>
      <div className={previewStyles.content}>
        {activeTab === ActivePreviewTab.Info && <BidPreviewInfo />}
        {activeTab === ActivePreviewTab.Comments && <BidPreviewComments />}
        {activeTab === ActivePreviewTab.Email && <BidPreviewEmail />}
        {isAwardDataFeatureAvailable && tenderId && activeTab === ActivePreviewTab.Outcome && (
          <TenderPreviewOutcome tenderId={tenderId} />
        )}
      </div>
    </>
  );
};

export default BidPreviewContent;
