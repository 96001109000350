import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileWord,
  faFilePdf,
  faFileExcel,
  faFileImage,
  faFileAlt,
  faFileZipper,
  faFileCode,
  faFileCsv
} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FileExt } from 'src/models/file/types';

export interface IFileTypeIcon {
  fileTypes: string[];
  icon: JSX.Element;
}

const fileTypeIcon: IFileTypeIcon[] = [
  { fileTypes: [FileExt.doc, FileExt.docx], icon: <FontAwesomeIcon icon={faFileWord} /> },
  { fileTypes: [FileExt.xls, FileExt.xlsx], icon: <FontAwesomeIcon icon={faFileExcel} /> },
  { fileTypes: [FileExt.pdf], icon: <FontAwesomeIcon icon={faFilePdf} /> },
  { fileTypes: [FileExt.zip, FileExt.rar], icon: <FontAwesomeIcon icon={faFileZipper} /> },
  { fileTypes: [FileExt.md], icon: <FontAwesomeIcon icon={faFileCode} /> },
  {
    fileTypes: [FileExt.jpeg, FileExt.jpg, FileExt.png, FileExt.gif, FileExt.tiff, FileExt.eps, FileExt.svg],
    icon: <FontAwesomeIcon icon={faFileImage} />
  },
  { fileTypes: [FileExt.csv], icon: <FontAwesomeIcon icon={faFileCsv} /> }
];
export const getIconByFileType = (fileType?: string, emptyIcon?: IconDefinition): JSX.Element => {
  const emptyFileIcon = <FontAwesomeIcon icon={emptyIcon || faFileAlt} />;

  if (!fileType) {
    return emptyFileIcon;
  }
  const icon = fileTypeIcon.find(({ fileTypes }) => fileTypes.includes(fileType.toLowerCase()));

  return icon?.icon || emptyFileIcon;
};
