import { ReactiveVar, useReactiveVar } from '@apollo/client';

export type ReactiveVariable<T> = [T, ReactiveVar<T>];

export const useReactiveVariable = <T>(reactiveVar: ReactiveVar<T>): ReactiveVariable<T> => {
  const value = useReactiveVar?.(reactiveVar);

  return [value, reactiveVar];
};

export default useReactiveVariable;
