import { gql } from '@apollo/client';

export const ORGANIZATIONS_FIELD = gql`
  fragment organizationsFields on Organisation {
    organisationName
    organisationNumber
  }
`;

export const GET_ORGS = gql`
  query getOrgs($resultOffset: Float, $resultLimit: Float, $searchPhrase: String!) {
    findOrganisationsByNameOrId(resultOffset: $resultOffset, resultLimit: $resultLimit, searchPhrase: $searchPhrase) {
      ...organizationsFields
    }
  }
  ${ORGANIZATIONS_FIELD}
`;

export const GET_ORGANIZATIONS_BY_IDS = gql`
  query getOrganisationsByIds($organisationIds: [String!]!, $resultLimit: Float) {
    getOrganisationsByIds(organisationIds: $organisationIds, resultLimit: $resultLimit) {
      ...organizationsFields
    }
  }
  ${ORGANIZATIONS_FIELD}
`;
