import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import Skeleton from '../Skeleton';

interface Props<T> {
  items?: T[];
  children: (item: T, index: number) => ReactNode;
  loading?: boolean;
  bordered?: true;
  className?: string;
  emptyState?: ReactNode;
}

export const List = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const { items, children, loading, bordered, className, emptyState } = props;

  if (!items?.length && emptyState) {
    return <>{emptyState}</>;
  }

  return (
    <ul className={classNames(styles.listBox, { [styles.bordered]: bordered, [className ?? '']: !!className })}>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {items?.map((item, index) => (
            <li key={index} className={styles.listItem}>
              {children(item, index)}
            </li>
          ))}
        </>
      )}
    </ul>
  );
};

export default List;
