import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWebhook } from '@fortawesome/pro-light-svg-icons';
import { QuickActionsDivider } from 'src/shared';
import { CreateBidWebhookStatus, IApiWebhook } from 'src/models/company/Webhooks/types';
import { useCreateWebhookStatus, useSendCreateBidWebhook } from 'src/models/company/Webhooks/hooks';

interface Props {
  webhook: IApiWebhook;
  bidId: string;
  onClose: () => void;
}

const SetStatusOverlay: FC<Props> = props => {
  const { bidId, webhook, onClose } = props;
  const { t } = useTranslation();
  const [sendCreateBidWebhook] = useSendCreateBidWebhook();
  const [createWebhookStatus] = useCreateWebhookStatus();

  const onSetStatus = useCallback(
    (status: CreateBidWebhookStatus) => {
      createWebhookStatus({
        eventType: webhook.eventType,
        relatedEntityId: bidId,
        status
      });
      onClose();
    },
    [bidId, createWebhookStatus, onClose, webhook.eventType]
  );

  const onResend = useCallback(() => {
    sendCreateBidWebhook({
      variables: {
        bidId
      }
    });
    onClose();
  }, [bidId, onClose, sendCreateBidWebhook]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ul className={styles.statusActions}>
          <li className={styles.statusAction}>
            <Button
              icon={<FontAwesomeIcon icon={faWebhook} />}
              className={styles.success}
              type={'text'}
              onClick={() => onSetStatus(CreateBidWebhookStatus.Success)}
            >
              {t('BidSpaces.Webhooks.setSuccessStatus')}
            </Button>
          </li>
          <li className={styles.statusAction}>
            <Button
              icon={<FontAwesomeIcon icon={faWebhook} />}
              className={styles.unknown}
              type={'text'}
              value={CreateBidWebhookStatus.Unknown}
              onClick={() => onSetStatus(CreateBidWebhookStatus.Unknown)}
            >
              {t('BidSpaces.Webhooks.setUnknownStatus')}
            </Button>
          </li>
          <li className={styles.statusAction}>
            <Button
              icon={<FontAwesomeIcon icon={faWebhook} />}
              className={styles.failed}
              type={'text'}
              value={CreateBidWebhookStatus.Failed}
              onClick={() => onSetStatus(CreateBidWebhookStatus.Failed)}
            >
              {t('BidSpaces.Webhooks.setFailedStatus')}
            </Button>
          </li>
          <QuickActionsDivider />
          <li className={styles.statusAction}>
            <Button
              icon={<FontAwesomeIcon icon={faWebhook} />}
              className={styles.resend}
              type={'text'}
              onClick={onResend}
            >
              {t('BidSpaces.Webhooks.resend')}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SetStatusOverlay;
