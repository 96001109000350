import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faQuestion } from '@fortawesome/pro-light-svg-icons';
import UserButton from '../UserButton';
import Skeleton from 'src/common/Skeleton';
import classNames from 'classnames';
import styles from './index.module.scss';
import dropDownOverlayStyles from 'src/shared/DropDownWithSearch/index.module.scss';
import UserGroups from '../UserGroups';
import SearchInput from '../SearchInput';
import { useUserSearch } from '../hooks';
import { AssignedTo } from 'src/models/users/types';
import Assignee from 'src/shared/Assignee';
export interface UserPickerOverlayProps {
  users: AssignedTo[];
  usersLoading?: boolean;
  selected?: AssignedTo | null;
  children?: ReactNode;
  onSelect: (user: AssignedTo) => void;
  onUnSelect?: () => void;
  searchable?: boolean;
}

export const UserPickerOverlay: FC<UserPickerOverlayProps> = ({
  selected,
  onSelect,
  onUnSelect,
  users: initialUsers,
  usersLoading,
  children,
  searchable
}) => {
  const { t } = useTranslation();
  const { data } = useUserSearch(initialUsers);
  const { users } = data;

  return (
    <div className={classNames(dropDownOverlayStyles.menu, { [styles.searchable]: !!searchable })}>
      <div className={dropDownOverlayStyles.menuInner}>
        {selected && !!onUnSelect && (
          <div className={styles.currentUser}>
            <Assignee assignedToData={selected} className={styles.currentUserName} size={'s'} />
            <button className={classNames(styles.button, styles.unAssignButton)} onClick={onUnSelect}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
        {!!searchable && (
          <div className={dropDownOverlayStyles.search}>
            <SearchInput users={initialUsers} />
          </div>
        )}
        <div className={styles.container}>
          {usersLoading ? (
            <Skeleton loading active />
          ) : (
            <>
              {!!users.length ? (
                <UserGroups
                  users={users}
                  wrapper={user => <UserButton key={user.id} user={user} onClick={onSelect} />}
                />
              ) : (
                <div className={dropDownOverlayStyles.searchEmpty}>
                  <span className={dropDownOverlayStyles.searchEmptyPic}>
                    <FontAwesomeIcon icon={faQuestion} className={dropDownOverlayStyles.searchEmptyIcon} />
                  </span>
                  <span className={dropDownOverlayStyles.searchEmptyLabel}>{t('Common.noMatchesFound')}</span>
                </div>
              )}
            </>
          )}
        </div>
        {!!children && children}
      </div>
    </div>
  );
};

export default UserPickerOverlay;
