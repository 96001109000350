import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/pro-light-svg-icons';
import Skeleton from 'src/common/Skeleton';
import { AssignedTo } from 'src/models/users/types';
import { Checkbox } from 'src/common';
import styles from './index.module.scss';
import dropDownOverlayStyles from 'src/shared/DropDownWithSearch/index.module.scss';
import UserGroups from '../UserGroups';
import SearchInput from '../SearchInput';
import { useUserSearch } from '../hooks';
import classNames from 'classnames';

export interface MultipleUserPickerOverlayProps {
  users: AssignedTo[];
  usersLoading?: boolean;
  selected?: string[];
  onSelect: (user: AssignedTo, checked: boolean) => void;
  searchable?: boolean;
}

export const MultipleUserPickerOverlay: FC<MultipleUserPickerOverlayProps> = ({
  selected,
  users: initialUsers,
  usersLoading,
  onSelect,
  searchable
}) => {
  const { t } = useTranslation();
  const { data } = useUserSearch(initialUsers);
  const { users } = data;

  return (
    <div className={classNames(styles.overlay, { [styles.searchable]: !!searchable })}>
      {!!searchable && (
        <div className={dropDownOverlayStyles.search}>
          <SearchInput users={initialUsers} />
        </div>
      )}
      <div className={styles.container}>
        {usersLoading ? (
          <Skeleton loading active />
        ) : (
          <>
            {!!users.length ? (
              <UserGroups
                users={users}
                wrapper={(user, children) => (
                  <Checkbox
                    key={user.id}
                    checked={selected?.includes(user.id)}
                    onChange={e => onSelect(user, e.target.checked)}
                    className={styles.checkbox}
                  >
                    {children}
                  </Checkbox>
                )}
              />
            ) : (
              <div className={styles.searchEmpty}>
                <span className={dropDownOverlayStyles.searchEmptyPic}>
                  <FontAwesomeIcon icon={faQuestion} />
                </span>
                <span>{t('Common.noMatchesFound')}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default MultipleUserPickerOverlay;
