import React, { FC } from 'react';
import classNames from 'classnames';
import { MentionsMenuOption } from './MentionMenuOption';
import Assignee from 'src/shared/Assignee';
import styles from '../index.module.scss';

interface Props {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionsMenuOption;
}

const MentionsMenuItem: FC<Props> = ({ index, isSelected, onClick, onMouseEnter, option }) => {
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={classNames(styles.mentionDropdownItem, { selected: isSelected })}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <div className={styles.mentionDropdownItemInner}>
        <Assignee assignedToData={option.user} size={'s'} className={styles.mentionDropdownUser} />
      </div>
    </li>
  );
};

export default MentionsMenuItem;
