import { MutationTuple, QueryResult } from '@apollo/client/react/types/types';
import { ApolloError, MutationUpdaterFn, NetworkStatus, useMutation, useQuery } from '@apollo/client';
import {
  ARCHIVE_INBOX,
  GENERATE_INBOX,
  GET_INBOX_EMAIL,
  GET_INBOX_EMAILS,
  GET_INBOXES,
  GET_BASE_INFO_OF_INBOXES,
  MARK_INBOX_EMAIL_AS_READ,
  MARK_INBOX_EMAIL_AS_UNREAD,
  UPDATE_INBOX,
  UPDATE_INBOX_EMAIL_STATUS,
  UPDATE_INBOX_NAME,
  GET_INBOX_TOTAL
} from './queries';
import { splitByQuotation } from 'src/helpers';
import { useParams } from 'react-router-dom';
import {
  EmailStatus,
  IInbox,
  IBaseInbox,
  IInboxEmail,
  INextCallOff,
  IApiInbox,
  IApiBaseInbox,
  ApiCallOffsVars,
  CallOffsVars,
  CallOffsSearchParams,
  ApiCallOffsData
} from './types';
import { useCallback, useContext, useMemo, useState } from 'react';
import { updateQueryParams, useQueryParams } from 'src/helpers/history';
import { useCloseSidebar, useCurrentSidebar, useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { CallOffsContext } from './context';
import { notification } from 'src/common';
import { useTranslation } from 'react-i18next';
import { AccessControlPolicy } from '../acl/types';
import { CallOffsData, BaseInbox, Inbox } from '.';
import { toInboxEmail } from './helpers';
import { useAllUsersAndTeams } from '../users/hooks';

export type UseCallOffs = [
  Pick<QueryResult<ApiCallOffsData, ApiCallOffsVars>, 'fetchMore' | 'loading' | 'error'> & {
    data?: CallOffsData;
    currentInbox?: IInbox;
    fetchingMore: boolean;
    inboxLoading: boolean;
    vars: CallOffsVars;
    initVars: CallOffsVars;
    apiVars: QueryResult<ApiCallOffsData, ApiCallOffsVars>['variables'];
  },
  (updVars: Partial<CallOffsVars>) => void
];

export function useCallOffs(): UseCallOffs {
  const context = useContext<UseCallOffs | null>(CallOffsContext);
  if (!context) {
    throw new Error('useCallOffs must be used under CallOffsProvider');
  }
  return context;
}

const INITIAL_CALL_OFFS_SEARCH: Partial<CallOffsVars> = {
  size: 10,
  from: 0,
  onlyUnhandled: true
};
const CALL_OFFS_SEARCH_PARAMS: (keyof CallOffsVars)[] = ['onlyUnhandled'];
export function useCallOffsCtx(): UseCallOffs {
  const { inboxId } = useParams<{ inboxId: string }>();
  const qp = useQueryParams();
  const initVars: CallOffsVars = useMemo(() => ({ ...INITIAL_CALL_OFFS_SEARCH, inboxId }), [inboxId]);
  const qpVars: Partial<CallOffsVars> = useMemo(
    () => ({
      // Only set onlyUnhandled to false when getting "onlyUnhandled: false" from url
      // Otherwise it should be default value (onlyUnhandled: true)
      onlyUnhandled:
        qp[CallOffsSearchParams.onlyUnhandled] === false
          ? qp[CallOffsSearchParams.onlyUnhandled]
          : initVars.onlyUnhandled
    }),
    [initVars.onlyUnhandled, qp]
  );
  const [_vars, _updateVars] = useState<CallOffsVars>(initVars);
  const _mappedVars: CallOffsVars = useMemo(() => ({ ...initVars, ..._vars, ...qpVars }), [_vars, initVars, qpVars]);

  const variables: ApiCallOffsVars = useMemo(
    () => ({
      from: _mappedVars.from,
      size: _mappedVars.size,
      statuses: _mappedVars.onlyUnhandled ? [EmailStatus.UNHANDLED] : [],
      keywords: !!_mappedVars.search ? splitByQuotation(_mappedVars.search) : undefined,
      emails: _mappedVars.inboxId ? [toInboxEmail(_mappedVars.inboxId)] : undefined
    }),
    [_mappedVars.from, _mappedVars.size, _mappedVars.onlyUnhandled, _mappedVars.search, _mappedVars.inboxId]
  );

  const query = useQuery<ApiCallOffsData, ApiCallOffsVars>(GET_INBOX_EMAILS, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !_mappedVars.inboxId
  });
  const { data: currentInbox, loading: inboxLoading } = useLoadInbox();

  const updateVarsFn = useCallback((_vars: Partial<CallOffsVars>) => {
    const changedKeys = Object.keys(_vars);
    if (changedKeys.some(key => CALL_OFFS_SEARCH_PARAMS.some(field => field === key))) {
      updateQueryParams(_vars);
    }
    _updateVars(currentVars => ({ ...currentVars, ..._vars }));
  }, []);

  return useMemo(
    () => [
      {
        vars: _mappedVars,
        initVars,
        apiVars: query.variables,
        data: query.data?.getInboxEmails ? new CallOffsData(query.data.getInboxEmails) : undefined,
        currentInbox,
        fetchMore: query.fetchMore,
        fetchingMore: query.networkStatus === NetworkStatus.fetchMore,
        loading:
          (query.loading ||
            query.data === undefined ||
            query.networkStatus === NetworkStatus.refetch ||
            query.networkStatus === NetworkStatus.setVariables) &&
          query.networkStatus !== NetworkStatus.fetchMore,
        inboxLoading
      },
      updateVarsFn
    ],
    [
      _mappedVars,
      initVars,
      query.variables,
      query.data,
      query.fetchMore,
      query.networkStatus,
      query.loading,
      currentInbox,
      inboxLoading,
      updateVarsFn
    ]
  );
}

type ApiInboxesData = { getInboxes: IApiInbox[] };
export function useLoadInboxes(skip?: boolean): Omit<QueryResult<ApiInboxesData>, 'data'> & { data?: IInbox[] } {
  const query = useQuery<ApiInboxesData>(GET_INBOXES, { skip: !!skip });
  const allUsersAndTeams = useAllUsersAndTeams();

  return useMemo(
    () => ({
      ...query,
      data: query.data ? query.data.getInboxes.map(inbox => new Inbox(inbox, allUsersAndTeams)) : undefined
    }),
    [allUsersAndTeams, query]
  );
}

/**
 * @param {string=} [inboxEmail]
 * - inbox email, fallback is `useParams<{ inboxId: string}>`, where `inboxId` is email without domain part
 */
export function useLoadInbox(inboxEmail?: string): { data?: IInbox; loading: boolean } {
  const { inboxId } = useParams<{ inboxId: string }>();
  const { data: inboxes, loading } = useLoadInboxes();
  const id = useMemo(() => inboxEmail ?? inboxId, [inboxEmail, inboxId]);

  return useMemo(
    () => ({ data: id ? inboxes?.find(inbox => inbox.emailAddress.startsWith(id)) : undefined, loading }),
    [id, inboxes, loading]
  );
}

export function useLoadInboxesNav(
  skip?: boolean
): Omit<QueryResult<{ getInboxes: IApiBaseInbox[] }>, 'data'> & { data?: IBaseInbox[] } {
  const query = useQuery<{ getInboxes: IApiBaseInbox[] }>(GET_BASE_INFO_OF_INBOXES, { skip: !!skip });

  return useMemo(
    () => ({ ...query, data: query.data ? query.data.getInboxes.map(inbox => new BaseInbox(inbox)) : undefined }),
    [query]
  );
}

interface IGenerateInboxResponse {
  __typename: 'Mutation';
  generateInbox: IInbox & {
    __typename: 'CompanyInbox';
  };
}

export function useGenerateInbox(): MutationTuple<IGenerateInboxResponse, { inboxName: string }> {
  return useMutation(GENERATE_INBOX);
}

export function getUpdateCacheOnGenerateInbox(): MutationUpdaterFn<IGenerateInboxResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const inboxesQueryData = cache.readQuery<{ getInboxes: IApiInbox[] } | null>({
      query: GET_INBOXES
    });
    if (inboxesQueryData) {
      const { getInboxes } = inboxesQueryData;
      cache.writeQuery({
        query: GET_INBOXES,
        data: { getInboxes: [...getInboxes, data.generateInbox] }
      });
    }
  };
}

export function useLoadInboxEmail(
  id: string | null,
  skip?: boolean
): QueryResult<{ getInboxEmail: { userMails: IInboxEmail[] } }> {
  return useQuery<{ getInboxEmail: { userMails: IInboxEmail[] } }>(GET_INBOX_EMAIL, {
    variables: { ids: [id] },
    skip: !id && !!skip
  });
}
export function useLoadInboxTotal(): QueryResult<ApiCallOffsData, ApiCallOffsVars> {
  const { inboxId } = useParams<{ inboxId: string }>();
  return useQuery<ApiCallOffsData, ApiCallOffsVars>(GET_INBOX_TOTAL, {
    variables: { emails: inboxId ? [toInboxEmail(inboxId)] : undefined },
    skip: !inboxId
  });
}

interface IUpdateInbox {
  __typename: 'Mutation';
  updateInbox: IInbox & {
    __typename: 'CompanyInbox';
  };
}

export function useUpdateInboxName(): MutationTuple<IUpdateInbox, { inboxName: string; inboxId: string }> {
  return useMutation(UPDATE_INBOX_NAME);
}

export function useActiveInboxEmail(): { data?: IInboxEmail; loading: boolean; error?: ApolloError } {
  const sidebarState = useCurrentSidebar();
  const {
    data: inboxEmailData,
    loading,
    error
  } = useLoadInboxEmail(sidebarState && sidebarState.id, sidebarState?.mode === SidebarMode.CALL_OFF_INFO);
  return useMemo(
    () => ({
      data:
        inboxEmailData && inboxEmailData.getInboxEmail.userMails
          ? inboxEmailData.getInboxEmail.userMails[0]
          : undefined,
      loading,
      error
    }),
    [error, inboxEmailData, loading]
  );
}
interface IUpdateInboxEmailStatusResponse {
  __typename: 'Mutation';
  setInboxMailsStatus: IInboxEmail[] & {
    __typename: 'UserMail';
  };
}

export function useUpdateInboxEmailStatus(): [
  (mailIds: string[], status: EmailStatus) => void,
  { loading: boolean; error: Error | undefined }
] {
  const [updateInboxEmailsStatus, { loading, error }] = useMutation<
    IUpdateInboxEmailStatusResponse,
    { mailIds: string[]; status: EmailStatus }
  >(UPDATE_INBOX_EMAIL_STATUS);
  const [{ currentInbox }] = useCallOffs();
  const { t } = useTranslation();

  const updateInboxEmailsStatusFn = useCallback(
    (mailIds: string[], status: EmailStatus) => {
      if (currentInbox) {
        updateInboxEmailsStatus({
          variables: { mailIds, status }
        }).catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
      }
    },
    [currentInbox, t, updateInboxEmailsStatus]
  );
  return [updateInboxEmailsStatusFn, { loading, error }];
}

interface IMarkInboxEmailAsRead {
  __typename: 'Mutation';
  setInboxMailsRead: IInboxEmail & {
    __typename: 'UserMail';
  };
}

export function useMarkInboxEmailsAsRead(): MutationTuple<IMarkInboxEmailAsRead, { mailIds: string[] }> {
  return useMutation(MARK_INBOX_EMAIL_AS_READ);
}
export function useMarkInboxEmailsAsUnread(): MutationTuple<IMarkInboxEmailAsRead, { mailIds: string[] }> {
  return useMutation(MARK_INBOX_EMAIL_AS_UNREAD);
}

interface IArchiveInboxResult {
  __typename: 'Mutation';
  updateArchived: IInbox & {
    __typename: 'CompanyInbox';
  };
}

export function useArchiveInbox(): MutationTuple<IArchiveInboxResult, { id: string }> {
  return useMutation<IArchiveInboxResult, { id: string }>(ARCHIVE_INBOX);
}

export function useUpdateInbox(): MutationTuple<
  IUpdateInbox,
  {
    inboxId: string;
    subscribers?: string[];
    owners?: string[];
    subscribePolicy?: AccessControlPolicy;
    editPolicy?: AccessControlPolicy;
  }
> {
  return useMutation(UPDATE_INBOX);
}

export function useOpenCallOffPreview(): (callOff?: INextCallOff) => void {
  const onCloseSidebar = useCloseSidebar();
  const onOpenSideBar = useOpenSidebar();
  const [markInboxEmailsAsRead] = useMarkInboxEmailsAsRead();

  return useCallback(
    (callOff?: INextCallOff) => {
      if (!!callOff) {
        const { id, isRead } = callOff;
        onOpenSideBar({
          id: id,
          mode: SidebarMode.CALL_OFF_INFO
        });
        !isRead && markInboxEmailsAsRead({ variables: { mailIds: [id] } }).catch(err => console.error(err));
      } else {
        onCloseSidebar();
      }
    },
    [markInboxEmailsAsRead, onCloseSidebar, onOpenSideBar]
  );
}
