import { createContext } from 'react';

export type UseNavSidebar = [boolean, () => void];
export const NavSidebarContext = createContext<UseNavSidebar>([
  true,
  () => {
    /* empty fn */
  }
]);
NavSidebarContext.displayName = 'NavSidebarContext';
