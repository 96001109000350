export enum SidebarMode {
  BID_INFO = 'BID_INFO',
  BID_COMMENT = 'BID_COMMENT',
  DOC_VIEWER = 'DOC_VIEWER',
  DOC_LIBRARY = 'DOC_LIBRARY',
  MP_INFO = 'MP_INFO',
  PROCUREMENT_INFO = 'PROCUREMENT_INFO',
  PROCUREMENT_DOCS = 'PROCUREMENT_DOCS',
  TASK_INFO = 'TASK_INFO',
  WORKSPACE_INFO = 'WORKSPACE_INFO',
  INBOX_INFO = 'INBOX_INFO',
  CALL_OFF_INFO = 'CALL_OFF_INFO',
  COMMENTS = 'COMMENTS',
  EC_INFO = 'EC_INFO',
  CONTENT_LIBRARY = 'CONTENT_LIBRARY',
  CONTENT_LIBRARY_SETTINGS = 'CONTENT_LIBRARY_SETTINGS',
  TASK_DRAFT = 'TASK_DRAFT'
}
