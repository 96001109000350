import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IBoxFieldDate } from 'src/models/procurements/Tender/types';
import styles from './index.module.scss';
import BlockEmpty from '../../Empty';
import { useDayCount } from 'src/common';
import BlockKeyValue from '../../KeyValue';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';
import { DateFormatTranslation } from 'src/lib/I18nService/types';

interface IProps extends IBlockReadProps, Pick<IBoxFieldDate, 'date' | 'time'> {}
export const ReadOnlyBlockDate: FC<IProps> = ({ date, time, className, config }) => {
  const { t } = useTranslation();
  const countDown = useDayCount(date ?? null);

  return date ? (
    config?.date?.isCountdown ? (
      <BlockKeyValue
        name={countDown.label ?? countDown.value}
        value={countDown.label ? `(${countDown.value})` : undefined}
      />
    ) : (
      <div className={className}>
        <span className={styles.block}>{`${t(config?.date?.dateFormat || DateFormatTranslation.full, {
          date: new Date(date)
        })} ${time ?? ''}`}</span>
      </div>
    )
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBlockDate;
