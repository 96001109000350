import { isFieldDate, isFieldString, ITenderBox } from 'src/models/procurements/Tender/types';
import { IBidIdentifier } from './types';
import { BidItemType, TenderBoxFieldType, WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { isString } from 'src/helpers';
import { ApolloCache } from '@apollo/client';

export function groupBidsByWs(bids: IBidIdentifier[]): { [wsId: string]: string[] } {
  return bids.reduce(function (groups, item) {
    groups[item.wsId] = groups[item.wsId] || [];
    groups[item.wsId].push(item.id);
    return groups;
  }, {} as { [wsId: string]: string[] });
}

export function datesSorter(a?: number | null, b?: number | null): number {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  return a - b;
}

export function stringsSorter(a?: string | null, b?: string | null): number {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  return a && b ? a.localeCompare(b, 'sv') : -1;
}

export function numbersSorter(a?: number | null, b?: number | null): number {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  return a - b;
}

export function customBidFieldSorter(a?: ITenderBox, b?: ITenderBox): number {
  if (!a || a.isEmpty) {
    return 1;
  }
  if (!b || b.isEmpty) {
    return -1;
  }

  if (isFieldString(a.fields[0]) && isFieldString(b.fields[0])) {
    return stringsSorter(a.fields[0].string, b.fields[0].string);
  }
  // else if (isFieldNumber(a.fields[0]) && isFieldNumber(b.fields[0])) {
  //   return numbersSorter(a.fields[0].number, b.fields[0].number); // FIXME
  // }
  else if (isFieldDate(a.fields[0]) && isFieldDate(b.fields[0])) {
    return datesSorter(a.fields[0].date, b.fields[0].date);
  }
  return 1;
}
const sortableFieldsTypes = [TenderBoxFieldType.Date, TenderBoxFieldType.String];

export function isSortableField(fieldType: TenderBoxFieldType): boolean {
  return sortableFieldsTypes.some(type => fieldType === type);
}

export function isWorkspaceStatusCategory(u: unknown): u is WorkspaceStatusCategory {
  return isString(u) && Object.values(WorkspaceStatusCategory).some(c => c === u);
}

export function isWorkspaceStatusCategories(u: unknown): u is WorkspaceStatusCategory[] {
  return Array.isArray(u) && u.some(item => isWorkspaceStatusCategory(item));
}

export function isBidItemType(u: unknown): u is BidItemType {
  return isString(u) && Object.values(BidItemType).some(c => c === u);
}

export function isBidItemTypes(u: unknown): u is BidItemType[] {
  return Array.isArray(u) && u.some(item => isBidItemType(item));
}

export const evictBidStatisticsCache = <T = unknown>(cache: ApolloCache<T>): void => {
  cache.evict({ fieldName: 'getBidCategoryStatistics' });
  cache.gc();
};
