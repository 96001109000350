import dayjs from 'src/helpers/dayjs';
import { isMonthWithShortName } from 'src/helpers/dates';
import { useTranslation } from 'react-i18next';

export function usePublishedDate(date: number | null): string {
  const { t } = useTranslation();

  if (!date) {
    return t('Common.notSet');
  }
  const publishedDate = new Date(date);

  const currentYear = dayjs().year();
  const publishedYear = dayjs(publishedDate).year();

  let value = '';
  if (currentYear === publishedYear) {
    value = dayjs(publishedDate).isToday()
      ? t('Common.today')
      : t('Common.Format.tableDateFormatThisYear', { date: publishedDate });
  } else {
    const keyForTranslator = isMonthWithShortName(publishedDate)
      ? 'Common.Format.tableDateFormatForShortMonths'
      : 'Common.Format.tableDateFormat';
    value = t(keyForTranslator, { date: publishedDate });
  }
  return value;
}

export default usePublishedDate;
