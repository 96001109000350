import React, { FC, useCallback, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import { Form, Input, Select, Tooltip, Button } from 'src/common';
import { IBoxFieldCurrency } from 'src/models/procurements/Tender/types';
import { CURRENCIES } from 'src/helpers/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import ValueActions from '../../ValueActions';
import classNames from 'classnames';
import { useClickAway } from 'react-use';

interface FormData {
  amount: number;
  currency: string | null;
}

export interface Props extends IBlockEditProps<IBoxFieldCurrency> {}

export const EditableBlockCurrency: FC<Props> = ({ field, onChange, onFinish, className }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const ref = useRef<HTMLDivElement>(null);
  const { id: fieldId, amount, currency } = field;

  const onSubmit = useCallback(() => {
    const value: FormData = form.getFieldValue(fieldId);
    Number(value.amount) !== field.amount || value.currency !== field.currency
      ? onChange && onChange(field.update(Number(value.amount), value.currency ?? null))
      : onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const onChangeCurrency = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        amount,
        currency
      }
    };
  }, [amount, currency, fieldId]);

  const onCancel = useCallback(() => {
    form.resetFields();
    onFinish && onFinish();
  }, [form, onFinish]);

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    onSubmit();
  });

  return (
    <div ref={ref} className={className}>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        className={classNames(styles.container, styles.fields)}
      >
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'amount']}>
            <Input
              type={'number'}
              onPressEnter={onSubmit}
              placeholder={t('Common.Blocks.amount')}
              className={styles.control}
            />
          </Form.Item>
          <ValueActions onCancel={onCancel} />
        </div>
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'currency']}>
            <Select
              showSearch
              optionFilterProp={'value'}
              onChange={onChangeCurrency}
              getPopupContainer={trigger => trigger.parentNode}
              className={styles.control}
              dropdownClassName={styles.select}
              listHeight={125}
              filterOption={(input, option) =>
                !!option && !!option.value && option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={t('Common.Blocks.currency')}
              allowClear
              clearIcon={
                <Tooltip title={t('Common.Blocks.deleteUnit')}>
                  <Button type={'text'} icon={<FontAwesomeIcon icon={faTimes} />} />
                </Tooltip>
              }
            >
              {CURRENCIES.map(c => (
                <Select.Option key={c.code} value={c.code}>
                  <span className={styles.currencySymbol}>{c.symbol_native}</span>
                  <span className={styles.currencyCode}>{c.code}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EditableBlockCurrency;
