import { FieldPolicy } from '@apollo/client';
import { isNotUndefined } from 'src/helpers';
import { AdminUsersCached, AdminUsersDataCached } from './types';

export function getAdminUsersPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['searchOptions', ['companyId', 'email'], 'sortBy']],
    read(existing: AdminUsersDataCached) {
      if (!existing) {
        return;
      }
      if (existing) {
        return existing.value;
      }
    },
    merge(existing: AdminUsersDataCached, incoming: AdminUsersCached, { variables, readField, canRead, isReference }) {
      if (!existing) {
        return { value: incoming, variables };
      }
      if (incoming?.users.length === 0) {
        return { value: { ...existing.value, count: incoming.count }, variables };
      }
      if (!!incoming?.users.length) {
        const merged =
          existing && !!existing.value.users.length
            ? [...existing.value.users, ...incoming.users.filter(user => canRead(user) && isReference(user))].filter(
                isNotUndefined
              )
            : [];
        return {
          value: {
            ...incoming,
            users: [...new Map(merged.map(user => [readField({ fieldName: 'email', from: user }), user])).values()]
          },
          variables
        };
      }
    }
  };
}
