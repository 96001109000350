import { gql } from '@apollo/client';
import { SIMPLE_TENDER_FIELDS } from '../Preview/query';
import { BASE_BOX_FIELDS, BOX_FIELDS_FIELD, LOT_FIELDS } from '../queries';

export const GET_SIMPLE_TENDERS = gql`
  query getSimpleTenders(
    $advanced: AdvancedSearchDTO
    $sortOnField: SortOnFieldInputObject
    $amount: Float
    $offset: Float
    $matchingProfileId: String
    $isStarred: Boolean
    $isUnhandled: Boolean
  ) {
    getSimpleTenders(
      advanced: $advanced
      query: {
        amount: $amount
        offset: $offset
        sortOnField: $sortOnField
        matchingProfileId: $matchingProfileId
        isStarred: $isStarred
        isUnhandled: $isUnhandled
      }
    ) {
      fetchedTendersCount @client
      count
      procurementsWithScore {
        highlights {
          context
          values
          targetPath
        }
        matchedCpvs
        procurement {
          ...simpleTenderFields
        }
        score
      }
    }
  }
  ${SIMPLE_TENDER_FIELDS}
`;

const BOX_CONTRACT_FIELDS = gql`
  fragment boxContractFields on TenderBox {
    ...baseBoxFields
    ...boxFieldsField
    lots {
      ...lotFields
    }
  }
  ${BASE_BOX_FIELDS}
  ${BOX_FIELDS_FIELD}
  ${LOT_FIELDS}
`;
const EXPIRING_CONTRACT_FIELDS = gql`
  fragment expiringContractFields on Tender {
    id
    lots {
      ...lotFields
    }
    general {
      id
      name {
        ...boxContractFields
      }
      buyerBoxes {
        ...boxContractFields
      }
      contractDurationBoxes {
        id
        lots {
          ...lotFields
        }
      }
      contractValueBoxes {
        ...boxContractFields
      }
    }
    expiringContract {
      contractStart
      contractEnd
      extensionEndDates
      hasLots
    }
    isRead
    bids {
      id
      itemId
      workspace {
        id
      }
      status {
        id
      }
    }
    rejected(matchingProfileId: $matchingProfileId)
  }
  ${BOX_CONTRACT_FIELDS}
  ${LOT_FIELDS}
`;

export const GET_EXPIRING_CONTRACTS = gql`
  query getExpiringContracts(
    $advanced: AdvancedSearchDTO
    $sortOnField: SortOnFieldInputObject
    $amount: Float
    $offset: Float
    $matchingProfileId: String
    $isStarred: Boolean
    $isUnhandled: Boolean
  ) {
    getTenders(
      advanced: $advanced
      query: {
        amount: $amount
        offset: $offset
        sortOnField: $sortOnField
        matchingProfileId: $matchingProfileId
        isStarred: $isStarred
        isUnhandled: $isUnhandled
      }
    ) {
      count
      procurementsWithScore {
        highlights {
          context
          values
          targetPath
        }
        matchedCpvs
        procurement {
          ...expiringContractFields
        }
      }
    }
  }
  ${EXPIRING_CONTRACT_FIELDS}
`;
