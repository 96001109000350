import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { Config } from 'src/config/config';
import './rum';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

import.meta.env.MODE !== 'development' &&
  Sentry.init({
    enabled: !!Config.MonitoringKey,
    dsn: `https://${Config.MonitoringKey}.ingest.sentry.io/6198442`,
    environment:
      import.meta.env.MODE === 'production'
        ? Config.PrivateApi.includes('prod.')
          ? 'production'
          : 'staging'
        : 'development',
    integrations: [
      new BrowserTracing({
        tracingOrigins: [Config.PrivateApi],
        beforeNavigate: context => {
          return {
            ...context,
            //FIXME: could be more exact match to actual router instead of characteristics in url
            name: context.name.replace(/\/[a-f0-9]{24}/g, '/{id}').replace(/\/[a-f0-9-]{36}/g, '/{id}')
          };
        },
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });

ReactGA.initialize(Config.Code);
ReactGA.pageview(window.location.pathname + window.location.search);
