import { gql } from '@apollo/client/core';
import { CHECKLIST_TEMPLATE_FIELDS } from './fragments';
import { CHECKLIST_FIELDS } from '../BidChecklist/fragments';

export const GET_CHECKLIST_TEMPLATES = gql`
  query getChecklistTemplates {
    getChecklistTemplates {
      ...checklistTemplateFields
    }
  }
  ${CHECKLIST_TEMPLATE_FIELDS}
`;

export const APPLY_CHECKLIST_TEMPLATE = gql`
  mutation applyChecklistTemplate($id: String!, $bidId: String!) {
    applyChecklistTemplate(input: { id: $id, bidId: $bidId }) {
      ...checklistFields
    }
  }
  ${CHECKLIST_FIELDS}
`;

export const CREATE_CHECKLIST_TEMPLATE = gql`
  mutation createChecklistTemplate($bidId: String!, $name: String!) {
    createChecklistTemplate(input: { bidId: $bidId, name: $name }) {
      ...checklistTemplateFields
    }
  }
  ${CHECKLIST_TEMPLATE_FIELDS}
`;

export const DELETE_CHECKLIST_TEMPLATE = gql`
  mutation deleteChecklistTemplate($id: String!) {
    deleteChecklistTemplate(input: { id: $id })
  }
`;
