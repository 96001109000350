import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'src/common/Badge';
import styles from './index.module.scss';
import { trackClickOnNotificationsList } from 'src/segment/events';
import Dropdown from 'src/common/Dropdown';
import HeaderAction from '../HeaderAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/pro-solid-svg-icons';
import {
  useMarkNotificationsAsSeen,
  useLoadNotifications,
  useNewNotificationSubscriptions
} from 'src/models/notifications/hooks';
import NotificationsMenuContent from './NotificationsMenuContent';

export const NotificationsMenu: FC = () => {
  const { t } = useTranslation();

  const [menuVisible, setMenuVisible] = useState(false);
  const [markAllNotificationsAsSeen] = useMarkNotificationsAsSeen();
  const { data } = useLoadNotifications();
  useNewNotificationSubscriptions();

  const toggleMenu = useCallback(() => {
    setMenuVisible(vis => {
      if (!vis) {
        !!data?.unseenCount && markAllNotificationsAsSeen();
        trackClickOnNotificationsList();
      }
      return !vis;
    });
  }, [data, markAllNotificationsAsSeen]);

  return (
    <Dropdown
      trigger={['click']}
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      visible={menuVisible}
      onVisibleChange={toggleMenu}
      overlay={
        <div className={styles.menu}>
          <div className={styles.header}>
            <h4>{t('Notifications.notifications')}</h4>
          </div>
          <NotificationsMenuContent />
        </div>
      }
    >
      <HeaderAction>
        <Badge count={data && (menuVisible ? 0 : data.unseenCount)} overflowCount={9} className={styles.iconBadge}>
          <FontAwesomeIcon data-action="toggle" data-name="notification-button" icon={faInbox} />
        </Badge>
      </HeaderAction>
    </Dropdown>
  );
};

export default NotificationsMenu;
