import { useEffect } from 'react';
import { FeatureFlag, useFeatureFlag } from './featureFlag';
import { Config } from '../config/config';
import { useApp, isPaidUser } from 'src/models/auth';

interface ProductVoiceWindow extends Window {
  productvoiceSettings?: {
    project_id: string;
    name?: string;
    email?: string;
    widget_selector?: string;
  };
}

let isProductVoiceLoaded = false;

export const PRODUCT_VOICE_BUTTON_ID = 'productfeed-trigger';

export const useProductVoice = (): void => {
  const isProductVoiceFeature = useFeatureFlag(FeatureFlag.ProductVoice);
  const { user } = useApp();
  // Dynamically load the script for Product voice when we render this component
  useEffect(() => {
    if (
      isProductVoiceFeature &&
      isPaidUser(user) &&
      import.meta.env.MODE !== 'development' &&
      !isProductVoiceLoaded &&
      Config.ProductVoiceId
    ) {
      const productVoiceWindow: ProductVoiceWindow = window;
      productVoiceWindow.productvoiceSettings = {
        project_id: Config.ProductVoiceId,
        name: user.userName,
        email: user.email,
        widget_selector: '#' + PRODUCT_VOICE_BUTTON_ID
      };

      const script = document.createElement('script');
      // We don't want to render the button related to this script until we have loaded the script
      // Otherwise the button won't work immediately
      script.async = false;
      script.id = Config.ProductVoiceId;
      script.src = `https://app.productvoice.io/widget/${Config.ProductVoiceId}.js`;

      document.body.appendChild(script);
      isProductVoiceLoaded = true;
    }
  }, [isProductVoiceFeature, user]);
};

export default useProductVoice;
