import { Reference } from '@apollo/client';
import { IUserName, IGuestUser } from '../users/types';

export type ICommentUser = IUserName | IGuestUser;

export interface IApiComment {
  id: string;
  parentId: string;
  parentType: CommentParentType;
  content: string;
  createdAt: Date;
  user: ICommentUser | null;
  isRead: boolean;
  sharedWith: IGuestUser[];
}

export enum CommentParentType {
  Procurement = 'Procurement',
  ProcurementBox = 'ProcurementBox',
  ProcurementBid = 'ProcurementBid',
  BidTask = 'ProcurementBidTask',
  BidQuestion = 'ProcurementBidQuestion',
  CallOffBid = 'CallOffBid',
  Tender = 'Tender',
  TenderBox = 'TenderBox',
  ManualBid = 'ManualBid'
}

export interface ICreateCommentResponse {
  __typename: 'Mutation';
  createProcurementComment: IApiComment & {
    __typename: 'ProcurementComment';
    user: {
      __typename: 'User';
    };
  };
}
export interface ICreateCommentVariables {
  id?: string;
  content: string;
  parentId: string;
  mentions: string[];
  previousMentions: string[];
  shareWith: string[];
  parentType: CommentParentType;
}

export interface IUpdateCommentResponse {
  __typename: 'Mutation';
  markProcurementCommentAsRead: IApiComment & {
    __typename: 'ProcurementComment';
    user: {
      __typename: 'User';
    };
  };
}
export interface CommentsCount {
  parentId: string;
  count: number;
}
export type ApiCommentsCountVars = {
  ids: string[];
  parentType: CommentParentType;
};
export interface ApiCommentsCountData {
  getCommentsCount: CommentsCount[];
}
export type CommentsCountCached = {
  value: Reference[];
  variables?: Pick<ApiCommentsCountVars, 'parentType'>;
};
export type ApiParentCommentsData = {
  getComments: IApiComment[];
};
export type ApiParentCommentsVars = {
  parentId: string;
  parentType: CommentParentType;
};
export type ParentCommentsCached = {
  value: Reference[];
  variables?: ApiParentCommentsVars;
};
