import React, { FC } from 'react';
import { ThemeMode, LogoProps } from 'src/models/ownership';

export const TendiumLogoSVG: FC<LogoProps> = ({ mode = ThemeMode.light, isShort, className }) => {
  return isShort ? (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 40 32" className={className}>
      <path
        fill={'#161052'}
        d="M9.139 6.937c-0.397-0.63-0.794-0.752-1.473-0.752h-6.659c-0.556 0-1.007-0.452-1.007-1.010v-4.166c0-0.558 0.451-1.010 1.007-1.010h24.521c0.556 0 1.007 0.452 1.007 1.010v4.166c0 0.558-0.451 1.010-1.007 1.010h-6.795c-0.735 0-1.022 0.469-0.639 1.109l12.171 18.076c0.187 0.278 0.454 0.445 1.057 0.445h7.284c0.556 0 1.007 0.452 1.007 1.010v4.166c0 0.558-0.451 1.010-1.007 1.010h-10.321c-1.7 0-2.757-0.69-3.783-2.182l-15.362-22.882z"
      />
    </svg>
  ) : (
    <svg width="6em" height="1em" fill="currentColor" viewBox="0 0 106 17">
      <path
        className={'name'}
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#31336E' }}
        d="M24.7,16.7h3.6V4.1h4.7V1H20v3h4.8V16.7z M42.2,10.8c0-2.5-1.8-5.5-6-5.5c-4,0-5.8,3-5.8,5.8
	c0,2.8,2,5.8,6,5.8c2.8,0,4.8-1.2,5.5-3.4l-3.2-0.8c-0.3,0.9-1.1,1.5-2.3,1.5c-1.4,0-2.4-0.8-2.7-2.2h8.4
	C42.2,11.8,42.2,11.2,42.2,10.8z M33.9,9.7c0.3-1.3,1.2-2,2.4-2c1.5,0,2.2,0.9,2.4,2H33.9z M43.5,16.7h3.4v-4.9
	c0-2.3,0.8-3.6,2.4-3.6c1.2,0,2,0.8,2,2.1v6.5h3.4V9.3c0-2.5-1.7-4.1-4.3-4.1c-1.6,0-2.9,0.7-3.6,1.7V5.4h-3.4V16.7z M61.5,16.9
	c1.3,0,2.5-0.5,3.1-1.3v1.1H68V0.9h-3.4v5.6c-0.6-0.8-1.7-1.3-3.1-1.3c-3.4,0-5.4,2.3-5.4,5.8C56.1,14.6,58.1,16.9,61.5,16.9z
	 M62.1,14.1c-1.6,0-2.7-1.2-2.7-3c0-1.8,1.1-3,2.7-3c1.7,0,2.7,1.2,2.7,3C64.8,12.9,63.7,14.1,62.1,14.1z M71.4,3.9
	c1.2,0,2.1-0.9,2.1-2c0-1.1-0.9-1.9-2.1-1.9c-1.2,0-2,0.9-2,1.9C69.4,3,70.2,3.9,71.4,3.9z M69.7,16.7h3.4V5.4h-3.4V16.7z M86,5.4
	h-3.4v4.9c0,2.3-0.9,3.6-2.5,3.6c-1.2,0-2-0.8-2-2.1V5.4h-3.4v7.4c0,2.5,1.7,4.1,4.3,4.1c1.6,0,2.8-0.7,3.6-1.7v1.5H86V5.4z
	 M87.7,16.7h3.4v-5.8c0-1.8,0.8-2.9,2.2-2.9c1.2,0,1.9,0.8,1.9,2v6.7h3.3v-5.8c0-1.8,0.8-2.9,2.2-2.9c1.2,0,1.9,0.8,1.9,2v6.7h3.4V9
	c0-2.2-1.7-3.8-4.2-3.8c-1.9,0-3.3,0.9-3.9,2c-0.6-1.3-1.7-2-3.5-2c-1.5,0-2.6,0.7-3.3,1.7V5.4h-3.4V16.7z"
      />
      <linearGradient
        id="gradient"
        gradientUnits="userSpaceOnUse"
        x1="15.3529"
        y1="2.432"
        x2="6.7964"
        y2="15.267"
        gradientTransform="matrix(1 0 0 -1 0 17.8898)"
      >
        <stop offset="0" style={{ stopColor: '#31336E' }} />
        <stop offset="1" style={{ stopColor: '#5357EA' }} />
      </linearGradient>
      <path
        className={'logo'}
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : 'url(#gradient)' }}
        d="M4.7,4.4C4.5,4.1,4.3,4.1,3.9,4.1H0.6c-0.3,0-0.5-0.2-0.5-0.5v-2C0.1,1.3,0.3,1,0.6,1h12.3
	c0.3,0,0.5,0.2,0.5,0.5v2c0,0.3-0.2,0.5-0.5,0.5H9.5C9.1,4.1,9,4.3,9.2,4.6l6.1,8.9c0.1,0.1,0.2,0.2,0.5,0.2h3.7
	c0.3,0,0.5,0.2,0.5,0.5v2c0,0.3-0.2,0.5-0.5,0.5h-5.2c-0.9,0-1.4-0.3-1.9-1.1L4.7,4.4z"
      />
    </svg>
  );
};

export default TendiumLogoSVG;
