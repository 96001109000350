import React, { FC, useCallback, useMemo, memo, useRef } from 'react';
import { Form, Input } from 'src/common';
import { IBoxFieldString } from 'src/models/procurements/Tender/types';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';
import ValueActions from '../../ValueActions';
import styles from './index.module.scss';
import { useClickAway } from 'react-use';

const { TextArea } = Input;

interface IFormData {
  string: string;
}

export interface IProps extends IBlockEditProps<IBoxFieldString> {
  autoFocus?: boolean;
  autoSize?: boolean | AutoSizeType;
  onPaste?: (e: {
    clipboardData: {
      getData: (arg: string) => string;
    };
  }) => void;
}

export const EditableBlockTextSimple: FC<IProps> = ({
  onChange,
  onFinish,
  onPaste,
  field,
  className,
  autoFocus,
  autoSize
}) => {
  const { id: fieldId, string } = field;
  const { t } = useTranslation();

  const [form] = useForm();
  const ref = useRef<HTMLDivElement>(null);

  const onSubmit = useCallback(() => {
    const value: IFormData = form.getFieldValue(fieldId);

    value.string !== field.string
      ? onChange && onChange(field.update(value.string !== '' ? value.string : null))
      : onFinish && onFinish();
  }, [field, fieldId, form, onChange, onFinish]);

  const onCancel = useCallback(() => {
    form.resetFields();
    onFinish && onFinish();
  }, [form, onFinish]);

  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    onSubmit();
  });

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        string
      }
    };
  }, [fieldId, string]);

  return (
    <div ref={ref} className={className}>
      <Form form={form} initialValues={initialValues} className={styles.container}>
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'string']} className={styles.input}>
            <TextArea
              autoSize={autoSize || true}
              onPressEnter={onSubmit}
              placeholder={t('Common.Blocks.String')}
              autoFocus={autoFocus}
              onFocus={e => autoFocus && e.currentTarget.select()}
              className={styles.control}
              onPaste={onPaste}
            />
          </Form.Item>
          <ValueActions onCancel={onCancel} />
        </div>
      </Form>
    </div>
  );
};

export default memo(EditableBlockTextSimple);
