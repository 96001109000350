import React, { FC } from 'react';
import styles from '../index.module.scss';
import { useTranslation } from 'react-i18next';
import { formatCurrencyValue } from 'src/models/procurements/Tender/helpers';
import { BidCategoryBadge } from 'src/common/BidCategoryBadge';
import { StackData } from 'src/models/bids/Bids/types';
import { formatDate } from 'src/helpers/dates';
import { useLanguage } from 'src/common';

interface Props {
  data: StackData;
}

const HoverTooltip: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { amount, date, statusCategory } = data;
  const formattedAmount = formatCurrencyValue(amount ?? 0, language, 'SEK');
  const formattedMonth = formatDate(date, 'month', { showOnlyMonthName: true });

  return (
    <>
      <div>
        <h3 className={styles.text}>{formattedMonth}</h3>
        <BidCategoryBadge status={statusCategory} />
      </div>

      <p>{t('BidSpaces.totalValue')}</p>
      <h3>{formattedAmount}</h3>
    </>
  );
};

export default HoverTooltip;
