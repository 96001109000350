import {
  faBooks,
  faCircleMinus,
  faClipboardCheck,
  faClipboardQuestion,
  faComment
} from '@fortawesome/pro-light-svg-icons';
import { IIcon, IIconMapper } from 'src/types/icon';
import { TaskFulfillment } from './types';
import { faCircleCheck, faCirclePlus, faCircleQuestion, faCircleX } from '@fortawesome/pro-light-svg-icons';
import { faCircleX as faCircleXSolid } from '@fortawesome/pro-solid-svg-icons';

export const TASK_EDITOR_ICON_MAPPER: IIconMapper = {
  answer: { defaultIcon: faClipboardCheck, color: '#2F54EB' },
  comments: { defaultIcon: faComment, color: '#2F54EB' },
  question: { defaultIcon: faClipboardQuestion, color: '#2F54EB' },
  lib: { defaultIcon: faBooks, color: '#2F54EB' }
};

export const FULFILLMENT_MAPPER: Record<TaskFulfillment | string, IIcon> = {
  [TaskFulfillment.Fulfill]: { defaultIcon: faCircleCheck, disabledIcon: faCircleCheck, color: '#52C41A' },
  [TaskFulfillment.Unsure]: { defaultIcon: faCircleQuestion, disabledIcon: faCircleQuestion, color: '#FAAD14' },
  [TaskFulfillment.NotFulfill]: { defaultIcon: faCircleXSolid, disabledIcon: faCircleX, color: '#F5222D' },
  [TaskFulfillment.NA]: { defaultIcon: faCircleMinus, disabledIcon: faCircleMinus, color: '#2F54EB' },
  null: { defaultIcon: faCirclePlus, disabledIcon: faCirclePlus, color: '#81818F' }
};
