import { FieldPolicy, Reference } from '@apollo/client';
import { notUndefined } from 'src/helpers/typescript';

export function getOrgsPolicy(): FieldPolicy {
  return {
    keyArgs: ['searchPhrase'],
    read(existing: Reference[]) {
      if (!existing) {
        return;
      }
      if (!!existing) {
        return existing;
      }
    },
    merge(existing: Reference[], incoming: Reference[], { args }) {
      if (!existing) {
        return incoming;
      }
      if (incoming && incoming.length === 0) {
        return existing;
      }
      if (incoming) {
        const merged = existing ? [...existing] : [];
        const offset = args?.resultOffset;
        if (offset) {
          for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
          }
        }
        return merged;
      }
    }
  };
}

export function getOrgsByIdPolicy(): FieldPolicy {
  return {
    keyArgs: ['organisationIds'],
    read(existedOrgs: Reference[], { args, toReference, canRead }) {
      const cachedOrgs: Reference[] =
        args && Array.isArray(args.organisationIds)
          ? args.organisationIds
              .map((id: string) => toReference({ __typename: 'Organisation', organisationNumber: id }))
              .filter(ref => canRead(ref))
              .filter(notUndefined)
          : existedOrgs;
      return cachedOrgs.length !== args?.organisationIds.length ? existedOrgs : cachedOrgs;
    }
  };
}
