import { getSortedBuyerBoxes, ocdsFilterOnlyBuyerBoxes, ocdsPartyToBuyerBox } from 'src/models/procurements/helpers';
import {
  IBoxFieldString,
  IBoxFieldCurrency,
  isFieldCurrency,
  OCDSEstimatedContractValue,
  ITenderBox
} from '../Tender/types';
import { TenderBox } from '..';
import { IBidIdentifier } from '../../bids/Bids/types';
import { filterBoxes, convertApiLotsToLots } from '../Tender/helpers';
import { ProcurementStatus } from '../types';
import { ApiTendersItem, TendersItem } from '../Tenders/types';
import { MpKeyword, MpKeywordAndGroup, MpKeywordGroup } from 'src/models/matchingProfiles/types';
import { isNotUndefined } from 'src/helpers';
import { findMatchedKeywords } from '../Preview/helpers';
import { useTendersTable, useTendersBoard, useTendersTimeline } from './hooks';
import { OcdsParty } from '../TenderFree/types';

export class SimpleTendersBaseItem {
  readonly id: string;
  readonly name: string;
  readonly buyerName?: string;
  readonly buyers: ITenderBox[];
  readonly isCommented: boolean;
  readonly isRead: boolean;
  readonly isRejected: boolean;
  readonly nextId?: string;
  readonly bid?: IBidIdentifier;
  readonly score?: number | null;

  constructor(readonly apiTender: TendersItem<ApiTendersItem>) {
    (this.id = apiTender.id),
      (this.score = apiTender.score),
      (this.name = apiTender.tender.title ?? ''),
      (this.buyerName = this.toBuyerName(apiTender.parties)),
      (this.buyers = ocdsFilterOnlyBuyerBoxes(apiTender.parties).map(party => ocdsPartyToBuyerBox(party))),
      (this.isCommented = apiTender.isCommented),
      (this.isRead = apiTender.isRead),
      (this.isRejected = !!apiTender.rejected),
      (this.nextId = apiTender.nextId),
      (this.bid = this.toBid());
  }

  private toBuyerName(parties: OcdsParty[]): string {
    const leadBuyerParty = parties.find(party => party.roles?.includes('LeadBuyer'));
    if (leadBuyerParty) return leadBuyerParty.name ?? '';

    const buyerParty = parties.find(party => party.roles?.includes('Buyer'));
    if (buyerParty) return buyerParty?.name ?? '';

    return '';
  }

  private toBid(): IBidIdentifier | undefined {
    return !!this.apiTender.bids && !!this.apiTender.bids.length
      ? { id: this.apiTender.bids[0].id, wsId: this.apiTender.bids[0].workspace.id }
      : undefined;
  }
}

export class TendersBaseItem {
  readonly id: string;
  readonly name: string;
  readonly buyers: ITenderBox[];
  readonly isCommented: boolean;
  readonly isRead: boolean;
  readonly isRejected: boolean;
  readonly nextId?: string;
  readonly bid?: IBidIdentifier;

  constructor(readonly apiTender: TendersItem<ApiTendersItem>) {
    const general = apiTender.general;
    const lots = convertApiLotsToLots(apiTender.lots);
    const nameBox = general.name ? new TenderBox(general.name) : undefined;
    (this.id = apiTender.id),
      (this.name = (nameBox && nameBox.firstField && (nameBox.firstField as IBoxFieldString).string) ?? ''),
      (this.buyers = filterBoxes(getSortedBuyerBoxes(general.buyerBoxes), lots)),
      (this.isCommented = apiTender.isCommented),
      (this.isRead = apiTender.isRead),
      (this.isRejected = !!apiTender.rejected),
      (this.nextId = apiTender.nextId),
      (this.bid = this.toBid());
  }

  private toBid(): IBidIdentifier | undefined {
    return !!this.apiTender.bids && !!this.apiTender.bids.length
      ? { id: this.apiTender.bids[0].id, wsId: this.apiTender.bids[0].workspace.id }
      : undefined;
  }
}

export class TendersTableItem extends SimpleTendersBaseItem {
  readonly published: number | null;
  readonly deadline: number | null;
  readonly assignedTo: string | null;
  readonly isStarred: boolean;
  readonly procurementStatus: ProcurementStatus[];
  readonly showScore: boolean;

  constructor(readonly apiTender: TendersItem<ApiTendersItem>, readonly _showScore: boolean) {
    super(apiTender);
    (this.published = apiTender.date ?? null),
      (this.deadline = apiTender.tender.tenderPeriod?.endDate ?? null),
      (this.assignedTo = apiTender.assignedTo ? apiTender.assignedTo.id : null),
      (this.isStarred = apiTender.isStarred),
      (this.procurementStatus = apiTender.procurementStatus);
    this.showScore = _showScore;
  }
}

export class TendersBoardItem extends TendersTableItem {
  readonly estValue?: OCDSEstimatedContractValue;
  readonly keywords?: MpKeywordAndGroup[];

  constructor(
    apiTender: TendersItem<ApiTendersItem>,
    readonly _showScore: boolean,
    private readonly _keywords?: MpKeyword[],
    private readonly _keywordGroups?: MpKeywordGroup[]
  ) {
    super(apiTender, _showScore);

    this.estValue = apiTender.tender.value;
    this.keywords = this.toKeywords();
  }

  private toKeywords(): MpKeywordAndGroup[] | undefined {
    const singleKeywords = this._keywords
      ? this._keywords.map(keyword => ({
          values: [{ value: keyword.value, id: keyword.id }],
          filterLogic: keyword.filterLogic,
          searchLogic: keyword.searchLogic,
          searchType: keyword.searchType,
          highlightColor: keyword.highlightColor
        }))
      : [];
    const groups = this._keywordGroups ? this._keywordGroups : [];
    const allKeywords = [...singleKeywords, ...groups];

    return this._keywords
      ? findMatchedKeywords(this.apiTender.highlights, !!allKeywords.length ? allKeywords : undefined)
      : undefined;
  }
}

export class TendersTimelineItem extends TendersBaseItem {
  readonly estValue: IBoxFieldCurrency | null;
  readonly title: string;
  readonly isLots: boolean;
  readonly dates: number[];

  constructor(apiTender: TendersItem<ApiTendersItem>) {
    super(apiTender);
    const { contractEnd, contractStart, extensionEndDates } = apiTender.expiringContract;
    const { general } = apiTender;

    this.title = this.name;
    this.isLots = general.contractDurationBoxes.some(box => !!box.lots?.length);
    this.dates = [...new Set([contractStart, contractEnd, ...(extensionEndDates ?? [])])].filter(isNotUndefined);
    this.estValue = this.toEstValue();
  }

  private toEstValue(): IBoxFieldCurrency | null {
    const lots = convertApiLotsToLots(this.apiTender.lots);
    const estimationValueBox = !filterBoxes(this.apiTender.general.contractValueBoxes, lots).length
      ? filterBoxes(this.apiTender.general.contractValueBoxes, lots)[0]
      : undefined;
    return estimationValueBox && estimationValueBox.firstField && isFieldCurrency(estimationValueBox.firstField)
      ? estimationValueBox.firstField
      : null;
  }
}

export { useTendersTable, useTendersBoard, useTendersTimeline };
