import React, { FC, useMemo } from 'react';
import { Navigate } from 'react-router';
import { useApp, UserType } from 'src/models/auth';
import { Paths } from '../paths';
import { StorageKey } from 'src/types/enums';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';

/** all authorized redirection (aka user logged in) should be handled in `HomePage` */
export const HomePage: FC = () => {
  const { permission, user } = useApp();
  const fromUrl = sessionStorage.getItem(StorageKey.FromLogin);
  const isPaid = useMemo(() => permission === UserType.Paid, [permission]);
  const isCompany = useMemo(
    () => isPaid && !!user?.company.orgNumber && !!user?.company.companyName,
    [isPaid, user?.company.companyName, user?.company.orgNumber]
  );
  const isMonitoringProfilesAvailable = useFeatureFlag(FeatureFlag.MonitoringProfiles);
  const isAnalyticsExpContractAvailable = useFeatureFlag(FeatureFlag.AnalyticsExpiringContracts);
  const isBidspacesAvailable = useFeatureFlag(FeatureFlag.Bidspaces);
  const isBiddingGPTAvailable = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT);

  const paidRedirect = useMemo(() => {
    if (!isCompany) {
      return Paths.START;
    }
    if (fromUrl) {
      sessionStorage.removeItem(StorageKey.FromLogin);
      return fromUrl;
    }
    if (isMonitoringProfilesAvailable) {
      return Paths.MONITORING;
    }
    if (isBidspacesAvailable && isBiddingGPTAvailable) {
      return Paths.BIDSPACES;
    }
    if (isAnalyticsExpContractAvailable) {
      return Paths.ANALYTICS_PAGE + Paths.EXPIRING_CONTRACTS_ROUTE;
    }
    return Paths.ALL_TENDERS;
  }, [
    fromUrl,
    isAnalyticsExpContractAvailable,
    isBiddingGPTAvailable,
    isBidspacesAvailable,
    isCompany,
    isMonitoringProfilesAvailable
  ]);
  const freeRedirect = useMemo(() => fromUrl ?? Paths.SETTINGS_PAGE, [fromUrl]);
  const to = useMemo(() => (isPaid ? paidRedirect : freeRedirect), [freeRedirect, isPaid, paidRedirect]);

  sessionStorage.removeItem(StorageKey.FromLogin);

  return <Navigate to={to} replace />;
};

export default HomePage;
