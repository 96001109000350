import { FieldPolicy, Reference } from '@apollo/client';
import { CommentsCountCached, ParentCommentsCached } from './types';

export function getCommentsCountPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['parentType']],
    read(existing: CommentsCountCached) {
      return existing?.value ?? undefined;
    },
    merge(existing: CommentsCountCached, incoming: Reference[], { variables, readField }) {
      let merged = existing ? [...existing.value] : [];
      if (incoming) {
        merged = [...merged, ...incoming];
      }
      return {
        value: [...new Map(merged.map(item => [readField({ fieldName: 'parentId', from: item }), item])).values()],
        variables
      };
    }
  };
}
export function getParentCommentsPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['parentId', 'parentType']],
    read(existing: ParentCommentsCached) {
      return existing?.value ?? undefined;
    },
    merge(existing: ParentCommentsCached, incoming: Reference[], { variables, readField }) {
      let merged = existing ? [...existing.value] : [];
      if (incoming) {
        merged = [...merged, ...incoming];
      }
      return {
        value: [
          ...new Map(merged.map(commentRef => [readField({ fieldName: 'id', from: commentRef }), commentRef])).values()
        ],
        variables
      };
    }
  };
}
