import React, { FC, useCallback, useRef, useState } from 'react';
import { useClickAway, useKey } from 'react-use';
import EditableBlockDate from './EditableBlock';
import styles from './index.module.scss';
import ReadOnlyBlockDate from './ReadOnlyBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IBoxFieldDate } from 'src/models/procurements/Tender/types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AddValueButton from '../AddValueButton';
import { IBlockProps, FieldViewMode } from 'src/shared/Tender/Boxes/types';

interface IProps extends IBlockProps<IBoxFieldDate> {}
export const BlockDate: FC<IProps> = ({ field, onChange, isEditable, config }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [currentMode, setCurrentMode] = useState(FieldViewMode.Read);

  const onToggleView = useCallback(() => {
    isEditable && setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, [isEditable]);

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));
  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    switchToReadMode();
  });

  const onFinish = useCallback(
    (val: IBoxFieldDate) => {
      onChange?.(val);
      switchToReadMode();
    },
    [onChange, switchToReadMode]
  );

  return (
    <>
      {currentMode === FieldViewMode.Edit && (
        <EditableBlockDate field={field} onChange={onFinish} containerRef={ref} onlyPicker />
      )}
      {!!field.date ? (
        <div
          className={classNames(styles.toggler, {
            [styles.isEditable]: isEditable,
            [styles.isActive]: currentMode === FieldViewMode.Edit
          })}
          onClick={onToggleView}
        >
          <ReadOnlyBlockDate date={field.date} config={config} />
          {isEditable && (
            <div className={styles.togglerIcon}>
              <FontAwesomeIcon icon={faPen} />
            </div>
          )}
        </div>
      ) : (
        <AddValueButton onToggleView={onToggleView} isEditable={!!isEditable} title={t('Common.Blocks.addDate')} />
      )}
    </>
  );
};

export default BlockDate;
