export default function splitByQuotation(text: string): string[] {
  return (
    (!!text &&
      text
        .replace(/"([^"]+)"/g, ' ')
        .replace(/[^\p{L}\p{N}\p{Z}]/gu, ' ')
        .split(' ')
        .filter(word => !!word)
        .concat(text.match(/"([^"]+)"/g) || [])) ||
    []
  );
}
