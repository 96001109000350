import React, { ReactElement } from 'react';
import { Pagination as BasePaging, PaginationProps } from 'antd';
import classNames from 'classnames';
import styles from './index.module.scss';

export type PagingProps = PaginationProps;

export const Paging = (props: PagingProps): ReactElement | null => {
  const { className } = props;

  return (
    <BasePaging
      size={'small'}
      hideOnSinglePage
      showSizeChanger={false}
      {...props}
      className={classNames(styles.paging, !!className && className)}
    />
  );
};
