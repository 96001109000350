import React, { FC, useEffect } from 'react';
import { Alert } from 'src/common';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag, FeatureFlag } from 'src/helpers/featureFlag';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { BidPreviewProvider } from 'src/models/bids/BidPreview/providers';
import useCurrentBidId from 'src/reactiveVars/CurrentBidIdVar';
import BidPreviewContent from './Content';

export const BidPreview: FC = () => {
  const currentSidebar = useCurrentSidebar();
  const activeBidId =
    currentSidebar && currentSidebar.mode === SidebarMode.BID_INFO && currentSidebar.id ? currentSidebar.id : undefined;

  const { t } = useTranslation();
  const isWorkspaceAclFeature = useFeatureFlag(FeatureFlag.Workspace_ACL);

  const [, setBidId] = useCurrentBidId();

  useEffect(() => {
    setBidId(activeBidId);
  }, [activeBidId, setBidId]);

  return (
    <>
      {isWorkspaceAclFeature && activeBidId ? (
        <BidPreviewProvider id={activeBidId}>
          <BidPreviewContent />
        </BidPreviewProvider>
      ) : (
        <Alert message={t('Common.ACL.noAccessFeature')} type={'info'} />
      )}
    </>
  );
};

export default BidPreview;
