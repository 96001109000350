import { useMemo, useCallback } from 'react';
import { useQuery, useMutation, MutationUpdaterFn, MutationTuple, ApolloError, useSubscription } from '@apollo/client';
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATIONS_AS_SEEN,
  ON_NEW_NOTIFICATIONS
} from './queries';
import {
  INotification,
  NOTIFICATIONS_LIMIT,
  INotificationsData,
  IApiNotificationsData,
  INotificationSearchArgs,
  IApiNotification,
  ActiveNotificationsTab
} from './types';
import { useMe, notification } from 'src/common';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import { useAllUsers } from '../users/hooks';
import { toNotificationType } from './helpers';
import { usePagePreferences } from '../users/Preferences/hooks';
import { PageName } from '../users/Preferences/types';
import { FeatureFlag } from '@tendium/prom-types';
import { useFeatureFlag } from 'src/helpers';

export interface ILoadNotificationsResponse {
  getNotifications: IApiNotificationsData & {
    __typename: 'InAppNotification';
  };
}

function getRelativePart(urlStr: string): string {
  const [, url] = urlStr.split(new URL(urlStr).origin);
  return url;
}

export function useLoadNotifications(): {
  data?: INotificationsData;
  loading: boolean;
  error?: ApolloError;
} {
  const allUsers = useAllUsers();
  const { data, loading, error } = useQuery<ILoadNotificationsResponse, INotificationSearchArgs>(GET_NOTIFICATIONS, {
    variables: {
      limit: NOTIFICATIONS_LIMIT
    }
  });

  return useMemo(
    () => ({
      data: data
        ? {
            notifications: orderBy(data.getNotifications.notifications, x => x.createdAt, 'desc').map(inApp => ({
              ...inApp,
              type: toNotificationType(inApp.title),
              createdBy: allUsers.find(user => user.email === inApp.email),
              url: !!inApp.url ? getRelativePart(inApp.url) : undefined
            })),
            unseenCount: data.getNotifications.newCounter
          }
        : undefined,
      loading,
      error
    }),
    [allUsers, data, error, loading]
  );
}

interface IMarkNotificationAsReadResponse {
  __typename: 'Mutation';
  markNotificationAsRead: INotification & {
    __typename: 'NotificationEntity';
  };
}

export function useMarkNotificationAsRead(): MutationTuple<
  IMarkNotificationAsReadResponse,
  { email: string; createdAt: number }
> {
  return useMutation(MARK_NOTIFICATION_AS_READ);
}

export function getUpdateCacheOnMarkNotificationAsRead(
  createdAt: number
): MutationUpdaterFn<IMarkNotificationAsReadResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const notificationRef = cache.identify({
      __typename: 'NotificationEntity',
      createdAt
    });
    cache.modify({
      id: notificationRef,
      fields: {
        isRead() {
          return true;
        }
      }
    });
  };
}

interface IMarkNotificationsAsReadResponse {
  __typename: 'Mutation';
  markAllNotificationsAsRead: boolean;
}
interface IUpdateNotificationsRequest {
  email: string;
}

export function useMarkNotificationsAsRead(): [() => void, { loading: boolean; error?: ApolloError }] {
  const [updateNotifications, { loading, error }] = useMutation<
    IMarkNotificationsAsReadResponse,
    IUpdateNotificationsRequest
  >(MARK_NOTIFICATIONS_AS_READ);

  const { t } = useTranslation();
  const { data: meData } = useMe();
  const email = meData?.email;

  const updateNotificationsFn = useCallback(() => {
    email &&
      updateNotifications({
        variables: { email },
        update: getUpdateCacheOnMarkNotificationsAsRead()
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
  }, [email, t, updateNotifications]);

  return [updateNotificationsFn, { loading, error }];
}
export function getUpdateCacheOnMarkNotificationsAsRead(): MutationUpdaterFn<IMarkNotificationsAsReadResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const notificationsQueryData = cache.readQuery<ILoadNotificationsResponse | null>({
      query: GET_NOTIFICATIONS,
      variables: { limit: NOTIFICATIONS_LIMIT }
    });
    if (!notificationsQueryData) {
      return;
    }
    const { notifications } = notificationsQueryData.getNotifications;
    const notReadNotifications = notifications.filter(n => !n.isRead).map(n => n.createdAt);
    notReadNotifications.forEach(n => {
      const nRef = cache.identify({
        __typename: 'NotificationEntity',
        createdAt: n
      });
      cache.modify({
        id: nRef,
        fields: {
          isRead() {
            return true;
          }
        }
      });
    });
  };
}

interface IMarkNotificationsAsSeenResponse {
  __typename: 'Mutation';
  markAllNotificationsAsSeen: boolean;
}
export function useMarkNotificationsAsSeen(): [() => void, { loading: boolean; error?: ApolloError }] {
  const [updateNotifications, { loading, error }] = useMutation<
    IMarkNotificationsAsSeenResponse,
    IUpdateNotificationsRequest
  >(MARK_NOTIFICATIONS_AS_SEEN);

  const { t } = useTranslation();
  const { data: meData } = useMe();
  const email = meData?.email;

  const updateNotificationsFn = useCallback(() => {
    email &&
      updateNotifications({
        variables: { email },
        update: getUpdateCacheOnMarkNotificationsAsSeen()
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
  }, [email, t, updateNotifications]);

  return [updateNotificationsFn, { loading, error }];
}
export function getUpdateCacheOnMarkNotificationsAsSeen(): MutationUpdaterFn<IMarkNotificationsAsSeenResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const notificationsQueryData = cache.readQuery<ILoadNotificationsResponse | null>({
      query: GET_NOTIFICATIONS,
      variables: { limit: NOTIFICATIONS_LIMIT }
    });
    if (!notificationsQueryData) {
      return;
    }
    const { notifications } = notificationsQueryData.getNotifications;
    const notSeenNotifications = notifications.filter(n => !n.isSeen).map(n => n.createdAt);
    cache.modify({
      fields: {
        getNotifications(cached) {
          return {
            ...cached,
            newCounter: 0
          };
        }
      }
    });
    notSeenNotifications.forEach(n => {
      const nRef = cache.identify({
        __typename: 'NotificationEntity',
        createdAt: n
      });
      cache.modify({
        id: nRef,
        fields: {
          isSeen() {
            return true;
          }
        }
      });
    });
  };
}

export function useNewNotificationSubscriptions(): void {
  useSubscription<{ newNotification: IApiNotification }>(ON_NEW_NOTIFICATIONS, {
    onData: ({ client, data }) => {
      if (!data) {
        return;
      }
      const newNotification = data.data?.newNotification;
      if (!newNotification) return;
      const { cache } = client;
      const notificationsQueryData = cache.readQuery<ILoadNotificationsResponse, INotificationSearchArgs>({
        query: GET_NOTIFICATIONS,
        variables: { limit: NOTIFICATIONS_LIMIT }
      });
      if (notificationsQueryData) {
        const { getNotifications } = notificationsQueryData;
        const isAlreadyExist = getNotifications.notifications.some(n => n.createdAt === newNotification.createdAt);
        !isAlreadyExist &&
          cache.writeQuery({
            query: GET_NOTIFICATIONS,
            variables: { limit: NOTIFICATIONS_LIMIT },
            data: {
              getNotifications: {
                ...getNotifications,
                newCounter: getNotifications.newCounter + 1,
                notifications: [...getNotifications.notifications, newNotification]
              }
            }
          });
      }
    }
  });
}

export function useNotificationsTabState(): [ActiveNotificationsTab, (tab: ActiveNotificationsTab) => void] {
  const [page, updatePage] = usePagePreferences(PageName.notificationsMenu);

  const isRemindersFeature = useFeatureFlag(FeatureFlag.Reminders);

  const activeNotificationsTab = useMemo(() => {
    if (!isRemindersFeature) {
      return ActiveNotificationsTab.Notifications;
    }
    return page ? (page.tab as ActiveNotificationsTab) : ActiveNotificationsTab.Notifications;
  }, [isRemindersFeature, page]);

  const setActiveNotificationsTabState = useCallback(
    (tab: ActiveNotificationsTab) => {
      if (activeNotificationsTab !== tab) {
        updatePage({ tab });
      }
    },
    [activeNotificationsTab, updatePage]
  );

  return useMemo(
    () => [activeNotificationsTab, setActiveNotificationsTabState],
    [activeNotificationsTab, setActiveNotificationsTabState]
  );
}
