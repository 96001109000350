import { useTooltip } from '@visx/tooltip';

export type ChartTooltipProps<T> = {
  tooltipData?: T;
  tooltipTop?: number;
  tooltipLeft?: number;
};

export function useChartTooltip<T>(): ChartTooltipProps<T> & {
  showTooltip: (props: ChartTooltipProps<T>) => void;
  hideTooltip: () => void;
  tooltipOpen: boolean;
} {
  let tooltipTimeout: number;
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<T>();

  return {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    showTooltip: ({ tooltipData, tooltipTop, tooltipLeft }: ChartTooltipProps<T>) => {
      if (tooltipTimeout) clearTimeout(tooltipTimeout);
      showTooltip({ tooltipData, tooltipTop, tooltipLeft });
    },
    hideTooltip: () => {
      tooltipTimeout = window.setTimeout(() => {
        hideTooltip();
      }, 300);
    }
  };
}
