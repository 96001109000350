import { makeVar } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { ReactiveVariable, useReactiveVariable } from 'src/helpers/reactiveVariables';
import { IBidSorting, IBidsSearchArgs, INITIAL_BIDS_SEARCH_ARGS } from 'src/models/bids/Bids/types';
import { PageView } from 'src/models/users/Preferences/types';
import { useMyRequestSettings } from 'src/models/users/RequestSettings/hooks';
import { RequestType } from 'src/models/users/RequestSettings/types';
import { useWorkspaceViewState } from 'src/models/workspace/hooks';

export const bidsSearchArgsVar = makeVar(INITIAL_BIDS_SEARCH_ARGS);
export function useBidsSearchArgs(): ReactiveVariable<IBidsSearchArgs> {
  const [searchArgs, updateSearchArgs] = useReactiveVariable<IBidsSearchArgs>(bidsSearchArgsVar);
  const { wsId } = useParams<{ wsId: string }>();
  const [currentView] = useWorkspaceViewState();
  const isKanban = useMemo(() => currentView === PageView.board, [currentView]);
  const query = useMyRequestSettings(!isKanban);

  const sortOnField: IBidSorting | undefined = useMemo(() => {
    if (isKanban) {
      const settings = query.data?.getMe.account?.requestSettings ?? [];
      const wsSorting = settings.find(setting => {
        return setting.requestType === RequestType.BidsSorting && setting.entityId === wsId;
      });
      if (wsSorting?.settings) {
        try {
          return JSON.parse(wsSorting?.settings);
        } catch (error) {}
      }
    }
  }, [isKanban, query.data?.getMe.account?.requestSettings, wsId]);

  return useMemo(
    () => [
      isKanban ? { ...searchArgs, sortOnField: sortOnField ?? INITIAL_BIDS_SEARCH_ARGS.sortOnField } : searchArgs,
      updateSearchArgs
    ],
    [updateSearchArgs, isKanban, searchArgs, sortOnField]
  );
}

export default useBidsSearchArgs;
