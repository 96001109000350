import {
  ApolloCache,
  ApolloError,
  DocumentNode,
  gql,
  LazyQueryResultTuple,
  MutationUpdaterFn,
  QueryResult,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import {
  IDocumentSearchResult,
  ISearchProfile,
  TermsForSearch,
  IDocument,
  ISearchProfileDocuments,
  ISearchSettings,
  IHandledDocument,
  IHandledDocumentPart,
  IHighlightChunk
} from './types';
import { useCallback, useContext, useMemo } from 'react';
import { SearchProfileColor, SearchProfilesColorsDict } from 'src/helpers/constants';
import {
  BOX_DOCUMENT_COODINATES,
  CREATE_SEARCH_PROFILE,
  DELETE_SEARCH_PROFILE,
  GET_SEARCH_COLORS,
  GET_SEARCH_PROFILES,
  TERMS_DOCUMENT_COODINATES,
  UPDATE_PROFILE_TERMS
} from './queries';
import { FilteredProfileTermsContext, ProfileTermsContext } from './context';
import {
  applySearchSettings,
  calTermOccurrence,
  filterProfiledDocuments,
  getDocumentsByProfiles,
  getHighlights,
  handleDocuments,
  searchResultsToDocuments,
  toSafeGroupId
} from './helpers';
import { useTranslation } from 'react-i18next';
import { trackAddSearchProfile, trackDeleteSearchProfile, trackUpdateSearchProfileTerms } from 'src/segment/events';
import { notification } from 'src/common';
import { gqlClient } from 'src/lib/API/graphql/api-client';
import isEqual from 'lodash/isEqual';
import { useMpKeywords } from 'src/models/matchingProfiles/hooks';
import useMpSearchProfileId from 'src/reactiveVars/MpSearchProfileIdVar';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { DOCUMENT_COORDINATES_FIELDS } from 'src/models/bids/BidTask/queries';

export interface IGetSearchProfilesResult {
  getSearchProfiles: ISearchProfile[];
}

export function useProfilesColors(): QueryResult<Partial<IGetSearchProfilesResult>> {
  return useQuery(GET_SEARCH_COLORS);
}

export function useUnusedColors(currentColor?: SearchProfileColor): { data: SearchProfileColor[]; loading: boolean } {
  const { data, loading } = useProfilesColors();
  const profiles = useMemo(() => data?.getSearchProfiles || [], [data?.getSearchProfiles]);

  const unusedColors = useMemo(() => {
    const profilesColors = profiles.map(profile => profile.color);

    return Object.keys(SearchProfilesColorsDict)
      .map(color => color as SearchProfileColor)
      .filter(color => currentColor === color || !profilesColors.includes(color));
  }, [currentColor, profiles]);
  return { data: unusedColors, loading };
}

const INITIAL_MP_SEARCH_GROUP_FIELDS = {
  color: SearchProfileColor.Red,
  colorValue: SearchProfilesColorsDict.Red,
  isMpBasedGroup: true
};

export function useDefaultSearchProfileFeature(): boolean {
  const isMonitoringProfilesFeature = useFeatureFlag(FeatureFlag.MonitoringProfiles);
  const isDefaultSearchProfileFeature = useFeatureFlag(FeatureFlag.DefaultSearchProfile);
  return !!isMonitoringProfilesFeature && !!isDefaultSearchProfileFeature;
}

export function useSearchProfiles(): { data?: ISearchProfile[]; error?: ApolloError; loading: boolean } {
  const query = useQuery<IGetSearchProfilesResult>(GET_SEARCH_PROFILES);
  const isDefaultSearchProfileFeature = useDefaultSearchProfileFeature();

  const [mpSearchGroupId] = useMpSearchProfileId();

  const { data, loading } = useMpKeywords(mpSearchGroupId, !isDefaultSearchProfileFeature);
  const mpData = data?.getMatchingProfile;

  const profiles = useMemo(() => {
    if (!!mpData) {
      const mpSearchProfile: ISearchProfile = {
        ...INITIAL_MP_SEARCH_GROUP_FIELDS,
        id: mpData.id,
        name: mpData.name,
        terms: mpData.keywords.map(val => val.value)
      };
      return [...(query.data?.getSearchProfiles || []), mpSearchProfile];
    }
    return query.data?.getSearchProfiles;
  }, [mpData, query.data]);
  return { ...query, data: profiles, loading: query.loading || loading };
}

export type ISearchTermsResult = Record<string, IDocumentSearchResult[]>;

const SEARCH_TERM_FIELDS = gql`
  fragment SearchTermFields on ProcurementSearch {
    filePath
    headline
    content
    order
    headlinePosition {
      ...documentCoordinatesFields
    }
    contentPosition {
      ...documentCoordinatesFields
    }
  }
  ${DOCUMENT_COORDINATES_FIELDS}
`;

function generateBulkSearch(groups: TermsForSearch, termVariables: string[]): DocumentNode {
  const getSingleGroupSearchQuery = (groupId: string): string => {
    const safeGroupId = toSafeGroupId(groupId);
    return `
      group_${safeGroupId}: searchTerms(query: { procurementId: $procurementId, terms: $terms_${safeGroupId} }) {
        ...SearchTermFields
      }
    `;
  };

  const searches = groups.map(group => getSingleGroupSearchQuery(group.searchProfile.id));

  const termVarDefinitions =
    termVariables.length > 0 ? `, ${termVariables.map(variable => `$${variable}: [String!]!`).join(', ')}` : '';

  return gql`
      query BulkSearchTerms($procurementId: String! ${termVarDefinitions}) {
          ${
            searches.length === 0
              ? `
            getMe {
                status
            }
          `
              : ''
          }
          ${searches.join('\n\n')}
      }

      ${SEARCH_TERM_FIELDS}
  `;
}

export function useSearchTerms(
  procurementId: string,
  groups: TermsForSearch,
  skip?: boolean
): QueryResult<ISearchTermsResult, { procurementId: string }> {
  const termVariables = Object.fromEntries(
    groups.map(group => [`terms_${toSafeGroupId(group.searchProfile.id)}`, group.terms])
  );

  const SEARCH_TERMS = generateBulkSearch(groups, Object.keys(termVariables));

  const variables = {
    procurementId,
    ...termVariables
  };
  return useQuery(SEARCH_TERMS, {
    variables,
    skip: groups.length === 0 || !!skip
  });
}

export function useSearchResultsToDocuments(
  searchResultsRaw: Readonly<Record<string, readonly IDocumentSearchResult[]>>,
  searchProfiles: readonly ISearchProfile[]
): IDocument[] {
  return useMemo(() => searchResultsToDocuments(searchResultsRaw, searchProfiles), [searchProfiles, searchResultsRaw]);
}

export function useDocumentsByProfiles<T extends IHandledDocument>(
  documents: T[],
  searchProfiles: ISearchProfile[]
): ISearchProfileDocuments<T>[] {
  return useMemo(() => {
    const profiledDocuments = getDocumentsByProfiles(documents, searchProfiles);
    return filterProfiledDocuments(profiledDocuments);
  }, [documents, searchProfiles]);
}

export function useHandleDocuments(
  documents: IDocument[],
  searchProfiles: readonly ISearchProfile[]
): IHandledDocument[] {
  return useMemo(() => handleDocuments(documents, searchProfiles), [documents, searchProfiles]);
}

export function useHighlights(
  part: IHandledDocumentPart,
  mainSearchProfile: ISearchProfile | null
): { content: IHighlightChunk[]; headline: IHighlightChunk[] } {
  return useMemo(() => getHighlights(part, mainSearchProfile), [mainSearchProfile, part]);
}

export function useFilteredDocuments(documents: IHandledDocument[], settings: ISearchSettings): IHandledDocument[] {
  return useMemo(() => applySearchSettings(documents, settings), [documents, settings]);
}

export function useAllTermOccurrence(filteredDocuments: IHandledDocument[]): { map: Map<string, number>; sum: number } {
  const termOccurrenceNumbers = useMemo(() => calTermOccurrence(filteredDocuments), [filteredDocuments]);
  return termOccurrenceNumbers;
}

export function useTermOccurrence(
  groupId: string,
  handledDocuments: IHandledDocument[],
  settings: ISearchSettings
): { map: Map<string, number>; sum: number } {
  const patchedSettings = useMemo(
    () => ({
      ...settings,
      groups: settings.groups.filter(g => g.searchProfile.id !== groupId)
    }),
    [groupId, settings]
  );
  const filteredDocuments = useFilteredDocuments(handledDocuments, patchedSettings);
  const termOccurrenceNumbers = useMemo(() => calTermOccurrence(filteredDocuments), [filteredDocuments]);
  return termOccurrenceNumbers;
}

export function useDocumentOccurrence(
  handledDocuments: IHandledDocument[],
  settings: ISearchSettings
): { map: Map<string, number>; sum: number } {
  const patchedSettings = useMemo(
    // these settings do not include documents
    () => ({
      ...settings,
      documents: []
    }),
    [settings]
  );
  const filteredDocuments = useFilteredDocuments(handledDocuments, patchedSettings);
  const occurrenceNumbers = useMemo(() => {
    const map = new Map<string, number>();
    let sum = 0;
    for (const document of filteredDocuments) {
      const documentKey = document.fileName;
      let documentNumber = map.get(documentKey) || 0;
      for (const part of document.parts) {
        documentNumber = documentNumber + part.occurrences.length + part.headlineOccurrences.length;
      }
      map.set(documentKey, documentNumber);
      sum += documentNumber;
    }
    return { map, sum };
  }, [filteredDocuments]);
  return occurrenceNumbers;
}

export function useProfileTermsContext(): TermsForSearch {
  return useContext(ProfileTermsContext);
}

export function useFilteredProfileTermsContext(): TermsForSearch {
  return useContext(FilteredProfileTermsContext);
}

export interface IUpdateTermsRequest {
  id: string;
  terms: string[];
}
export interface IUpdateTermsResponse {
  __typename: 'Mutation';
  updateSearchProfile: ISearchProfile & {
    __typename: 'SearchProfile';
  };
}

export interface IUpdateTermsFnParams {
  searchProfileId: string;
  searchProfileTitle: string;
  terms: string[];
  prevTerms: string[];
}

export function useUpdateTerms(): [
  (data: IUpdateTermsFnParams) => void,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();
  const termsForSearch = useProfileTermsContext();

  const [updateTermsOfSearchProfile, { loading, error }] = useMutation<IUpdateTermsResponse, IUpdateTermsRequest>(
    UPDATE_PROFILE_TERMS
  );

  const useUpdateTermsFn = useCallback(
    (data: IUpdateTermsFnParams) => {
      const { searchProfileId: id, searchProfileTitle: name, terms, prevTerms } = data;
      trackUpdateSearchProfileTerms({ id, name, terms, prevTerms });
      updateTermsOfSearchProfile({
        variables: { id, terms },
        update: getUpdateCacheOnUpdateTerms(id, terms, termsForSearch)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [termsForSearch, t, updateTermsOfSearchProfile]
  );
  return [useUpdateTermsFn, { loading, error }];
}

export function getUpdateCacheOnUpdateTerms(
  spId: string,
  terms: string[],
  termsForSearch: TermsForSearch
): MutationUpdaterFn<IUpdateTermsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const spRef = cache.identify({
      __typename: 'SearchProfile',
      id: spId
    });
    cache.modify({
      id: spRef,
      fields: {
        terms() {
          return [...terms];
        }
      }
    });

    const updatedTerms = termsForSearch.map(sp => {
      if (sp.searchProfile.id === spId) {
        return { ...sp, terms: [...terms] };
      }
      return { ...sp };
    });
    clearSearchTermsCacheOnUpdate(cache, updatedTerms);
  };
}

export interface ICreateSearchProfileRequest {
  name: string;
  terms: string[];
  color: SearchProfileColor;
}
export interface ICreateSearchProfileResponse {
  __typename: 'Mutation';
  createSearchProfile: ISearchProfile & {
    __typename: 'SearchProfile';
  };
}

export function useCreateSearchProfile(): [
  (data: { name: string; onFinish?: () => void }) => void,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();
  const { data: unusedColors } = useUnusedColors();

  const [createSearchProfile, { loading, error }] = useMutation<
    ICreateSearchProfileResponse,
    ICreateSearchProfileRequest
  >(CREATE_SEARCH_PROFILE);

  const createSearchProfileFn = useCallback(
    (data: { name: string; onFinish?: () => void }) => {
      const { name, onFinish } = data;
      trackAddSearchProfile({
        name,
        terms: [],
        color: unusedColors[0]
      });
      createSearchProfile({
        variables: { name, terms: [], color: unusedColors[0] },
        update: getUpdateCacheOnCreateSearchProfile()
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createSearchProfile, t, unusedColors]
  );
  return [createSearchProfileFn, { loading, error }];
}

export function getUpdateCacheOnCreateSearchProfile(): MutationUpdaterFn<ICreateSearchProfileResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const spQueryData = cache.readQuery<IGetSearchProfilesResult | null>({
      query: GET_SEARCH_COLORS
    });

    const getSearchProfiles = spQueryData?.getSearchProfiles;
    cache.writeQuery({
      query: GET_SEARCH_COLORS,
      data: {
        getSearchProfiles: !!getSearchProfiles
          ? [...getSearchProfiles, data.createSearchProfile]
          : [data.createSearchProfile]
      }
    });
  };
}

export interface IDeleteSearchProfileRequest {
  id: string;
}
export interface IDeleteSearchProfileResponse {
  deleteSearchProfile: boolean;
  __typename: 'Mutation';
}

export function useDeleteSearchProfile(): [
  (data: { id: string; name: string; onFinish?: () => void }) => void,
  { loading: boolean; error: ApolloError | undefined }
] {
  const { t } = useTranslation();

  const [deleteSearchProfile, { loading, error }] = useMutation<
    IDeleteSearchProfileResponse,
    IDeleteSearchProfileRequest
  >(DELETE_SEARCH_PROFILE);

  const termsForSearch = useProfileTermsContext();

  const deleteSearchProfileFn = useCallback(
    (data: { id: string; name: string; onFinish?: () => void }) => {
      const { id, name, onFinish } = data;
      trackDeleteSearchProfile({ id, name });
      deleteSearchProfile({
        variables: { id },
        update: getUpdateCacheOnDeleteSearchProfile(id, termsForSearch)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteSearchProfile, t, termsForSearch]
  );
  return [deleteSearchProfileFn, { loading, error }];
}

export function getUpdateCacheOnDeleteSearchProfile(
  id: string,
  termsForSearch: TermsForSearch
): MutationUpdaterFn<IDeleteSearchProfileResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const spQueryData = cache.readQuery<IGetSearchProfilesResult | null>({
      query: GET_SEARCH_COLORS
    });
    if (!spQueryData) {
      return;
    }
    const { getSearchProfiles } = spQueryData;
    const searchProfiles = getSearchProfiles.filter(sp => sp.id !== id);
    if (searchProfiles.length) {
      cache.writeQuery({
        query: GET_SEARCH_COLORS,
        data: { getSearchProfiles: searchProfiles }
      });
    } else {
      gqlClient.clearQueryCache('getSearchProfiles');
    }
    const restTerms = termsForSearch.filter(sp => sp.searchProfile.id !== id);
    clearSearchTermsCacheOnUpdate(cache, restTerms);
  };
}

interface ISearchTermsQuery {
  query: {
    procurementId: string;
    terms: string;
  };
}

export function clearSearchTermsCacheOnUpdate(cache: ApolloCache<unknown>, updatedTerms: TermsForSearch): void {
  const searchResultQuery: string[] = [];
  cache.modify({
    fields: {
      searchTerms: (existing, { fieldName, storeFieldName }) => {
        const data: ISearchTermsQuery = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));

        if (data) {
          const terms = data.query.terms;
          const existingTerms = updatedTerms.find(sp => isEqual(sp.terms, terms));
          if (!existingTerms) {
            searchResultQuery.push(storeFieldName);
          }
        }
        return existing;
      }
    }
  });
  !!searchResultQuery.length &&
    searchResultQuery.forEach(queryId => {
      cache.evict({
        fieldName: queryId
      });
    });
  cache.gc();
}

export interface DocumentPosition {
  page: number;
  start: {
    x: number;
    y: number;
  };
  end: {
    x: number;
    y: number;
  };
  pageSize: {
    width: number;
    height: number;
  };
}
export interface CoordinatesResponse {
  headlinePosition: DocumentPosition[];
  contentPosition: DocumentPosition[];
}

export interface BoxCoordinatesResponse {
  boxCoordinates: CoordinatesResponse | undefined;
}

export interface TermsDocumentCoordinatesResponse {
  termsDocumentCoordinates: CoordinatesResponse | undefined;
}

export interface BoxCoordinatesRequest {
  boxId: string;
  procurementId: string;
}

export interface TermsDocumentCoordinatesRequest {
  filePath: string;
  procurementId: string;
  terms: string[];
}

export function useBoxCoordinates(): LazyQueryResultTuple<BoxCoordinatesResponse, BoxCoordinatesRequest> {
  return useLazyQuery<BoxCoordinatesResponse, BoxCoordinatesRequest>(BOX_DOCUMENT_COODINATES);
}

export function useTermsDocumentCoordinates(): LazyQueryResultTuple<
  TermsDocumentCoordinatesResponse,
  TermsDocumentCoordinatesRequest
> {
  return useLazyQuery<TermsDocumentCoordinatesResponse, TermsDocumentCoordinatesRequest>(TERMS_DOCUMENT_COODINATES);
}
