import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'src/common/Button';
import Tooltip from 'src/common/Tooltip';
import { useTranslation } from 'react-i18next';
import {
  ICON_STATUS_LOADING,
  ICON_TYPE_ADD,
  ICON_TYPE_REMOVE,
  IEditableBoxActionIcon,
  IEditableBoxActionProps,
  IEditableBoxActionStatusIcon
} from './types';
import classNames from 'classnames';

export const EditableBoxAction: FC<IEditableBoxActionProps> = props => {
  const { t } = useTranslation();
  const { type, isLoading, isDone, onAction } = props;
  const actionStatusIcon: IEditableBoxActionStatusIcon = type === 'remove' ? ICON_TYPE_REMOVE : ICON_TYPE_ADD;
  const actionIcon: IEditableBoxActionIcon = !isLoading
    ? !isDone
      ? actionStatusIcon.isDefault
      : actionStatusIcon.isDone
    : ICON_STATUS_LOADING;
  const isDisabled = !!isLoading || !!isDone;
  const actionCallback = useCallback(() => {
    !isDisabled && onAction && onAction();
  }, [isDisabled, onAction]);
  return (
    <Tooltip title={t(actionIcon.title)}>
      <Button
        type={'link'}
        icon={<FontAwesomeIcon className={styles.icon} icon={actionIcon.icon} />}
        onClick={actionCallback}
        className={classNames({
          [styles.disabledButton]: isDisabled
        })}
      />
    </Tooltip>
  );
};

export default EditableBoxAction;
