import { IHighlight, Scaled } from 'react-pdf-highlighter';
import { DocumentPosition } from 'src/pages/TenderPage/DocumentSearch/hooks';
import { v4 } from 'uuid';

export const toPdfHighlightsData = (
  headlinePosition: DocumentPosition[],
  contentPosition: DocumentPosition[]
): IHighlight[] => {
  if (!contentPosition.length && !headlinePosition.length) {
    return [];
  }

  const positionOfClosestHeadline = !!headlinePosition.length ? [headlinePosition[headlinePosition.length - 1]] : [];
  const positions = [...positionOfClosestHeadline, ...contentPosition];

  return positions.map(data => {
    const { page, pageSize, start, end } = data;
    const highlight = {
      pageNumber: page,
      height: pageSize.height,
      width: pageSize.width,
      // Adjust to highlight positions to be more centered
      x1: start.x - 4,
      y1: start.y - 5,
      x2: end.x + 4,
      y2: end.y + 3
    };
    return toReactPdfHighlightType(highlight);
  });
};

const toReactPdfHighlightType = (data: Scaled): IHighlight => {
  const { pageNumber, ...restData } = data;
  return {
    id: v4(),
    position: {
      boundingRect: restData,
      rects: [restData],
      pageNumber: pageNumber ?? 1
    },
    comment: {
      text: '',
      emoji: ''
    },
    content: {
      text: ''
    }
  };
};

export const cleanBoxId = (boxId: string): string => boxId.split('#')[boxId.split('#').length - 1];

export const keywordHighlightValueToTerms = (keywordHighlightValue: string): string[] =>
  keywordHighlightValue
    .split('.')
    .find(s => s.includes('**'))
    ?.split(' ')
    .map(s => s.replace(/\*\*/g, '')) ?? [];
