import { FieldPolicy, Reference } from '@apollo/client';

type ICallOffsCached = {
  userMails: Reference[];
  total: number;
};

export function getCallOffsPolicy(): FieldPolicy {
  return {
    keyArgs: ['emails', 'keywords', 'statuses'],
    merge(existing: ICallOffsCached, incoming: ICallOffsCached, { args, readField }) {
      if (!existing) {
        return incoming;
      }
      if (incoming && !!incoming.userMails && incoming.userMails.length === 0) {
        return { ...existing, total: incoming.total };
      }
      if (incoming && incoming.userMails) {
        let merged = existing && existing.userMails ? [...existing.userMails] : [];
        if (args) {
          const { from, statuses } = args;

          merged = statuses.length
            ? merged.filter(item => statuses.includes(readField({ fieldName: 'mailStatus', from: item })))
            : merged;

          for (let i = 0; i < incoming.userMails.length; ++i) {
            merged[from + i] = incoming.userMails[i];
          }
        }
        return { ...existing, userMails: merged, total: incoming.total };
      }
    }
  };
}
