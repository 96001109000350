import { FieldPolicy, Reference } from '@apollo/client';
import { RemindersCachedResult } from './types';

export function getRemindersPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['relationId', 'assignedTo', 'createdBy']],

    read(existing: RemindersCachedResult) {
      if (!existing) {
        return;
      }
      if (!!existing) {
        return existing.value;
      }
    },
    merge(existing: RemindersCachedResult, incoming: Reference[], { variables }) {
      let merged = existing?.value ? [...existing.value] : [];

      if (incoming) {
        if (variables) {
          for (let i = 0; i < incoming.length; ++i) {
            merged[variables.from + i] = incoming[i];
          }
        } else {
          merged = [...merged, ...incoming];
        }
      }

      return {
        value: merged,
        variables
      };
    }
  };
}
