import React, { FC, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { Form, Select, Tooltip, Button } from 'src/common';
import { IBoxFieldBoolean } from 'src/models/procurements/Tender/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IBlockEditProps } from 'src/shared/Tender/Boxes/types';

interface FormData {
  boolean: string | null;
}

export interface Props extends IBlockEditProps<IBoxFieldBoolean> {}

export const EditableBlockBoolean: FC<Props> = ({ field, onChange, onFinish, containerRef, className }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id: fieldId, boolean } = field;

  const onChangeValues = useCallback(() => {
    const value: FormData = form.getFieldValue(fieldId);
    const isNotChanged =
      (field.boolean !== null && field.boolean !== undefined && value.boolean === field.boolean.toString()) ||
      ((field.boolean === null || field.boolean === undefined) && value.boolean === null);
    isNotChanged
      ? onFinish && onFinish()
      : onChange && onChange(field.update(value.boolean === `true` ? true : value.boolean === `false` ? false : null));
  }, [field, fieldId, form, onChange, onFinish]);

  const onSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement> | React.MouseEvent<HTMLFormElement>) => {
      const eventTypes = ['click', 'mousedown', 'touchstart'];
      if ((e instanceof KeyboardEvent && e.key === 'Enter' && !e.shiftKey) || eventTypes.includes(e.type)) {
        onChangeValues();
      }
    },
    [onChangeValues]
  );

  const initialValues = useMemo(() => {
    return {
      [fieldId]: {
        boolean: boolean !== undefined && boolean !== null ? boolean.toString() : undefined
      }
    };
  }, [boolean, fieldId]);

  return (
    <div ref={containerRef} className={className}>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        className={classNames(styles.container, styles.fields)}
      >
        <div className={styles.field}>
          <Form.Item name={[fieldId, 'boolean']}>
            <Select
              onChange={onChangeValues}
              getPopupContainer={trigger => trigger.parentNode}
              className={styles.control}
              dropdownClassName={styles.select}
              allowClear
              clearIcon={
                <Tooltip title={t('Common.Blocks.deleteField')}>
                  <Button type={'text'} icon={<FontAwesomeIcon icon={faTimes} />} />
                </Tooltip>
              }
            >
              <Select.Option key={`true`} value={`true`}>
                <span className={classNames(styles.block, !!className && className)}>
                  <FontAwesomeIcon icon={faCheck} className={classNames(styles.booleanIcon, styles.true)} />
                  <span>{t('Common.yes')}</span>
                </span>
              </Select.Option>
              <Select.Option key={`false`} value={`false`}>
                <span className={classNames(styles.block, !!className && className)}>
                  <FontAwesomeIcon icon={faXmark} className={styles.booleanIcon} />
                  <span>{t('Common.no')}</span>
                </span>
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EditableBlockBoolean;
