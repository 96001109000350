import { orderBy, minBy } from 'lodash';
import {
  IApiTenderBox,
  ITenderCat,
  TenderBlockSize,
  ITenderSubCat,
  ITenderBox,
  isFieldDate,
  IApiTenderLot,
  ITenderLot,
  ITenderCatMap,
  ITenderSubCatMap,
  IApiTenderFileCategory,
  IApiProcurementFile,
  IProcurementFile,
  IProcurementFilesCategory,
  IGenericCat,
  IGenericSubCat,
  TenderSubCatName,
  TenderCatName
} from './types';
import { TenderBoxFieldType } from '@tendium/prom-types/tender';
import {
  CAT_ICON_MAPPER,
  SUB_CATS_ORDER,
  CATS_ORDER,
  SUB_CAT_ICON_MAPPER,
  FILE_CATEGORY_UNKNOWN,
  LOCALE_MAP
} from './mappers';
import { TenderBox } from '..';
import { isNotUndefined } from 'src/helpers';
import { Maybe } from 'src/helpers/typescript';
import { Language } from '@tendium/prom-types';

export function convertApiLotsToLots(apiLots?: IApiTenderLot[]): ITenderLot[] {
  return apiLots
    ? apiLots
        .map(apiLot => ({ ...apiLot, lotItem: Number(apiLot.lotItem) }))
        .slice()
        .sort((a, b) => a.lotItem - b.lotItem)
    : [];
}

export function sortByNotEmptyField(aBox: ITenderBox, bBox: ITenderBox): number {
  const aBoxDiff = aBox.isEmpty ? false : aBox.rawFields.length === aBox.fields.length;
  const bBoxDiff = bBox.isEmpty ? false : bBox.rawFields.length === bBox.fields.length;

  if (aBoxDiff && !bBoxDiff) {
    return -1;
  }
  if (bBoxDiff && !aBoxDiff) {
    return 1;
  }
  return 0;
}

export function isFirstPriorBox(box: ITenderBox): boolean {
  return !box.isEmpty && box.lots.length === 0;
}

export function isSecondPriorBox(box: ITenderBox, lots: ITenderLot[]): boolean {
  return !box.isEmpty && lots.length === box.lots.length && box.lots.length !== 0;
}

export function isAddition(boxes?: ITenderBox[]): boolean {
  if (!boxes || (!!boxes && !boxes.length)) {
    return false;
  } else {
    if (boxes.length > 1) {
      return !!boxes.filter(box => !box.isEmpty).length;
    } else {
      return boxes[0].rawFields.length > 1 && !boxes[0].isEmpty;
    }
  }
}

// TODO: refactor after release (make a factory, support different filtering options: by date/by lot)
export function filterBoxes(boxes: IApiTenderBox[], lots: ITenderLot[]): ITenderBox[] {
  if (!boxes.length) {
    return [];
  }
  const filteredBoxes = boxes.map(box => !!box && new TenderBox(box)).filter(isNotUndefined);
  if (!lots.length) {
    return filteredBoxes.sort(sortByNotEmptyField);
  }
  const noLotsBoxes = filteredBoxes.filter(box => isFirstPriorBox(box));
  const allLotsBoxes = filteredBoxes.filter(box => isSecondPriorBox(box, lots));
  const lotsBoxes = filteredBoxes.filter(box => !isFirstPriorBox(box) && !isSecondPriorBox(box, lots));
  const orderedLotsBoxes = orderBy(lotsBoxes, box => minBy(box.lots, lot => lot.lotItem)?.lotItem, 'asc');

  return [...noLotsBoxes, ...allLotsBoxes, ...orderedLotsBoxes].sort(sortByNotEmptyField);
}

export function groupBoxesByHeliumId(boxes: ITenderBox[]): ITenderBox[][] {
  const map = boxes.reduce((boxesMap, box) => {
    if (!boxesMap.has(box.heliumId)) {
      boxesMap.set(box.heliumId, [box]);
    } else {
      boxesMap.get(box.heliumId)?.push(box);
    }
    return boxesMap;
  }, new Map<string, ITenderBox[]>());
  return [...map.values()];
}

export function groupBoxesToCats(boxes: IApiTenderBox[], lots: ITenderLot[], includeQA: boolean): ITenderCat[] {
  const filteredBoxes = filterBoxes(boxes, lots).filter(box => box.fields && !!box.fields.length);
  const group: Map<string, ITenderCatMap> = filteredBoxes.reduce((catMap, box) => {
    const [catId, subCatId] = box.category.split('.');
    const newSubCat: ITenderSubCatMap = {
      id: box.category,
      title: subCatId,
      boxes: new Map().set(box.id, box),
      size: TenderBlockSize.Full,
      icon: SUB_CAT_ICON_MAPPER[subCatId] ?? SUB_CAT_ICON_MAPPER['OTHER']
    };
    if (!catMap.has(catId)) {
      catMap.set(catId, {
        id: catId,
        title: catId,
        icon: CAT_ICON_MAPPER[catId] ?? CAT_ICON_MAPPER['OTHER'],
        subCats: new Map().set(box.category, newSubCat)
      });
    } else {
      if (catMap.get(catId)?.subCats.has(box.category)) {
        catMap.get(catId)?.subCats.get(box.category)?.boxes.set(box.id, box);
      } else {
        catMap.get(catId)?.subCats.set(box.category, newSubCat);
      }
    }

    return catMap;
  }, new Map<string, ITenderCatMap>());

  if (includeQA && !group.has('ADDITIONS_AND_QA')) {
    const catId = 'ADDITIONS_AND_QA';
    group.set(catId, {
      id: catId,
      title: catId,
      icon: CAT_ICON_MAPPER[catId] ?? CAT_ICON_MAPPER['OTHER'],
      subCats: new Map()
    });
  }

  const rawCats = [...group.values()].map(cat => ({
    ...cat,
    subCats: [...cat.subCats.values()].map(subCat => ({ ...subCat, boxes: [...subCat.boxes.values()] }))
  }));
  const sortedCats = [...rawCats]
    .sort((aCat: ITenderCat, bCat: ITenderCat) => {
      const aCatIdx = CATS_ORDER.findIndex(catOrder => catOrder === aCat.title);
      const bCatIdx = CATS_ORDER.findIndex(catOrder => catOrder === bCat.title);
      return aCatIdx - bCatIdx;
    })
    .map(cat => {
      const { subCats } = cat;
      const sortedSubCats = [...subCats]
        .sort((aSubCat: ITenderSubCat, bSubCat: ITenderSubCat) => {
          const aSubCatIdx = SUB_CATS_ORDER.findIndex(subCatOrder => subCatOrder.id === aSubCat.id);
          const bSubCatIdx = SUB_CATS_ORDER.findIndex(subCatOrder => subCatOrder.id === bSubCat.id);
          return aSubCatIdx - bSubCatIdx;
        })
        .map(subCat => {
          const { boxes: subCatBoxes, id } = subCat;
          let sortedBoxes: ITenderBox[] = [];
          if (id === 'ADDITIONS_AND_QA.ADDITIONS' || id === 'ADDITIONS_AND_QA.QUESTIONS_AND_ANSWERS') {
            sortedBoxes = [...subCatBoxes].sort((aBox, bBox): number => {
              return sortDateBoxes(aBox, bBox);
            });
          } else {
            const BOX_ORDER = SUB_CATS_ORDER.find(sub => sub.id === id)?.boxes;
            sortedBoxes = BOX_ORDER
              ? [...subCatBoxes].sort((aBox: ITenderBox, bBox: ITenderBox) => {
                  const aBoxIdx = BOX_ORDER.findIndex(boxOrder => boxOrder === aBox.title);
                  const bBoxIdx = BOX_ORDER.findIndex(boxOrder => boxOrder === bBox.title);
                  return aBoxIdx - bBoxIdx;
                })
              : [...subCatBoxes].sort((aBox, bBox): number => {
                  const aLots = aBox.lots;
                  const bLots = bBox.lots;

                  if (aLots === undefined && bLots === undefined) {
                    return 0;
                  }
                  if (aLots === undefined) {
                    return -1;
                  }
                  if (bLots === undefined) {
                    return 1;
                  }

                  const aLotIdxMin = Math.min(...aLots.map(lot => lot.lotItem));
                  const bLotIdxMin = Math.min(...bLots.map(lot => lot.lotItem));

                  return aLotIdxMin - bLotIdxMin;
                });
          }
          return { ...subCat, boxes: sortedBoxes };
        });

      return { ...cat, subCats: sortedSubCats };
    });

  return sortedCats;
}

export function sortDateBoxes(aBox: ITenderBox, bBox: ITenderBox): number {
  const aDateField = aBox.fields && aBox.fields.find(field => field.type === TenderBoxFieldType.Date);
  const bDateField = bBox.fields && bBox.fields.find(field => field.type === TenderBoxFieldType.Date);

  if (!aDateField && !bDateField) {
    return 0;
  } else if (!aDateField && isFieldDate(bDateField)) {
    return 1;
  } else if (!bDateField && isFieldDate(aDateField)) {
    return -1;
  }

  return isFieldDate(aDateField) && !!aDateField.date && isFieldDate(bDateField) && bDateField.date
    ? aDateField.date - bDateField.date
    : 0;
}

// TODO: refactor this after switch to v2, copied from v1
export function toDocsSubCats(apiFileCategories: IApiTenderFileCategory[]): IProcurementFilesCategory {
  const mapFilesToMultiLevelTree = (fileCategories: IApiProcurementFile[]): IProcurementFile[] => {
    const filesTree = fileCategories.reduce(
      (resultTree: IProcurementFile[], currentFile: IApiProcurementFile): IProcurementFile[] => {
        if (!currentFile.fileName) return [];
        const paths = currentFile.fileName.split('/');
        paths.reduce((currentLevel: IProcurementFile[] | undefined, currentPath: string) => {
          if (!currentLevel) return [];
          let temp: IProcurementFile | undefined = currentLevel.find(
            (file: IProcurementFile) => file.fileName === currentPath
          );
          if (!temp) {
            const fileObject: IProcurementFile = {
              fileName: currentPath,
              originalFileName: currentFile.fileName,
              fileType: currentFile.fileType,
              targetPath: currentFile.targetPath,
              dateUploaded: new Date(currentFile.dateUploaded),
              children: []
            };
            temp = fileObject;
            currentLevel.push(fileObject);
          }
          return temp.children;
        }, resultTree);
        return resultTree;
      },
      []
    );
    return filesTree;
  };

  const sortMultiLevelTree = (multiLevelTree: IProcurementFile[]): IProcurementFile[] => {
    const sorter = (a: IProcurementFile, b: IProcurementFile): number => {
      if (!a.directoryName) {
        return 1;
      }
      if (!b.directoryName) {
        return -1;
      }
      if (a.directoryName < b.directoryName) {
        return -1;
      }
      if (a.directoryName > b.directoryName) {
        return 1;
      }
      return 0;
    };

    multiLevelTree.sort((a, b) => sorter(a, b));
    multiLevelTree.forEach(function loop(val) {
      val.children?.sort(sorter).map(x => loop(x));
      return val;
    });

    return multiLevelTree;
  };

  let filesAmount = 0;

  const fileCategories = apiFileCategories.map((value: IApiTenderFileCategory) => {
    filesAmount += value.files.length;
    const multiLevelTreeFiles = mapFilesToMultiLevelTree(value.files);
    return {
      name: value.name || FILE_CATEGORY_UNKNOWN,
      description: value.description || FILE_CATEGORY_UNKNOWN,
      children: sortMultiLevelTree(multiLevelTreeFiles)
    };
  });

  return {
    filesAmount,
    fileCategories
  };
}

export function formatCurrencyValue(value: number, language?: Language, currency?: string): string {
  const locale = language ? LOCALE_MAP[language]?.code : 'en-US';
  const options: Intl.NumberFormatOptions = {
    notation: 'compact'
  };

  if (currency) {
    options.style = 'currency';
    options.currency = currency;
    options.currencyDisplay = 'code';
  }

  return new Intl.NumberFormat(locale, options).format(value);
}

export function toGenericCat(catName: TenderCatName | string): IGenericCat {
  return {
    id: catName,
    title: catName,
    icon: CAT_ICON_MAPPER[catName] || CAT_ICON_MAPPER['OTHER']
  };
}
export function toGenericSubCat(subCatName: TenderSubCatName): IGenericSubCat {
  return {
    id: subCatName,
    title: subCatName,
    icon: SUB_CAT_ICON_MAPPER[subCatName]
  };
}

export function getQuestionId(box: IApiTenderBox): Maybe<string> | null {
  if (!box.category?.includes('ADDITIONS_AND_QA')) {
    return null;
  }

  const idField = box.fields.find(field => field.name === 'ID');

  return idField && 'string' in idField ? idField.string : null;
}
