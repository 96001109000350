export default function isISOString(u: unknown): boolean {
  if (!u || typeof u !== 'string') return false;
  try {
    const dt = new Date(u).getTime();
    const str = new Date(dt).toISOString();
    return str === u;
  } catch (error) {
    return false;
  }
}
