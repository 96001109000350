import React, { FC } from 'react';
import styles from './index.module.scss';
import { Header } from 'src/shared';
import { Skeleton } from 'src/common';

const BasicSkeleton: FC = () => (
  <div className={styles.container}>
    <Header />
    <div className={styles.main}>
      <Skeleton active loading />
    </div>
  </div>
);

export default BasicSkeleton;
