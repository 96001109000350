import { useMemo } from 'react';
import dayjs from 'src/helpers/dayjs';
import { useTranslation } from 'react-i18next';

export function useDeadlineDate(
  deadline: number | null,
  showExpiredDate?: boolean
): { value: string; isExpired?: boolean } {
  const { t } = useTranslation();
  const now = dayjs().utc().startOf('day');

  const deadlineValue = useMemo(() => {
    if (!deadline) return { value: t('Common.notSet') };

    const deadlineDate = dayjs(deadline).utc().startOf('day');
    const diffInDays = deadlineDate.diff(now, 'day');
    const currentYear = now.year();
    const deadlineYear = deadlineDate.year();

    const formatExpiredDate = (
      deadlineDate: dayjs.Dayjs,
      absDiffInDays: number,
      deadlineYear: number,
      currentYear: number
    ): string => {
      if (!showExpiredDate) return t('Common.expired');
      if (absDiffInDays === 1) return t('Common.yesterday');
      if (absDiffInDays <= 7) return `${absDiffInDays} ${t('Common.days')} ${t('Common.ago')}`;
      if (deadlineYear < currentYear) return deadlineDate.format('D MMM YYYY');

      return deadlineDate.format('D MMM');
    };

    const formatDeadline = (
      deadlineDate: dayjs.Dayjs,
      diffInDays: number,
      deadlineYear: number,
      currentYear: number
    ): string => {
      if (diffInDays < 0) return formatExpiredDate(deadlineDate, Math.abs(diffInDays), deadlineYear, currentYear);
      if (diffInDays === 0) return t('Common.today');
      if (diffInDays === 1) return t('Common.tomorrow');
      if (diffInDays <= 7) return `${diffInDays} ${t('Common.days')}`;

      const dateFormat = deadlineYear > currentYear ? 'D MMM YYYY' : 'D MMM';
      return deadlineDate.format(dateFormat);
    };

    const value = formatDeadline(deadlineDate, diffInDays, deadlineYear, currentYear);
    const isExpired = diffInDays < 0;

    return { value, isExpired };
  }, [deadline, now, showExpiredDate, t]);

  return deadlineValue;
}

export default useDeadlineDate;
