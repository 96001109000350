import React, { FC, ReactElement, ReactNode, useMemo } from 'react';
import styles from '../index.module.scss';
import { AssignmentType } from 'src/models/users/types';
import { useTranslation } from 'react-i18next';
import { AssignedTo } from 'src/models/users/types';
import Assignee from 'src/shared/Assignee';

interface Props {
  users: AssignedTo[];
  wrapper?: (user: AssignedTo, children: ReactNode) => ReactElement;
}

const UserGroups: FC<Props> = ({ users, wrapper }) => {
  const { t } = useTranslation();
  const grouppedUsers = useMemo(() => {
    const userMap = new Map<string, AssignedTo[]>();
    Object.keys(AssignmentType).reduce((map, key) => {
      const filteredUsers = users.filter(user => user.type === key);
      filteredUsers.length && map.set(key, filteredUsers);
      return map;
    }, userMap);
    return Array.from(userMap.entries());
  }, [users]);
  return (
    <>
      {grouppedUsers.map(([key, users]) => {
        return (
          !!users.length && (
            <React.Fragment key={`group_${key}`}>
              {grouppedUsers.length > 1 && (
                <div key={`group_title_${key}`} className={styles.groupTitle}>
                  {t(`Common.AssignmentType.${key}`, { count: 2 })}
                </div>
              )}

              {users.map(user => {
                const children = <Assignee key={user.id} assignedToData={user} size={'s'} />;
                return wrapper ? wrapper(user, children) : children;
              })}
            </React.Fragment>
          )
        );
      })}
    </>
  );
};
export default UserGroups;
