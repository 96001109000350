import { gql } from '@apollo/client';

export const TAG_FIELDS = gql`
  fragment tagFields on ContentLibraryTag {
    id
    title
    color
  }
`;

export const GET_CONTENT_LIBRARY_TAGS = gql`
  query getContentLibraryTags($query: GetContentLibraryTagsInput!) {
    getContentLibraryTags(query: $query) {
      ...tagFields
    }
  }
  ${TAG_FIELDS}
`;

export const DELETE_CONTENT_LIBRARY_TAG = gql`
  mutation DeleteContentLibraryTag($input: DeleteContentLibraryTagInput!) {
    deleteContentLibraryTag(input: $input)
  }
`;

export const CREATE_CONTENT_LIBRARY_TAG = gql`
  mutation CreateContentLibraryTag($input: CreateContentLibraryTagInput!) {
    createContentLibraryTag(input: $input) {
      ...tagFields
    }
  }
  ${TAG_FIELDS}
`;
