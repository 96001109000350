import { Language } from '@tendium/prom-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from 'src/models/auth';

export function useLanguage(): { language: Language } {
  const { i18n } = useTranslation();
  const { user } = useApp();

  return useMemo(
    () => ({
      language: (user?.language as Language) || (i18n.language as Language)
    }),
    [i18n.language, user]
  );
}

export default useLanguage;
