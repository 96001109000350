import { createContext, Dispatch, SetStateAction } from 'react';
import TenderFullData from '.';

export type TenderContextValue = {
  data: TenderFullData | null;
  loading: boolean;
};
export const TenderContext = createContext<TenderContextValue>({
  data: null,
  loading: false
});

export const CurrentTenderBoxContext = createContext<string | null>(null);
CurrentTenderBoxContext.displayName = 'CurrentTenderBoxContext';

export const UpdateCurrentTenderBoxContext = createContext<Dispatch<SetStateAction<string | null>> | null>(null);
UpdateCurrentTenderBoxContext.displayName = 'UpdateCurrentTenderBoxContext';
