import React, { FC } from 'react';
import { useStagesBids } from 'src/models/bids/Bids/hooks';
import { List } from 'src/common';
import BidListItem from '../../../BidListItem';
import { BidsColumn, BidsDashboardCardMode, SortOrder } from 'src/models/bids/Bids/types';

interface Props {
  amount: number;
  assignedToId: string;
  mode: BidsDashboardCardMode;
}

export const BidsList: FC<Props> = ({ amount, assignedToId, mode }) => {
  const { data, loading } = useStagesBids({
    amount,
    assignedToId,
    sort: {
      name: BidsColumn.deadline,
      order: SortOrder.ASC
    }
  });

  return (
    <List items={data?.bids} loading={loading}>
      {bid => bid && <BidListItem bid={bid} mode={mode} />}
    </List>
  );
};
