import React, { FC, useCallback, useState, useRef } from 'react';
import { IBoxFieldDateRange } from 'src/models/procurements/Tender/types';
import { useKey, useClickAway } from 'react-use';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import EditableBlockDateRange from './EditableBlock';
import ReadOnlyBlockDateRange from './ReadOnlyBlock';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AddValueButton from '../AddValueButton';
import { FieldViewMode, IBlockProps } from 'src/shared/Tender/Boxes/types';

interface IProps extends IBlockProps<IBoxFieldDateRange> {}
export const BlockDateRange: FC<IProps> = ({ field, onChange, viewMode, isEditable }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [currentMode, setCurrentMode] = useState(viewMode || FieldViewMode.Read);

  const switchToReadMode = useCallback(() => {
    if (isEditable && currentMode === FieldViewMode.Edit) {
      setCurrentMode(FieldViewMode.Read);
    }
  }, [currentMode, isEditable]);

  const onToggleView = useCallback(() => {
    setCurrentMode(prev => (prev === FieldViewMode.Read ? FieldViewMode.Edit : FieldViewMode.Read));
  }, []);

  useKey('Escape', () => setCurrentMode(FieldViewMode.Read));
  useClickAway(ref, e => {
    e.stopPropagation();
    e.preventDefault();
    switchToReadMode();
  });

  const onSave = useCallback(
    (val: IBoxFieldDateRange) => {
      onChange?.(val);
    },
    [onChange]
  );

  const onFinish = useCallback(() => {
    switchToReadMode();
  }, [switchToReadMode]);

  return (
    <>
      {currentMode === FieldViewMode.Edit && (
        <EditableBlockDateRange field={field} onChange={onSave} onFinish={onFinish} containerRef={ref} />
      )}
      {field.from || field.to ? (
        <div className={classNames(styles.toggler, { [styles.isEditable]: isEditable })} onClick={onToggleView}>
          <ReadOnlyBlockDateRange from={field.from} to={field.to} className={styles.text} />
          {isEditable && (
            <div className={styles.togglerIcon}>
              <FontAwesomeIcon icon={faPen} />
            </div>
          )}
        </div>
      ) : (
        <AddValueButton onToggleView={onToggleView} isEditable={!!isEditable} title={t('Common.Blocks.addDuration')} />
      )}
    </>
  );
};

export default BlockDateRange;
