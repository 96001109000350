import { FieldPolicy, Reference } from '@apollo/client';
import { TasksCached, TasksCachedResult, BiddingStatsCached } from './types';

export function getTasksPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['groupId', 'type', 'isSubmitted', 'filters']],
    read(existing: TasksCachedResult) {
      if (!existing) {
        return;
      }
      if (!!existing) {
        return existing.value;
      }
    },
    merge(existing: TasksCachedResult, incoming: TasksCached, { variables }) {
      let merged = existing?.value ? [...existing.value.questions] : [];
      if (incoming) {
        if (variables) {
          const { offset } = variables;
          for (let i = 0; i < incoming.questions.length; ++i) {
            merged[offset + i] = incoming.questions[i];
          }
        } else {
          merged = [...merged, ...incoming.questions];
        }
        for (let i = 0; i < incoming.totalCount; ++i) {
          merged[i] = merged[i] ?? null;
        }
      }

      return {
        value: {
          ...incoming,
          questions: merged
        },
        variables
      };
    }
  };
}

export function getBiddingStatsPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['bidId', 'filters', 'isSubmitted']],
    read(existing: BiddingStatsCached) {
      if (!existing) {
        return;
      }
      if (!!existing) {
        return existing.value;
      }
    },
    merge(existing: BiddingStatsCached, incoming: Reference[], { variables }) {
      return {
        value: incoming,
        variables
      };
    }
  };
}
