import React, { FC } from 'react';
import { getDurationFromPeriod, PeriodType, toDateRangeStr } from 'src/helpers/dates';
import { getSupplierNameByOrgId, IApiOrganization } from 'src/models/organisations';
import { PeriodTransaction } from 'src/models/procurements/Billing/types';
import { getRatioOfTotalReceived, toCurrencyValue } from 'src/models/procurements/Billing/helpers';
import styles from '../index.module.scss';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'src/common';

interface Props {
  displayView: PeriodType;
  transaction: PeriodTransaction;
  cumulativeValue: number;
  currency?: string;
  colorScale: (d: string) => string;
  suppliers: IApiOrganization[];
}

const HoverTooltip: FC<Props> = ({ transaction, cumulativeValue, colorScale, suppliers, displayView }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { period, supplierOrgId, currency, amount } = transaction;
  const formatedPeriod = toDateRangeStr(getDurationFromPeriod(period, displayView), t);
  const formatedAmount = toCurrencyValue(language, amount, currency);
  const formatedTotal = toCurrencyValue(language, cumulativeValue, currency);
  const ratio = getRatioOfTotalReceived(amount, cumulativeValue);
  const supplierName = getSupplierNameByOrgId(suppliers, supplierOrgId);

  return (
    <div className={styles.maxWidth}>
      <div className={styles.title}>
        <div className={styles.square} style={{ backgroundColor: colorScale(supplierOrgId) }}></div>
        <div>{supplierName}</div>
      </div>
      <div className={styles.secondaryTitle}>{t('Tenders.Billing.duration')}</div>
      <div className={styles.text}>{formatedPeriod}</div>
      <div className={styles.secondaryTitle}>{t('Tenders.Billing.supplierPayments')}</div>
      <div className={styles.text}>
        {formatedAmount} {t('Tenders.Billing.paymentRatio', { ratio })}
      </div>
      <div className={styles.secondaryTitle}>{t('Tenders.Billing.totalPaymentValue')}</div>
      <div className={styles.text}>{formatedTotal}</div>
    </div>
  );
};

export default HoverTooltip;
