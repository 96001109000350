import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Letter } from 'react-letter';
import classNames from 'classnames';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import BidPreviewAttachments from 'src/pages/BidsPage/BidPreview/Attachments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import styles from './index.module.scss';
import { IInboxEmail } from 'src/models/callOffs/types';

export const BidPreviewEmail: FC = () => {
  const { data } = useBidPreview();
  const mails = data?.mails;
  const procurementId = data?.origin.id;
  const { t } = useTranslation();
  const isMultipleMails = mails && mails.length > 1;
  const [activeEmail, setActiveEmail] = useState(mails ? mails[0] : null);
  const toggleActiveEmail = useCallback(
    (mail: IInboxEmail) => {
      if (activeEmail && activeEmail.mailId === mail.mailId) {
        return;
      }
      setActiveEmail(mail);
    },
    [activeEmail]
  );

  return mails ? (
    <>
      {isMultipleMails && (
        <div className={styles.emailsWrapper}>
          <ul className={styles.emails}>
            {mails.map(mail => (
              <li
                className={classNames(styles.email, {
                  [styles.active]: activeEmail && activeEmail.mailId === mail.mailId
                })}
                key={mail.mailId}
                onClick={() => toggleActiveEmail(mail)}
              >
                <div className={styles.emailDate}>
                  {t('Common.Format.tableDateFormatThisYear', { date: new Date(mail.date) })}
                </div>
                <div className={styles.emailSubject}>{mail.subject || `(${t('CallOffs.noSubject')})`}</div>
                {mail.attachments && mail.attachments.length > 0 && (
                  <div className={styles.emailIcon}>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className={styles.divider}></div>
        </div>
      )}
      {activeEmail && (
        <div
          className={classNames(styles.letterWrapper, {
            [styles.withBorder]: isMultipleMails
          })}
        >
          <Letter className={styles.letter} html={activeEmail.htmlContent || activeEmail.content || '—'} />
          {activeEmail.attachments && activeEmail.attachments.length > 0 && (
            <BidPreviewAttachments
              attachments={activeEmail.attachments}
              zip={activeEmail.zip}
              procurementId={procurementId}
            />
          )}
        </div>
      )}
    </>
  ) : null;
};

export default BidPreviewEmail;
