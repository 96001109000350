import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, ClearableInput } from 'src/common';
import styles from './index.module.scss';
import { ApiContentLibraryVars } from 'src/models/contentLibrary/ContentLibrary/types';
import { useBidspacesQpVars, useBidsFilterVars } from 'src/models/bids/Bids/hooks';
import { debounce } from 'lodash';

interface Props {
  className?: string;
  placeholder?: string;
}
type FormState = Pick<ApiContentLibraryVars, 'search'>;

const BidspacesFilterSearch: FC<Props> = ({ className, placeholder }) => {
  const { t } = useTranslation();

  const { updateVars } = useBidsFilterVars();
  const vars = useBidspacesQpVars();
  const [form] = Form.useForm<FormState>();

  const [searchFocus, setSearchFocus] = useState(false);

  const onFocus = useCallback(() => setSearchFocus(true), []);

  const onBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!value) {
      setSearchFocus(false);
    }
  }, []);

  const values = useMemo(() => ({ search: vars.search }), [vars.search]);

  const onValuesChange = useMemo(
    () =>
      debounce((changedValues: FormState) => {
        updateVars?.({ search: changedValues.search });
      }, 300),
    [updateVars]
  );

  const onClear = useCallback(() => {
    form.setFieldsValue({ search: '' });
    updateVars?.({ search: undefined });
  }, [form, updateVars]);

  useEffect(() => {
    if (vars.search !== form.getFieldValue('search')) {
      form.setFieldsValue({ search: vars.search });
    }
  }, [form, vars.search]);

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      initialValues={values}
      className={classNames(styles.form, className, { [styles.isFocus]: searchFocus })}
    >
      <Form.Item name={'search'} className={styles.searchField} htmlFor={'search-main'}>
        <ClearableInput
          id={'search-main'}
          placeholder={placeholder ?? t('Common.search')}
          onFocus={onFocus}
          onBlur={onBlur}
          onClear={onClear}
        />
      </Form.Item>
    </Form>
  );
};

export default BidspacesFilterSearch;
