import { gql } from '@apollo/client';

export const REMINDER_FIELDS = gql`
  fragment reminderFields on Reminder {
    id
    message
    createdDate
    dueDate
    type
    relationId
    status
    title
    assignedTo {
      id
    }
    createdBy {
      email
    }
  }
`;

export const CREATE_REMINDER = gql`
  mutation createReminder($reminder: CreateReminderInput!) {
    createReminder(input: $reminder) {
      ...reminderFields
    }
  }
  ${REMINDER_FIELDS}
`;

export const GET_REMINDERS = gql`
  query getReminders($relationId: String, $assignedTo: String, $createdBy: String, $from: Float, $size: Float) {
    getReminders(
      input: { relationId: $relationId, assignedTo: $assignedTo, createdBy: $createdBy, from: $from, size: $size }
    ) {
      ...reminderFields
    }
  }
  ${REMINDER_FIELDS}
`;

export const GET_REMINDERS_COUNT = gql`
  query getRemindersCount($input: GetRemindersInput!) {
    getRemindersCount(input: $input) {
      totalCount
    }
  }
`;

export const DELETE_REMINDER = gql`
  mutation deleteReminder($deleteReminderId: String!) {
    deleteReminder(id: $deleteReminderId) {
      id
    }
  }
`;

export const HANDLE_REMINDER = gql`
  mutation handleReminder($handleReminderId: String!) {
    handleReminder(id: $handleReminderId) {
      id
      status
    }
  }
`;
