import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'src/common';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';

interface Props {
  className?: string;
}

export const AnalyticsDemoBanner: FC<Props> = ({ className = '' }) => {
  const { t } = useTranslation();
  const isAnalyticsPagesDemo = useFeatureFlag(FeatureFlag.Analytics_Pages_Demo);
  if (!isAnalyticsPagesDemo) return null;
  return (
    <Alert
      message={t('MarketDashboard.demoInfoBanner')}
      type="info"
      showIcon
      className={classNames(styles.analyticsDemoBanner, { [className]: !!className })}
    />
  );
};

export default AnalyticsDemoBanner;
