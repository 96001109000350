import React, { FC, KeyboardEvent, useCallback, useMemo, useRef } from 'react';
import styles from '../index.module.scss';
import { Radio, Form } from 'src/common';
import { useTranslation } from 'react-i18next';
import { HslStringColorPicker } from 'react-colorful';
import { isHsl, isHex, hexToHsl, isRgb, rgbToHsl } from 'src/helpers/styles';
import { ColorPickerMode } from '..';
import { useClickAway } from 'react-use';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IPickerColor {
  raw: string;
  hsl: string;
}

interface IFormState {
  paletteColor: IPickerColor | null;
}

interface IProps {
  onChange?: (color: string) => void;
  onSubmit: (color: string) => void;
  currentColor: string | null;
  paletteColors?: string[];
  pickerMode?: ColorPickerMode;
  onClose: () => void;
}

export const ColorPickerOverlay: FC<IProps> = (props: IProps) => {
  const { onChange, onSubmit, currentColor, paletteColors, pickerMode, onClose } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const ref = useRef<HTMLDivElement>(null);

  const onKeyPress = useCallback((e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const pickerColors: IPickerColor[] = useMemo(() => {
    return paletteColors
      ? paletteColors.map(paletteColor => {
          let hsl = '';
          if (isHsl(paletteColor)) {
            hsl = paletteColor;
          } else if (isHex(paletteColor)) {
            hsl = hexToHsl(paletteColor);
          } else if (isRgb(paletteColor)) {
            hsl = rgbToHsl(paletteColor);
          }
          return {
            raw: paletteColor,
            hsl
          };
        })
      : [];
  }, [paletteColors]);

  const pickerCurrentColor: IPickerColor | null = useMemo(() => {
    let hsl = '';
    if (!currentColor) return null;
    if (isHsl(currentColor)) {
      hsl = currentColor;
    } else if (isHex(currentColor)) {
      hsl = hexToHsl(currentColor);
    } else if (isRgb(currentColor)) {
      hsl = rgbToHsl(currentColor);
    }
    return {
      raw: currentColor,
      hsl
    };
  }, [currentColor]);

  const initialValues = useMemo((): IFormState => {
    return {
      paletteColor: pickerCurrentColor || null
    };
  }, [pickerCurrentColor]);

  const onColorChangeFromPalette = useCallback(
    (changedValues: Partial<IFormState>) => {
      if (!changedValues.paletteColor) {
        return;
      }
      onChange && onChange(changedValues.paletteColor.raw);
    },
    [onChange]
  );

  useClickAway(ref, () => {
    onSubmit(pickerCurrentColor?.raw || '');
    onClose();
  });

  return (
    <div className={styles.dropdown} ref={ref}>
      <div className={styles.title}>
        <span>{t('Common.selectColor')}</span>
      </div>
      <button onClick={onClose} className={styles.closeButton}>
        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
      </button>
      <div className={styles.dropdownInner}>
        {pickerMode !== 'palette' && (
          <div className={styles.pickerContent}>
            <HslStringColorPicker
              className={styles.colorPicker}
              color={pickerCurrentColor ? pickerCurrentColor.hsl : undefined}
              onChange={onChange}
            />
          </div>
        )}
        {pickerMode !== 'picker' && (
          <Form
            initialValues={initialValues}
            onValuesChange={onColorChangeFromPalette}
            form={form}
            layout={'vertical'}
            className={styles.paletteContent}
            onKeyPress={e => onKeyPress(e)}
          >
            <Form.Item name={'paletteColor'} className={styles.field}>
              <Radio.Group className={styles.eventTypeCheckboxes}>
                {pickerColors.map(color => (
                  <Radio.Button
                    key={color.raw}
                    value={color}
                    className={styles.radioButton}
                    style={{ color: color.hsl }}
                  >
                    <div className={styles.radioIcon}>
                      <FontAwesomeIcon icon={faCheck} className={styles.icon} />
                    </div>
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default ColorPickerOverlay;
