import { PreviewDescriptionSetting } from 'src/models/users/Preferences/types';
import { safeCallSegment } from '../helpers';

export type PreviewDescriptionEventSource = 'Bid preview' | 'Tender preview';

export function trackUpdatePreviewDescription(
  eventSource: PreviewDescriptionEventSource,
  type: PreviewDescriptionSetting
): void {
  safeCallSegment(() => {
    analytics.track('Preview Overview', {
      eventSource,
      type
    });
  });
}
