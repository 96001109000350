/** workaround for issue with page-refresh if emailAddress used as a slug eg call-offs/:emailAddress
 *
 * page goes down, not blank, not error-page, just `page address unavailable` or something similar
 */
export const INBOX_DOMAIN = import.meta.env.VITE_INBOX_DOMAIN;

export function toInboxEmail(inboxId: string): string {
  return `${inboxId}${INBOX_DOMAIN}`;
}
export function toInboxId(inboxEmail: string): string {
  const [inboxId] = inboxEmail.split('@');
  return inboxId;
}
