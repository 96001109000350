import React, { FC, useMemo } from 'react';
import { Alert } from 'src/common';
import { BuyerCoverageGap } from 'src/models/procurements/Billing/types';
import styles from '../index.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  buyerCoverageGaps: BuyerCoverageGap[];
  displaySupplierLimits: number; // 0 means display nothing
}

const MissingDataAlert: FC<Props> = ({ buyerCoverageGaps, displaySupplierLimits }) => {
  const { t } = useTranslation();
  const dates = buyerCoverageGaps.map(d => d.period).join(', ');
  const alertText = useMemo(() => {
    if (dates && displaySupplierLimits) {
      return t('Tenders.Billing.combineTips', { dates, count: displaySupplierLimits });
    }
    if (dates) {
      return t('Tenders.Billing.missingValueTips', { dates });
    }
    if (displaySupplierLimits) {
      return t('Tenders.Billing.maxSuppliersTips', { count: displaySupplierLimits });
    }
    return null;
  }, [dates, displaySupplierLimits, t]);
  return <>{!!alertText && <Alert className={styles.alert} message={alertText} type={'warning'} showIcon />}</>;
};

export default MissingDataAlert;
