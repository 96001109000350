import { IApiTender } from '../Tender/types';

export interface ApiTenderOutcomeResponse {
  readonly getTender: ApiTenderOutcome;
}
export interface ApiTenderOutcomeRequest {
  procurementId: string;
}

export type ApiTenderOutcome = Pick<IApiTender, 'id' | 'fileCategories' | 'lots' | 'boxes'>;

export enum BidderBoxFieldName {
  BidderStatus = 'Bidder status',
  BidderName = 'Bidder name',
  NationalRegistrationNumber = 'National registration number',
  OfferedPrice = 'Offered price according to evaluation model'
}

export enum FileCategory {
  TenderEvaluationDocuments = 'Tender evaluation documents'
}
