import { Reference } from '@apollo/client';
import { GetRemindersInput, ReminderStatus, ReminderType } from '@tendium/prom-types/schema';

export interface Reminder {
  id: string;
  message: string;
  createDate: Date;
  dueDate: Date;
  type: ReminderType;
  assignedTo: { id: string };
  createdBy: { email: string };
  status: ReminderStatus;
  relationId?: string;
  title?: string;
}

export interface ApiRemindersResponse {
  getReminders: Reminder[];
}

export interface ApiRemindersRequest {
  input: GetRemindersInput;
}

export const DEFAULT_REMINDERS_LIMIT = 10;

export type RemindersCachedResult = {
  value: Reference[];
  variables?: GetRemindersInput;
};

export enum RemindersEventSource {
  bidPreview = 'Bid preview',
  customReportPage = 'Custom report page',
  biddingTool = 'Bidding tool',
  notificationList = 'Notification list'
}
