import { AssignedTo } from 'src/models/users/types';
import { IMember } from 'src/models/acl/types';
import { ApiAssignedTo } from '../users/AssignedTo/types';

export function getPreparedMembersArray(users: readonly string[], id: string, isOwner?: boolean): string[] {
  return isOwner ? users.filter(userId => userId !== id) : [...users, id];
}
function toMember(user: AssignedTo, isOwner: boolean): IMember {
  return { user, isOwner };
}
export function toMembersList(
  users: readonly Readonly<AssignedTo>[],
  owner: Readonly<AssignedTo>,
  userId?: string
): IMember[] {
  return [
    ...users.filter(user => user.id === owner.id).map(user => toMember(user, true)), // owners on the top
    ...users.filter(user => user.id === userId && user.id !== owner.id).map(user => toMember(user, true)),
    ...users.filter(user => user.id !== owner.id && user.id !== userId).map(user => toMember(user, false))
    // ...users.filter(a => a.email !== owner.email).map(x => toMember(x, false))
  ];
}

export function toOwnersAndSubscribers(usersAndTeams: ApiAssignedTo[], allUsersAndTeams: AssignedTo[]): AssignedTo[] {
  const assignmentIds = usersAndTeams.map(userOrTeam => userOrTeam.id);
  return allUsersAndTeams.filter(item => assignmentIds.includes(item.id));
}
