import React, { FC } from 'react';
import styles from './index.module.scss';
import { Skeleton } from 'src/common';

export const LoadingState: FC = () => {
  return (
    <div className={styles.container}>
      <Skeleton loading active />
    </div>
  );
};

export default LoadingState;
