import { WebhookEventType } from '@tendium/prom-types';
import { IApiWebhook, WebhookBidspaceSettings } from './types';

export class Webhook implements IApiWebhook {
  public readonly id: string;
  public readonly name: string;
  public readonly url: string;
  public readonly eventType: WebhookEventType;
  public readonly secret: string;
  public readonly bidspaceSetting: WebhookBidspaceSettings | undefined;

  constructor(apiWebhook: IApiWebhook) {
    this.id = apiWebhook.id;
    this.name = apiWebhook.name;
    this.url = apiWebhook.url;
    this.eventType = apiWebhook.eventType;
    this.secret = apiWebhook.secret;
    this.bidspaceSetting = apiWebhook.bidspaceSetting;
  }
}
