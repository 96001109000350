import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, Button } from 'src/common';
import { useCreateMp, getUpdateCacheOnCreateMp } from 'src/models/matchingProfiles/hooks';
import styles from './index.module.scss';
import { useNavigate } from 'react-router';
import { Paths } from 'src/pages/paths';
import { trackMpCreate } from 'src/segment/events';
import { OverviewQp } from 'src/models/procurements/types';
import qs from 'qs';
import { SidebarMode } from 'src/shared/InfoSidebar/types';

interface IFormState {
  name: string;
}

interface Props {
  onHide: () => void;
  eventSource?: string;
}

export const MatchingProfileCreate: FC<Props> = ({ onHide, eventSource }) => {
  const { t } = useTranslation();
  const [createMp, { loading: createMpLoading }] = useCreateMp();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const initialValues = useMemo((): IFormState => {
    return {
      name: ''
    };
  }, []);

  const onSubmit = useCallback(
    (values: IFormState) => {
      const { name } = values;
      createMp({
        variables: { name },
        update: getUpdateCacheOnCreateMp()
      })
        .then(({ data }) => {
          const mpId = data?.createMatchingProfile.id;
          if (!!mpId) {
            trackMpCreate({ id: mpId, eventSource, name });
            navigate({
              pathname: `${Paths.MONITORING}/${mpId}`,
              search: qs.stringify({
                [OverviewQp.PreviewId]: mpId,
                [OverviewQp.PreviewMode]: SidebarMode.MP_INFO
              })
            });
          }
          form.resetFields();
          onHide();
        })
        .catch(() => {
          form.setFields([
            {
              name: 'name',
              errors: [t('Common.unknownErrorDesc')]
            }
          ]);
        });
    },
    [createMp, eventSource, form, onHide, navigate, t]
  );

  return (
    <>
      <Modal
        title={t('Tenders.addNewMatchingProfile')}
        visible
        onCancel={onHide}
        footer={null}
        maskClosable
        width={500}
      >
        <Form className={styles.form} onFinish={onSubmit} initialValues={initialValues} form={form} layout={'vertical'}>
          <Form.Item
            label={t('Common.name')}
            name={'name'}
            rules={[
              { required: true, message: t('Common.inputRequired', { inputName: t('Tenders.monitoringProfile') }) },
              { max: 30, message: t('Common.inputTooLong', { inputName: t('Tenders.monitoringProfile') }) }
            ]}
          >
            <Input data-type={'name'} type={'text'} placeholder={t('Common.name')} />
          </Form.Item>
          <div className={styles.formFooter}>
            <Button type={'text'} onClick={onHide}>
              {t('Common.cancel')}
            </Button>
            <Button type={'primary'} htmlType={'submit'} loading={createMpLoading}>
              {t('Common.add')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default MatchingProfileCreate;
