import React, { FC } from 'react';
import Skeleton from 'src/common/Skeleton';
import styles from './index.module.scss';

interface IProps {
  loading: boolean;
  active?: boolean;
  width?: number | string;
}
export const SingleLineSkeleton: FC<IProps> = (props: IProps) => {
  const { loading, active, width } = props;
  return (
    <Skeleton
      paragraph={false}
      title={{
        className: styles.title,
        width
      }}
      loading={loading}
      active={active}
    />
  );
};
SingleLineSkeleton.displayName = 'SingleLineSkeleton';

export default SingleLineSkeleton;
