export enum NodeState {
  Checked = 1,
  ExactChecked = 2
}
export type NodesState = { readonly [key: string]: NodeState };

export interface IInputTreeNode {
  key: string;
  label: string;
  opened?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  children: readonly IInputTreeNode[];
}

export interface ITwoWayTreeNode<T extends ITwoWayTreeNode<T>> {
  key: string;
  children: readonly T[];
  parent: T | null;
}
