import { ApiAssignedTo } from 'src/models/users/AssignedTo/types';
import { IApiComment } from 'src/models/comments/types';
import { IApiBid } from 'src/models/bids/BidFull/types';
import { isString, isObject } from 'src/helpers';
import { IIcon } from 'src/types/icon';
import { TenderBoxFieldType } from '@tendium/prom-types/tender';
import { Maybe } from 'src/helpers/typescript';
import { ProcurementStatus } from '../types';
import { OcdsParty } from '../TenderFree/types';
import { File } from 'src/models/file/types';
import { ReactNode } from 'react';

export interface OCDSTenderPeriod {
  endDate?: number;
}

export interface OCDSContractPeriod {
  endDate?: number;
  startDate?: number;
  maxExtentDate?: number;
}

export interface OCDSEstimatedContractValue {
  amount?: number;
  currency?: string;
}
export interface OCDSTenderObject {
  tenderPeriod?: OCDSTenderPeriod;
  title: string;
  value?: OCDSEstimatedContractValue;
  contractPeriod?: OCDSContractPeriod;
}
export interface IApiTenderResponse {
  readonly getTender: IApiTender;
}
export interface IApiTenderBidResponse {
  readonly getTender: {
    bids: {
      id: string;
    }[];
  };
}
export interface IApiTenderRequest {
  id: string;
  includeSummary?: boolean;
}
export interface IApiUpdatedTenderResponse {
  readonly getUpdatedTender: IApiTender;
}
export interface IApiUpdatedTenderRequest {
  id: string;
  bidId: string;
  includeSummary?: boolean;
}
export interface IApiTenderFileCategory {
  name: string;
  description: string;
  files: IApiProcurementFile[];
}
export interface IApiProcurementFile {
  fileName: string;
  dateUploaded: number;
  targetPath: string;
  fileType: string;
}
export type ApiTenderFile = Partial<Omit<IApiProcurementFile, 'targetPath'>> & Pick<IApiProcurementFile, 'targetPath'>;
export interface IApiTender {
  id: string;
  fileCategories: IApiTenderFileCategory[];
  general: ITenderGeneral<IApiTenderBox>;
  timeline: ITenderTimeline<IApiTenderBox>;
  lots: IApiTenderLot[];
  boxes: IApiTenderBox[];
  custom: IApiTenderBox[];
  assignedTo: ApiAssignedTo | null;
  bids: IApiBid[];
  comments: IApiComment[];
  isRead: boolean;
  isStarred: boolean;
  procurementStatus: ProcurementStatus[];
  expiringContract: ApiExpiringContract;
  date: number;
  parties: OcdsParty[];
  tender: OCDSTenderObject;
  rejected: boolean;
  summaries?: {
    eng?: string;
    original?: string;
  };
}
export interface ApiExpiringContract {
  contractStart?: number;
  contractEnd?: number;
  extensionEndDates?: number[];
  hasLots: boolean;
}

export interface ITenderGeneral<T> {
  name?: T;
  description: T[];
  introduction: T[];
  scopeOfContract?: T[];
  city?: T;
  country?: T;
  placeOfPerformanceNutsCode?: T;
  buyerNutsCode?: T;
  postcode?: T;
  postalAddress?: T;
  buyerBoxes: T[];
  contractDurationBoxes: T[];
  locationBoxes: T[];
  contractRenewalBoxes: T[];
  contractValueBoxes: T[];
  typeOfProcedureBoxes: T[];
  contactPersonBoxes: T[];
  emailBoxes: T[];
  phoneBoxes: T[];
  bidValidityBoxes: T[];
  linkToProcurerBoxes: T[];
  linkForSubmittingTenderBoxes: T[];
  linkToTenderDocumentBoxes: T[];
  linkToQA: T[];
  mainCpvCode: T[];
  additionalCpvCode: T[];
  frameworkAgreement: T[];
  numberOfSuppliersAssignedContract: T[];
  referenceNumberBoxes: T[];
}
export interface ITenderTimeline<T> {
  availableDate?: T;
  minimumTimeFrame?: T;
  contractStartAndEndStart?: T;
  contractStartAndEndEnd?: T;
  deadlineOfClarificationQuestions: T[];
  expectedPublicationDate?: T;
  deadline: T[];
  demonstrationStart?: T;
  demonstrationEnd?: T;
  presentationStart?: T;
  presentationEnd?: T;
  showingOfObjectsStart?: T;
  showingOfObjectsEnd?: T;
  dateOfDispatch?: T;
}
export interface IApiBoxFieldGeneric {
  name: string;
  type: TenderBoxFieldType;
}
export enum ApiBoxFieldTypeName {
  String = 'ProcurementBoxStringField',
  Array = 'ProcurementBoxArrayField',
  Number = 'ProcurementBoxNumberField',
  URL = 'ProcurementBoxUrlField',
  Range = 'ProcurementBoxRangeFieldDTO',
  Date = 'ProcurementBoxDateFieldDTO',
  Boolean = 'ProcurementBoxBooleanFieldDTO',
  Money = 'ProcurementBoxMoneyFieldDTO',
  DateRange = 'ProcurementBoxDateRangeFieldDTO',
  MoneyRange = 'ProcurementBoxMoneyRangeFieldDTO'
}
export interface IBoxFieldGeneric extends IApiBoxFieldGeneric {
  id: string;
  isChanged: boolean;
}
export interface IApiBoxFieldString extends IApiBoxFieldGeneric {
  string?: Maybe<string>;
}
export interface IApiBoxFieldNumber extends IApiBoxFieldGeneric {
  number?: Maybe<number>;
  unit?: Maybe<BoxFieldRangeUnit>;
}
export interface IApiBoxFieldBoolean extends IApiBoxFieldGeneric {
  boolean?: Maybe<boolean>;
}
export interface IApiBoxFieldArray extends IApiBoxFieldGeneric {
  array?: Maybe<string[]>;
  unit?: Maybe<BoxFieldRangeUnit>;
}
export interface IApiBoxFieldDate extends IApiBoxFieldGeneric {
  date?: Maybe<number>;
  time?: Maybe<string>;
}
export interface IApiBoxFieldCurrency extends IApiBoxFieldGeneric {
  amount?: Maybe<number>;
  currency?: Maybe<string>;
}
export interface IApiBoxFieldCurrencyRange extends IApiBoxFieldGeneric {
  from?: Maybe<string>;
  to?: Maybe<string>;
  currency?: Maybe<string>;
}
export interface IApiBoxFieldRange extends IApiBoxFieldGeneric {
  from?: Maybe<string>;
  to?: Maybe<string>;
  unit?: Maybe<BoxFieldRangeUnit>;
}
export interface IApiBoxFieldDateRange extends Omit<IApiBoxFieldRange, 'unit'> {}

export interface IApiBoxFieldUrl extends IApiBoxFieldGeneric {
  url?: Maybe<string>;
  title?: Maybe<string>;
}
export type IApiBoxField =
  | IApiBoxFieldString
  | IApiBoxFieldNumber
  | IApiBoxFieldBoolean
  | IApiBoxFieldArray
  | IApiBoxFieldDate
  | IApiBoxFieldCurrency
  | IApiBoxFieldCurrencyRange
  | IApiBoxFieldRange
  | IApiBoxFieldDateRange
  | IApiBoxFieldUrl;

export interface IApiTenderBox {
  id: string;
  specificationId: string;
  category: string;
  title: string;
  fields: IApiBoxField[];
  lots?: IApiTenderLot[];
  comments: IApiComment[];
  requirementStatus: BoxRequirementStatus | null;
  order?: number;
  dataSource?: IApiBoxDataSource | null;
}
export interface IApiTenderLot {
  name: string;
  description: string;
  id: string;
  lotItem: string;
}
export interface IApiBoxDataSource {
  filePath?: string;
  origin?: string;
  text?: string;
  headline?: string;
}

export enum BoxFieldRangeUnit {
  Hours = 'Hours',
  Days = 'Days',
  Months = 'Months',
  Years = 'Years',
  Percents = 'Percents',
  Meters = 'Meters',
  SquareMeters = 'SquareMeters'
}
export enum TenderStatus {
  Award = 'Award',
  Tender = 'Tender',
  TenderCancellation = 'TenderCancellation',
  Planning = 'Planning'
}
export enum TenderCatName {
  Overview = 'GENERAL_INFO',
  Docs = 'DOCUMENTS',
  KeywordSearch = 'KEYWORD_SEARCH',
  Bidding = 'BIDDING',
  Outcome = 'OUTCOME'
}
export enum TenderSubCatName {
  Timeline = 'TIMELINE_INFO',
  ShortDescription = 'Short description',
  Intro = 'INTRODUCTION',
  ScopeOfContract = 'SCOPE_OF_CONTRACT',
  Contact = 'CONTACT_INFO',
  Contract = 'CONTRACT_INFO',
  Procurement = 'PROCUREMENT',
  Additional = 'ADDITIONAL_INFORMATION',
  Cpv = 'CPV',
  Location = 'LOCATION_INFO',
  Competitors = 'COMPETITORS',
  Lots = 'LOTS',
  Others = 'OTHERS_INFO',
  Custom = 'CUSTOM_BID_FIELDS'
}
export enum TenderBlockSize {
  Full = 'Full',
  ThreeQuarters = 'ThreeQuarters',
  Half = 'Half',
  Quarter = 'Quarter'
}
export enum BoxRequirementStatus {
  MUST = 'MUST',
  SHOULD = 'SHOULD',
  INFO = 'INFO',
  OTHER = 'OTHER'
}

export interface IBoxFieldString extends IBoxFieldGeneric, Pick<IApiBoxFieldString, 'string'> {
  apiField: IApiBoxFieldString;
  update: (string?: Maybe<string>) => IBoxFieldString;
}
export interface IBoxFieldNumber extends IBoxFieldGeneric, Pick<IApiBoxFieldNumber, 'number' | 'unit'> {
  apiField: IApiBoxFieldNumber;
  update: (number?: Maybe<number>, unit?: Maybe<BoxFieldRangeUnit>) => IBoxFieldNumber;
}
export interface IBoxFieldBoolean extends IBoxFieldGeneric, Pick<IApiBoxFieldBoolean, 'boolean'> {
  apiField: IApiBoxFieldBoolean;
  update: (boolean?: Maybe<boolean>) => IBoxFieldBoolean;
}
export interface IBoxFieldArray extends IBoxFieldGeneric, Pick<IApiBoxFieldArray, 'array' | 'unit'> {
  apiField: IApiBoxFieldArray;
  update: (array?: Maybe<string[]>) => IBoxFieldArray;
}
export interface IBoxFieldDate extends IBoxFieldGeneric, Pick<IApiBoxFieldDate, 'date' | 'time'> {
  apiField: IApiBoxFieldDate;
  update: (date?: Maybe<number>, time?: Maybe<string>) => IBoxFieldDate;
}
export interface IBoxFieldCurrencyRange extends IBoxFieldGeneric, Pick<IApiBoxFieldCurrencyRange, 'currency'> {
  from: Maybe<number>;
  to: Maybe<number>;
  apiField: IApiBoxFieldCurrencyRange;
  update: (from?: Maybe<number>, to?: Maybe<number>, currency?: Maybe<string>) => IBoxFieldCurrencyRange;
}
export interface IBoxFieldCurrency extends IBoxFieldGeneric, Pick<IApiBoxFieldCurrency, 'amount' | 'currency'> {
  apiField: IApiBoxFieldCurrency;
  update: (amount?: Maybe<number>, currency?: Maybe<string>) => IBoxFieldCurrency;
}
export interface IBoxFieldRange extends IBoxFieldGeneric, Pick<IApiBoxFieldRange, 'from' | 'to' | 'unit'> {
  apiField: IApiBoxFieldRange;
  update: (from?: Maybe<string>, to?: Maybe<string>, unit?: Maybe<BoxFieldRangeUnit>) => IBoxFieldRange;
}
export interface IBoxFieldDateRange extends IBoxFieldGeneric {
  from: Maybe<number>;
  to: Maybe<number>;
  apiField: IApiBoxFieldDateRange;
  update: (from?: Maybe<number>, to?: Maybe<number>) => IBoxFieldDateRange;
}
export interface IBoxFieldUrl extends IBoxFieldGeneric, Pick<IApiBoxFieldUrl, 'url' | 'title'> {
  apiField: IApiBoxFieldUrl;
  update: (url?: Maybe<string>, title?: Maybe<string>) => IBoxFieldUrl;
}
export interface IBoxFieldEmail extends IBoxFieldGeneric {
  email?: Maybe<string>;
  apiField: IApiBoxFieldString;
  update: (email?: Maybe<string>) => IBoxFieldEmail;
}
export type IBoxField =
  | IBoxFieldString
  | IBoxFieldNumber
  | IBoxFieldBoolean
  | IBoxFieldArray
  | IBoxFieldDate
  | IBoxFieldCurrency
  | IBoxFieldCurrencyRange
  | IBoxFieldRange
  | IBoxFieldDateRange
  | IBoxFieldUrl
  | IBoxFieldEmail;

export interface ITenderLot extends Omit<IApiTenderLot, 'lotItem'> {
  lotItem: number;
}

export interface ITenderBox extends Omit<IApiTenderBox, 'fields' | 'lots' | 'dataSource'> {
  fields: IBoxField[];
  rawFields: IBoxField[];
  lots: ITenderLot[];
  isEmpty: boolean;
  isCustom: boolean;
  firstField?: IBoxField;
  dataSource?: ITenderBoxDataSource;
  keyName?: TenderKeys;
  originId: string;
  heliumId: string;
}
export enum TenderBoxMode {
  Base = 'Base',
  Collection = 'Collection',
  Custom = 'Custom',
  Reverse = 'Reverse'
}
export interface ITenderBoxDataSource extends File {}

export interface ITenderSubCat extends IGenericSubCat {
  boxes: ITenderBox[];
}

export interface ITenderCat extends IGenericCat {
  subCats: ITenderSubCat[];
}

export interface ITenderCatMap extends IGenericCat {
  subCats: Map<string, ITenderSubCatMap>;
}
export interface ITenderSubCatMap extends IGenericSubCat {
  boxes: Map<string, ITenderBox>;
}

export interface IGenericCat {
  id: string; // box.category before the dot
  title: string; // box.category before the dot or custom title
  icon: IIcon;
}
export interface IDocsCat extends IGenericCat {
  // FIXME: refactor files, after switching to v2
  fileCategories: IProcurementFileCategory[];
  filesAmount: number;
  linkToDocsSource?: string;
}
export interface IGenericSubCat {
  id: string; // box.category
  title: string; // box.category after the dot or custom title
  order?: number;
  size?: TenderBlockSize;
  icon?: IIcon;
  badge?: ReactNode;
}
export interface ITimelineBox {
  isHidden: boolean;
  box: ITenderBox;
}
export type ITenderDataKeys = keyof ITenderGeneral<IApiTenderBox> | keyof ITenderTimeline<IApiTenderBox>;
export type TenderKeys = ITenderDataKeys | 'awardCriteria';
export interface IGeneralSubCat extends IGenericSubCat {
  boxes: Record<ITenderDataKeys, ITenderBox[] | undefined>;
}
export interface IDescSubCat extends IGenericSubCat {
  boxes: Pick<ITenderGeneral<ITenderBox>, 'description'>;
}
export interface ILocationSubCat extends IGenericSubCat {
  boxes: Pick<ITenderGeneral<ITenderBox>, 'locationBoxes' | 'placeOfPerformanceNutsCode'>;
}
export interface IIntroSubCat extends IGenericSubCat {
  boxes: Pick<ITenderGeneral<ITenderBox>, 'introduction'>;
}
export interface IScopeOfContractSubCat extends IGenericSubCat {
  boxes: Pick<ITenderGeneral<ITenderBox>, 'scopeOfContract'>;
}
export interface IContactSubCat extends IGenericSubCat {
  boxes: Pick<
    ITenderGeneral<ITenderBox>,
    | 'buyerBoxes'
    | 'phoneBoxes'
    | 'contactPersonBoxes'
    | 'emailBoxes'
    | 'city'
    | 'postalAddress'
    | 'buyerNutsCode'
    | 'postcode'
  >;
}
export interface IContractSubCat extends IGenericSubCat {
  boxes: Pick<
    ITenderGeneral<ITenderBox>,
    'contractValueBoxes' | 'contractDurationBoxes' | 'contractRenewalBoxes' | 'numberOfSuppliersAssignedContract'
  > &
    Pick<ITenderTimeline<ITenderBox>, 'contractStartAndEndStart' | 'contractStartAndEndEnd'>;
}
export interface IProcurementSubCat extends IGenericSubCat {
  boxes: Pick<
    ITenderGeneral<ITenderBox>,
    'typeOfProcedureBoxes' | 'linkForSubmittingTenderBoxes' | 'frameworkAgreement'
  > &
    Pick<ITenderTimeline<ITenderBox>, 'deadline' | 'availableDate' | 'deadlineOfClarificationQuestions'>;
}
export interface IAdditionalSubCat extends IGenericSubCat {
  boxes: [ITenderBox | ITenderBox[]];
}
export interface ICpvSubCat extends IGenericSubCat {
  boxes: Pick<ITenderGeneral<ITenderBox>, 'mainCpvCode' | 'additionalCpvCode'>;
}
export interface ILotsSubCat extends IGenericSubCat {
  lots: ITenderLot[];
}
export interface IGeneralCat extends IGenericCat {
  readonly [TenderSubCatName.ShortDescription]: IGeneralSubCat;
  readonly [TenderSubCatName.Location]: IGeneralSubCat;
  readonly [TenderSubCatName.Timeline]: ITenderSubCat;
  readonly [TenderSubCatName.Intro]: IGeneralSubCat;
  readonly [TenderSubCatName.ScopeOfContract]: IGeneralSubCat;
  readonly [TenderSubCatName.Contact]: IGeneralSubCat;
  readonly [TenderSubCatName.Contract]: IGeneralSubCat;
  readonly [TenderSubCatName.Procurement]: IGeneralSubCat;
  readonly [TenderSubCatName.Additional]: IAdditionalSubCat;
  readonly [TenderSubCatName.Cpv]: IGeneralSubCat;
  readonly [TenderSubCatName.Competitors]: IGenericSubCat;
  readonly [TenderSubCatName.Lots]: ILotsSubCat;
  readonly [TenderSubCatName.Custom]: ITenderSubCat;
}

export function isFieldString(u: unknown): u is IBoxFieldString {
  return isObject(u) && 'string' in u && 'type' in u && u.type === TenderBoxFieldType.String;
}

export function isFieldNumber(u: unknown): u is IBoxFieldNumber {
  return isObject(u) && 'number' in u && 'type' in u && u.type === TenderBoxFieldType.Number;
}

export function isFieldBoolean(u: unknown): u is IBoxFieldBoolean {
  return isObject(u) && 'boolean' in u && 'type' in u && u.type === TenderBoxFieldType.Boolean;
}
export function isFieldArray(u: unknown): u is IBoxFieldArray {
  return isObject(u) && 'array' in u && 'type' in u && u.type === TenderBoxFieldType.Array;
}

export function isFieldDate(u: unknown): u is IBoxFieldDate {
  return isObject(u) && 'date' in u && 'type' in u && u.type === TenderBoxFieldType.Date;
}

export function isFieldCurrency(u: unknown): u is IBoxFieldCurrency {
  return isObject(u) && 'currency' in u && 'type' in u && u.type === TenderBoxFieldType.Money;
}

export function isFieldRange(u: unknown): u is IBoxFieldRange {
  return isObject(u) && 'from' in u && 'unit' in u && 'type' in u && u.type === TenderBoxFieldType.Range;
}

export function isFieldCurrencyRange(u: unknown): u is IBoxFieldCurrencyRange {
  return isObject(u) && 'currency' in u && 'from' in u && 'type' in u && u.type === TenderBoxFieldType.MoneyRange;
}

export function isFieldDateRange(u: unknown): u is IBoxFieldDateRange {
  return isObject(u) && 'from' in u && 'type' in u && u.type === TenderBoxFieldType.DateRange;
}

export function isFieldUrl(u: unknown): u is IBoxFieldUrl {
  return isObject(u) && 'url' in u && 'type' in u && u.type === TenderBoxFieldType.URL;
}

export function isFieldEmail(u: unknown): u is IBoxFieldEmail {
  return isObject(u) && 'email' in u && 'type' in u && u.type === TenderBoxFieldType.String;
}

export function isTenderBox(u: unknown): u is IApiTenderBox {
  return isObject(u) && 'specificationId' in u && isString(u.specificationId);
}

export interface IBoxWithComments {
  id: string;
  title: string;
  comments: IApiComment[];
  questionId?: string;
}
export interface IProcurementFilesCategory {
  fileCategories: IProcurementFileCategory[];
  filesAmount: number;
}

export interface IProcurementFileCategory {
  name: string;
  description: string;
  children: IProcurementFile[];
}

export interface IProcurementFile {
  directoryName?: string;
  originalFileName?: string;
  fileName: string;
  fileType: string;
  targetPath: string;
  children?: IProcurementFile[];
  dateUploaded: Date;
}
export enum RequirementStatusType {
  MUST = 'MUST',
  SHOULD = 'SHOULD',
  INFO = 'INFO',
  OTHER = 'OTHER'
}
export const ACTIVE_STATUSES = [RequirementStatusType.MUST, RequirementStatusType.SHOULD] as const;
export type ActiveStatuses = (typeof ACTIVE_STATUSES)[number];

export function isStatus(status: unknown): status is RequirementStatusType {
  return typeof status === 'string' && Object.values(RequirementStatusType).some(x => x === status);
}

export function isRequirementStatus(status: unknown): status is ActiveStatuses {
  return isStatus(status) && ACTIVE_STATUSES.some(r => r === status);
}

export function showFieldArray(f: IBoxFieldArray): boolean {
  return !!f.array?.length;
}

export function showFieldBoolean(f: IBoxFieldBoolean): boolean {
  return !!f.boolean;
}

export function showFieldRange(f: IBoxFieldRange): boolean {
  return !!f.from || !!f.to;
}

export function showFieldCurrencyRange(f: IBoxFieldCurrencyRange): boolean {
  return !!f.from || !!f.to;
}

export function showFieldUrl(f: IBoxFieldUrl): boolean {
  return !!f.url;
}

export function showFieldEmail(f: IBoxFieldEmail): boolean {
  return !!f.email;
}

export function showFieldDateRange(f: IBoxFieldDateRange): boolean {
  return !!f.from || !!f.to;
}

export function showFieldDate(f: IBoxFieldDate): boolean {
  return !!f.date;
}

export function showFieldNumber(f: IBoxFieldNumber): boolean {
  return !!f.number;
}

export function showFieldCurrency(f: IBoxFieldCurrency): boolean {
  return !!f.amount;
}

export function showFieldString(f: IBoxFieldString): boolean {
  return !!f.string;
}

export function showField(u: unknown): boolean {
  if (isFieldArray(u)) return showFieldArray(u);
  if (isFieldBoolean(u)) return showFieldBoolean(u);
  if (isFieldRange(u)) return showFieldRange(u);
  if (isFieldCurrencyRange(u)) return showFieldCurrencyRange(u);
  if (isFieldUrl(u)) return showFieldUrl(u);
  if (isFieldEmail(u)) return showFieldEmail(u);
  if (isFieldDateRange(u)) return showFieldDateRange(u);
  if (isFieldDate(u)) return showFieldDate(u);
  if (isFieldNumber(u)) return showFieldNumber(u);
  if (isFieldCurrency(u)) return showFieldCurrency(u);
  if (isFieldString(u)) return showFieldString(u);
  return false;
}
