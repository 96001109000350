import React, { FC } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  className?: string;
  size?: SizeProp;
}

export const AiBadge: FC<Props> = props => {
  const { size, className } = props;

  return (
    <div className={className}>
      <FontAwesomeIcon icon={faStars} size={size ?? 'lg'} className={styles.aiBadge} />
    </div>
  );
};

export default AiBadge;
