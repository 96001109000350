import React, { FC, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getSupplierLink } from 'src/models/organisations';
import styles from './index.module.scss';
import { FeatureFlag, useAnalyticsFeatureFlags, useFeatureFlag } from 'src/helpers/featureFlag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

interface SupplierLinkProps {
  orgId?: string;
  orgName?: string;
  children?: ReactNode;
}

export const SupplierLink: FC<SupplierLinkProps> = ({ orgId, orgName, children }) => {
  const isAnalyticsPagesDemo = useFeatureFlag(FeatureFlag.Analytics_Pages_Demo);
  const isSupplierPageAvailable = useAnalyticsFeatureFlags(FeatureFlag.Analytics_Supplier_Profiles_Page);
  const demoSupplierId = '5564480282'; // Atea Sverige AB
  const demoSupplierName = 'Atea Sverige AB';

  const supplierLink = useMemo(() => {
    if (isAnalyticsPagesDemo) {
      return getSupplierLink(demoSupplierId, demoSupplierName);
    }

    if (!orgId) return '';

    return getSupplierLink(orgId, orgName);
  }, [orgId, orgName, isAnalyticsPagesDemo]);

  if (!orgId || !isSupplierPageAvailable) return <>{children}</>;

  return (
    <Link to={supplierLink} className={styles.link}>
      <FontAwesomeIcon icon={faUpRightFromSquare} className={styles.icon} />
      {children}
    </Link>
  );
};

export default SupplierLink;
