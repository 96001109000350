import { ApolloError, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { PreviousTenderItem } from '.';
import { ApiPreviousTendersData } from './types';
import { GET_PREVIOUS_TENDERS } from './queries';

export function useGetPreviousTenders(procurementId: string): {
  data: PreviousTenderItem[];
  loading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<ApiPreviousTendersData, { procurementId: string }>(GET_PREVIOUS_TENDERS, {
    variables: { procurementId },
    skip: !procurementId
  });

  return useMemo(() => {
    return {
      data: data?.getPreviousTenders.procurementsWithScore?.map(item => new PreviousTenderItem(item.procurement)) ?? [],
      loading,
      error
    };
  }, [data, loading, error]);
}
