import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import Tooltip from 'src/common/Tooltip';
import Spinner from '../Spinner';

export type WorkspaceBadgeMode = 'short' | 'full';

interface IProps {
  title: string;
  color: string;
  className?: string;
  mode?: WorkspaceBadgeMode;
  loading?: boolean;
  size?: (typeof Sizes)[number];
  tooltip?: boolean;
}

export const WorkspaceBadge: FC<IProps> = ({ loading = false, size = 's', ...restProps }) => {
  const { title, color, className, mode, tooltip } = restProps;

  return (
    <Tooltip title={tooltip ? title : undefined} placement={mode === 'short' ? 'right' : undefined}>
      <div className={classNames(styles.wsBadge, className)}>
        {loading ? (
          <Spinner className={styles.wsLoadingSpinner} />
        ) : (
          <span
            className={classNames(styles.wsBadgeIcon, {
              [styles.xSmall]: size === 'xs',
              [styles.small]: size === 's',
              [styles.medium]: size === 'm',
              [styles.large]: size === 'l',
              [styles.xLarge]: size === 'xl'
            })}
            style={{ backgroundColor: color }}
          >
            <span className={styles.wsBadgeLetter}>{!!title ? title.charAt(0) : 'W'}</span>
          </span>
        )}
        {mode === 'full' && <span className={styles.wsBadgeTitle}>{title}</span>}
      </div>
    </Tooltip>
  );
};

export default WorkspaceBadge;
