/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITwoWayTreeNode, NodesState, NodeState, IInputTreeNode } from './types';
import { notUndefined, Writeable } from 'src/helpers/typescript';
import { cloneDeep, pickBy, union } from 'lodash';
import { ITreeState } from './index';
import { CodeFilter } from 'src/models/matchingProfiles/types';

/**
 * @note: `treeData` used here in case we have filtered `IInputTreeNode[]` for some reason
 */
export function toTwoWayTreeNode<T extends { key: string | number; children?: readonly T[] }>(
  treeData: readonly T[]
): ITwoWayTreeNode<any>[] {
  function handleNode(node: T, parent: ITwoWayTreeNode<any> | null): ITwoWayTreeNode<any> {
    const key = node.key.toString();
    const { children } = node;
    const twoWayNode: ITwoWayTreeNode<any> = {
      key,
      parent,
      children: []
    };
    twoWayNode.children = (children || []).map(child => handleNode(child, twoWayNode));

    return twoWayNode;
  }
  return treeData.map(node => handleNode(node, null));
}

export function findNode<T extends ITwoWayTreeNode<T>>(keyToSearch: string, tree: readonly T[]): T | undefined {
  const searchAtNode = (node: T): T | undefined => {
    const key = node.key.toString();
    if (key === keyToSearch) {
      return node;
    }
    const { children } = node;
    for (const child of children) {
      const res = searchAtNode(child);
      if (res) {
        return res;
      }
    }
    return undefined;
  };
  for (const node of tree) {
    const res = searchAtNode(node);
    if (res) {
      return res;
    }
  }

  return undefined;
}

export function getPathToNode<T extends ITwoWayTreeNode<T>>(node: T): string[] {
  const parents = getParents(node);

  return parents.map(p => p.key);
}
export function getParents<T extends ITwoWayTreeNode<T>>(node: T): T[] {
  const path: T[] = [];

  let currentNode = node;
  while (currentNode.parent !== null) {
    const { parent } = currentNode;
    path.push(parent);
    currentNode = parent;
  }

  return path.reverse();
}

export function getChildKeys<T extends ITwoWayTreeNode<T>>(node: T): string[] {
  const result: string[] = [];

  const targetKey = node.key;
  const handleNode = (nodeToHandle: T): void => {
    const key = nodeToHandle.key.toString();
    const { children } = nodeToHandle;

    // skip the node itself
    if (targetKey !== key) {
      result.push(key.toString());
    }
    children.forEach(child => handleNode(child));
  };

  handleNode(node);
  return result;
}

export function toCheckedKeys(nodesState: NodesState): string[] {
  return Object.entries(nodesState).map(([key]) => key);
}

export function filterCheckedKeys(nodesState: NodesState): string[] {
  return Object.entries(nodesState)
    .filter(([, state]) => state === NodeState.Checked)
    .map(([key]) => key);
}

export function filterExactCheckedKeys(nodesState: NodesState): string[] {
  return Object.entries(nodesState)
    .filter(([, state]) => state === NodeState.ExactChecked)
    .map(([key]) => key);
}

export function toNodesState(checkedKeys: readonly string[], exactCheckedKeys: readonly string[]): NodesState {
  const state: Writeable<NodesState> = {};
  checkedKeys.forEach(key => {
    state[key] = NodeState.Checked;
  });
  exactCheckedKeys.forEach(key => {
    state[key] = NodeState.ExactChecked;
  });
  return state;
}

export function filterCheckedNodesState(state: NodesState): NodesState {
  return Object.fromEntries(Object.entries(state).filter(([, value]) => value === NodeState.Checked));
}
export function filterExactCheckedNodesState(state: NodesState): NodesState {
  return Object.fromEntries(Object.entries(state).filter(([, value]) => value === NodeState.ExactChecked));
}

export function zipNodesState<T extends ITwoWayTreeNode<T>>(tree: readonly T[], nodesState: NodesState): NodesState {
  const zippedNodesState: Writeable<NodesState> = {};
  const handleNode = (nodeToHandle: T, toIncludeBoundary: NodeState | null): void => {
    const key = nodeToHandle.key.toString();
    const { children } = nodeToHandle;

    const nodeState: NodeState | undefined = nodesState[key];
    let nextToIncludeBoundary = toIncludeBoundary;
    if (nodeState !== undefined) {
      if (toIncludeBoundary === null || nodeState > toIncludeBoundary) {
        zippedNodesState[key] = nodeState;
        nextToIncludeBoundary = nodeState;
      }
    }
    children.forEach(node => handleNode(node, nextToIncludeBoundary));
  };

  tree.forEach(node => handleNode(node, null));

  return zippedNodesState;
}

/**
 * Add children of checked nodes
 * @see: zipNodesState
 */
export function unzipNodesState<T extends ITwoWayTreeNode<T>>(
  tree: readonly T[],
  nodesState: NodesState,
  filteredKeys?: string[]
): NodesState {
  const unzippedNodesState: Writeable<NodesState> = {};
  const handleNode = (nodeToHandle: T, minimalState: NodeState | null): void => {
    const key = nodeToHandle.key.toString();
    const { children } = nodeToHandle;

    const nodeState: NodeState | undefined = nodesState[key];
    let nextMinimalState = minimalState;
    if (minimalState === null && nodeState !== undefined) {
      nextMinimalState = nodeState;
    } else if (minimalState !== null && nodeState !== undefined && nodeState > minimalState) {
      nextMinimalState = nodeState;
    }
    if (nextMinimalState !== null) {
      unzippedNodesState[key] = nextMinimalState;
    }
    children.forEach(node => handleNode(node, nextMinimalState));
  };

  tree.forEach(node => handleNode(node, null));

  if (filteredKeys && filteredKeys.length) {
    return pickBy({ ...unzippedNodesState }, (val, key) => !filteredKeys.includes(key));
  }

  return unzippedNodesState;
}

export function zipNodesStateStrictly<T extends ITwoWayTreeNode<T>>(
  tree: readonly T[],
  nodesState: NodesState
): NodesState {
  return {
    ...filterExactCheckedNodesState(nodesState),
    ...zipNodesState(tree, filterCheckedNodesState(nodesState))
  };
}

export function unzipNodesStateStrictly<T extends ITwoWayTreeNode<T>>(
  tree: readonly T[],
  nodesState: NodesState,
  filteredKeys?: string[]
): NodesState {
  return {
    ...filterExactCheckedNodesState(nodesState),
    ...unzipNodesState(tree, filterCheckedNodesState(nodesState), filteredKeys)
  };
}

export function filterTreeData(
  tree: readonly IInputTreeNode[],
  searchPhrase: string,
  includeKey: boolean
): IInputTreeNode[] {
  const searchPhraseLower = searchPhrase.toLowerCase();
  const deepClone = cloneDeep(tree);

  return deepClone.map(node => {
    function filterNode(node: IInputTreeNode, foundInParent: boolean): IInputTreeNode {
      let foundInChildren = false;
      let foundInCurrentParent = false;

      // FIXME: Remove exception for 2 chars when we will know how to implement that.
      if (searchPhraseLower.length === 2) {
        if (
          node.label.toLowerCase().startsWith(searchPhraseLower) ||
          (includeKey && node.key.toLowerCase().startsWith(searchPhraseLower))
        ) {
          foundInCurrentParent = true;
        }
      } else {
        if (
          node.label.toLowerCase().indexOf(searchPhraseLower) > -1 ||
          (includeKey && node.key.toLowerCase().indexOf(searchPhraseLower) > -1)
        ) {
          foundInCurrentParent = true;
        }
      }

      if (node.children?.length) {
        const filteredChildren = node.children.map(node => {
          return filterNode(node, foundInCurrentParent);
        });
        if (!foundInParent && !foundInCurrentParent) {
          node.children = filteredChildren;
        }
        foundInChildren = filteredChildren.filter(child => !child.hidden).length > 0;
        node.opened = foundInChildren;
      }
      node.hidden = !(foundInCurrentParent || false || foundInChildren);
      return node;
    }

    return filterNode(node, false);
  });
}

export function toExpandedKeys(nodesState: readonly IInputTreeNode[]): string[] {
  const handleNode = (node: IInputTreeNode): void => {
    if (node.opened) {
      expandedKeys.push(node.key);
    }
    node.children.forEach(node => handleNode(node));
  };
  const expandedKeys: string[] = [];
  nodesState.forEach(node => {
    handleNode(node);
  });
  return expandedKeys;
}

export function filterExpandedKeys(tree: readonly IInputTreeNode[], keys: ITreeState): string[] {
  const openedNodesKeys = toExpandedKeys(tree);
  const mergedOpened = union(openedNodesKeys, keys.expandedKeys);
  const filteredClosed = mergedOpened.filter(key => !keys.collapsedKeys.includes(key));
  return filteredClosed;
}

export function getHalfChecked<T extends ITwoWayTreeNode<T>>(tree: readonly T[], nodesState: NodesState): Set<T> {
  const checked = toCheckedKeys(nodesState);
  const halfChecked = new Set<T>();
  for (const checkedKey of checked) {
    const node = findNode(checkedKey, tree);
    if (node) {
      const parents = getParents(node);
      parents.forEach(p => {
        halfChecked.add(p);
      });
    }
  }

  return halfChecked;
}

export function getCheckedParentKeys<T extends ITwoWayTreeNode<T>>(
  tree: readonly T[],
  nodeKey: string,
  checkedKeys: string[]
): string[] {
  const node = findNode(nodeKey, tree);
  if (!node) {
    return checkedKeys;
  }
  const parentKeys = getPathToNode(node);
  return checkedKeys.filter(key => parentKeys.includes(key));
}

export function uncheckChildNodes<T extends ITwoWayTreeNode<T>>(
  tree: readonly T[],
  nodeKey: string,
  checkedKeys: string[]
): string[] {
  const node = findNode(nodeKey, tree);
  if (!node) {
    return checkedKeys;
  }
  const childKeys = getChildKeys(node);
  return checkedKeys.filter(key => !childKeys.includes(key));
}

// Imitate uncheck: remove node key, its children and parents
export function uncheckNode<T extends ITwoWayTreeNode<T>>(
  tree: readonly T[],
  nodeKey: string,
  checkedKeys: string[]
): string[] {
  const node = findNode(nodeKey, tree);
  if (!node) {
    return checkedKeys;
  }
  const childKeys = getChildKeys(node);
  const parentKeys = getPathToNode(node);
  return checkedKeys.filter(key => key !== nodeKey && !childKeys.includes(key) && !parentKeys.includes(key));
}

export function filterTreeNodes(tree: IInputTreeNode[], filter: CodeFilter, checkStrictly?: boolean): IInputTreeNode[] {
  if (!filter.codesExact.length && !filter.codes.length) return tree;

  const treeNodes = toTwoWayTreeNode(tree);
  const nodesState = toNodesState(filter.codes, filter.codesExact);
  const halfCheckedKeys = Array.from(getHalfChecked(treeNodes, nodesState)).map(x => x.key);

  let enabledKeys: string[] = [];

  if (checkStrictly) {
    const strictlyNodesState = unzipNodesStateStrictly(treeNodes, nodesState);
    enabledKeys = toCheckedKeys(strictlyNodesState);
  } else {
    const unzippedNodesState = unzipNodesState(treeNodes, filterCheckedNodesState(nodesState));
    enabledKeys = toCheckedKeys(unzippedNodesState);
  }

  const isDisabled = (node: IInputTreeNode): boolean =>
    halfCheckedKeys.includes(node.key) && ![...filter.codes, ...filter.codesExact].includes(node.key);
  const isFiltered = (node: IInputTreeNode): boolean => ![...enabledKeys, ...halfCheckedKeys].includes(node.key);
  const handleNode = (node: IInputTreeNode): IInputTreeNode | undefined => {
    if (isFiltered(node)) return;

    const { children, ...rest } = node;
    return {
      ...rest,
      disabled: isDisabled(node),
      children: children.map(handleNode).filter(notUndefined)
    };
  };

  return tree.map(handleNode).filter(notUndefined);
}
