import React, { FC, MouseEventHandler, useCallback, useState } from 'react';
import styles from './index.module.scss';
import { Button, Tooltip } from 'src/common';
import { useCopyToClipboard } from 'react-use';
import { useTranslation } from 'react-i18next';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  email: string;
}

export const InboxEmail: FC<Props> = (props: Props) => {
  const { email } = props;
  const { t } = useTranslation();

  const [, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const onCopy: MouseEventHandler<HTMLElement> = useCallback(
    e => {
      e.stopPropagation();
      copyToClipboard(email);
      setIsCopied(true);
    },
    [copyToClipboard, email]
  );

  return (
    <div className={styles.container}>
      <Tooltip title={email}>
        <span className={styles.email}>{email}</span>
      </Tooltip>
      <Button icon={<FontAwesomeIcon icon={faCopy} />} type={'text'} onClick={onCopy}>
        {isCopied ? t('Common.isCopied') : t('Common.copyEmail')}
      </Button>
    </div>
  );
};

export default InboxEmail;
