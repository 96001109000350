import React, { FC } from 'react';
import styles from './index.module.scss';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface Props {
  desc: string;
  className?: string;
}

export const TenderDescriptionInfo: FC<Props> = ({ desc, className }) => {
  return (
    <p className={classNames(styles.summaryInfo, className)}>
      <FontAwesomeIcon icon={faInfoCircle} />
      <span className={styles.summaryInfoText}>{desc}</span>
    </p>
  );
};

export default TenderDescriptionInfo;
