import React, { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import modalStyles from 'src/common/Modal/index.module.scss';
import { confirm, notification } from 'src/common';
import { EditableBoxAction } from '../EditableBox';
import { ITenderBox } from 'src/models/procurements/Tender/types';
import { updateCacheRemoveTenderBox, useRemoveTenderBox, useTenderData } from 'src/models/procurements/Tender/hooks';

export interface IProps {
  box: ITenderBox;
}

export const BoxDeleteAction: FC<IProps> = ({ box }) => {
  const { id: boxId, specificationId } = box;
  const { t } = useTranslation();
  const { data } = useTenderData();
  const tenderId = data?.id;
  const bidId = data?.bid?.id;

  const [tenderBoxRemoved, setTenderBoxRemoved] = useState(false);
  const [removeTenderBox, { loading: tenderBoxRemoving }] = useRemoveTenderBox();
  const onRemove = useCallback(() => {
    confirm.confirm({
      title: t('Common.delete'),
      okText: t('Common.delete'),
      content: t('Tenders.RemoveTenderBoxConfirmationContent'),
      cancelText: t('Common.cancel'),
      className: modalStyles.confirmModal,
      centered: true,
      onOk: () =>
        boxId &&
        bidId &&
        tenderId &&
        !tenderBoxRemoving &&
        removeTenderBox({
          variables: {
            id: tenderId,
            bidId,
            box: {
              specificationId,
              boxId
            }
          },
          update: updateCacheRemoveTenderBox(tenderId, boxId)
        })
          .then(res => {
            setTenderBoxRemoved(true);
            return res;
          })
          .catch(() => {
            notification.error({
              description: t('Common.unknownErrorDesc'),
              message: t('Common.unknownError')
            });
          })
    });
  }, [bidId, boxId, removeTenderBox, specificationId, t, tenderBoxRemoving, tenderId]);

  return (
    <EditableBoxAction type={'remove'} onAction={onRemove} isLoading={tenderBoxRemoving} isDone={tenderBoxRemoved} />
  );
};

export default BoxDeleteAction;
