import React, { FC } from 'react';
import styles from '../SearchResults/index.module.scss';
import { useTranslation } from 'react-i18next';
import { StackSerieRange } from 'src/common/StackTimeline/types';

interface IProps {
  range: StackSerieRange;
  count: number;
}

export const TooltipContentRanges: FC<IProps> = ({ range, count }) => {
  const { t } = useTranslation();

  return (
    <>
      <span className={styles.tooltipData}>
        {!!count ? t('ExpiringContracts.extension', { count }) : t('ExpiringContracts.originContractPeriod')}
      </span>
      <span className={styles.tooltipLabel}>
        {t('Common.Format.tableDateFormatForShortMonths', { date: range.label.from })} {t('Common.to')}{' '}
        {t('Common.Format.tableDateFormatForShortMonths', { date: range.label.to })}
      </span>
    </>
  );
};

export default TooltipContentRanges;
