import { WorkspaceStatusCategory } from '@tendium/prom-types/tender';
import { safeCallSegment } from '../helpers';
import { PageView } from 'src/models/users/Preferences/types';

export type AllBidspacesFilter = 'Bidspace' | 'Status' | 'Assigned to' | 'Bid type';

export function trackABFilter(filter: AllBidspacesFilter): void {
  safeCallSegment(() => {
    analytics.track('AB filtering', {
      filter
    });
  });
}

export function trackABSwitchMode(mode: PageView): void {
  safeCallSegment(() => {
    analytics.track('AB Switch mode', {
      mode
    });
  });
}

export function trackGraphInteraction(category: WorkspaceStatusCategory): void {
  safeCallSegment(() => {
    analytics.track('AB graph interaction', {
      category
    });
  });
}
