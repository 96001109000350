import React, { FC } from 'react';
import { Spinner, Tooltip } from 'src/common';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faPlusSquare as faPlusSquareSolid, faSquareExclamation } from '@fortawesome/pro-solid-svg-icons';
import { WorkspacePickerTheme } from '../../index';
import classNames from 'classnames';

interface Props {
  isUpdating: boolean;
  wsBadge: React.ReactNode | null;
  onOpenMenu: () => void;
  theme?: WorkspacePickerTheme;
  inNoAccessWorkspace?: boolean;
  disabled?: boolean;
}

const FullModeLabel: FC<Props> = props => {
  const { isUpdating, onOpenMenu, wsBadge, theme, inNoAccessWorkspace, disabled } = props;
  const { t } = useTranslation();

  return (
    <button
      disabled={!!inNoAccessWorkspace || !!disabled}
      className={classNames(styles.button, {
        [styles.themeSelect]: theme === 'select',
        [styles.showArrow]: theme === 'select' && !!wsBadge,
        [styles.isDisabled]: disabled
      })}
      onClick={onOpenMenu}
    >
      {!!wsBadge ? (
        wsBadge
      ) : !!inNoAccessWorkspace ? (
        <Tooltip title={t('BidSpaces.inNoAccessWorkspace')}>
          <FontAwesomeIcon icon={faSquareExclamation} className={styles.noAccessWorkspaceIcon} />
          <span className={styles.buttonLabel}>{t('BidSpaces.unknownBidspace')}</span>
        </Tooltip>
      ) : (
        <>
          {isUpdating && <Spinner className={styles.spinner} />}
          {!isUpdating && <FontAwesomeIcon icon={faPlusSquareSolid} className={styles.icon} />}
          <span className={styles.buttonLabel}>{t('BidSpaces.addToBidspace')}</span>
        </>
      )}
    </button>
  );
};

export default FullModeLabel;
