import React, { ReactNode, useCallback } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

interface Props<T extends string> {
  icon: IconProp;
  onChangeMode: (view: T) => void;
  title: ReactNode;
  mode: T;
  isActive: boolean;
  className?: string;
  disabled?: boolean;
}

export function ModeButton<T extends string>(props: Props<T>): React.JSX.Element {
  const { icon, onChangeMode, title, mode, isActive, className, disabled } = props;

  const onClick = useCallback(() => {
    onChangeMode(mode);
  }, [mode, onChangeMode]);

  return (
    <button
      className={classNames(styles.viewButton, className, {
        [styles.isActive]: isActive,
        [styles.isDisabled]: disabled
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={icon} className={styles.viewIcon} />
      <span>{title}</span>
    </button>
  );
}

export default ModeButton;
