import { useMemo } from 'react';
import { useApiTenders } from '../Tenders/hooks';
import { TendersBoardItem, TendersTableItem, TendersTimelineItem } from '.';
import { useLoadMp } from 'src/models/matchingProfiles/hooks';

export function useTendersTable(): {
  data?: { tenders: TendersTableItem[]; count: number; fetchedTendersCount: number };
} {
  const [{ data }] = useApiTenders();
  const { data: mpData } = useLoadMp();
  return useMemo(
    () => ({
      data: data
        ? {
            tenders: data.tenders.map(tender => new TendersTableItem(tender, !!mpData?.matchingProfileEmbedding)),
            count: data.count,
            fetchedTendersCount: data.fetchedTendersCount
          }
        : undefined
    }),
    [data, mpData]
  );
}

export function useTendersBoard(): {
  data?: { tenders: TendersBoardItem[]; count: number; fetchedTendersCount: number };
} {
  const [{ data }] = useApiTenders();
  const { data: mpData } = useLoadMp();

  return useMemo(
    () => ({
      data: data
        ? {
            tenders: data.tenders.map(
              tender =>
                new TendersBoardItem(
                  tender,
                  !!mpData?.matchingProfileEmbedding,
                  mpData?.apiKeywords ?? undefined,
                  mpData?.apiKeywordGroups ?? undefined
                )
            ),
            count: data.count,
            fetchedTendersCount: data.fetchedTendersCount
          }
        : undefined
    }),
    [data, mpData?.apiKeywords, mpData?.apiKeywordGroups, mpData?.matchingProfileEmbedding]
  );
}

export function useTendersTimeline(): { data?: { tenders: TendersTimelineItem[]; count: number } } {
  const [{ data }] = useApiTenders();

  return useMemo(
    () => ({
      data: data
        ? {
            tenders: data.tenders
              .filter(tender => tender.expiringContract.contractEnd || tender.expiringContract.contractStart)
              .map(tender => new TendersTimelineItem(tender)),
            count: data.count
          }
        : undefined
    }),
    [data]
  );
}
