import { Location } from 'history';
import { router } from 'src';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Paths } from 'src/pages/paths';

export function isTheSamePathname(location: Location, pathname: string): boolean {
  return location.pathname === pathname;
}

export function updateQueryParams(qp: Record<string, unknown>, replace = false, path?: string, hash?: string): void {
  const prevQp = getQueryParams();
  const newQp = { ...prevQp, ...qp };
  const newQPFiltered = Object.fromEntries(
    Object.entries(newQp).filter(([, v]) => v !== null && v !== undefined && v !== '')
  );
  const newPath = `${path ?? router.state.location.pathname}?${qs.stringify(newQPFiltered)}${hash ?? ''}`;
  if (replace) {
    router.navigate(newPath, { replace: true });
  } else {
    router.navigate(newPath);
  }
}

export function getQueryParams(): Partial<Record<string, unknown>> {
  return qs.parse(router.state.location.search.replace('?', ''));
}

export function useQueryParams(url?: string): Partial<Record<string, unknown>> {
  const { search } = useLocation();
  const searchString = useMemo(() => {
    return url ?? search;
  }, [search, url]);

  return useMemo(
    () =>
      qs.parse(searchString.replace('?', ''), {
        ignoreQueryPrefix: true,
        decoder(str, decoder, charset) {
          const strWithoutPlus = str.replace(/\+/g, ' ');
          if (charset === 'iso-8859-1') {
            // unescape never throws, no try...catch needed:
            return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
          }

          if (/^[+-]?\d+(\.\d+)?$/.test(str)) {
            return parseFloat(str);
          }

          type KeywordDecoder = { true: boolean; false: boolean; null: null; undefined: undefined };
          const keywords: KeywordDecoder = {
            true: true,
            false: false,
            null: null,
            undefined: undefined
          };
          if (str in keywords) {
            return keywords[str as keyof KeywordDecoder];
          }

          // utf-8
          try {
            return decodeURIComponent(strWithoutPlus);
          } catch (e) {
            return strWithoutPlus;
          }
        }
      }),
    [searchString]
  );
}
export interface LocationState {
  from?: string;
  search?: string;
}

export function useBackHref(): string {
  const location = useLocation();

  return useMemo(() => (location.state?.from ? location.state.from : Paths.ROOT), [location.state]);
}

export function useNavigationState(): string {
  const { pathname, search, hash } = useLocation();

  return useMemo(() => `${pathname}${search}${hash}`, [hash, pathname, search]);
}
