import { gql } from '@apollo/client';

export const PREVIOUS_TENDER_FIELDS = gql`
  fragment previousTenderFields on OCDSTender {
    id
    date
    tender {
      title
      tenderPeriod {
        endDate
      }
      contractPeriod {
        endDate
        startDate
      }
    }
    parties {
      name
      identifier {
        id
      }
      roles
    }
  }
`;

export const GET_PREVIOUS_TENDERS = gql`
  query getPreviousTenders($procurementId: String!) {
    getPreviousTenders(procurementId: $procurementId) {
      procurementsWithScore {
        procurement {
          ...previousTenderFields
        }
        score
      }
    }
  }
  ${PREVIOUS_TENDER_FIELDS}
`;
