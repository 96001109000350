import { ICallOff, IInbox, IBaseInbox, IApiBaseInbox, IApiInbox } from './types';
import { EmailStatus, IInboxEmail, ApiCallOffsData } from './types';
import dayjs from 'src/helpers/dayjs';
import { toHSLColor } from 'src/helpers/styles';
import { IAccessControl } from '../acl/types';
import { AssignedTo } from '../users/types';
import { toOwnersAndSubscribers } from '../acl/helpers';

export class BaseInbox implements IBaseInbox {
  public readonly emailAddress: string;
  public readonly inboxName: string;
  public readonly isArchived: boolean | null;
  public readonly color: string;

  constructor(private readonly apiInbox: IApiBaseInbox) {
    this.emailAddress = this.apiInbox.emailAddress;
    this.inboxName = this.apiInbox.inboxName;
    this.isArchived = this.apiInbox.isArchived;
    this.color = toHSLColor(this.apiInbox.emailAddress);
  }
}

export class Inbox extends BaseInbox implements IInbox {
  public readonly accessControl: IAccessControl;
  public readonly accessToEdit: boolean;
  public readonly owners: AssignedTo[];
  public readonly subscribers: AssignedTo[];

  constructor(apiInbox: IApiInbox, allUsersAndTeams: AssignedTo[]) {
    super(apiInbox);
    this.accessControl = apiInbox.accessControl;
    this.accessToEdit = apiInbox.accessToEdit;
    this.owners = toOwnersAndSubscribers(apiInbox.owners ?? [], allUsersAndTeams);
    this.subscribers = toOwnersAndSubscribers(apiInbox.subscribers ?? [], allUsersAndTeams);
  }
}

export class CallOffsData {
  readonly count: number;
  readonly callOffs: ICallOff[];

  constructor(private readonly apiCallOffResponse: ApiCallOffsData['getInboxEmails']) {
    this.count = apiCallOffResponse.total;
    this.callOffs = this.toCallOffsData();
  }

  private toCallOffsData(): ICallOff[] {
    const apiCallOffs = this.apiCallOffResponse.userMails;
    return apiCallOffs.map((apiCallOff, index) => {
      const bid = !!apiCallOff.bidsV2.length
        ? {
            id: apiCallOff.bidsV2[0].id,
            wsId: apiCallOff.bidsV2[0].workspace.id
          }
        : undefined;

      const linkedBid = !!apiCallOff.linkedBidsV2.length
        ? {
            id: apiCallOff.linkedBidsV2[0].id,
            wsId: apiCallOff.linkedBidsV2[0].workspace.id,
            originId: apiCallOff.mailId
          }
        : undefined;

      const nextCallOff: IInboxEmail | undefined = apiCallOffs[index + 1];

      return {
        id: apiCallOff.mailId,
        date: +dayjs(apiCallOff.date),
        subject: apiCallOff.subject || '',
        originalSender: apiCallOff.originalSender,
        isRead: apiCallOff.isRead,
        isRejected: apiCallOff.mailStatus === EmailStatus.REJECTED,
        isExcluded: apiCallOff.mailStatus === EmailStatus.REJECTED || apiCallOff.mailStatus === EmailStatus.INTERESTING,
        isAttachment: !!apiCallOff.attachments && !!apiCallOff.attachments.length,
        bid,
        linkedBid,
        nextCallOff: nextCallOff && {
          id: nextCallOff.mailId,
          isRead: nextCallOff.isRead
        }
      };
    });
  }
}
