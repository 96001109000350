import { AssignedTo } from 'src/models/users/types';
import { safeCallSegment } from '../helpers';
import { TrackEventSource } from './types';
import { isUser } from 'src/models/users/types';

/** When a user creates a new team */
export function trackCreateTeam(teamId: string, teamName: string): void {
  safeCallSegment(() => {
    analytics.track('Team create', {
      teamId,
      teamName
    });
  });
}

/** When a user update a team */
export function trackSaveTeam(teamId: string, teamName: string | null): void {
  safeCallSegment(() => {
    analytics.track('Team save', {
      teamId,
      teamName
    });
  });
}

/** When a user add a user to a team */
export function trackAddTeammates(teamId: string, teamName: string | null, userId: string): void {
  safeCallSegment(() => {
    analytics.track('Teammates Add', {
      teamId,
      teamName,
      userId
    });
  });
}

/** When a user deletes a user from a team */
export function trackDeleteTeammates(teamId: string, teamName: string | null, userId: string): void {
  safeCallSegment(() => {
    analytics.track('Teammates Delete', {
      teamId,
      teamName,
      userId
    });
  });
}

/** When a user deletes the team */
export function trackDeleteTeam(teamId: string, teamName: string): void {
  safeCallSegment(() => {
    analytics.track('Team Delete', {
      teamId,
      teamName
    });
  });
}

/** When a user leaves the team */
export function trackLeaveTeam(teamId: string, teamName: string, userId: string): void {
  safeCallSegment(() => {
    analytics.track('Team Leave', {
      teamId,
      teamName,
      userId
    });
  });
}

export interface TrackAssignToData {
  eventSource: TrackEventSource;
  assignedTo: AssignedTo;
}

/** When a user is assigned */
export function trackAssignTo(data: TrackAssignToData): void {
  safeCallSegment(() => {
    const { eventSource, assignedTo } = data;
    const { type, name, id } = assignedTo;
    analytics.track('Assign To', {
      eventSource,
      type,
      mentions: isUser(assignedTo) ? id : name
    });
  });
}
