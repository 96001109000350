import { createContext } from 'react';
import { Room } from '.';

export type RoomContextValue = {
  data: Room | null;
  loading: boolean;
};

export const RoomContext = createContext<RoomContextValue>({
  data: null,
  loading: false
});
RoomContext.displayName = 'RoomContext';
