import { gql } from '@apollo/client/core';
import { FILE_NODE_FIELDS } from 'src/models/documentsLibrary/fragments';
import { COMMENT_FIELDS } from 'src/models/comments/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';

export const CHECKLIST_ITEM_FIELDS = gql`
  fragment checklistItemFields on ChecklistItem {
    assignedTo {
      ...assignedToFields
    }
    deadline
    description
    id
    isActive
    name
    status
    fulfillment
    attachments {
      ...fileNodeFields
    }
    comments {
      ...commentFields
    }
    isPreMade
  }
  ${ASSIGNED_TO_FIELDS}
  ${FILE_NODE_FIELDS}
  ${COMMENT_FIELDS}
`;

export const CHECKLIST_FIELDS = gql`
  fragment checklistFields on BidV2 {
    id
    workspace {
      id
    }
    checklist {
      ...checklistItemFields
    }
  }
  ${CHECKLIST_ITEM_FIELDS}
`;
