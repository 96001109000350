import { gql } from '@apollo/client';

export const REQUEST_SETTINGS_FIELDS = gql`
  fragment requestSettingsFields on RequestSettings {
    entityId
    requestType
    settings
  }
`;

export const USER_REQUEST_SETTINGS_FIELDS = gql`
  fragment userRequestSettingsFields on User {
    requestSettings {
      ...requestSettingsFields
    }
  }
  ${REQUEST_SETTINGS_FIELDS}
`;

export const SET_REQUEST_SETTINGS = gql`
  mutation setRequestSettings($entityId: String!, $type: RequestType!, $settings: String) {
    setRequestSettings(input: { entityId: $entityId, type: $type, settings: $settings })
  }
`;

export const GET_ME_REQUEST_SETTINGS = gql`
  query meRequestSettings {
    getMe {
      account {
        ...userRequestSettingsFields
      }
    }
  }
  ${USER_REQUEST_SETTINGS_FIELDS}
`;
