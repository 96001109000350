import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useDeadlineDate } from 'src/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-light-svg-icons';

interface Props {
  deadline: number | null;
  showText?: boolean;
  showIcon?: boolean;
  showExpiredDate?: boolean;
  className?: string;
}

export const Deadline: FC<Props> = props => {
  const { deadline, showText, showIcon, showExpiredDate, className } = props;
  const { t } = useTranslation();
  const deadlineValue = useDeadlineDate(deadline, showExpiredDate);

  return (
    <div className={classNames(styles.deadlineContainer, { [className ?? '']: !!className })}>
      {showText && <p className={styles.text}>{t('Tenders.deadline')}:</p>}
      {showIcon && (
        <FontAwesomeIcon
          icon={faBell}
          className={classNames(styles.icon, { [styles.isExpired]: deadlineValue.isExpired })}
        />
      )}
      <p className={classNames(styles.value, { [styles.isExpired]: deadlineValue.isExpired })}>{deadlineValue.value}</p>
    </div>
  );
};

export default Deadline;
