import { ContentLibraryTagDTO, TagGroupColor } from '@tendium/prom-types/schema';

export class Tag {
  public readonly id: string;
  public readonly color: TagGroupColor;
  public readonly title: string;

  constructor(apiContentLibraryTag: ContentLibraryTagDTO) {
    this.id = apiContentLibraryTag.id;
    this.color = apiContentLibraryTag.color;
    this.title = apiContentLibraryTag.title;
  }
}
