import {
  IMatchingProfile,
  IApiMp,
  SupplierSearchTerm,
  MpKeyword,
  MpKeywordGroup,
  MpKeywordAndGroup,
  MatchingProfileEmbedding
} from './types';
import { IApiOrganization } from '../organisations/types';
import { AssignedTo } from '../users/types';
import { IAccessControl } from '../acl/types';
import { BuyerType, FilterLogic, JunctionLogic, KeywordWidth, Language, ProfileType } from '@tendium/prom-types';
import { getGroupedKeywords } from '../keywords/helpers';
import { ContractEndFilterOutput } from '@tendium/prom-types/schema';
import { toContractEnd } from './helpers';
import { toOwnersAndSubscribers } from '../acl/helpers';

export class MatchingProfile implements IMatchingProfile {
  public readonly id: string;
  public readonly name: string;
  public readonly owners: AssignedTo[];
  public readonly subscribers: AssignedTo[];
  public readonly accessToEdit: boolean;
  public readonly accessControl: IAccessControl;
  public readonly profileType: ProfileType;
  public readonly nutsCodes?: string[];
  public readonly nutsCodesExact?: string[];
  public readonly cpvCodes?: string[];
  public readonly cpvCodesExact?: string[];
  public readonly bTagIds?: string[];
  public readonly keywordWidth?: KeywordWidth;
  public readonly highlightKeywords?: MpKeywordAndGroup[];
  public readonly filterKeywords?: MpKeywordAndGroup[];
  public readonly apiKeywordGroups?: MpKeywordGroup[];
  public readonly apiKeywords?: MpKeyword[];
  public readonly buyerOrgIds?: string[];
  public readonly buyerSearchStrings?: string[];
  public readonly companies?: IApiOrganization[];
  public readonly supplierSearchTerms?: SupplierSearchTerm[];
  public readonly buyerTypes?: BuyerType[];
  public readonly supplierSearchLogic?: JunctionLogic;
  public readonly contractValueCurrency?: string;
  public readonly contractValueMin?: number;
  public readonly contractValueMax?: number;
  public readonly contractValueExcludeMissingValue?: boolean;
  public readonly contractEnd?: ContractEndFilterOutput;
  public readonly matchingProfileEmbedding?: MatchingProfileEmbedding;
  public readonly filterKeywordLanguages?: Language[];
  public readonly highlightKeywordLanguages?: Language[];

  constructor(apiMp: IApiMp, allUsersAndTeams: AssignedTo[]) {
    this.id = apiMp.id;
    this.name = apiMp.name;
    this.owners = toOwnersAndSubscribers(apiMp.owners, allUsersAndTeams);
    this.subscribers = toOwnersAndSubscribers(apiMp.subscribers, allUsersAndTeams);
    this.accessToEdit = apiMp.accessToEdit;
    this.accessControl = apiMp.accessControl;
    this.nutsCodes = apiMp.nutsCodes;
    this.nutsCodesExact = apiMp.nutsCodesExact;
    this.cpvCodes = apiMp.cpvCodes;
    this.cpvCodesExact = apiMp.cpvCodesExact;
    this.bTagIds = apiMp.bTagIds;
    this.keywordWidth = apiMp.keywordWidth;
    this.apiKeywords = apiMp.keywords;
    this.apiKeywordGroups = apiMp.keywordGroups;
    this.buyerOrgIds = apiMp.buyerOrgIds;
    this.buyerSearchStrings = apiMp.buyerSearchStrings;
    this.supplierSearchTerms = apiMp.supplierSearchTerms;
    this.buyerTypes = apiMp.buyerTypes;
    this.supplierSearchLogic = apiMp.supplierSearchLogic;
    this.contractValueCurrency = apiMp.contractValueCurrency;
    this.contractValueMin = apiMp.contractValueMin;
    this.contractValueMax = apiMp.contractValueMax;
    this.profileType = apiMp.profileType;
    this.contractValueExcludeMissingValue = apiMp.contractValueExcludeMissingValue;
    this.highlightKeywords = getGroupedKeywords(
      this.apiKeywords ?? [],
      this.apiKeywordGroups ?? [],
      FilterLogic.HighlightOnly
    );
    this.filterKeywords = getGroupedKeywords(this.apiKeywords ?? [], this.apiKeywordGroups ?? [], FilterLogic.Filter);
    this.contractEnd = toContractEnd(apiMp.contractEnd);
    this.matchingProfileEmbedding = apiMp.matchingProfileEmbedding;
    this.filterKeywordLanguages = apiMp.filterKeywordLanguages;
    this.highlightKeywordLanguages = apiMp.highlightKeywordLanguages;
  }
}
