import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AiBadge, Tag } from 'src/common';
import ProcurementCard from 'src/common/ProcurementCard';
import { IGenericSubCat, TenderBlockSize } from 'src/models/procurements/Tender/types';
import { TenderSubCat } from 'src/shared';
import styles from './index.module.scss';
import { useGetPreviousTenders } from 'src/models/procurements/PreviousTenders/hooks';
import { TagType } from 'src/common/Tag';

export const PREVIOUS_PROCUREMENTS_SUB_CAT: IGenericSubCat = {
  id: 'PREVIOUS_PROCUREMENTS',
  title: 'PREVIOUS_PROCUREMENTS',
  badge: <AiBadge />,
  size: TenderBlockSize.Full
};
interface Props {
  procurementId: string;
}

export const PreviousProcurements: FC<Props> = props => {
  const { procurementId } = props;
  const { t } = useTranslation();

  const { data: previousProcurements } = useGetPreviousTenders(procurementId);

  return !!previousProcurements.length ? (
    <TenderSubCat subCat={PREVIOUS_PROCUREMENTS_SUB_CAT} actions={<Tag type={TagType.Beta} />}>
      <span className={styles.desc}>{t('Tenders.previousProcurementDesc')}</span>
      {previousProcurements.map(previousProcurement => (
        <ProcurementCard
          key={previousProcurement.id}
          id={previousProcurement.id}
          title={previousProcurement.title}
          buyers={previousProcurement.buyers}
          suppliers={previousProcurement.suppliers}
          contractStart={previousProcurement.contractStart}
          contractEnd={previousProcurement.contractEnd}
        />
      ))}
    </TenderSubCat>
  ) : null;
};

export default PreviousProcurements;
