import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../Box/index.module.scss';
import classNames from 'classnames';
import { Tooltip, useExistingTranslation } from 'src/common';
import BoxDataSource from '../BoxDataSource';
import { RequirementStatusType, isRequirementStatus } from 'src/models/procurements/Tender/types';
import BoxActions from '../BoxActions';
import { BlockLabel, BlockEmpty } from 'src/shared/Blocks';
import LotsSups from 'src/shared/LotsSups';
import BoxField from '../BoxField';
import { IBoxBaseProps } from '../types';
import BoxDeleteAction from '../BoxDeleteAction';
import BoxComments from '../BoxComments';
import { useBoxProps } from '../hooks';
import { Translations } from 'src/lib/I18nService/types';

export const BoxBase: FC = () => {
  const { actions = true, box, isEditable, editable, config, hideEmpty } = useBoxProps() as IBoxBaseProps;
  const { fields, title, lots, dataSource, requirementStatus: reqStatus } = box;

  const { t } = useTranslation();
  const { existingT: tenderT } = useExistingTranslation(Translations.procurements);
  const requirementStatus = reqStatus || RequirementStatusType.OTHER;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          {!!isRequirementStatus(requirementStatus) && (
            <Tooltip title={t(`DetailsPage.RequirementStatusType.${requirementStatus}`)}>
              <span className={classNames(styles.reqStatus, styles[requirementStatus])} />
            </Tooltip>
          )}
          <BlockLabel label={tenderT(title)} />
          {!!lots?.length && <LotsSups lots={lots} />}
          {!!actions && (
            <>
              {actions}
              <BoxActions box={box} />
            </>
          )}
        </div>
        {editable && <BoxDeleteAction box={box} />}
      </div>
      <div className={styles.content}>
        {fields && !!fields.length ? (
          fields.map((field, index) => (
            <BoxField
              config={{ ...config, isTitle: !!config?.isTitle ? index !== 0 : false }}
              field={field}
              key={field.id}
              box={box}
              isEditable={!!isEditable}
              hideEmpty={hideEmpty}
            />
          ))
        ) : (
          <BlockEmpty />
        )}
        {dataSource && <BoxDataSource dataSource={dataSource} boxId={box.id} />}
        <BoxComments box={box} />
      </div>
    </>
  );
};

export default BoxBase;
