import Table, { ColumnProps } from 'antd/lib/table';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTenderOutcome } from 'src/models/procurements/PreviewOutcome/hooks';
import { BoxField } from 'src/shared';
import styles from './index.module.scss';
import { Skeleton } from 'src/common';
import { ITenderBox } from 'src/models/procurements/Tender/types';
import FilesContent from 'src/pages/TenderPage/ProcurementFiles/FilesContent';
import { BillingCharts } from 'src/shared/Tender/Billing';

type TableData = {
  key: string;
};

function useTableColumns(): ColumnProps<TableData>[] {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns: ColumnProps<TableData>[] = [
      {
        title: t('ExpiringContracts.bidderName'),
        dataIndex: 'Bidder name',
        className: styles.colName,
        ellipsis: true
      },
      {
        title: t('ExpiringContracts.id'),
        dataIndex: 'National registration number',
        className: styles.colName,
        ellipsis: true
      },
      {
        title: t('ExpiringContracts.price'),
        dataIndex: 'Offered price according to evaluation model',
        className: styles.colName,
        ellipsis: true
      },
      {
        title: t('ExpiringContracts.bidderStatus'),
        dataIndex: 'Bidder status',
        className: styles.colName,
        ellipsis: true
      }
    ];
    return columns;
  }, [t]);
}

const getRows = (rows: ITenderBox[]): { lots: Record<string, TableData[]>; rowsWithoutLot: TableData[] } => {
  const lots: Record<string, TableData[]> = {};
  const rowsWithoutLot: TableData[] = [];

  rows.forEach(box => {
    if (!box.lots.length) {
      const rowDataWithoutLot: { [key: string]: JSX.Element } = {};
      box.fields.forEach(field => {
        rowDataWithoutLot[field.name] = (
          <BoxField config={{ isTooltip: true }} isEditable={false} field={field} box={box} key={box.id} />
        );
      });

      const tableDataRowWithoutLot: TableData = {
        key: box.id,
        ...rowDataWithoutLot
      };

      rowsWithoutLot.push(tableDataRowWithoutLot);
      return;
    }

    const lotNumbers = box.lots.map(lot => lot.lotItem);

    lotNumbers.forEach(lotNumber => {
      if (!lots[lotNumber]) {
        lots[lotNumber] = [];
      }

      const rowData: Record<string, JSX.Element> = {};
      box.fields.forEach(field => {
        rowData[field.name] = (
          <BoxField config={{ isTooltip: true }} isEditable={false} field={field} box={box} key={box.id} />
        );
      });

      const tableDataRow: TableData = {
        key: box.id,
        ...rowData
      };

      lots[lotNumber].push(tableDataRow);
    });
  });

  return { lots, rowsWithoutLot };
};

type Props = { tenderId?: string };
export const TenderPreviewOutcome: FC<Props> = ({ tenderId }) => {
  const { t } = useTranslation();
  const { data, loading } = useTenderOutcome(tenderId);
  const columns: ColumnProps<TableData>[] = useTableColumns();
  const tableData = useMemo(() => getRows(data?.rows ?? []), [data?.rows]);

  if (loading) return <Skeleton loading={loading} />;

  if (!data?.rows?.length && !data?.outcomeFilesCategory.filesAmount) {
    return <div className={styles.desc}>{t('ExpiringContracts.outcomeEmpty')}</div>;
  }

  const { lots, rowsWithoutLot } = tableData;

  const table =
    rowsWithoutLot.length > 0 ? (
      <div className={styles.tableContainer}>
        <Table
          dataSource={rowsWithoutLot}
          columns={columns}
          className={styles.table}
          size="small"
          rowKey={record => record?.key}
          pagination={false}
        />
      </div>
    ) : null;

  const lotTables = Object.keys(lots).length
    ? Object.entries(lots).map(([lotNumber, rowData]) => {
        const lotData = data?.rows
          .find(row => row.lots.some(lot => String(lot.lotItem) === lotNumber))
          ?.lots.find(lot => String(lot.lotItem) === lotNumber);

        return (
          <div key={lotNumber} className={styles.lotTablesContainer}>
            <div className={styles.lotTitleContainer}>
              <span className={styles.lotTitle}>
                {t('ExpiringContracts.lot')}
                {lotNumber}
              </span>

              {lotData && <span className={styles.headerDesc}>{lotData.name}</span>}
            </div>
            <Table
              dataSource={rowData}
              columns={columns}
              className={styles.table}
              size="small"
              rowKey={record => record?.key}
              pagination={false}
            />
          </div>
        );
      })
    : null;

  return (
    <>
      {table}
      {lotTables}
      <FilesContent
        procurementFiles={data.outcomeFilesCategory}
        procurementId={data.id}
        eventSource="Outcome Tab - Preview"
      />
      <BillingCharts tenderId={tenderId} />
    </>
  );
};

export default TenderPreviewOutcome;
