import { useCallback, useMemo } from 'react';
import { MutationTuple, QueryResult } from '@apollo/client/react/types/types';
import { useMutation, useQuery, MutationUpdaterFn } from '@apollo/client';
import {
  GET_WEBHOOKS,
  CREATE_WEBHOOK,
  UPDATE_WEBHOOK,
  REMOVE_WEBHOOK,
  WEBHOOKS_FIELDS,
  SEND_CREATE_BID_WEBHOOK,
  GET_WEBHOOKS_WITH_SETTINGS,
  UPDATE_WORKSPACE_WEBHOOKS_SETTINGS,
  CREATE_WEBHOOK_STATUS
} from './queries';
import {
  ApiWebhookResponse,
  ApiWebhookVars,
  CreateWebhookStatus,
  IApiWebhook,
  UpdateWebhookBidspaceSettings,
  WebhookStatus
} from './types';
import { WebhookEventType } from '@tendium/prom-types';
import { Webhook } from '.';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';

export function useLoadWebhooksWithSettings(workspaceId: string): Omit<
  QueryResult<ApiWebhookResponse, ApiWebhookVars>,
  'data'
> & {
  data?: IApiWebhook[];
} {
  const isWebHooksFeature = useFeatureFlag(FeatureFlag.Users_Webhooks);
  const query = useQuery<ApiWebhookResponse, ApiWebhookVars>(GET_WEBHOOKS_WITH_SETTINGS, {
    variables: { workspaceId },
    skip: !isWebHooksFeature
  });
  return useMemo(
    () => ({
      ...query,
      data: query.data ? query.data.getWebhooks.map(webhook => new Webhook(webhook)) : undefined
    }),
    [query]
  );
}

export function useLoadWebhooks(): Omit<QueryResult<ApiWebhookResponse>, 'data'> & {
  data?: IApiWebhook[];
} {
  const isWebHooksFeature = useFeatureFlag(FeatureFlag.Users_Webhooks);

  const query = useQuery<ApiWebhookResponse>(GET_WEBHOOKS, {
    skip: !isWebHooksFeature
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? query.data.getWebhooks.map(webhook => new Webhook(webhook)) : undefined
    }),
    [query]
  );
}

export function useLoadWebhook(id: string): IApiWebhook | undefined {
  const { data: webhooks } = useLoadWebhooks();
  return useMemo(() => {
    return webhooks && webhooks.find(hook => hook.id === id);
  }, [id, webhooks]);
}

export interface ICreateWebhook {
  __typename: 'Mutation';
  createWebhook: IApiWebhook & {
    __typename: 'Webhook';
  };
}

export function useCreateWebhook(): MutationTuple<
  ICreateWebhook,
  { name: string; url: string; eventType: WebhookEventType; secret: string }
> {
  return useMutation(CREATE_WEBHOOK);
}

export function getUpdateCacheOnWebhookCreate(): MutationUpdaterFn<{
  createWebhook: IApiWebhook;
  __typename: 'Mutation';
}> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const webhooksQueryData = cache.readQuery<ApiWebhookResponse | null>({
      query: GET_WEBHOOKS
    });
    if (!webhooksQueryData) {
      return;
    }
    const { getWebhooks } = webhooksQueryData;
    cache.writeQuery({
      query: GET_WEBHOOKS,
      data: { getWebhooks: [...getWebhooks, data.createWebhook] }
    });
  };
}

export interface IUpdateWebhook {
  __typename: 'Mutation';
  updateWebhook: IApiWebhook & {
    __typename: 'Webhook';
  };
}
export interface IUpdateWebhookInput {
  name?: string;
  url?: string;
  eventType?: WebhookEventType;
  secret?: string;
}
export function useUpdateWebhook(): MutationTuple<
  IUpdateWebhook,
  { id: string; name?: string; url?: string; eventType?: WebhookEventType; secret?: string }
> {
  return useMutation(UPDATE_WEBHOOK);
}
export function getUpdateCacheOnUpdateWebhook(id: string): MutationUpdaterFn<IUpdateWebhook> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const webhookRef = cache.identify({
      __typename: 'Webhook',
      id
    });
    cache.writeFragment({
      id: webhookRef,
      fragment: WEBHOOKS_FIELDS,
      data: data.updateWebhook,
      fragmentName: 'webhooksFields'
    });
  };
}

export function useRemoveWebhook(): MutationTuple<
  { removeWebhook: IApiWebhook[]; __typename: 'Mutation' },
  { id: string }
> {
  return useMutation(REMOVE_WEBHOOK);
}

export function getUpdateCacheOnRemoveWebhook(
  id: string
): MutationUpdaterFn<{ removeWebhook: IApiWebhook[]; __typename: 'Mutation' }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const webhooksData = cache.readQuery<ApiWebhookResponse | null>({
      query: GET_WEBHOOKS
    });
    if (!webhooksData) {
      return;
    }
    const { getWebhooks } = webhooksData;
    const newWebhooks = getWebhooks.filter(hook => hook.id !== id);
    cache.writeQuery({
      query: GET_WEBHOOKS,
      data: { getWebhooks: newWebhooks }
    });
  };
}

export function useSendCreateBidWebhook(): MutationTuple<
  { id: string; webhookStatus: WebhookStatus },
  { bidId: string }
> {
  return useMutation(SEND_CREATE_BID_WEBHOOK);
}

export function useUpdateWebhookBidspaceSettings(): [
  (webhookSettings: UpdateWebhookBidspaceSettings) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [updateWebhookBidspaceSettings, { loading, error }] = useMutation(UPDATE_WORKSPACE_WEBHOOKS_SETTINGS);

  const updateWebhookBidspaceSettingsFn = useCallback(
    (webhookSettings: UpdateWebhookBidspaceSettings) => {
      updateWebhookBidspaceSettings({
        variables: { webhookSettings }
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, updateWebhookBidspaceSettings]
  );
  return [updateWebhookBidspaceSettingsFn, { loading, error }];
}

export function useCreateWebhookStatus(): [
  (webhookStatus: CreateWebhookStatus) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [createWebhookStatus, { loading, error }] = useMutation(CREATE_WEBHOOK_STATUS);

  const createWebhookStatusFn = useCallback(
    (webhookStatus: CreateWebhookStatus) => {
      createWebhookStatus({
        variables: { webhookStatus },
        update: getUpdateCacheOnCreateWebhookStatus(webhookStatus)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, createWebhookStatus]
  );
  return [createWebhookStatusFn, { loading, error }];
}

function getUpdateCacheOnCreateWebhookStatus(
  webhookStatus: CreateWebhookStatus
): MutationUpdaterFn<{ createWebhookStatus: WebhookStatus }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const ref = cache.identify({
      __typename: 'BidV2',
      id: webhookStatus.relatedEntityId
    });
    cache.modify({
      id: ref,
      fields: {
        webhookStatus() {
          return data.createWebhookStatus;
        }
      }
    });
  };
}
