import { LegendProps } from '../types';
import React, { JSX } from 'react';
import styles from './index.module.scss';
import { Legend } from '@visx/legend';
import classNames from 'classnames';
import { Tooltip } from 'src/common';

export function CustomLegend(props: LegendProps): JSX.Element | null {
  const { colorScale, labelFormat, vertical, truncate, tooltip, setFade, onMouseEnter, onMouseLeave, renderLabel } =
    props;
  return (
    <Legend scale={colorScale} labelFormat={labelFormat}>
      {labels => (
        <div
          className={classNames(styles.legend, {
            [styles.column]: !!vertical
          })}
        >
          {labels.map((label, i) => {
            const LabelNode = (
              <Tooltip title={tooltip ? label.text : null}>
                <span className={classNames(styles.text, { [styles.truncate]: !!truncate })}>{label.text}</span>
              </Tooltip>
            );
            return (
              <div
                key={`legend-${label.text}-${i}`}
                className={classNames(styles.legendItem, {
                  [styles.fade]: setFade ? setFade(label.datum) : false
                })}
                onMouseEnter={() => onMouseEnter && onMouseEnter(label.datum)}
                onMouseLeave={onMouseLeave}
              >
                <div
                  className={styles.icon}
                  style={{
                    backgroundColor: label.value
                  }}
                />
                {renderLabel ? renderLabel(label, LabelNode) : LabelNode}
              </div>
            );
          })}
        </div>
      )}
    </Legend>
  );
}

export default CustomLegend;
