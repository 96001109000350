import { makeVar } from '@apollo/client';
import { ReactiveVariable, useReactiveVariable } from 'src/helpers/reactiveVariables';
import { ICallOff } from 'src/models/callOffs/types';

const SelectedCallOffsVar = makeVar<ICallOff[]>([]);
export function useSelectedCallOffs(): ReactiveVariable<ICallOff[]> {
  return useReactiveVariable<ICallOff[]>(SelectedCallOffsVar);
}

export default useSelectedCallOffs;
