import { AccessControlPolicy } from 'src/models/acl/types';
import { safeCallSegment } from '../helpers';

export function trackAddCallOffInbox(co: { id: string }): void {
  safeCallSegment(() => {
    analytics.track('CO add inbox', {
      co
    });
  });
}

/** @todo should be used on a link-click, not on a page watch
 * - analytics.track => for recording user actions
 * - analytics.page => for listening page changes
 */
export function trackSwitchCallOffs(co: { id: string }): void {
  safeCallSegment(() => {
    analytics.track('CO switch call offs', {
      co
    });
  });
}

export function trackUpdateInboxSubscribersPolicy(inboxId: string, policy: AccessControlPolicy): void {
  safeCallSegment(() => {
    analytics.track('Update inbox subscribers policy', {
      inboxId,
      policy
    });
  });
}

export function trackUpdateInboxOwnersPolicy(inboxId: string, policy: AccessControlPolicy): void {
  safeCallSegment(() => {
    analytics.track('Update inbox owners policy', {
      inboxId,
      policy
    });
  });
}

export function trackUpdateInboxSubscribersAndOwners(inboxId: string): void {
  safeCallSegment(() => {
    analytics.track('Update Inbox subscribers and owners', {
      inboxId
    });
  });
}

export function trackUpdateInboxSubscribers(inboxId: string): void {
  safeCallSegment(() => {
    analytics.track('Update Inbox subscribers', {
      inboxId
    });
  });
}

export function trackUpdateInboxOwners(inboxId: string): void {
  safeCallSegment(() => {
    analytics.track('Update Inbox owners', {
      inboxId
    });
  });
}
