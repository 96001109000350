import React, { FC } from 'react';
import SidebarView from 'src/shared/InfoSidebar/SidebarView';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import WorkspacePreview from '../WorkspacePreview';
import BidPreview from '../BidPreview';

const BidsSidebars: FC = () => {
  const sidebarState = useCurrentSidebar();

  return (
    <>
      {sidebarState && (
        <SidebarView>
          {sidebarState.mode === SidebarMode.WORKSPACE_INFO && <WorkspacePreview />}
          {sidebarState.mode === SidebarMode.BID_INFO && sidebarState.id && <BidPreview key={sidebarState.id} />}
        </SidebarView>
      )}
    </>
  );
};

export default BidsSidebars;
