import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IProps {
  children: React.ReactNode | React.ReactNode[] | null;
}

const ScrollToTop: FC<IProps> = props => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
