import React, { ReactNode, FC, useMemo, useEffect } from 'react';
import { BidPreviewContext } from './context';
import { useBidPreviewData } from './hooks';
import { BidPreviewData } from '.';
import useCurrentTenderId from 'src/reactiveVars/CurrentTenderIdVar';

interface IProps {
  children: ReactNode;
  id: string;
}

export const BidPreviewProvider: FC<IProps> = ({ children, id }) => {
  const { data: rawData, loading } = useBidPreviewData(id);
  const [, setTenderId] = useCurrentTenderId();

  useEffect(() => {
    setTenderId(rawData?.getBid.itemId);
  }, [rawData, setTenderId]);

  const data = useMemo(() => (rawData ? new BidPreviewData({ ...rawData.getBid }) : null), [rawData]);
  const contextValue = useMemo(() => ({ data, loading }), [loading, data]);

  return <BidPreviewContext.Provider value={contextValue}>{children}</BidPreviewContext.Provider>;
};
