import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, UserName } from 'src/common';
import { IUserName } from 'src/models/users/types';
import styles from './index.module.scss';

interface IProps {
  users: IUserName[];
}

export const UserCount: FC<IProps> = ({ users }) => {
  const { t } = useTranslation();
  const count = useMemo(() => users.length, [users.length]);
  const title = useMemo(() => {
    return t('Users.userCount', { count });
  }, [count, t]);
  return (
    <div className={styles.container}>
      {!!count ? (
        <Tooltip
          title={users.map(user => (
            <UserName className={styles.user} key={user.email} user={user} size={'s'} />
          ))}
        >
          {title}
        </Tooltip>
      ) : (
        title
      )}
    </div>
  );
};

export default UserCount;
