import { IIconMapper } from 'src/types/icon';
import { NotificationGroupName } from './types';
import {
  faCommentDots,
  faSparkles,
  faAlarmExclamation,
  faCircleUser,
  faHighlighter
} from '@fortawesome/pro-light-svg-icons';

export const NOTIFICATION_ICON_MAPPER: IIconMapper = {
  [NotificationGroupName.DEADLINE]: { defaultIcon: faAlarmExclamation },
  [NotificationGroupName.ASSIGNED]: { defaultIcon: faCircleUser },
  [NotificationGroupName.COMMENTS]: { defaultIcon: faCommentDots },
  [NotificationGroupName.CHANGES_IN_STATUS]: { defaultIcon: faHighlighter },
  [NotificationGroupName.MP_DAILY_SENDOUT]: { defaultIcon: faSparkles },
  [NotificationGroupName.WS_SENDOUT]: { defaultIcon: faSparkles },
  [NotificationGroupName.EXPIRING_CONTRACTS_SENDOUT]: { defaultIcon: faSparkles }
};
