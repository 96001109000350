import { createContext } from 'react';
import { TenderPreviewData } from '.';
import { MpNewKeywordGroup } from 'src/models/matchingProfiles/types';

export type TenderPreviewDataContextValue = {
  loading: boolean;
  data: TenderPreviewData | null;
  keywords: MpNewKeywordGroup[];
};
export const TenderPreviewDataContext = createContext<TenderPreviewDataContextValue | null>(null);
