import { useMemo } from 'react';
import { MutationTuple, MutationUpdaterFn, Reference, useMutation, useQuery, QueryResult } from '@apollo/client';
import { ASSIGN_MAILS_TO_BID, UNASSIGN_MAILS_TO_BID, GET_ALL_BIDS } from './queries';
import { EmailStatus } from '../../callOffs/types';
import {
  ILinkedBid,
  IGetAllBidsResponse,
  ILinkedBidsData,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ILinkedBidsSearchArgs
} from './types';
import { LinkedBidsData } from '.';
import { useLinkedBidsSearchArgs } from 'src/reactiveVars/SelectedBidsSearchArgsVar';

export function useLinkedBids(): Omit<QueryResult<IGetAllBidsResponse, ILinkedBidsSearchArgs>, 'data'> & {
  data?: ILinkedBidsData;
} {
  const page = DEFAULT_PAGE;
  const pageSize = DEFAULT_PAGE_SIZE;
  const [search] = useLinkedBidsSearchArgs();
  const query = useQuery<IGetAllBidsResponse, ILinkedBidsSearchArgs>(GET_ALL_BIDS, {
    variables: {
      amount: pageSize,
      offset: (page - 1) * pageSize,
      search
    }
  });

  return useMemo(() => ({ ...query, data: query.data ? new LinkedBidsData(query.data.getBids) : undefined }), [query]);
}

export function useLinkedBid(bidId: string): ILinkedBid | null {
  const { data } = useLinkedBids();

  return useMemo(() => (data && data.bids.find(bid => bid.id === bidId)) || null, [bidId, data]);
}

interface IAssignMailsToBidResponse {
  __typename: 'Mutation';
  assignMultipleMailsToBid: ILinkedBid & {
    __typename: 'BidV2';
  };
}

interface IUnassignMailsToBidResponse {
  __typename: 'Mutation';
  unassignMultipleMailsToBid: ILinkedBid & {
    __typename: 'BidV2';
  };
}
interface ILinkMailsToBidRequest {
  bidId: string;
  mailIds: string[];
}
export function useAssignMailsToBid(): MutationTuple<IAssignMailsToBidResponse, ILinkMailsToBidRequest> {
  return useMutation(ASSIGN_MAILS_TO_BID);
}

export function useUnAssignMailsToBid(): MutationTuple<IUnassignMailsToBidResponse, ILinkMailsToBidRequest> {
  return useMutation(UNASSIGN_MAILS_TO_BID);
}

export function updateCacheOnAssignCallOffToBid(originIds: string[]): MutationUpdaterFn<IAssignMailsToBidResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: data.assignMultipleMailsToBid.id
    });
    originIds.forEach(originId => {
      const callOffRef = cache.identify({
        __typename: 'UserMail',
        mailId: originId
      });
      cache.modify({
        id: callOffRef,
        fields: {
          linkedBidsV2() {
            return [{ __ref: bidRef }];
          },
          mailStatus() {
            return EmailStatus.INTERESTING;
          }
        }
      });
      cache.modify({
        id: bidRef,
        fields: {
          mails(existingRefs: Reference[], { toReference, readField }) {
            return existingRefs.some(ref => readField('id', ref) === originId)
              ? existingRefs
              : [
                  ...existingRefs,
                  toReference({
                    __typename: 'UserMail',
                    mailId: originId
                  })
                ];
          }
        }
      });
    });
  };
}
