import { AssignedTo } from 'src/models/users/types';
import { ContentLibraryRoomDTO } from '@tendium/prom-types/schema';
import { AccessControlPolicy } from 'src/models/acl/types';
import { toOwnersAndSubscribers } from 'src/models/acl/helpers';

export class Room {
  public readonly id: string;
  public readonly title: string;
  public readonly owners: AssignedTo[];
  public readonly subscribers: AssignedTo[];
  public readonly editPolicy: AccessControlPolicy;
  public readonly subscribePolicy: AccessControlPolicy;
  public readonly accessToEdit: boolean;

  constructor(apiContentLibraryRoom: ContentLibraryRoomDTO, allUsersAndTeams: AssignedTo[]) {
    this.id = apiContentLibraryRoom.id;
    this.title = apiContentLibraryRoom.title;
    this.owners = toOwnersAndSubscribers(apiContentLibraryRoom.owners, allUsersAndTeams);
    this.subscribers = toOwnersAndSubscribers(apiContentLibraryRoom.subscribers, allUsersAndTeams);
    this.editPolicy = AccessControlPolicy[apiContentLibraryRoom.editPolicy];
    this.subscribePolicy = AccessControlPolicy[apiContentLibraryRoom.subscribePolicy];
    this.accessToEdit = apiContentLibraryRoom.accessToEdit;
  }
}
