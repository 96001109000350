import { ContractEndRangeMode } from '@tendium/prom-types';
import { ContractEndFilterOutput } from '@tendium/prom-types/schema';
import { TFunction } from 'i18next';
import { filterTreeNodes } from 'src/common/betaComponents/MpTree/utils';
import isISOString from 'src/helpers/isISOString';
import isNumber from 'src/helpers/isNumber';
import { notNull } from 'src/helpers/typescript';
import { CodeFilter, MpCode, MpCodeTypes } from 'src/models/matchingProfiles/types';
import { IPreferencesTreeNode } from 'src/shared/MatchingProfile/PreferencesTree/types';

export function toPreferencesTree(
  type: MpCodeTypes,
  nodes: readonly MpCode[],
  t: TFunction,
  filter?: CodeFilter
): IPreferencesTreeNode[] {
  function applySort(values: IPreferencesTreeNode[]): IPreferencesTreeNode[] {
    return type === 'nutsCodes' ? values.sort((a, b) => a.label.localeCompare(b.label)) : values;
  }
  const handleNode = (node: MpCode): IPreferencesTreeNode => {
    const { code, children } = node;
    const [mainCode] = code.split('-');
    return {
      key: code,
      label: t(`${type}|${type === 'cpvCodes' ? mainCode : code}`),
      children: applySort(children.map(handleNode))
    };
  };
  const sortedCodes = applySort(nodes.map(handleNode));

  if (filter) {
    return filterTreeNodes(sortedCodes, filter, true);
  }
  return sortedCodes;
}

export function toCpvCodes(codes: string[]): string[] {
  return codes.map(code => code.replace(/[^\d-]/g, '').match(/^\d{8}-\d/)?.[0] || null).filter(notNull);
}

export function toContractEnd(contractEnd?: ContractEndFilterOutput): ContractEndFilterOutput | undefined {
  const isRelative =
    contractEnd?.rangeMode === ContractEndRangeMode.RELATIVE &&
    (isNumber(contractEnd?.relativeStart) || isNumber(contractEnd?.relativeEnd));
  const isAbsolute =
    contractEnd?.rangeMode === ContractEndRangeMode.ABSOLUTE &&
    (isISOString(contractEnd?.start) || contractEnd?.start instanceof Date) &&
    (isISOString(contractEnd?.end) || contractEnd?.end instanceof Date);
  return {
    ...(isAbsolute
      ? {
          start: contractEnd?.start ?? undefined,
          end: contractEnd?.end ?? undefined
        }
      : undefined),
    ...(isRelative
      ? {
          relativeStart: contractEnd?.relativeStart ?? undefined,
          relativeEnd: contractEnd?.relativeEnd ?? undefined
        }
      : undefined),
    searchMode: contractEnd?.searchMode,
    rangeMode: contractEnd?.rangeMode,
    strictPeriodOverlap: contractEnd?.strictPeriodOverlap,
    contractDurationYearsFallback: contractEnd?.contractDurationYearsFallback
  };
}
