import { isObject, isString } from 'src/helpers';

export function isApiOriginCallOff<T>(u: unknown): u is T {
  return isObject(u) && 'mailId' in u && isString(u.mailId);
}

export type BidByggfaktaProject = {
  byggfaktaProject?: ByggfaktaProject;
};

export type ByggfaktaProject = {
  createdAt: number;
  bidId: string;
  projectId: string;
};
