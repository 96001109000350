import { Reference } from '@apollo/client';
import { TenderCatName, TenderKeys, TenderSubCatName } from './Tender/types';

export enum ProcurementStatus {
  Award = 'Award',
  Tender = 'Tender',
  TenderCancellation = 'TenderCancellation',
  Planning = 'Planning'
}
export type ITendersCached = {
  procurementsWithScore: { procurement: Reference }[];
  count: number;
  fetchedTendersCount: number;
};
/** @fixme move to `Sidebars` types */
export enum OverviewQp {
  PreviewId = 'previewId',
  PreviewMode = 'previewMode',
  CommentId = 'commentId'
}

export enum ActivePreviewTab {
  Info = 'info',
  Comments = 'comments',
  Email = 'Email',
  Attachment = 'attachment',
  Matches = 'matches',
  Outcome = 'outcome'
}

export enum BoxSpecId {
  TITLE_OF_THE_TENDER = 'TITLE_OF_THE_TENDER',
  MINIMUM_TIME_FRAME_DURING_WHICH_THE_TENDERER_MUST_MAINTAIN_THE_TENDER = 'MINIMUM_TIME_FRAME_DURING_WHICH_THE_TENDERER_MUST_MAINTAIN_THE_TENDER',
  CONTRACT_PERIOD = 'CONTRACT_PERIOD',
  DEADLINE_OF_CLARIFICATION_QUESTIONS = 'DEADLINE_OF_CLARIFICATION_QUESTIONS',
  EXPECTED_PUBLICATION_DATE = 'EXPECTED_PUBLICATION_DATE',
  DEADLINE = 'DEADLINE',
  DEMONSTRATION_2 = 'DEMONSTRATION_2',
  PRESENTATION_2 = 'PRESENTATION_2',
  SHOWING_OF_OBJECTS_2 = 'SHOWING_OF_OBJECTS_2',
  DATE_OF_DISPATCH = 'DATE_OF_DISPATCH',
  PROCURING_AGENCY = 'PROCURING_AGENCY',
  LINK_TO_TED_2 = 'LINK_TO_TED_2',
  LINK_TO_TED = 'LINK_TO_TED',
  TYPE_OF_DOCUMENT = 'TYPE_OF_DOCUMENT',
  TYPE_OF_CONTRACT = 'TYPE_OF_CONTRACT',
  MAIN_SITE_OR_PLACE_OF_PERFORMANCE = 'MAIN_SITE_OR_PLACE_OF_PERFORMANCE',
  ESTIMATED_CONTRACT_VALUE_2 = 'ESTIMATED_CONTRACT_VALUE_2',
  ESTIMATED_CONTRACT_VALUE = 'ESTIMATED_CONTRACT_VALUE',
  REFERENCE_NUMBER = 'REFERENCE_NUMBER',
  TYPE_OF_PROCEDURE_2 = 'TYPE_OF_PROCEDURE_2',
  EVALUATION_MODEL_3 = 'EVALUATION_MODEL_3',
  CONTACT_PERSON = 'CONTACT_PERSON',
  EMAIL = 'EMAIL',
  'E-MAIL' = 'E-MAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  LINK_FOR_SUBMITTING_TENDER_2 = 'LINK_FOR_SUBMITTING_TENDER_2',
  LINK_FOR_SUBMITTING_TENDER = 'LINK_FOR_SUBMITTING_TENDER',
  LINK_TO_PROCURER_2 = 'LINK_TO_PROCURER_2',
  LINK_TO_PROCURER = 'LINK_TO_PROCURER',
  THIS_IS_A_RECURRING_PROCUREMENT = 'THIS_IS_A_RECURRING_PROCUREMENT',
  LINK_TO_TENDER_DOCUMENT_2 = 'LINK_TO_TENDER_DOCUMENT_2',
  LINK_TO_TENDER_DOCUMENT = 'LINK_TO_TENDER_DOCUMENT',
  LINK_TO_ETENDERING_2 = 'LINK_TO_ETENDERING_2',
  LINK_TO_ETENDERING = 'LINK_TO_ETENDERING',
  LINK_TO_Q_AND_A = 'LINK_TO_Q_AND_A',
  MAIN_CPV_CODE = 'MAIN_CPV_CODE',
  ADDITIONAL_CPV_CODE = 'ADDITIONAL_CPV_CODE',
  FRAMEWORK_AGREEMENT_2 = 'FRAMEWORK_AGREEMENT_2',
  INTRODUCTION = 'INTRODUCTION',
  SHORT_DESCRIPTION = 'SHORT_DESCRIPTION',
  SCOPE_OF_CONTRACT = 'SCOPE_OF_CONTRACT',
  TOWN = 'TOWN',
  COUNTRY = 'COUNTRY',
  PLACE_OF_PERFORMANCE_NUTS_CODE = 'PLACE_OF_PERFORMANCE_NUTS_CODE',
  NUTS_CODE = 'NUTS_CODE',
  POSTAL_CODE = 'POSTAL_CODE',
  POSTAL_ADDRESS = 'POSTAL_ADDRESS',
  NUMBER_OF_SUPPLIERS_ASSIGNED_CONTRACT = 'NUMBER_OF_SUPPLIERS_ASSIGNED_CONTRACT',
  CONTRACT_RENEWAL = 'CONTRACT_RENEWAL',
  AVAILABLE_DATE_BOX = 'AVAILABLE_DATE_BOX',
  CONTRACT_DURATION = 'CONTRACT_DURATION',
  CONTRACT_PERIOD_START = 'CONTRACT_PERIOD_START',
  CONTRACT_PERIOD_END = 'CONTRACT_PERIOD_END',
  DEMONSTRATION_2_START = 'DEMONSTRATION_2_START',
  DEMONSTRATION_2_END = 'DEMONSTRATION_2_END',
  PRESENTATION_2_START = 'PRESENTATION_2_START',
  PRESENTATION_2_END = 'PRESENTATION_2_END',
  'SHOWING_OF_OBJECT(S)_2_START' = 'SHOWING_OF_OBJECT(S)_2_START',
  'SHOWING_OF_OBJECT(S)_2_END' = 'SHOWING_OF_OBJECT(S)_2_END',
  CONTRACT_AWARD_INFORMATION_2 = 'CONTRACT_AWARD_INFORMATION_2'
}

export const BOX_SPEC_KEY_MAPPER: Record<TenderKeys, { category: string; specificationId: BoxSpecId; title: string }> =
  {
    name: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.TITLE_OF_THE_TENDER,
      title: 'Title of the tender'
    },
    description: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.ShortDescription}`,
      specificationId: BoxSpecId.SHORT_DESCRIPTION,
      title: 'Short description'
    },
    introduction: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Intro}`,
      specificationId: BoxSpecId.INTRODUCTION,
      title: 'Introduction'
    },
    scopeOfContract: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.ScopeOfContract}`,
      specificationId: BoxSpecId.SCOPE_OF_CONTRACT,
      title: 'Scope of contract'
    },
    city: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.TOWN,
      title: 'Town'
    },
    country: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.COUNTRY,
      title: 'Country'
    },
    placeOfPerformanceNutsCode: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Location}`,
      specificationId: BoxSpecId.PLACE_OF_PERFORMANCE_NUTS_CODE,
      title: 'Place of performance nuts code'
    },
    buyerNutsCode: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.NUTS_CODE,
      title: 'Nuts code'
    },
    postcode: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.POSTAL_CODE,
      title: 'Postal code'
    },
    postalAddress: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.POSTAL_ADDRESS,
      title: 'Postal address'
    },
    buyerBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.PROCURING_AGENCY,
      title: 'Procuring agency'
    },
    contractDurationBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contract}`,
      specificationId: BoxSpecId.CONTRACT_DURATION,
      title: 'Contract duration'
    },
    locationBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Location}`,
      specificationId: BoxSpecId.MAIN_SITE_OR_PLACE_OF_PERFORMANCE,
      title: 'Place of performance'
    },
    contractRenewalBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contract}`,
      specificationId: BoxSpecId.CONTRACT_RENEWAL,
      title: 'Contract renewal'
    },
    contractValueBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contract}`,
      specificationId: BoxSpecId.ESTIMATED_CONTRACT_VALUE_2,
      title: 'Estimated contract value'
    },
    typeOfProcedureBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.TYPE_OF_PROCEDURE_2,
      title: '"Type of procedure'
    },
    contactPersonBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.CONTACT_PERSON,
      title: 'Contact person'
    },
    emailBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.EMAIL,
      title: 'E-mail'
    },
    phoneBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.PHONE_NUMBER,
      title: 'Phone number'
    },
    bidValidityBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.MINIMUM_TIME_FRAME_DURING_WHICH_THE_TENDERER_MUST_MAINTAIN_THE_TENDER,
      title: 'Bid validity period'
    },
    linkToProcurerBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contact}`,
      specificationId: BoxSpecId.LINK_TO_PROCURER_2,
      title: 'Link to procurer'
    },
    linkForSubmittingTenderBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.LINK_FOR_SUBMITTING_TENDER_2,
      title: 'Link for submitting tender'
    },
    linkToTenderDocumentBoxes: {
      category: `${TenderCatName.Docs}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.LINK_TO_TENDER_DOCUMENT_2,
      title: 'Link to tender document'
    },
    linkToQA: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.LINK_TO_Q_AND_A,
      title: 'Link to questions and answers'
    },
    mainCpvCode: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Cpv}`,
      specificationId: BoxSpecId.MAIN_CPV_CODE,
      title: 'Main CPV code'
    },
    additionalCpvCode: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Cpv}`,
      specificationId: BoxSpecId.ADDITIONAL_CPV_CODE,
      title: 'Additional CPV code'
    },
    frameworkAgreement: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.FRAMEWORK_AGREEMENT_2,
      title: 'Framework agreement'
    },
    numberOfSuppliersAssignedContract: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Contract}`,
      specificationId: BoxSpecId.NUMBER_OF_SUPPLIERS_ASSIGNED_CONTRACT,
      title: 'The number of suppliers assigned to framework agreement'
    },
    referenceNumberBoxes: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Procurement}`,
      specificationId: BoxSpecId.REFERENCE_NUMBER,
      title: 'Reference number'
    },
    availableDate: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.AVAILABLE_DATE_BOX,
      title: 'availableDate'
    },
    minimumTimeFrame: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.MINIMUM_TIME_FRAME_DURING_WHICH_THE_TENDERER_MUST_MAINTAIN_THE_TENDER,
      title: 'Bid validity period' //'Minimum time frame during which the tenderer must maintain the tender
    },
    contractStartAndEndStart: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.CONTRACT_PERIOD_START,
      title: 'Contract period' //'Contract start'
    },
    contractStartAndEndEnd: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.CONTRACT_PERIOD_END,
      title: 'Contract period' //'Contract end'
    },
    deadlineOfClarificationQuestions: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.DEADLINE_OF_CLARIFICATION_QUESTIONS,
      title: 'Deadline of clarification questions'
    },
    expectedPublicationDate: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.EXPECTED_PUBLICATION_DATE,
      title: 'Expected publication date for the procurement'
    },
    deadline: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.DEADLINE,
      title: 'Submission deadline'
    },
    demonstrationStart: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.DEMONSTRATION_2_START,
      title: 'Demonstration' //'Contract start'
    },
    demonstrationEnd: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.DEMONSTRATION_2_END,
      title: 'Demonstration' //'Contract end'
    },
    presentationStart: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.PRESENTATION_2_START,
      title: 'Presentation' //'Contract start'
    },
    presentationEnd: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.PRESENTATION_2_END,
      title: 'Presentation' //Contract end
    },
    showingOfObjectsStart: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId['SHOWING_OF_OBJECT(S)_2_START'],
      title: 'Showing of object(s)' //Contract start
    },
    showingOfObjectsEnd: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId['SHOWING_OF_OBJECT(S)_2_END'],
      title: 'Showing of object(s)' //Contract end
    },
    dateOfDispatch: {
      category: `${TenderCatName.Overview}.${TenderSubCatName.Timeline}`,
      specificationId: BoxSpecId.DATE_OF_DISPATCH,
      title: 'Date of dispatch of this notice'
    },
    awardCriteria: {
      category: `BIDDING_PROCESS.EVALUATION_PROCESS`,
      specificationId: BoxSpecId.EVALUATION_MODEL_3,
      title: 'Evaluation model'
    }
  };
