import React, { FC, useState, ReactNode, useCallback } from 'react';
import styles from '../index.module.scss';
import { Dropdown } from 'src/common';
import QuickActionsMenu from '../QuickActionsMenu';
import { UpdateQuickActionsCloseContext } from '../context';
import classNames from 'classnames';

interface IProps {
  children: ReactNode;
  placement?: (typeof Placements)[number];
  className?: string;
  buttonClassName?: string;
}

export const QuickActions: FC<IProps> = props => {
  const { children, placement, className, buttonClassName } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const onClose = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  return (
    <UpdateQuickActionsCloseContext.Provider value={onClose}>
      <Dropdown
        trigger={['click']}
        placement={placement}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        overlay={<QuickActionsMenu>{children}</QuickActionsMenu>}
        visible={dropdownVisible}
        onVisibleChange={visible => setDropdownVisible(visible)}
        overlayClassName={classNames(styles.quickActions, !!className && className)}
      >
        <button
          className={classNames(
            styles.actionsButton,
            { [styles.isActive]: dropdownVisible },
            !!buttonClassName && buttonClassName
          )}
          onClick={e => e.stopPropagation()}
        >
          <span className={styles.actionsIconLeft} />
          <span className={styles.actionsIcon} />
          <span className={styles.actionsIconRight} />
        </button>
      </Dropdown>
    </UpdateQuickActionsCloseContext.Provider>
  );
};

export default QuickActions;
