import { useContext, useCallback, useMemo } from 'react';
import { UserSearchContext, UpdateUserSearchContext, UserSearch } from './context';
import { AssignedTo } from 'src/models/users/types';
import { isUser } from 'src/models/users/types';

export function useUserSearch(initialUsers: AssignedTo[]): {
  data: UserSearch;
  search: (str: string) => void;
  clear: () => void;
} {
  const userSearch = useContext(UserSearchContext);
  const updateUserSearch = useContext(UpdateUserSearchContext);
  const { searchString } = userSearch;

  const onSearch = useCallback(
    (str: string) => {
      return str
        ? initialUsers.filter(
            user =>
              (user.name && user.name.toLowerCase().includes(str)) ||
              (isUser(user) &&
                ((user.lastName && user.lastName.toLowerCase().includes(str)) ||
                  user.email.toLowerCase().includes(str) ||
                  user.userName.toLowerCase().includes(str)))
          )
        : [...initialUsers];
    },
    [initialUsers]
  );

  const searchFn = useCallback(
    (str: string) => {
      const filteredUsers = onSearch(str);
      updateUserSearch && updateUserSearch({ searchString: str, users: filteredUsers });
    },
    [onSearch, updateUserSearch]
  );

  const clearFn = useCallback(() => {
    updateUserSearch && updateUserSearch({ searchString: '', users: initialUsers });
  }, [initialUsers, updateUserSearch]);

  const data = useMemo(
    () => ({
      searchString,
      users: onSearch(searchString)
    }),
    [onSearch, searchString]
  );

  return useMemo(
    () => ({
      data,
      search: searchFn,
      clear: clearFn
    }),
    [clearFn, data, searchFn]
  );
}
