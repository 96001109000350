import { SchemaUtils } from '@tendium/prom-types';
import {
  ApiContractPeriodDatesOutput,
  TenderContractInfo,
  ApiTenderTransactionData,
  PeriodTransaction,
  CumulativeTransaction,
  BuyerCoverageGap
} from './types';
import { getBuyerOrgIdFromFields, getDateFromDateFields } from '../helpers';
import { IApiOrganization } from '../../organisations';

export class TenderContractInfoData implements TenderContractInfo {
  public readonly contractEnd?: number;
  public readonly contractStart?: number;
  public readonly extensionEndDates?: number[];
  public readonly availableDate?: number;
  public readonly deadline?: number;
  public readonly buyerOrgIds: string[];

  constructor(api: ApiContractPeriodDatesOutput) {
    const data = api.getTender;
    this.availableDate = getDateFromDateFields(data.timeline?.availableDate?.fields ?? []);
    this.deadline = getDateFromDateFields(data.timeline?.deadline?.[0]?.fields ?? []);
    this.contractStart = data.expiringContract?.contractStart;
    this.contractEnd = data.expiringContract?.contractEnd;
    this.extensionEndDates = data.expiringContract?.extensionEndDates ?? [];

    const sortedBuyers = SchemaUtils.TenderBox.sortBuyerBoxes(data.general.buyerBoxes);
    const orgId = getBuyerOrgIdFromFields(sortedBuyers?.[0].fields ?? []);
    this.buyerOrgIds = orgId ? [orgId] : [];
  }
}

export class TenderTransactionData implements ApiTenderTransactionData {
  public readonly buyerCoverageGaps: BuyerCoverageGap[];
  public readonly cumulativeTransactions: CumulativeTransaction[];
  public readonly currency: string;
  public readonly organisations: IApiOrganization[];
  public readonly transactions: PeriodTransaction[];
  constructor(api: ApiTenderTransactionData, suppliers: IApiOrganization[]) {
    this.currency = api.currency;
    this.organisations = api.organisations.map(org => ({
      organisationName:
        suppliers.find(supp => supp.organisationNumber === org.organisationNumber)?.organisationName ??
        org.organisationName ??
        org.organisationNumber,
      organisationNumber: org.organisationNumber
    }));
    this.transactions = api.transactions;
    this.cumulativeTransactions = api.cumulativeTransactions;
    this.buyerCoverageGaps = api.buyerCoverageGaps;
  }
}
