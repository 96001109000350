import React, { FC, ReactNode } from 'react';
import { AppContext, SignUpFlowContext } from './context';
import { useAppCtx, useSignUpCtx } from './hooks';

interface Props {
  children: ReactNode;
}
export const AppProvider: FC<Props> = ({ children }) => {
  const value = useAppCtx();

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const SignUpProvider: FC<Props> = ({ children }) => {
  const value = useSignUpCtx();

  return <SignUpFlowContext.Provider value={value}>{children}</SignUpFlowContext.Provider>;
};
