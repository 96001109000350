import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AssignedTo } from 'src/models/users/types';
import { faLongArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'src/common';
import Assignee from 'src/shared/Assignee';

interface IProps {
  user: AssignedTo;
  isOwner?: boolean;
  onRemove: (id: string) => void;
  onMove: (id: string) => void;
  canNotBeDeleted?: boolean;
  readOnly: boolean;
  isMe?: boolean;
  className?: string;
}

export const ACUserItem: FC<IProps> = (props: IProps) => {
  const { onRemove, onMove, isOwner, canNotBeDeleted, readOnly, isMe, className, user } = props;
  const { id } = user;
  const { t } = useTranslation();

  const onRemoveHandler = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  const onMoveHandler = useCallback(() => {
    onMove(id);
  }, [id, onMove]);

  return (
    <div className={styles.row}>
      <Assignee assignedToId={user.id} />
      <div className={classNames(styles.actions, (readOnly && !isMe) || canNotBeDeleted ? styles.isHiddenActions : '')}>
        {!readOnly && (
          <Tooltip title={t(isOwner ? 'Common.ACL.makeOwnerToSubscriber' : 'Common.ACL.makeSubscriberToOwner')}>
            <Button
              onClick={onMoveHandler}
              type={'text'}
              icon={<FontAwesomeIcon icon={faLongArrowUp} />}
              className={classNames(styles.iconButton, className, { [styles.isOwner]: isOwner })}
            />
          </Tooltip>
        )}
        <Button onClick={onRemoveHandler} type={'link'}>
          {t('Common.remove')}
        </Button>
      </div>
    </div>
  );
};

export default ACUserItem;
