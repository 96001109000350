import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Heading, List } from 'src/common';
import SupplierListItem from 'src/shared/SupplierContracts/SupplierListItem';
import { SupplierContractObject } from 'src/models/procurements/Tenders/types';

interface Props {
  className?: string;
}

const SupplierListDemo: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState<string | undefined>();

  // Tender ID from production
  const demoTenderId = '6711020d77358b1194c8f733';

  const demoSupplierContractObjects: SupplierContractObject[] = [
    {
      supplier: {
        organisationName: `${t('Company.company')} 3`,
        organisationNumber: 'DEMO3'
      },
      tenders: [
        {
          id: demoTenderId,
          tender: {
            title: `${t('DetailsPage.tender')} 1`,
            tenderPeriod: {
              endDate: 1664610412000
            },
            value: {
              amount: 1_500_000,
              currency: 'SEK'
            },
            contractPeriod: {
              startDate: 1622533612000,
              endDate: 1664610412000
            }
          }
        },
        {
          id: demoTenderId,
          tender: {
            title: `${t('DetailsPage.tender')} 2`,
            tenderPeriod: {
              endDate: 1664610412000
            },
            value: {
              amount: 1_000_000,
              currency: 'SEK'
            },
            contractPeriod: {
              startDate: 1622533612000,
              endDate: 1664610412000
            }
          }
        },
        {
          id: demoTenderId,
          tender: {
            title: `${t('DetailsPage.tender')} 3`,
            tenderPeriod: {
              endDate: 1664610412000
            },
            value: {
              amount: 500_000,
              currency: 'SEK'
            },
            contractPeriod: {
              startDate: 1622533612000,
              endDate: 1664610412000
            }
          }
        }
      ]
    },
    {
      supplier: {
        organisationName: `${t('Company.company')} 4`,
        organisationNumber: 'DEMO4'
      },
      tenders: [
        {
          id: demoTenderId,
          tender: {
            title: `${t('DetailsPage.tender')} 1`,
            tenderPeriod: {
              endDate: 1664610412000
            },
            value: {
              amount: 1_900_000,
              currency: 'SEK'
            },
            contractPeriod: {
              startDate: 1622533612000,
              endDate: 1664610412000
            }
          }
        },
        {
          id: demoTenderId,
          tender: {
            title: `${t('DetailsPage.tender')} 2`,
            tenderPeriod: {
              endDate: 1664610412000
            },
            value: {
              amount: 1_000_000,
              currency: 'SEK'
            },
            contractPeriod: {
              startDate: 1622533612000,
              endDate: 1664610412000
            }
          }
        }
      ]
    },
    {
      supplier: {
        organisationName: `${t('Company.company')} 5`,
        organisationNumber: 'DEMO5'
      },
      tenders: [
        {
          id: demoTenderId,
          tender: {
            title: `${t('DetailsPage.tender')} 1`,
            tenderPeriod: {
              endDate: 1664610412000
            },
            value: {
              amount: 1_900_000,
              currency: 'SEK'
            },
            contractPeriod: {
              startDate: 1622533612000,
              endDate: 1664610412000
            }
          }
        }
      ]
    }
  ];
  return (
    <div className={classNames(styles.container, className)}>
      <div>
        <Heading title={t('Tenders.Billing.multipleContracts')} />
        <List items={demoSupplierContractObjects}>
          {(item: SupplierContractObject) => (
            <SupplierListItem supplierContractObject={item} expandedId={expandedId} setExpandedId={setExpandedId} />
          )}
        </List>
      </div>
    </div>
  );
};

export default SupplierListDemo;
