import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReadOnlyBlockRange from '../../Range/ReadOnlyBlock';
import { IBoxFieldDateRange } from 'src/models/procurements/Tender/types';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';

interface IProps extends IBlockReadProps, Pick<IBoxFieldDateRange, 'from' | 'to'> {}
export const ReadOnlyBlockDateRange: FC<IProps> = ({ from, to, className }) => {
  const { t } = useTranslation();
  const dateFormat = 'Common.Format.detailsDateFormat';

  const fromDate = !!from ? t(dateFormat, { date: new Date(from) }) : undefined;
  const toDate = !!to ? t(dateFormat, { date: new Date(to) }) : undefined;

  return (
    <div className={className}>
      <ReadOnlyBlockRange from={fromDate} to={toDate} />
    </div>
  );
};

export default ReadOnlyBlockDateRange;
