import React, { FC } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { faAward } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Tooltip from 'src/common/Tooltip';
import { ProcurementStatus } from 'src/models/procurements/types';
import { IStatusData, ITenderMultipleStatusBadgeProps, ITenderSingleStatusBadgeProps } from './types';

const getStatusData = (props: ITenderSingleStatusBadgeProps, t: TFunction): IStatusData | null => {
  if (props.status !== ProcurementStatus.Award && props.status !== ProcurementStatus.TenderCancellation) {
    return null;
  }
  const status = props.status === ProcurementStatus.Award ? 'awarded' : 'cancelled';
  const icon = props.status === ProcurementStatus.Award ? faAward : faBan;
  const title = props.title || t(`Tenders.${status}`);
  const tooltip = props.iconTooltip || t(`Tenders.${status}Tips`);
  const className = styles[status];

  return {
    icon,
    title,
    tooltip,
    className
  };
};

const getStatusComp = (props: ITenderSingleStatusBadgeProps, status: IStatusData): JSX.Element => {
  const { mode, className } = props;
  if (mode === 'text') {
    return <span className={classNames(styles.badgeText, status.className, className)}>({status.title})</span>;
  }
  if (mode === 'icon') {
    return (
      <Tooltip title={status.tooltip}>
        <span className={classNames(styles.badgeIcon, status.className, className)}>
          <FontAwesomeIcon icon={status.icon} />
        </span>
      </Tooltip>
    );
  }
  return (
    <span className={classNames(styles.badge, status.className, className)}>
      <FontAwesomeIcon icon={status.icon} />
      <span className={styles.title}>{status.title}</span>
    </span>
  );
};

export function showTenderStatusBadge(statuses: ProcurementStatus[]): boolean {
  return !!statuses.find(
    status => status === ProcurementStatus.Award || status === ProcurementStatus.TenderCancellation
  );
}

export const SingleTenderStatusBadge: FC<ITenderSingleStatusBadgeProps> = props => {
  const { t } = useTranslation();
  const status = getStatusData(props, t);
  if (!status) {
    return null;
  }
  const Comp = getStatusComp(props, status);
  if (props.wrapper) {
    const Wrapper = props.wrapper;
    return <Wrapper>{Comp}</Wrapper>;
  }
  return Comp;
};

export const TenderStatusBadge: FC<ITenderMultipleStatusBadgeProps> = props => {
  const { statuses, ...restProps } = props;
  return (
    <>
      {statuses.map(status => {
        const singleProps = typeof status === 'string' ? { ...restProps, status } : { ...restProps, ...status };
        const key = typeof status === 'string' ? status : status.status;
        return <SingleTenderStatusBadge {...singleProps} key={key} />;
      })}
    </>
  );
};

export default TenderStatusBadge;
