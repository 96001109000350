import React, { ReactNode, FC, useMemo } from 'react';
import { useRemindersCountData, useRemindersData } from './hooks';
import { RemindersContext } from './context';

interface Props {
  children: ReactNode;
  relationId?: string;
  assignedTo?: string;
  createdBy?: string;
  id?: string;
  wsId?: string;
  sourceTitle?: string;
}

export const RemindersProvider: FC<Props> = ({
  children,
  relationId,
  assignedTo,
  createdBy,
  id,
  wsId,
  sourceTitle
}) => {
  const {
    data: remindersData,
    loading: remindersLoading,
    fetchMore
  } = useRemindersData(relationId, assignedTo, createdBy);
  const { data: remindersCountData, loading: remindersCountLoading } = useRemindersCountData({
    relationId,
    assignedTo,
    createdBy
  });
  const loading = remindersLoading || remindersCountLoading;

  const remindersCount = useMemo(() => {
    return remindersCountData?.getRemindersCount.totalCount;
  }, [remindersCountData?.getRemindersCount.totalCount]);

  const contextValue = useMemo(
    () => ({ loading, remindersData, remindersCount, id, wsId, sourceTitle, fetchMore }),
    [loading, remindersData, remindersCount, id, wsId, sourceTitle, fetchMore]
  );

  return <RemindersContext.Provider value={contextValue}>{children}</RemindersContext.Provider>;
};
