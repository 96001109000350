import React, { FC } from 'react';
import { ThemeMode, LogoProps } from 'src/models/ownership';

export const ByggfaktaLogoIcon: FC<LogoProps> = ({ mode = ThemeMode.light, isShort, className }) => {
  return isShort ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 129.9 110.1"
      xmlSpace="preserve"
      className={className}
      fill="currentColor"
    >
      <path d="M8.1,0l53.1,0C65.5,0,69,3.5,69,7.8v67.6l34.4-60H85.3L76.4,0h53.6L68.2,106.5c-3.7,6.4-13.5,3.8-13.5-3.6V15.5l-34.4,0  l22,38.3l-8.4,15.8L1.1,12.1C-2,6.7,1.9,0,8.1,0z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 597.4 110.1"
      xmlSpace="preserve"
      fill="currentColor"
    >
      <path
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#005ff7' }}
        id="logo"
        d="M8.1,0l53.1,0C65.5,0,69,3.5,69,7.8v67.6l34.4-60H85.3L76.4,0h53.6L68.2,106.5c-3.7,6.4-13.5,3.8-13.5-3.6V15.5  l-34.4,0l22,38.3l-8.4,15.8L1.1,12.1C-2,6.7,1.9,0,8.1,0z"
      />
      <path
        style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#005ff7' }}
        id="text"
        d="M173.5,29.5h52.2v12h-52.2V29.5z M207,91.4l0-45.8l-14.3,4.2l0,41.6H207z M327.4,91.4V51.7l30.1,39.7h13.3V29.5  h-14.3v39.1l-28.6-39.1h-13.5v61.9H327.4z M394.6,49.8v41.6h31.9c17.1-2.8,27.7-14.7,27.6-33.7c-0.1-20.1-20.6-28.2-28.3-28.3  c0,0-31.4,0.2-31.1,0.1v12h31.3c4.5,0.7,13.5,7,13.5,17.6c0,12.5-9.4,20.5-14.3,20.5h-16.9l0-34.1L394.6,49.8z M473.5,29.5v61.9  h47.8V79.6h-33.9V65.5h30.1V54.1h-30.1V41.5h33.9v-12H473.5z M543.4,29.5v12c0,0,30.9,0,32.2,0c2.5,0,7.2,3.2,7.3,9.4  c0.1,7-4.6,9.8-7.8,10.9h-17.4V45.5l-14,4.2v41.7h14.3V73.3h13.8l9.4,18.1h16.4L584.2,71c0,0,11.8-5.2,12-20  c0.3-19-21.1-21.5-19.2-21.5C578.7,29.5,543.4,29.5,543.4,29.5z M244.5,29.5v61.9h47.8V79.7h-33.9V65.5h30.1V54.1h-30.1V41.6h33.9  v-12H244.5z"
      />
    </svg>
  );
};

export default ByggfaktaLogoIcon;
