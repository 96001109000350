import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { IBoxFieldEmail } from 'src/models/procurements/Tender/types';
import BlockEmpty from '../../Empty';
import { IBlockReadProps } from 'src/shared/Tender/Boxes/types';
import { Tooltip } from 'src/common';
import { toCorrectEmail } from 'src/models/procurements/helpers';

interface IProps extends IBlockReadProps, Pick<IBoxFieldEmail, 'email'> {}
export const ReadOnlyBlockEmail: FC<IProps> = ({ email, className, config }) => {
  return email ? (
    config?.isTooltip ? (
      <Tooltip title={email}>
        <a className={styles.block} href={toCorrectEmail(email)} rel={'noopener noreferrer'} target={'_blank'}>
          <span className={classNames(styles.title, className)}>{email}</span>
        </a>
      </Tooltip>
    ) : (
      <a className={styles.block} href={toCorrectEmail(email)} rel={'noopener noreferrer'} target={'_blank'}>
        <span className={classNames(styles.title, className)}>{email}</span>
      </a>
    )
  ) : (
    <BlockEmpty />
  );
};

export default ReadOnlyBlockEmail;
