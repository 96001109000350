import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Translations } from 'src/lib/I18nService/types';

export function useExistingTranslation(ns?: Translations): { existingT: (value: string) => string } {
  const { t, i18n } = useTranslation(ns);
  return {
    existingT: useCallback(
      value => {
        return i18n.exists(value, { ns }) ? t(value) : value;
      },
      [i18n, ns, t]
    )
  };
}

export default useExistingTranslation;
