import React, { FC } from 'react';
import styles from './index.module.scss';
import AssignedBidsCard from './AssignedBidsCard';
import DeadlineComingUpBidsCard from './DeadlineComingUpBidsCard';
import ForecastBarStackChart from './ForecastBarStackChart';

export const BidspacesDashboard: FC = () => {
  return (
    <>
      <div className={styles.dashboard}>
        <AssignedBidsCard />
        <DeadlineComingUpBidsCard />
      </div>
      <div className={styles.barChart}>
        <ForecastBarStackChart />
      </div>
    </>
  );
};

export default BidspacesDashboard;
