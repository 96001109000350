import { ReactNode, Ref } from 'react';
import { ITenderBox, TenderBlockSize, TenderBoxMode, IBoxField } from 'src/models/procurements/Tender/types';
import { StrictUnion } from 'src/helpers/typescript';
import { DateFormatTranslation, Translations } from 'src/lib/I18nService/types';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { isObject } from 'src/helpers';

export interface IBoxCommonProps {
  actions?: ReactNode;
  isEditable?: boolean;
  as: TenderBoxMode;
  isActive?: boolean;
  isFirstField?: boolean;
  isInfo?: boolean;
  editable?: boolean; // isDeletable box
  boxMode?: boolean; // bordered or not box
  config?: IFieldConfig;
  hideEmpty?: boolean;
}
export interface IBoxPublicProps {
  size?: TenderBlockSize;
  className?: string;
}
export interface IBoxBaseProps extends Omit<IBoxCommonProps, 'isFirstField' | 'isInfo' | 'isExtra'> {
  box: ITenderBox;
}
interface IBoxTitleProps {
  title: ReactNode;
}
export interface IBoxCustomProps extends IBoxCommonProps, IBoxTitleProps {
  box: ITenderBox;
  field?: IBoxField;
}
export interface IBoxCollectionProps extends IBoxCommonProps, IBoxTitleProps {
  boxes: ITenderBox[];
}
export interface IBoxReverseProps extends IBoxCommonProps, IBoxTitleProps {
  box: ITenderBox;
}
export type IBoxProps = StrictUnion<
  | (Omit<IBoxCollectionProps, 'boxes'> & { boxes?: ITenderBox[] })
  | (Omit<IBoxCustomProps, 'box'> & { box?: ITenderBox })
  | (Omit<IBoxReverseProps, 'box'> & { box?: ITenderBox })
  | IBoxBaseProps
> &
  Omit<IBoxCommonProps, 'isActive'> &
  IBoxPublicProps;

export type IBoxInnerProps = StrictUnion<IBoxCollectionProps | IBoxCustomProps | IBoxReverseProps | IBoxBaseProps> &
  IBoxCommonProps &
  IBoxPublicProps;

export function isBoxBase(u: unknown): u is IBoxBaseProps {
  return isObject(u) && 'as' in u && u.as === TenderBoxMode.Base;
}
export function isBoxCollection(u: unknown): u is IBoxCollectionProps {
  return isObject(u) && 'as' in u && u.as === TenderBoxMode.Collection;
}
export function isBoxCustom(u: unknown): u is IBoxCustomProps {
  return isObject(u) && 'as' in u && u.as === TenderBoxMode.Custom;
}
export function isBoxReverse(u: unknown): u is IBoxReverseProps {
  return isObject(u) && 'as' in u && u.as === TenderBoxMode.Reverse;
}

export interface IFieldCommonProps {
  isEditable?: boolean;
  translation?: Translations;
  config?: IFieldConfig;
  className?: string;
}
export interface IFieldProps extends Omit<IFieldCommonProps, 'translation'> {
  box: ITenderBox;
  field: IBoxField;
  name_t?: Translations;
  value_t?: Translations;
  hideEmpty?: boolean;
}
export enum FieldViewMode {
  Edit = 'Edit',
  Read = 'Read'
}
export interface IFieldConfig {
  isTitle?: boolean;
  isTooltip?: boolean;
  icon?: boolean | IconDefinition;
  date?: IFieldDateConfig;
  currency?: IFieldCurrencyConfig;
}
export interface IFieldDateConfig {
  isCountdown?: boolean;
  showTime?: boolean;
  dateFormat?: DateFormatTranslation;
}
export interface IFieldCurrencyConfig {
  isShort?: boolean;
}
export const DEFAULT_CONFIG: IFieldConfig = {
  isTitle: false,
  isTooltip: false,
  date: {
    isCountdown: false,
    dateFormat: DateFormatTranslation.full
  },
  currency: {
    isShort: false
  }
};
export interface IBlockProps<T> extends IFieldCommonProps {
  field: T;
  onChange?: (field: T) => void;
  onFinish?: () => void;
  viewMode?: FieldViewMode;
}
export interface IBlockEditProps<T>
  extends Pick<IBlockProps<T>, 'field' | 'onChange' | 'onFinish' | 'className' | 'config'> {
  containerRef?: Ref<HTMLDivElement>;
}
export interface IBlockReadProps extends Pick<IFieldCommonProps, 'translation' | 'className' | 'config'> {}
