import { MouseEvent, useCallback, useContext } from 'react';
import { NewStatusIdContext, SetNewStatusIdContext } from './context';
import { useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { IBids } from 'src/models/bids/Bids/types';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { trackOpenBidPreviewInWorkspace } from 'src/segment/events';
import { useBidPreviewNav } from 'src/models/bids/BidPreview/hooks';

export function useSetNewStatusIdContext(): (id: string | null) => void {
  const ctx = useContext(SetNewStatusIdContext);
  if (!ctx) {
    throw new Error('not in SetNewStatusIdContext provider');
  }

  return ctx;
}

export function useNewStatusIdContext(): string | null {
  return useContext(NewStatusIdContext);
}

export function useOpenBidPreview(bid: IBids): (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void {
  const openSidebar = useOpenSidebar();
  const eventSource = useBidPreviewNav();

  return useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.preventDefault();
      openSidebar({
        id: bid.id,
        mode: SidebarMode.BID_INFO
      });
      trackOpenBidPreviewInWorkspace(
        {
          id: bid.workspace.id
        },
        eventSource,
        bid.status?.category
      );
    },
    [openSidebar, bid.id, bid.workspace.id, bid.status?.category, eventSource]
  );
}
