import React, { ReactNode, Ref, forwardRef } from 'react';
import Spinner from 'src/common/Spinner';
import styles from './index.module.scss';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface Props {
  isOpen: boolean;
  isActive: boolean;
  label: ReactNode;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
}
const DropdownButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref: Ref<HTMLButtonElement>) => {
  const { isOpen, isActive, label, loading, className, disabled, ...restProps } = props;
  const isDisabled = !!(disabled || loading);

  return (
    <button
      className={classNames(styles.button, !!className && className, { [styles.isActive]: isActive })}
      disabled={isDisabled}
      ref={ref}
      {...restProps}
    >
      <span>{label}</span>
      {!loading ? (
        <FontAwesomeIcon icon={faCaretDown} className={classNames(styles.buttonIcon, { [styles.isActive]: isOpen })} />
      ) : (
        <Spinner className={classNames(styles.buttonIcon, styles.spinner)} />
      )}
    </button>
  );
});

DropdownButton.displayName = 'DropdownButton';
export default DropdownButton;
