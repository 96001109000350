import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faFaceThinking } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  title?: string;
  desc?: string;
  className?: string;
  icon?: IconDefinition;
  children?: ReactNode;
}
export const EmptyFilter: FC<Props> = ({ title, desc, className, icon, children }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, className)}>
      <FontAwesomeIcon icon={icon ?? faFaceThinking} className={styles.icon} />
      <div className={styles.title}>{title ?? t('Common.noMatch')}</div>
      <p className={styles.desc}>{desc ?? t('Common.noMatchDesc')}</p>
      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default EmptyFilter;
