import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import { Team } from 'src/models/users/Team/types';
import Spinner from '../Spinner';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { toColor } from 'src/helpers/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserCount from '../UserCount';
import userStyles from '../UserName/index.module.scss';
import avatarStyles from '../Avatar/index.module.scss';
import styles from './index.module.scss';
import { UserNameProps } from '../UserName';

interface IProps extends Omit<UserNameProps, 'user'> {
  team: Team;
  bordered?: true;
}

export const TeamName: FC<IProps> = ({ size = 'm', showTooltip = false, ...restProps }) => {
  const { team, bordered, className, loading, desc, mode } = restProps;
  const { id, name, color, members } = team;
  const backgroundColor = useMemo(() => color ?? toColor(id), [color, id]);

  const TeamIcon = (
    <div
      className={classNames(avatarStyles.avatar, {
        [avatarStyles.xSmall]: size === 'xs',
        [avatarStyles.small]: size === 's',
        [avatarStyles.medium]: size === 'm',
        [avatarStyles.large]: size === 'l',
        [avatarStyles.xLarge]: size === 'xl',
        [avatarStyles.isUpdating]: loading,
        [avatarStyles.bordered]: bordered
      })}
      style={!loading ? { backgroundColor } : undefined}
    >
      {loading ? <Spinner /> : <FontAwesomeIcon icon={faUsers} className={avatarStyles.icon} />}
    </div>
  );

  return (
    <Tooltip title={showTooltip ? name : null}>
      {mode === 'onlyAvatar' ? (
        TeamIcon
      ) : (
        <div className={classNames(userStyles.user, className)}>
          <div className={styles.inner}>
            {TeamIcon}
            <div className={userStyles.userContent}>
              <span className={userStyles.name} data-value={name}>
                {name}
              </span>
              {mode === 'full' && desc && <span className={styles.desc}>{desc}</span>}
            </div>
          </div>
          {mode !== 'full' && mode !== 'onlyName' && <UserCount users={members} />}
        </div>
      )}
    </Tooltip>
  );
};

export default TeamName;
