import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import { router } from 'src';
import { recordPageView } from '.';

const PATH_WITH_ID = Object.values(Paths).filter(p => /:.+/.test(p));

const handlePageWithId = (pathname: string, matchedPath: string): boolean => {
  const PageViewWithId = matchPath({ path: matchedPath }, pathname);
  if (PageViewWithId) {
    // TODO: @check
    recordPageView({
      pageId:
        PageViewWithId.pathname === Paths.PROCUREMENT_DETAILS_PAGE
          ? Paths.TENDER_PAGE
          : PageViewWithId.pathname /*(pathnameBase??)*/
    });
    return true;
  }
  return false;
};

const handler = (pathname: string): void => {
  PATH_WITH_ID.forEach(p => {
    if (handlePageWithId(pathname, p)) {
    }
  });
  recordPageView({ pageId: pathname });
};

/** @todo check if it works correctly, should be totally enough without any additional handlers */
export function useRumPageWatcher(): void {
  const location = useLocation();
  useEffect(() => {
    const unregister = router.subscribe(state => {
      handler(state.location.pathname);
    });
    handler(location.pathname);

    return () => {
      unregister();
    };
  }, [location.pathname]);
}

export default useRumPageWatcher;
