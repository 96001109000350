import React, { FC } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import DownloadAllAttachments from 'src/shared/CallOffs/DownloadAllAttachents';
import AttachmentItem from 'src/shared/CallOffs/AttachmentsItem';
import { IInboxEmailAttachment } from 'src/models/callOffs/types';
import { BlockEmpty } from 'src/shared';

interface IProps {
  attachments: IInboxEmailAttachment[] | null;
  zip: IInboxEmailAttachment | null;
  procurementId: string | undefined;
}

export const BidPreviewAttachments: FC<IProps> = props => {
  const { attachments, zip, procurementId } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <h5 className={styles.attachmentsTitle}>{t('Common.listOfAttachments')}</h5>
        {zip && <DownloadAllAttachments url={zip.getUrl} fileName={zip.fileName} />}
      </div>
      <ul className={styles.attachments}>
        {attachments && attachments.length ? (
          attachments.map(attachment => (
            <AttachmentItem
              key={attachment.filePath}
              attachment={attachment}
              eventSource="Attachments - Bid Preview"
              procurementId={procurementId}
            />
          ))
        ) : (
          <BlockEmpty />
        )}
      </ul>
    </>
  );
};

export default BidPreviewAttachments;
