import { useLocation } from 'react-router';
import { useQueryParams } from 'src/helpers';
import { OverviewQp } from 'src/models/procurements/types';
import { Paths } from 'src/pages/paths';

export function useTenderActionEventSource(excludePreview?: boolean): string {
  const qp = useQueryParams();
  const qpPreviewId = qp[OverviewQp.PreviewId];
  const { pathname } = useLocation();

  if (!excludePreview && qpPreviewId) return 'Tender Preview';

  if (pathname.includes(Paths.EXPIRING_CONTRACTS_ROUTE)) return 'Expiring Contracts';

  if (pathname.includes(Paths.MONITORING)) return 'Monitoring Profile';

  if (pathname.includes(Paths.TENDER_ROUTE)) return 'Details Page';

  return 'All tenders';
}
