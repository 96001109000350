import React, { FC, useMemo } from 'react';
import { AccessControlPolicy } from 'src/models/acl/types';
import { useEmployees } from 'src/models/users/hooks';
import { MemberList, MemberListProps } from 'src/common/MemberList';

interface IProps extends MemberListProps {
  accessControlPolicy: AccessControlPolicy | undefined;
}
export const ACLUserList: FC<IProps> = props => {
  const { owners, members: subscribersList, accessControlPolicy, loading, ...restProps } = props;

  const { data: employees, loading: employeeLoading } = useEmployees();

  const isLoading = loading || employeeLoading;

  const subscribePolicyIsAll = accessControlPolicy === AccessControlPolicy.ALL;
  const employeesList = useMemo(() => employees?.personel.filter(emp => emp.enabled) || [], [employees?.personel]);

  const preparedSubscribersList = useMemo(() => {
    return subscribePolicyIsAll ? employeesList : subscribersList;
  }, [employeesList, subscribePolicyIsAll, subscribersList]);

  return <MemberList owners={owners} members={preparedSubscribersList} loading={isLoading} {...restProps} />;
};

export default ACLUserList;
