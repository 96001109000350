import { IApiBid } from '../bids/BidFull/types';
import { DndType } from 'src/types/dnd';
import { BidItemType } from '@tendium/prom-types/tender';
import { IBidIdentifier, ILinkedBidIdentifier } from '../bids/Bids/types';
import { IAccessControl } from '../acl/types';
import { AssignedTo } from '../users/types';
import { isObject } from 'src/helpers';
import { ApiAssignedTo } from '../users/AssignedTo/types';

export interface ICallOff {
  id: string;
  date: number;
  subject: string;
  originalSender: string;
  isRead: boolean;
  isRejected: boolean;
  isExcluded?: boolean;
  isAttachment: boolean;
  bid?: IBidIdentifier;
  linkedBid?: ILinkedBidIdentifier;
  nextCallOff?: INextCallOff;
}
export interface INextCallOff {
  id: string;
  isRead: boolean;
}

export enum EmailStatus {
  INTERESTING = 'INTERESTING',
  REJECTED = 'REJECTED',
  UNHANDLED = 'UNHANDLED'
}
export interface IApiBaseInbox {
  emailAddress: string;
  inboxName: string;
  isArchived: boolean | null;
}
export interface IBaseInbox extends IApiBaseInbox {
  color?: string;
}
export interface IApiInbox extends IApiBaseInbox {
  owners?: ApiAssignedTo[];
  subscribers?: ApiAssignedTo[];
  accessControl: IAccessControl;
  accessToEdit: boolean;
}
export interface IInbox extends Omit<IApiInbox, 'owners' | 'subscribers'>, IBaseInbox {
  owners?: AssignedTo[];
  subscribers?: AssignedTo[];
}

export interface IInboxEmailAttachment {
  filePath: string;
  fileName: string;
  getUrl: string;
}

export interface IInboxEmail {
  mailId: string;
  email: string;
  date: Date;
  subject: string | null;
  originalSender: string;
  content?: string;
  htmlContent?: string;
  mailStatus: EmailStatus;
  isRead: boolean;
  attachments: IInboxEmailAttachment[] | null;
  zip: IInboxEmailAttachment | null;
  bidsV2: IApiBid[];
  linkedBidsV2: IApiBid[];
}
export type ApiCallOffsData = {
  getInboxEmails: {
    __typename: 'MailResponse';
    total: number;
    userMails: IInboxEmail[];
  };
};

export type ApiCallOffsVars = {
  from?: number;
  size?: number;
  keywords?: string[];
  statuses?: EmailStatus[];
  emails?: string[];
};
export type CallOffsVars = Pick<ApiCallOffsVars, 'from' | 'size'> & {
  search?: string;
  onlyUnhandled?: boolean;
  inboxId?: string;
};
export enum CallOfsTableColumnsIds {
  received = 'received',
  actions = 'actions',
  subject = 'subject',
  sender = 'sender'
}

export interface ICallOffsDndItem {
  type: DndType.CallOffsItem;
  dndItem: ICallOff;
  bidIds?: IBidIdentifier[];
  originIds?: string[];
  originType?: BidItemType;
  linkedBids?: ILinkedBidIdentifier[];
}
export function isCallOffsDndItem(u: unknown): u is ICallOffsDndItem {
  return isObject(u) && 'type' in u && u.type === DndType.CallOffsItem;
}

export interface ISelectedCallOff {
  id: string;
  bid?: IBidIdentifier;
  linkedBid?: ILinkedBidIdentifier;
}
export enum CallOffsSearchParams {
  onlyUnhandled = 'onlyUnhandled'
}
