import { gql } from '@apollo/client/core';
import { CHECKLIST_FIELDS, CHECKLIST_ITEM_FIELDS } from './fragments';

export const GET_BID_CHECKLIST = gql`
  query getChecklist($bidId: String!) {
    getBid(input: { bidId: $bidId }) {
      ...checklistFields
    }
  }
  ${CHECKLIST_FIELDS}
`;

export const CREATE_CHECKLIST_ITEM = gql`
  mutation createChecklistItem(
    $bidId: String!
    $name: String!
    $description: String!
    $status: ChecklistItemStatus!
    $assignedTo: String
    $deadline: Float
  ) {
    createChecklistItem(
      input: {
        bidId: $bidId
        name: $name
        description: $description
        status: $status
        assignedTo: $assignedTo
        deadline: $deadline
      }
    ) {
      ...checklistItemFields
    }
  }
  ${CHECKLIST_ITEM_FIELDS}
`;

export const UPDATE_CHECKLIST_ITEM = gql`
  mutation updateChecklistItem(
    $id: String!
    $bidId: String!
    $name: String
    $status: ChecklistItemStatus
    $assignedTo: String
    $deadline: Float
    $description: String
    $fulfillment: ChecklistItemFulfillment
    $isActive: Boolean
  ) {
    updateChecklistItem(
      input: {
        id: $id
        bidId: $bidId
        name: $name
        status: $status
        assignedTo: $assignedTo
        deadline: $deadline
        description: $description
        fulfillment: $fulfillment
        isActive: $isActive
      }
    ) {
      ...checklistItemFields
    }
  }
  ${CHECKLIST_ITEM_FIELDS}
`;

export const DELETE_CHECKLIST_ITEMS = gql`
  mutation deleteChecklistItems($bidId: String!) {
    deleteChecklistItems(input: { bidId: $bidId })
  }
`;
