import React, { useCallback, FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface IStarTogglerProps {
  onSetStarred: (id: string, name: string, isStarred: boolean) => void;
  isStarred: boolean;
  id: string;
  name: string;
  lightTheme?: boolean;
  className?: string;
  mode?: 'full' | 'short';
}

const StarToggler: FC<IStarTogglerProps> = ({ mode = 'short', ...restProps }) => {
  const { id, isStarred, onSetStarred, lightTheme, className, name } = restProps;
  const { t } = useTranslation();

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onSetStarred(id, name, isStarred);
    },
    [id, name, isStarred, onSetStarred]
  );
  return (
    <button className={classNames(styles.starButton, className)} onClick={onClick}>
      <FontAwesomeIcon
        icon={isStarred ? faStarSolid : faStar}
        className={classNames(styles.starIcon, { [styles.isStarred]: isStarred, [styles.isLightTheme]: lightTheme })}
      />
      {mode === 'full' && <span className={styles.starLabel}>{t(isStarred ? 'Tenders.unstar' : 'Tenders.star')}</span>}
    </button>
  );
};

export default StarToggler;
