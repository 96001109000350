import React, { FC, useCallback, useState } from 'react';
import RemindersItem from './RemindersItem';
import { useReminders } from 'src/models/reminders/hooks';
import { List, Skeleton } from 'src/common';
import { Reminder } from 'src/models/reminders/types';
import { LoadMoreButton } from 'src/shared';
import { useFetchMore } from 'src/common/infinityScroll';

interface Props {
  fromNotificationList?: boolean;
}

export const RemindersList: FC<Props> = props => {
  const { fromNotificationList } = props;
  const { remindersData, remindersCount, loading, fetchMore } = useReminders();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const loadMore = useCallback(
    async (v: { from: number }) => {
      return (
        fetchMore &&
        fetchMore({
          variables: v
        })
      );
    },
    [fetchMore]
  );

  const onLoadMore = useFetchMore({ from: remindersData?.length ?? 0 }, loadMore);
  const hasMore = (!!remindersData ? remindersData.length : 0) < (!!remindersCount ? remindersCount : 0);

  return loading ? (
    <Skeleton loading active />
  ) : !!remindersData?.length ? (
    <div ref={setContainer}>
      <List items={remindersData} bordered>
        {(item: Reminder) => (
          <RemindersItem reminder={item} key={item.id} fromNotificationList={fromNotificationList} />
        )}
      </List>
      <LoadMoreButton
        onLoadMore={onLoadMore}
        isLoading={loading}
        hasMore={hasMore}
        scrollContainer={container}
        event={'click'}
        type={'link'}
      />
    </div>
  ) : null;
};

export default RemindersList;
