import { MutationTuple, QueryResult, useQuery, useMutation, MutationUpdaterFn } from '@apollo/client';
import {
  UPDATE_COMPANY_ADMIN,
  GET_COMPANY_INFO,
  UPDATE_COMPANY_NUTS_CODES,
  LOAD_COMPANY_NUTS,
  SAVE_COMPANY_SETTINGS,
  GET_COMPANY_MP_COUNT,
  GET_COMPANY_WS_COUNT
} from './queries';
import { ApiAdminCompany, IApiCompanyNutsFilter } from './types';
import { AdminCompany } from '.';
import { useMemo } from 'react';
import { CompanySettings, UpdateCompanySettingsInput } from '@tendium/prom-types/schema';
import { ProfileType } from '@tendium/prom-types';
import { useMe } from 'src/common';

interface IUpdateCompanyAdminResponse {
  updateCompanyAdmin: ApiAdminCompany;
}
export function useUpdateCompanyAdmin(): MutationTuple<
  IUpdateCompanyAdminResponse,
  { email: string; companyId: string }
> {
  return useMutation(UPDATE_COMPANY_ADMIN);
}

interface IApiAdminCompanyResponse {
  getCompany: ApiAdminCompany;
}
export function useAdminCompany(
  companyId?: string
): Omit<QueryResult<IApiAdminCompanyResponse, { companyId: string }>, 'data'> & { data?: AdminCompany } {
  const query = useQuery<
    IApiAdminCompanyResponse,
    {
      companyId: string;
    }
  >(GET_COMPANY_INFO, {
    variables: {
      companyId: companyId ?? ''
    },
    skip: !companyId
  });
  return useMemo(() => ({ ...query, data: query.data ? new AdminCompany(query.data.getCompany) : undefined }), [query]);
}

export function useLoadCompanyNuts(
  companyId?: string,
  skip?: boolean
): QueryResult<{ getCompanyNutsFilter: IApiCompanyNutsFilter }> {
  return useQuery<{ getCompanyNutsFilter: IApiCompanyNutsFilter }, { companyId?: string }>(LOAD_COMPANY_NUTS, {
    variables: { companyId: companyId },
    skip
  });
}

export interface IUpdateCompanyNutsFilterResponse {
  __typename: 'Mutation';
  updateNutsFilter: IApiCompanyNutsFilter & {
    __typename: 'NutsFilter';
  };
}
export function useUpdateCompanyNutsCodes(): MutationTuple<
  IUpdateCompanyNutsFilterResponse,
  { companyId?: string; nutsCodes: string[]; nutsCodesExact: string[] }
> {
  return useMutation(UPDATE_COMPANY_NUTS_CODES);
}

export function getUpdateCacheOnUpdateCompanyNutsCodes(
  filterId: string
): MutationUpdaterFn<IUpdateCompanyNutsFilterResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const filterRef = cache.identify({
      __typename: 'NutsFilter',
      id: filterId
    });
    cache.modify({
      id: filterRef,
      fields: {
        nutsCodes(existingNutsCodesRefs) {
          return data.updateNutsFilter.nutsCodes === null ? existingNutsCodesRefs : data.updateNutsFilter.nutsCodes;
        },
        nutsCodesExact(existingNutsCodesExactRefs) {
          return data.updateNutsFilter.nutsCodesExact === null
            ? existingNutsCodesExactRefs
            : data.updateNutsFilter.nutsCodesExact;
        }
      }
    });
  };
}

export interface UpdateCompanySettingsResponse {
  settings: CompanySettings;
}

export function useUpdateCompanySettings(): MutationTuple<
  UpdateCompanySettingsResponse,
  { companyId?: string; settings?: UpdateCompanySettingsInput }
> {
  return useMutation(SAVE_COMPANY_SETTINGS);
}

export function useCreateMpDisabled(profileType?: ProfileType): boolean {
  const { data: meData } = useMe();
  const query = useQuery<{ getCompanyMatchingProfilesCount: number }, { input?: ProfileType }>(GET_COMPANY_MP_COUNT, {
    variables: { input: profileType }
  });

  const mpLimit = useMemo(() => {
    return meData?.company.settings?.matchingProfilesLimit;
  }, [meData?.company.settings?.matchingProfilesLimit]);

  return useMemo(() => {
    if (mpLimit && query.data) {
      return query.data.getCompanyMatchingProfilesCount >= mpLimit;
    }
    return false;
  }, [mpLimit, query.data]);
}

export function useCreateWsDisabled(): boolean {
  const { data: meData } = useMe();
  const query = useQuery<{ getCompanyWorkspacesCount: number }>(GET_COMPANY_WS_COUNT);

  const wsLimit = useMemo(() => {
    return meData?.company.settings?.workspaceLimit;
  }, [meData?.company.settings?.workspaceLimit]);

  return useMemo(() => {
    if (wsLimit && query.data) {
      return query.data.getCompanyWorkspacesCount >= wsLimit;
    }
    return false;
  }, [wsLimit, query.data]);
}
