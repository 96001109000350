import React, { FC } from 'react';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { CommentParentType } from 'src/models/comments/types';
import { Comments } from 'src/shared';
import { useWsUsersAndTeams } from 'src/models/workspace/hooks';
import { BidItemType } from '@tendium/prom-types/tender';

export const BidPreviewComments: FC = () => {
  const { data } = useBidPreview();
  const comments = data?.comments;
  const users = useWsUsersAndTeams(data?.workspaceId);

  return (
    <>
      {comments && data?.id && (
        <Comments
          users={users}
          comments={comments}
          parentId={data.id}
          parentType={
            data.origin.originType === BidItemType.CallOff
              ? CommentParentType.CallOffBid
              : data.origin.originType === BidItemType.Manual
              ? CommentParentType.ManualBid
              : CommentParentType.ProcurementBid
          }
          eventSource={'Bid Preview'}
        />
      )}
    </>
  );
};

export default BidPreviewComments;
