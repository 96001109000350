import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot, $nodesOfType, EditorState, LexicalEditor } from 'lexical';
import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { AssignedTo } from 'src/models/users/types';
import { EditorStateContent } from './types';
import { MentionsPlugin, MentionNode } from './plugins/Mentions';
import styles from './index.module.scss';

const editorConfig = {
  namespace: 'Editor',
  theme: {
    ltr: styles.ltr,
    rtl: styles.rtl,
    paragraph: styles.editorParagraph
  },
  onError(error: unknown) {
    import.meta.env.MODE === 'development' && console.log('Lexical Error:', error);
  },
  nodes: [MentionNode]
};

interface EditorProps {
  mentions: AssignedTo[];
  editorRef: React.RefObject<LexicalEditor>;
  placeholder?: string;
  className?: string;
  onChange?: (detail: EditorStateContent) => void;
}

const Editor: FC<EditorProps> = ({ mentions, editorRef, placeholder, className, onChange }) => {
  const onStateChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      const editorStateContent = editorState.read(() => {
        const $root = $getRoot();
        const $mentionNodes = $nodesOfType(MentionNode);
        return {
          content: $root.getTextContent().trim(),
          htmlContent: $generateHtmlFromNodes(editor, null),
          mentions: $mentionNodes.map((node: MentionNode) => node.exportJSON())
        };
      });
      onChange && onChange(editorStateContent);
    },
    [onChange]
  );
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <EditorRefPlugin editorRef={editorRef} />
      <RichTextPlugin
        contentEditable={<ContentEditable className={classNames(styles.editor, className)} />}
        ErrorBoundary={LexicalErrorBoundary}
        placeholder={placeholder ? <div className={styles.editorPlaceholder}>{placeholder}</div> : null}
      />
      <MentionsPlugin users={mentions} />
      <HistoryPlugin />
      <OnChangePlugin onChange={onStateChange} />
      <ClearEditorPlugin />
    </LexicalComposer>
  );
};

export default Editor;

export * from './types';
export * from './helpers';
export * from './hooks';
