import { SchemaUtils } from '@tendium/prom-types';

export const BIG_STRING_LIMIT = 100;

export const STRING_FIELD_SIMPLE_NAMES = [
  'Title of the tender',
  'Base for award of contract',
  'Main site or place of performance',
  'Place of performance',
  'The number of suppliers assigned to framework agreement',
  'Type of procedure',
  'Reference number',
  'Procuring agency',
  "Procuring agency's national ID",
  'Postal address',
  'Postal code',
  'Town',
  'Contact person',
  'Phone number',
  'Contract renewal',
  'Type of period',
  'Credit rating agency',
  'Min credit rating',
  'Name of the role',
  'List of languages',
  'Name of system',
  'Language on bid',
  'Type of critera',
  'Criteria',
  'Weighting',
  'Type of value deduction',
  'Contact information',
  'Type of date',
  'Chapter',
  '' //Custom text field
];

export const NOT_EDITABLE_FIELD_NAMES = [SchemaUtils.TenderBox.BuyerBoxFieldNames.IS_LEAD_BUYER as string];
